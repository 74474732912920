import { Component, EventEmitter, inject, Input, Output, TemplateRef } from '@angular/core';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { CommonModule } from '@angular/common';
import { AbstractControl, ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { RemoveFormSectionComponent } from 'shared/remove-form-section/remove-form-section.component';
import { IndexableFormType } from 'app/utils/miscTypes';
import { getFormGroup } from 'utils';

@Component({
  selector: 'ads-multiple-forms-section',
  standalone: true,
  imports: [CommonModule, FormsModule, ExpansionPanelComponent, MatButtonModule, MatIconModule, MatExpansionModule],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './multiple-forms-section.component.html',
  styleUrl: './multiple-forms-section.component.scss',
})
export class MultipleFormsSectionComponent<T extends IndexableFormType> {
  @Input() title = '';
  @Input() groupName = '';
  @Input() list: T[] = [];
  @Input() formTemplate?: TemplateRef<object>;
  @Input() formControls?: Record<string, AbstractControl<string, string>>;
  @Output() addClicked = new EventEmitter<void>();
  @Output() deleteClicked = new EventEmitter<T>();
  expanded = true;
  @Input() expandedList: number[] = [];
  @Input() submitted = false;
  @Input() photosStatus: ('valid' | 'pristine' | 'dirty')[] = [];

  readonly dialog = inject(MatDialog);

  onAddClick(event: Event) {
    event.preventDefault();
    this.addClicked.emit();
  }

  onDeleteClick(event: Event, item: T) {
    event.preventDefault();

    this.dialog.open(RemoveFormSectionComponent, {
      data: { sectionName: this.title + ': ' + item.index, onConfirm: () => this.deleteClicked.emit(item) },
    });
  }

  getFormStatus = (index: number) => () => {
    const control = this.formControls?.[this.groupName + '#' + index];

    if (this.photosStatus[index - 1] === 'dirty') {
      return 'dirty';
    }

    if (control === undefined) {
      return 'pristine';
    }

    if (control.disabled) {
      return 'valid';
    }
    if (control.valid) {
      return 'valid';
    }
    if (Object.values(control?.value ?? {}).some((value) => value !== '')) {
      return 'dirty';
    }
    if (this.photosStatus[index - 1] !== 'pristine') {
      return 'dirty';
    }
    if (control.pristine) {
      return 'pristine';
    }
    if (control.touched) {
      return 'dirty';
    }

    return 'pristine';
  };

  getFormGroup(index: number) {
    return getFormGroup(this.formControls?.[this.groupName + '#' + index]);
  }
}
