import { Component, AfterViewInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { IframeWrapperComponent } from 'pages/iframe-wrapper/iframe-wrapper.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { LayoutNavService } from './layout-nav.service';
import { HeaderComponent } from 'shared/header/header.component';
import { DesktopNavComponent } from './desktop-nav/desktop-nav.component';
import { HeaderService } from 'shared/header/header.service';
import { FeatureManagerService } from 'services';

@Component({
  selector: 'ads-layout-nav',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    MatTabsModule,
    IframeWrapperComponent,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    HeaderComponent,
    DesktopNavComponent,
  ],
  templateUrl: './layout-nav.component.html',
  styleUrl: './layout-nav.component.scss',
})
export class LayoutNavComponent implements AfterViewInit {
  sideNavOpened = false;
  isDesktopView = false;

  constructor(
    public layoutNavService: LayoutNavService,
    public breakpointObserver: BreakpointObserver,
    public readonly featureManager: FeatureManagerService,
    private readonly headerService: HeaderService,
    public readonly router: Router
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.breakpointObserver.observe(['(max-width: 1006px)']).subscribe((x) => {
        this.layoutNavService.isDesktopView.set(!x.matches);
        this.headerService.isDesktopView.set(!x.matches);

        if (!x.matches && this.layoutNavService.selectedIndex === 4) {
          this.layoutNavService.selectedIndex = 0;
          this.router.navigate(['home']);
        }
      });

      this.featureManager.pwaDashboard$.subscribe((isPwa) => {
        if (isPwa) {
          const tabIndex = this.layoutNavService.getTabIndexFromRoute();
          if (this.layoutNavService.isNavigationRouteDefined(tabIndex)) {
            this.layoutNavService.changeTab(tabIndex);
          }
        }
      });

      if (window.matchMedia('(display-mode: standalone)').matches && /(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        this.layoutNavService.isMobileIOS.set(true);
      }
    });
  }
}
