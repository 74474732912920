<ads-simple-form #atmSection="ngModelGroup" ngModelGroup="itmSection">
  <ads-select
    label="ITM Manufacturer"
    name="manufacturer"
    [list]="itmManufacturerList"
    ngModel
  ></ads-select>
  <ads-common-form-input
    name="model"
    label="ITM Model"
    [maxLength]="30"
    [required]="true"
    ngModel
  ></ads-common-form-input>
  <ads-common-form-input
    name="serialNumber"
    label="ITM Serial number"
    [maxLength]="30"
    [required]="true"
    ngModel
  ></ads-common-form-input>
  <ads-select label="Depositor type" name="depositorType" [list]="depositorTypeList" ngModel></ads-select>
  <ads-select
    label="Communication Type"
    name="communicationType"
    [list]="communicationTypesList"
    ngModel
  ></ads-select>
  <ads-common-form-input
    name="terminalId"
    label="ITM Terminal ID"
    [maxLength]="50"
    [required]="true"
    ngModel
  ></ads-common-form-input>
  <ads-common-form-input
    name="networkTerminalId"
    label="Network Terminal ID"
    [maxLength]="50"
    [required]="true"
    ngModel
  ></ads-common-form-input>
</ads-simple-form>
