import { CommonModule } from '@angular/common';
import { Component, Input, AfterViewInit } from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CcCdDropdowns, CcCdSection, PhotoUploadBase } from 'models';
import { PhotoUploadControlService } from 'services';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { ccCdPhotoUploads } from 'utils';
import { PhotosSectionComponent } from '../../shared/photos-section/photos-section.component';
import { CcCdService } from 'pages/cc-cd-pm/cc-cd-pm.service';
import { CommonTextAreaComponent } from '../../../shared/material-wrappers/common-text-area/common-text-area.component';

@Component({
  selector: 'ads-cc-cd-section',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CommonFormInputComponent,
    MatFormFieldModule,
    MatInputModule,
    SelectComponent,
    PhotosSectionComponent,
    CommonTextAreaComponent
],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  providers: [PhotoUploadControlService],
  templateUrl: './cc-cd-section.component.html',
  styleUrl: './cc-cd-section.component.scss',
})
export class CcCdSectionComponent implements AfterViewInit {
  @Input() item?: CcCdSection;
  @Input() ngSectionName = '';
  @Input() ccCdDropdowns?: CcCdDropdowns;
  @Input() photoUploadForm!: FormGroup;
  @Input() section?: FormGroup;

  photoUploads: PhotoUploadBase[] = ccCdPhotoUploads;

  constructor(private readonly ccCdService: CcCdService) {}

  ngAfterViewInit(): void {
    if (this.item) {
      this.ccCdService.loadPhotos(this.item).subscribe((photos) => {
        this.photoUploadForm?.setValue(photos);
      });
    }
  }

  displayEquipmentRating(): boolean {
    return this.item?.overallCondition === 'Poor Condition' || this.item?.overallCondition === 'Failed';
  }

}
