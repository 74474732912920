import { UCSFormSection } from 'forms/security/ucs/ucs.component';

export interface UCSForm {
  ucsModel: string | null;
  ucsManufacturer: string | null;
  ucsManufacturer_Other: string | null;
  overallConditionUCS: string | null;
  overallConditionUCS_Other: string | null;
  quantityComboLocks: string | null;
  quantityOfPortaLocks: string | null;
}

export class UCSTasks {
  checkForProperOperation: number | null;
  inspectForExcessiveWear: number | null;
  cleanAndLubricate: number | null;

  constructor() {
    this.checkForProperOperation = null;
    this.inspectForExcessiveWear = null;
    this.cleanAndLubricate = null;
  }
}

export interface UCSSecurityForm {
  ucsList: UCSFormSection[];
  ucsTasks: UCSTasks;
  comments: string | null;
}
