<ads-radio-prompt
  [isFilterApplied]="isFilterApplied"
  [label]="label"
  [value]="value"
  [options]="filteringOptions"
  [isDesktopView]="true"
  (cancel)="onCancelClick()"
  (confirm)="onConfirmClick()"
  (optionSelected)="onColumnSelected($event)"
>
</ads-radio-prompt>
