import { YesNoNa } from './yes-no-na';

export class ExteriorEvaluation {
  allNa: boolean;
  surchargeStickerPresent: YesNoNa | null;
  depositoryAvailabilityStickerPresent: YesNoNa | null;
  audioInstructionsFunctioning: YesNoNa | null;
  isSoftwareOnsite: YesNoNa | null;
  constructor() {
    this.allNa = false;
    this.surchargeStickerPresent = null;
    this.depositoryAvailabilityStickerPresent = null;
    this.audioInstructionsFunctioning = null;
    this.isSoftwareOnsite = null;
  }
}
