import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environment/environment';
import { Observable, finalize } from 'rxjs';
import { LoaderService } from 'services';

@Injectable()
export class ApimInterceptor implements HttpInterceptor {
  constructor(private readonly loaderService: LoaderService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loaderService.show();

    const authReq = req.clone({
      headers: req.headers.set('Ocp-Apim-Subscription-Key', environment.apiConfig.apimSubscriptionKey),
    });

    return next.handle(authReq).pipe(finalize(() => this.loaderService.hide()));
  }
}
