import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectOption } from 'models';
import { roundDateToNearestQuarter } from 'app/utils/dates';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonFormInputComponent } from "../common-form-input/common-form-input.component";
import { ModalActionsComponent } from "../../modal-actions/modal-actions.component";

@Component({
  selector: 'ads-timepicker',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatIconModule, CommonFormInputComponent, ModalActionsComponent],
  templateUrl: './timepicker.component.html',
  styleUrl: './timepicker.component.scss',
})
export class TimepickerComponent implements OnInit{
  @Input() dateValue = new Date();
  @Input() value = '';
  @Input() disabled = false;
  @Input() label = 'Time';
  @Input() showPicker = false;
  @Output() valueChange = new EventEmitter<string>();
  displayValue = '';
  hour = '';
  minute = '';
  ampm: 'AM' | 'PM' = 'AM';

  hours: SelectOption<string>[] = [
    { name: '01', value: '01' },
    { name: '02', value: '02' },
    { name: '03', value: '03' },
    { name: '04', value: '04' },
    { name: '05', value: '05' },
    { name: '06', value: '06' },
    { name: '07', value: '07' },
    { name: '08', value: '08' },
    { name: '09', value: '09' },
    { name: '10', value: '10' },
    { name: '11', value: '11' },
    { name: '12', value: '12' },
  ];
  minutes: SelectOption<string>[] = [
    { name: '00', value: '00' },
    { name: '15', value: '15' },
    { name: '30', value: '30' },
    { name: '45', value: '45' },
  ];
  ampms: SelectOption<string>[] = [
    { name: 'AM', value: 'AM' },
    { name: 'PM', value: 'PM' },
  ];

  constructor() {
    const now = roundDateToNearestQuarter(new Date());
    this.ampm = new Date().getHours() >= 12 ? 'PM' : 'AM';
    this.minute = now.getMinutes().toString();
    this.minute = this.minute.length === 1 ? `0${this.minute}` : this.minute;
    this.hour = now.getHours() > 12 ? (now.getHours() - 12).toString() : now.getHours().toString();
    this.hour = this.hour.length === 1 ? `0${this.hour}` : this.hour;
  }

  ngOnInit(): void {
    const date = roundDateToNearestQuarter(this.dateValue);
    this.ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    this.minute = date.getMinutes().toString();
    this.minute = this.minute.length === 1 ? `0${this.minute}` : this.minute;
    this.hour = date.getHours() > 12 ? (date.getHours() - 12).toString() : date.getHours().toString();
    this.hour = this.hour.length === 1 ? `0${this.hour}` : this.hour;
    this.timeChanged();
  }

  timeChanged = () => {
    if (this.hour && this.minute && this.ampm) {
      this.value = `${this.hour} ${this.minute} ${this.ampm}`; // We should consider refactor this to a separate interface
      this.displayValue = `${this.hour}:${this.minute} ${this.ampm}`;
      this.valueChange.emit(this.value);
    } else {
      this.valueChange.emit('');
    }
  };

  onTimeChange = () => setTimeout(this.timeChanged);

  onFieldFocus() {
    this.showPicker = true;
  }

  onConfirmTime() {
    this.timeChanged();
    this.hidePicker();
  }

  onCancel() {
    this.hidePicker();
  }

  hidePicker() {
    this.showPicker = false;
  }

  incrementHour() {
    const hour = parseInt(this.hour, 10);
    if (hour === 12) {
      this.hour = '01';
    } else {
      this.hour = (hour + 1).toString().padStart(2, '0');
    }
    if(hour === 11) {
      this.toggleAmPm();
    }
  }

  decrementHour() {
    const hour = parseInt(this.hour, 10);
    if (hour === 1) {
      this.hour = '12';
    } else {
      this.hour = (hour - 1).toString().padStart(2, '0');
    }
    if(hour === 12) {
      this.toggleAmPm();
    }
  }

  incrementMinute() {
    const minute = parseInt(this.minute, 10);
    if (minute === 45) {
      this.minute = '00';
      this.incrementHour();
    } else {
      this.minute = (minute + 15).toString().padStart(2, '0');
    }
  }

  decrementMinute() {
    const minute = parseInt(this.minute, 10);
    if (minute === 0) {
      this.minute = '45';
      this.decrementHour();
    } else {
      this.minute = (minute - 15).toString().padStart(2, '0');
    }
  }

  toggleAmPm() {
    this.ampm = this.ampm === 'AM' ? 'PM' : 'AM';
  }
}

