import { SensorsTasks } from 'models';
import { SecurityAlarmPanelDto } from 'models/api/security/alarm/alarm-panel-dto';
import { SecurityAlarmPanelTasksDto } from 'models/api/security/alarm/alarm-panel-tasks-dto';
import { SecurityBatteriesDto } from 'models/api/security/alarm/batteries-dto';
import { SecurityBatteryTasksDto } from 'models/api/security/alarm/battery-tasks-dto';
import { SecurityHubDto } from 'models/api/security/alarm/hub-dto';
import { SecurityKeypadTasksDto } from 'models/api/security/alarm/keypad-tasks-dto';
import { SecurityKeypadsDto } from 'models/api/security/alarm/keypads-dto';
import { SecurityPathTableSectionDto } from 'models/api/security/alarm/path-table-dto';
import { SecuritySensorsDto } from 'models/api/security/alarm/sensors-dto';
import { SecuritySensorsTasksDto } from 'models/api/security/alarm/sensors-tasks-dto';
import { SecurityWirelessReceiverPanelDto } from 'models/api/security/alarm/wireless-receiver-panel-dto';
import { SecurityZoneExpanderSectionDto } from 'models/api/security/alarm/zone-expander-panel-dto';
import { SecurityAlarmsDto } from 'models/api/security/security';
import { AlarmPanelForm } from 'models/security/alarm/alarmPanels';
import { AlarmPanelTasks } from 'models/security/alarm/alarmPanelTasks';
import { AlarmSecurityForm } from 'models/security/alarm/alarmSecurityForm';
import { BatteriesForm } from 'models/security/alarm/batteries';
import { BatteryTasks } from 'models/security/alarm/batteryTasks';
import { HubSectionForm } from 'models/security/alarm/hubSection';
import { KeypadsForm } from 'models/security/alarm/keypads';
import { KeypadTasks } from 'models/security/alarm/keypadTasks';
import { PathSectionForm } from 'models/security/alarm/pathTableSection';
import { SensorsForm } from 'models/security/alarm/sensors';
import { WireLessReceiverSectionForm } from 'models/security/alarm/wirelessReceiverSection';
import { ZoneExpanderSectionForm } from 'models/security/alarm/zoneExpanderSection';

export const mapAlarmPanels = (panels: AlarmPanelForm[]): SecurityAlarmPanelDto[] => {
  return panels.map((panel) => ({
    alarmModel: panel.alarmModel,
    account: panel.accountNumber,
    alarmManufacturer: panel.manufacturer,
    alarmManufacturer_Other: panel.otherManufacturer,
    centralStation: panel.centralStation,
    centralStation_Other: panel.otherCentralStation,
    equipment_Rating: panel.otherEquipmentRating,
    firmwareVersion: panel.firmwareVersion,
    ipAddress: panel.ipAddress,
    meid: panel.meId,
    overallcondition: panel.overallCondition,
    phoneNumber: panel.phoneNumber ? panel.phoneNumber.toString() : null,
    totalZonesAvailableForUse: panel.zonesAmount ? panel.zonesAmount.toString() : null,
    totalZonesInAlarmPanel: panel.alarmPanelZonesAmount ? panel.alarmPanelZonesAmount.toString() : null,
  }));
};

export const mapPathTableSections = (panels: PathSectionForm[]): SecurityPathTableSectionDto[] => {
  return panels.map((panel) => ({
    commType: panel.communicationType,
    pathType: panel.pathType,
    path: panel.path,
    receiverAddressNumber: panel.receiverAddress,
  }));
};

export const mapWirelessReceiverSections = (
  panels: WireLessReceiverSectionForm[]
): SecurityWirelessReceiverPanelDto[] => {
  return panels.map((panel) => ({
    dateBatteriesWereChangedInContacts: panel.lastBatterChangeContacts?.toLocaleDateString('en-US') ?? null,
    dateBatteriesWereChangedInHoldupButtons: panel.lastBatterChangeButtons?.toLocaleDateString('en-US') ?? null,
    dateBatteriesWereChangedInMoneyClips: panel.lastBatterChangeMoneyClips?.toLocaleDateString('en-US') ?? null,
    dateBatteriesWereChangedInMotions: panel.lastBatterChangeMotions?.toLocaleDateString('en-US') ?? null,
    locationOfWirelessReceiver: panel.location,
    wirelessReceiverManufacturer: panel.manufacturer,
    wirelessReceiverModel: panel.model,
  }));
};

export const mapZoneExpanderSections = (panels: ZoneExpanderSectionForm[]): SecurityZoneExpanderSectionDto[] => {
  return panels.map((panel) => ({
    locationOfZoneExpander: panel.location,
  }));
};

export const mapAlarmPanelTasks = (panelTasks: AlarmPanelTasks): SecurityAlarmPanelTasksDto[] => [
  {
    inspecAllWireAndPCBConnectionInControlPane: panelTasks.wireAndPcbInspection ?? null,
    isProgrammingSheetWithControlPanel: panelTasks.isProgrammingSheetPresent ?? null,
    monitoringSheetWithControlPane: panelTasks.isMonitoringSheetPresent ?? null,
    testCellularComms: panelTasks.testCellularComms ?? null,
    testControlPanelTamperSwitch: panelTasks.testTamperSwith ?? null,
    testIPComms: panelTasks.testIpComms ?? null,
    testPhoneComms: panelTasks.testPhoneComms ?? null,
    testPhoneCommsLossDetection: panelTasks.testPhoneComsLoss ?? null,
    verifiedReceiverPrimaryDialerPhone: panelTasks.verifyPrimaryDialer ?? null,
    verifiedSecondaryReceiverPhone: panelTasks.verifySecondaryReceiver ?? null,
  },
];

export const mapBatteries = (batteries: BatteriesForm): SecurityBatteriesDto[] => [
  {
    batteryVoltageAfter1HourPowerLoss: batteries.voltageTestResult ?? null,
    dateOfBatteryInstallation: batteries.installation ? batteries.installation?.toLocaleDateString('en-US') : null,
    dateOfSecondaryPowerSupplyInstallation: batteries.secondaryBatteryInstallation
      ? batteries.secondaryBatteryInstallation?.toLocaleDateString('en-US')
      : null,
    quantityOfBatteriesInstalled: batteries.batteriesAmount ?? null,
    secondaryPowerSupplyInstalled: batteries.secondaryPowerSupply ?? null,
  },
];

export const mapBatteryTasks = (batteryTasks: BatteryTasks): SecurityBatteryTasksDto[] => [
  {
    testACLossDetection: batteryTasks.acLossTest ?? null,
    testBatteryLossDetection: batteryTasks.batteryLossTest ?? null,
  },
];

export const mapKeypadTasks = (batteryTasks: KeypadTasks): SecurityKeypadTasksDto[] => [
  {
    testAck: batteryTasks.ack70Test ?? null,
    testDuressCode: batteryTasks.duressCodeTest ?? null,
    testKeypadTemper: batteryTasks.keypadTamperTest ?? null,
  },
];

export const mapSensorsTasks = (sensorsTasks: SensorsTasks): SecuritySensorsTasksDto[] => [
  {
    testATMDoorContact: sensorsTasks.testAtmDoorContact,
    testATMHeatSensors: sensorsTasks.testAtmHeatSensors,
    testATMVib: sensorsTasks.testAtmVib,
    testCashContainerContacts: sensorsTasks.testCashContainerContacts,
    testCashContainerHeatSensors: sensorsTasks.testCashContainerHeatSensors,
    testCashContainerVibes: sensorsTasks.testCashContainerVibes,
    testMotionSensors: sensorsTasks.testMotionSensors,
    testNightDropContacts: sensorsTasks.testNightDropContacts,
    testNightDropHead: sensorsTasks.testNightDropHead,
    testNightDropHeatSensors: sensorsTasks.testNightDropHeatSensors,
    testNightDropVib: sensorsTasks.testNightDropVib,
    testPremiseDoorContacts: sensorsTasks.testPremiseDoorContacts,
    testTCR_TCDDoorContacts: sensorsTasks.testTcrTcdDoorContacts,
    testTCR_TCDHeatSensors: sensorsTasks.testTcrTcdHeatSensors,
    testTCR_TCDVib: sensorsTasks.testTcrTcdVib,
    testVaultAudioSensors: sensorsTasks.testVaultAudioSensors,
    testVaultDoor: sensorsTasks.testVaultDoor,
    testVaultDoorHeatSensors: sensorsTasks.testVaultDoorHeatSensors,
    verifiedAtmVibSettingATM: sensorsTasks.verifiedAtmVibSetting,
    verifiedNightDropVibSettings: sensorsTasks.verifiedNightDropVibSettings,
    verifiedTcd_TcrVibSettingTCD_TCR: sensorsTasks.verifiedTcdTcrVibSetting,
    verifiedTestCashVibSetting: sensorsTasks.verifiedTestCashVibSetting,
  },
];

export const mapSensors = (sensors: SensorsForm): SecuritySensorsDto[] => [
  {
    quantityOfArmedCashContainers: sensors.armedCashContainers ? Number.parseInt(sensors.armedCashContainers) : null,
    quantityOfATMs: sensors.atmsAmount ? Number.parseInt(sensors.atmsAmount) : null,
    quantityOfAudioSensors: sensors.audioSensors ? Number.parseInt(sensors.audioSensors) : null,
    quantityOfMotionSensorsHardwired: sensors.hardwiredMotionSensors
      ? Number.parseInt(sensors.hardwiredMotionSensors)
      : null,
    quantityOfMotionSensorsWireless: sensors.wirelessMotionSensors
      ? Number.parseInt(sensors.wirelessMotionSensors)
      : null,
    quantityOfNightDropContactsHardwired: sensors.hardwiredNightDropContacts
      ? Number.parseInt(sensors.hardwiredNightDropContacts)
      : null,
    quantityOfNightDropContactsWireless: sensors.wirelessNightDropContacts
      ? Number.parseInt(sensors.wirelessNightDropContacts)
      : null,
    quantityOfPremiseDoorContactsHardwired: sensors.hardWiredDoorContacts
      ? Number.parseInt(sensors.hardWiredDoorContacts)
      : null,
    quantityOfPremiseDoorContactsWireless: sensors.wirelessDoorContacts
      ? Number.parseInt(sensors.wirelessDoorContacts)
      : null,
    quantityOfTCRTCD: sensors.tcdTcrAmount ? Number.parseInt(sensors.tcdTcrAmount) : null,
    quantityOfVaultDoorContacts: sensors.vaultDoorContacts ? Number.parseInt(sensors.vaultDoorContacts) : null,
  },
];

export const mapKeypads = (batteries: KeypadsForm): SecurityKeypadsDto[] => [
  {
    quantityOfKepadsWithoutZones: batteries.keypadsWithoutZones ? Number.parseInt(batteries.keypadsWithoutZones) : null,
    quantityOfKepadsWithZones: batteries.keypadsWithZones ? Number.parseInt(batteries.keypadsWithZones) : null,
  },
];

export const mapAlarmRequest = (form: AlarmSecurityForm | null): SecurityAlarmsDto => ({
  security_Alarm: form ? {
    security_Alarm_AlarmPanels: mapAlarmPanels(form?.alarmPanels),
    security_Alarm_PathTables: mapPathTableSections(form?.pathTableSections),
    security_Alarm_Wireless_Receivers: mapWirelessReceiverSections(form?.wirelessReceiverSections),
    security_Alarm_ZoneExpanders: mapZoneExpanderSections(form?.zoneExpanderSections),
    security_Alarm_PanelTasks: mapAlarmPanelTasks(form?.alarmPanelTasks),
    security_Alarm_Batteries: mapBatteries(form?.batteries),
    security_Alarm_BatteryTasks: mapBatteryTasks(form?.batteryTasks),
    security_Alarm_Keypads: mapKeypads(form?.keypads),
    security_Alarm_KeypadTasks: mapKeypadTasks(form?.keypadTasks),
    security_Alarm_Sensors: mapSensors(form?.sensors),
    security_Alarm_SensorTasks: mapSensorsTasks(form?.sensorsTasks),
    security_Alarm_HubTasks_TellerHoldUpTypes: mapHubsTasks(form?.hubSections),
    security_Alarm_HubTasks_PlatformHoldupTypes: mapHubsTasks(form?.platformSections),
    platformHoldUpTypes_AreHoldUpDevicesZonedOut: form.platformTasks.areDevicesZonedOut,
    platformHoldUpTypes_TestTellerHoldUpTypes: form.platformTasks.testHoldUpDevices,
    tellerHoldUpTypes_TestTellerHoldUpTypes: form.tellerTasks.testHoldUpDevices,
    tellerHoldUpTypes_AreHoldUpDevicesZonedOut: form.tellerTasks.areDevicesZonedOut,
    additionalComments: form.comments,
  }: {
    security_Alarm_AlarmPanels: [],
    security_Alarm_PathTables: [],
    security_Alarm_Wireless_Receivers: [],
    security_Alarm_ZoneExpanders: [],
    security_Alarm_PanelTasks: [],
    security_Alarm_Batteries: [],
    security_Alarm_BatteryTasks: [],
    security_Alarm_Keypads: [],
    security_Alarm_KeypadTasks: [],
    security_Alarm_Sensors: [],
    security_Alarm_SensorTasks: [],
    security_Alarm_HubTasks_TellerHoldUpTypes: [],
    security_Alarm_HubTasks_PlatformHoldupTypes: [],
    platformHoldUpTypes_AreHoldUpDevicesZonedOut: null,
    platformHoldUpTypes_TestTellerHoldUpTypes: null,
    tellerHoldUpTypes_TestTellerHoldUpTypes: null,
    tellerHoldUpTypes_AreHoldUpDevicesZonedOut: null,
    additionalComments: null,
  },
});

export const mapHubsTasks = (panels: HubSectionForm[]): SecurityHubDto[] => {
  return panels.map((panel) => ({
    quantity: panel.quantity ? Number.parseInt(panel.quantity) : null,
    type: panel.type,
  }));
};
