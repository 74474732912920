import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service'; // Adjust the path as necessary
import { routesMapADS } from 'app/app.routes';
import { environment } from 'environment/environment';
import { FeatureManagerService } from './feature-manager.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { EmbeddedMenuFeatureFlagKey, PmFormsFeatureFlagKey, NewRmaFeatureFlagKey, DefectivePartsFeatureFlagKey } from 'utils';
import { UserService } from './user.service';

enum MessageTypes {
  acquireTokenSilent = 'acquireTokenSilent',
  openPmForm = 'openPmForm',
  logout = 'logout',
  navigate = 'navigate',
  getFeatureFlags = 'getFeatureFlags',
  sendMail = 'sendMail',
  getEmployeeDetail = 'getEmployeeDetail',
}

@Injectable({
  providedIn: 'root',
})
export class IframeMessageService {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly featureManagerService: FeatureManagerService,
    private readonly userService: UserService,
  ) {
    this.initializeMessageListener();
  }

  private initializeMessageListener() {
    window.addEventListener('message', this.handleMessageEvent.bind(this));
  }

  public iframeNavigateTo(url: string) {
    (document.getElementById('ads-frame-component') as HTMLIFrameElement)?.contentWindow?.postMessage(
      { messageType: 'navigate', url },
      environment.apiConfig.adsUrl
    );
  }

  private sendFeatureFlag(flagKey: string, flagValue: boolean) {
    (document.getElementById('ads-frame-component') as HTMLIFrameElement)?.contentWindow?.postMessage(
      { messageType: 'setFeatureFlag', flagKey, flagValue },
      environment.apiConfig.adsUrl
    );
  }

  private async handleMessageEvent(event: MessageEvent) {
    if (event.origin === environment.apiConfig.adsUrl) {
      switch (event.data.messageType) {
        case MessageTypes.acquireTokenSilent: {
          const result = await this.authService.refreshToken();
          (document.getElementById('ads-frame-component') as HTMLIFrameElement)?.contentWindow?.postMessage(
            { messageType: 'acquireTokenSilent', authTokenRequest: result },
            environment.apiConfig.adsUrl
          );
          break;
        }
        case MessageTypes.openPmForm: {
          const url = this.router.createUrlTree([`/PM/${routesMapADS(event.data.formType)}/${event.data.workOrder}`]);
          window.open(url.toString(), '_blank');
          break;
        }
        case MessageTypes.logout: {
          this.authService.logout();
          window.location.href = '/';
          break;
        }
        case MessageTypes.navigate: {
          this.router.navigate([event.data.fragment]);
          break;
        }
        case MessageTypes.getFeatureFlags: {
          const menuFlagValue = await firstValueFrom(this.featureManagerService.pwaEmbeddedMenu$);
          this.sendFeatureFlag(EmbeddedMenuFeatureFlagKey, menuFlagValue);
          const formsFlagValue = await firstValueFrom(this.featureManagerService.pwaPmForms$);
          this.sendFeatureFlag(PmFormsFeatureFlagKey, formsFlagValue);
          const rmaFlagValue = await firstValueFrom(this.featureManagerService.pwaNewRma$);
          this.sendFeatureFlag(NewRmaFeatureFlagKey, rmaFlagValue);
          const defectiveFlagValue = await firstValueFrom(this.featureManagerService.pwaDefectiveParts$);
          this.sendFeatureFlag(DefectivePartsFeatureFlagKey, defectiveFlagValue);
          break;
        }
        case MessageTypes.sendMail: {
          try {
            await this.authService.sendMail(event.data);

            (document.getElementById('ads-frame-component') as HTMLIFrameElement)?.contentWindow?.postMessage(
              { messageType: 'sendMail' },
              environment.apiConfig.adsUrl
            );
          } catch (error) {
            (document.getElementById('ads-frame-component') as HTMLIFrameElement)?.contentWindow?.postMessage(
              { messageType: 'sendMail', data: error },
              environment.apiConfig.adsUrl
            );
          }
          break;
        }
        case MessageTypes.getEmployeeDetail: {
          const employeeDetail = await lastValueFrom(this.userService.employeeDetail());
          (document.getElementById('ads-frame-component') as HTMLIFrameElement)?.contentWindow?.postMessage(
            { messageType: MessageTypes.getEmployeeDetail, data: employeeDetail },
            environment.apiConfig.adsUrl
          );
          break;
        }
      }
    }
  }
}
