<table mat-table [dataSource]="list" *ngIf="list.length > 0">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? toggleAllRows(list, selection) : null"
        [checked]="selection.hasValue() && isAllSelected(list, selection)"
        [indeterminate]="selection.hasValue() && !isAllSelected(list, selection)"
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        color="#b3261e"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="upsManufacturer">
    <th mat-header-cell *matHeaderCellDef>Manufacturer</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-bottom-sheet-input
        [ngModelGroup]="'upsItem#' + (i + 1)"
        name="upsManufacturer"
        [required]="true"
        [value]="element.upsManufacturer"
        [(ngModel)]="element.upsManufacturer"
        [openBottomSheet]="openBottomSheet(element)"
      ></ads-table-form-bottom-sheet-input>
      <div style="margin-bottom: 6px" *ngIf="displayOtherManufacturer(element)">
        <ads-table-form-input
          [ngModelGroup]="'upsItem#' + (i + 1)"
          name="upsManufacturer_Other"
          [maxLength]="50"
          [required]="true"
          [value]="element.upsManufacturer_Other"
          [(ngModel)]="element.upsManufacturer_Other"
        ></ads-table-form-input>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="upsModel">
    <th mat-header-cell *matHeaderCellDef>Model</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-input
        [ngModelGroup]="'upsItem#' + (i + 1)"
        name="upsModel"
        [maxLength]="50"
        [required]="true"
        [value]="element.upsModel"
        [(ngModel)]="element.upsModel"
      ></ads-table-form-input>
    </td>
  </ng-container>

  <ng-container matColumnDef="numberOfOutputs">
    <th mat-header-cell *matHeaderCellDef>Number of Outputs</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-input
        [ngModelGroup]="'upsItem#' + (i + 1)"
        name="numberOfOutputs"
        [maxLength]="50"
        [required]="true"
        [value]="element.numberOfOutputs"
        [(ngModel)]="element.numberOfOutputs"
      ></ads-table-form-input>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumnsKeys()"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsKeys()"></tr>
</table>
