import { CompleteNa } from '../shared/complete-na';

export class TcdTcrCommonTasks {
  allNa: boolean;
  checkSensors: CompleteNa | null;
  foreignMaterial: CompleteNa | null;
  checkRollers: CompleteNa | null;
  checkBelts: CompleteNa | null;
  diagnosticTests: CompleteNa | null;

  constructor() {
    this.allNa = false;
    this.checkSensors = null;
    this.foreignMaterial = null;
    this.checkRollers = null;
    this.checkBelts = null;
    this.diagnosticTests = null;
  }
}
