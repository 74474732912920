import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, NgModelGroup } from '@angular/forms';
import { DealDrawerFormSection } from '../deal-drawer.component';
import { CommonModule } from '@angular/common';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { SelectOption } from 'models';
import { MatDivider } from '@angular/material/divider';
import { SimpleFormComponent } from '../../../../shared/simple-form/simple-form.component';
import { CommonTextAreaComponent } from '../../../../shared/material-wrappers/common-text-area/common-text-area.component';

@Component({
  selector: 'ads-deal-drawer-section-form',
  standalone: true,
  imports: [CommonModule, FormsModule, CommonFormInputComponent, MatDivider, SelectComponent, SimpleFormComponent, CommonTextAreaComponent],
  templateUrl: './deal-drawer-section-form.component.html',
  styleUrl: './deal-drawer-section-form.component.scss',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class DealDrawerSectionFormComponent {
  @Input() dealDrawerLists?: DealDrawerLists;
  @Input() section?: FormGroup;
  @Input() item?: DealDrawerFormSection;

  displayOtherManufacturer = () => {
    return Boolean(this.item?.dealDrawerMake === 'Other');
  };

  displayEquipmentRating(): boolean {
    return this.item?.overallCondition === 'Poor Condition' || this.item?.overallCondition === 'Failed';
  }
}

export interface DealDrawerLists {
  manufacturerList: SelectOption<string | number>[];
  conditionList: SelectOption<string | number>[];
}
