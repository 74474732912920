import { NetworkLogo } from './network-logo';

export class NetworkLogos {
  logos: NetworkLogo[];
  other: string;
  constructor() {
    this.logos = [];
    this.other = '';
  }
}
