<button
  mat-button
  class="input-button"
  type="button"
  (click)="openBottomSheet()"
  [style]="{ maxWidth: maxWidth }"
  disableRipple
>
  <mat-form-field appearance="outline" hideRequiredMarker>
    <input
      #customInput="ngModel"
      matInput
      adsRequired
      [checkRequired]="required"
      [required]="required"
      [disabled]="getDisabled() ? 'disabled' : false"
      [name]="name"
      [maxLength]="maxLength"
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="value"
      (ngModelChange)="onModelChange($event)"
      (blur)="markAsTouched()"
      [readonly]="true"
      style="visibility: hidden; width: 0"
    />
    <div class="input-container">
      <div class="input-text">{{ getValue() }}</div>
    </div>
  </mat-form-field>
  <mat-icon class="input-arrow">arrow_drop_down</mat-icon>
</button>
