export interface SortingOption<T> {
  name: string;
  value: string;
  ascLabel: string;
  descLabel: string;
  compare?: (a: T, b: T) => number;
}

export const newSortingOption = <T>(
  name: string,
  value: string,
  ascLabel: string,
  descLabel: string,
  compare?: (a: T, b: T) => number
): SortingOption<T> => ({
  name,
  value,
  ascLabel,
  descLabel,
  compare,
});
