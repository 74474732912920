import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MultipleFormsSectionComponent } from 'forms/shared/multiple-forms-section/multiple-forms-section.component';
import { LoaderService, PhotoUploadControlService } from 'services';
import { HeaderService } from 'shared/header/header.service';
import { StickyHeaderService } from 'shared/sticky-header/sticky-header.service';
import { TcdTcrService } from './tcd-tcr-pm.service';
import { OrderInfoComponent } from 'shared/order-info/order-info.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { TcdTcrSectionComponent } from '../../forms/tcd-tcr/tcd-tcr-section/tcd-tcr-section.component';
import { mapTcdTcrSection, mapTcrDropdowns, mapTcrForm } from 'models/api/maps/tcd-tcr/tcd-tcr-map';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { TcdTcrTasksComponent } from 'forms/tcd-tcr/tcd-tcr-tasks/tcd-tcr-tasks.component';
import { TcdTcrCassetteTasksComponent } from '../../forms/tcd-tcr/tcd-tcr-cassettes-tasks/tcd-tcr-cassettes-tasks.component';
import { TcdTcrFeedModuleTasksComponent } from '../../forms/tcd-tcr/tcd-tcr-feed-module-tasks/tcd-tcr-feed-module-tasks.component';
import { TcdTcrInputModuleTasksComponent } from '../../forms/tcd-tcr/tcd-tcr-input-module-tasks/tcd-tcr-input-module-tasks.component';
import { TcdTcrExternalAreaTasksComponent } from '../../forms/tcd-tcr/tcd-tcr-external-area-tasks/tcd-tcr-external-area-tasks.component';
import { TcdTcrVacuumingBlowOutComponent } from '../../forms/tcd-tcr/tcd-tcr-vacuuming-blow-out/tcd-tcr-vacuuming-blow-out.component';
import { tcdTcrPhotoUploads } from 'app/utils/tcd-tcr-form-constants';
import { CommonTextAreaComponent } from '../../shared/material-wrappers/common-text-area/common-text-area.component';
import { TcdTcrDropdowns } from 'models/atm/tcd-tcr-dropdowns';
import { photosStatuses } from 'app/utils/photo-status';
import { TcdTcrFormSection } from './tcd-tcr.models';
import { TcdTcrForm } from 'models/api/tcd-tcr/tcd-tcr-form';
import { TcdTcrResponse, TcdTcrResponseDropdowns } from 'models/api/tcd-tcr/tcd-tcr-response';
import { FormPageComponent } from 'pages/form-page/form-page';
import { PageOpenMode, PageSubmitMode } from 'models/api';
import { RoutesEnum } from 'app/utils/navigation';

@Component({
  selector: 'ads-tcr-cleaning-pm',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MultipleFormsSectionComponent,
    OrderInfoComponent,
    MatFormFieldModule,
    MatInputModule,
    MatButton,
    TcdTcrSectionComponent,
    TcdTcrTasksComponent,
    ExpansionPanelComponent,
    TcdTcrCassetteTasksComponent,
    TcdTcrFeedModuleTasksComponent,
    TcdTcrInputModuleTasksComponent,
    TcdTcrExternalAreaTasksComponent,
    TcdTcrVacuumingBlowOutComponent,
    CommonTextAreaComponent,
  ],
  templateUrl: './tcd-tcr-pm.component.html',
  styleUrl: './tcd-tcr-pm.component.scss',
  providers: [PhotoUploadControlService],
})
export class TcdTcrPmComponent
  extends FormPageComponent<TcdTcrService, TcdTcrForm, TcdTcrResponse, TcdTcrDropdowns, TcdTcrResponseDropdowns>
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild('tcdTcrForm', { static: true }) override form?: NgForm;
  @ViewChild('tcdTcrSectionsPanels', { static: true }) tcdTcrSectionsPanels?: ExpansionPanelComponent;
  @ViewChild('tdrTcrTasksSectionPanel', { static: true }) tcdTcrTasksPanel?: ExpansionPanelComponent;
  @ViewChild('cassetteTasksSectionPanel', { static: true }) cassetteTasksPanel?: ExpansionPanelComponent;
  @ViewChild('feedModuleTasksSectionPanel', { static: true }) feedModuleTasksPanel?: ExpansionPanelComponent;
  @ViewChild('inputModuleTasksSectionPanel', { static: true }) inputModuleTasksPanel?: ExpansionPanelComponent;
  @ViewChild('externalAreaTasksSectionPanel', { static: true }) externalAreaPanel?: ExpansionPanelComponent;
  @ViewChild('vacuumingBlowOutSectionPanel', { static: true }) tcdTcrVacuumingBlowOutPanel?: ExpansionPanelComponent;

  expandedList: number[] = [];

  override mapForm = mapTcrForm;
  override mapDropdowns = mapTcrDropdowns;
  override photoUploads = tcdTcrPhotoUploads;
  override overrideLoadForm = (data: TcdTcrResponse) => this.loadTcdTcrForm(data);
  override sectionNames = [
    'tcdTcrs',
    'tcdTcrTasks',
    'tcdTcrFeedModuleTasks',
    'tcdTcrPresentModuleTasks',
    'tcdTcrExternalAreaTasks',
    'tcdTcrVacuumingBlowOutTasks',
  ];
  override sections = {};

  constructor(
    public readonly tcdTcrService: TcdTcrService,
    headerService: HeaderService,
    route: ActivatedRoute,
    router: Router,
    stickyHeaderService: StickyHeaderService,
    loaderService: LoaderService,
    snackBar: MatSnackBar,
    private readonly pucs: PhotoUploadControlService
  ) {
    super(tcdTcrService, headerService, router, stickyHeaderService, loaderService, route, snackBar);
  }

  ngOnInit() {
    if (this.getIsFormSubmitted()) {
      this.form?.form.disable();
    } else {
      this.headerService.resetForm = this.resetForm;
      this.headerService.showResetFormButton.set(true);
    }

    this.sections = {
      tcdTcrSections: this.tcdTcrSectionsPanels,
      tcdTcrTasks: this.tcdTcrTasksPanel,
      tcdTcrCassetteTasks: this.cassetteTasksPanel,
      tcdTcrFeedModuleTasks: this.feedModuleTasksPanel,
      tcdTcrInputModuleTasks: this.inputModuleTasksPanel,
      tcdTcrExternalAreaTasks: this.externalAreaPanel,
      tcdTcrVacuumingBlowOut: this.tcdTcrVacuumingBlowOutPanel,
    };
  }

  ngAfterViewInit() {
    this.form?.control.valueChanges.subscribe((values) => (this.tcdTcrService.form = values));
    this.headerService.resetForm = this.resetForm;
    this.headerService.title.set('TCD/TCR Form');
    setTimeout(() => this.setDefault());
  }

  ngOnDestroy(): void {
    this.stickyHeaderService.enabled = false;
  }

  onAddClick() {
    const maxIndex =
      this.service.tcdTcrSections.length === 0
        ? 1
        : Math.max(...this.service.tcdTcrSections.map((item) => item.index)) + 1;
    this.service.tcdTcrSections.push(new TcdTcrFormSection(maxIndex));
    this.service.photoUploadForms.push(this.pucs.toFormGroup(tcdTcrPhotoUploads));
  }

  getPhotosStatuses = () => photosStatuses(this.service.photoUploadForms);

  getSection(sectionName: string) {
    if (sectionName.includes('tcdTcrSection') && this.getIsFormSubmitted()) {
      this.form?.controls[sectionName]?.disable();
    }
    return this.form?.controls[sectionName] as FormGroup | undefined;
  }

  onRemoveTcr(item: TcdTcrFormSection) {
    const indexAt = this.service.tcdTcrSections.indexOf(item);
    this.service.tcdTcrSections.splice(indexAt, 1);
    this.service.tcdTcrSections = this.service.tcdTcrSections.map((x, index) => ({ ...x, index: index + 1 }));
    this.service.photoUploadForms.splice(indexAt, 1);
  }

  loadTcdTcrForm = (data: TcdTcrResponse) => {
    this.tcdTcrService.allQtyOptions = data.dropdowns.allNumberOfCassettes;
    data.tcdTcr.tcdTcr_TcdTcr.forEach((item, index) => {
      this.service.tcdTcrSections.push(mapTcdTcrSection(item, index + 1));
      this.service.photoUploadForms.push(this.pucs.toFormGroup(tcdTcrPhotoUploads));
    });
    this.form?.form.setValue({
      ...this.tcdTcrService.form,
      tcdTcrSections: {},
    });
  };

  override scrollToFirstInvalidNestedSection = (key: string) => {
    if (key === 'tcdTcrs') {
      const sections = this.service.tcdTcrSections.map((x) => [this.getSection('tcdTcrSections#' + x.index), x.index]);
      const invalidSections = sections.filter((x) => (x[0] as FormGroup).invalid);
      const invalidPhotos = this.service.photoUploadForms
        .map((x, idx) => (x.invalid ? idx + 1 : -1))
        .filter((x) => x !== -1);
      if (invalidSections.length > 0 || invalidPhotos.length > 0) {
        this.expandedList = invalidSections.map((x) => x[1] as number);
        this.expandedList.push(...invalidPhotos);
        return this.expandedList[0];
      }
    }
    return -1;
  };

  override saveForm = () =>
    this.sendForm(PageSubmitMode.PAGE_SUBMIT_MODE_SAVE, () => {
      this.openSnackBar('TCD/TCR Form saved successfully!', 'Close');
    });

  override onSubmit = () => {
    if (!this.workOrder) {
      return;
    }
    this.form?.form.markAllAsTouched();
    this.service.photoUploadForms.forEach((x) => x.markAllAsTouched());
    if (!this.form?.valid) {
      this.scrollToFirstInvalidSection();
      return;
    }
    this.sendForm(PageSubmitMode.PAGE_SUBMIT_MODE_SUBMIT, () =>
      this.router.navigate([`${RoutesEnum.Submitted}/${RoutesEnum.Atm}`])
    );
  };

  override sendForm = (pageSubmitMode: PageSubmitMode, callback: () => void) => {
    if (!this.workOrder) {
      return;
    }
    this.tcdTcrService
      .saveForm({
        comments: this.form?.value.comments,
        workOrderDetail: this.workOrder,
        pageSubmitMode: pageSubmitMode,
        pageOpenMode: this.workOrder.workStatus === null ? PageOpenMode.PAGE_MODE_ADD : PageOpenMode.PAGE_MODE_UPDATE,
        photosForm: {},
        fieldsForm: this.service.form,
      })
      .subscribe(callback);
  }
}
