<ads-custom-prompt
  #mobilePrompt
  [label]="label"
  [ngClass]="{ 'sort-button': isDesktopView }"
  [icon]="icon"
  [focused]="focused"
  [isDesktopView]="isDesktopView"
  (clicked)="onClick()"
  (dismissed)="onDismissed()"
  [mobileContent]="mobileFilter"
></ads-custom-prompt>
<div class="prompt-wrapper">
  <div *ngIf="isDesktopView && focused" class="prompt-container">
    <div class="date-fields">
      <ads-datepicker
        label="From"
        [minDate]="minDate"
        [maxDate]="toDate ?? maxDate"
        (ngModelChange)="fromDateChanged.emit($event)"
        [(ngModel)]="fromDate">
      </ads-datepicker>
      <ads-datepicker
        label="To"
        [minDate]="fromDate"
        (ngModelChange)="toDateChanged.emit($event)"
        [(ngModel)]="toDate">
      </ads-datepicker>
    </div>
    <div class="buttons">
      <ads-custom-button label="Cancel" (clicked)="onCancelClick()"></ads-custom-button>
      <button mat-button mat-flat-button class="confirm" (click)="onConfirmClick()">Confirm</button>
    </div>
  </div>
</div>
<ng-template #mobileFilter>
  <ads-prompt-wrapper (primaryButtonClicked)="onConfirmClick()" (cancelButtonClicked)="onCancelClick()">
    <div class="date-fields">
      <ads-datepicker
        label="From"
        [minDate]="minDate"
        [maxDate]="toDate ?? maxDate"
        (ngModelChange)="fromDateChanged.emit($event)"
        [(ngModel)]="fromDate">
      </ads-datepicker>
      <ads-datepicker
        label="To"
        [minDate]="fromDate"
        (ngModelChange)="toDateChanged.emit($event)"
        [(ngModel)]="toDate">
      </ads-datepicker>
    </div>
  </ads-prompt-wrapper>
</ng-template>
