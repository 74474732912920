import { Component, Input, Optional } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ControlContainer, FormGroup, FormsModule, NgModelGroup } from '@angular/forms';
import { AlarmPanelTasks, CompleteNa, SelectOption, YesNo } from 'models';
import { SingleToggleButtonComponent, RadioButtonGroupComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-alarm-panel-tasks',
  standalone: true,
  imports: [SingleToggleButtonComponent, RadioButtonGroupComponent, SimpleFormComponent, MatDividerModule, FormsModule],
  templateUrl: './alarm-panel-tasks.component.html',
  styleUrl: './alarm-panel-tasks.component.scss',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class AlarmPanelTasksComponent {
  @Input() section?: FormGroup;

  selectAllChecked = false;
  fields = Object.getOwnPropertyNames(new AlarmPanelTasks());

  yesNoOptions: SelectOption<YesNo>[] = [
    { name: 'Yes', value: YesNo.Yes },
    { name: 'No', value: YesNo.No },
  ];

  completeNaOptions: SelectOption<CompleteNa>[] = [
    { name: 'Complete', value: CompleteNa.Complete },
    { name: 'N/A', value: CompleteNa.Na },
  ];

  constructor(@Optional() public readonly ngModelGroup: NgModelGroup) {}
}
