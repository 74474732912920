import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ControlContainer, NgForm, FormGroup } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { SelectOption, YesNo, CompleteNa } from 'models';
import { RadioButtonGroupComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-cc-cd-tasks-section',
  standalone: true,
  imports: [CommonModule, FormsModule, SimpleFormComponent, RadioButtonGroupComponent, MatDividerModule],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './cc-cd-tasks-section.component.html',
})
export class CcCdTasksSectionComponent {
  @Input() section?: FormGroup;

  yesNoOptions: SelectOption<YesNo>[] = [
    { name: 'Yes', value: YesNo.Yes },
    { name: 'No', value: YesNo.No },
  ];

  completeNaOptions: SelectOption<CompleteNa>[] = [
    { name: 'Complete', value: CompleteNa.Complete },
    { name: 'N/A', value: CompleteNa.Na },
  ];
}
