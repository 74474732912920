<ads-simple-form #networkLogos="ngModelGroup" [ngModelGroup]="groupName">
  <mat-button-toggle-group multiple class="two-column-group" name="logos" ngModel>
    @for (item of options; track item) {
    <mat-button-toggle
      class="option"
      [style.width]="'45%'"
      [value]="item.value"
      (click)="onClick(item.value)"
      (keyup)="onKeyPress($event)"
    >
      <span class="label" [ngClass]="{ selected: isItemSelected(item.value) }">{{ item.name }}</span>
    </mat-button-toggle>
    }
  </mat-button-toggle-group>
  <ads-common-form-input
    class="comment"
    [maxLength]="50"
    label="Other networks"
    [required]="false"
    [(ngModel)]="other"
    name="other"
  ></ads-common-form-input>
</ads-simple-form>
