import { DatePipe } from '@angular/common';
import { AdsNoteRequestModel, DocuRestriction, NoteTableName } from 'models/call-control/ack-schedule-models';

export const buildNote = (description: string, notes: string, refRecId?: number, recId?: number, userId?: string, restriction = DocuRestriction.External) => [
  {
    description: description,
    notes: notes,
    refTableId: 287,
    typeId: 'Note',
    refRecId: refRecId,
    docuRestriction: restriction,
    createdBy: userId,
    recId: recId,
    tableName: NoteTableName.TableName,
  } as AdsNoteRequestModel,
];

export const newSlaNoteText = (
  slaDueDate: Date,
  dateTimeEntered: Date,
  reasonCodeValue: string,
  otherValue: string
): string => {
  const datepipe: DatePipe = new DatePipe('en-US');
  const finalReasonText = reasonCodeValue === 'Other' ? reasonCodeValue + '(' + otherValue + ')' : reasonCodeValue;
  const note =
    `SLA due date- ${datepipe.transform(slaDueDate, 'MM-dd-yyyy hh:mm a')}` +
    ` | Scheduled date- ${datepipe.transform(dateTimeEntered, 'MM-dd-yyyy hh:mm a')}` +
    ` | Reason code- ${finalReasonText}`;
  return note;
};
