import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ItmCleaning, ItmCleaningDropdowns } from 'models';
import { LoaderService, PhotoUploadControlService } from 'services';
import { ItmCleaningService } from './itm-cleaning.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { ItmCleaningResponse } from 'models/api';
import { itmCleaningPhotoUploads } from 'app/utils/itm-cleaning-form-constants';
import { HeaderService } from 'shared/header/header.service';
import { OrderInfoComponent } from 'shared/order-info/order-info.component';
import { StickyHeaderService } from 'shared/sticky-header/sticky-header.service';
import { mapItmCleaningDropdowns, mapItmCleaningForm } from 'models/api/maps/itm-cleaning/itm-cleaning-map';
import { NetworkLogosComponent, PhotosSectionComponent } from 'forms';
import { ItmFormSectionComponent } from 'forms/itm-cleaning/itm-form-section/itm-form-section.component';
import { ItmExteriorEvaluationComponent } from 'forms/itm-cleaning/itm-exterior-evaluation/itm-exterior-evaluation.component';
import { ItmCleaningTasksComponent } from 'forms/itm-cleaning/itm-cleaning-tasks/itm-cleaning-tasks.component';
import { ItmReplenishmentTasksComponent } from 'forms/itm-cleaning/itm-replenishment-tasks/itm-replenishment-tasks.component';
import { CommonTextAreaComponent } from '../../shared/material-wrappers/common-text-area/common-text-area.component';
import { ItmCleaningResponseDropdowns } from 'models/api/itm-cleaning/itm-cleaning-response';
import { FormPageComponent } from 'pages/form-page/form-page';

@Component({
  selector: 'ads-itm-cleaning-pm',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    ExpansionPanelComponent,
    FormsModule,
    OrderInfoComponent,
    NetworkLogosComponent,
    PhotosSectionComponent,
    ItmFormSectionComponent,
    ItmExteriorEvaluationComponent,
    ItmCleaningTasksComponent,
    ItmReplenishmentTasksComponent,
    CommonTextAreaComponent,
  ],
  templateUrl: './itm-cleaning-pm.component.html',
  styleUrl: './itm-cleaning-pm.component.scss',
  providers: [PhotoUploadControlService],
})
export class ItmCleaningPmComponent
  extends FormPageComponent<
    ItmCleaningService,
    ItmCleaning,
    ItmCleaningResponse,
    ItmCleaningDropdowns,
    ItmCleaningResponseDropdowns
  >
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild('itmForm', { static: true }) override form?: NgForm;
  @ViewChild('itmSectionPanel', { static: true }) itm?: ExpansionPanelComponent;
  @ViewChild('itmExteriorEvaluationSectionPanel', { static: true }) exteriorEvaluation?: ExpansionPanelComponent;
  @ViewChild('itmNetworkLogosSectionPanel', { static: true }) networkLogos?: ExpansionPanelComponent;
  @ViewChild('itmCleaningTasksSectionPanel', { static: true }) cleaningTasks?: ExpansionPanelComponent;
  @ViewChild('itmReplenishmentTasksSectionPanel', { static: true }) replenishmentTasks?: ExpansionPanelComponent;
  @ViewChild('itmPhotosSectionPanel', { static: true }) photos?: ExpansionPanelComponent;

  override sections = {};

  override sectionNames = [
    'itmSection',
    'itmExteriorEvaluation',
    'itmNetworkLogos',
    'cleaningTasks',
    'replenishmentTasks',
    'photoUploads',
  ];
  override mapForm = mapItmCleaningForm;
  override mapDropdowns = mapItmCleaningDropdowns;
  override photoUploads = itmCleaningPhotoUploads;

  constructor(
    public readonly itmCleaningService: ItmCleaningService,
    protected pucs: PhotoUploadControlService,
    headerService: HeaderService,
    route: ActivatedRoute,
    router: Router,
    stickyHeaderService: StickyHeaderService,
    loaderService: LoaderService,
    snackBar: MatSnackBar
  ) {
    super(itmCleaningService, headerService, router, stickyHeaderService, loaderService, route, snackBar);
  }

  ngOnDestroy(): void {
    this.stickyHeaderService.enabled = false;
  }

  ngAfterViewInit() {
    this.form?.control.valueChanges.subscribe((values) => (this.itmCleaningService.form = values));
    this.headerService.resetForm = this.resetForm;
    this.headerService.title.set('ITM Cleaning Form');
    setTimeout(() => this.setDefault());
  }

  ngOnInit() {
    this.photoUploadForm = this.pucs.toFormGroup(itmCleaningPhotoUploads);
    this.form?.control.addControl('photoUploads', this.photoUploadForm);
    this.sections = {
      itmSection: this.itm,
      itmExteriorEvaluation: this.exteriorEvaluation,
      itmNetworkLogos: this.networkLogos,
      cleaningTasks: this.cleaningTasks,
      replenishmentTasks: this.replenishmentTasks,
      photoUploads: this.photos,
    };
  }

  getSection(sectionName: string) {
    return this.form?.controls[sectionName] as FormGroup | undefined;
  }
}
