import { NightDropFormSection } from 'forms/security/night-drop/night-drop.component';
import {
  SecurityNightDropComboLockTasksDto,
  SecurityNightDropDropDoorTasksDto,
  SecurityNightDropHeadTasksDto,
  SecurityNightDropSectionDto,
  SecurityNightDropTimeClockTasksDto,
} from 'models/api/security/night-drop/night-drop-dto';
import { SecurityNightDropSectionsDto } from 'models/api/security/security';
import {
  NightDropComboLockTasks,
  NightDropDropDoorTasks,
  NightDropHeadTasks,
  NightDropSecurityForm,
  NightDropTimeClockTasks,
} from 'models/security/night-drop/nightDropSecurityForm';

export const mapNightDropSectionsResponse = (panels: SecurityNightDropSectionDto[]): NightDropFormSection[] => {
  if (!panels || panels.length === 0)
    return [
      {
        nightDropModel: '',
        nightDropManufacturer: '',
        nightDropManufacturer_Other: '',
        isNightDropEncasedInConcrete: null,
        swingType: '',
        overallConditionNightDrop: '',
        equipment_Rating_NightDrops: '',
        nightDropSafeManufacturer: '',
        nightDropSafeManufacturer_Other: '',
        nightDropDoorStyle: '',
        comboLocks: [],
        timeClocks: [],
        index: 1,
      },
    ];

  return panels.map((panel, index) => ({
    nightDropModel: panel.nightDropModel,
    index: index + 1,
    comboLocks: panel.security_NightDrop_ComboLocks.map((comboLock, idx) => ({
      ...comboLock,
      index: idx + 1,
    })),
    timeClocks: panel.security_NightDrop_TimeClocks.map((timeClock, idx) => ({
      ...timeClock,
      index: idx + 1,
    })),
    nightDropManufacturer: panel.nightDropManufacturer,
    nightDropManufacturer_Other: panel.nightDropManufacturer_Other,
    isNightDropEncasedInConcrete: panel.isNightDropEncasedInConcrete,
    swingType: panel.swingType,
    overallConditionNightDrop: panel.overallConditionNightDrop,
    equipment_Rating_NightDrops: panel.equipment_Rating_NightDrops,
    nightDropSafeManufacturer: panel.nightDropSafeManufacturer,
    nightDropSafeManufacturer_Other: panel.nightDropSafeManufacturer_Other,
    nightDropDoorStyle: panel.nightDropDoorStyle,
  }));
};

export const mapNightDropDropDoorTasksResponse = (
  tasks?: SecurityNightDropDropDoorTasksDto
): NightDropDropDoorTasks => {
  return tasks ? {
    checkBoltWorkForProperOperation: tasks.checkBoltWorkForProperOperation,
    inspectBoltWorkForExcessiveWear: tasks.inspectBoltWorkForExcessiveWear,
    cleanAndLubricateBoltWork: tasks.cleanAndLubricateBoltWork,
  } : {
    checkBoltWorkForProperOperation: null,
    inspectBoltWorkForExcessiveWear: null,
    cleanAndLubricateBoltWork: null,
  };
};

export const mapNightDropTimeClockResponse = (tasks?: SecurityNightDropTimeClockTasksDto): NightDropTimeClockTasks => {
  return tasks ? {
    checkTimeLocksForProperOperation: tasks.checkTimeLocksForProperOperation,
    pullRightTimeClockForCleaning: tasks.pullRightTimeClockForCleaning,
    shiftAllTimeClocksRight: tasks.shiftAllTimeClocksRight,
    replaceLeftTimeClock: tasks.replaceLeftTimeClock,
    markAnyReplacedTimeClocksWithDateInstalled: tasks.markAnyReplacedTimeClocksWithDateInstalled,
  } : {
    checkTimeLocksForProperOperation: null,
    pullRightTimeClockForCleaning: null,
    shiftAllTimeClocksRight: null,
    replaceLeftTimeClock: null,
    markAnyReplacedTimeClocksWithDateInstalled: null,
  };
};

export const mapNightDropComboLockResponse = (tasks?: SecurityNightDropComboLockTasksDto): NightDropComboLockTasks => {
  return tasks ? {
    checkComboLockForProperOperation: tasks.checkComboLockForProperOperation,
    disassembleAndInspectComboLock: tasks.disassembleAndInspectComboLock,
    lubricateAndReassembleComboLock: tasks.lubricateAndReassembleComboLock,
    checkForDialAndRingProperOperation: tasks.checkForDialAndRingProperOperation,
    disassembleAndInspectDialAndRing: tasks.disassembleAndInspectDialAndRing,
  } : {
    checkComboLockForProperOperation: null,
    disassembleAndInspectComboLock: null,
    lubricateAndReassembleComboLock: null,
    checkForDialAndRingProperOperation: null,
    disassembleAndInspectDialAndRing: null,
  };
};

export const mapNightDropHeadResponse = (tasks?: SecurityNightDropHeadTasksDto): NightDropHeadTasks => {
  return tasks ? {
    lubricateAndReassembleDialAndRing: tasks.lubricateAndReassembleDialAndRing,
    checkForProperOperationOfHead: tasks.checkForProperOperationOfHead,
    inspectHeadForExcessiveWear: tasks.inspectHeadForExcessiveWear,
    lubricateHeadBoltTrack: tasks.lubricateHeadBoltTrack,
    cleanHeadWithAppropriateCleaner: tasks.cleanHeadWithAppropriateCleaner,
  } : {
    lubricateAndReassembleDialAndRing: null,
    checkForProperOperationOfHead: null,
    inspectHeadForExcessiveWear: null,
    lubricateHeadBoltTrack: null,
    cleanHeadWithAppropriateCleaner: null,
  };
};

export const mapSecurityNightDropForm = (data: SecurityNightDropSectionsDto): NightDropSecurityForm => ({
  nightDropSections: mapNightDropSectionsResponse(data.security_NightDrop_NightDrops),
  nightDropDropDoorTasks: mapNightDropDropDoorTasksResponse(data.security_NightDrop_NightDropDoorTasks[0]),
  timeClockTasks: mapNightDropTimeClockResponse(data.security_NightDrop_TimeClockTasks[0]),
  comboLockTasks: mapNightDropComboLockResponse(data.security_NightDrop_CombolockTasks[0]),
  nightDropHeadTasks: mapNightDropHeadResponse(data.security_NightDrop_NightDropHeadTasks[0]),
  comments: data.comments ?? null,
});
