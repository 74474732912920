<ads-simple-form #batteries="ngModelGroup" ngModelGroup="batteries">
  <ads-common-form-input
    name="batteriesAmount"
    label="Quantity of batteries installed"
    [maxLength]="4"
    type="number"
    [minValue]="0"
    [required]="true"
    ngModel
    inputMode="tel"
  ></ads-common-form-input>
  <ads-datepicker
    name="installation"
    label="Battery installed on"
    tooltip="Must replace after 3 years"
    [required]="true"
    ngModel
  ></ads-datepicker>
  <ads-common-form-input
    name="voltageTestResult"
    label="Voltage test"
    tooltip="After 1 hour of power loss"
    [maxLength]="10"
    [required]="true"
    ngModel
  ></ads-common-form-input>
  <ads-radio-button-group
    label="Secondary power supply installed?"
    name="secondaryPowerSupply"
    [required]="true"
    [options]="yesNoOptions"
    ngModel
  ></ads-radio-button-group>
  <ads-datepicker
    name="secondaryBatteryInstallation"
    label="Secondary power supply installed on"
    tooltip="Must replace after 3 years"
    [required]="false"
    ngModel
  ></ads-datepicker>
</ads-simple-form>
