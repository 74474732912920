/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, Optional, ViewChild } from '@angular/core';
import { NgModel, NgModelGroup } from '@angular/forms';

@Component({
  template: '',
})
export abstract class TableSectionComponentBaseComponent {
  @Input({ required: true }) addNew: () => void = () => {};
  @Input() onDeleteChecked: (index: number) => void = () => {};

  @Input() set markedForDeletion(markedForDeletion: boolean) {
    if (markedForDeletion) {
      this.displaySelectKeys();
    } else {
      this.displayKeys();
    }
  }

  @Input() onDelete: (indexes: number[]) => void = () => {};
  @ViewChild('customInput') customInput?: NgModel;

  constructor(@Optional() public readonly ngModelGroup: NgModelGroup) {}

  abstract displaySelectKeys(): void;
  abstract displayKeys(): void;
  abstract openBottomSheet(e: any): void;
  abstract onBlur(e: any): void;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(list: any[], selection: SelectionModel<any>) {
    const numSelected = selection.selected.length;
    const numRows = list.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(list: any[], selection: SelectionModel<any>) {
    if (this.isAllSelected(list, selection)) {
      selection.clear();
      return;
    }

    selection.select(...list);
  }
}
