<ads-simple-form #nightDrop="ngModelGroup" ngModelGroup="nightDrop">
  <ng-container ngModelGroup="nightDropSections">
    <ads-nested-multiple-forms-section
      #nightDropSections
      title="Night Drop"
      [formTemplate]="formTemplate"
      [list]="security.nightDropData.nightDropSections"
      [formControls]="getFormControls()"
      [groupName]="'nightDropSection'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddClick()"
      (deleteClicked)="onRemoveNightDrop($event)"
    ></ads-nested-multiple-forms-section>
    <ng-template #formTemplate let-item="item">
      <ads-night-drop-section-form
        [section]="getItemSection('nightDropSection#' + item.index, 'nightDropSections')"
        [item]="item"
        [lists]="dropdowns"
        [submitted]="submitted"
      ></ads-night-drop-section-form>
    </ng-template>
  </ng-container>
  <ads-expansion-panel
    title="Night drop door tasks"
    [formGroup]="formGroup"
    [name]="'nightDropDropDoorTasks'"
    [class]="'nightDropDropDoorTasks'"
    ><ads-nd-drop-door-tasks-section
      [section]="getTasksSection('nightDropDropDoorTasks')"
    ></ads-nd-drop-door-tasks-section>
  </ads-expansion-panel>
  <ads-expansion-panel
    title="Time clock tasks"
    [formGroup]="formGroup"
    [name]="'timeClockTasks'"
    [class]="'timeClockTasks'"
  >
    <ads-time-clock-tasks-section [section]="getTasksSection('timeClockTasks')"></ads-time-clock-tasks-section>
  </ads-expansion-panel>
  <ads-expansion-panel
    title="Combo lock tasks"
    [formGroup]="formGroup"
    [name]="'comboLockTasks'"
    [class]="'comboLockTasks'"
  >
    <ads-combo-lock-tasks-section
      [section]="getTasksSection('comboLockTasks')"
      [fields]="comboLockFields"
    ></ads-combo-lock-tasks-section>
  </ads-expansion-panel>
  <ads-expansion-panel
    title="Night drop head tasks"
    [formGroup]="formGroup"
    [name]="'nightDropHeadTasks'"
    [class]="'nightDropHeadTasks'"
    ><ads-nd-head-tasks-section [section]="getTasksSection('nightDropHeadTasks')"></ads-nd-head-tasks-section>
  </ads-expansion-panel>
  <ads-common-text-area
    name="comments"
    label="Comments (optional)"
    [maxLength]="200"
    ngModel
    ></ads-common-text-area>
</ads-simple-form>
