<ads-work-order-mobile-menu></ads-work-order-mobile-menu>
<div class="container">
  <mat-expansion-panel class="expansion-panel" expanded>
    <mat-expansion-panel-header class="expansion-panel-header">
      <mat-panel-title> Work order details </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansion-panel-header-content">
      <div *ngIf="workOrderService.workOrderDetails() as details" class="details">
        <div class="description">
          <p>{{ details.calltype }}</p>
          <p>{{ details.description }}</p>
        </div>
        <ads-detail-row name="Problem code:" [value]="details.problem"></ads-detail-row>
        <ads-detail-row
          name="Ticket #:"
          [value]="details.ticketRecId"
          [valueAction]="workOrderService.openTicketNotes"
        ></ads-detail-row>
        <ads-detail-row name="Customer PO:" [value]="details.customerpo"></ads-detail-row>
        <ads-detail-row
          name="Opened:"
          value="{{ details.createddatetime | date : 'MM/dd/yyyy - hh:mm a' }}"
        ></ads-detail-row>
        <ads-detail-row name="On-site by:" [value]="details.onSiteBy | date : 'MM/dd/yyyy - hh:mm a'"></ads-detail-row>
        <ads-detail-row name="Resolve by:" [value]="details.resolveBy | date : 'MM/dd/yyyy - hh:mm a'"></ads-detail-row>
        <ads-detail-row
          name="Scheduled:"
          [value]="details.servicedatetime | date : 'MM/dd/yyyy - hh:mm a'"
        ></ads-detail-row>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel class="expansion-panel" expanded>
    <mat-expansion-panel-header class="expansion-panel-header">
      <mat-panel-title> Customer info </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansion-panel-header-content">
      <div *ngIf="workOrderService.workOrderDetails() as details" class="details">
        <ads-detail-row name="Customer:" [value]="details.customer"></ads-detail-row>
        <ads-detail-row name="Search name:" [value]="details.nameAlias"></ads-detail-row>
        <ads-detail-row
          name="Address:"
          [tallRow]="true"
          [value]="details.address + '<br/>' + details.city + ', ' + details.state + ' '+ details.zip"
          [valueAction]="workOrderService.openMap">
        </ads-detail-row>
        <ads-detail-row
          name="Contact Method:"
          [value]="workOrderService.email ? 'Email' : 'Phone'"
        ></ads-detail-row>
        <ads-detail-row
          *ngIf="workOrderService.contactName"
          name="Name:"
          [value]="workOrderService.contactName"
        ></ads-detail-row>
        <ads-detail-row
          *ngIf="workOrderService.email"
          name="Email Address:"
          [value]="workOrderService.email"
          [valueAction]="workOrderService.openEmail"
        ></ads-detail-row>
        <ads-detail-row
        *ngIf="workOrderService.phone"
          name="Phone:"
          [value]="workOrderService.phone"
          [valueAction]="workOrderService.openPhone"
        ></ads-detail-row>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel class="expansion-panel" expanded>
    <mat-expansion-panel-header class="expansion-panel-header">
      <mat-panel-title> Equipment on-site </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansion-panel-header-content">
      <div *ngIf="workOrderService.workOrderDetails() as details" class="details">
        <div class="search-header">
          <div class="search-box">
            <ads-common-form-input
              label="Search"
              [maxLength]="50"
              [(ngModel)]="workOrderService.searchText"
            ></ads-common-form-input>
            <mat-icon>search</mat-icon>
          </div>
          <button mat-button mat-stroked-button (click)="workOrderService.openEquipmentOnSite()">
            Open list <mat-icon iconPositionEnd>open_in_new</mat-icon>
          </button>
        </div>

        <ng-container
          *ngFor="
            let equipment of (workOrderService.equipmentOnSite | async) ?? []
              | equipmentFilter : workOrderService.searchText
          "
        >
          <div class="equipment-container">
            <div class="equipment-header">
              <h3>{{ equipment.equipmentType || '-' }}</h3>
              <span>{{ equipment.terminalID || '-' }}</span>
            </div>

            <div class="equipment-manufacturer">
              <div class="manufacturer">
                <mat-icon class="material-icons-outlined">domain</mat-icon>
                <span>{{ equipment.manufacturer || '-' }}</span>
              </div>
              {{ equipment.model || '-' }}
            </div>

            <div class="equipment-type">
              <span>{{ equipment.type || '-' }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-expansion-panel>
  <div class="actions-footer" [class.light]="themeService.name() === 'light'">
    <ads-select
      label="Status"
      [disabled]="workOrderService.loading"
      [list]="workOrderService.statuses"
      [value]="workOrderService.status"
      [(ngModel)]="workOrderService.status"
      [showEmptyOption]="false"
      (valueChange)="onStatusChange($event)"
    ></ads-select>

    <button
      mat-button
      mat-stroked-button
      *ngIf="workOrderService.attachments().length === 0"
      (click)="attachmentsClicked()"
    >
      Attachments
    </button>
    <button
      mat-button
      class="main-button"
      *ngIf="workOrderService.attachments().length > 0"
      (click)="attachmentsClicked()"
    >
      Attachments
    </button>
  </div>
</div>
