import { CompleteNa } from 'models/shared/complete-na';

export class AudioMatrixTasks {
  allNa: boolean;
  testCallButtons: CompleteNa | null;
  testCustomerGain: CompleteNa | null;
  testTellerGain: CompleteNa | null;
  inspectMicrophone: CompleteNa | null;
  inspectTellerConsoles: CompleteNa | null;
  inspectWiringConnections: CompleteNa | null;

  constructor() {
    this.allNa = false;
    this.testCallButtons = null;
    this.testCustomerGain = null;
    this.testTellerGain = null;
    this.inspectMicrophone = null;
    this.inspectTellerConsoles = null;
    this.inspectWiringConnections = null
  }
}
