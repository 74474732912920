import { CommonModule } from '@angular/common';
import { Component, inject, input, Input, OnInit, signal } from '@angular/core';
import { FormsModule, ControlContainer, NgModelGroup } from '@angular/forms';
import { SecuritySurveillanceDropdowns } from 'models/security/security-dropdowns';
import { SurveillancePOESwitchSection } from '../surveillance.component';
import { TableSectionComponentBaseComponent } from 'forms/shared/table-section-component-base/table-section-component.base';
import { SelectionModel } from '@angular/cdk/collections';
import { MatBottomSheetModule, MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { TableBottomSheetsComponent } from 'forms/shared/table-bottom-sheets/table-bottom-sheets.component';
import { TableFormBottomSheetInputComponent } from 'shared/material-wrappers/table-form/table-form-bottom-sheet-input/table-form-bottom-sheet-input.component';
import { TableFormInputComponent } from 'shared/material-wrappers/table-form/table-form-input/table-form-input.component';

@Component({
  selector: 'ads-surveillance-poe-switch-section',
  standalone: true,
  imports: [
    MatTableModule,
    CommonModule,
    FormsModule,
    MatInputModule,
    TableFormInputComponent,
    TableFormBottomSheetInputComponent,
    MatBottomSheetModule,
    MatCheckboxModule,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
  templateUrl: './surveillance-poe-switch-section.component.html',
  styleUrl: './surveillance-poe-switch-section.component.scss',
})
export class SurveillancePoeSwitchSectionComponent extends TableSectionComponentBaseComponent implements OnInit {
  displayedColumnsKeys = signal(['poeSwitchManufacturer', 'poeSwitchModel']);

  @Input() list: SurveillancePOESwitchSection[] = [];
  readonly dropdowns = input<SecuritySurveillanceDropdowns>();

  selection = new SelectionModel<SurveillancePOESwitchSection>(true, []);

  private readonly _bottomSheet = inject(MatBottomSheet);

  ngOnInit() {
    this.selection.changed.subscribe((change) => {
      change.added.concat(change.removed).forEach((item) => {
        this.onDeleteChecked(item.index);
      });
    });
  }

  openBottomSheet = (element: SurveillancePOESwitchSection) => () => {
    const onDismiss = this._bottomSheet.open(TableBottomSheetsComponent, {
      data: {
        selections: [
          {
            title: 'POE Switch Manufacturer',
            control: 'poeSwitchManufacturer',
            options: this.dropdowns()?.surveillancePoeSwitchManufacturers,
            value: element.poeSwitchManufacturer,
          },
        ],
        addNew: this.addNew,
      },
    });

    onDismiss.afterDismissed().subscribe((result) => {
      if (result) {
        element.poeSwitchManufacturer = result.poeSwitchManufacturer;
      }
    });
  };

  onBlur = (element: SurveillancePOESwitchSection) => () => {
    if (!element.poeSwitchManufacturer) {
      this.openBottomSheet(element)();
    }
  };

  displaySelectKeys = () => {
    this.displayedColumnsKeys.set(['select', 'poeSwitchManufacturer', 'poeSwitchModel']);
  };

  displayKeys = () => {
    this.displayedColumnsKeys.set(['poeSwitchManufacturer', 'poeSwitchModel']);
  };

  displayOtherManufacturer = (element: SurveillancePOESwitchSection) => {
    return element.poeSwitchManufacturer === 'Other';
  };
}
