import { ChangeDetectionStrategy, Component, computed, EventEmitter, Input, Output, signal, WritableSignal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

export interface CheckboxItem {
  name: string;
  completed: boolean;
  subitems?: CheckboxItem[];
}
@Component({
  selector: 'ads-checkbox-list',
  standalone: true,
  imports: [MatCheckboxModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './checkbox-list.component.html',
  styleUrl: './checkbox-list.component.scss',
})
export class CheckboxListComponent {

  @Input() item: WritableSignal<CheckboxItem> = signal<CheckboxItem>({
    name: '',
    completed: false,
    subitems: [],
  });

  @Output() itemChange = new EventEmitter<CheckboxItem>();

  readonly partiallyComplete = computed(() => {
    const item = this.item();
    if (item.subitems) {
      return item.subitems.some((t) => t.completed) && !item.subitems.every((t) => t.completed);
    }
    return false;
  });

  update(completed: boolean, index?: number) {
    this.item.update((item) => {
      if (index === undefined) {
        item.completed = completed;
        item.subitems?.forEach((t) => (t.completed = completed));
      } else {
        item.subitems![index].completed = completed;
        item.completed = item.subitems?.every((t) => t.completed) ?? true;
      }
      this.itemChange.emit({...item});
      return { ...item };
    });
  }

}
