import { AfterViewInit, Component } from '@angular/core';
import { BaseFormInputComponent } from '../form-input-base/form-input.base';

@Component({
  template: '',
})
export abstract class BaseTableFormComponent extends BaseFormInputComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.ngModelGroup?.control !== null && this.name !== '') {
        const control = this.ngModelGroup.control;

        control.registerControl(this.name, this.customInput);

        control.controls[this.name].valueChanges.subscribe((value) => {
          if (value === null) {
            this.customInput?.control.setValue('');
            this.customInput?.control.markAsPristine();
            this.customInput?.control.markAsUntouched();
          }
          this.value = value;

          if (value) {
            this.onTouched();
          }
        });
      }
    });
  }
}
