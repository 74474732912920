import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, Optional, ViewChild } from '@angular/core';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, NgModel, NgModelGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ValidateRequiredDirective } from 'app/shared/directives/validate-required.directive';
import { SelectOption } from 'models';

@Component({
  selector: 'ads-select',
  standalone: true,
  imports: [CommonModule, MatSelectModule, MatFormFieldModule, FormsModule, ValidateRequiredDirective],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectComponent,
    },
  ],
})
export class SelectComponent implements AfterViewInit {
  @Input() name = '';
  @Input() list?: SelectOption<string | number>[] = [];
  @Input() label = '';
  @Input() required = false;
  @Input() value: string | null |undefined = '';
  @ViewChild('customSelect') customSelect?: NgModel;

  touched = false;
  disabled = false;
  formControl = new FormControl('', []);

  constructor(@Optional() private readonly ngModelGroup: NgModelGroup) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.ngModelGroup?.control) {
        this.ngModelGroup.control.registerControl(this.name, this.customSelect);
        this.ngModelGroup.control.controls[this.name].valueChanges.subscribe((value) => {
          if (value === null) {
            this.customSelect?.control.setValue('');
            this.customSelect?.control.markAsPristine();
            this.customSelect?.control.markAsUntouched();
            this.touched = false;
          }
        });
      }
    });
  }

  getInvalid = () => {
    return this.ngModelGroup?.control?.controls[this.name]?.touched && this.customSelect?.control?.status === 'INVALID';
  };

  getDisabled = () => this.disabled || this.ngModelGroup.disabled;

  onChange = () => {};

  onTouched = () => {};

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  propagateChange = (value: string) => {
    this.writeValue(value);
  };

  writeValue(newValue: string): void {
    this.value = newValue;
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  onModelChange(val: string) {
    this.propagateChange(val);
  }
}
