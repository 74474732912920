import { Component, Input } from '@angular/core';
import { CommonFormInputComponent } from "../../../../shared/material-wrappers/common-form-input/common-form-input.component";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SingleToggleButtonComponent } from "../../../../shared/material-wrappers/single-toggle-button/single-toggle-button.component";

@Component({
  selector: 'ads-call-control-filters',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CommonFormInputComponent,
    MatIconModule,
    MatButtonModule,
    SingleToggleButtonComponent,
  ],
  templateUrl: './call-control-filters.component.html',
  styleUrl: './call-control-filters.component.scss',
})
export class CallControlFiltersComponent {
  @Input() isDesktopView = false;
  searchText = '';
  criteria = {
    filters: [] as string[],
  };

  toggleFilter(filter: string) {
    if (this.criteria.filters.includes(filter)) {
      this.criteria.filters = this.criteria.filters.filter((f) => f !== filter);
    } else {
      this.criteria.filters.push(filter);
    }
  }
}
