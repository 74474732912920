import { Component, OnDestroy, OnInit, effect, inject, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from 'environment/environment';
import { ThemingService, AuthService, LoggingService } from 'services';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ads-pwa-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, MatProgressSpinnerModule, CommonModule],
})
export class AppComponent implements OnInit, OnDestroy {
  env = environment.name;
  themeSelectorOpen = signal(false);
  componentSelectorOpen = signal(true);
  themingService = inject(ThemingService);
  setTheme = effect(() => {
    const themeProperties = this.themingService.getThemeProperties();
    for (const property in themeProperties) {
      document.body.style.setProperty(property, themeProperties[property]);
    }
  });

  constructor(public readonly authService: AuthService, public readonly loggingService: LoggingService) {}

  ngOnInit(): void {
    this.authService.init();
    this.authService.checkUserValidity();
  }

  ngOnDestroy(): void {
    this.authService.onDestroy();
  }
}
