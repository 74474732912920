<div class="common-form-field">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-button-toggle-group
    #customSelect="ngModel"
    *ngIf="options && options.length > 0"
    class="group"
    required
    adsRequired
    [name]="name"
    [multiple]="multiple"
    [disabled]="disabled"
    [ngModel]="value"
    (ngModelChange)="onModelChange($event)"
  >
    @for (item of options; track item) {
    <mat-button-toggle
      class="option"
      [ngClass]="{ 'remove-icon': removeIcon }"
      [style.width]="'calc(100% / ' + options.length + ')'"
      [value]="item.value"
      [checked]="value === item.value"
      [class.error]="getInvalid() && !disabled"
      [class.disabled-selected]="disabled && value === item.value"
    >
      {{ item.name }}
    </mat-button-toggle>
    }
  </mat-button-toggle-group>
  <mat-hint align="start" class="custom-error" *ngIf="getInvalid() && !disabled"> Please select an option </mat-hint>
</div>
