<ads-simple-form *ngIf="item" [ngModelGroup]="'pathTableSection#' + item.index">
  <ads-common-form-input
    name="path"
    label="Path"
    [maxLength]="50"
    [required]="true"
    [value]="item.path"
    [(ngModel)]="item.path"
  ></ads-common-form-input>
  <ads-select
    label="Path Type"
    name="pathType"
    [list]="alarmLists?.pathTypesList"
    [value]="item.pathType"
    [(ngModel)]="item.pathType"
    [required]="true"
  ></ads-select>
  <ads-select
    label="Communication Type"
    name="communicationType"
    [list]="alarmLists?.communicationTypeList"
    [value]="item.communicationType"
    [(ngModel)]="item.communicationType"
    [required]="true"
  ></ads-select>
  <ads-common-form-input
    name="receiverAddress"
    label="Receiver Address/Number"
    [maxLength]="50"
    [required]="true"
    [value]="item.receiverAddress"
    [(ngModel)]="item.receiverAddress"
  ></ads-common-form-input>
</ads-simple-form>
