import { Component, inject, OnInit } from '@angular/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { SearchOrderComponent } from './search-order/search-order.component';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { SelectOption } from 'models';
import { FormsModule } from '@angular/forms';
import { RMARequestService, ShippingAddress } from './rma-request.service';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { CommonModule } from '@angular/common';
import { AuthService } from 'services';
import { PartsComponent } from './parts/parts.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { RoutesEnum } from 'app/utils/navigation';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { SubmitRmaRequestCommand } from 'models/rma-request/rma-request';
import { MatSnackBar } from '@angular/material/snack-bar';

const SHIP_TO_OPTIONS: SelectOption<string>[] = [
  { value: '0', name: 'No preference' },
  { value: '1', name: 'My Truck Stock Default' },
  { value: '2', name: 'My Main Stock Default' },
  { value: '3', name: 'New Address (Free Text)' },
];

const getRMAType = (selectedIndex: number): string => {
  switch (selectedIndex) {
    case 0:
      return 'Service Order';
    case 1:
      return 'Project';
    default:
      return 'Vanstock';
  }
};

@Component({
  selector: 'ads-rma-request',
  standalone: true,
  imports: [
    MatTabsModule,
    SearchOrderComponent,
    SelectComponent,
    FormsModule,
    CommonTextAreaComponent,
    CommonModule,
    CommonFormInputComponent,
    PartsComponent,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './rma-request.component.html',
  styleUrl: './rma-request.component.scss',
})
export class RmaRequestComponent implements OnInit {
  shipToOptions: SelectOption<string>[] = SHIP_TO_OPTIONS;

  shippingMethodItems: SelectOption<string>[] = [];

  addresses: ShippingAddress | null = null;

  vanstockName = 'Van';

  public readonly _snackBar = inject(MatSnackBar);

  constructor(
    protected readonly rmaRequestService: RMARequestService,
    private readonly authService: AuthService,
    private readonly router: Router,
    protected readonly layoutNavService: LayoutNavService,
  ) {}

  ngOnInit(): void {
    this.rmaRequestService.getPartRequestShippingMethod().subscribe((shippingMethods) => {
      this.shippingMethodItems = shippingMethods
        .map((method) => ({ value: method.Code, name: method.Txt } as SelectOption<string>))
        .filter((method) => method.value !== 'UPS');
    });

    this.rmaRequestService
      .getShippingAddresses(this.authService.user?.inventLocationId ?? '')
      .subscribe((addresses) => {
        this.addresses = addresses;
      });

    this.vanstockName = this.authService.user?.firstName + ' Van';
  }

  goBack = () => {
    this.router.navigate([RoutesEnum.Home]);
  };

  onSubmit = () => {
    const type = getRMAType(this.rmaRequestService.selectedIndex());

    const data: SubmitRmaRequestCommand = {
      type: type,
      referenceId:
        type !== 'Vanstock'
          ? this.rmaRequestService.selectedControl() ?? ''
          : this.authService.user?.inventLocationId ?? '',
      shippingAddress: SHIP_TO_OPTIONS.find((x) => x.value === this.rmaRequestService.selectedShipTo)?.name ?? '',
      shippingMethod: this.rmaRequestService.selectedShippingMethod ?? '',
      notesToRma: this.rmaRequestService.description,
      techId: this.authService.user?.personnelNumber ?? '',
      technicianName: this.authService.user?.fullName ?? '',
      site: this.addresses?.InventSiteId ?? '',
      createdBy: this.authService.user?.userId ?? '',
      lines: this.rmaRequestService.parts().map((x, idx) => ({
        ...x,
        uploadImage: {
          file: {},
          name: x.partNumber ?? 'uploaded-image#' + idx,
          blobUrl: x.uploadImage,
        },
      })),
    };

    this.rmaRequestService.submitRMA(data).subscribe({ complete: this.onSubmitSuccess, error: this.onSubmitError });
  };

  submitEnabled = () => {
    let valid =
      !!this.rmaRequestService.selectedShipTo &&
      !!this.rmaRequestService.description &&
      !!this.rmaRequestService.selectedControl;

    if (valid && this.rmaRequestService.selectedShipTo === '3') {
      valid = valid && !!this.rmaRequestService.shipToAddress;
    }

    valid = valid && this.rmaRequestService.parts().length > 0;

    return valid;
  };

  onTabChanged(event: MatTabChangeEvent) {
    this.rmaRequestService.selectedIndex.set(event.index);
  }

  clearEntries = () => {
    this.rmaRequestService.clearEntries();
    this.goBack();
  };

  onSubmitSuccess = () => {
    this._snackBar.open('RMA Submitted successfully.', 'Close');
    this.rmaRequestService.clearEntries();
  };

  onSubmitError = (error: unknown) => {
    console.error(error);
    this._snackBar.open('Failed to submit RMA. Please try again later.', 'Close');
  };
}
