<ads-simple-form #ccCdTasks="ngModelGroup" ngModelGroup="ccCdTasks">
  <ads-radio-button-group
    [options]="yesNoOptions"
    label="Are configuration sheets present?"
    name="confSheetsPresent"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Check error log for abnormalities"
    name="checkErrorForAbnormalities"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
