import { SecuritySurveillanceDto } from 'models/api/security/security';
import {
  SecuritySurveillanceCameraTableDto,
  SecuritySurveillanceCameraTasksDto,
  SecuritySurveillanceDVRNVRDto,
  SecuritySurveillanceDVRTasksDto,
  SecuritySurveillanceMonitorTableDto,
  SecuritySurveillanceMonitorTasksDto,
  SecuritySurveillancePOESwitchDto,
  SecuritySurveillanceUPSDto,
} from 'models/api/security/surveillance/surveillance-dto';
import {
  SurveillanceCameraTableForm,
  SurveillanceCameraTasksForm,
  SurveillanceDVRNVRForm,
  SurveillanceDVRTasksForm,
  SurveillanceMonitorTableForm,
  SurveillanceMonitorTasksForm,
  SurveillancePOESwitchForm,
  SurveillanceSecurityForm,
  SurveillanceUPSForm,
} from 'models/security/surveillance/surveillanceSecurityForm';

export const mapSecuritySurveillanceDvrNvrRequest = (
  dvrSections: SurveillanceDVRNVRForm[]
): SecuritySurveillanceDVRNVRDto[] => {
  return dvrSections.map((section) => ({
    dvrModel: section.dvrModel,
    dvrManufacturer: section.dvrManufacturer,
    dvrManufacturer_Other: section.dvrManufacturer_Other,
    dvrSerialNumber: section.dvrSerialNumber,
    overallCondition_DVRNVR: section.overallCondition_DVRNVR,
    softwareVersion: section.softwareVersion,
    totalChannelsInDVR: section.totalChannelsInDVR,
    totalChannelsAvailableForUse: section.totalChannelsAvailableForUse,
    totalAnalogInputs: section.totalAnalogInputs,
    totalAnalogInputsAvailableForUse: section.totalAnalogInputsAvailableForUse,
    totalHardDriveSize: section.totalHardDriveSize,
    daysOfRetention: section.daysOfRetention,
    equipment_Rating: section.equipment_Rating,
    security_Surveillance: section.security_Surveillance,
  }));
};

export const mapSecuritySurveillanceUPSRequest = (upsSections: SurveillanceUPSForm[]): SecuritySurveillanceUPSDto[] => {
  return upsSections.map((section) => ({
    upsModel: section.upsModel,
    upsManufacturer: section.upsManufacturer,
    upsManufacturer_Other: section.upsManufacturer_Other,
    numberOfOutputs: section.numberOfOutputs,
  }));
};

export const mapSecuritySurveillancePOESwitchRequest = (
  poeSections: SurveillancePOESwitchForm[]
): SecuritySurveillancePOESwitchDto[] => {
  return poeSections.map((section) => ({
    poeSwitchModel: section.poeSwitchModel,
    poeSwitchManufacturer: section.poeSwitchManufacturer,
    poeSwitchManufacturer_Other: section.poeSwitchManufacturer_Other,
  }));
};

export const mapSecuritySurveillanceCameraTableRequest = (
  cameraSections: SurveillanceCameraTableForm[]
): SecuritySurveillanceCameraTableDto[] => {
  return cameraSections
    .filter((section) => !!section.camera && !!section.cameraType && !!section.isPTZ)
    .map((section) => ({
      camera: section.camera ?? '',
      cameraType: section.cameraType ?? '',
      isPTZ: section.isPTZ ?? '',
    }));
};

export const mapSecuritySurveillanceMonitorTableRequest = (
  monitorSections: SurveillanceMonitorTableForm[]
): SecuritySurveillanceMonitorTableDto[] => {
  return monitorSections
    .filter((section) => !!section.monitor && !!section.monitorType)
    .map((section) => ({
      monitor: section.monitor ?? '',
      monitorType: section.monitorType ?? '',
    }));
};

export const mapSecuritySurveillanceDVRTasksRequest = (
  tasks?: SurveillanceDVRTasksForm
): SecuritySurveillanceDVRTasksDto[] => {
  return [
    {
      checkTimeDate: tasks?.checkTimeDate ?? null,
      cleanedDVRCase: tasks?.cleanedDVRCase ?? null,
      cleanedInsideEnclosure: tasks?.cleanedInsideEnclosure ?? null,
      inspectAllWireConnectionsInPowerSupply: tasks?.inspectAllWireConnectionsInPowerSupply ?? null,
      inspectConnectors: tasks?.inspectConnectors ?? null,
      inspectInternalFanOperation: tasks?.inspectInternalFanOperation ?? null,
      inspectAndCleanPOESwitch: tasks?.inspectAndCleanPOESwitch ?? null,
      verifiedAllCamerasShowLiveVideo: tasks?.verifiedAllCamerasShowLiveVideo ?? null,
      verifiedAllCamerasShowRecordedVideo: tasks?.verifiedAllCamerasShowRecordedVideo ?? null,
      verifyXterms: tasks?.verifyXterms ?? null,
    },
  ];
};

export const mapSecuritySurveillanceCameraTasksRequest = (
  tasks?: SurveillanceCameraTasksForm
): SecuritySurveillanceCameraTasksDto[] => {
  return [
    {
      cleanCamerasAndHousings: tasks?.cleanCamerasAndHousings ?? null,
      inspectCameraPositionFocus: tasks?.inspectCameraPositionFocus ?? null,
      inspectCameraWireConnection: tasks?.inspectCameraWireConnection ?? null,
    },
  ];
};

export const mapSecuritySurveillanceMonitorTasksRequest = (
  tasks?: SurveillanceMonitorTasksForm
): SecuritySurveillanceMonitorTasksDto[] => {
  return [
    {
      cleanMonitor: tasks?.cleanMonitor ?? null,
      inspectMonitorOperation: tasks?.inspectMonitorOperation ?? null,
    },
  ];
};

export const mapSecuritySurveillanceRequest = (surveillance: SurveillanceSecurityForm | null): SecuritySurveillanceDto => ({
  security_Surveillance: {
    overallCondition_Cameras: surveillance?.overallConditionCameras ?? null,
    equipment_Rating_Cameras: surveillance?.equipmentRatingCameras ?? null,
    overallCondition_Monitor: surveillance?.overallConditionMonitor ?? null,
    equipment_Rating_Monitor: surveillance?.equipmentRatingMonitor ?? null,
    security_Surveillance_DVRNVRs: mapSecuritySurveillanceDvrNvrRequest(surveillance?.surveillanceDvrNvr ?? []),
    security_Surveillance_UPSs: mapSecuritySurveillanceUPSRequest(surveillance?.surveillanceUps ?? []),
    security_Surveillance_POESwitchs: mapSecuritySurveillancePOESwitchRequest(
      surveillance?.surveillancePoeSwitch ?? []
    ),
    security_Surveillance_CameraTables: mapSecuritySurveillanceCameraTableRequest(
      surveillance?.surveillanceCameraTable ?? []
    ),
    security_Surveillance_MonitorTables: mapSecuritySurveillanceMonitorTableRequest(
      surveillance?.surveillanceMonitorTable ?? []
    ),
    security_Surveillance_DVRTasks: mapSecuritySurveillanceDVRTasksRequest(surveillance?.surveillanceDvrTasks),
    security_Surveillance_CameraTasks: mapSecuritySurveillanceCameraTasksRequest(surveillance?.surveillanceCameraTasks),
    security_Surveillance_MonitorTasks: mapSecuritySurveillanceMonitorTasksRequest(
      surveillance?.surveillanceMonitorTasks
    ),
    comments: surveillance?.comments ?? null,
  },
});
