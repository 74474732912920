export interface FilterOption<T> {
  name: string;
  value: string;
  isList: boolean;
  minValue?: T;
  maxValue?: T;
  defaultMinValue?: T;
  defaultMaxValue?: T;
  defaultOption?: T;
  options?: T[];
}

export const newDateFilter = (
  propertyName: string,
  label: string,
  minValue?: Date,
  maxValue?: Date,
  defaultMinValue = new Date('01/01/1999'),
  defaultMaxValue = new Date('01/01/2099')
): FilterOption<Date> => ({
  value: propertyName,
  name: label,
  isList: false,
  maxValue,
  minValue,
  defaultMinValue,
  defaultMaxValue,
});
