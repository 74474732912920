<ads-simple-form *ngIf="item" class="alarm-section-wrapper" [ngModelGroup]="'alarmPanel#' + item.index">
  <ads-select
    label="Alarm Manufacturer"
    name="manufacturer"
    [list]="alarmLists?.manufacturerList"
    [value]="item.manufacturer"
    [(ngModel)]="item.manufacturer"
  ></ads-select>
  <ads-common-form-input
    [style.display]="displayOtherManufacturer() ? 'block' : 'none'"
    name="otherManufacturer"
    label="Other Manufacturer"
    [maxLength]="30"
    [required]="displayOtherManufacturer()"
    [value]="item.otherManufacturer"
    [(ngModel)]="item.otherManufacturer"
  ></ads-common-form-input>
  <ads-common-form-input
    name="alarmModel"
    label="Alarm Model"
    [maxLength]="30"
    [required]="true"
    [value]="item.alarmModel"
    [(ngModel)]="item.alarmModel"
  ></ads-common-form-input>
  <ads-common-form-input
    name="firmwareVersion"
    label="Firmware Version"
    [maxLength]="30"
    [required]="true"
    [value]="item.firmwareVersion"
    [(ngModel)]="item.firmwareVersion"
  ></ads-common-form-input>
  <ads-select
    label="Overall Condition - Alarm Panel"
    name="overallCondition"
    [list]="alarmLists?.alarmPanelOverallConditionsList"
    [value]="item.overallCondition"
    [(ngModel)]="item.overallCondition"
    [required]="true"
  >
  </ads-select>

  <ads-common-text-area
    [style.display]="displayEquipmentRating() ? 'block' : 'none'"
    name="otherEquipmentRating"
    label="Substandard equipment comments"
    [maxLength]="500"
    [required]="displayEquipmentRating()"
    [value]="item.otherEquipmentRating"
    [(ngModel)]="item.otherEquipmentRating"
  >
  </ads-common-text-area>

  <ads-common-form-input
    name="accountNumber"
    label="Account #"
    [maxLength]="30"
    [required]="true"
    [value]="item.accountNumber"
    [(ngModel)]="item.accountNumber"
  ></ads-common-form-input>
  <ads-select
    label="Central Station"
    name="centralStation"
    [list]="alarmLists?.centralStationsList"
    [value]="item.centralStation"
    [(ngModel)]="item.centralStation"
  ></ads-select>
  <ads-common-form-input
    [style.display]="displayOtherCentralStation() ? 'block' : 'none'"
    name="otherCentralStation"
    label="Other Central Station"
    [maxLength]="30"
    [required]="displayOtherCentralStation()"
    [value]="item.otherCentralStation"
    [(ngModel)]="item.otherCentralStation"
  ></ads-common-form-input>
  <mat-form-field class="common-form-field" appearance="outline" hideRequiredMarker>
    <mat-label>Associated Phone number</mat-label>
    <ads-phone-input
      [id]="'custom-phone-input'"
      name="phoneNumber"
      [(ngModel)]="item.phoneNumber"
      [initialValue]="item.phoneNumber"
    ></ads-phone-input>
    <mat-icon matSuffix>phone</mat-icon>
    <mat-hint>Include area code</mat-hint>
  </mat-form-field>
  <ads-common-form-input
    name="ipAddress"
    label="Associated IP Address"
    [maxLength]="30"
    [required]="true"
    [value]="item.ipAddress"
    [(ngModel)]="item.ipAddress"
  ></ads-common-form-input>
  <ads-common-form-input
    name="meId"
    label="MEID"
    tooltip="If applicable"
    [maxLength]="30"
    [required]="true"
    [value]="item.meId"
    [(ngModel)]="item.meId"
  ></ads-common-form-input>

  <ads-common-form-input
    name="alarmPanelZonesAmount"
    label="Total zones including expanders"
    [maxLength]="4"
    type="number"
    [minValue]="0"
    [required]="true"
    [value]="item.alarmPanelZonesAmount"
    [(ngModel)]="item.alarmPanelZonesAmount"
    inputMode="tel"
  ></ads-common-form-input>

  <ads-common-form-input
    name="zonesAmount"
    label="Total zones available for use"
    [maxLength]="4"
    type="number"
    [minValue]="0"
    [required]="true"
    [value]="item.zonesAmount"
    [(ngModel)]="item.zonesAmount"
    inputMode="tel"
  ></ads-common-form-input>

</ads-simple-form>
