import { ItmDropdowns, ItmForm } from '../../../itm';
import { ItmResponse, ItmResponseDropdowns } from '../../itm/itm-response';

export const mapItmForm = (response: ItmResponse): ItmForm => {
  const data = response.itm;
  return {
    itmSpecs: {
      manufacturer: data.itmSpecs_ItmManufacturer,
      serialNumber: data.itmSpecs_ItmSerialNumber,
      depositorType: data.itmSpecs_DepositorType,
      kabaSerialNumber: data.itmSpecs_KabaSerialNumber,
      model: data.itmSpecs_ItmModel,
      communicationType: data.itmSpecs_CommType,
      terminalId: data.itmSpecs_ItmTerminalId,
      networkTerminalId: data.itmSpecs_ItmNetworkTerminalId,
      overallCondition: data.itmSpecs_OverallCondition,
      equipmentRating: data.itmSpecs_Equipment_Rating,
    },
    software: {
      onsite: data.software_SoftwareOnsite,
      softwareVersion: data.software_Version,
      operatingSystem: data.software_OS,
    },
    itmTasks: {
      areConfigSheetsPresent: data.itmTasks_AreConfigSheetsPresent,
      isMaintainanceLogSheetPresent: data.itmTasks_IsMaintainanceLogSheetPresent,
      verifySiteProfileOnSharepoint: data.itmTasks_VerifySiteProfileOnSharepoint,
      checkErrorLog: data.itmTasks_CheckErrorLog,
      clearTallies: data.itmTasks_ClearTallies,
      requestKabaCombo: data.itmTasks_RequestKabaCombo,
    },
    itmPeripheralTasks: {
      cleanPreHead: data.peripheralTasks_CleanPreHead,
      cleanAllBelts: data.peripheralTasks_CleanAllBelts,
      cleanReadHead: data.peripheralTasks_CleanReadHead,
      cleanWithFeltCard: data.peripheralTasks_CleanWithFeltCard,
      blowOutSensorAreas: data.peripheralTasks_BlowOutSenserAreas,
      performDiagnosticTest: data.peripheralTasks_PerformDiagnosticTest,
      isCardReaderEmvCapable: data.peripheralTasks_IsCardReaderEmvCapable,
      connectToTeller: data.peripheralTasks_ConnectToTeller,
      testAudioVideo: data.peripheralTasks_TestAudioVideo,
      testSignaturePad: data.peripheralTasks_TestSignaturePad,
      testPhone: data.peripheralTasks_TestPhone,
      testScanner: data.peripheralTasks_TestScanner,
      testCoinDispenser: data.peripheralTasks_TestCoinDispenser,
      testVideoFromTeller: data.peripheralTasks_TestVideoFromTeller,
    },
    itmReceiptPrinterTasks: {
      allNa: false,
      removeAllPaperDust: data.receiptPrinterTasks_RemoveAllPaperDust,
      openAndCleanEngine: data.receiptPrinterTasks_OpenAndCleanEngine,
      checkAndCleanAllBelts: data.receiptPrinterTasks_CheckAndCleanAllBelts,
      checkAndCleanAllRollers: data.receiptPrinterTasks_CheckAndCleanAllRollers,
      blowOutSensorAreas: data.receiptPrinterTasks_BlowOutSensorAreas,
      blowOutCutterArea: data.receiptPrinterTasks_BlowOutCutterArea,
      performDiagnosticTest: data.receiptPrinterTasks_PerformDiagnosticTest,
    },
    itmCashDispenserTasks: {
      allNa: false,
      checkAndCleanAllSensors: data.cashDispenserTasks_CheckAndCleanAllSensors,
      removeAnyForeignMaterialFromDispenser: data.cashDispenserTasks_RemoveAnyForeignMaterialFromDispenser,
      checkAndCleanAllRollers: data.cashDispenserTasks_CheckAndCleanAllRollers,
      checkAndCleanAllBelts: data.cashDispenserTasks_CheckAndCleanAllBelts,
      checkAndCleanShutter: data.cashDispenserTasks_CheckAndCleanShutter,
      replacePickCupsOrFeedRollers: data.cashDispenserTasks_ReplacePickCupsOrFeedRollers,
      checkInterlockSwitch: data.cashDispenserTasks_CheckInterlockSwitch,
      performDiagnosticTest: data.cashDispenserTasks_PerformDiagnosticTest,
    },
    itmFasciaAndAdaTasks: {
      cleanCustomerInterfaceArea: data.fasciaAndAdaTasks_CleanCustomerInterfaceArea,
      areAdaStickersPresentAndReadable: data.fasciaAndAdaTasks_AreAdaStickersPresentAndReadable,
      checkToneVolumeAndAdjust: data.fasciaAndAdaTasks_CheckToneVolumeAndAdjust,
      testVoiceGuidance: data.fasciaAndAdaTasks_TestVoiceGuidance,
      testAllKeypadAndFunctionKeys: data.fasciaAndAdaTasks_TestAllKeypadAndFunctionKeys,
      checkAlignWithCardReader: data.fasciaAndAdaTasks_CheckAlignWithCardReader,
      checkAlignAndOperationOfDispensorShutter: data.fasciaAndAdaTasks_CheckAlignAndOperationOfDispensorShutter,
      checkAlignWithReceiptPrinter: data.fasciaAndAdaTasks_CheckAlignWithReceiptPrinter,
      cleanOperatorPanel: data.fasciaAndAdaTasks_CleanOperatorPanel,
      cleanEntireExteriorOfStandaloneAtms: data.fasciaAndAdaTasks_CleanEntireExteriorOfStandaloneAtms,
      verifyTouchScreenCalibration: data.fasciaAndAdaTasks_VerifyTouchScreenCalibration,
      testOpenCloseIndicator: data.fasciaAndAdaTasks_TestOpenCloseIndicator,
      replaceLightBulbs: data.fasciaAndAdaTasks_ReplaceLightBulbs,
      wipeDownAllAtmDevices: data.fasciaAndAdaTasks_WipeDownAllAtmDevices,
      antiSkimmingInDeviceConfigMode: data.fasciaAndAdaTasks_AntiSkimmingInDeviceConfigMode,
      topHatLightOperational: data.topHatLightOperational,
    },
    itmCashCheckDepositoryTasks: {
      allNa: false,
      cleanAllSensors: data.cashCheckDepositoryTasks_CleanAllSensors,
      checkAndCleanAllRollers: data.cashCheckDepositoryTasks_CheckAndCleanAllRollers,
      checkAndCleanAllBelts: data.cashCheckDepositoryTasks_CheckAndCleanAllBelts,
      cleanInkArea: data.cashCheckDepositoryTasks_CleanInkArea,
      cleanCheckScannerLenses: data.cashCheckDepositoryTasks_CleanCheckScannerLenses,
      checkPrintQuality: data.cashCheckDepositoryTasks_CheckPrintQuality,
      checkAndCleanAllBins: data.cashCheckDepositoryTasks_CheckAndCleanAllBins,
      verifyInfeedAlignmentToFascia: data.cashCheckDepositoryTasks_VerifyInfeedAlignmentToFascia,
      performDiagnosticTest: data.cashCheckDepositoryTasks_PerformDiagnosticTest,
      replaceRetardCartridges: data.cashCheckDepositoryTasks_ReplaceRetardCartridges,
    },
    itmVacuumingAndBlowOutTasks: {
      allNa: false,
      safe: data.vacummingAndBlowOut_Safe,
      topHalf: data.vacummingAndBlowOut_TopHalf,
      dispenser: data.vacummingAndBlowOut_Dispenser,
      depositories: data.vacummingAndBlowOut_Depositories,
      receiptPrinter: data.vacummingAndBlowOut_ReceiptPrinter,
      allFans: data.vacummingAndBlowOut_AllFans,
      allFilters: data.vacummingAndBlowOut_AllFilters,
      powerSupply: data.vacummingAndBlowOut_PowerSupply,
      safeHeaters: data.vacummingAndBlowOut_SafeHeaters,
    },
    comments: data.comments ?? null,
  };
};

export const mapItmDropdowns = (data: ItmResponseDropdowns): ItmDropdowns => {
  return {
    specsManufacturerList: data.itmManufactures.map((item) => ({
      value: item.manufacturerName,
      name: item.manufacturerName,
    })),
    specsDepositorsList: data.itmDepositors.map((item) => ({
      value: item.depositorTypeCode,
      name: item.depositorTypeName,
    })),
    specsOverallConditionsList: data.overallConditions.map((item) => ({
      value: item.name,
      name: item.name,
    })),
    communicationTypesList: data.communicationTypes.map((item) => ({
      value: item.name,
      name: item.name,
    })),
    operatingSystemsList: data.operatingSystems.map((item) => ({
      value: item.name,
      name: item.name,
    })),
  };
};
