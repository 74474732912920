export class AtmReceiptPrinterTasks {
  allNa: boolean;
  removeAllPaperDust: number | null;
  openCleanEngine: number | null;
  cleanAllBelts: number | null;
  cleanAllRollers: number | null;
  blowSensorAreas: number | null;
  blowCutterArea: number | null;
  diagnostic: number | null;

  constructor() {
    this.allNa = false;
    this.removeAllPaperDust = null;
    this.openCleanEngine = null;
    this.cleanAllBelts = null;
    this.cleanAllRollers = null;
    this.blowSensorAreas = null;
    this.blowCutterArea = null;
    this.diagnostic = null;
  }
}
