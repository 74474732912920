import { HttpParams } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { statusColors } from 'app/utils/call-control';
import { RoutesEnum } from 'app/utils/navigation';
import { AdsCallControl, AdsEquipmentOnSite, AdsTicketNotes, SelectOption } from 'models';
import { CallControlService } from 'pages/call-control/call-control.service';
import { CustomHttpUrlEncodingCodec } from 'pages/home/home.service';
import { Observable } from 'rxjs';
import { ApiService, AuthService } from 'services';
import { HeaderService } from 'shared/header/header.service';
import { nextStatus, WorkOrderStatus } from './status/status';
import { MatDialog } from '@angular/material/dialog';
import { TicketDialogComponent } from './ticket-dialog/ticket-dialog.component';
import { AcknowledgeComponent } from './shared/acknowledge/acknowledge.component';
import { ScheduleComponent } from './shared/schedule/schedule.component';
import { TravellingComponent } from './shared/travelling/travelling.component';
import { StartCallComponent } from './shared/start-call/start-call.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ComponentType } from '@angular/cdk/portal';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { LaunchFormDialogComponent } from './shared/launch-form-dialog/launch-form-dialog.component';
import { CompleteCallComponent } from './shared/complete-call/complete-call.component';
import { SuspendComponent } from './shared/suspend/suspend.component';
import { CheckTimeStampCommand } from 'models/call-control/check-time-stamp-command';
import { CallControlDateModel } from 'models/call-control/call-control-date-model';
import { CompleteWoNoteModel } from 'models/notes/complete-wo-note-model';
import { PartDetail } from 'models/call-control/part';
import { Labor } from 'models/call-control/labor';
import {
  AdsPMDetails,
  AdsPMFormType,
  AdsAttachments,
  AttachmentEntity,
  AdsAttachmentUpload,
  AdsRelatedArticle,
  AdsOpenCall,
} from 'models/call-control/ads-call-control';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AssociatedEquipmentModel,
  CompleteRegisterModel,
  CompleteRegisterRequest,
  EquipmentEntityModel,
  InsertCompleteCallCommand,
} from 'models/equipment/equipment';
import { ProcessTravelBackCommand } from 'models/call-control/back-and-travel';
import { OperationResponse } from 'models/call-control/command-response';
import { ContactMethodEntityModel } from 'models/call-control/contact';
import { TimeZonePipe } from 'app/utils/time-zone.pipe';
import { mapCalls } from 'pages/call-control/utils/calls-mapping';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderService {
  loading = true;
  canModifyDate = false;
  readonly dialog = inject(MatDialog);
  workOrderDetails = signal<AdsCallControl>({});
  equipmentOnSite: Observable<AdsEquipmentOnSite[]> | undefined;
  workOrder = '';
  status = '';
  searchText = '';
  statuses: SelectOption<string>[] = [];
  pmDetails = signal<AdsPMDetails>({});
  attachments = signal<AdsAttachments[]>([]);
  leaveSite = false;
  readonly dialogRef = inject(MatDialog);
  readonly _bottomSheet = inject(MatBottomSheet);
  readonly _snackBar = inject(MatSnackBar);
  otherTechsOnSite = '';
  email = '';
  phone = '';
  contactName = '';
  completeRegisterModel: CompleteRegisterModel[] = [
    {
      date: new Date().toLocaleString(),
      description: 'some desc',
      qty: 1,
    },
  ];
  missingSLAreasons: SelectOption<string>[] = [
    {
      name: 'Scheduled at customer request',
      value: 'Scheduled at customer request',
    },
    {
      name: 'Vendor meet required',
      value: 'Vendor meet required',
    },
    {
      name: 'Parts ordered/needed',
      value: 'Parts ordered/needed',
    },
    {
      name: 'Time lock overwind',
      value: 'Time lock overwind',
    },
    {
      name: 'Landlord related',
      value: 'Landlord related',
    },
    {
      name: 'Weather related',
      value: 'Weather related',
    },
  ];

  constructor(
    private readonly service: ApiService,
    private readonly authService: AuthService,
    private readonly callControlService: CallControlService,
    private readonly router: Router,
    public readonly headerService: HeaderService,
    public readonly layoutNavService: LayoutNavService
  ) {}

  init(workOrderCallback?: () => void) {
    if (this.authService.authenticated && this.workOrder?.length > 0 && this.workOrder.startsWith('WO')) {
      if (this.workOrderDetails().workorder) {
        this.setWorkOrderDetails(undefined, workOrderCallback);
        return;
      }
      const timeZonePipe = new TimeZonePipe();
      this.callControlService.callControlList({}).subscribe((calls) => {
        const call = calls.items.filter((call) => call.workorder === this.workOrder);
        this.setWorkOrderDetails(mapCalls(call, timeZonePipe)[0], workOrderCallback);
      });
    } else {
      this.router.navigate([RoutesEnum.CallControl]);
    }
  }

  loadContactData() {
    const wo = this.workOrder;
    if (wo) {
      this.getContactMethodByWorkOrder(wo).subscribe((response) => {
        this.email = response?.contactMethodEmail ?? this.email;
        this.phone = response?.contactMethodPhone ?? this.phone;
        this.contactName = response?.contactMethodName ?? this.contactName;
      });
    }
  }

  private readonly setWorkOrderDetails = (call?: AdsCallControl, workOrderCallback?: () => void) => {
    this.setPMDetails();
    this.setDetails(call ?? this.workOrderDetails());
    if (workOrderCallback) {
      workOrderCallback();
    }

    if (this.workOrderDetails().recid) {
      this.setAttachments();
    }

    if (this.workOrderDetails().isArticleAssociated) {
      this.headerService.showMenuHighlight = true;
    }
  };

  setDetails = (details: AdsCallControl) => {
    this.workOrderDetails.set(details);
    details.laststatusname = details.laststatusname === 'Ack' ? 'Acknowledged' : details.laststatusname;
    this.status = details.laststatusname ?? '';
    this.refreshStatusOptions();
    this.loading = false;
    if (details.masterAccount) {
      this.equipmentOnSite = this.getEquipmentOnSite(details.nbr);
      if (details.laststatusname) {
        this.headerService.title.set(this.workOrder);
        this.headerService.titleAnnex.set({
          text: details.laststatusname,
          backgroundColor: statusColors.get(details.laststatusname ?? 'Started') ?? '',
          color: details.laststatusname === 'Scheduled' ? 'white' : '#242424',
          border: details.laststatusname === 'Scheduled' ? '1px solid white' : '',
        });
      }
    }
  };

  setPMDetails = () => {
    this.getPMDetails(this.workOrder).subscribe((details) => {
      this.pmDetails.set(details);
    });
  };

  setAttachments = () => {
    this.getAttachments(this.workOrderDetails().recid!).subscribe((attachments) => {
      this.attachments.set(attachments);
    });
  };

  handlePMForm = () => {
    if (this.pmDetails().formType === 'Agreement Survey') {
      this.getASUrl().subscribe((url) => {
        window.open(encodeURI(url), '_blank');
      });
      return;
    }

    if (this.pmDetails().formType === 'Preventative Maintenance') {
      this.dialog.open(LaunchFormDialogComponent);
      return;
    }

    this.launchPMForm(AdsPMFormType[this.pmDetails().formType as never]);
  };

  handleSiteProfile = () => {
    this.getSiteProfileUrl().subscribe((url) => {
      window.open(encodeURI(url), '_blank');
    });
  };

  launchPMForm = (pmURL: string) => {
    const url = this.router.createUrlTree([`/PM/${pmURL}/${this.workOrder}`]);
    window.open(url.toString(), '_blank');
  };

  refreshStatusOptions() {
    const currentStatus = this.workOrderDetails().laststatusname as WorkOrderStatus;
    this.status = this.workOrderDetails().laststatusname ?? '';
    const nextStatuses = nextStatus.get(currentStatus)?.map((status) => ({ value: status, name: status })) ?? [];
    this.statuses = [{ name: currentStatus, value: currentStatus }, ...nextStatuses];
  }

  openTicketNotes = async () => {
    this.getTicketNotes(this.workOrderDetails().ticketRecId).subscribe((notes) => {
      if (notes.noteInfo.length > 0) {
        this.dialog.open(TicketDialogComponent, {
          data: notes.noteInfo[0],
        });
      }
    });
  };

  statusSelected = (status: string) => {
    this.canModifyDate = false;
    let targetComponent: ComponentType<unknown> | undefined;
    switch (status) {
      case WorkOrderStatus.Suspended:
        this.leaveSite = true;
        targetComponent = SuspendComponent;
        break;
      case WorkOrderStatus.Opened:
        break;
      case WorkOrderStatus.Acknowledged:
        targetComponent = AcknowledgeComponent;
        break;
      case WorkOrderStatus.Scheduled:
        targetComponent = ScheduleComponent;
        break;
      case WorkOrderStatus.Notified:
        break;
      case WorkOrderStatus.Started:
        targetComponent = StartCallComponent;
        break;
      case WorkOrderStatus.Travelling:
        targetComponent = TravellingComponent;
        break;
      case WorkOrderStatus.Completed: {
        this.leaveSite = false;
        this.getPMDetails(this.workOrder).subscribe((response) => {
          if (response.formType == 'Agreement Survey' || response.workStatusByName?.toLowerCase() == 'submitted') {
            this.showModal(CompleteCallComponent);
          } else {
            this._snackBar.open(
              'The PM Form associated with this WO is not yet submitted. Please submit the PM Form to complete the WO.',
              'OK'
            );
          }
        });
        break;
      }
      default:
        console.error('Invalid status');
    }
    if (targetComponent) {
      const disableClose = status !== WorkOrderStatus.Travelling;
      this.showModal(targetComponent, disableClose);
    }

    setTimeout(() => {
      this.refreshStatusOptions();
    });
  };

  showModal(targetComponent: ComponentType<unknown>, disableClose = true) {
    if (this.layoutNavService.isDesktopView()) {
      this.dialog.open(targetComponent, { disableClose });
    } else {
      this._bottomSheet.open(targetComponent, { disableClose });
    }
  }

  openPhone = () => {
    if (this.phone) {
      window.location.href = 'tel:' + this.phone;
    }
  };

  openEmail = () => {
    if (this.email) {
      window.location.href = 'mailto:' + this.email;
    }
  };

  openMap = async () => {
    const wo = this.workOrderDetails();
    if (wo?.address && wo?.address?.length > 0) {
      const fullAddress = wo.address + ' , ' + wo.city + ', ' + wo.state + ' ' + wo.zip;
      window.open('https://maps.google.com/maps?q=' + fullAddress, '_blank');
    }
  };

  openEquipmentOnSite = async () => {
    this.getEquipmentOnSiteUrl(this.workOrderDetails().workorder).subscribe((url) => {
      window.open(encodeURI(url), '_blank');
    });
  };

  getStatusTimestamp(body: CheckTimeStampCommand): Observable<CallControlDateModel> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    queryParameters = queryParameters.set('workOrder', body.workOrder ?? '');
    queryParameters = queryParameters.set('utcOffset', body.utcOffset ?? new Date().getTimezoneOffset() / -60);
    if (body.statusToCheck) {
      queryParameters = queryParameters.set('statusId', body.statusToCheck);
    }
    return this.service.sendRequest<CallControlDateModel>({
      urlPrefix: 'ax',
      method: 'get',
      url: `callcontrol/timestamp`,
      params: queryParameters,
    });
  }

  /**
   * Get last tech note for complete
   *
   * @param workOrder Work order
   */
  public getLastTechNote(workOrder?: string): Observable<CompleteWoNoteModel[]> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (workOrder !== undefined && workOrder !== null) {
      queryParameters = queryParameters.set('workOrder', workOrder);
    }
    return this.service.sendRequest<CompleteWoNoteModel[]>({
      method: 'get',
      url: `callcontrol/getlasttechnoteforcomplete`,
      params: queryParameters,
    });
  }

  /**
   * Retrieve labor by work order.
   *
   * @param workOrder Work order
   */
  public getLaborFromCC(workOrder?: string): Observable<Labor[]> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (workOrder !== undefined && workOrder !== null) {
      queryParameters = queryParameters.set('workOrder', workOrder);
    }
    return this.service.sendRequest<Labor[]>({
      method: 'get',
      url: `callcontrol/getlaborandparttiminglistfromcc`,
      params: queryParameters,
    });
  }

  /**
   * Retrieve part requested by service order and project id.
   *
   * @param serviceOrder Service order
   * @param projId Project id
   */
  public getPartsDetailList(serviceOrder?: string, projId?: string): Observable<PartDetail[]> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (serviceOrder !== undefined && serviceOrder !== null) {
      queryParameters = queryParameters.set('workOrder', serviceOrder);
    }
    if (projId !== undefined && projId !== null) {
      queryParameters = queryParameters.set('projId', projId);
    }
    return this.service.sendRequest<PartDetail[]>({
      urlPrefix: 'ax',
      method: 'get',
      url: `part-request/labor-part`,
      params: queryParameters,
    });
  }

  /**
   * Gets a call list according with the parameters used into the request
   *
   * @param techIdMaster technician id
   * @param isMyOpenCalls flag to indicate when the result will be filtered by open  status call
   */
  public getEquipmentOnSite(branchAccount?: string): Observable<AdsEquipmentOnSite[]> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (branchAccount !== undefined && branchAccount !== null) {
      queryParameters = queryParameters.set('branchAccount', branchAccount);
    }

    return this.service.sendRequest<AdsEquipmentOnSite[]>({
      method: 'get',
      url: `equipment/onsiteequipmentsbybranchaccount`,
      params: queryParameters,
    });
  }

  /**
   * Gets a URL according to the parameters in the request.
   *
   * @param workOrder Work order
   */
  public getEquipmentOnSiteUrl(workOrder?: string): Observable<string> {
    if (!this.authService.user?.personnelNumber || !workOrder) {
      throw new Error('User not authenticated');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() })
      .set('workOrder', workOrder)
      .set('type', 'EQ')
      .set('techIdMaster', this.authService.user?.personnelNumber);

    return this.service.sendRequest<string>({
      method: 'get',
      url: 'layout/url',
      params: queryParameters,
    });
  }

  public getTicketNotes(ticketNumber?: string): Observable<AdsTicketNotes> {
    if (!ticketNumber) {
      throw new Error('Ticket number is required');
    }

    return this.service.sendRequest<AdsTicketNotes>({
      method: 'get',
      url: `support/tickets/${ticketNumber}/notes`,
      urlPrefix: 'ccc',
    });
  }

  public getPMDetails(workOrder: string): Observable<AdsPMDetails> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() }).set(
      'serviceOrderId',
      workOrder
    );

    return this.service.sendRequest<AdsPMDetails>({
      method: 'get',
      url: `callcontrol/pm-details`,
      params: queryParameters,
    });
  }

  public getASUrl(): Observable<string> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() })
      .set('workOrder', this.workOrder)
      .set('type', 'AS')
      .set('techIdMaster', this.authService.user?.personnelNumber ?? '');

    return this.service.sendRequest<string>({
      method: 'get',
      url: 'layout/url',
      params: queryParameters,
    });
  }

  public getSiteProfileUrl(): Observable<string> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() })
      .set('workOrder', this.workOrder)
      .set('type', 'SP')
      .set('techIdMaster', this.authService.user?.personnelNumber ?? '');

    return this.service.sendRequest<string>({
      method: 'get',
      url: 'layout/url',
      params: queryParameters,
    });
  }

  public getAttachments(recId: number): Observable<AdsAttachments[]> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() }).set('recId', recId);

    return this.service.sendRequest<AdsAttachments[]>({
      method: 'get',
      url: `attachments`,
      params: queryParameters,
    });
  }

  public getAttachmentFile(attachmentRecId: number): Observable<AttachmentEntity> {
    return this.service.sendRequest<AttachmentEntity>({
      method: 'get',
      url: `attachments/${attachmentRecId}`,
    });
  }

  public deleteAttachment(documentId: string): Observable<void> {
    return this.service.sendRequest<void>({
      method: 'delete',
      url: `attachments/${documentId}`,
    });
  }

  public uploadAttachmentFile(body: AdsAttachmentUpload): Observable<void> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() }).set(
      'workorder',
      this.workOrder
    );

    if (this.authService.user?.userId) {
      queryParameters = queryParameters.set('userId', this.authService.user?.userId);
    }

    return this.service.sendRequest<void>({
      method: 'post',
      url: `attachments`,
      params: queryParameters,
      body: body,
    });
  }

  public getRelatedArticles(): Observable<AdsRelatedArticle[]> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() }).set(
      'workOrder',
      this.workOrder
    );

    return this.service.sendRequest<AdsRelatedArticle[]>({
      method: 'get',
      url: `callcontrol/related-articles`,
      params: queryParameters,
    });
  }

  public getOpenCalls(): Observable<AdsOpenCall[]> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() })
      .set('customerAccount', this.workOrderDetails().nbr ?? '')
      .set('serviceOrderToExclude', this.workOrder);

    return this.service.sendRequest<AdsOpenCall[]>({
      method: 'get',
      url: `callcontrol/open-calls`,
      params: queryParameters,
    });
  }

  public getAssociatedEquipmentByWorkOrder(workOrder?: string): Observable<EquipmentEntityModel[]> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (workOrder !== undefined && workOrder !== null) {
      queryParameters = queryParameters.set('workOrder', workOrder);
    }

    return this.service.sendRequest<EquipmentEntityModel[]>({
      method: 'get',
      url: `callcontrol/getassociatedequipmentbyworkorder`,
      params: queryParameters,
    });
  }

  public updateAssociatedEquipment(body?: {
    serviceOrderId?: string;
    associatedEquipments?: AssociatedEquipmentModel[];
  }): Observable<void> {
    return this.service.sendRequest<void>({
      method: 'post',
      url: `callcontrol/insertupdateassociatedequipmentforserviceorder`,
      body,
    });
  }

  public completeCall(body: InsertCompleteCallCommand): Observable<void> {
    return this.service.sendRequest<void>({
      method: 'post',
      url: `callcontrol/insertcompletecall`,
      body,
    });
  }

  public completeRegister(body: CompleteRegisterRequest): Observable<CompleteRegisterModel[]> {
    return this.service.sendRequest<CompleteRegisterModel[]>({
      method: 'post',
      url: `callcontrol/completeregister`,
      body,
    });
  }

  public getCustomerEquipmentList(masterAccount?: string, branchAccount?: string): Observable<EquipmentEntityModel[]> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (masterAccount !== undefined && masterAccount !== null) {
      queryParameters = queryParameters.set('masterAccount', masterAccount);
    }
    if (branchAccount !== undefined && branchAccount !== null) {
      queryParameters = queryParameters.set('branchAccount', branchAccount);
    }
    return this.service.sendRequest<EquipmentEntityModel[]>({
      method: 'get',
      url: `callcontrol/getcustomerequipmentlist`,
      params: queryParameters,
    });
  }

  public processTravelBack(body: ProcessTravelBackCommand): Observable<OperationResponse> {
    return this.service.sendRequest<OperationResponse>({
      method: 'post',
      url: `callcontrol/processtravelback`,
      body,
    });
  }

  public getContactMethodByWorkOrder(workOrder?: string, isMobileCall?: boolean): Observable<ContactMethodEntityModel> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (workOrder !== undefined && workOrder !== null) {
      queryParameters = queryParameters.set('workOrder', workOrder);
    }

    if (isMobileCall !== undefined && isMobileCall !== null) {
      queryParameters = queryParameters.set('isMobileCall', isMobileCall);
    }
    return this.service.sendRequest<ContactMethodEntityModel>({
      method: 'get',
      url: `callcontrol/getPreferredContactInfoByWorkOrder`,
      params: queryParameters,
    });
  }
}
