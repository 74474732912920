import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { CallControlFiltersComponent } from "./call-control-filters/call-control-filters.component";
import { MatIconModule } from "@angular/material/icon";
import { AdsCallControl } from "../call-control.service";
import { ThemingService } from "services";

@Component({
  selector: 'ads-service-calls',
  standalone: true,
  imports: [CommonModule, CallControlFiltersComponent, MatIconModule],
  templateUrl: './service-calls.component.html',
  styleUrl: './service-calls.component.scss',
})
export class ServiceCallsComponent {
  @Input() isDesktopView = false;
  @Input() calls: AdsCallControl[] = [];
  statusColors = new Map<string, string>([
    ['Travelling', '#F1F1F1'],
    ['Notified', '#7777F1'],
    ['Opened', '#FFEBAD'],
    ['Started', '#77EC95'],
    ['Scheduled', '#1B1B1B'],
  ]);

  constructor(public readonly theming: ThemingService) {}

}
