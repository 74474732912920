import { CommonModule } from '@angular/common';
import { Component, ViewChild, OnDestroy, AfterViewInit, OnInit } from '@angular/core';
import { FormGroup, FormsModule, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService, PhotoUploadControlService } from 'services';
import { HeaderService } from 'shared/header/header.service';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { OrderInfoComponent } from 'shared/order-info/order-info.component';
import { StickyHeaderService } from 'shared/sticky-header/sticky-header.service';
import { ItmService } from './itm-pm.service';
import { ItmResponse, ItmResponseDropdowns, mapItmDropdowns, mapItmForm } from 'models/api';
import { ItmDropdowns, ItmForm } from 'models';
import { ItmSpecsSectionComponent } from 'forms/itm/itm-specs-section/itm-specs-section.component';
import { ItmSoftwareSectionComponent } from 'forms/itm/itm-software-section/itm-software-section.component';
import { ItmTasksSectionComponent } from 'forms/itm/itm-tasks-section/itm-tasks-section.component';
import { ItmPeripheralTasksSectionComponent } from 'forms/itm/itm-peripheral-tasks-section/itm-peripheral-tasks-section.component';
import { ItmReceiptPrinterTasksSectionComponent } from 'forms/itm/itm-receipt-printer-tasks-section/itm-receipt-printer-tasks-section.component';
import { ItmCashDispenserTasksSectionComponent } from 'forms/itm/itm-cash-dispenser-tasks-section/itm-cash-dispenser-tasks-section.component';
import { ItmFasciaAdaTasksSectionComponent } from 'forms/itm/itm-fascia-ada-tasks-section/itm-fascia-ada-tasks-section.component';
import { ItmCashCheckDepositoryTasksSectionComponent } from 'forms/itm/itm-cash-check-depository-tasks-section/itm-cash-check-depository-tasks-section.component';
import { ItmVacuumingBlowOutTasksSectionComponent } from 'forms/itm/itm-vacuuming-blow-out-tasks-section/itm-vacuuming-blow-out-tasks-section.component';
import { itmPhotoUploads } from 'utils';
import { PhotosSectionComponent } from 'forms';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { FormPageComponent } from 'pages/form-page/form-page';

@Component({
  selector: 'ads-itm-pm',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    ExpansionPanelComponent,
    FormsModule,
    OrderInfoComponent,
    ItmSpecsSectionComponent,
    ItmSoftwareSectionComponent,
    ItmTasksSectionComponent,
    ItmPeripheralTasksSectionComponent,
    ItmReceiptPrinterTasksSectionComponent,
    ItmCashDispenserTasksSectionComponent,
    ItmFasciaAdaTasksSectionComponent,
    ItmCashCheckDepositoryTasksSectionComponent,
    ItmVacuumingBlowOutTasksSectionComponent,
    PhotosSectionComponent,
    CommonTextAreaComponent,
  ],
  providers: [PhotoUploadControlService],
  templateUrl: './itm-pm.component.html',
  styleUrl: './itm-pm.component.scss',
})
export class ItmPmComponent
  extends FormPageComponent<ItmService, ItmForm, ItmResponse, ItmDropdowns, ItmResponseDropdowns>
  implements OnDestroy, AfterViewInit, OnInit
{
  @ViewChild('itmForm', { static: true }) override form?: NgForm;
  @ViewChild('itmSpecsSectionPanel', { static: true }) itmSpecs?: ExpansionPanelComponent;
  @ViewChild('softwareSectionPanel', { static: true }) software?: ExpansionPanelComponent;
  @ViewChild('itmTasksSectionPanel', { static: true }) itmTasks?: ExpansionPanelComponent;
  @ViewChild('itmPeripheralTasksSectionPanel', { static: true }) itmPeripheralTasks?: ExpansionPanelComponent;
  @ViewChild('itmReceiptPrinterTasksSectionPanel', { static: true }) itmReceiptPrinterTasks?: ExpansionPanelComponent;
  @ViewChild('itmCashDispenserTasksSectionPanel', { static: true }) itmCashDispenserTasks?: ExpansionPanelComponent;
  @ViewChild('itmFasciaAndAdaTasksSectionPanel', { static: true }) itmFasciaAndAdaTasks?: ExpansionPanelComponent;
  @ViewChild('itmCashCheckDepositorySectionPanel', { static: true })
  itmCashCheckDepositoryTasks?: ExpansionPanelComponent;
  @ViewChild('itmVacuumingBlowOutSectionPanel', { static: true }) itmVacuumingBlowOutTasks?: ExpansionPanelComponent;
  @ViewChild('itmPhotosSectionPanel', { static: true }) photos?: ExpansionPanelComponent;

  override sections = {};

  override sectionNames = [
    'itmSpecs',
    'software',
    'itmTasks',
    'itmPeripheralTasks',
    'itmReceiptPrinterTasks',
    'itmCashDispenserTasks',
    'itmFasciaAndAdaTasks',
    'itmCashCheckDepositoryTasks',
    'itmVacuumingBlowOutTasks',
    'photoUploads',
  ];

  override mapForm = mapItmForm;
  override mapDropdowns = mapItmDropdowns;
  override photoUploads = itmPhotoUploads;

  constructor(
    private readonly itmService: ItmService,
    headerService: HeaderService,
    router: Router,
    stickyHeaderService: StickyHeaderService,
    loaderService: LoaderService,
    route: ActivatedRoute,
    snackBar: MatSnackBar,
    private readonly pucs: PhotoUploadControlService
  ) {
    super(
      itmService,
      headerService,
      router,
      stickyHeaderService,
      loaderService,
      route,
      snackBar,
    );
  }

  ngOnInit() {
    this.photoUploadForm = this.pucs.toFormGroup(itmPhotoUploads);
    this.form?.control.addControl('photoUploads', this.photoUploadForm);
    this.sections = {
      itmSpecs: this.itmSpecs,
      software: this.software,
      itmTasks: this.itmTasks,
      itmPeripheralTasks: this.itmPeripheralTasks,
      itmReceiptPrinterTasks: this.itmReceiptPrinterTasks,
      itmCashDispenserTasks: this.itmCashDispenserTasks,
      itmFasciaAndAdaTasks: this.itmFasciaAndAdaTasks,
      itmCashCheckDepositoryTasks: this.itmCashCheckDepositoryTasks,
      itmVacuumingBlowOutTasks: this.itmVacuumingBlowOutTasks,
      photoUploads: this.photos,
    };
  }

  ngOnDestroy(): void {
    this.stickyHeaderService.enabled = false;
  }

  ngAfterViewInit() {
    this.form?.control.valueChanges.subscribe((values) => (this.itmService.form = values));
    this.headerService.resetForm = this.resetForm;
    this.headerService.title.set('ITM Form');
    setTimeout(() => this.setDefault());
  }

  getSection(sectionName: string) {
    return this.form?.controls[sectionName] as FormGroup | undefined;
  }
}
