<div *ngIf="isDesktopView" class="search-box">
  <ads-common-form-input
    label="Search"
    [maxLength]="50"
    [(ngModel)]="service.searchText"
    (ngModelChange)="onSearchChanged()"
  ></ads-common-form-input>
  <mat-icon>search</mat-icon>
</div>
<div *ngIf="isDesktopView" class="filter-buttons">
  <span class="filter-title">Filter by: </span>
  <ads-date-filtering
    #scheduledFilter
    label="Scheduled"
    [isDesktopView]="true"
    [minDate]="minDate"
    (confirm)="onScheduledDateFilterChanged()"
    (fromDateChanged)="scheduledFromDate = $event"
    (toDateChanged)="scheduledToDate = $event"
  ></ads-date-filtering>
  <ads-date-filtering
    #onSiteFilter
    label="On-site by"
    [isDesktopView]="true"
    [minDate]="minDate"
    (confirm)="onOnSiteByDateFilterChanged()"
    (fromDateChanged)="onSiteByFromDate = $event"
    (toDateChanged)="onSiteByToDate = $event"
  ></ads-date-filtering>
  <ads-radio-filtering
    [isFilterApplied]="service.statusFilter.value !== 'none'"
    [value]="service.statusFilter.value"
    [filteringOptions]="service.statusFilter.options ?? []"
    label="Status"
    (filterChanged)="onStatusFilterChanged($event)"
  ></ads-radio-filtering>
  <ads-radio-filtering
    [isFilterApplied]="service.priorityFilter.value !== 'none'"
    [value]="service.priorityFilter.value"
    [filteringOptions]="service.priorityFilter.options ?? []"
    label="Priority"
    (filterChanged)="onPriorityFilterChanged($event)"
  ></ads-radio-filtering>
  <button
    *ngIf="
      scheduledFromDate !== undefined ||
      scheduledToDate !== undefined ||
      onSiteByFromDate !== undefined ||
      onSiteByToDate !== undefined ||
      service.statusFilter.value !== 'none' ||
      service.priorityFilter.value !== 'none'
    "
    mat-icon-button
    (click)="clearFilters()"
    class="clear-filters-button"
  >
    <mat-icon class="material-icons-outlined">filter_alt_off</mat-icon>
  </button>
  <span class="separator"></span>
  <ads-desktop-sorting [label]="'Sort'" (sortChanged)="onSortingChanged()"></ads-desktop-sorting>
</div>
<div *ngIf="!isDesktopView" class="mobile-wrapper">
  <div class="mobile-search-box">
    <div class="search-box">
      <ads-common-form-input
        label="Search"
        [maxLength]="50"
        [(ngModel)]="service.searchText"
        (ngModelChange)="onSortingChanged()"
      >
      </ads-common-form-input>
      <mat-icon>search</mat-icon>
    </div>
    <ads-mobile-sorting
      (menuOpened)="service.onSortOpened()"
      (menuDismissed)="service.onSortDismissed()"
      (sortChanged)="onSortingChanged()"
    ></ads-mobile-sorting>
  </div>
  <div class="filter-buttons mobile-filter-buttons">
    <div class="inner-filters-wrapper">
      <span class="filter-by-title">Filter by:</span>
      <div class="filters-wrapper">
        <ads-mobile-date-filtering
          label="Scheduled"
          [minDate]="minDate"
          (fromDateChanged)="scheduledFromDate = $event"
          (toDateChanged)="scheduledToDate = $event"
          (dateFilterConfirmed)="onScheduledDateFilterChanged()"
        ></ads-mobile-date-filtering>
        <ads-mobile-date-filtering
          label="On-site by"
          [minDate]="minDate"
          (fromDateChanged)="onSiteByFromDate = $event"
          (toDateChanged)="onSiteByToDate = $event"
          (dateFilterConfirmed)="onOnSiteByDateFilterChanged()"
        ></ads-mobile-date-filtering>
        <ads-mobile-radio-filtering
          label="Status"
          [filteringOptions]="service.statusFilter.options ?? []"
          [isFilterApplied]="service.statusFilter.value !== 'none'"
          (filterConfirmed)="onStatusFilterChanged($event)"
        ></ads-mobile-radio-filtering>
        <ads-mobile-radio-filtering
          label="Priority"
          [filteringOptions]="service.priorityFilter.options ?? []"
          [isFilterApplied]="service.priorityFilter.value !== 'none'"
          (filterConfirmed)="onPriorityFilterChanged($event)"
        ></ads-mobile-radio-filtering>
      </div>
    </div>
    <button
      *ngIf="allCalls.length !== calls.length"
      mat-icon-button
      (click)="clearFilters()"
      class="clear-filters-button"
    >
      <mat-icon class="material-icons-outlined">filter_alt_off</mat-icon>
    </button>
  </div>
</div>
