<button
  *ngIf="currentTheme === 'light'"
  class="light"
  mat-button
  mat-stroked-button
  (click)="onClick($event)"
  [matMenuTriggerFor]="matMenu"
  [disabled]="disabled"
>
  <mat-icon *ngIf="icon" iconPositionEnd class="material-icons-outlined">{{ icon }}</mat-icon>
  {{ label }}
</button>
<button
  *ngIf="currentTheme === 'dark'"
  class="dark"
  mat-button
  mat-raised-button
  (click)="onClick($event)"
  [matMenuTriggerFor]="matMenu"
  [disabled]="disabled"
>
  <mat-icon *ngIf="icon" iconPositionEnd class="material-icons-outlined">{{ icon }}</mat-icon>
  {{ label }}
</button>
