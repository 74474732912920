import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { FormsModule, ControlContainer, NgForm, FormGroup, NgModelGroup } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { SelectOption, CompleteNa, YesNo } from 'models';
import { RadioButtonGroupComponent, SingleToggleButtonComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-atm-fascia-ada-tasks-section',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SimpleFormComponent,
    RadioButtonGroupComponent,
    MatDividerModule,
    SingleToggleButtonComponent,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './atm-fascia-ada-tasks-section.component.html',
})
export class AtmFasciaAdaTasksSectionComponent {
  @Input() section?: FormGroup;

  constructor(@Optional() public readonly ngModelGroup: NgModelGroup) {}

  options: SelectOption<CompleteNa>[] = [
    { name: 'Complete', value: CompleteNa.Complete },
    { name: 'N/A', value: CompleteNa.Na },
  ];

  yesNoOptions: SelectOption<YesNo>[] = [
    { name: 'Yes', value: YesNo.Yes },
    { name: 'No', value: YesNo.No },
  ];
}
