import { CommonModule } from '@angular/common';
import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ExpansionPanelComponent, RadioButtonGroupComponent } from 'shared/material-wrappers';
import { OrderInfoComponent } from 'shared/order-info/order-info.component';
import { SecurityService } from './security.service';
import { HeaderService } from 'shared/header/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StickyHeaderService } from 'shared/sticky-header/sticky-header.service';
import { LoaderService } from 'services';
import { PageOpenMode, PageSubmitMode, SecurityResponse, WorkOrderDetailDto, WorkStatus } from 'models/api';
import { getFormGroup, initialSecurityDropdowns } from 'utils';
import { mapSecurityDropdowns, mapSecurityForm } from 'models/api/maps/security-map';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { SecuritySelectedEquipmentComponent } from 'forms/security/security-selected-equipment/security-selected-equipment.component';
import { AlarmComponent } from '../../forms/security/alarm/alarm.component';
import { AccessHardwareComponent } from '../../forms/security/access-hardware/access-hardware.component';
import { NightDropComponent } from '../../forms/security/night-drop/night-drop.component';
import { SafesComponent } from '../../forms/security/safes/safes.component';
import { UcsComponent } from 'forms/security/ucs/ucs.component';
import { VaultComponent } from 'forms/security/vault/vault.component';
import { RtsComponent } from 'forms/security/rts/rts.component';
import { DealDrawerComponent } from '../../forms/security/deal-drawer/deal-drawer.component';
import { AudioVideoComponent } from '../../forms/security/audio-video/audio-video.component';
import { SurveillanceComponent } from 'forms/security/surveillance/surveillance.component';
import { CompleteNa, SelectOption } from 'models';
import { MatBadgeModule } from '@angular/material/badge';
import { RoutesEnum } from 'app/utils/navigation';

@Component({
  selector: 'ads-security',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatTabsModule,
    ExpansionPanelComponent,
    FormsModule,
    OrderInfoComponent,
    SecuritySelectedEquipmentComponent,
    AlarmComponent,
    AccessHardwareComponent,
    UcsComponent,
    NightDropComponent,
    VaultComponent,
    SafesComponent,
    DealDrawerComponent,
    RtsComponent,
    AudioVideoComponent,
    SurveillanceComponent,
    RadioButtonGroupComponent,
    MatBadgeModule,
  ],
  templateUrl: './security.component.html',
  styleUrl: './security.component.scss',
})
export class SecurityComponent implements AfterViewInit {
  @ViewChild('securityForm', { static: true }) form?: NgForm;
  @ViewChild('selectedEquipmentSectionPanel', { static: true }) selectedEquipment?: ExpansionPanelComponent;
  @ViewChild('tabGroup', { static: true }) tabGroup?: MatTabsModule;

  workOrder?: WorkOrderDetailDto;
  customerName = '';
  activeEquipment: Record<string, boolean> = {};
  trySubmit = false;

  securityDropdowns = initialSecurityDropdowns;

  constructor(
    public readonly securityService: SecurityService,
    private readonly headerService: HeaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly stickyHeaderService: StickyHeaderService,
    private readonly loaderService: LoaderService,
    private readonly snackBar: MatSnackBar
  ) {}

  options: SelectOption<CompleteNa>[] = [
    { name: 'Complete', value: CompleteNa.Complete },
    { name: 'N/A', value: CompleteNa.Na },
  ];

  ngAfterViewInit() {
    this.headerService.resetForm = this.resetForm;
    this.headerService.title.set('Security Form');
    setTimeout(() => {
      this.setDefault();
      this.stickyHeaderService.enableStickyHeader('', '');
      this.route.params.subscribe((params) => {
        this.securityService.workOrder = params['work-order'];
        if (this.securityService.workOrder?.length > 0) {
          this.handleSecurityData();
        }
      });
    });
  }

  private handleSecurityData(): void {
    this.securityService.getSecurityForm().subscribe((data) => {
      this.loaderService.show();
      this.workOrder = data.workOrderDetail;

      if (this.getIsFormSubmitted()) {
        this.form?.form.disable();
      } else {
        this.headerService.resetForm = this.resetForm;
        this.headerService.showResetFormButton.set(true);
      }

      this.loadSecurityData(data);

      this.form?.form
        .get('selectedEquipment')
        ?.get('selectedEquipment')
        ?.valueChanges.subscribe((newValue) => {
          if (newValue.length > 0) {
            this.activeEquipment = newValue.reduce(
              (acc: Record<string, boolean>, val: string) => ({ ...acc, [val]: true }),
              {}
            );
          }
        });

      this.form?.form.controls['selectedEquipment'].setValue({
        selectedEquipment: this.securityService.securityForm.selectedEquipment,
      });

      (this.form?.form.controls['applyCSGProtectionDecalInBottomRightCorner'] as FormGroup).controls[
        'applyCSGProtectionDecalInBottomRightCorner'
      ].setValue(this.securityService.securityForm.applyCSGProtectionDecalInBottomRightCorner);

      this.loaderService.hide();
    });
  }

  loadSecurityData = (data: SecurityResponse) => {
    this.customerName = data.customerName;
    this.stickyHeaderService.enableStickyHeader(data.customerName, data.workOrderDetail.city);
    this.securityDropdowns = mapSecurityDropdowns(data.dropdowns);
    this.securityService.securityForm = mapSecurityForm(data.security);
    this.securityService.loadAlarmData(data.security.security_Alarm, this.securityDropdowns.alarm);
    this.securityService.loadAccessHardwareData(data.security, this.securityDropdowns.accessControlHardware);
    this.securityService.loadNightDropData(data.security.security_NightDrop, this.securityDropdowns.nightDrop);
    this.securityService.loadDealDrawerData(data.security.security_DealDrawer, this.securityDropdowns.dealDrawer);
    this.securityService.loadSafesData(data.security.security_Safes, this.securityDropdowns.safes);
    this.securityService.loadRtsData(data.security.security_RTS, this.securityDropdowns.rts);
    this.securityService.loadVaultData(data.security.security_Vault, this.securityDropdowns.vault);
    this.securityService.loadAudioVideoData(data.security.security_AudioVideoDU, this.securityDropdowns.audioVideoDU);
    this.securityService.loadUCSData(data.security, this.securityDropdowns.ucs);
    this.securityService.loadSurveillanceData(data.security, this.securityDropdowns.surveillance);
  };

  setDefault = () => {};

  resetForm = () => {
    this.handleSecurityData();
  };

  saveForm = () => {
    if (!this.workOrder) {
      return;
    }

    this.securityService
      .saveSecurityForm({
        fieldsForm: {
          ...this.securityService.parseSecurityForm(this.activeEquipment, this.form?.form),
          selectedEquipment: (this.form?.form.get('selectedEquipment') as FormGroup).get('selectedEquipment')?.value,
          applyCSGProtectionDecalInBottomRightCorner:
            this.form?.form?.value.applyCSGProtectionDecalInBottomRightCorner
              .applyCSGProtectionDecalInBottomRightCorner,
        },
        workOrderDetail: this.workOrder,
        pageSubmitMode: PageSubmitMode.PAGE_SUBMIT_MODE_SAVE,
        pageOpenMode: this.workOrder.workStatus === null ? PageOpenMode.PAGE_MODE_ADD : PageOpenMode.PAGE_MODE_UPDATE,
      })
      .subscribe(() => this.openSnackBar('Security Form saved successfully!', 'Close'));
  };

  focusFirstInvalidControl = () => {
    const sections = [
      'alarm',
      'surveillance',
      'vault',
      'safes',
      'ucs',
      'nightDrop',
      'accessHardware',
      'dealDrawer',
      'rts',
      'audioVideo',
    ];

    for (let idx = 0; idx < sections.length; idx++) {
      const section = sections[idx];
      const control = this.form?.form.controls[section];
      if (control?.invalid) {
        this.selectedIndex = idx;
        setTimeout(() => {
          this.checkSectionValidity(control);
        }, 200);
        break;
      }
    }
  };

  checkSectionValidity = (section: AbstractControl) => {
    let scrolled = false;
    const form = getFormGroup(section);
    Object.keys(form.controls).forEach((groupKey: string) => {
      const formGroup = form.controls[groupKey] as FormGroup;
      if (formGroup.invalid) {
        Object.keys(formGroup.controls).forEach((key: string) => {
          const formControl = formGroup.controls[key];
          if (formControl.invalid) {
            if (!scrolled) {
              scrolled = true;
              const isField = document.querySelector('[name="' + key + '"]');
              if (isField) {
                const sectionElement = document.getElementsByClassName(groupKey)[0] as HTMLElement;
                sectionElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                setTimeout(() => document.dispatchEvent(new CustomEvent(`expand-${groupKey}`, {})), 400);
              } else {
                // If not a field, we assume it is an expansion panel that we need to navigate to
                const sectionElement = document.getElementsByClassName(key)[0] as HTMLElement;
                sectionElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                setTimeout(() => document.dispatchEvent(new CustomEvent(`expand-${key}`, {})), 400);
              }
            }
          }
        });
      }
    });
  };

  onSubmit = () => {
    if (!this.workOrder) {
      return;
    }

    this.form?.form.markAllAsTouched();
    this.trySubmit = true;
    if (!this.form?.valid) {
      this.focusFirstInvalidControl();
      return;
    }

    this.securityService
      .saveSecurityForm({
        fieldsForm: {
          ...this.securityService.parseSecurityForm(this.activeEquipment, this.form?.form),
          selectedEquipment: (this.form?.form.get('selectedEquipment') as FormGroup).get('selectedEquipment')?.value,
          applyCSGProtectionDecalInBottomRightCorner:
            this.form?.form?.value.applyCSGProtectionDecalInBottomRightCorner
              .applyCSGProtectionDecalInBottomRightCorner,
        },
        workOrderDetail: this.workOrder,
        pageSubmitMode: PageSubmitMode.PAGE_SUBMIT_MODE_SUBMIT,
        pageOpenMode: this.workOrder.workStatus === null ? PageOpenMode.PAGE_MODE_ADD : PageOpenMode.PAGE_MODE_UPDATE,
      })
      .subscribe(() => this.router.navigate([`${RoutesEnum.Submitted}/${RoutesEnum.Security}`]));
  };

  getSection(sectionName: string) {
    return this.form?.controls[sectionName] as FormGroup | undefined;
  }

  showTabGroup(): boolean {
    const selectedEquipment = (this.form?.form.get('selectedEquipment') as FormGroup)?.get('selectedEquipment');
    return selectedEquipment?.value.length > 0;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      verticalPosition: 'top',
      panelClass: 'snackbar-position',
      duration: 5000,
    });
  }

  selectedIndex = 0;
  onTabChanged(event: MatTabChangeEvent) {
    this.selectedIndex = event.index;
    const element = document.getElementById('mat-tab-label-0-' + Math.max(0, event.index - 1));
    document.getElementsByClassName('mat-mdc-tab-label-container')[0].scrollLeft = element?.offsetLeft ?? 0;
  }

  getIsFormSubmitted = () => {
    return !!this.workOrder?.workStatus && this.workOrder.workStatus === WorkStatus.Submitted;
  };

  getTabStatus = (tabName: string) => {
    if (this.form?.form.controls[tabName]?.invalid && this.trySubmit) {
      return 'warn';
    }

    if (this.form?.form.controls[tabName]?.valid) {
      return 'valid';
    }

    return '';
  };

  getFm(formGroupName: string) {
    const fg = getFormGroup(this.form?.form.controls[formGroupName]);
    return fg;
  }
}
