<div class="buttons-container" [ngClass]="{ 'container-desktop': isDesktop }">
  <button mat-stroked-button class="home-button" type="button" (click)="navigateToCC()">
    <mat-icon class="material-icons-outlined">shield</mat-icon>
    Calls
  </button>
  <button mat-button mat-stroked-button class="home-button" type="button" (click)="navigateEmail()">
    <mat-icon class="material-icons-outlined">mail</mat-icon>
    Email
  </button>
  <button mat-button mat-stroked-button class="home-button" type="button" [matMenuTriggerFor]="formsMenu">
    <mat-icon class="material-icons-outlined">apps</mat-icon>
    Forms
  </button>
  <mat-menu #formsMenu="matMenu" yPosition="below">
    <button mat-menu-item (click)="navigateKaba()">
      <mat-icon class="material-icons-outlined">lock</mat-icon>Kaba Request
    </button>
    <button mat-menu-item (click)="navigateRMA()">
      <mat-icon class="material-icons-outlined">description</mat-icon>RMA Request
    </button>
  </mat-menu>
  <ads-calls-container *ngIf="isDesktop" [newCalls]="newCalls" [openCalls]="openCalls" [isDesktop]="true"></ads-calls-container>
</div>
