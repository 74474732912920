<ads-simple-form #envDepDispTasks="ngModelGroup" ngModelGroup="envDepDispTasks">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button
  ><mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Check and clean all sensors"
    name="cleanAllSensors"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Check and clean all belts"
    name="cleanAllBelts"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Check and clean shutter"
    name="cleanShutter"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Clean ink area"
    name="cleanInkArea"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Replace or clean ink spit pad"
    name="cleanInkPad"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Replace ink cartridge/ribbon"
    name="replaceCartridgeRibbon"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Replenish envelopes in hopper"
    name="replenishEnvelops"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Perform diagnostic test"
    name="diagnosticTests"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
