import { CompleteNa } from '../shared/complete-na';
import { TcdTcrCommonTasks } from './tcd-tcr-common-tasks';

export class TcdTcrCassetteTasks extends TcdTcrCommonTasks {
  cassetteVerifyCassettesNotBentOrDamaged: CompleteNa | null;

  constructor() {
    super();
    this.cassetteVerifyCassettesNotBentOrDamaged = null;
  }
}
