<div class="container">
  <h3>
    <button mat-icon-button (click)="goBack()">
      <mat-icon class="material-icons-outlined">arrow_back</mat-icon>
    </button>
    {{ workOrder }}

    <span
      class="status-chip"
      [ngStyle]="{ background: statusColors.get(workOrderDetails?.laststatusname ?? 'Started') }"
      [ngClass]="{ black: workOrderDetails?.laststatusname === 'Scheduled' }"
    >
      {{ workOrderDetails?.laststatusname }}</span
    >
  </h3>
  <div class="actions-container">
    <button mat-button mat-stroked-button type="button">Open call(s)</button>
    <button mat-button mat-stroked-button type="button">Site profile</button>
    <button mat-button mat-stroked-button type="button">Article(s)</button>
    <button mat-button mat-stroked-button type="button">Support</button>
    <button mat-button mat-stroked-button type="button">Change Priority</button>
    <button mat-button mat-stroked-button type="button">Cancel</button>
  </div>
  <div class="info-container">
    <div class="details-info">
      <ads-panel class="panel">
        <div class="panel-header">
          <strong>Work order details</strong>
        </div>
        <div class="panel-content">
          <div *ngIf="workOrderDetails as details" class="details">
            <p>{{ details.calltype }}</p>
            <ads-detail-row
              name="Scheduled:"
              [value]="details.servicedatetime | date : 'MM/dd/yyyy - HH:mm a'"
            ></ads-detail-row>
            <ads-detail-row name="Problem:" [value]="details.problem"></ads-detail-row>
            <ads-detail-row name="Call Type:" [value]="details.problemTypeId"></ads-detail-row>
            <ads-detail-row
              name="Opened:"
              [value]="details.dateopened | date : 'MM/dd/yyyy - HH:mm a'"
            ></ads-detail-row>
            <ads-detail-row
              name="Ticket#:"
              [value]="details.ticketRecId"
              [valueAction]="openTicketNotes"
            ></ads-detail-row>
            <ads-detail-row name="PO:" [value]="details.customerpo"></ads-detail-row>
            <ads-detail-row
              name="On-site by:"
              [value]="details.onSiteBy | date : 'MM/dd/yyyy - HH:mm a'"
            ></ads-detail-row>
            <ads-detail-row
              name="Resolve by:"
              [value]="details.resolveBy | date : 'MM/dd/yyyy - HH:mm a'"
            ></ads-detail-row>
          </div>
        </div>
      </ads-panel>
      <ads-panel class="panel">
        <div class="panel-header">
          <strong>Customer info</strong>
        </div>
        <div class="panel-content">
          <p></p>
          <div *ngIf="workOrderDetails as details" class="details">
            <ads-detail-row name="Customer:" [value]="details.customer"></ads-detail-row>
            <ads-detail-row name="Search name:" [value]="details.nameAlias"></ads-detail-row>
            <ads-detail-row name="City:" [value]="details.city"></ads-detail-row>
            <ads-detail-row name="Contact:" [value]="details.contact"></ads-detail-row>
            <ads-detail-row name="Phone:" [value]="details.phone" [valueAction]="openPhone"></ads-detail-row>
            <ads-detail-row name="Address:" [value]="details.address" [valueAction]="openMap"></ads-detail-row>
          </div>
        </div>
      </ads-panel>
    </div>
    <ads-panel class="panel">
      <div class="panel-header">
        <strong>Equipment on-site</strong>
      </div>

      <div *ngIf="workOrderDetails as details" class="details">
        <div class="search-header">
          <div class="search-box">
            <ads-common-form-input label="Search" [maxLength]="50" [(ngModel)]="searchText"></ads-common-form-input>
            <mat-icon>search</mat-icon>
          </div>
          <button mat-button mat-stroked-button (click)="openEquipmentOnSite()">
            Open list <mat-icon iconPositionEnd>open_in_new</mat-icon>
          </button>
        </div>
      </div>
    </ads-panel>
  </div>
</div>
