<ads-simple-form *ngIf="item" [ngModelGroup]="'zoneExpanderSection#' + item.index">
  <ads-common-form-input
    name="location"
    label="Location"
    [maxLength]="30"
    [required]="true"
    [value]="item.location"
    [(ngModel)]="item.location"
  ></ads-common-form-input>
</ads-simple-form>
