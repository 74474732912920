import { NightDropFormSection } from 'forms/security/night-drop/night-drop.component';
import { ComboLockSection } from '../shared/combo-lock';
import { TimeClockSection } from '../shared/time-clock';
import { ComboLockTasks, TimeClockTasks } from '../shared/shared-tasks';

export type NightDropComboLock = ComboLockSection;

export type NightDropTimeClock = TimeClockSection;

export interface NightDropSectionForm {
  nightDropModel: string | null;
  nightDropManufacturer: string | null;
  nightDropManufacturer_Other: string | null;
  isNightDropEncasedInConcrete: number | null;
  swingType: string | null;
  overallConditionNightDrop: string | null;
  equipment_Rating_NightDrops: string | null;
  nightDropSafeManufacturer: string | null;
  nightDropSafeManufacturer_Other: string | null;
  nightDropDoorStyle: string | null;
  comboLocks: NightDropComboLock[];
  timeClocks: NightDropTimeClock[];
}

export class NightDropDropDoorTasks {
  checkBoltWorkForProperOperation: number | null;
  inspectBoltWorkForExcessiveWear: number | null;
  cleanAndLubricateBoltWork: number | null;

  constructor() {
    this.checkBoltWorkForProperOperation = null;
    this.inspectBoltWorkForExcessiveWear = null;
    this.cleanAndLubricateBoltWork = null;
  }
}

export class NightDropTimeClockTasks extends TimeClockTasks {
  constructor() {
    super();
  }
}

export class NightDropComboLockTasks extends ComboLockTasks {
  constructor() {
    super();
  }
}

export class NightDropHeadTasks {
  lubricateAndReassembleDialAndRing: number | null;
  checkForProperOperationOfHead: number | null;
  inspectHeadForExcessiveWear: number | null;
  lubricateHeadBoltTrack: number | null;
  cleanHeadWithAppropriateCleaner: number | null;

  constructor() {
    this.lubricateAndReassembleDialAndRing = null;
    this.checkForProperOperationOfHead = null;
    this.inspectHeadForExcessiveWear = null;
    this.lubricateHeadBoltTrack = null;
    this.cleanHeadWithAppropriateCleaner = null;
  }
}

export interface NightDropSecurityForm {
  nightDropSections: NightDropFormSection[];
  nightDropDropDoorTasks: NightDropDropDoorTasks;
  timeClockTasks: NightDropTimeClockTasks;
  comboLockTasks: NightDropComboLockTasks;
  nightDropHeadTasks: NightDropHeadTasks;
  comments: string | null;
}
