<h4 class="title">Schedule</h4>
<ads-on-site-by *ngIf="workOrderService.workOrderDetails().onSiteBy"></ads-on-site-by>
<div class="schedule-form">
  <ads-common-form-input
    label="Name of Site Contact"
    [maxLength]="50"
    [required]="true"
    [(ngModel)]="siteContact"
  ></ads-common-form-input>
  <ads-datepicker [maxDate]="maxDate" [(ngModel)]="scheduleDate" label="Date"></ads-datepicker>
  <ads-timepicker *ngIf="scheduleDate" [dateValue]="scheduleDate" (valueChange)="onTimeChange($event)"></ads-timepicker>
  <ads-select
    *ngIf="onSiteBy && priority !== 'NO SLA' && scheduleDate && onSiteBy < scheduleDate"
    label="Reason for missing SLA"
    [list]="missingSLAreasons"
    [(ngModel)]="missingSLAReason"
  ></ads-select>
</div>
<ads-modal-actions (cancel)="hideAll()" (confirm)="onConfirm()"></ads-modal-actions>
