<div class="menu">
  <button
    mat-button
    mat-flat-button
    class="main-button"
    (click)="launchFormClicked()"
    *ngIf="workOrderService.workOrderDetails().problemTypeId === 'PM' || workOrderService.workOrderDetails().problemTypeId === 'Survey'"
  >
    Launch Form
  </button>
  <ads-custom-prompt label="Open calls" (clicked)="openCallsClicked()"> </ads-custom-prompt>
  <ads-custom-prompt label="Site profile" (clicked)="siteProfileClicked()"> </ads-custom-prompt>
  <ads-custom-prompt
    *ngIf="workOrderService.workOrderDetails().isArticleAssociated"
    label="Articles"
    (clicked)="articleClicked()"
  ></ads-custom-prompt>
  <ads-custom-prompt label="Support" [isDesktopView]="true" (clicked)="supportClicked()"> </ads-custom-prompt>
  <ads-custom-prompt label="Change Priority" (clicked)="changePriorityClicked()"> </ads-custom-prompt>
  <ads-custom-prompt label="Cancel" (clicked)="cancelClicked()"> </ads-custom-prompt>
</div>
<div class="main-actions">
  <ads-select
    label="Status"
    [disabled]="workOrderService.loading"
    [list]="workOrderService.statuses"
    [value]="workOrderService.status"
    [(ngModel)]="workOrderService.status"
    [showEmptyOption]="false"
    (valueChange)="onStatusChange($event)"
  ></ads-select>
  <button
    mat-button
    mat-stroked-button
    (click)="attachmentsClicked()"
    *ngIf="workOrderService.attachments().length === 0"
  >
    Attachments
  </button>
  <button
    mat-button
    class="main-button"
    (click)="attachmentsClicked()"
    *ngIf="workOrderService.attachments().length > 0"
  >
    Attachments
  </button>
</div>
