<ads-simple-form *ngIf="item" class="surveillance-dvr-section-wrapper" [ngModelGroup]="'dvrNvrItem#' + item.index">
  <ads-select
    label="DVR Manufacturer"
    name="dvrManufacturer"
    [list]="dvrNvrItems?.surveillanceDvrDvrManufacturers"
    [value]="item.dvrManufacturer"
    [(ngModel)]="item.dvrManufacturer"
  ></ads-select>
  <ads-common-form-input
    [style.display]="displayOtherManufacturer() ? 'block' : 'none'"
    name="dvrManufacturer_Other"
    label="Other Manufacturer"
    [maxLength]="50"
    [required]="displayOtherManufacturer()"
    [value]="item.dvrManufacturer_Other"
    [(ngModel)]="item.dvrManufacturer_Other"
  ></ads-common-form-input>
  <ads-common-form-input
    name="dvrModel"
    label="DVR model"
    [maxLength]="50"
    [required]="true"
    [value]="item.dvrModel"
    [(ngModel)]="item.dvrModel"
  ></ads-common-form-input>
  <ads-common-form-input
    name="dvrSerialNumber"
    label="DVR serial number"
    [maxLength]="50"
    [required]="true"
    [value]="item.dvrSerialNumber"
    [(ngModel)]="item.dvrSerialNumber"
  ></ads-common-form-input>
  <ads-select
    label="Overall condition - DVR/NVR"
    name="overallCondition_DVRNVR"
    [list]="dvrNvrItems?.surveillanceDVRNVROverallConditions"
    [value]="item.overallCondition_DVRNVR"
    [(ngModel)]="item.overallCondition_DVRNVR"
  ></ads-select>
  <ads-common-text-area
    [style.display]="displayOtherCondition() ? 'block' : 'none'"
    name="equipment_Rating"
    label="Substandard equipment comments"
    [maxLength]="500"
    [required]="displayOtherCondition()"
    [value]="item.equipment_Rating"
    [(ngModel)]="item.equipment_Rating"
  ></ads-common-text-area>
  <ads-common-form-input
    name="softwareVersion"
    label="Software version"
    [maxLength]="50"
    [required]="true"
    [value]="item.softwareVersion"
    [(ngModel)]="item.softwareVersion"
  ></ads-common-form-input>
  <ads-common-form-input
    name="totalChannelsInDVR"
    label="Total channels in DVR"
    type="number"
    [minValue]="0"
    [maxLength]="8"
    [required]="true"
    [value]="item.totalChannelsInDVR"
    [(ngModel)]="item.totalChannelsInDVR"
    inputMode="tel"
  ></ads-common-form-input>
  <ads-common-form-input
    name="totalChannelsAvailableForUse"
    label="Total channels available for use"
    type="number"
    [minValue]="0"
    [maxLength]="8"
    [required]="true"
    [value]="item.totalChannelsAvailableForUse"
    [(ngModel)]="item.totalChannelsAvailableForUse"
    inputMode="tel"
  ></ads-common-form-input>
  <ads-common-form-input
    name="totalAnalogInputs"
    label="Total analog inputs"
    type="number"
    [minValue]="0"
    [maxLength]="8"
    [required]="true"
    [value]="item.totalAnalogInputs"
    [(ngModel)]="item.totalAnalogInputs"
    inputMode="tel"
  ></ads-common-form-input>
  <ads-common-form-input
    name="totalAnalogInputsAvailableForUse"
    label="Total analog inputs"
    type="number"
    tooltip="That are available for use"
    [minValue]="0"
    [maxLength]="8"
    [required]="true"
    [value]="item.totalAnalogInputsAvailableForUse"
    [(ngModel)]="item.totalAnalogInputsAvailableForUse"
    inputMode="tel"
  ></ads-common-form-input>
  <ads-common-form-input
    name="totalHardDriveSize"
    label="Total hard drive size"
    type="number"
    tooltip="Size in gigabytes"
    [minValue]="0"
    [maxLength]="8"
    [required]="true"
    [value]="item.totalHardDriveSize"
    [(ngModel)]="item.totalHardDriveSize"
    inputMode="tel"
  ></ads-common-form-input>
  <ads-common-form-input
    name="daysOfRetention"
    label="Days of retention"
    type="number"
    [minValue]="0"
    [maxLength]="8"
    [required]="true"
    [value]="item.daysOfRetention"
    [(ngModel)]="item.daysOfRetention"
    inputMode="tel"
  ></ads-common-form-input>
</ads-simple-form>
