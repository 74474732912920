<ads-simple-form #tcdTcrExternalAreaTasks="ngModelGroup" ngModelGroup="tcdTcrExternalAreaTasks">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button
  ><mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Clean customer interface area"
    name="customerArea"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Check tone volume"
    name="toneVolume"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Clean and test screen"
    name="screen"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Clean entire exterior"
    name="exterior"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Check alignment with exit and input"
    name="alignment"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Verify cables are neat and organized behind equipment"
    name="cables"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
</ads-simple-form>
