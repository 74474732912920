export class CcCdPresentModuleTasks {
  allNa: boolean;
  checkCleanSensors: number | null;
  removeForeignMaterial: number | null;
  checkCleanRollers: number | null;
  checkCleanBelts: number | null;
  performDiagnosticTests: number | null;

  constructor() {
    this.allNa = false;
    this.checkCleanSensors = null;
    this.removeForeignMaterial = null;
    this.checkCleanRollers = null;
    this.checkCleanBelts = null;
    this.performDiagnosticTests = null;
  }
}
