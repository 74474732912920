import { YesNoNa } from 'models/shared/yes-no-na';

export class BatteriesForm {
  secondaryPowerSupply: YesNoNa | null;
  batteriesAmount: string | null;
  installation: Date | null;
  voltageTestResult: string | null;
  secondaryBatteryInstallation: Date | null;

  constructor() {
    this.secondaryPowerSupply = null;
    this.batteriesAmount = null;
    this.installation = null;
    this.voltageTestResult = null;
    this.secondaryBatteryInstallation = null;
  }
}
