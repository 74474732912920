<h4 class="title">Support Request</h4>
<mat-tab-group [(selectedIndex)]="tabIndex">
  <mat-tab label="Phone call">
    <ads-select label="Department" [list]="supportOptions" [(ngModel)]="selectedSupportOption"></ads-select>
  </mat-tab>
  <mat-tab label="Submit ticket">
    <ads-select label="Department" [list]="supportOptions" [(ngModel)]="selectedSupportOption"></ads-select>
    <ads-common-form-input label="Title" [maxLength]="50" [required]="true" [(ngModel)]="title"></ads-common-form-input>
    <ads-common-text-area
      label="Description"
      [maxLength]="1000"
      [required]="true"
      [(ngModel)]="description"
    ></ads-common-text-area>
    <ads-radio-button-group
      label="Include selected service order information?"
      [options]="yesNoOptions"
      [value]="includeInfo"
    ></ads-radio-button-group>
  </mat-tab>
</mat-tab-group>
<div class="menu-actions">
  <ads-custom-button label="Cancel" (clicked)="onCancel()"></ads-custom-button>
  <ads-custom-button class="main-button confirm" label="Confirm" (clicked)="onConfirm()"></ads-custom-button>
</div>
