import { AdsAtmRequest, AtmRequest } from '../../atm/ads-atm-request';
import {
  AtmDto,
  AtmSoftware,
  AtmSpecs,
  AtmTasks,
  AtmCardReaderTasks,
  AtmEnvDepDispTasks,
  AtmReceiptPrinterTasks,
  AtmCashDispenserTasks,
  AtmFasciaAdaTasks,
  CashDepositoryTasks,
  CheckDepositoryTasks,
  VacuumBlowOutTasks,
  AtmPhotos,
} from '../../atm/atm';

export const atmMapRequest = ({
  fieldsForm,
  photosForm,
  comments,
  workOrderDetail,
  pageSubmitMode,
  pageOpenMode,
}: AtmRequest): AdsAtmRequest => {
  const atmSpecs: AtmSpecs = {
    atmSpecs_Manufacturer: fieldsForm.atmSpecs.manufacturer,
    atmSpecs_Manufacturer_Other: fieldsForm.atmSpecs.otherAtmManufacturer,
    atmSpecs_Model: fieldsForm.atmSpecs.model,
    atmSpecs_Serial: fieldsForm.atmSpecs.serialNumber,
    atmSpecs_CommType: fieldsForm.atmSpecs.communicationType,
    atmSpecs_CommType_Other: fieldsForm.atmSpecs.communicationTypeOther,
    atmSpecs_DepositorType: fieldsForm.atmSpecs.depositorType,
    atmSpecs_DepositorType_Other: null,
    atmSpecs_TerminalId: fieldsForm.atmSpecs.terminalId,
    atmSpecs_OverallCondition: fieldsForm.atmSpecs.overallCondition,
    atmSpecs_KabaSerialNumber: fieldsForm.atmSpecs.kabaSerialNumber,
    atmSpecs_Equipment_Rating: fieldsForm.atmSpecs.equipmentRating,
  };

  const atmSoftware: AtmSoftware = {
    software_Onsite: fieldsForm.software.onsite,
    software_Version: fieldsForm.software.softwareVersion,
    software_OS: fieldsForm.software.operatingSystem,
    software_OS_Other: fieldsForm.software.operatingSystemOther,
    software_NCR_CD2: fieldsForm.software.ncrcd2,
  };

  const atmTasks: AtmTasks = {
    atmTasks_ConfSheetsPresent: fieldsForm.atmTasks.confSheetsPresent,
    atmTasks_LogSheetPresent: fieldsForm.atmTasks.logSheetPresent,
    atmTasks_VerifySiteProfileSP: fieldsForm.atmTasks.verifySiteProfileSP,
    atmTasks_TalliesAbnormalities: fieldsForm.atmTasks.talliesAbnormalities,
    atmTasks_ClearTallies: fieldsForm.atmTasks.clearTallies,
    atmTasks_RequestKabaCombo: fieldsForm.atmTasks.requestKabaCombo,
  };

  const cardReaderTasks: AtmCardReaderTasks = {
    cardReaderTasks_CleanPrehead: fieldsForm.cardReaderTasks.cleanPrehead,
    cardReaderTasks_CleanAllRollers: fieldsForm.cardReaderTasks.cleanAllRollers,
    cardReaderTasks_CleanAllBelts: fieldsForm.cardReaderTasks.cleanAllBelts,
    cardReaderTasks_CleanReadHead: fieldsForm.cardReaderTasks.cleanReadHead,
    cardReaderTasks_CleanFeltCard: fieldsForm.cardReaderTasks.cleanFeltCard,
    cardReaderTasks_BlowSensorAreas: fieldsForm.cardReaderTasks.blowSensorAreas,
    cardReaderTasks_DiagnosticTest: fieldsForm.cardReaderTasks.diagnosticTest,
    cardReaderTasks_EvmCapable: fieldsForm.cardReaderTasks.evmCapable,
  };

  const envDepDispTasks: AtmEnvDepDispTasks = {
    envDepDispTasks_CleanAllSensors: fieldsForm.envDepDispTasks.cleanAllSensors,
    envDepDispTasks_CleanAllBelts: fieldsForm.envDepDispTasks.cleanAllBelts,
    envDepDispTasks_CleanShutter: fieldsForm.envDepDispTasks.cleanShutter,
    envDepDispTasks_CleanInkArea: fieldsForm.envDepDispTasks.cleanInkArea,
    envDepDispTasks_CleanInkPad: fieldsForm.envDepDispTasks.cleanInkPad,
    envDepDispTasks_ReplaceCartridgeRibbon: fieldsForm.envDepDispTasks.replaceCartridgeRibbon,
    envDepDispTasks_ReplenishEnvelops: fieldsForm.envDepDispTasks.replenishEnvelops,
    envDepDispTasks_DiagnosticTests: fieldsForm.envDepDispTasks.diagnosticTests,
  };

  const receiptPrinterTasks: AtmReceiptPrinterTasks = {
    receiptPrinterTasks_RemoveAllPaperDust: fieldsForm.receiptPrinterTasks.removeAllPaperDust,
    receiptPrinterTasks_OpenCleanEngine: fieldsForm.receiptPrinterTasks.openCleanEngine,
    receiptPrinterTasks_CleanAllBelts: fieldsForm.receiptPrinterTasks.cleanAllBelts,
    receiptPrinterTasks_CleanAllRollers: fieldsForm.receiptPrinterTasks.cleanAllRollers,
    receiptPrinterTasks_BlowSensorAreas: fieldsForm.receiptPrinterTasks.blowSensorAreas,
    receiptPrinterTasks_BlowCutterArea: fieldsForm.receiptPrinterTasks.blowCutterArea,
    receiptPrinterTasks_Diagnostic: fieldsForm.receiptPrinterTasks.diagnostic,
  };

  const cashDispenserTasks: AtmCashDispenserTasks = {
    cashDispenserTasks_CleanSensors: fieldsForm.cashDispenserTasks.cleanSensors,
    cashDispenserTasks_RemoveForeignMaterial: fieldsForm.cashDispenserTasks.removeForeignMaterial,
    cashDispenserTasks_CleanRollers: fieldsForm.cashDispenserTasks.cleanRollers,
    cashDispenserTasks_CleanBelts: fieldsForm.cashDispenserTasks.cleanBelts,
    cashDispenserTasks_CleanShutter: fieldsForm.cashDispenserTasks.cleanShutter,
    cashDispenserTasks_ReplacePickupFeedRollers: fieldsForm.cashDispenserTasks.replacePickupFeedRollers,
    cashDispenserTasks_CheckInterlockSwitch: fieldsForm.cashDispenserTasks.checkInterlockSwitch,
    cashDispenserTasks_Diagnostic: fieldsForm.cashDispenserTasks.diagnostic,
  };

  const fasciaAdaTasks: AtmFasciaAdaTasks = {
    fascia_CleanCustomerInterfaceArea: fieldsForm.fasciaAdaTasks.cleanCustomerInterfaceArea,
    fascia_AdaStickersPresentReadable: fieldsForm.fasciaAdaTasks.adaStickersPresentReadable,
    fascia_CleanToneVolume: fieldsForm.fasciaAdaTasks.cleanToneVolume,
    fascia_TestVoiceGuidance: fieldsForm.fasciaAdaTasks.testVoiceGuidance,
    fascia_TestKeypadFunctionKeys: fieldsForm.fasciaAdaTasks.testKeypadFunctionKeys,
    fascia_CheckAlignCardReader: fieldsForm.fasciaAdaTasks.checkAlignCardReader,
    fascia_CheckAlignOperDispShutter: fieldsForm.fasciaAdaTasks.checkAlignOperDispShutter,
    fascia_CheckAlignReceiptPrinter: fieldsForm.fasciaAdaTasks.checkAlignReceiptPrinter,
    fascia_CleanOperatorPanel: fieldsForm.fasciaAdaTasks.cleanOperatorPanel,
    fascia_CleanEntireExterior: fieldsForm.fasciaAdaTasks.cleanEntireExterior,
    fascia_VerifyTouchScreen: fieldsForm.fasciaAdaTasks.verifyTouchScreen,
    fascia_TestOpenCloseIndicator: fieldsForm.fasciaAdaTasks.testOpenCloseIndicator,
    fascia_ReplaceLightBulbs: fieldsForm.fasciaAdaTasks.replaceLightBulbs,
    fascia_AntiSkimmingInDeviceConfigMode: fieldsForm.fasciaAdaTasks.antiSkimmingInDeviceConfigMode,
    topHatLightOperational: fieldsForm.fasciaAdaTasks.topHatLightOperational,
  };

  const cashDepositoryTasks: CashDepositoryTasks = {
    cashDepositoryTasks_CleanSensors: fieldsForm.cashDepositoryTasks.cleanSensors,
    cashDepositoryTasks_CleanBillScannerLenses: fieldsForm.cashDepositoryTasks.cleanBillScannerLenses,
    cashDepositoryTasks_CleanRollers: fieldsForm.cashDepositoryTasks.cleanRollers,
    cashDepositoryTasks_CleanBelts: fieldsForm.cashDepositoryTasks.cleanBelts,
    cashDepositoryTasks_CleanBins: fieldsForm.cashDepositoryTasks.cleanBins,
    cashDepositoryTasks_VerifyInfeedAlignment: fieldsForm.cashDepositoryTasks.verifyInfeedAlignment,
    cashDepositoryTasks_Diagnostic: fieldsForm.cashDepositoryTasks.diagnostic,
  };

  const checkDepositoryTasks: CheckDepositoryTasks = {
    checkDepository_CleanSensors: fieldsForm.checkDepositoryTasks.cleanSensors,
    checkDepository_CleanRollers: fieldsForm.checkDepositoryTasks.cleanRollers,
    checkDepository_CleanBelts: fieldsForm.checkDepositoryTasks.cleanBelts,
    checkDepository_CleanInkArea: fieldsForm.checkDepositoryTasks.cleanInkArea,
    checkDepository_CheckScannerLenses: fieldsForm.checkDepositoryTasks.checkScannerLenses,
    checkDepository_CheckPrinterQuality: fieldsForm.checkDepositoryTasks.checkPrinterQuality,
    checkDepository_CleanAllBins: fieldsForm.checkDepositoryTasks.cleanAllBins,
    checkDepository_VerifyInfeedAlignment: fieldsForm.checkDepositoryTasks.verifyInfeedAlignment,
    checkDepository_Diagnostic: fieldsForm.checkDepositoryTasks.diagnostic,
  };

  const vacuumBlowOutTasks: VacuumBlowOutTasks = {
    vaccumBlowOut_Safe: fieldsForm.vacuumBlowOutTasks.safe,
    vaccumBlowOut_TopHalf: fieldsForm.vacuumBlowOutTasks.topHalf,
    vaccumBlowOut_Dispenser: fieldsForm.vacuumBlowOutTasks.dispenser,
    vaccumBlowOut_Depositories: fieldsForm.vacuumBlowOutTasks.depositories,
    vaccumBlowOut_ReceiptPrinter: fieldsForm.vacuumBlowOutTasks.receiptPrinter,
    vaccumBlowOut_AllFans: fieldsForm.vacuumBlowOutTasks.allFans,
    vaccumBlowOut_AllFilters: fieldsForm.vacuumBlowOutTasks.allFilters,
    vaccumBlowOut_PowerSupply: fieldsForm.vacuumBlowOutTasks.powerSupply,
    vaccumBlowOut_SafeHeaters: fieldsForm.vacuumBlowOutTasks.safeHeaters,
  };

  const photos: AtmPhotos = {
    photo_Front_Distance: photosForm['atm-front-distance'].value?.split('?sv=')[0] ?? null,
    photo_Card_Reader: photosForm['atm-card-reader'].value?.split('?sv=')[0] ?? null,
    photo_Depositories: photosForm['atm-depositor'].value?.split('?sv=')[0] ?? null,
    photo_Dispenser: photosForm['atm-dispenser'].value?.split('?sv=')[0] ?? null,
    photo_CustomerMonitor: photosForm['atm-customer-monitor'].value?.split('?sv=')[0] ?? null,
    photo_SerialNumberLabel: photosForm['atm-serial-number-label'].value?.split('?sv=')[0] ?? null,
    photo_FrontClose: photosForm['atm-front-close'].value?.split('?sv=')[0] ?? null,
    photo_Computer: photosForm['atm-computer'].value?.split('?sv=')[0] ?? null,
    photo_CustomerScreen: photosForm['atm-touchscreen-fdks'].value?.split('?sv=')[0] ?? null,
    photo_Printer: photosForm['atm-printer'].value?.split('?sv=')[0] ?? null,
    photo_OperatorPanel: photosForm['atm-operator-panel'].value?.split('?sv=')[0] ?? null,
    photo_Software: photosForm['atm-software'].value?.split('?sv=')[0] ?? null,
    photo_AdditionalPhoto: photosForm['atm-additional-photo'].value?.split('?sv=')[0] ?? null,
  };

  const atm: AtmDto = {
    updatedOn: new Date().toUTCString(),
    performedBy: workOrderDetail.performedBy ?? null,
    workOrderId: workOrderDetail.id,
    ...atmSpecs,
    ...atmSoftware,
    ...atmTasks,
    ...cardReaderTasks,
    ...envDepDispTasks,
    ...receiptPrinterTasks,
    ...cashDispenserTasks,
    ...fasciaAdaTasks,
    ...cashDepositoryTasks,
    ...checkDepositoryTasks,
    ...vacuumBlowOutTasks,
    ...photos,
    comments,
  };

  return {
    pageOpenMode,
    pageSubmitMode,
    isEditable: true,
    fromXml: false,
    atm,
    workOrderDetail: workOrderDetail,
  };
};
