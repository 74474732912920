<ads-simple-form #ucs="ngModelGroup" [ngModelGroup]="'ucs'">
  <ng-container ngModelGroup="ucsItems">
    <ads-nested-multiple-forms-section
      #ucsSectionsPanels
      title="UCS"
      [formTemplate]="formTemplate"
      [list]="security.ucsData.ucsSections"
      [formControls]="getFormControls()"
      [groupName]="'ucsItem'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddClick()"
      (deleteClicked)="onRemoveUCS($event)"
    ></ads-nested-multiple-forms-section>
    <ng-template #formTemplate let-item="item">
      <ads-ucs-section-form
        [section]="getItemSection('ucsItem#' + item.index)"
        [item]="item"
        [ucsItems]="ucsDropdowns"
      ></ads-ucs-section-form>
    </ng-template>
  </ng-container>
  <ads-expansion-panel title="UCS Tasks" [formGroup]="formGroup" [name]="'ucsTasks'" [class]="'ucsTasks'">
    <ads-ucs-tasks-section [section]="getTasksSection()"></ads-ucs-tasks-section>
  </ads-expansion-panel>
  <ads-common-text-area name="comments" label="Comments (optional)" [maxLength]="200" ngModel></ads-common-text-area>
</ads-simple-form>
