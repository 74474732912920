<ads-simple-form #accessControlCardReaderTasks="ngModelGroup" [ngModelGroup]="'accessControlCardReaderTasks'">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button>
  <ads-radio-button-group
    [options]="options"
    label="Visually inspect wire"
    name="visuallyInspectWire"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
