<ads-order-info [order]="workOrder" [customerName]="customerName"></ads-order-info>
<form #itmForm="ngForm" (ngSubmit)="onSubmit()">
  <ads-expansion-panel
    #itmSectionPanel
    title="ITM"
    [formGroup]="form?.form"
    [name]="'itmSection'"
    [class]="'itmSection'"
  >
    <ads-itm-form-section
      [section]="getSection('itmSection')"
      [itmManufacturerList]="dropdowns.manufacturerList"
      [depositorTypeList]="dropdowns.depositorTypeList"
      [communicationTypesList]="dropdowns.communicationTypes"
    ></ads-itm-form-section>
  </ads-expansion-panel>
  <ads-expansion-panel
    #itmExteriorEvaluationSectionPanel
    title="ITM Exterior Evaluation"
    [formGroup]="form?.form"
    [name]="'itmExteriorEvaluation'"
    [class]="'itmExteriorEvaluation'"
  >
    <ads-itm-exterior-evaluation [section]="getSection('itmExteriorEvaluation')"></ads-itm-exterior-evaluation>
  </ads-expansion-panel>
  <ads-expansion-panel
    #itmNetworkLogosSectionPanel
    title="ITM network logos"
    [formGroup]="form?.form"
    [name]="'itmNetworkLogos'"
    [class]="'itmNetworkLogos'"
  >
    <ads-network-logos [section]="getSection('itmNetworkLogos')" [groupName]="'itmNetworkLogos'"></ads-network-logos>
  </ads-expansion-panel>
  <ads-expansion-panel
    #itmCleaningTasksSectionPanel
    title="Cleaning Tasks"
    [formGroup]="form?.form"
    [name]="'cleaningTasks'"
    [class]="'cleaningTasks'"
  >
    <ads-itm-cleaning-tasks [section]="getSection('cleaningTasks')"></ads-itm-cleaning-tasks>
  </ads-expansion-panel>
  <ads-expansion-panel
    #itmReplenishmentTasksSectionPanel
    title="Replenishment Tasks"
    [formGroup]="form?.form"
    [name]="'replenishmentTasks'"
    [class]="'replenishmentTasks'"
  >
    <ads-itm-replenishment-tasks [section]="getSection('replenishmentTasks')"></ads-itm-replenishment-tasks>
  </ads-expansion-panel>
  <ads-expansion-panel
    #itmPhotosSectionPanel
    title="Photos"
    [formGroup]="form?.form"
    [name]="'photoUploads'"
    [class]="'photoUploads'"
  >
    <ads-photos-section
      [photoUploads]="photoUploads"
      [photoUploadForm]="getSection('photoUploads')!"
    ></ads-photos-section>
  </ads-expansion-panel>
  <ads-common-text-area name="comments" label="Comments (optional)" [maxLength]="500" ngModel></ads-common-text-area>
  <div class="form-buttons" *ngIf="!getIsFormSubmitted()">
    <button
      mat-button
      mat-stroked-button
      (click)="saveForm()"
      [disabled]="form?.form?.disabled || (isLoading | async)"
      type="button"
    >
      Save
    </button>
    <button mat-button mat-flat-button class="main-button" [disabled]="form?.form?.disabled">Submit</button>
  </div>
</form>
<div class="clear"></div>
