import { IndexableFormType } from "app/utils/miscTypes";
import { TcdTcrSection } from "models/api/tcd-tcr/tcd-tcr";

export class TcdTcrFormSection implements IndexableFormType, TcdTcrSection {
  model: string | null;
  serialNumber: string | null;
  type: string | null;
  terminalId: string | null;
  clientWorkstationsAmount: string | null;
  communicationType: string | null;
  otherCommunicationType: string | null;
  cassettesRollersAmount: string | null;
  coinDispensersAmount: string | null;
  coinDispenserManufacturer: string | null;
  manufacturer: string | null;
  otherManufacturer: string | null;
  overallCondition: string | null;
  otherExternalEquipment: string | null;
  equipmentRating: string | null;
  photoFront: string | null;
  photoCoinDispenser: string | null;
  photoExternalDevices: string | null;
  photoSerialNumber: string | null;
  photoAdditionalPhoto: string | null;
  index: number;
  constructor(index: number) {
    this.index = index;
    this.model = '';
    this.serialNumber = '';
    this.type = '';
    this.terminalId = '';
    this.clientWorkstationsAmount = '';
    this.communicationType = '';
    this.otherCommunicationType = '';
    this.cassettesRollersAmount = '';
    this.coinDispensersAmount = '';
    this.coinDispenserManufacturer = '';
    this.manufacturer = '';
    this.otherManufacturer = '';
    this.overallCondition = '';
    this.otherExternalEquipment = '';
    this.equipmentRating = '';
    this.photoFront = '';
    this.photoCoinDispenser = '';
    this.photoExternalDevices = '';
    this.photoSerialNumber = '';
    this.photoAdditionalPhoto = '';
  }
}
