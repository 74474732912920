<ads-simple-form #itmSpecs="ngModelGroup" ngModelGroup="itmSpecs">
  <ads-select label="ITM Manufacturer" name="manufacturer" [list]="itmManufacturerList" ngModel></ads-select>

  <ads-common-form-input
    name="serialNumber"
    label="ITM Serial Number"
    [maxLength]="50"
    [required]="true"
    ngModel
  ></ads-common-form-input>

  <ads-select label="Depositor type" name="depositorType" [list]="depositorTypeList" ngModel></ads-select>

  <ads-common-form-input
    name="kabaSerialNumber"
    label="Kaba Serial Number"
    [maxLength]="50"
    [required]="true"
    ngModel
  ></ads-common-form-input>

  <ads-common-form-input
    name="model"
    label="ITM Model"
    [maxLength]="50"
    [required]="true"
    ngModel
  ></ads-common-form-input>

  <ads-select label="Communication Type" name="communicationType" [list]="communicationTypeList" ngModel></ads-select>

  <ads-common-form-input
    name="terminalId"
    label="ITM Terminal ID"
    [maxLength]="50"
    [required]="true"
    ngModel
  ></ads-common-form-input>

  <ads-common-form-input
    name="networkTerminalId"
    label="Network Terminal ID"
    [maxLength]="50"
    ngModel
  ></ads-common-form-input>

  <ads-select
    label="Overall Condition - ITM"
    name="overallCondition"
    [list]="overallConditionList"
    ngModel
  ></ads-select>

  <ads-common-text-area
    [style.display]="displaySubCondition() ? 'block' : 'none'"
    name="equipmentRating"
    label="Substandard equipment comments"
    [maxLength]="500"
    [required]="displaySubCondition()"
    ngModel
  >
  </ads-common-text-area>
</ads-simple-form>
