import {
  AccessControlCardReaderInterfaceTableFormSection,
  AccessControlDoorTableFormSection,
  AccessControlPowerSupplyTableFormSection,
  AccessControlsFormSection,
} from 'forms/security/access-hardware/access-hardware.component';
import {
  SecurityAccessControlCardReaderInterfaceDto,
  SecurityAccessControlCardReaderTasksDto,
  SecurityAccessControlDoorsDto,
  SecurityAccessControlPanelTasksDto,
  SecurityAccessControlPowerSupplyTableDto,
  SecurityAccessControlPowerSupplyTasksDto,
  SecurityAccessControlsPanelDto,
} from 'models/api/security/access-hardware/access-hardware-dto';
import { SecurityAccessControlHardwareDataDto } from 'models/api/security/security';
import {
  AccessControlCardReaderTasksForm,
  AccessControlPowerSupplyTasksForm,
  AccessControlTasksForm,
  AccessHardwareSecurityForm,
} from 'models/security/access-hardware/accessHardwareSecurityForm';

export const mapAccessControlsPanelsResponse = (
  panels: SecurityAccessControlsPanelDto[]
): AccessControlsFormSection[] => {
  if (!panels || panels.length === 0)
    return [
      {
        controlPanelModel: null,
        systemManufacturer: null,
        systemManufacturer_Other: null,
        controlPanelModel_Other: null,
        location: null,
        ipAddress: null,
        overallConditionAccessControlsystem: null,
        equipmentRatingAccessControlsystem: null,
        index: 1,
      },
    ];

  return panels.map((panel, index) => ({
    controlPanelModel: panel?.controlPanelModel,
    systemManufacturer: panel?.systemManufacturer,
    systemManufacturer_Other: panel?.systemManufacturer_Other,
    controlPanelModel_Other: panel?.controlPanelModel_Other,
    location: panel?.location,
    ipAddress: panel?.ipAddress,
    overallConditionAccessControlsystem: panel?.overallConditionAccessControlsystem,
    equipmentRatingAccessControlsystem: panel?.equipment_Rating_AccessControlsystem,
    index: index + 1,
  }));
};

export const mapAccessControlTasksResponse = (tasks: SecurityAccessControlPanelTasksDto): AccessControlTasksForm => {
  return {
    verifyComms: tasks?.verifyComms ?? null,
    testTamper: tasks?.testTamper ?? null,
    testPowerFail: tasks?.testPowerFail ?? null,
  };
};

export const mapAccessControlCardReaderInterfacesResponse = (
  interfaces: SecurityAccessControlCardReaderInterfaceDto[]
): AccessControlCardReaderInterfaceTableFormSection[] =>
  interfaces.map((interfaceItem, index) => ({
    address: interfaceItem?.address,
    interfaceType: interfaceItem?.interfaceType,
    interfaceType_Other: interfaceItem?.interfaceType_Other,
    location: interfaceItem?.location,
    port: interfaceItem?.port,
    index: index + 1,
  }));

export const mapAccessControlItemTasksResponse = (
  tasks: SecurityAccessControlCardReaderTasksDto | SecurityAccessControlPowerSupplyTasksDto
): AccessControlCardReaderTasksForm | AccessControlPowerSupplyTasksForm => {
  return {
    visuallyInspectWire: tasks?.visuallyInspectWire ?? null,
  };
};

export const mapAccessControlPowerSupplyTableResponse = (
  powerSupplies: SecurityAccessControlPowerSupplyTableDto[]
): AccessControlPowerSupplyTableFormSection[] =>
  powerSupplies.map((powerSupply, index) => ({
    location: powerSupply?.location,
    powerSupply: powerSupply?.powerSupply,
    powerSupply_Other: powerSupply?.powerSupply_Other,
    voltage: powerSupply?.voltage,
    batteryAhr: powerSupply?.batteryAhr,
    batteryVoltageAfter1HourPowerLoss: powerSupply?.batteryVoltageAfter1HourPowerLoss,
    index: index + 1,
  }));

export const mapAccessControlDoorsResponse = (
  doors: SecurityAccessControlDoorsDto[]
): AccessControlDoorTableFormSection[] =>
  doors.map((door, index) => ({
    readerLocation: door?.readerLocation,
    readerStyle: door?.readerStyle,
    readerStyle_Other: door?.readerStyle_Other,
    lockStyle: door?.lockStyle,
    lockStyle_Other: door?.lockStyle_Other,
    doesAREXDeviceUnlockDoor: door?.doesAREXDeviceUnlockDoor,
    testLock: door?.testLock,
    testReader: door?.testReader,
    testDoorHelpOpenAlarm: door?.testDoorHelpOpenAlarm,
    testDoorForcedAlarm: door?.testDoorForcedAlarm,
    index: index + 1,
  }));

export const mapSecurityAccessHardwareForm = (data: SecurityAccessControlHardwareDataDto): AccessHardwareSecurityForm => ({
  accessControls: mapAccessControlsPanelsResponse(data.security_AccessControlHardware_AccessControls),
  accessControlTasks: mapAccessControlTasksResponse(data.security_AccessControlHardware_AccessControlPanelTasks[0]),
  accessControlCardReaderInterfaces: mapAccessControlCardReaderInterfacesResponse(
    data.security_AccessControlHardware_CardReaderInterfaces
  ),
  accessControlCardReaderTasks: mapAccessControlItemTasksResponse(
    data.security_AccessControlHardware_CardReaderInterfaceTasks[0]
  ),
  accessControlPowerSupplies: mapAccessControlPowerSupplyTableResponse(
    data.security_AccessControlHardware_PowerSupplytables
  ),
  accessControlPowerSupplyTasks: mapAccessControlItemTasksResponse(
    data.security_AccessControlHardware_PowerSupplyTasks[0]
  ),
  accessControlDoors: mapAccessControlDoorsResponse(data.security_AccessControlHardware_DoorTables),
  comments: data.comments,
});
