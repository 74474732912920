import { Component, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { RadioButtonGroupComponent } from 'shared/material-wrappers/radio-button-group/radio-button-group.component';
import { SelectOption } from 'models';
import { FormsModule } from '@angular/forms';
import { Chart, ChartConfiguration, Plugin } from 'chart.js';
import { ThemingService } from 'services';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { PanelComponent } from 'shared/panel/panel.component';
import { homeBarChartOptions } from '../home-charts-config';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HomeService, OveralChartData } from '../home.service';
import { shorten } from 'app/utils/string';
import { CheckboxListComponent, CheckboxItem } from 'shared/checkbox-list/checkbox-list.component';
import { CommonModule } from '@angular/common';
import { ChartsComponent } from 'shared/charts/charts.component';
import { BaseChartDirective } from 'ng2-charts';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ads-home-charts',
  standalone: true,
  imports: [
    CommonModule,
    RadioButtonGroupComponent,
    FormsModule,
    PanelComponent,
    MatIconModule,
    MatButtonModule,
    CheckboxListComponent,
    ChartsComponent,
  ],
  templateUrl: './home-charts.component.html',
  styleUrl: './home-charts.component.scss',
})
export class HomeChartsComponent implements OnInit {
  @Input() rankingValues: string[] = [];
  chart?: BaseChartDirective;

  barChartPlugins = [pluginDataLabels] as unknown as Plugin<'bar', unknown>[];
  barChartOptions = homeBarChartOptions(this.theming);
  chartsData: OveralChartData = {};

  values: string[][] = [];
  allLabels: string[] = [];
  allValues: string[][] = [];
  currentChart: string[] = [];
  activeTabs = [0];
  title = '';
  showFilters = false;
  allCenters = 0;

  filters: WritableSignal<CheckboxItem> = signal<CheckboxItem>({
    name: 'Select All',
    completed: false,
    subitems: [],
  });

  options: SelectOption<string>[] = [
    { name: 'PM', value: 'PM' },
    { name: 'FTF', value: 'FTF' },
    { name: 'Resolve', value: 'Resolve' },
  ];

  barChartData: ChartConfiguration<'bar'>['data'] = {
    labels: [],
    datasets: [
      {
        data: [],
        maxBarThickness: 3,
        label: 'PM',
        datalabels: { formatter: (value) => `${value}` },
        backgroundColor: '#C92B54',
      },
      {
        data: [],
        maxBarThickness: 3,
        label: 'FTF',
        hidden: true,
        datalabels: { formatter: (value) => `${value}` },
        backgroundColor: '#2BC954',
      },
      {
        data: [],
        maxBarThickness: 3,
        label: 'Resolve',
        hidden: true,
        datalabels: { formatter: (value) => `${value}` },
        backgroundColor: '#542BC9',
      },
    ],
  };

  constructor(private readonly theming: ThemingService, private readonly homeService: HomeService) {
    Chart.register(ChartDataLabels);

    toObservable(this.theming.name).subscribe(() => {
      this.barChartOptions = homeBarChartOptions(this.theming);
    });
  }

  ngOnInit(): void {
    this.homeService.overallPercentageGraphByCenter().subscribe((data) => {
      this.chartsData = data;
      this.chartsData = {
        pm: this.chartsData.pm?.sort((a, b) => a.center?.localeCompare(b.center ?? '') ?? 0),
        ftf: this.chartsData.ftf?.sort((a, b) => a.center?.localeCompare(b.center ?? '') ?? 0),
        compliance: this.chartsData.compliance?.sort((a, b) => a.center?.localeCompare(b.center ?? '') ?? 0),
      };
      // Format the data to fill the chart
      const pmValues: number[] = this.chartsData.pm?.map((value) => value.percentage ?? 0) ?? [];
      const ftfValues: number[] = this.chartsData.ftf?.map((value) => value.percentage ?? 0) ?? [];
      const resolveValues: number[] = this.chartsData.compliance?.map((value) => value.percentage ?? 0) ?? [];
      this.allValues = [
        pmValues.map((value) => `${value}`),
        ftfValues.map((value) => `${value}`),
        resolveValues.map((value) => `${value}`),
      ];
      this.values = [...this.allValues];
      this.barChartData.datasets[0].data = pmValues;
      this.barChartData.datasets[1].data = ftfValues;
      this.barChartData.datasets[2].data = resolveValues;

      // Update axis labels on load
      this.allLabels = this.chartsData.pm?.map((value) => value.center ?? '') ?? [];
      this.barChartData.labels = this.shortenCenterLabels(this.allLabels);

      // Finally, we make sure all graphs are shown by default
      this.currentChart = ['PM', 'FTF', 'Resolve'];
      this.onOptionClicked(['PM', 'FTF', 'Resolve']);
      this.allCenters = this.filters().subitems?.filter((item) => item.completed).length ?? 0;
    });
  }

  onChartInit = (chart: BaseChartDirective) => {
    this.chart = chart;
  };

  onOptionClicked(e: string | string[]) {
    if (Array.isArray(e) && e.length === 0) {
      this.currentChart = ['PM'];
      this.onOptionClicked('PM');
      return;
    }
    const labels: string[] = this.chartsData.pm?.map((value) => value.center ?? '') ?? [];
    const tabs: number[] = [];

    // Find the tabs that are selected
    if (e.includes('PM')) {
      tabs.push(0);
    }
    if (e.includes('FTF')) {
      tabs.push(1);
    }
    if (e.includes('Resolve')) {
      tabs.push(2);
    }
    this.activeTabs = tabs;

    // Reset filters when a option is toggled
    const sortedLabels = labels.slice().sort((a, b) => a.localeCompare(b));
    this.filters.update(() => ({
      name: 'Select All',
      completed: true,
      subitems: sortedLabels.map((label) => ({
        name: label,
        completed: true,
      })),
    }));

    // Show only the bars for the selected tabs
    this.barChartData.datasets.forEach((dataset) => (dataset.hidden = true));
    tabs.forEach((tab) => (this.barChartData.datasets[tab].hidden = false));

    // Re-render the chart
    setTimeout(() => {
      if (this.chart) {
        this.chart?.update();
      }
    }, 200);
  }

  shortenCenterLabels(names: string[]): string[] {
    return names.map((name) => {
      switch (name.toUpperCase()) {
        case 'ARIZONA':
          return 'AZ';
        case 'COLORADO':
          return 'CO';
        case 'IDAHO':
          return 'ID';
        case 'NEVADA':
          return 'NV';
        case 'NEW MEXICO':
          return 'NM';
        case 'NORTHERN CALIFORNIA':
          return 'NORCAL';
        case 'OREGON':
          return 'OR';
        case 'SOUTHERN CALIFORNIA':
          return 'SOCAL';
        case 'SONC':
          return 'SONC';
        case 'UTAH':
          return 'UT';
        case 'WASHINGTON':
          return 'WA';
        case 'EWIM':
          return 'EWIM';
      }

      return shorten(name);
    });
  }

  onFilterChange(filters: CheckboxItem) {
    if (!filters.completed && filters.subitems?.every((item) => !item.completed)) {
      this.filters.set({
        name: 'Select All',
        subitems: filters.subitems?.map((item) => ({ ...item, completed: true })) ?? [],
        completed: true,
      });
      return;
    }

    // Since labels are different, we first find indexes of the centers to show
    const centersToShow =
      filters.subitems
        ?.map((item) => filters.subitems?.indexOf(item))
        .filter((i) => {
          if (i !== undefined) {
            if (filters.subitems?.[i].completed) {
              return true;
            }
            return false;
          } else {
            return false;
          }
        }) ?? [];

    // Now we modify the data set to remove the unchecked options
    const newPmData: number[] = [];
    const newFtfData: number[] = [];
    const newResolveData: number[] = [];
    centersToShow.forEach((center) => {
      if (center !== undefined) {
        newPmData.push(parseInt(this.allValues[0][center]));
        newFtfData.push(parseInt(this.allValues[1][center]));
        newResolveData.push(parseInt(this.allValues[2][center]));
      }
    });
    this.values = [
      newPmData.map((value) => `${value}`),
      newFtfData.map((value) => `${value}`),
      newResolveData.map((value) => `${value}`),
    ];
    this.barChartData.datasets[0].data = newPmData;
    this.barChartData.datasets[1].data = newFtfData;
    this.barChartData.datasets[2].data = newResolveData;

    // Now we modify the labels to match the checked options
    this.barChartData.labels = this.shortenCenterLabels(
      this.allLabels.filter((_, index) => centersToShow.includes(index)),
    );

    // And finally we re-render the chart
    if (this.chart) {
      this.chart?.update();
    }
  }
}
