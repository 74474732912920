import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, NgModelGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { RtsFormSection } from '../rts.component';
import { RtsLists } from '../rts.models';
import { MatDivider } from '@angular/material/divider';
import { SimpleFormComponent } from '../../../../shared/simple-form/simple-form.component';
import { CommonTextAreaComponent } from '../../../../shared/material-wrappers/common-text-area/common-text-area.component';

@Component({
  selector: 'ads-rts-section-form',
  standalone: true,
  imports: [CommonModule, FormsModule, CommonFormInputComponent, MatDivider, SelectComponent, SimpleFormComponent, CommonTextAreaComponent],
  templateUrl: './rts-section-form.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class RtsSectionFormComponent {
  @Input() rtsLists?: RtsLists;
  @Input() section?: FormGroup;
  @Input() item?: RtsFormSection;
  @Input() photoUploadForm!: FormGroup;

  displayEquipmentRating(): boolean {
    return this.item?.overallCondition === 'Poor Condition' || this.item?.overallCondition === 'Failed';
  }

  displayOtherMake(): boolean {
    return this.item?.rtsMake === 'Other';
  }
}
