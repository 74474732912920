<ads-custom-prompt
  class="sort-button"
  [icon]="icon"
  [label]="label"
  [focused]="focused"
  [isDesktopView]="isDesktopView"
  (clicked)="onClick()"
  (dismissed)="onDismissed()"
></ads-custom-prompt>
<div class="prompt-wrapper">
  <div *ngIf="focused" class="prompt-container">
    <div class="radios">
      <ads-radio-list
        [value]="value"
        [options]="options"
        (optionClicked)="onOptionSelected($event)"
      >
      </ads-radio-list>
      <ng-content></ng-content>
    </div>
    <div class="buttons">
      <ads-custom-button label="Cancel" (clicked)="onCancelClick()"></ads-custom-button>
      <button mat-button mat-flat-button class="confirm" (click)="onConfirmClick()">Confirm</button>
    </div>
  </div>
</div>
