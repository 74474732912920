<ads-simple-form #software="ngModelGroup" ngModelGroup="software">
  <ads-radio-button-group
    [options]="options"
    label="Software Onsite?"
    name="onsite"
    [required]="true"
    ngModel
  ></ads-radio-button-group>

  <ads-select label="Operating System" name="operatingSystem" [list]="operatingSystemsList" ngModel></ads-select>

  <ads-common-form-input
    [style.display]="displayOther() ? 'block' : 'none'"
    name="operatingSystemOther"
    label="Other Operating System"
    [maxLength]="50"
    [required]="displayOther()"
    ngModel
  ></ads-common-form-input>

  <ads-common-form-input
    name="softwareVersion"
    label="Software Version"
    [maxLength]="64"
    [required]="true"
    ngModel
  ></ads-common-form-input>

  <ads-common-form-input
    name="ncrcd2"
    label="NCR CD2 MUP Release"
    tooltip="If applicable"
    [maxLength]="64"
    [required]="false"
    ngModel
  ></ads-common-form-input>
</ads-simple-form>
