<button
  class="call-container"
  [ngClass]="{ 'dark-theme': currentTheme === 'dark', 'light-theme': currentTheme === 'light' }"
  (click)="onCallClick()"
>
  <div class="call-header">
    <span class="work-order">{{ call.workorder }}, {{ call.nameAlias }}</span>
    <span
      class="status-chip"
      [ngStyle]="{ background: statusColors.get(call.laststatusname ?? 'Started') }"
      [ngClass]="{ black: ['Scheduled', 'Susp-Other'].includes(call.laststatusname ?? '') }"
    >
      {{ call.laststatusname }}
    </span>
  </div>
  <div class="call-description-row">
    <mat-icon class="material-icons-outlined">bolt</mat-icon><span class="time-range">{{ call.priority }}</span>
  </div>
  <div class="call-details-row">
    <span class="detail-content">
      <span class="line">{{ call.calltype }}</span>
      <span class="line">{{ call.problem }}</span>
    </span>
    <div *ngIf="isDesktopView" class="details-row-end">
      <div class="location-wrapper">
        <div class="location-details">
          <span class="location-line">{{ call.address }}</span>
          <span class="location-line">{{ call.city }}, {{ call.state }} {{ call.zip }}</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isDesktopView" class="call-details-row">
    <div class="mobile-dates">
      <span>
        <mat-icon>event</mat-icon>
        <span class="label">Scheduled:</span>
        {{ getValidDate(call.servicedatetime) }}
      </span>
      <span *ngIf="call.onSiteBy">
        <mat-icon>event</mat-icon>
        <span class="label">On-site by:</span>
        {{ getValidDate(call.onSiteBy) }}
      </span>
    </div>
    <div
      class="date-color-indicator"
      [ngStyle]="{
        color: getDateColor(call.servicedatetime)[1],
        border: '1px solid ' + getDateColor(call.servicedatetime)[1]
      }"
    >
      {{ getDateColor(call.servicedatetime)[0] }}
    </div>
  </div>

  <div *ngIf="isDesktopView" class="call-footer">
    <span class="center"> {{ call.customer }}</span>
    <div class="call-footer-end">
      <div
        class="date-color-indicator"
        [ngStyle]="{
          color: getDateColor(call.servicedatetime)[1],
          border: '1px solid ' + getDateColor(call.servicedatetime)[1]
        }"
      >
        {{ getDateColor(call.servicedatetime)[0] }}
      </div>
      <span> <mat-icon>event</mat-icon> Scheduled: {{ call.servicedatetime | date : 'MM/dd/yyyy - hh:mm a' }} </span>
      <span *ngIf="call.onSiteBy">
        <mat-icon>event</mat-icon> On-site By: {{ call.onSiteBy | date : 'MM/dd/yyyy - hh:mm a' }}
      </span>
    </div>
  </div>
  <div *ngIf="!isDesktopView" class="call-footer center">
    <span class="center"> {{ call.customer }}</span>
  </div>
  <div class="selected-order" *ngIf="currentWorkOrderService.getSelectedCall()?.workorder === call.workorder">
    Currently selected
  </div>
</button>
