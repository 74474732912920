<div class="dialog-title">
  <h2>Ticket Notes</h2>
  <span class="external-chip" *ngIf="!data.isInternal">External</span>
</div>
<mat-dialog-content>
  <div class="dialog-description">
    <strong>Created by</strong>
    <span>{{ data.fullName }}</span>
  </div>

  <div class="dialog-description">
    <strong>Updated</strong>
    <span>{{ data.createdDateUtc | date:'MM/dd/yyyy hh:mm a' }}</span>
  </div>

  <div class="dialog-description">
    <strong>Notes</strong>
    <span [innerHTML]="data.description"></span>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-stroked-button (click)="onClose()">Close</button>
</mat-dialog-actions>
