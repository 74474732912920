import { HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OpenAckCalls } from 'models/api/home/home-requests';
import { Observable } from 'rxjs';
import { ApiService, AuthService } from 'services';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private readonly service: ApiService, private readonly authService: AuthService) {}

  public getCalls(): Observable<OpenAckCalls> {
    return this.service.sendRequest<OpenAckCalls>({
      method: 'get',
      url: `dashboard/calls?techIdMaster=${this.authService.user?.personnelNumber ?? ''}`,
    });
  }

  public navigateCCUrl(): void {
    this.service
      .sendRequest<string>({
        method: 'get',
        url: `layout/url?workOrder=WO-000000&type=CCLink&techIdMaster=${this.authService.user?.personnelNumber ?? ''}`,
      })
      .subscribe((url) => {
        window.open(encodeURI(url), '_blank');
      });
  }

  public individualRank(): Observable<IndividualRankAndPercentage> {
    const techIdMaster = this.authService.user?.personnelNumber;
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (techIdMaster !== undefined && techIdMaster !== null) {
      queryParameters = queryParameters.set('techIdMaster', techIdMaster);
    }

    return this.service.sendRequest<IndividualRankAndPercentage>({
      method: 'get',
      url: 'dashboard/individualrank',
      params: queryParameters,
    });
  }

  /**
   * Gets an overall percentages list according to the parameters in the request.
   */
  public overallPercentageGraphByCenter(): Observable<OveralChartData> {
    return this.service.sendRequest<OveralChartData>({
      method: 'get',
      url: 'dashboard/overallpercentagegraphbycenter',
    });
  }
}

export interface DashboardModel {
  unAckCalls?: number;
  openCalls?: number;
}

export class CustomHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
  override encodeKey(k: string): string {
    k = super.encodeKey(k);
    return k.replace(/\+/gi, '%2B');
  }
  override encodeValue(v: string): string {
    v = super.encodeValue(v);
    return v.replace(/\+/gi, '%2B');
  }
}

export interface IndividualRankAndPercentage {
  fTF?: string;
  resolve?: string;
  pm?: string;
  totalTech?: string;
  pctIsMetPM?: number;
  pctIsMetSLA?: number;
  pctIsMetFTF?: number;
  installGrossMarginPct?: number;
}

export interface OveralPercentage {
  center?: string;
  percentage?: number | null;
}

export interface OveralChartData {
  pm?: OveralPercentage[];
  compliance?: OveralPercentage[];
  ftf?: OveralPercentage[];
  gross?: OveralPercentage[];
}
