import {
  SurveillanceCameraTableSection,
  SurveillanceDVRNVRSection,
  SurveillanceMonitorTableSection,
  SurveillancePOESwitchSection,
  SurveillanceUPSSection,
} from 'forms/security/surveillance/surveillance.component';
import { SurveillanceDataDto } from 'models/api/security/security';
import {
  SecuritySurveillanceCameraTableDto,
  SecuritySurveillanceCameraTasksDto,
  SecuritySurveillanceDVRNVRDto,
  SecuritySurveillanceDVRTasksDto,
  SecuritySurveillanceMonitorTableDto,
  SecuritySurveillanceMonitorTasksDto,
  SecuritySurveillancePOESwitchDto,
  SecuritySurveillanceUPSDto,
} from 'models/api/security/surveillance/surveillance-dto';
import {
  SurveillanceCameraTasksForm,
  SurveillanceDVRTasksForm,
  SurveillanceMonitorTasksForm,
  SurveillanceSecurityForm,
} from 'models/security/surveillance/surveillanceSecurityForm';

export const mapSurveillanceDVRNVRSections = (panels: SecuritySurveillanceDVRNVRDto[]): SurveillanceDVRNVRSection[] => {
  if (!panels || panels.length === 0)
    return [
      {
        index: 0,
        dvrModel: '',
        dvrManufacturer: '',
        dvrManufacturer_Other: '',
        dvrSerialNumber: '',
        overallCondition_DVRNVR: '',
        softwareVersion: '',
        totalChannelsInDVR: '',
        totalChannelsAvailableForUse: '',
        totalAnalogInputs: '',
        totalAnalogInputsAvailableForUse: '',
        totalHardDriveSize: '',
        daysOfRetention: '',
        equipment_Rating: '',
        security_Surveillance: '',
      },
    ];

  return panels.map((panel, idx) => ({
    dvrModel: panel.dvrModel,
    dvrManufacturer: panel.dvrManufacturer,
    dvrManufacturer_Other: panel.dvrManufacturer_Other,
    dvrSerialNumber: panel.dvrSerialNumber,
    overallCondition_DVRNVR: panel.overallCondition_DVRNVR,
    softwareVersion: panel.softwareVersion,
    totalChannelsInDVR: panel.totalChannelsInDVR,
    totalChannelsAvailableForUse: panel.totalChannelsAvailableForUse,
    totalAnalogInputs: panel.totalAnalogInputs,
    totalAnalogInputsAvailableForUse: panel.totalAnalogInputsAvailableForUse,
    totalHardDriveSize: panel.totalHardDriveSize,
    daysOfRetention: panel.daysOfRetention,
    equipment_Rating: panel.equipment_Rating,
    security_Surveillance: panel.security_Surveillance,
    index: idx + 1,
  }));
};

export const mapSurveillanceUPSSections = (panels: SecuritySurveillanceUPSDto[]): SurveillanceUPSSection[] => {
  if (!panels || panels.length === 0)
    return [
      {
        index: 0,
        upsModel: '',
        upsManufacturer: '',
        upsManufacturer_Other: '',
        numberOfOutputs: '',
      },
    ];

  return panels.map((panel, idx) => ({
    upsModel: panel.upsModel,
    upsManufacturer: panel.upsManufacturer,
    upsManufacturer_Other: panel.upsManufacturer_Other,
    numberOfOutputs: panel.numberOfOutputs,
    index: idx + 1,
  }));
};

export const mapSurveillancePOESwitchSections = (
  panels: SecuritySurveillancePOESwitchDto[]
): SurveillancePOESwitchSection[] => {
  if (!panels || panels.length === 0)
    return [
      {
        index: 0,
        poeSwitchModel: '',
        poeSwitchManufacturer: '',
        poeSwitchManufacturer_Other: '',
      },
    ];

  return panels.map((panel, idx) => ({
    poeSwitchModel: panel.poeSwitchModel,
    poeSwitchManufacturer: panel.poeSwitchManufacturer,
    poeSwitchManufacturer_Other: panel.poeSwitchManufacturer_Other,
    index: idx + 1,
  }));
};

export const mapSurveillanceCameraTableSections = (
  panels: SecuritySurveillanceCameraTableDto[]
): SurveillanceCameraTableSection[] => {
  if (!panels || panels.length === 0)
    return [
      {
        index: 0,
        camera: '',
        cameraType: '',
        isPTZ: '',
      },
    ];

  return panels.map((panel, idx) => ({
    camera: panel.camera ?? '',
    cameraType: panel.cameraType ?? '',
    isPTZ: panel.isPTZ ?? '',
    index: idx + 1,
  }));
};

export const mapSurveillanceMonitorTableSections = (
  panels: SecuritySurveillanceMonitorTableDto[]
): SurveillanceMonitorTableSection[] => {
  if (!panels || panels.length === 0)
    return [
      {
        index: 0,
        monitor: '',
        monitorType: '',
      },
    ];

  return panels.map((panel, idx) => ({
    monitor: panel.monitor,
    monitorType: panel.monitorType,
    index: idx + 1,
  }));
};

export const mapSurveillanceDVRTasks = (panels?: SecuritySurveillanceDVRTasksDto): SurveillanceDVRTasksForm => {
  return {
    checkTimeDate: panels?.checkTimeDate ?? null,
    cleanedDVRCase: panels?.cleanedDVRCase ?? null,
    cleanedInsideEnclosure: panels?.cleanedInsideEnclosure ?? null,
    inspectAllWireConnectionsInPowerSupply: panels?.inspectAllWireConnectionsInPowerSupply ?? null,
    inspectAndCleanPOESwitch: panels?.inspectAndCleanPOESwitch ?? null,
    inspectConnectors: panels?.inspectConnectors ?? null,
    inspectInternalFanOperation: panels?.inspectInternalFanOperation ?? null,
    verifiedAllCamerasShowLiveVideo: panels?.verifiedAllCamerasShowLiveVideo ?? null,
    verifiedAllCamerasShowRecordedVideo: panels?.verifiedAllCamerasShowRecordedVideo ?? null,
    verifyXterms: panels?.verifyXterms ?? null,
  };
};

export const mapSurveillanceCameraTasks = (panels: SecuritySurveillanceCameraTasksDto): SurveillanceCameraTasksForm => {
  return {
    cleanCamerasAndHousings: panels?.cleanCamerasAndHousings ?? null,
    inspectCameraPositionFocus: panels?.inspectCameraPositionFocus ?? null,
    inspectCameraWireConnection: panels?.inspectCameraWireConnection ?? null,
  };
};

export const mapSurveillanceMonitorTasks = (
  panels: SecuritySurveillanceMonitorTasksDto
): SurveillanceMonitorTasksForm => {
  return {
    cleanMonitor: panels?.cleanMonitor ?? null,
    inspectMonitorOperation: panels?.inspectMonitorOperation ?? null,
  };
};

export const mapSurveillanceForm = (surveillanceSections: SurveillanceDataDto): SurveillanceSecurityForm => {
  return {
    overallConditionCameras: surveillanceSections?.overallCondition_Cameras,
    equipmentRatingCameras: surveillanceSections?.equipment_Rating_Cameras,
    overallConditionMonitor: surveillanceSections?.overallCondition_Monitor,
    equipmentRatingMonitor: surveillanceSections?.equipment_Rating_Monitor,
    surveillanceDvrNvr: mapSurveillanceDVRNVRSections(surveillanceSections.security_Surveillance_DVRNVRs),
    surveillanceUps: mapSurveillanceUPSSections(surveillanceSections.security_Surveillance_UPSs),
    surveillancePoeSwitch: mapSurveillancePOESwitchSections(surveillanceSections.security_Surveillance_POESwitchs),
    surveillanceCameraTable: mapSurveillanceCameraTableSections(
      surveillanceSections.security_Surveillance_CameraTables
    ),
    surveillanceMonitorTable: mapSurveillanceMonitorTableSections(
      surveillanceSections.security_Surveillance_MonitorTables
    ),
    surveillanceDvrTasks: mapSurveillanceDVRTasks(surveillanceSections.security_Surveillance_DVRTasks[0]),
    surveillanceCameraTasks: mapSurveillanceCameraTasks(surveillanceSections.security_Surveillance_CameraTasks[0]),
    surveillanceMonitorTasks: mapSurveillanceMonitorTasks(surveillanceSections.security_Surveillance_MonitorTasks[0]),
    comments: surveillanceSections?.comments,
  };
};
