<div class="container">
  <ads-navigation-header
    *ngIf="layoutNavService.isDesktopView()"
    (clicked)="onBackArrowClicked()"
    [showButton]="false"
    [label]="'Complete Call ' + workOrderService.workOrder"
  ></ads-navigation-header>
  <form #completeForm="ngForm">
    <p>
      AUTHORIZATION TO PERFORM FORCIBLE ENTRY: The undersigned hereby grants Cook Solutions Group Inc. representative(s)
      full authority to open safe deposit box No._____, or other equipment described _____.
    </p>
    <div class="form-group">
      <ads-common-form-input name="boxNumber" label="Safe Deposit Box Number" [(ngModel)]="boxNumber">
      </ads-common-form-input>
      <ads-common-form-input name="otherEquipment" label="Other Equipment" [(ngModel)]="otherEquipment">
      </ads-common-form-input>
    </div>

    <p class="alarm-security-banner">
      Authorization FOR ALARM SECURITY INTERRUPTION: The undersigned hereby grants Cook Solutions Group Inc.
      representative(s) full authority to interrupt security by disconnecting the alarm and/or cameras in the
      performance of their work. It is understood and agreed that the undersigned
    </p>

    <div class="form-group">
      <ads-common-form-input
        name="electronicSignature"
        label="Electronic signature"
        [(ngModel)]="electronicSignature"
        [required]="true"
      ></ads-common-form-input>
      <ads-common-form-input name="title" label="Title" [(ngModel)]="title" [required]="true"> </ads-common-form-input>
    </div>

    <div class="form-group">
      <ads-common-form-input name="email1" label="Email #1" [(ngModel)]="email1"> </ads-common-form-input>
      <ads-common-form-input name="email2" label="Email #2" [(ngModel)]="email2"> </ads-common-form-input>
      <ads-common-form-input name="email3" label="Email #3" [(ngModel)]="email3"> </ads-common-form-input>
    </div>
  </form>

  <h3>Items (if any)</h3>
  <div *ngIf="workOrderService.completeRegisterModel.length === 0" class="no-items">No items</div>
  <div *ngIf="workOrderService.completeRegisterModel.length > 0" class="items">
    <div *ngFor="let item of workOrderService.completeRegisterModel" class="item">
      {{ item.date }} - Quantity: {{ item.qty }}: {{ item.description }}
    </div>
  </div>

  <ads-common-form-input label="Technician Name" [(ngModel)]="technicianName"></ads-common-form-input>
  <p class="no-margin">
    Technician: By entering your name above, you testify to witnessing the electronic signature of the customer, and
    that the identity of said customer has been verified.
  </p>

  <div class="complete-footer">
    <ads-modal-actions [showCancel]="false" (confirm)="onConfirm()"></ads-modal-actions>
  </div>
</div>
