import { SafesFormSection } from 'forms/security/safes/safes.component';
import {
  SecuritySafesComboLockTasksDto,
  SecuritySafesSectionDto,
  SecuritySafesTasksDto,
  SecuritySafesTimeClockTasksDto,
} from 'models/api/security/safes/safes-dto';
import { SecuritySafesSectionsDto } from 'models/api/security/security';
import { SafesComboLockTasks, SafesSecurityForm, SafesTasks } from 'models/security/safes/safesSecurityForm';
import { TimeClockTasks } from 'models/security/shared/shared-tasks';

const mapSafesSectionsResponse = (panels: SecuritySafesSectionDto[]): SafesFormSection[] => {
  if (!panels || panels.length === 0)
    return [
      {
        safeType: '',
        safeManufacturer: '',
        safeManufacturer_Other: '',
        swingType: '',
        overallConditionSafe: '',
        equipment_Rating_Safe: '',
        safeModel: '',
        comboLocks: [],
        timeClocks: [],
        index: 1,
      },
    ];

  return panels.map((panel, index) => ({
    safeModel: panel.safeModel,
    safeManufacturer: panel.safeManufacturer,
    safeManufacturer_Other: panel.safeManufacturer_Other,
    swingType: panel.swingType,
    overallConditionSafe: panel.overallConditionSafe,
    equipment_Rating_Safe: panel.equipment_Rating_Safe,
    safeType: panel.safeType,
    comboLocks: panel.security_Safes_ComboLocks.map((comboLock, idx) => ({
      comboLockChangedDetail: comboLock.comboLockChangedDetail,
      comboLockManufacturer: comboLock.comboLockManufacturer,
      comboLockModel: comboLock.comboLockModel,
      comboLockType: comboLock.comboLockType,
      isComboLockChanged: comboLock.isComboLockChanged,
      isThereAKeyLockingDial: comboLock.isThereKeyLockingDial,
      index: idx + 1,
    })),
    timeClocks: panel.security_Safes_TimeClocks.map((timeClock, idx) => ({
      quantityOfMovements: timeClock.quantityOfMovements,
      timeClockManufacturer: timeClock.timeClockManufacturer,
      timeClockModel: timeClock.timeClockModel,
      timeClockType: timeClock.timeClockType,
      isLinkageConnectedDirectlyToCombolock: timeClock.isLinkageConnectedDirectlyToComboLock,
      index: idx + 1,
    })),
    index: index + 1,
  }));
};

const mapNightDropDropDoorTasksResponse = (tasks?: SecuritySafesTasksDto): SafesTasks => {
  return {
    checkBoltWorkForProperOperation: tasks?.checkBoltWorkForProperOperation ?? null,
    inspectBoltWorkForExcessiveWear: tasks?.inspectBoltWorkForExcessiveWear ?? null,
    cleanAndLubricateBoltWork: tasks?.cleanAndLubricateBoltWork ?? null,
  };
};

const mapSafesTimeClockResponse = (tasks?: SecuritySafesTimeClockTasksDto): TimeClockTasks => {
  return {
    checkTimeLocksForProperOperation: tasks?.checkTimeLocksForProperOperation ?? null,
    pullRightTimeClockForCleaning: tasks?.pullRightTimeClockForCleaning ?? null,
    shiftAllTimeClocksRight: tasks?.shiftAllTimeClocksRight ?? null,
    replaceLeftTimeClock: tasks?.replaceLeftTimeClock ?? null,
    markAnyReplacedTimeClocksWithDateInstalled: tasks?.markAnyReplacedTimeClocksWithDateInstalled ?? null,
  };
};

const mapSafesComboLockResponse = (tasks?: SecuritySafesComboLockTasksDto): SafesComboLockTasks => {
  return {
    checkComboLockForProperOperation: tasks?.checkComboLockForProperOperation ?? null,
    disassembleAndInspectComboLock: tasks?.disassembleAndInspectComboLock ?? null,
    lubricateAndReassembleComboLock: tasks?.lubricateAndReassembleComboLock ?? null,
    checkForDialAndRingProperOperation: tasks?.checkForDialAndRingProperOperation ?? null,
    disassembleAndInspectDialAndRing: tasks?.disassembleAndInspectDialAndRing ?? null,
    lubricateAndReassembleDialAndRing: tasks?.lubricateAndReassembleDialAndRing ?? null,
  };
};

export const mapSecuritySafesResponse = (data: SecuritySafesSectionsDto): SafesSecurityForm => ({
  safesSections: mapSafesSectionsResponse(data.security_Safes_Safes),
  timeClockTasks: mapSafesTimeClockResponse(data.security_Safes_TimeClockTasks[0]),
  comboLockTasks: mapSafesComboLockResponse(data.security_Safes_ComboLockTasks[0]),
  safesTasks: mapNightDropDropDoorTasksResponse(data.security_Safes_SafeTasks[0]),
  comments: data.comments ?? null,
});
