import {
  SecurityNightDropComboLockTasksDto,
  SecurityNightDropDropDoorTasksDto,
  SecurityNightDropHeadTasksDto,
  SecurityNightDropSectionDto,
  SecurityNightDropTimeClockTasksDto,
} from 'models/api/security/night-drop/night-drop-dto';
import { SecurityNightDropDto } from 'models/api/security/security';
import {
  NightDropComboLockTasks,
  NightDropDropDoorTasks,
  NightDropHeadTasks,
  NightDropSectionForm,
  NightDropSecurityForm,
  NightDropTimeClockTasks,
} from 'models/security/night-drop/nightDropSecurityForm';
import { mapComboLocks, mapTimeClocks } from 'models/security/shared/maps';

export const mapNightDropSectionsRequest = (panels: NightDropSectionForm[]): SecurityNightDropSectionDto[] => {
  return panels.map((panel) => ({
    nightDropModel: panel.nightDropModel,
    nightDropDoorStyle: panel.nightDropDoorStyle,
    nightDropManufacturer: panel.nightDropManufacturer,
    nightDropManufacturer_Other: panel.nightDropManufacturer_Other,
    isNightDropEncasedInConcrete: panel.isNightDropEncasedInConcrete,
    swingType: panel.swingType,
    overallConditionNightDrop: panel.overallConditionNightDrop,
    equipment_Rating_NightDrops: panel.equipment_Rating_NightDrops,
    nightDropSafeManufacturer: panel.nightDropSafeManufacturer,
    nightDropSafeManufacturer_Other: panel.nightDropSafeManufacturer_Other,
    security_NightDrop_ComboLocks: mapComboLocks(Object.values(panel.comboLocks)),
    security_NightDrop_TimeClocks: mapTimeClocks(Object.values(panel.timeClocks)),
  }));
};

export const mapNightDropDropDoorTasksRequest = (
  tasks?: NightDropDropDoorTasks
): SecurityNightDropDropDoorTasksDto[] => {
  return [
    {
      checkBoltWorkForProperOperation: tasks?.checkBoltWorkForProperOperation ?? null,
      inspectBoltWorkForExcessiveWear: tasks?.inspectBoltWorkForExcessiveWear ?? null,
      cleanAndLubricateBoltWork: tasks?.cleanAndLubricateBoltWork ?? null,
    },
  ];
};

export const mapNightDropTimeClockTasksRequest = (
  tasks?: NightDropTimeClockTasks
): SecurityNightDropTimeClockTasksDto[] => {
  return [
    {
      checkTimeLocksForProperOperation: tasks?.checkTimeLocksForProperOperation ?? null,
      pullRightTimeClockForCleaning: tasks?.pullRightTimeClockForCleaning ?? null,
      shiftAllTimeClocksRight: tasks?.shiftAllTimeClocksRight ?? null,
      replaceLeftTimeClock: tasks?.replaceLeftTimeClock ?? null,
      markAnyReplacedTimeClocksWithDateInstalled: tasks?.markAnyReplacedTimeClocksWithDateInstalled ?? null,
    },
  ];
};

export const mapNightDropComboLockTasksRequest = (
  tasks?: NightDropComboLockTasks
): SecurityNightDropComboLockTasksDto[] => {
  return [
    {
      checkComboLockForProperOperation: tasks?.checkComboLockForProperOperation ?? null,
      disassembleAndInspectComboLock: tasks?.disassembleAndInspectComboLock ?? null,
      lubricateAndReassembleComboLock: tasks?.lubricateAndReassembleComboLock ?? null,
      checkForDialAndRingProperOperation: tasks?.checkForDialAndRingProperOperation ?? null,
      disassembleAndInspectDialAndRing: tasks?.disassembleAndInspectDialAndRing ?? null,
    },
  ];
};

export const mapNightDropHeadTasksRequest = (tasks?: NightDropHeadTasks): SecurityNightDropHeadTasksDto[] => {
  return [
    {
      lubricateAndReassembleDialAndRing: tasks?.lubricateAndReassembleDialAndRing ?? null,
      checkForProperOperationOfHead: tasks?.checkForProperOperationOfHead ?? null,
      inspectHeadForExcessiveWear: tasks?.inspectHeadForExcessiveWear ?? null,
      lubricateHeadBoltTrack: tasks?.lubricateHeadBoltTrack ?? null,
      cleanHeadWithAppropriateCleaner: tasks?.cleanHeadWithAppropriateCleaner ?? null,
    },
  ];
};

export const mapNightDropRequest = (nightDrop: NightDropSecurityForm | null): SecurityNightDropDto => ({
  security_NightDrop: {
    security_NightDrop_NightDrops: mapNightDropSectionsRequest(nightDrop?.nightDropSections ?? []),
    security_NightDrop_NightDropDoorTasks: mapNightDropDropDoorTasksRequest(nightDrop?.nightDropDropDoorTasks),
    security_NightDrop_TimeClockTasks: mapNightDropTimeClockTasksRequest(nightDrop?.timeClockTasks),
    security_NightDrop_CombolockTasks: mapNightDropComboLockTasksRequest(nightDrop?.comboLockTasks),
    security_NightDrop_NightDropHeadTasks: mapNightDropHeadTasksRequest(nightDrop?.nightDropHeadTasks),
    comments: nightDrop?.comments ?? null,
  },
});
