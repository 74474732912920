import { Injectable, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'services';
import { ExpansionPanelComponent } from 'shared/material-wrappers';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  public showResetFormButton = signal(false);
  public sections = [] as ExpansionPanelComponent[];
  public expanded = false;
  public title = signal('');
  public isDesktopView = signal(false);
  public resetForm: (() => void) | undefined;

  constructor(private readonly service: ApiService) {}

  public checkAllCollapsed = () => {
    if (this.sections.every((section) => !section.expanded)) {
      this.expanded = false;
    }
  };

  public deleteAlert(alertId: number): Observable<void> {
    return this.service.sendRequest<void>({
      method: 'delete',
      url: `technician/deletealert?alertId=${alertId}`,
    });
  }
}
