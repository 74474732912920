import { CommonModule, Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AdsCallControl, AdsEquipmentOnSite } from 'models';
import { Observable } from 'rxjs';
import { PanelComponent } from 'shared/panel/panel.component';
import { DetailRowComponent } from '../detail-row/detail-row.component';
import { FormsModule } from '@angular/forms';
import { CommonFormInputComponent } from 'shared/material-wrappers';
import { Router } from '@angular/router';
import { RoutesEnum } from 'app/utils/navigation';
import { statusColors } from 'app/utils/call-control';

@Component({
  selector: 'ads-work-order-desktop',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    PanelComponent,
    MatButtonModule,
    DetailRowComponent,
    CommonFormInputComponent,
    FormsModule,
  ],
  templateUrl: './work-order-desktop.component.html',
  styleUrl: './work-order-desktop.component.scss',
})
export class WorkOrderDesktopComponent {
  @Input({ required: true }) searchText = '';
  @Input({ required: true }) workOrderDetails?: AdsCallControl;
  @Input({ required: true }) equipmentOnSite: Observable<AdsEquipmentOnSite[]> | undefined | null;
  @Input({ required: true }) workOrder = '';
  @Input({ required: true }) openTicketNotes: () => Promise<void> = () => Promise.resolve();
  @Input({ required: true }) openPhone = () => {};
  @Input({ required: true }) openMap: () => Promise<void> = () => Promise.resolve();
  @Input({ required: true }) openEquipmentOnSite: () => Promise<void> = () => Promise.resolve();

  constructor(protected readonly router: Router, private readonly location: Location) {}

  statusColors = statusColors;

  goBack = () => {
    return window.history.state.navigationId > 1
      ? this.location.back()
      : this.router.navigate([RoutesEnum.CallControl]);
  };
}
