import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ControlContainer, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { checkAllOptions, checkIfAllAreNa } from 'app/utils/select-all';
import { AtmExteriorEvaluation, SelectOption, YesNoNa } from 'models';
import { SingleToggleButtonComponent, RadioButtonGroupComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-atm-exterior-evaluation',
  standalone: true,
  imports: [SingleToggleButtonComponent, RadioButtonGroupComponent, SimpleFormComponent, MatDividerModule, FormsModule],
  templateUrl: './atm-exterior-evaluation.component.html',
  styleUrl: './atm-exterior-evaluation.component.scss',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AtmExteriorEvaluationComponent {
  @Input() section?: FormGroup;

  selectAllChecked = false;
  fields = Object.getOwnPropertyNames(new AtmExteriorEvaluation());

  options: SelectOption<YesNoNa>[] = [
    { name: 'Yes', value: YesNoNa.Yes },
    { name: 'No', value: YesNoNa.No },
    { name: 'N/A', value: YesNoNa.Na },
  ];

  onOptionClicked = () => checkIfAllAreNa(this.fields, this.section);
  onSelectAllChange = (shouldSelectAll: boolean) => checkAllOptions(shouldSelectAll, this.fields, this.section);
}
