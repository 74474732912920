import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { DetailRowComponent } from '../detail-row/detail-row.component';
import { WorkOrderMobileMenuComponent } from './work-order-mobile-menu/work-order-mobile-menu.component';
import { MatSelectModule } from '@angular/material/select';
import { nextStatus, WorkOrderStatus } from '../status/status';
import { WorkOrderService } from '../work-order-service';
import { MatDialog } from '@angular/material/dialog';
import { AttachmentListDialogComponent } from '../attachment-list-dialog/attachment-list-dialog.component';
import { EquipmentFilterPipe } from '../shared/equipment-filter/equipment-filter.pipe';
import { ThemingService } from 'services';
import { CallControlDateModel } from 'models/call-control/call-control-date-model';
import { NotifyOutOfSyncComponent } from '../shared/notify-out-of-sync/notify-out-of-sync.component';

@Component({
  selector: 'ads-work-order-mobile',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    DetailRowComponent,
    CommonFormInputComponent,
    FormsModule,
    MatButtonModule,
    MatSelectModule,
    WorkOrderMobileMenuComponent,
    SelectComponent,
    EquipmentFilterPipe,
  ],
  templateUrl: './work-order-mobile.component.html',
  styleUrl: './work-order-mobile.component.scss',
})
export class WorkOrderMobileComponent implements OnInit {
  nextStatuses: WorkOrderStatus[] = [];
  readonly dialogRef = inject(MatDialog);

  constructor(public workOrderService: WorkOrderService, protected readonly themeService: ThemingService) {}

  ngOnInit(): void {
    this.workOrderService.loadContactData();
    if (this.workOrderService.workOrderDetails().laststatusname) {
      this.nextStatuses = nextStatus.get(this.workOrderService.workOrderDetails().laststatusname as WorkOrderStatus) ?? [];
    }
  }

  getStatusLabel() {
    const label =
      this.workOrderService.workOrderDetails().laststatusname === 'Acknowledged'
        ? 'Acknowledged'
        : this.workOrderService.workOrderDetails().laststatusname;
    return label;
  }

  attachmentsClicked() {
    this.dialogRef.open(AttachmentListDialogComponent);
  }

  onStatusChange(newStatus: string) {
    this.workOrderService
      .getStatusTimestamp({ workOrder: this.workOrderService.workOrder })
      .subscribe((response) => this.setNewStatus(newStatus, response));
  }

  setNewStatus = (newStatus: string, response: CallControlDateModel) => {
    if (response.Status) {
      if (this.workOrderService.workOrderDetails().laststatusname?.includes(response.Status ?? ' ')) {
        this.workOrderService.statusSelected(newStatus);
      } else {
        this.workOrderService.showModal(NotifyOutOfSyncComponent);
      }
    } else {
      this.workOrderService.statusSelected(newStatus);
    }
  };
}
