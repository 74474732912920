export class CcCdExternalAreaTasks {
  allNa: boolean;
  cleanCustomerInterfaceArea: number | null;
  cleanTestScreen: number | null;
  cleanEntireExterior: number | null;
  checkAdjustmentScrewCalibration: number | null;
  verifyCablesAreNeatOrganizedBehindEquip: number | null;

  constructor() {
    this.allNa = false;
    this.cleanCustomerInterfaceArea = null;
    this.cleanTestScreen = null;
    this.cleanEntireExterior = null;
    this.checkAdjustmentScrewCalibration = null;
    this.verifyCablesAreNeatOrganizedBehindEquip = null;
  }
}
