import { Component } from '@angular/core';

@Component({
  selector: 'ads-simple-form',
  standalone: true,
  imports: [],
  templateUrl: './simple-form.component.html',
  styleUrl: './simple-form.component.scss',
})
export class SimpleFormComponent {}
