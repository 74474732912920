import { AdsActiveUserModel, AdsCallControl } from 'models';
import { TicketRequestModel } from 'models/call-control/ticket';

export const mapTicketData = (
  selectedDepartment: string,
  title: string,
  description: string,
  includeServiceOrder: boolean,
  selectedCall: AdsCallControl,
  userDetail: AdsActiveUserModel
): TicketRequestModel => {
  let desc = `<html><body>${description}</body></html>`;
  if (includeServiceOrder) {
    desc =
      `<html><body>` +
      `<b>Service Order:</b> ${selectedCall.workorder}` +
      `<br/><b>Project Name:</b> ${selectedCall.projgroup}` +
      `<br/><b>Problem Code:</b> ${selectedCall.problemTypeId}` +
      `<br/><br/><b>Service Order Description:</b><br/>${selectedCall.description}` +
      `<br/><br/><b>Customer:</b><br/>${selectedCall.customer}` +
      `<br/><br/><b>Service Address:</b><br/>${selectedCall.address}` +
      `<br/> ${selectedCall.city}, ${selectedCall.state} ${selectedCall.zip}` +
      `<br/><br/> ${description}` +
      `</body></html>`;
  }
  return {
    contactRecId: userDetail.ccUserId,
    contactSourceRecId: 1,
    title: title,
    customerId: 'CST-0014964', //Cook Solutions Group
    boardRecId: 0,
    ticketTypeRecId: 0,
    priorityRecId: 0,
    stateRecId: 0,
    initialDescription: desc,
    teamRecId: 0,
    projectId: selectedCall.projid ?? '',
    createdBy: userDetail.ccUserId,
    createdBySourceRecId: 1,
    contactMethod: '',
    contactMethodEmail: '',
    contactMethodPhone: '',
    contactMethodExtensioet: '',
    contactMethodName: '',
    department: selectedDepartment,
    equipmentDetailsForEmail: '',
    isTicketDashboard: false,
    isCallFromServiceModule: false,
    contactEmail: userDetail.userName ?? '',
    contactName: userDetail.fullName ?? '',
    ticketRecId: 0,
  };
};
