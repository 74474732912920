import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { ThemingService } from 'services';

@Component({
  selector: 'ads-custom-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatMenuModule],
  templateUrl: './custom-button.component.html',
  styleUrl: './custom-button.component.scss',
})
export class CustomButtonComponent implements OnInit {
  @Input() icon = '';
  @Input() label = '';
  @Input() matMenu: MatMenu | null = null;
  @Input() disabled = false;
  @Output() clicked = new EventEmitter<MouseEvent>();
  currentTheme = '';

  constructor(public readonly theming: ThemingService) {}

  ngOnInit(): void {
    this.currentTheme = this.theming.theme.name;
    this.theming.watchTheme.subscribe((theme) => {
      this.currentTheme = theme;
    });
  }

  onClick(e: MouseEvent) {
    this.clicked.emit(e);
  }
}
