import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectOption } from 'models';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { SelectComponent } from 'shared/material-wrappers';
import { ModalActionsComponent } from 'shared/modal-actions/modal-actions.component';

@Component({
  selector: 'ads-launch-form-dialog',
  standalone: true,
  imports: [CommonModule, FormsModule, ModalActionsComponent, SelectComponent],
  templateUrl: './launch-form-dialog.component.html',
  styleUrl: './launch-form-dialog.component.scss',
})
export class LaunchFormDialogComponent {
  selectedForm = '';

  selectFormOptions: SelectOption<string>[] = [
    { name: 'Atm', value: 'Atm' },
    { name: 'Atm Cleaning', value: 'AtmCleaning' },
    { name: 'Itm', value: 'Itm' },
    { name: 'Itm Cleaning', value: 'ItmCleaning' },
    { name: 'Security', value: 'Security' },
    { name: 'TCR', value: 'TcdTcr' },
    { name: 'Currency Counter/Discriminator', value: 'CcCd' },
  ];

  public readonly dialogRef = inject(MatDialog);

  constructor(private readonly workOrderService: WorkOrderService) {}

  onCancel() {
    this.dialogRef.closeAll();
  }

  onConfirm() {
    this.workOrderService.launchPMForm(this.selectedForm);
    this.dialogRef.closeAll();
  }
}
