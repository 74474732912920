import { CommonModule } from '@angular/common';
import { Component, Input, AfterViewInit } from '@angular/core';
import { FormsModule, ControlContainer, NgModelGroup, FormGroup } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { SelectOption, YesNo } from 'models';
import { CommonFormInputComponent, RadioButtonGroupComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from '../../../../shared/simple-form/simple-form.component';
import { VaultComboLockSection } from 'models/security/vault/combo-lock';

@Component({
  selector: 'ads-vault-combo-locks-section',
  standalone: true,
  imports: [CommonModule, FormsModule, CommonFormInputComponent, MatDividerModule, RadioButtonGroupComponent, SimpleFormComponent],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
  templateUrl: './vault-combo-locks-section.component.html',
})
export class VaultComboLocksSectionComponent implements AfterViewInit {
  @Input() section?: FormGroup;
  @Input() submitted = false;
  @Input() item?: VaultComboLockSection;

  options: SelectOption<YesNo>[] = [
    { name: 'Yes', value: YesNo.Yes },
    { name: 'No', value: YesNo.No },
  ];

  ngAfterViewInit() {
    setTimeout(() => {
      this.section?.controls['isThereKeyLockingDial'].setValue(this.item?.isThereKeyLockingDial);

      this.section?.controls['isComboLockChanged'].setValue(this.item?.isComboLockChanged);
    });
  }

  displayOtherCondition() {
    const field = this.section?.get('isComboLockChanged');
    return Boolean(field && field.value === YesNo.No);
  }
}
