import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  CcCdExternalAreaTasks,
  CcCdFeedModuleTasks,
  CcCdForm,
  CcCdPresentModuleTasks,
  CcCdSection,
  CcCdTasks,
  CcCdVacuumingBlowOutTasks,
} from 'models';
import { CcCdResponse } from 'models/api';
import { mapCcCdSection } from 'models/api/maps/cc-cd/cc-cd-map';
import { ccCdMapRequest } from 'models/api/maps/cc-cd/cc-cd-map-request';
import { FormPageService, PageRequest } from 'pages/form-page/form-page-service';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { ApiService, AuthService, PhotoUploadService } from 'services';
import { CcCdPhotoUploadKey } from 'utils';

@Injectable({
  providedIn: 'root',
})
export class CcCdService implements FormPageService<CcCdForm, CcCdResponse> {
  public form: CcCdForm = {
    ccCdSections: [],
    ccCdTasks: new CcCdTasks(),
    ccCdFeedModuleTasks: new CcCdFeedModuleTasks(),
    ccCdPresentModuleTasks: new CcCdPresentModuleTasks(),
    ccCdExternalAreaTasks: new CcCdExternalAreaTasks(),
    ccCdVacuumingBlowOutTasks: new CcCdVacuumingBlowOutTasks(),
    comments: '',
  };
  workOrder = '';
  ccCdSections: CcCdSection[] = [];
  photoUploadForms: FormGroup[] = [];

  constructor(
    private readonly service: ApiService,
    private readonly authService: AuthService,
    private readonly photoUploadService: PhotoUploadService
  ) {}

  updateForm(data: CcCdForm) {
    this.form = data;
  }

  public getForm(): Observable<CcCdResponse> {
    const params = new HttpParams({
      fromObject: { userId: this.authService.user?.personnelNumber ?? '' },
    });
    return this.service.sendRequest<CcCdResponse>({
      method: 'get',
      url: `pmforms/currencycounter/${this.workOrder}`,
      params,
      urlPrefix: 'ccc',
    });
  }

  public saveForm(request: PageRequest<CcCdForm>): Observable<string> {
    const saveForm = ccCdMapRequest({
      photosForm: this.photoUploadForms.map((form) => form.value),
      fieldsForm: this.form,
      comments: request.comments,
      workOrderDetail: request.workOrderDetail,
      pageSubmitMode: request.pageSubmitMode,
      pageOpenMode: request.pageOpenMode,
      ccCdSections: this.ccCdSections,
    });
    return this.service.sendRequest<string>({
      method: 'post',
      url: 'pmforms/currencycounter',
      body: saveForm,
      urlPrefix: 'ccc',
    });
  }

  public loadPhotos = (data: CcCdSection): Observable<Record<CcCdPhotoUploadKey, string>> => {
    const sources = [
      data.photoFront,
      data.photoSerialNumber,
      data.photoPrinter,
      data.photoExternalDevices,
      data.photoAdditionalPhoto,
    ].map((url) => {
      if (!url) {
        return Promise.resolve({ sasUrl: '' });
      }
      return this.photoUploadService.getPhotoUrl(url);
    });

    return forkJoin(sources).pipe(
      map(([photo_Front, photo_SerialNumber, photo_Printer, photo_ExternalDevices, photo_AdditionalPhoto]) => ({
        photoFront: photo_Front.sasUrl,
        photoSerialNumber: photo_SerialNumber.sasUrl,
        photoPrinter: photo_Printer.sasUrl,
        photoExternalDevices: photo_ExternalDevices.sasUrl,
        photoAdditionalPhoto: photo_AdditionalPhoto.sasUrl,
      })),
      catchError(() =>
        of({
          photoFront: '',
          photoSerialNumber: '',
          photoPrinter: '',
          photoExternalDevices: '',
          photoAdditionalPhoto: '',
        })
      )
    );
  };

  getPhotos(data: CcCdResponse, updateFormPhotos: (photos: Record<CcCdPhotoUploadKey, string>) => void) {
    data.currencyCounter.currencyCounterDiscriminatorItems.forEach((section, index) => {
      this.loadPhotos(mapCcCdSection(section, index)).subscribe((photos) => {
        updateFormPhotos(photos);
      });
    });
  }
}
