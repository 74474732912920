import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AdsRelatedArticle } from 'models/call-control/ads-call-control';
import { WorkOrderService } from '../work-order-service';
import { Router, UrlSerializer } from '@angular/router';

@Component({
  selector: 'ads-related-articles-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule],
  templateUrl: './related-articles-dialog.component.html',
  styleUrl: './related-articles-dialog.component.scss',
})
export class RelatedArticlesDialogComponent {
  articles = signal<AdsRelatedArticle[]>([]);
  isLoading = signal(true);

  public readonly dialogRef = inject(MatDialog);

  constructor(
    public readonly workOrderService: WorkOrderService,
    private readonly router: Router,
    private readonly serializer: UrlSerializer,
  ) {
    this.workOrderService.getRelatedArticles().subscribe((articles) => {
      this.articles.set(articles);
      this.isLoading.set(false);
    });
  }

  closeDialog() {
    this.dialogRef.closeAll();
  }

  viewArticle(articleRecId: number) {
    const queryParams = { id: articleRecId };
    const tree = this.router.createUrlTree([`/viewArticle`], { queryParams });
    const url = this.serializer.serialize(tree);
    window.open(url, '_blank');
  }
}
