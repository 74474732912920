<h4 class="title">Suspend</h4>
<div class="suspend-form">
  <ads-datepicker [disabled]="true" [maxDate]="maxDate" [(ngModel)]="startedDate" label="Started Date"></ads-datepicker>
  <ads-timepicker
    *ngIf="startedDate"
    [dateValue]="startedDate"
    (valueChange)="onSuspendTimeChange($event)"
  ></ads-timepicker>
  <mat-checkbox class="select-all" (change)="functionalityRestoredChanged($event)">
    Functionality Restored
  </mat-checkbox>
  <ads-datepicker [maxDate]="maxDate" [(ngModel)]="returnDate" label="Return Date"></ads-datepicker>
  <ads-timepicker
    *ngIf="startedDate"
    [disabled]="!loaded"
    [dateValue]="startedDate"
    (valueChange)="onReturnTimeChange($event)"
  ></ads-timepicker>
  <ads-common-text-area
    label="Technical Notes"
    class="completion-notes"
    [maxLength]="500"
    [(ngModel)]="techNotes"
  ></ads-common-text-area>
  <ads-select
    *ngIf="showMissingSLA()"
    label="Wells Fargo CDSE missed SLA reason"
    [list]="missingSLAreasons"
    [(ngModel)]="missingSLAReason"
  ></ads-select>
</div>
<ads-modal-actions
  (cancel)="hideAll()"
  (confirm)="onConfirm()"
  [disableConfirm]="!validSuspendTime || !validReturnTime"
></ads-modal-actions>
