import { TimeZonePipe } from "app/utils/time-zone.pipe";
import { AdsCallControl } from "models";
import { DifferencePipe } from "ngx-moment";

export const mapCalls = (data: AdsCallControl[], timeZonePipe: TimeZonePipe) => {
    // Migrating logic from previous ADS implementation
    const items: AdsCallControl[] = [];
    data.forEach((x) => {
      let pmFlag = false;
      const differencePipe = new DifferencePipe();
      const now = new Date();
      const utcOffset = new Date().getTimezoneOffset() / -60;
      const dueDate = x.servicedatetime ? timeZonePipe.transform(x.servicedatetime.toString()) : new Date();
      const daydiff = differencePipe.transform(now, dueDate, 'day', false);

      if (Math.abs(daydiff) >= 31 && x.problem?.includes('Preventative')) {
        pmFlag = true;
      }

      if (now > dueDate) {
        pmFlag = false;
      }

      x.dateopened = x.dateopened ? new Date(x.dateopened).toLocaleDateString() : new Date().toLocaleDateString();
      x.laststatusname = x.laststatusname === 'Ack' ? 'Acknowledged' : x.laststatusname;
      if (x.servicedatetime) {
        x.servicedatetime = new Date(x.servicedatetime);
        x.servicedatetime.setHours(x.servicedatetime.getHours() + utcOffset);
      }
      if (x.createddatetime) {
        x.createddatetime = new Date(x.createddatetime);
        x.createddatetime.setHours(x.createddatetime.getHours() + utcOffset);
      }
      x.createddatetime = x.createddatetime
        ? new Date(x.createddatetime).toLocaleString()
        : new Date().toLocaleString();
      x.servicedatetime = x.servicedatetime
        ? new Date(x.servicedatetime).toLocaleString()
        : new Date().toLocaleString();

      items.push({ ...x, isPm: pmFlag });
    });

    return items;
  }
