<form [formGroup]="formGroup">
  <div class="selects-container">
    <ads-radio-button-group
      [options]="optionsYesNoNa"
      label="Does a REX device unlock door?"
      name="doesAREXDeviceUnlockDoor"
      [required]="true"
      [formControlName]="'doesAREXDeviceUnlockDoor'"
    ></ads-radio-button-group>

    <ads-radio-button-group
      [options]="optionsCompleteNa"
      label="Test Lock"
      name="testLock"
      [required]="true"
      [formControlName]="'testLock'"
    ></ads-radio-button-group>

    <ads-radio-button-group
      [options]="optionsCompleteNa"
      label="Test Reader"
      name="testReader"
      [required]="true"
      [formControlName]="'testReader'"
    ></ads-radio-button-group>

    <ads-radio-button-group
      [options]="optionsCompleteNa"
      label="Test door held open alarm"
      name="testDoorHelpOpenAlarm"
      [required]="true"
      [formControlName]="'testDoorHelpOpenAlarm'"
    ></ads-radio-button-group>

    <ads-radio-button-group
      [options]="optionsCompleteNa"
      label="Test door forced alarm"
      name="testDoorForcedAlarm"
      [required]="true"
      [formControlName]="'testDoorForcedAlarm'"
    ></ads-radio-button-group>
  </div>

  <div class="button-container">
    <button mat-stroked-button type="button" (click)="onCancel()">Cancel</button>
    <button mat-stroked-button type="button" (click)="onAccept()">Accept</button>
    <button mat-button mat-flat-button class="main-button" type="button" (click)="onAcceptAndAddNew()">
      Accept & Add other
    </button>
  </div>
</form>
