<ads-service-calls-header
  [minDate]="getMinDate()"
  [isDesktopView]="isDesktopView"
  (sortingChanged)="refreshData()"
  (filterChanged)="refreshData()"
>
</ads-service-calls-header>
<div *ngIf="calls.length > 0" class="calls-list">
  <button
    *ngFor="let call of calls"
    class="call-container"
    [ngClass]="{ 'dark-theme': currentTheme === 'dark', 'light-theme': currentTheme === 'light' }"
    (click)="onCallClick(call)"
  >
    <div class="call-header">
      <span class="work-order">{{ call.workorder }}, {{ call.center }}</span>
      <span
        class="status-chip"
        [ngStyle]="{ background: statusColors.get(call.laststatusname ?? 'Started') }"
        [ngClass]="{ black: call.laststatusname === 'Scheduled' }"
      >
        {{ call.laststatusname }}
      </span>
    </div>
    <div class="call-description-row">
      <mat-icon class="material-icons-outlined">bolt</mat-icon><span class="time-range">{{ call.priority }}</span>
      <span *ngIf="isDesktopView && call.customerpo" class="description-row-end">
        <mat-icon class="material-icons-outlined">description</mat-icon><span>{{ call.customerpo }}</span>
      </span>
    </div>
    <div class="call-details-row">
      <span class="detail-content">
        <span class="line">{{ call.calltype }}</span>
        <span class="line">{{ call.problem }}</span>
      </span>
      <div *ngIf="isDesktopView" class="details-row-end">
        <div *ngIf="call.ticketRecId">
          <mat-icon class="material-icons-outlined">confirmation_number</mat-icon><span>{{ call.ticketRecId }}</span>
        </div>
        <div class="location-wrapper">
          <mat-icon class="material-icons-outlined">map</mat-icon>
          <div class="location-details">
            <span class="location-line">{{ call.address }}</span>
            <span class="location-line">{{ call.city }}, {{ call.state }}, {{ call.zip }}</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isDesktopView" class="call-footer">
      <span class="center"> {{ call.customer }}</span>
      <div class="call-footer-end">
        <span class="gray-out">
          <mat-icon>event</mat-icon>{{ getDate(call.servicedatetime) }}, {{ getTime(call.servicedatetime) }}</span
        >
        <span><mat-icon>event</mat-icon>{{ getDate(call.createddatetime) }}, {{ getTime(call.createddatetime) }}</span>
      </div>
    </div>
    <div *ngIf="!isDesktopView" class="call-footer">
      <span class="center"> {{ call.customer }}</span>
      <div class="call-footer-end mobile">
        <mat-icon>event</mat-icon>
        <div>
          <span>{{ getDate(call.createddatetime) }}</span>
          <span>{{ getTime(call.createddatetime) }}</span>
        </div>
      </div>
    </div>
  </button>
</div>
<div *ngIf="calls.length === 0" class="empty-list">
  <div>No results. <a href="javascript:void(0)" (click)="clearFilters()"> Click here to clear filters. </a></div>
</div>
