<div *ngIf="item && tcdTcrLists" class="tcr-section-wrapper" [ngModelGroup]="'tcdTcrSections#' + item.index">
  <ads-select
    label="TCD/TCR Manufacturer"
    name="manufacturer"
    [required]="true"
    [list]="tcdTcrLists.manufacturerList"
    [value]="item.manufacturer"
    [(ngModel)]="item.manufacturer"
  >
  </ads-select>
  <ads-common-form-input
    [style.display]="displayOtherManufacturer() ? 'block' : 'none'"
    name="otherManufacturer"
    label="Other Manufacturer"
    [maxLength]="64"
    [required]="displayOtherManufacturer()"
    [(ngModel)]="item.otherManufacturer"
  ></ads-common-form-input>

  <ads-common-form-input
    name="model"
    label="TCD/TCR Model"
    [maxLength]="30"
    [required]="true"
    [value]="item.model"
    [(ngModel)]="item.model"
  ></ads-common-form-input>
  <ads-common-form-input
    name="serialNumber"
    label="TCD/TCR Serial Number"
    [maxLength]="30"
    [required]="true"
    [value]="item.serialNumber"
    [(ngModel)]="item.serialNumber"
  ></ads-common-form-input>
  <ads-select
    label="Type"
    name="Type"
    [required]="true"
    [list]="tcdTcrLists.tcrTypeList"
    [value]="item.type"
    [(ngModel)]="item.type"
    (ngModelChange)="onTypeChange($event)"
  >
  </ads-select>
  <ads-common-form-input
    name="terminalId"
    label="Terminal ID"
    [maxLength]="30"
    [required]="true"
    [value]="item.terminalId"
    [(ngModel)]="item.terminalId"
  ></ads-common-form-input>
  <ads-common-form-input
    name="clientWorkstationsAmount"
    label="Quantity of Client Workstations"
    [maxLength]="2"
    [required]="true"
    [value]="item.clientWorkstationsAmount?.toString()"
    [(ngModel)]="item.clientWorkstationsAmount"
    type="number"
    [minValue]="0"
    inputMode="tel"
  ></ads-common-form-input>
  <ads-select
    label="Communication Type"
    name="communicationType"
    [required]="true"
    [list]="tcdTcrLists.communicationTypeList"
    [value]="item.communicationType"
    [(ngModel)]="item.communicationType"
  >
  </ads-select>
  <ads-common-form-input
    [style.display]="displayOtherCommunicationType() ? 'block' : 'none'"
    name="otherCommunicationType"
    label="Other Communication Type"
    [maxLength]="64"
    [required]="displayOtherCommunicationType()"
    [(ngModel)]="item.otherCommunicationType"
  ></ads-common-form-input>
  <ads-select
    label="Quantity of cassettes/rollers"
    name="cassettesRollersAmount"
    [required]="true"
    [list]="tcdTcrLists.cassettesRollersList"
    [value]="item.cassettesRollersAmount"
    [(ngModel)]="item.cassettesRollersAmount"
  >
  </ads-select>
  <ads-select
    label="Quantity of coin dispensers"
    name="coinDispensersAmount"
    [required]="true"
    [list]="tcdTcrLists.coinDispensersList"
    [value]="item.coinDispensersAmount?.toString()"
    [(ngModel)]="item.coinDispensersAmount"
  ></ads-select>
  <ads-common-form-input
    name="coinDispenserManufacturer"
    label="Manufacturer of coin dispenser"
    [maxLength]="30"
    [required]="false"
    [value]="item.coinDispenserManufacturer"
    [(ngModel)]="item.coinDispenserManufacturer"
  ></ads-common-form-input>

  <ads-common-text-area
    name="otherExternalEquipment"
    label="Other external equipment attached"
    [maxLength]="200"
    [required]="displayEquipmentRating()"
    [value]="item.otherExternalEquipment"
    [(ngModel)]="item.otherExternalEquipment">
  </ads-common-text-area>

  <ads-select
    label="Overall Condition - TCD/TCR"
    name="overallCondition"
    [required]="true"
    [list]="tcdTcrLists.conditionList"
    [value]="item.overallCondition"
    [(ngModel)]="item.overallCondition"
  ></ads-select>

  <ads-common-text-area
    [style.display]="displayEquipmentRating() ? 'block' : 'none'"
    name="equipmentRating"
    label="Substandard equipment comments"
    [maxLength]="500"
    [required]="displayEquipmentRating()"
    [value]="item.equipmentRating"
    [(ngModel)]="item.equipmentRating">
  </ads-common-text-area>

  <div>
    <h4>Photos</h4>
    <ads-photos-section
      *ngIf="section"
      [photoUploads]="photoUploads"
      [photoUploadForm]="photoUploadForm"
    ></ads-photos-section>
  </div>
</div>
