import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { environment } from 'environment/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  appInsights: ApplicationInsights;
  constructor(private readonly router: Router) {
    const angularPlugin = new AngularPlugin();

    let debug = {};

    if (window.location.href.includes('localhost')) {
      debug = {
        instrumentationKey: 'debug',
        disableTelemetry: true,
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const telemetryInitializer = (envelope: any) => {
      envelope.tags['ai.cloud.role'] = 'ADS-PWA';
    };

    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.loggers.appInsightsConnectionString,
        ...debug,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router },
        },
      },
    });

    this.appInsights.addTelemetryInitializer(telemetryInitializer);
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: Record<string, unknown>) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: Record<string, unknown>) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: Record<string, unknown>) {
    this.appInsights.trackTrace({ message: message }, properties);
  }

  startTrackPage(name?: string): void {
    this.appInsights.startTrackPage(name);
  }

  stopTrackPage(url?: string, name?: string): void {
    this.appInsights.stopTrackPage(name, url);
  }

  startTrackEvent(name?: string): void {
    this.appInsights.startTrackEvent(name);
  }

  stopTrackEvent(name: string, url?: string): void {
    this.appInsights.stopTrackEvent(
      name,
      {
        url: url ?? ''
      });
  }
}
