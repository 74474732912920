import { NetworkLogo } from '../../../shared/network-logo';
import { AdsItmCleaningRequest, ItmCleaningRequest } from '../../itm-cleaning/ads-itm-cleaning-request';
import {
  ITMCleaningITM,
  ITMCleaningExtEval,
  ITMCleaningNetworkLogos,
  ITMCleaningPhotos,
  ITMCleaningReplenishmentTasks,
  ITMCleaningTasks,
  ItmCleaningDto,
} from '../../itm-cleaning/itm-cleaning';

const getLogos = (logos: NetworkLogo[], other: string): ITMCleaningNetworkLogos => {
  const itmNetworkLogos = {
    itmNetworkLogos_Visa: false,
    itmNetworkLogos_Mastercard: false,
    itmNetworkLogos_Discover: false,
    itmNetworkLogos_AmericanExpress: false,
    itmNetworkLogos_Plus: false,
    itmNetworkLogos_Maestro: false,
    itmNetworkLogos_Cirrus: false,
    itmNetworkLogos_Star: false,
    itmNetworkLogos_Coop: false,
    itmNetworkLogos_Other: other,
  };

  logos.forEach((logo) => {
    switch (logo) {
      case NetworkLogo.Visa:
        itmNetworkLogos.itmNetworkLogos_Visa = true;
        break;
      case NetworkLogo.Mastercard:
        itmNetworkLogos.itmNetworkLogos_Mastercard = true;
        break;
      case NetworkLogo.Discover:
        itmNetworkLogos.itmNetworkLogos_Discover = true;
        break;
      case NetworkLogo.AmericanExpress:
        itmNetworkLogos.itmNetworkLogos_AmericanExpress = true;
        break;
      case NetworkLogo.Plus:
        itmNetworkLogos.itmNetworkLogos_Plus = true;
        break;
      case NetworkLogo.Maestro:
        itmNetworkLogos.itmNetworkLogos_Maestro = true;
        break;
      case NetworkLogo.Cirrus:
        itmNetworkLogos.itmNetworkLogos_Cirrus = true;
        break;
      case NetworkLogo.Star:
        itmNetworkLogos.itmNetworkLogos_Star = true;
        break;
      case NetworkLogo.Coop:
        itmNetworkLogos.itmNetworkLogos_Coop = true;
        break;
    }
  });

  return itmNetworkLogos;
};

export const itmCleaningMapRequest = ({
  photosForm,
  fieldsForm,
  comments,
  workOrderDetail,
  pageSubmitMode,
  pageOpenMode,
}: ItmCleaningRequest): AdsItmCleaningRequest => {
  const itm: ITMCleaningITM = {
    itm_Manufacturer: fieldsForm.itmSection.manufacturer,
    itm_Model: fieldsForm.itmSection.model,
    itm_NetworkTerminalId: fieldsForm.itmSection.networkTerminalId,
    itm_SerialNumber: fieldsForm.itmSection.serialNumber,
    itm_TerminalID: fieldsForm.itmSection.terminalId,
    itm_CommunicationType: fieldsForm.itmSection.communicationType,
    itm_DepositorType: fieldsForm.itmSection.depositorType,
  };

  const itmExtEval: ITMCleaningExtEval = {
    itmExtEval_AreAudioInstructionsFunctioning: fieldsForm.itmExteriorEvaluation.audioInstructionsFunctioning,
    itmExtEval_IsDepositoryAvailabilityStickerPresent: fieldsForm.itmExteriorEvaluation.depositoryAvailabilityStickerPresent,
    itmExtEval_IsSoftwareOnsite: fieldsForm.itmExteriorEvaluation.isSoftwareOnsite,
    itmExtEval_IsSurchargeStickerPresent: fieldsForm.itmExteriorEvaluation.surchargeStickerPresent,
  };

  const itmNetworkLogos = getLogos(fieldsForm.itmNetworkLogos.logos ?? [], fieldsForm.itmNetworkLogos.other);

  const cleaningTasks: ITMCleaningTasks = fieldsForm.cleaningTasks ? {
    cleaningTasks_CleanIdScanner: fieldsForm.cleaningTasks.cleanIdScanner,
    cleaningTasks_CleanSignaturePad: fieldsForm.cleaningTasks.cleanSignaturePad,
    cleaningTasks_CleanWipeCustomerDisplay: fieldsForm.cleaningTasks.customerDisplay,
    cleaningTasks_CleanWipeFasciaTrim: fieldsForm.cleaningTasks.fasciaTrim,
    cleaningTasks_CleanWipeFunctionKeys: fieldsForm.cleaningTasks.functionKeys,
    cleaningTasks_CleanItmAreaAndDisposeGarbage: fieldsForm.cleaningTasks.itmArea,
    cleaningTasks_CleanCoinHolder: fieldsForm.cleaningTasks.cleanCoinHolder,
    cleaningTasks_ThoroughlyInspectItm: fieldsForm.cleaningTasks.thoroughInspection,
    cleaningTasks_CleanWipeItmSides: fieldsForm.cleaningTasks.itmSidesBackAndTop,
    cleaningTasks_VaccumBlowoutAllFans: fieldsForm.cleaningTasks.vacuumAllFans,
    cleaningTasks_VaccumBlowoutAllFilters: fieldsForm.cleaningTasks.vacuumAllFilters,
    cleaningTasks_VaccumBlowoutSafe: fieldsForm.cleaningTasks.vacuumSafe,
    cleaningTasks_VaccumBlowoutTopHalf: fieldsForm.cleaningTasks.vacuumTopHalf,
  } : {
    cleaningTasks_CleanIdScanner: null,
    cleaningTasks_CleanSignaturePad: null,
    cleaningTasks_CleanWipeCustomerDisplay: null,
    cleaningTasks_CleanWipeFasciaTrim: null,
    cleaningTasks_CleanWipeFunctionKeys: null,
    cleaningTasks_CleanItmAreaAndDisposeGarbage: null,
    cleaningTasks_CleanCoinHolder: null,
    cleaningTasks_ThoroughlyInspectItm: null,
    cleaningTasks_CleanWipeItmSides: null,
    cleaningTasks_VaccumBlowoutAllFans: null,
    cleaningTasks_VaccumBlowoutAllFilters: null,
    cleaningTasks_VaccumBlowoutSafe: null,
    cleaningTasks_VaccumBlowoutTopHalf: null,
  };

  const replenishmentTasks: ITMCleaningReplenishmentTasks = fieldsForm.replenishmentTasks ? {
    replenishmentTasks_CheckPrinterPaperRoll: fieldsForm.replenishmentTasks.paperRoll,
    replenishmentTasks_CheckDepositoryInkCartridge: fieldsForm.replenishmentTasks.depositoryInk,
  } : {
    replenishmentTasks_CheckPrinterPaperRoll: null,
    replenishmentTasks_CheckDepositoryInkCartridge: null,
  };

  const photo: ITMCleaningPhotos = {
    photoFrontClose: photosForm['itm-front-close'].value?.split('?sv=')[0] ?? null,
    photoItmFeeNotice: photosForm['itm-fee-notice'].value?.split('?sv=')[0] ?? null,
    photoItmScreen: photosForm['itm-screen'].value?.split('?sv=')[0] ?? null,
    photoItmNetworkDecals: photosForm['itm-network-decals'].value?.split('?sv=')[0] ?? null,
    photoLongDistance: photosForm['itm-long-distance'].value?.split('?sv=')[0] ?? null,
    photoDamage: photosForm['itm-damage'].value?.split('?sv=')[0] ?? null,
    photoMissingParts: photosForm['itm-missing-parts'].value?.split('?sv=')[0] ?? null,
    photoMissingStickers: photosForm['itm-missing-stickers'].value?.split('?sv=')[0] ?? null,
    photo_AdditionalPhoto: photosForm['itm-additional-photo'].value?.split('?sv=')[0] ?? null,
    photoSerialNumber: photosForm['itm-serial-number'].value?.split('?sv=')[0] ?? null,
  };

  const itmCleaning: ItmCleaningDto = {
    updatedOn: new Date().toUTCString(),
    performedBy: workOrderDetail.performedBy ?? null,
    workOrderId: workOrderDetail.id,
    ...itm,
    ...itmExtEval,
    ...itmNetworkLogos,
    ...cleaningTasks,
    ...replenishmentTasks,
    ...photo,
    comments,
  };

  return {
    pageOpenMode,
    pageSubmitMode,
    isEditable: true,
    fromXml: false,
    itmCleaning,
    workOrderDetail: workOrderDetail,
  };
};
