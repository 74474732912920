<button class="equipment-info" (click)="cardClicked()">
  <span class="terminal-id">{{ item.terminalID }}</span>
  <span class="equipment-type"> <mat-icon>build</mat-icon>{{ item.equipmentType }}: {{ item.type }}</span>
  <span class="manufacturer-model">{{ item.manufacturer }}, {{ item.model }}</span>
</button>
<div class="actions" *ngIf="canRemove || canSelect">
  <button *ngIf="canRemove" mat-icon-button class="remove-button" (click)="removeClicked.emit()">
    <mat-icon>delete</mat-icon>
  </button>
  <mat-checkbox
    *ngIf="canSelect"
    [checked]="isSelected"
    color="primary"
  ></mat-checkbox>
</div>
