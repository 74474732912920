import { SafesFormSection } from 'forms/security/safes/safes.component';
import { ComboLockSection } from '../shared/combo-lock';
import { TimeClockTasks, ComboLockTasks } from '../shared/shared-tasks';
import { TimeClockSection } from '../shared/time-clock';

export interface SafesTabFormSection {
  safeType: string | null;
  safeManufacturer: string | null;
  safeManufacturer_Other: string | null;
  swingType: string | null;
  overallConditionSafe: string | null;
  equipment_Rating_Safe: string | null;
  safeModel: string | null;
  comboLocks: ComboLockSection[];
  timeClocks: TimeClockSection[];
}

export class SafesTasks {
  checkBoltWorkForProperOperation: number | null;
  inspectBoltWorkForExcessiveWear: number | null;
  cleanAndLubricateBoltWork: number | null;

  constructor() {
    this.checkBoltWorkForProperOperation = null;
    this.inspectBoltWorkForExcessiveWear = null;
    this.cleanAndLubricateBoltWork = null;
  }
}

export class SafesComboLockTasks extends ComboLockTasks {
  lubricateAndReassembleDialAndRing: number | null;
  constructor() {
    super();
    this.lubricateAndReassembleDialAndRing = null;
  }
}

export interface SafesSecurityForm {
  safesSections: SafesFormSection[];
  safesTasks: SafesTasks;
  timeClockTasks: TimeClockTasks;
  comboLockTasks: SafesComboLockTasks;
  comments: string | null;
}
