import { CommonModule } from '@angular/common';
import { Component, Input, AfterViewInit } from '@angular/core';
import { FormsModule, ControlContainer, NgModelGroup, FormGroup } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { SelectOption, YesNo } from 'models';
import { VaultTimeClockSection } from 'models/security/vault/time-clock';
import { CommonFormInputComponent, RadioButtonGroupComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-vault-time-clocks-section',
  standalone: true,
  imports: [CommonModule, FormsModule, CommonFormInputComponent, MatDividerModule, RadioButtonGroupComponent, SimpleFormComponent],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
  templateUrl: './vault-time-clocks-section.component.html',
})
export class VaultTimeClocksSectionComponent implements AfterViewInit {
  @Input() section?: FormGroup;
  @Input() submitted = false;
  @Input() item?: VaultTimeClockSection;

  options: SelectOption<YesNo>[] = [
    { name: 'Yes', value: YesNo.Yes },
    { name: 'No', value: YesNo.No },
  ];

  ngAfterViewInit() {
    setTimeout(() => {
      this.section?.controls['isLinkageConnectedDirectlyToComboLock'].setValue(
        this.item?.isLinkageConnectedDirectlyToComboLock
      );
    });
  }
}
