import { getAreAllNa } from 'app/utils/areAllNa';
import { DealDrawerFormSection } from 'forms/security/deal-drawer/deal-drawer.component';
import { SecurityDealDrawerSectionDto } from 'models/api/security/dead-drawer/deal-drawer-dto';
import { SecurityDealDrawerTasksDto } from 'models/api/security/dead-drawer/deal-drawer-tasks';
import { SecurityDealDrawerSectionsDto } from 'models/api/security/security';
import { DealDrawerSecurityForm } from 'models/security/deal-drawer/dealDrawerSecurityForm';
import { DealDrawerTasks } from 'models/security/deal-drawer/dealDrawerTasks';

export const mapDealDrawerSectionsResponse = (panels: SecurityDealDrawerSectionDto[]): DealDrawerFormSection[] => {
  if (panels.length === 0) {
    return [new DealDrawerFormSection(1)];
  }
  return panels.map((panel, index) => ({
    dealDrawerModel: panel.dealDrawerModel,
    dealDrawerMake: panel.dealDrawerMake,
    laneNumber: panel.lane ?? null,
    otherMake: panel.dealDrawerMake_Other,
    overallCondition: panel.overallConditionDealDrawer,
    overallConditionComments: panel.overallConditionDealDrawer_Other,
    index: index + 1,
  }));
};

export const mapSecurityDealDrawerResponse = (data: SecurityDealDrawerSectionsDto): DealDrawerSecurityForm => ({
  dealDrawerSections: mapDealDrawerSectionsResponse(data.security_DealDrawer_DealDrawers),
  dealDrawerTasks: mapDealDrawerTasksResponse(data.security_DealDrawer_DealDrawerTasks),
  comments: data.comments ?? null,
});

export const mapDealDrawerTasksResponse = (data: SecurityDealDrawerTasksDto[]): DealDrawerTasks => ({
  allNa: getAreAllNa(data[0]),
  cleanDrawerTracks: data[0]?.cleanAndLubeDrawerTracks ?? null,
  cleanUnit: data[0]?.cleanUnitWithAppropriateCleaner ?? null,
  testDrawerMotor: data[0]?.inspectAndTestDrawerMotor ?? null,
  testDrawerTracks: data[0]?.inspectAndTestDrawerTracks ?? null,
  testHeaterAssy: data[0]?.inspectCleanAndTestHeaterAssy ?? null,
  testManualOperation: data[0]?.testManualOperation ?? null,
  testMotorizedOperation: data[0]?.testMotorizedOperation ?? null,
  testSwitches: data[0]?.inspectAndTestinoutonoffSwitches ?? null,
});
