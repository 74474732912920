import { CommonModule } from '@angular/common';
import { Component, inject, input, Input, OnInit, signal } from '@angular/core';
import { FormsModule, ControlContainer, NgModelGroup } from '@angular/forms';
import { AccessControlHardwareDropdowns } from 'models/security/security-dropdowns';
import { AccessControlCardReaderInterfaceTableFormSection } from '../access-hardware.component';
import { TableSectionComponentBaseComponent } from 'forms/shared/table-section-component-base/table-section-component.base';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { TableFormBottomSheetInputComponent } from 'shared/material-wrappers/table-form/table-form-bottom-sheet-input/table-form-bottom-sheet-input.component';
import { TableFormInputComponent } from 'shared/material-wrappers/table-form/table-form-input/table-form-input.component';
import { SelectionModel } from '@angular/cdk/collections';
import { TableBottomSheetsComponent } from 'forms/shared/table-bottom-sheets/table-bottom-sheets.component';

@Component({
  selector: 'ads-ah-card-reader-interface-table-section',
  standalone: true,
  imports: [
    MatTableModule,
    CommonModule,
    FormsModule,
    MatInputModule,
    TableFormInputComponent,
    TableFormBottomSheetInputComponent,
    MatBottomSheetModule,
    MatCheckboxModule,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
  templateUrl: './ah-card-reader-interface-table-section.component.html',
  styleUrl: './ah-card-reader-interface-table-section.component.scss',
})
export class AhCardReaderInterfaceTableSectionComponent extends TableSectionComponentBaseComponent implements OnInit {
  displayedColumnsKeys = signal(['location', 'interfaceType', 'port', 'address']);

  @Input() list: AccessControlCardReaderInterfaceTableFormSection[] = [];
  readonly dropdowns = input<AccessControlHardwareDropdowns>();

  selection = new SelectionModel<AccessControlCardReaderInterfaceTableFormSection>(true, []);

  private readonly _bottomSheet = inject(MatBottomSheet);

  ngOnInit() {
    this.selection.changed.subscribe((change) => {
      change.added.concat(change.removed).forEach((item) => {
        this.onDeleteChecked(item.index);
      });
    });
  }

  onBlur = (element: AccessControlCardReaderInterfaceTableFormSection) => () => {
    if (!element.interfaceType) {
      this.openBottomSheet(element)();
    }
  };

  openBottomSheet = (element: AccessControlCardReaderInterfaceTableFormSection) => () => {
    const onDismiss = this._bottomSheet.open(TableBottomSheetsComponent, {
      data: {
        selections: [
          {
            title: 'Interface Type',
            control: 'interfaceType',
            options: this.dropdowns()?.acCardReaderInterfaceTableInterfaceTypes,
            value: element.interfaceType,
          },
        ],
        addNew: this.addNew,
      },
    });

    onDismiss.afterDismissed().subscribe((result) => {
      if (result) {
        element.interfaceType = result.interfaceType;
      }
    });
  };

  displaySelectKeys = () => {
    this.displayedColumnsKeys.set(['select', 'location', 'interfaceType', 'port', 'address']);
  };

  displayKeys = () => {
    this.displayedColumnsKeys.set(['location', 'interfaceType', 'port', 'address']);
  };

  displayOtherInterface = (element: AccessControlCardReaderInterfaceTableFormSection) => {
    return element.interfaceType === 'Other';
  };
}
