import { Component, EventEmitter, inject, Input, Output, TemplateRef } from '@angular/core';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { CommonModule } from '@angular/common';
import { AbstractControl, ControlContainer, FormsModule, NgModelGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { RemoveFormSectionComponent } from 'shared/remove-form-section/remove-form-section.component';
import { IndexableFormType } from 'app/utils/miscTypes';
import { getFormGroup } from 'utils';

@Component({
  selector: 'ads-nested-multiple-forms-section',
  standalone: true,
  imports: [CommonModule, FormsModule, ExpansionPanelComponent, MatButtonModule, MatIconModule, MatExpansionModule],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
  templateUrl: './nested-multiple-forms-section.component.html',
  styleUrl: './nested-multiple-forms-section.component.scss',
})
export class NestedMultipleFormsSectionComponent<T extends IndexableFormType> {
  @Input() title = '';
  @Input() itemTitle?: string;
  @Input() groupName = '';
  @Input() list: T[] = [];
  @Input() formTemplate?: TemplateRef<object>;
  @Input() formControls?: Record<string, AbstractControl<string, string>>;
  @Output() addClicked = new EventEmitter<void>();
  @Output() deleteClicked = new EventEmitter<T>();
  expanded = true;
  @Input() expandedList: number[] = [];
  @Input() submitted = false;
  @Input() control: AbstractControl<string, string> | undefined;
  @Input() minItems = 1;

  readonly dialog = inject(MatDialog);

  onAddClick(event: Event) {
    this.expanded = true;
    event.preventDefault();
    this.addClicked.emit();
  }

  onDeleteClick(event: Event, item: T) {
    event.preventDefault();

    this.dialog.open(RemoveFormSectionComponent, {
      data: { sectionName: this.title + ': ' + item.index, onConfirm: () => this.deleteClicked.emit(item) },
    });
  }

  getShowDeleteButton() {
    return this.list.length > this.minItems && !this.submitted;
  }

  getFormStatus = (index: number) => () => {
    const control = this.formControls?.[this.groupName + '#' + index];

    if (this.expandedList.includes(index)) {
      return 'dirty';
    }

    if (control !== undefined) {
      if (control.disabled) {
        return 'valid';
      }
      if (control.valid) {
        control.markAllAsTouched();
        return 'valid';
      }
      if (control.pristine) {
        return 'pristine';
      }
      if (control.touched) {
        return 'dirty';
      }
    }
    return 'pristine';
  };

  getFormGroup(index: number) {
    return getFormGroup(this.formControls?.[this.groupName + '#' + index]);
  }
}
