<div class="buttons-container" [ngClass]="{ 'container-desktop': isDesktop }">
  <ads-custom-button-inverted label="CC" class="home-button" (clicked)="navigateToCC()" icon="shield"></ads-custom-button-inverted>
  <ads-custom-button-inverted label="Email" class="home-button" (clicked)="navigateEmail()" icon="mail"></ads-custom-button-inverted>
  <ads-custom-button-inverted label="Forms" class="home-button" [matMenu]="formsMenu" icon="apps"></ads-custom-button-inverted>

  <mat-menu #formsMenu="matMenu" yPosition="below">
    <button mat-menu-item (click)="navigateKaba()">
      <mat-icon class="material-icons-outlined">lock</mat-icon>Kaba Request
    </button>
    <button mat-menu-item (click)="navigateRMA()">
      <mat-icon class="material-icons-outlined">description</mat-icon>RMA Request
    </button>
  </mat-menu>
  <ads-calls-container *ngIf="isDesktop" [newCalls]="newCalls" [openCalls]="openCalls" [isDesktop]="true"></ads-calls-container>
</div>
