import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { Component, Inject, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { ModalActionsComponent } from 'shared/modal-actions/modal-actions.component';
import { TravelBackAndLunchComponent } from '../travel-back-and-lunch/travel-back-and-lunch.component';
import { LoaderService } from 'shared/loader/loader.service';

@Component({
  selector: 'ads-ask-travel-back-and-lunch',
  standalone: true,
  imports: [CommonModule, FormsModule, MatTabsModule, ModalActionsComponent, MatCheckboxModule],
  templateUrl: './ask-travel-back-and-lunch.component.html',
  styleUrl: './ask-travel-back-and-lunch.component.scss',
  providers: [
    {
      provide: Window,
      useValue: window,
    },
  ],
})
export class AskTravelBackAndLunchComponent {
  public readonly _snackBar = inject(MatSnackBar);
  public readonly _bottomSheet = inject(MatBottomSheet);
  public readonly _dialogRef = inject(MatDialog);

  constructor(
    public workOrderService: WorkOrderService,
    public readonly router: Router,
    @Inject(Window) private readonly window: Window,
    public readonly loader: LoaderService,
  ) {}

  refreshPage = () => this.window.location.reload();

  hideAll(shouldRefresh = true) {
    this._dialogRef.closeAll();
    this._bottomSheet.dismiss();
    if (shouldRefresh && !this.router.url.includes('forcible')) {
      this.loader.show();
      setTimeout(() => {
        this.loader.hide();
        this.refreshPage();
      }, 3000);
    }
  }

  onConfirm() {
    this.hideAll(false);
    this.workOrderService.showModal(TravelBackAndLunchComponent);
  }
}
