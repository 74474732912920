export class AtmCardReaderTasks {
  allNa: boolean;
  cleanPrehead: number | null;
  cleanAllRollers: number | null;
  cleanAllBelts: number | null;
  cleanReadHead: number | null;
  cleanFeltCard: number | null;
  blowSensorAreas: number | null;
  diagnosticTest: number | null;
  evmCapable: number | null;

  constructor() {
    this.allNa = false;
    this.cleanPrehead = null;
    this.cleanAllRollers = null;
    this.cleanAllBelts = null;
    this.cleanReadHead = null;
    this.cleanFeltCard = null;
    this.blowSensorAreas = null;
    this.diagnosticTest = null;
    this.evmCapable = null;
  }
}
