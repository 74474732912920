import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ControlContainer, FormGroup, FormsModule, NgModelGroup } from '@angular/forms';
import {
  SingleToggleButtonComponent,
  CommonFormInputComponent,
} from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ads-sensors',
  standalone: true,
  imports: [
    CommonModule,
    SingleToggleButtonComponent,
    SimpleFormComponent,
    MatDividerModule,
    FormsModule,
    CommonFormInputComponent,
  ],
  templateUrl: './sensors.component.html',
  styleUrl: './sensors.component.scss',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class SensorsComponent {
  @Input() section?: FormGroup;
}
