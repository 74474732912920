import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { Component, inject, TemplateRef, ViewChild } from '@angular/core';
import { SelectComponent } from 'shared/material-wrappers/select/select.component';
import { CustomPromptComponent } from 'shared/custom-prompt/custom-prompt.component';
import { SupportRequestComponent } from '../../shared/support-request/support-request.component';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { OpenCallsDialogComponent } from 'pages/work-order/open-calls-dialog/open-calls-dialog.component';
import { CancelRequestComponent } from 'pages/work-order/shared/cancel-request/cancel-request.component';
import { ChangePriorityComponent } from 'pages/work-order/shared/change-priority/change-priority.component';
import { AttachmentListDialogComponent } from 'pages/work-order/attachment-list-dialog/attachment-list-dialog.component';
import { RelatedArticlesDialogComponent } from 'pages/work-order/related-articles-dialog/related-articles-dialog.component';
import { NotifyOutOfSyncComponent } from 'pages/work-order/shared/notify-out-of-sync/notify-out-of-sync.component';
import { CallControlDateModel } from 'models/call-control/call-control-date-model';

@Component({
  selector: 'ads-work-order-desktop-menu',
  standalone: true,
  imports: [CommonModule, FormsModule, CustomPromptComponent, SelectComponent, MatButtonModule],
  templateUrl: './work-order-desktop-menu.component.html',
  styleUrl: './work-order-desktop-menu.component.scss',
})
export class WorkOrderDesktopMenuComponent {
  @ViewChild('menu', { read: TemplateRef }) menu!: TemplateRef<unknown>;
  @ViewChild('requestSupportTemplate', { read: TemplateRef }) requestSupportTemplate!: TemplateRef<unknown>;
  readonly dialogRef = inject(MatDialog);

  constructor(public workOrderService: WorkOrderService) {}

  launchFormClicked() {
    this.workOrderService.handlePMForm();
  }

  openCallsClicked() {
    this.dialogRef.open(OpenCallsDialogComponent);
  }

  siteProfileClicked() {
    this.workOrderService.handleSiteProfile();
  }

  articleClicked() {
    this.dialogRef.open(RelatedArticlesDialogComponent);
  }

  supportClicked() {
    this.dialogRef.open(SupportRequestComponent);
  }

  changePriorityClicked() {
    this.dialogRef.open(ChangePriorityComponent);
  }

  cancelClicked() {
    this.dialogRef.open(CancelRequestComponent);
  }

  attachmentsClicked() {
    this.dialogRef.open(AttachmentListDialogComponent);
  }

  onStatusChange(newStatus: string) {
    this.workOrderService
      .getStatusTimestamp({ workOrder: this.workOrderService.workOrder })
      .subscribe((response) => this.setNewStatus(newStatus, response));
  }

  setNewStatus = (newStatus: string, response: CallControlDateModel) => {
    if (response.Status) {
      if (this.workOrderService.workOrderDetails().laststatusname?.includes(response.Status)) {
        this.workOrderService.statusSelected(newStatus);
      } else {
        this.workOrderService.showModal(NotifyOutOfSyncComponent);
      }
    } else {
      this.workOrderService.statusSelected(newStatus);
    }
  };
}
