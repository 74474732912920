import { Component } from '@angular/core';
import { AuthService } from 'services';

@Component({
  selector: 'ads-login',
  standalone: true,
  imports: [],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  currentYear = new Date().getFullYear();

  constructor(public readonly authService: AuthService) {}

  loginPopup() {
    this.authService.loginPopup();
  }
  onKeyPress(e: Event) {
    const event = e as KeyboardEvent;
    if (event.key == 'Enter') {
      this.loginPopup();
    }
  }
}
