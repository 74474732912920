import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, NgForm, NgModelGroup } from '@angular/forms';
import { SelectOption } from 'models';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';

@Component({
  selector: 'ads-itm-specs-section',
  standalone: true,
  imports: [CommonModule, FormsModule, CommonFormInputComponent, SimpleFormComponent, SelectComponent, CommonTextAreaComponent],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './itm-specs-section.component.html',
})
export class ItmSpecsSectionComponent {
  @Input() section?: FormGroup;
  @Input() itmManufacturerList: SelectOption<string>[] = [];
  @Input() depositorTypeList: SelectOption<string>[] = [];
  @Input() communicationTypeList: SelectOption<string>[] = [];
  @Input() overallConditionList: SelectOption<string>[] = [];

  constructor(@Optional() public readonly ngModelGroup: NgModelGroup) {}

  displaySubCondition = () => {
    const control = this.section?.get('overallCondition');
    return Boolean(control && (control.value === 'Poor Condition' || control.value === 'Failed'));
  };
}
