export class ItmCashCheckDepositoryTasks {
  allNa: boolean;
  cleanAllSensors: number | null;
  checkAndCleanAllRollers: number | null;
  checkAndCleanAllBelts: number | null;
  cleanInkArea: number | null;
  cleanCheckScannerLenses: number | null;
  checkPrintQuality: number | null;
  checkAndCleanAllBins: number | null;
  verifyInfeedAlignmentToFascia: number | null;
  performDiagnosticTest: number | null;
  replaceRetardCartridges: number | null;

  constructor() {
    this.allNa = false;
    this.cleanAllSensors = null;
    this.checkAndCleanAllRollers = null;
    this.checkAndCleanAllBelts = null;
    this.cleanInkArea = null;
    this.cleanCheckScannerLenses = null;
    this.checkPrintQuality = null;
    this.checkAndCleanAllBins = null;
    this.verifyInfeedAlignmentToFascia = null;
    this.performDiagnosticTest = null;
    this.replaceRetardCartridges = null;
  }
}
