<button
  mat-button
  class="menu-button"
  [class.active]="layoutNavService.selectedIndex === navIndex && isDesktop"
  [class.space-between]="layoutNavService.sideNavOpened  || !layoutNavService.isDesktopView()"
  (click)="onClick()"
>
  <div class="text-container">
    <mat-icon class="material-icons-outlined">{{ icon }}</mat-icon>
    <span *ngIf="layoutNavService.sideNavOpened || !layoutNavService.isDesktopView()">
      {{ title }}
    </span>
  </div>
  <mat-icon class="material-icons-outlined" iconPositionEnd style="font-size: 20px" *ngIf="!isDesktop"
    >chevron_right</mat-icon
  >
</button>
