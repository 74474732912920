import { SecurityDealDrawerSectionDto } from 'models/api/security/dead-drawer/deal-drawer-dto';
import { SecurityDealDrawerTasksDto } from 'models/api/security/dead-drawer/deal-drawer-tasks';
import { SecurityDealDrawerDto } from 'models/api/security/security';
import { DealDrawerSectionForm, DealDrawerSecurityForm } from 'models/security/deal-drawer/dealDrawerSecurityForm';
import { DealDrawerTasks } from 'models/security/deal-drawer/dealDrawerTasks';

export const mapDealDrawerSections = (panels: DealDrawerSectionForm[]): SecurityDealDrawerSectionDto[] => {
  return panels.map((panel) => ({
    lane: panel.laneNumber ?? null,
    dealDrawerModel: panel.dealDrawerModel,
    dealDrawerMake: panel.dealDrawerMake,
    dealDrawerMake_Other: panel.otherMake,
    overallConditionDealDrawer: panel.overallCondition,
    overallConditionDealDrawer_Other: panel.overallConditionComments,
  }));
};

export const mapDealDrawerTasks = (data: DealDrawerTasks): SecurityDealDrawerTasksDto[] => ([{
  cleanAndLubeDrawerTracks: data.cleanDrawerTracks,
  cleanUnitWithAppropriateCleaner: data.cleanUnit,
  inspectAndTestDrawerMotor: data.testDrawerMotor,
  inspectAndTestDrawerTracks: data.testDrawerTracks,
  inspectCleanAndTestHeaterAssy: data.testHeaterAssy,
  testManualOperation: data.testManualOperation,
  testMotorizedOperation: data.testMotorizedOperation,
  inspectAndTestinoutonoffSwitches: data.testSwitches,
}])

export const mapSecurityDealDrawer = (data: DealDrawerSecurityForm) => ({
  dealDrawerSections: mapDealDrawerSections(data.dealDrawerSections),
});

export const mapDealDrawerRequest = (dealDrawer: DealDrawerSecurityForm | null): SecurityDealDrawerDto => ({
  security_DealDrawer: {
    security_DealDrawer_DealDrawers: mapDealDrawerSections(dealDrawer?.dealDrawerSections ?? []),
    security_DealDrawer_DealDrawerTasks: mapDealDrawerTasks(dealDrawer?.dealDrawerTasks ?? new DealDrawerTasks()),
    comments: dealDrawer?.comments ?? null,
  },
});
