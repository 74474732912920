import { SelectOption } from './select-option';

export enum NetworkLogo {
  Visa = 'visa',
  Mastercard = 'mastercard',
  Discover = 'discover',
  AmericanExpress = 'americanExpress',
  Plus = 'plus',
  Maestro = 'maestro',
  Cirrus = 'cirrus',
  Star = 'star',
  Coop = 'coop',
  None = 'none',
}

export const networkLogosOptions: SelectOption<NetworkLogo>[] = [
  { name: 'Visa', value: NetworkLogo.Visa },
  { name: 'Mastercard', value: NetworkLogo.Mastercard },
  { name: 'Discover', value: NetworkLogo.Discover },
  { name: 'American Express', value: NetworkLogo.AmericanExpress },
  { name: 'Plus', value: NetworkLogo.Plus },
  { name: 'Maestro', value: NetworkLogo.Maestro },
  { name: 'Cirrus', value: NetworkLogo.Cirrus },
  { name: 'Start', value: NetworkLogo.Star },
  { name: 'Co-op', value: NetworkLogo.Coop },
  { name: 'No network logos present', value: NetworkLogo.None },
];
