import { YesNo } from '../shared/yes-no-na';

export class ItmSoftware {
  onsite: YesNo | null;
  operatingSystem: string | null;
  softwareVersion: string | null;

  constructor() {
    this.onsite = null;
    this.operatingSystem = '';
    this.softwareVersion = '';
  }
}
