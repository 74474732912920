import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatCheckboxModule, MatCheckboxChange } from '@angular/material/checkbox';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { ReviewEquipmentService } from './../review-equipment.service';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { CustomButtonComponent } from 'shared/custom-button/custom-button.component';
import { filter } from 'pages/work-order/shared/equipment-filter/equipment-filter.pipe';
import { EquipmentTableComponent } from 'shared/equipment-table/equipment-table.component';
import { CommonFormInputComponent } from 'shared/material-wrappers/common-form-input/common-form-input.component';
import { EquipmentEntityModel } from 'models/equipment/equipment';
import { AuthService } from 'services';

@Component({
  selector: 'ads-add-equipment',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    EquipmentTableComponent,
    CommonFormInputComponent,
    CustomButtonComponent,
  ],
  templateUrl: './add-equipment.component.html',
  styleUrl: './add-equipment.component.scss',
})
export class AddEquipmentComponent implements OnInit {
  searchText = '';
  displayedColumnsKeys = [
    'select',
    'equipmentType',
    'partsWarrantyEnds',
    'manufacturer',
    'model',
    'type',
    'terminalID',
  ];
  allEquipment: EquipmentEntityModel[] = [];
  readonly _dialogRef = inject(MatDialog);
  readonly _bottomSheet = inject(MatBottomSheet);
  areAllSelected = false;

  constructor(
    private readonly authService: AuthService,
    private readonly workOrderService: WorkOrderService,
    protected readonly layoutNavService: LayoutNavService,
    protected readonly reviewEquipmentService: ReviewEquipmentService
  ) {}

  ngOnInit(): void {
    if(this.authService.authenticated) {
      this.workOrderService
      .getCustomerEquipmentList(
        this.workOrderService.workOrderDetails().masterAccount,
        this.workOrderService.workOrderDetails().nbr
      )
      ?.subscribe({
        next: this.onLoadEquipmentList,
      });
    }
  }

  onLoadEquipmentList = (equipment: EquipmentEntityModel[]) => {
    this.reviewEquipmentService.allEquipment.data = equipment.map((e, i) => ({ ...e, index: i }));
    this.allEquipment = this.reviewEquipmentService.allEquipment.data;
    this.reviewEquipmentService.toAdd.clear();
    this.reviewEquipmentService.selectedEquipment.data.forEach((eq) => {
      const equipmentToSelect = this.reviewEquipmentService.allEquipment.data.find(
        (associatedEquipment) => eq.equipmentId === associatedEquipment.equipmentId
      );
      if (equipmentToSelect) {
        this.reviewEquipmentService.toAdd.select(equipmentToSelect);
      }
    });
  }

  hideAll() {
    this._dialogRef.closeAll();
    this._bottomSheet.dismiss();
  }

  onConfirm() {
    this.reviewEquipmentService.equipmentNotShown = false;
    this.reviewEquipmentService.confirmAdd();
    this.hideAll();
  }

  equipmentNotShown() {
    this.reviewEquipmentService.equipmentNotShown = true;
    this.reviewEquipmentService.toAdd.clear();
    this.reviewEquipmentService.selectedEquipment.data = [];
    this.hideAll();
  }

  getFilteredEquipment() {
    if (this.searchText.length === 0) {
      this.reviewEquipmentService.allEquipment.data = this.allEquipment;
    } else {
      const data: EquipmentEntityModel[] = filter(this.allEquipment, this.searchText);
      this.reviewEquipmentService.allEquipment.data = data;
    }
    return this.reviewEquipmentService.allEquipment;
  }

  onMobileSelectAll(change: MatCheckboxChange) {
    if (change.checked) {
      this.reviewEquipmentService.toAdd.setSelection(...this.reviewEquipmentService.allEquipment.data);
    } else {
      this.reviewEquipmentService.toAdd.clear();
    }
  }
}
