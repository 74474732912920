import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CustomPromptComponent } from "shared/custom-prompt/custom-prompt.component";
import { DatePickerComponent } from "shared/material-wrappers/datepicker/datepicker.component";
import { PromptWrapperComponent } from "shared/prompt-wrapper/prompt-wrapper.component";
import { CustomButtonComponent } from "shared/custom-button/custom-button.component";

@Component({
  selector: 'ads-date-filtering',
  standalone: true,
  imports: [
    CommonModule,
    CustomPromptComponent,
    MatButtonModule,
    DatePickerComponent,
    FormsModule,
    PromptWrapperComponent,
    CustomButtonComponent,
  ],
  templateUrl: './date-filtering.component.html',
  styleUrl: './date-filtering.component.scss',
})
export class DateFilteringComponent {
  @Input() focused = false;
  @Input() isDesktopView = false;
  @Input() icon = '';
  @Input() minDate?: Date;

  fromDate?: Date;
  toDate?: Date;
  today = new Date();

  @Output() cancel = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();
  @Output() fromDateChanged = new EventEmitter<Date>();
  @Output() toDateChanged = new EventEmitter<Date>();

  @ViewChild('mobilePrompt') mobilePrompt: CustomPromptComponent | null = null;

  onCancelClick() {
    if (this.mobilePrompt) {
      this.mobilePrompt.close();
    }
    this.focused = false;
    this.cancel.emit();
  }

  onConfirmClick() {
    this.focused = false;
    this.confirm.emit();
  }

  onClick() {
    this.focused = !this.focused;
  }

  onDismissed() {
    this.onCancelClick();
  }

}
