<ads-simple-form #batteryTasks="ngModelGroup" ngModelGroup="audioMatrixTasks">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test call button for all lanes"
    name="testCallButtons"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test customer gain for all lanes"
    name="testCustomerGain"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test teller gain for all lanes"
    name="testTellerGain"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect microphone and speakers"
    name="inspectMicrophone"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect and test teller consoles"
    name="inspectTellerConsoles"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect all wiring connection for audio system"
    name="inspectWiringConnections"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
