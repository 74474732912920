import { PhotoUpload } from './photo-upload';

export const atmCleaningPhotoUploadKeys = [
  'atm-front-close',
  'atm-screen',
  'atm-network-decals',
  'atm-long-distance',
  'atm-serial-number',
  'atm-fee-notice',
  'atm-missing-parts',
  'atm-damage',
  'atm-additional-photo',
  'atm-missing-stickers',
] as const;

export type AtmCleaningPhotoUploadKey = (typeof atmCleaningPhotoUploadKeys)[number];

export const atmCleaningPhotoUploads: PhotoUpload<AtmCleaningPhotoUploadKey>[] = [
  {
    label: 'Front close (showing stickers)',
    key: 'atm-front-close',
    required: true,
  },
  {
    label: 'ATM screen',
    key: 'atm-screen',
    required: true,
  },
  {
    label: 'ATM network decals',
    key: 'atm-network-decals',
    required: true,
  },
  {
    label: "Long distance, approx. 20'",
    key: 'atm-long-distance',
    required: true,
  },
  {
    label: 'Serial number',
    key: 'atm-serial-number',
    required: true,
  },
  {
    label: 'ATM fee notice (optional)',
    key: 'atm-fee-notice',
    required: false,
  },
  {
    label: 'Missing parts (optional)',
    key: 'atm-missing-parts',
    required: false,
  },
  {
    label: 'Damage (optional)',
    key: 'atm-damage',
    required: false,
  },
  {
    label: 'Additional photo (optional)',
    key: 'atm-additional-photo',
    required: false,
  },
  {
    label: 'Missing stickers (optional)',
    key: 'atm-missing-stickers',
    required: false,
  },
];
