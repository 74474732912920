<ads-simple-form #itmExteriorEvaluation="ngModelGroup" ngModelGroup="itmExteriorEvaluation">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Is surcharge sticker present?"
    name="surchargeStickerPresent"
    [required]="true"
    [options]="options"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Is depository availability sticker present?"
    name="depositoryAvailabilityStickerPresent"
    [required]="true"
    [options]="options"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Are audio instructions functioning when headphones are plugged in?"
    name="audioInstructionsFunctioning"
    [required]="true"
    [options]="options"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Is software onsite?"
    name="isSoftwareOnsite"
    [required]="true"
    [options]="options"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
