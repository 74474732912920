import { FormGroup } from '@angular/forms';
import { getFormGroup } from './form-group-cast';
export const getFormStatus = (formGroup?: FormGroup | null) => (sectionName: string) => {
  if (formGroup?.disabled) {
    return 'valid';
  }
  const section = formGroup?.controls[sectionName] ?? formGroup;
  if (section !== undefined && section !== null) {
    if (section.valid) {
      return 'valid';
    }
    if (section.pristine) {
      return 'pristine';
    }
    if (checkAllFieldsAreEmpty(section as FormGroup)) {
      return 'pristine';
    }
    if (section.touched) {
      return 'dirty';
    }
  }
  return 'pristine';
};

const checkAllFieldsAreEmpty = (section: FormGroup): boolean => {
  const areAllFieldsEmpty = Object.entries(section.controls).every(([key, control]) => {
    if (key === 'allNa') {
      return true; // We will not consider this field for status calculation
    }
    return control.value === null || control.value === '' || control.value === undefined;
  });
  return areAllFieldsEmpty;
};

export const getSectionStatusFormGroup = (formGroup?: FormGroup | null) => (sectionName: string) => {
  const control = formGroup?.controls[sectionName] as FormGroup;
  if (control !== undefined) {
    if (control.disabled) {
      return 'valid';
    }
    if (control.valid) {
      control.markAllAsTouched();
      return 'valid';
    }
    if (control.pristine) {
      return 'pristine';
    }
    if (control.touched) {
      return 'dirty';
    }
  }
  return 'pristine';
};

export const getSectionByFormGroup = (formGroup?: FormGroup | null) => (sectionName: string) => {
  return formGroup?.controls[sectionName] as FormGroup;
};

export const getItemSectionByFormGroup =
  (formGroup?: FormGroup | null) => (sectionName: string, containerName: string) => {
    return getFormGroup(formGroup?.controls[containerName])?.controls[sectionName] as FormGroup;
  };

export const getFormControlsByFormGroup = (sectionNames: string, formGroup?: FormGroup | null) => () => {
  const fg = formGroup?.get(sectionNames) as FormGroup;
  return fg ? fg.controls : {};
};

export const getTasksSectionByFormGroup = (formGroup?: FormGroup | null) => (containerName: string) => {
  return formGroup?.controls[containerName] as FormGroup;
};
