import { SafesFormSection } from 'forms/security/safes/safes.component';
import {
  SecuritySafesComboLockDto,
  SecuritySafesComboLockTasksDto,
  SecuritySafesSectionDto,
  SecuritySafesTasksDto,
  SecuritySafesTimeClockDto,
  SecuritySafesTimeClockTasksDto,
} from 'models/api/security/safes/safes-dto';
import { SafesComboLockTasks, SafesSecurityForm, SafesTasks } from 'models/security/safes/safesSecurityForm';
import { ComboLockSection } from 'models/security/shared/combo-lock';
import { SecuritySafesDto } from 'models/api/security/security';
import { TimeClockTasks } from 'models/security/shared/shared-tasks';
import { TimeClockSection } from 'models/security/shared/time-clock';


export const mapComboLocks = (list: ComboLockSection[]): SecuritySafesComboLockDto[] =>
  list.map((item) => ({
    isThereKeyLockingDial: item.isThereAKeyLockingDial,
    comboLockChangedDetail: item.comboLockChangedDetail,
    comboLockManufacturer: item.comboLockManufacturer,
    comboLockModel: item.comboLockModel,
    comboLockType: item.comboLockType,
    isComboLockChanged: item.isComboLockChanged,
  }));

export const mapTimeClocks = (list: TimeClockSection[]): SecuritySafesTimeClockDto[] =>
  list.map((item) => ({
    quantityOfMovements: item.quantityOfMovements,
    timeClockManufacturer: item.timeClockManufacturer,
    timeClockModel: item.timeClockModel,
    timeClockType: item.timeClockType,
    isLinkageConnectedDirectlyToComboLock: item.isLinkageConnectedDirectlyToCombolock,
  }));


export const mapSecuritySafesSectionsRequest = (panels: SafesFormSection[]): SecuritySafesSectionDto[] => {
  return panels.map((panel) => ({
    safeType: panel.safeType,
    safeManufacturer: panel.safeManufacturer,
    safeManufacturer_Other: panel.safeManufacturer_Other,
    swingType: panel.swingType,
    overallConditionSafe: panel.overallConditionSafe,
    equipment_Rating_Safe: panel.equipment_Rating_Safe,
    safeModel: panel.safeModel,

    security_Safes_ComboLocks: mapComboLocks(Object.values(panel.comboLocks)),
    security_Safes_TimeClocks: mapTimeClocks(Object.values(panel.timeClocks)),
  }));
};

export const mapSecuritySafesTasksRequest = (tasks?: SafesTasks): SecuritySafesTasksDto[] => {
  return [
    {
      checkBoltWorkForProperOperation: tasks?.checkBoltWorkForProperOperation ?? null,
      inspectBoltWorkForExcessiveWear: tasks?.inspectBoltWorkForExcessiveWear ?? null,
      cleanAndLubricateBoltWork: tasks?.cleanAndLubricateBoltWork ?? null,
    },
  ];
};

export const mapSafesTimeClockTasksRequest = (tasks?: TimeClockTasks): SecuritySafesTimeClockTasksDto[] => {
  return [
    {
      checkTimeLocksForProperOperation: tasks?.checkTimeLocksForProperOperation ?? null,
      pullRightTimeClockForCleaning: tasks?.pullRightTimeClockForCleaning ?? null,
      shiftAllTimeClocksRight: tasks?.shiftAllTimeClocksRight ?? null,
      replaceLeftTimeClock: tasks?.replaceLeftTimeClock ?? null,
      markAnyReplacedTimeClocksWithDateInstalled: tasks?.markAnyReplacedTimeClocksWithDateInstalled ?? null,
    },
  ];
};

export const mapSafesComboLockTasksRequest = (tasks?: SafesComboLockTasks): SecuritySafesComboLockTasksDto[] => {
  return [
    {
      checkComboLockForProperOperation: tasks?.checkComboLockForProperOperation ?? null,
      disassembleAndInspectComboLock: tasks?.disassembleAndInspectComboLock ?? null,
      lubricateAndReassembleComboLock: tasks?.lubricateAndReassembleComboLock ?? null,
      checkForDialAndRingProperOperation: tasks?.checkForDialAndRingProperOperation ?? null,
      disassembleAndInspectDialAndRing: tasks?.disassembleAndInspectDialAndRing ?? null,
      lubricateAndReassembleDialAndRing: tasks?.lubricateAndReassembleDialAndRing ?? null,
    },
  ];
};

export const mapSafesRequest = (safes: SafesSecurityForm | null): SecuritySafesDto => ({
  security_Safes: {
    security_Safes_Safes: mapSecuritySafesSectionsRequest(safes?.safesSections ?? []),
    security_Safes_SafeTasks: mapSecuritySafesTasksRequest(safes?.safesTasks),
    security_Safes_ComboLockTasks: mapSafesComboLockTasksRequest(safes?.comboLockTasks),
    security_Safes_TimeClockTasks: mapSafesTimeClockTasksRequest(safes?.timeClockTasks),
    comments: safes?.comments ?? null,
  },
});
