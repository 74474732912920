import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { TableFormBottomSheetInputComponent } from 'shared/material-wrappers/table-form/table-form-bottom-sheet-input/table-form-bottom-sheet-input.component';
import { TableFormInputComponent } from 'shared/material-wrappers/table-form/table-form-input/table-form-input.component';
import { SurveillanceCameraTableSection } from '../surveillance.component';
import { TableBottomSheetsComponent } from 'forms/shared/table-bottom-sheets/table-bottom-sheets.component';
import { SecuritySurveillanceDropdowns } from 'models/security/security-dropdowns';
import { BaseAddManyModalComponent } from 'forms/shared/nested-section-base/add-many-modal.base';

export interface SurveillanceCameraTableSectionAddMany extends Omit<SurveillanceCameraTableSection, 'camera'> {
  quantity: string | undefined;
}

@Component({
  selector: 'ads-surveillance-camera-table-add-many-modal',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogContent,
    MatDialogActions,
    MatTableModule,
    CommonModule,
    FormsModule,
    MatInputModule,
    TableFormInputComponent,
    TableFormBottomSheetInputComponent,
    MatBottomSheetModule,
  ],
  templateUrl: './surveillance-camera-table-add-many-modal.component.html',
  styleUrl: './surveillance-camera-table-add-many-modal.component.scss',
})
export class SurveillanceCameraTableAddManyModalComponent extends BaseAddManyModalComponent {
  displayedColumns = ['quantity', 'cameraType', 'isPTZ'];

  dataSource: SurveillanceCameraTableSectionAddMany[] = [
    { quantity: undefined, cameraType: null, isPTZ: null, index: 0 },
  ];

  openBottomSheet = (e: unknown) => () => {
    const element = e as SurveillanceCameraTableSection;
    const onDismiss = this._bottomSheet.open(TableBottomSheetsComponent, {
      data: {
        selections: [
          {
            title: 'Type',
            control: 'cameraType',
            options: (this.data.dropdowns as SecuritySurveillanceDropdowns)?.surveillanceCameraTableCameraType,
            value: element.cameraType,
          },
          {
            title: 'Is PTZ?',
            control: 'isPTZ',
            options: (this.data.dropdowns as SecuritySurveillanceDropdowns)?.surveillanceCameraTableIsPtz,
            value: element.isPTZ?.toString(),
          },
        ],
      },
    });

    onDismiss.afterDismissed().subscribe((result) => {
      if (result) {
        element.cameraType = result.cameraType;
        element.isPTZ = result.isPTZ;
      }
    });
  };
}
