import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, NgModelGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { PhoneInputComponent } from '../../../../shared/material-wrappers/phone-input/phone-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ZoneExapnderForm } from 'pages/security/security.service.models';

@Component({
  selector: 'ads-zone-expander-section-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CommonFormInputComponent,
    SelectComponent,
    SimpleFormComponent,
    PhoneInputComponent,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
  ],
  templateUrl: './zone-expander-section-form.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class ZoneExpanderSectionFormComponent {
  @Input() section?: FormGroup;
  @Input() item?: ZoneExapnderForm;

}

