<ads-simple-form *ngIf="item" class="access-hardware-access-doors-wrapper" [ngModelGroup]="'accessControlDoor#' + item.index">
  <ads-common-form-input
    name="readerLocation"
    label="Reader Location"
    [maxLength]="30"
    [required]="true"
    [value]="item.readerLocation"
    [(ngModel)]="item.readerLocation"
  ></ads-common-form-input>

  <ads-select
    label="Reader style"
    name="readerStyle"
    [list]="lists?.acwDoorTableReaderStyles"
    [value]="item.readerStyle"
    [(ngModel)]="item.readerStyle"
  ></ads-select>

  <ads-common-form-input
    [style.display]="displayOther('readerStyle') ? 'block' : 'none'"
    name="readerStyle_Other"
    label="Other Reader Style"
    [maxLength]="50"
    [required]="displayOther('readerStyle')"
    [value]="item.readerStyle_Other"
    [(ngModel)]="item.readerStyle_Other"
  ></ads-common-form-input>

  <ads-select
    label="Lock Style"
    name="lockStyle"
    [list]="lists?.acwDoorTableLockStyles"
    [value]="item.lockStyle"
    [(ngModel)]="item.lockStyle"
  ></ads-select>

  <ads-common-form-input
    [style.display]="displayOther('lockStyle') ? 'block' : 'none'"
    name="lockStyle_Other"
    label="Other Lock Style"
    [maxLength]="50"
    [required]="displayOther('lockStyle')"
    [value]="item.lockStyle_Other"
    [(ngModel)]="item.lockStyle_Other"
  ></ads-common-form-input>

  <ads-radio-button-group
    [options]="optionsYesNoNa"
    label="Does a REX device unlock door?"
    name="doesAREXDeviceUnlockDoor"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
<mat-divider></mat-divider>
  <ads-radio-button-group

    [options]="optionsCompleteNa"
    label="Test Lock"
    name="testLock"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
<mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="optionsCompleteNa"
    label="Test Reader"
    name="testReader"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
<mat-divider></mat-divider>
  <ads-radio-button-group

    [options]="optionsCompleteNa"
    label="Test door held open alarm"
    name="testDoorHelpOpenAlarm"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
<mat-divider></mat-divider>
  <ads-radio-button-group

    [options]="optionsCompleteNa"
    label="Test door forced alarm"
    name="testDoorForcedAlarm"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
