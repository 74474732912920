import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { ControlContainer, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { AtmCleaningTasks, CompleteNa, SelectOption } from 'models';
import { checkAllOptions, checkIfAllAreNa } from 'app/utils/select-all';
import { SingleToggleButtonComponent, RadioButtonGroupComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-atm-cleaning-tasks',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    SingleToggleButtonComponent,
    SimpleFormComponent,
    RadioButtonGroupComponent,
    MatDividerModule,
    FormsModule,
  ],
  templateUrl: './atm-cleaning-tasks.component.html',
  styleUrl: './atm-cleaning-tasks.component.scss',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AtmCleaningTasksComponent {
  @Input() section?: FormGroup;

  selectAllChecked = false;
  fields = Object.getOwnPropertyNames(new AtmCleaningTasks());

  options: SelectOption<CompleteNa>[] = [
    { name: 'Complete', value: CompleteNa.Complete },
    { name: 'N/A', value: CompleteNa.Na },
  ];

  onOptionClicked = () => checkIfAllAreNa(this.fields, this.section);
  onSelectAllChange = (shouldSelectAll: boolean) => checkAllOptions(shouldSelectAll, this.fields, this.section);
}
