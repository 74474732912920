import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatTabsModule } from '@angular/material/tabs';
import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SelectOption, YesNo } from 'models';
import { CommonFormInputComponent } from 'shared/material-wrappers/common-form-input/common-form-input.component';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { CustomButtonComponent } from 'shared/custom-button/custom-button.component';
import { SelectComponent } from 'shared/material-wrappers/select/select.component';
import { RadioButtonGroupComponent } from 'shared/material-wrappers/radio-button-group/radio-button-group.component';
import { MatDialog } from '@angular/material/dialog';
import { CallControlService } from 'pages/call-control/call-control.service';
import { mapTicketData } from '../support-request-ticket';
import { DepartmentModel } from 'models/call-control/department';
import { AuthService } from 'services';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TicketInformation } from 'models/call-control/ticket';

@Component({
  selector: 'ads-support-request',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CustomButtonComponent,
    MatTabsModule,
    SelectComponent,
    CommonFormInputComponent,
    CommonTextAreaComponent,
    RadioButtonGroupComponent,
  ],
  templateUrl: './support-request.component.html',
  styleUrl: './support-request.component.scss',
})
export class SupportRequestComponent implements OnInit {
  selectedSupportOption: DepartmentModel | null = null;
  title = '';
  description = '';
  tabIndex = 0;
  includeInfo: YesNo = YesNo.Yes;
  supportOptions: SelectOption<DepartmentModel>[] = [];

  public readonly _snackBar = inject(MatSnackBar);
  public readonly _bottomSheet = inject(MatBottomSheet);
  public readonly dialogRef = inject(MatDialog);

  yesNoOptions: SelectOption<YesNo>[] = [
    { name: 'Yes', value: YesNo.Yes },
    { name: 'No', value: YesNo.No },
  ];

  constructor(
    public readonly callControlService: CallControlService,
    public readonly authService: AuthService,
    public readonly workOrderService: WorkOrderService
  ) {}

  ngOnInit(): void {
    if (this.authService.authenticated) {
      this.callControlService.getDepartmentList().subscribe((data) => {
        this.supportOptions = data.map((department) => ({
          name: department.option ? `${department.department} - ${department.option}` : department.department ?? '',
          value: department,
        }));
      });
    }
  }

  onConfirm() {
    if (this.tabIndex === 0) {
      if (this.selectedSupportOption) {
        // Phone call option
        window.open(`tel:${this.selectedSupportOption.phoneNumber}`);
        this._bottomSheet.dismiss();
        this.dialogRef.closeAll();
      }
    } else if (this.title && this.description && this.selectedSupportOption) {
        // Submit ticket option
        this.sendTicket();
        this._bottomSheet.dismiss();
        this.dialogRef.closeAll();
      }
  }

  onCancel() {
    this._bottomSheet.dismiss();
    this.dialogRef.closeAll();
  }

  sendTicket() {
    if (this.selectedSupportOption && this.workOrderService.workOrderDetails() && this.authService.user) {
      const ticket = mapTicketData(
        this.selectedSupportOption?.department ?? '',
        this.title,
        this.description,
        this.includeInfo === YesNo.Yes,
        this.workOrderService.workOrderDetails(),
        this.authService.user
      );
      this.callControlService.submitTicket(ticket).subscribe({
        next: this.onTicketSubmitSuccess,
        error: this.onTicketSubmitError,
      });
    }
  }

  onTicketSubmitSuccess = (result: TicketInformation) => {
    if (result.ticketRecId > 0) {
      this._snackBar.open('Ticket submitted successfully.', 'Close');
    } else {
      this.onTicketSubmitError('Failed to submit ticket, please try again later.');
    }
  };

  onTicketSubmitError = (error: unknown) => {
    console.error(error);
    this._snackBar.open('Failed to submit ticket, please try again later.', 'Close');
  };
}
