<ads-simple-form *ngIf="item" class="vault-section-wrapper" [ngModelGroup]="'vaultSection#' + item.index">
  <ads-select
    label="Vault Manufacturer"
    name="manufacturer"
    [list]="vaultLists?.manufacturerList"
    [value]="item.manufacturer"
    [(ngModel)]="item.manufacturer"
  ></ads-select>
  <ads-common-form-input
    [style.display]="displayOther() ? 'block' : 'none'"
    name="otherManufacturer"
    label="Other Manufacturer"
    [maxLength]="50"
    [required]="displayOther()"
    [value]="item.otherManufacturer"
    [(ngModel)]="item.otherManufacturer"
  ></ads-common-form-input>
  <ads-common-form-input
    name="vaultModel"
    label="Vault Model"
    [maxLength]="50"
    [required]="true"
    [value]="item.vaultModel"
    [(ngModel)]="item.vaultModel"
  ></ads-common-form-input>
  <ads-select
    label="Swing Type"
    name="swingType"
    [list]="vaultLists?.typesList"
    [value]="item.swingType"
    [(ngModel)]="item.swingType"
  ></ads-select>
  <ads-select
    label="Overall Condition - Vault"
    name="overallCondition"
    [list]="vaultLists?.overallConditionsList"
    [value]="item.overallCondition"
    [(ngModel)]="item.overallCondition"
    [required]="true"
  ></ads-select>
  <ads-common-text-area
    [style.display]="displayEquipmentRating() ? 'block' : 'none'"
    name="overallConditionComments"
    label="Substandard equipment comments"
    [maxLength]="500"
    [required]="displayEquipmentRating()"
    [value]="item.overallConditionComments"
    [(ngModel)]="item.overallConditionComments"
  ></ads-common-text-area>
  <ads-common-form-input
    name="ventilatorManufacturer"
    label="Ventilator Manufacturer"
    [maxLength]="50"
    [required]="true"
    [(ngModel)]="item.ventilatorManufacturer"
    [value]="item.ventilatorManufacturer"
  ></ads-common-form-input>
  <ads-common-form-input
    name="ventilatorModel"
    label="Ventilator Model"
    [maxLength]="50"
    [required]="true"
    [value]="item.ventilatorModel"
    [(ngModel)]="item.ventilatorModel"
  ></ads-common-form-input>
  <ads-select
    label="Overall Condition - Ventilator"
    name="ventilatorOverallCondition"
    [list]="vaultLists?.overallConditionsList"
    [value]="item.ventilatorOverallCondition"
    [(ngModel)]="item.ventilatorOverallCondition"
    [required]="true"
  ></ads-select>
  <ads-common-text-area
    [style.display]="displayVentilatorEquipmentRating() ? 'block' : 'none'"
    name="ventilatorOverallConditionComments"
    label="Substandard equipment comments"
    [maxLength]="500"
    [required]="displayVentilatorEquipmentRating()"
    [value]="item.ventilatorOverallConditionComments"
    [(ngModel)]="item.ventilatorOverallConditionComments"
  ></ads-common-text-area>
  <ads-radio-button-group
    [options]="yesNoOptions"
    label="Does vault door remain stationary at 90 degrees?"
    name="doorRotationTest"
    [required]="true"
    [(ngModel)]="item.doorRotationTest"
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-select
    label="If no - Does door creep open or close?"
    name="creepDoorCloses"
    [list]="vaultLists?.creepDoorClosesList"
    [value]="item.creepDoorCloses"
    [(ngModel)]="item.creepDoorCloses"
    [required]="item.doorRotationTest === yesNo.No"
  ></ads-select>
  <ng-container ngModelGroup="comboLocks">
    <ads-nested-multiple-forms-section
      #vaultSectionLocks
      title="Combo Locks"
      itemTitle="Lock"
      [formTemplate]="formTemplateLocks"
      [list]="item.comboLocks"
      [formControls]="getFormControls('comboLocks')"
      [groupName]="'comboLock'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddComboLock()"
      (deleteClicked)="onRemoveComboLock($event)"
      [minItems]="0"
    ></ads-nested-multiple-forms-section>
    <ng-template #formTemplateLocks let-item="item">
      <ads-vault-combo-locks-section
        [section]="getSection('comboLock#' + item.index, 'comboLocks')"
        [item]="item"
      ></ads-vault-combo-locks-section>
    </ng-template>
  </ng-container>
  <ng-container ngModelGroup="timeClocks">
    <ads-nested-multiple-forms-section
      #vaultSectionClocks
      title="Time Clocks"
      itemTitle="Clock"
      [formTemplate]="formTemplateClocks"
      [list]="item.timeClocks"
      [formControls]="getFormControls('timeClocks')"
      [groupName]="'timeClock'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddTimeClock()"
      (deleteClicked)="onRemoveTimeClock($event)"
      [minItems]="0"
    ></ads-nested-multiple-forms-section>
    <ng-template #formTemplateClocks let-item="item">
      <ads-vault-time-clocks-section
        [section]="getSection('timeClock#' + item.index, 'timeClocks')"
        [item]="item"
      ></ads-vault-time-clocks-section>
    </ng-template>
  </ng-container>
</ads-simple-form>
