<ads-simple-form #dealDrawerTasks="ngModelGroup" ngModelGroup="dealDrawerTasks">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button
  ><mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test motorized operation"
    name="testMotorizedOperation"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test manual operation"
    name="testManualOperation"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect and test drawer motor"
    name="testDrawerMotor"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect and test drawer tracks"
    name="testDrawerTracks"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>


  <ads-radio-button-group
    label="Clean and lube drawer tracks"
    name="cleanDrawerTracks"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect and test in/out on/off switches"
    name="testSwitches"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect, clean and test heater assy"
    name="testHeaterAssy"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Clean unit with appropriate cleaner"
    name="cleanUnit"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
</ads-simple-form>
