import { TcdTcrForm } from '../../tcd-tcr/tcd-tcr-form';
import { TcdTcrSectionDto, TcdTcrResponseDropdowns, TcdTcrResponse } from '../../tcd-tcr/tcd-tcr-response';
import { TcdTcrTasks } from '../../tcd-tcr/tcd-tcr-tasks';
import { TcdTcrCassetteTasks } from 'models/tcd-tcr/tcd-tcr-cassettes-tasks';
import { TcdTcrFeedModuleTasks } from 'models/tcd-tcr/tcd-tcr-feed-module-tasks';
import { TcdTcrInputModuleTasks } from 'models/tcd-tcr/tcd-tcr-input-module-tasks';
import { TcdTcrExternalAreaTasks } from 'models/tcd-tcr/tcd-tcr-external-area-tasks';
import { TcdTcrVacuumingBlowOut } from 'models/tcd-tcr/tcd-tcr-vaccuming-blow-out';
import { TcdTcrSection } from '../../tcd-tcr/tcd-tcr';
import { TcdTcrDropdowns } from 'models/atm/tcd-tcr-dropdowns';
import { TcdTcrFormSection } from 'pages/tcd-tcr-pm/tcd-tcr.models';

export const mapTcrForm = (response: TcdTcrResponse): TcdTcrForm => {
  const data = response.tcdTcr;
  const tcdTcrSections: TcdTcrSection[] = [];

  data.tcdTcr_TcdTcr.forEach((section, index) => {
    tcdTcrSections.push(mapTcdTcrSection(section, index));
  });

  const tcdTcrTasks: TcdTcrTasks = {
    checkError: data.tcdTcrTasks_CheckErrorForAbnormalities,
    confSheetsPresent: data.tcdTcrTasks_ConfSheetsPresent,
  };

  const tcdTcrCassetteTasks: TcdTcrCassetteTasks = {
    allNa: false,
    checkBelts: data.cassetteTasks_CheckCleanBelts,
    diagnosticTests: data.cassetteTasks_Diagnostics,
    checkRollers: data.cassetteTasks_CheckCleanRollers,
    checkSensors: data.cassetteTasks_CheckCleanSensors,
    foreignMaterial: data.cassetteTasks_RemoveForeignMaterial,
    cassetteVerifyCassettesNotBentOrDamaged: data.cassetteTasks_VerifyCassettesNotBentOrDamaged,
  };

  const tcdTcrFeedModuleTasks: TcdTcrFeedModuleTasks = {
    allNa: false,
    checkBelts: data.feedModuleTasks_CheckCleanBelts,
    diagnosticTests: data.feedModuleTasks_PerformDiagnosticTests,
    checkRollers: data.feedModuleTasks_CheckCleanRollers,
    checkSensors: data.feedModuleTasks_CheckCleanSensors,
    foreignMaterial: data.feedModuleTasks_RemoveForeignMaterial,
  };

  const tcdTcrInputModuleTasks: TcdTcrInputModuleTasks = {
    allNa: false,
    checkBelts: data.presentModuleTasks_CheckCleanBelts,
    diagnosticTests: data.presentModuleTasks_Diagnostic,
    checkRollers: data.presentModuleTasks_CheckCleanRollers,
    checkSensors: data.presentModuleTasks_CheckCleanSensors,
    foreignMaterial: data.presentModuleTasks_RemoveForeignMaterial,
    verifyIndicators: data.presentModuleTasks_VerifyOperationOfIndicators,
    checkShutter: data.presentModuleTasks_CheckCleanShutter,
  };

  const tcdTcrExternalAreaTasks: TcdTcrExternalAreaTasks = {
    allNa: false,
    alignment: data.externalAreaTasks_CheckAlignmentWithExitInput,
    cables: data.externalAreaTasks_VerifyCablesAreNeatOrganizedBehindEquip,
    customerArea: data.externalAreaTasks_CleanCustomerInterfaceArea,
    exterior: data.externalAreaTasks_CleanEntireExterior,
    screen: data.externalAreaTasks_CleanTestScreen,
    toneVolume: data.externalAreaTasks_CheckToneVolume,
  };

  const tcdTcrVacuumingBlowOut: TcdTcrVacuumingBlowOut = {
    allNa: false,
    fans: data.vaccummingBlowOut_AllFans,
    powerSupply: data.vaccummingBlowOut_PowerSupply,
    safe: data.vaccummingBlowOut_Safe,
    upperArea: data.vaccummingBlowOut_UpperTrack,
  };

  return {
    tcdTcrSections,
    comments: data.comments ?? '',
    tcdTcrTasks: tcdTcrTasks,
    tcdTcrCassetteTasks,
    tcdTcrFeedModuleTasks,
    tcdTcrInputModuleTasks,
    tcdTcrExternalAreaTasks,
    tcdTcrVacuumingBlowOut,
  } as TcdTcrForm;
};

export const mapTcdTcrSection = (item: TcdTcrSectionDto, index: number): TcdTcrFormSection => {
  return {
    cassettesRollersAmount: item.tcdTcr_NumberOfCassettes?.toString() ?? null,
    clientWorkstationsAmount: item.tcdTcr_WorkstationQty?.toString() ?? null,
    coinDispenserManufacturer: item.tcdTcr_ManufactureOfCoinDispenser ?? null,
    coinDispensersAmount: item.tcdTcr_NumberOfCoinDispensers ? item.tcdTcr_NumberOfCoinDispensers.toString() : null,
    communicationType: item.tcdTcr_CommType,
    otherCommunicationType: item.tcdTcr_CommType_Other,
    manufacturer: item.tcdTcr_Manufacturer,
    otherManufacturer: item.tcdTcr_Manufacturer_Other,
    model: item.tcdTcr_Model,
    otherExternalEquipment: item.tcdTcr_ExternalEquipmentAttached,
    overallCondition: item.tcdTcr_OverallCondition,
    equipmentRating: item.equipment_Rating,
    serialNumber: item.tcdTcr_Serial,
    terminalId: item.tcdTcr_TerminalId,
    type: item.tcdTcr_Type,
    photoFront: item.photo_Front,
    photoSerialNumber: item.photo_SerialNumber,
    photoAdditionalPhoto: item.photo_AdditionalPhoto,
    photoExternalDevices: item.photo_ExternalDevices,
    photoCoinDispenser: item.photo_CoinDispenser,
    index,
  };
};

export const mapTcrDropdowns = (data: TcdTcrResponseDropdowns): TcdTcrDropdowns => {
  return {
    manufacturerList: data.tcdManufacturer.map((item) => ({
      name: item.manufacturerName,
      value: item.manufacturerName,
    })),
    tcrTypeList: data.tcdTcrType.map((item) => ({
      name: item.name,
      value: item.name,
    })),
    communicationTypeList: data.communicationTypes.map((item) => ({
      name: item.name,
      value: item.name,
    })),
    cassettesRollersList: data.tcdTcrNumberOfCassettes.map((item) => ({
      name: item.toString(),
      value: item.toString(),
    })),
    coinDispensersList: data.quantityOfCoinDispensersList.map((item) => ({
      name: item.name,
      value: item.name,
    })),
    conditionList: data.tcdTcrOverallConditions.map((item) => ({
      name: item.name,
      value: item.name,
    })),
  };
};
