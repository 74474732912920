import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { PhotoUploadBase } from 'models';
import { PhotoUploadControlService } from 'services';
import { PhotoUploadComponent } from 'shared/photo-upload/photo-upload.component';

@Component({
  selector: 'ads-photos-section',
  standalone: true,
  providers: [PhotoUploadControlService],
  imports: [CommonModule, ReactiveFormsModule, PhotoUploadComponent, MatDividerModule],
  templateUrl: './photos-section.component.html',
  styleUrl: './photos-section.component.scss',
})
export class PhotosSectionComponent {
  @Input() photoUploads: PhotoUploadBase[] | null = [];
  @Input() photoUploadForm!: FormGroup;
}
