<ads-simple-form #surveillanceCameraTasks="ngModelGroup" [ngModelGroup]="'surveillanceCameraTasks'">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button>
  <ads-radio-button-group
    [options]="options"
    label="Inspect camera position/focus"
    name="inspectCameraPositionFocus"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Inspect camera wire connection"
    name="inspectCameraWireConnection"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Clean cameras and housings"
    name="cleanCamerasAndHousings"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
