import { CompleteNa } from 'models/shared/complete-na';

export class SensorsTasksForm {
  allNa: boolean;
  testAtmDoorContact: CompleteNa | null;
  testAtmHeatSensors: CompleteNa | null;
  testAtmVib: CompleteNa | null;
  testCashContainerContacts: CompleteNa | null;
  testCashContainerHeatSensors: CompleteNa | null;
  testCashContainerVibes: CompleteNa | null;
  testMotionSensors: CompleteNa | null;
  testNightDropContacts: CompleteNa | null;
  testNightDropHead: CompleteNa | null;
  testNightDropHeatSensors: CompleteNa | null;
  testNightDropVib: CompleteNa | null;
  testPremiseDoorContacts: CompleteNa | null;
  testTcrTcdDoorContacts: CompleteNa | null;
  testTcrTcdHeatSensors: CompleteNa | null;
  testTcrTcdVib: CompleteNa | null;
  testVaultAudioSensors: CompleteNa | null;
  testVaultDoor: CompleteNa | null;
  testVaultDoorHeatSensors: CompleteNa | null;
  verifiedAtmVibSetting: CompleteNa | null;
  verifiedNightDropVibSettings: CompleteNa | null;
  verifiedTcdTcrVibSetting: CompleteNa | null;
  verifiedTestCashVibSetting: CompleteNa | null;

  constructor() {
    this.allNa = false;
    this.testAtmDoorContact = null;
    this.testAtmHeatSensors = null;
    this.testAtmVib = null;
    this.testCashContainerContacts = null;
    this.testCashContainerHeatSensors = null;
    this.testCashContainerVibes = null;
    this.testMotionSensors = null;
    this.testNightDropContacts = null;
    this.testNightDropHead = null;
    this.testNightDropHeatSensors = null;
    this.testNightDropVib = null;
    this.testPremiseDoorContacts = null;
    this.testTcrTcdDoorContacts = null;
    this.testTcrTcdHeatSensors = null;
    this.testTcrTcdVib = null;
    this.testVaultAudioSensors = null;
    this.testVaultDoor = null;
    this.testVaultDoorHeatSensors = null;
    this.verifiedAtmVibSetting = null;
    this.verifiedNightDropVibSettings = null;
    this.verifiedTcdTcrVibSetting = null;
    this.verifiedTestCashVibSetting = null;

  }
}
