import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class PageTrackingService {
  constructor(
    private readonly router: Router,
    private readonly loggingService: LoggingService) {
      router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          loggingService.startTrackPage();
        }
        else if (event instanceof NavigationEnd) {
          loggingService.stopTrackPage(event.url);
        }
      })
    }
}
