<span class="title">Add Monitor(s)</span>
<mat-dialog-content>
  <form #form="ngForm">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef>Quantity</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <ads-table-form-input
            [ngModelGroup]="'monitorTableItem'"
            name="quantity"
            [maxLength]="5"
            [required]="true"
            [value]="element.quantity"
            [(ngModel)]="element.quantity"
            [type]="'number'"
          ></ads-table-form-input>
        </td>
      </ng-container>

      <ng-container matColumnDef="monitorType">
        <th mat-header-cell *matHeaderCellDef>Monitor Type</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <ads-table-form-bottom-sheet-input
            [ngModelGroup]="'monitorTableItem'"
            name="monitorType"
            [required]="true"
            [value]="element.monitorType"
            [(ngModel)]="element.monitorType"
            [openBottomSheet]="openBottomSheet(element)"
          ></ads-table-form-bottom-sheet-input>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="button-container">
    <button type="button" mat-stroked-button (click)="onCancel()">Cancel</button>
    <button mat-button mat-flat-button class="main-button" type="button" (click)="onAccept(dataSource)">Accept</button>
  </div>
</mat-dialog-actions>
