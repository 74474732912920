<div class="mobile-more-container">
  <ads-profile-name></ads-profile-name>
  <ads-nav-button title="Calendar" icon="calendar_month" [navIndex]="4"></ads-nav-button>
  <mat-divider></mat-divider>
  <ads-nav-button title="History" icon="history" [navIndex]="5"></ads-nav-button>
  <mat-divider></mat-divider>
  <ads-nav-button title="Centran" icon="lock_open" [navIndex]="6"></ads-nav-button>
  <mat-divider></mat-divider>
  <ads-nav-button title="My Open Calls" icon="phonelink_ring" [navIndex]="7"></ads-nav-button>
  <mat-divider></mat-divider>
  <ads-nav-button title="ATM Down Overnight" icon="bedtime" [navIndex]="8"></ads-nav-button>
  <mat-divider></mat-divider>
  <ads-nav-button title="Other Techs" icon="groups" [navIndex]="9"></ads-nav-button>
  <mat-divider></mat-divider>
  <ads-nav-button title="Inventory" icon="inventory" [navIndex]="10"></ads-nav-button>
  <mat-divider></mat-divider>
  <ads-nav-button title="Part Status" icon="construction" [navIndex]="11"></ads-nav-button>

  <ads-logout-footer></ads-logout-footer>
</div>
