<ads-custom-prompt
  #mobilePrompt
  class="sort-button"
  label="Status"
  [mobileContent]="mobileSort"
  (dismissed)="mobilePrompt?.close()"
></ads-custom-prompt>
<ng-template #mobileSort>
  <ads-prompt-wrapper (primaryButtonClicked)="onConfirmed()" (cancelButtonClicked)="mobilePrompt?.close()">
    <div class="sort-by-wrapper">
      <span class="sort-by-title">Filter by status</span>
      <div class="sort-by-content">
        <ads-radio-button-group
          class="sort-by-options"
          [options]="filteringOptions"
          [verticalLayout]="true"
          (optionClicked)="onOptionClicked($event)"
          [(ngModel)]="value"
        ></ads-radio-button-group>
      </div>
    </div>
  </ads-prompt-wrapper>
</ng-template>
