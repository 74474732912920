import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, delay, retry, timeout } from 'rxjs/operators';
import { iif, throwError, of, Observable } from 'rxjs';

@Injectable()
export class RetryHttpErrorsInterceptor implements HttpInterceptor {
  private readonly maximumRetries = 3;
  private readonly retryDelay = 200;

  private readonly maximumTimeoutRetries = 2;
  private readonly timeoutDelay = 20000;
  private timeoutRetryCount = 0;

  constructor() {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next
      .handle(request)
      .pipe(
        timeout({
          each: this.timeoutDelay,
          with: () => throwError(() => of(new HttpErrorResponse(request))),
        })
      )
      .pipe(this.retryPipe());
  }

  shouldTriggerRetry(error: HttpErrorResponse) {
    if (error.status === 0) {
      // This would be a timeout error
      this.timeoutRetryCount++;
      if (this.timeoutRetryCount <= this.maximumTimeoutRetries) {
        return true;
      }
    }
    // Retry will only trigger if the error is a 5xx error
    if (error.status < 500) {
      return false;
    }
    // If all conditions are met, we trigger the retry
    return true;
  }

  retryPipe<T>() {
    return retry<T>({
      count: this.maximumRetries,
      delay: (errors: Observable<HttpErrorResponse>) =>
        errors?.pipe(
          // We use RxJS concatMap to avoid retries on throttling errors & ensure request idempotency
          concatMap((error: HttpErrorResponse, retryId: number) =>
            // We check if we should trigger the reply
            iif(() => this.shouldTriggerRetry(error),
              this.retryError(error, retryId, this.retryDelay),
              // Otherwise, we return the corresponding error
              throwError(() => error)
            )
          )
        )
    });
  }

  retryError(error: HttpErrorResponse, retryId: number, retryDelay: number) {
    // Retry request with exponential backoff time
    return of(error).pipe(delay(retryDelay * Math.pow(2, retryId)));
  }
}
