<form [formGroup]="formGroup">
  <div class="selects-container">
    @for (item of data.selections; track item) {
    <div [class.list-group]="data.selections.length > 1" [class.list-single]="data.selections.length === 1">
      <span class="option-group-title">{{ item.title }}</span>
      <mat-button-toggle-group
        class="group"
        hideSingleSelectionIndicator
        [name]="item.control"
        [formControlName]="item.control"
      >
        @for (option of item.options; track option) {
        <mat-button-toggle class="option" [value]="option.value">
          {{ option.name }}
        </mat-button-toggle>
        }
      </mat-button-toggle-group>
    </div>
    }
  </div>

  <div class="button-container">
    <button *ngIf="!addEnabled || data.showCancel" type="button" mat-stroked-button (click)="onCancel()">Cancel</button>
    <button mat-stroked-button type="button" (click)="onAccept()">Accept</button>
    <button
      *ngIf="addEnabled"
      mat-button
      mat-flat-button
      class="main-button"
      type="button"
      (click)="onAcceptAndAddNew()"
    >
      Accept & Add other
    </button>
  </div>
</form>
