<ads-panel>
  <ads-charts
    *ngIf="barChartData.datasets[0].data.length > 0"
    [customHeight]="100"
    [chartData]="barChartData"
    [chartOptions]="barChartOptions"
    [chartPlugins]="barChartPlugins"
    [legend]="false"
    [onChartInit]="onChartInit">
  </ads-charts>
</ads-panel>
