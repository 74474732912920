import { Pipe, PipeTransform } from '@angular/core';
import { AdsEquipmentOnSite } from 'models';
import { EquipmentEntityModel } from 'models/equipment/equipment';

export const filter = (equipment: AdsEquipmentOnSite[] | EquipmentEntityModel[], searchText: string): EquipmentEntityModel[] | AdsEquipmentOnSite[] => {
  if (!equipment || !searchText) {
    return equipment;
  }
  const search = searchText.toLowerCase();
  return equipment.filter(
    (item) =>
      (item.equipmentType?.toLowerCase().includes(search) ||
        item.terminalID?.toLowerCase().includes(search) ||
        item.manufacturer?.toLowerCase().includes(search) ||
        item.model?.toLowerCase().includes(search) ||
        item.type?.toLowerCase().includes(search)) ??
      false,
  );
}

@Pipe({
  name: 'equipmentFilter',
  standalone: true,
})
export class EquipmentFilterPipe implements PipeTransform {
  transform = filter;
}
