<ads-simple-form #atmTasks="ngModelGroup" ngModelGroup="atmTasks">
  <ads-radio-button-group
    [options]="yesNoOptions"
    label="Are Configuration sheets present?"
    name="confSheetsPresent"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="yesNoOptions"
    label="Is maintenance log sheet present?"
    name="logSheetPresent"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Verify site profile information"
    name="verifySiteProfileSP"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Check error log and tallies for abnormalities (SLM ONLY)"
    name="talliesAbnormalities"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Clear tallies (SLM Only)"
    name="clearTallies"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Request Kaba combo"
    name="requestKabaCombo"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
