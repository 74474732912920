import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { CommonModule, DatePipe, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { PartDetail } from 'models/call-control/part';
import { Labor } from 'models/call-control/labor';
import { RoutesEnum } from 'app/utils/navigation';
import { CallControlPage } from 'pages/page-utils/call-control-page';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { LaborTableComponent } from './labor-table/labor-table.component';
import { PartsTableComponent } from './parts-table/parts-table.component';
import { HeaderService } from 'shared/header/header.service';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { NavigationHeaderComponent } from 'shared/navigation-header/navigation-header.component';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { CommonFormInputComponent } from 'shared/material-wrappers/common-form-input/common-form-input.component';
import { CallStatusId } from 'models/call-control/ack-schedule-models';
import { ModalActionsComponent } from 'shared/modal-actions/modal-actions.component';
import { CallControlService } from 'pages/call-control/call-control.service';
import { CompleteWoNoteModel } from 'models/notes/complete-wo-note-model';
import { NotesService } from 'services/notes.service';
import { PerformRounding } from 'app/utils/dates';
import { buildNote } from 'app/utils/notes';
import { AuthService } from 'services';
import { OperationResponse } from 'models/call-control/command-response';
import { LoaderService } from 'shared/loader/loader.service';

@Component({
  selector: 'ads-complete-work-order',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatRadioModule,
    NavigationHeaderComponent,
    CommonFormInputComponent,
    CommonTextAreaComponent,
    PartsTableComponent,
    LaborTableComponent,
    ModalActionsComponent,
  ],
  templateUrl: './complete-work-order.component.html',
  styleUrl: './complete-work-order.component.scss',
})
export class CompleteWorkOrderComponent extends CallControlPage implements OnInit, OnDestroy {
  otherTechnicians = '';
  completionNotes = '(Tech notes)';
  lastNote: CompleteWoNoteModel | null = null;
  parts = new MatTableDataSource<PartDetail>([]);
  labor = new MatTableDataSource<Labor>([]);
  partsColumnsToShow = ['date', 'part', 'quantity', 'description', 'type', 'transferType', 'addedBy'];
  laborColumnsToShow = ['date', 'startTime', 'endTime', 'hours', 'entryType', 'type'];

  thirdParty: boolean | null = null;
  vandalism: boolean | null = null;
  isFirstLineMaintenance: boolean | null = null;
  isLockObsolete: boolean | null = null;
  disableFLMorSLM = true;
  disableObsoleteLock = true;
  disableCausedByThirdParty = true;

  private readonly datepipe: DatePipe = new DatePipe('en-US');
  public readonly _snackBar = inject(MatSnackBar);

  constructor(
    override readonly route: ActivatedRoute,
    override readonly headerService: HeaderService,
    override readonly location: Location,
    override readonly router: Router,
    public readonly loader: LoaderService,
    public readonly authService: AuthService,
    public readonly layoutNavService: LayoutNavService,
    public readonly callControlService: CallControlService,
    public readonly notesService: NotesService,
    public override readonly workOrderService: WorkOrderService
  ) {
    super(headerService, location, workOrderService, route, router);
  }

  ngOnInit() {
    this.init(this.updateSurveyValues).then(() => {
      this.workOrderService
        .getLastTechNote(this.workOrderService.workOrder)
        .subscribe((note) => (this.lastNote = note[0]));
      this.workOrderService
        .getStatusTimestamp({ statusToCheck: CallStatusId.Started_50, workOrder: this.workOrderService.workOrder })
        .subscribe((startedDate) => {
          if (startedDate.Date) {
            this.getPageData(startedDate.Date);
          }
        });
    });
  }

  getPageData(startedDate: Date) {
    this.workOrderService.getPartsDetailList(this.workOrderService.workOrder).subscribe((parts) => {
      this.parts.data = parts.map((e, i) => ({ ...e, index: i }));
      this.workOrderService.getLaborFromCC(this.workOrderService.workOrder).subscribe((labor) => {
        const time = this.route.snapshot.paramMap.get('time');
        if (time) {
          this.loadLaborData(startedDate, labor, time);
        }
      });
    });
  }

  loadLaborData(startedDate: Date, labor: Labor[], time: string) {
    const laborData = labor.map((e, i) => ({ ...e, index: i }));
    if (startedDate != null) {
      const hours = time?.split(':')[0];
      const minutes = time?.split(':')[1];
      const completeWorkOrderTime = new Date(startedDate ?? '');
      completeWorkOrderTime?.setHours(Number(hours), Number(minutes), 0, 0);
      const timeEnd = this.datepipe.transform(completeWorkOrderTime, 'yyyy/MM/dd');
      const selectedDateTime = new Date(timeEnd + ' ' + PerformRounding(completeWorkOrderTime ?? new Date()));
      if (startedDate) {
        const _hours = Math.abs(selectedDateTime.getTime() - new Date(startedDate).getTime()) / 36e5;
        laborData.push({
          index: laborData.length,
          date: startedDate,
          startTime: startedDate,
          endTime: selectedDateTime,
          hours: _hours.toString(),
          entryType: 'ADS*',
          type: 'Labor',
        });
      }
    }
    this.labor.data = laborData;
  }

  ngOnDestroy() {
    if (this.headerService.titleAnnex()) {
      this.headerService.titleAnnex.set(undefined);
    }
  }

  onBackArrowClicked() {
    const time = this.route.snapshot.paramMap.get('time');
    this.router.navigate([
      `${RoutesEnum.WorkOrder}/${RoutesEnum.ReviewEquipment}/${this.workOrderService.workOrder}`,
      { time },
    ]);
  }

  onCompleteClick() {
    if (!this.disableCausedByThirdParty) {
      if (this.thirdParty == null) {
        this._snackBar.open(
          'Could not complete work order. Please select if the issue was caused by a third party.',
          'Close',
          { duration: 3000 }
        );
        return;
      } else {
        this.postAtmNote();
      }
    }
    const refRecId = Number(this.workOrderService.workOrderDetails().ticketRecId);
    const userId = this.authService.user?.userId ?? '';
    const recId = this.workOrderService.workOrderDetails().recid;

    // Build and send completion note
    const completeNoteModel = buildNote('Call Completion Note', this.completionNotes, refRecId, recId, userId);
    if (this.authService.authenticated) {
      this.notesService.addNote(completeNoteModel).subscribe();
      this.notesService.addNotesForSuspendOrComplete(
        this.lastNote,
        this.completionNotes,
        userId,
        refRecId,
        this.otherTechnicians
      );
    }
  }

  updateSurveyValues = () => {
    const call = this.workOrderService.workOrderDetails();
    this.disableObsoleteLock = !call.problem?.includes('S/D Box');
    this.disableFLMorSLM = call.calltype?.includes('Security') ?? false;
    this.disableCausedByThirdParty = !['ATM', 'TCD', 'Security'].some((type) => call.calltype?.includes(type));
  };

  onThirdPartyChange(change: MatRadioChange) {
    if (!change.value) {
      this.vandalism = null;
    }
  }

  onCompleteSuccess = (result: OperationResponse) => {
    if (result.isSuccess) {
      this.workOrderService.otherTechsOnSite = this.otherTechnicians;
      this.loader.show();
      this._snackBar
        .open('Work Order Completed successfully.', 'Close', { duration: 4000 })
        .afterDismissed()
        .subscribe(() => {
          this.loader.hide();
          this.workOrderService
            .completeRegister({
              techIdMaster: this.authService.user?.personnelNumber ?? '',
              utcOffset: new Date().getTimezoneOffset() / -60,
              workOrder: this.workOrderService.workOrder,
            })
            .subscribe((response) => {
              this.loader.hide();
              this.workOrderService.completeRegisterModel = response.map((item) => ({
                ...item,
                date: new Date(item.date ?? '').toLocaleString(),
              }));
              this.router.navigate([
                `${RoutesEnum.WorkOrder}/${RoutesEnum.forcibleEntry}/${this.workOrderService.workOrder}`,
              ]);
            });
        });
    } else {
      this.onCompleteSubmitError(result.errorMessage);
    }
  };

  onCompleteSubmitError = (error: unknown) => {
    this.loader.hide();
    console.error(error);
    this._snackBar.open(error as string, 'Close');
  };

  postAtmNote() {
    const isCausedByThirdPartyNote = `Was this issue caused by a third party? ${this.thirdParty ? 'Yes' : 'No'}`;
    const noteParts = [isCausedByThirdPartyNote];

    if (this.thirdParty && this.vandalism !== null) {
      const isCausedByVandalismNote = `Was this issue caused by vandalism? ${this.vandalism ? 'Yes' : 'No'}`;
      noteParts.push(isCausedByVandalismNote);
    }

    if (!this.disableFLMorSLM) {
      const isFLMorSLMNote = `Is this FLM or SLM? ${this.isFirstLineMaintenance ? 'FLM' : 'SLM'}`;
      noteParts.push(isFLMorSLMNote);
    }

    if (!this.disableObsoleteLock && this.isLockObsolete !== null) {
      const isObsoleteLockNote = `Obsolete Lock? ${this.isLockObsolete ? 'FLM' : 'SLM'}`;
      noteParts.push(isObsoleteLockNote);
    }

    const refRecId = Number(this.workOrderService.workOrderDetails().ticketRecId);
    const userId = this.authService.user?.userId;
    const recId = this.workOrderService.workOrderDetails().recid;
    const surveyNoteModel = buildNote('Call Completion Survey', noteParts.join(' // '), refRecId, recId, userId);
    this.notesService.addNote(surveyNoteModel).subscribe(() => {
      this.router.navigate([`${RoutesEnum.CallControl}`]);
    });
  }
}
