<ads-simple-form #itmPeripheralTasks="ngModelGroup" ngModelGroup="itmPeripheralTasks">
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Clean pre-head (MOTORIZED CARD READER)"
    name="cleanPreHead"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Check and clean all belts (MOTORIZED CARD READER)"
    name="cleanAllBelts"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Clean read head"
    name="cleanReadHead"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Clean with felt card"
    name="cleanWithFeltCard"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Blow out sensor areas"
    name="blowOutSensorAreas"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Perform diagnostic test"
    name="performDiagnosticTest"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="yesNoOptions"
    label="Is card reader EMV capable?"
    name="isCardReaderEmvCapable"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Connect to teller"
    name="connectToTeller"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Test audio/video"
    name="testAudioVideo"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Test signature pad"
    name="testSignaturePad"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Test phone"
    name="testPhone"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Test scanner"
    name="testScanner"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Test coin dispenser"
    name="testCoinDispenser"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Test video from teller"
    name="testVideoFromTeller"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
