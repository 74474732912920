import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SortingOption } from 'models/shared/sorting-option';

@Component({
  selector: 'ads-radio-values',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatMenuModule, MatButtonModule],
  templateUrl: './radio-values.component.html',
  styleUrl: './radio-values.component.scss',
})
export class RadioValuesComponent<T> {
  @Input() options: SortingOption<T>[] = [];
  @Input() currentCriteria = '';
  @Input() currentCriteriaLabel = '';
  @Output() optionClicked = new EventEmitter<{ column: string; asc: boolean; sortingLabel: string }>();

  onOptionClicked(column: string, asc: boolean, sortingLabel: string) {
    this.optionClicked.emit({ column, asc, sortingLabel });
  }
}
