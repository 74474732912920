<ads-simple-form #accessHardware="ngModelGroup" ngModelGroup="accessHardware">
  <ng-container ngModelGroup="accessControls">
    <ads-nested-multiple-forms-section
      #accessHardwareSectionsPanels
      title="Access Control"
      [formTemplate]="formTemplateAccessControl"
      [list]="security.accessHardwareData.accessControls"
      [formControls]="getFormControls('accessControls')"
      [groupName]="'accessControl'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddClick('accessControls')"
      (deleteClicked)="onRemoveSection($event, 'accessControls')"
    >
    </ads-nested-multiple-forms-section>
    <ng-template #formTemplateAccessControl let-item="item">
      <ads-access-hardware-section-form
        [section]="getItemSection('accessControl#' + item.index, 'accessControls')"
        [item]="item"
        [lists]="achDropdowns"
      ></ads-access-hardware-section-form>
    </ng-template>
  </ng-container>

  <ads-expansion-panel
    title="Access Control Panel tasks"
    [name]="'accessControlTasks'"
    [class]="'accessControlTasks'"
    [formGroup]="formGroup"
  >
    <ads-ah-access-control-tasks-section
      [section]="getTasksSection('accessControlTasks')"
    ></ads-ah-access-control-tasks-section>
  </ads-expansion-panel>

  <ng-container ngModelGroup="accessControlCardReaderInterfaces">
    <ads-nested-multiple-forms-section
      #accessHardwareCardReaderInterfaces
      title="Card Reader Interface Table"
      [formTemplate]="formTemplateCardReader"
      [list]="security.accessHardwareData.accessControlCardReaderInterfaces"
      [formControls]="getFormControls('accessControlCardReaderInterfaces')"
      [groupName]="'accessControlCardReaderInterface'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddClick('accessControlCardReaderInterfaces')"
      (deleteClicked)="onRemoveSection($event, 'accessControlCardReaderInterfaces')"
      itemTitle="Card Reader"
      [minItems]="0"
    >
      <div class="badge-container" *ngIf="security.accessHardwareData.accessControlCardReaderInterfaces.length > 0">
        <ads-badge-text
          text="Readers"
          [value]="'' + security.accessHardwareData.accessControlCardReaderInterfaces.length"
        ></ads-badge-text>
      </div>
    </ads-nested-multiple-forms-section>
    <ng-template #formTemplateCardReader let-item="item">
      <ads-ah-card-reader-interface-table-section
        [section]="
          getItemSection('accessControlCardReaderInterface#' + item.index, 'accessControlCardReaderInterfaces')
       "
        [item]="item"
        [lists]="achDropdowns"
      ></ads-ah-card-reader-interface-table-section>
    </ng-template>
  </ng-container>

  <ads-expansion-panel
    title="Card Reader Interface tasks"
    [formGroup]="formGroup"
    [name]="'accessControlCardReaderTasks'"
    [class]="'accessControlCardReaderTasks'"
  >
    <ads-ah-card-reader-interface-tasks-section
      [section]="getTasksSection('accessControlCardReaderTasks')"
    ></ads-ah-card-reader-interface-tasks-section>
  </ads-expansion-panel>

  <ng-container ngModelGroup="accessControlPowerSupplies">
    <ads-nested-multiple-forms-section
      #accessHardwareControlPowerSupplies
      title="Power Supply Table"
      [formTemplate]="formTemplatePowerSupplies"
      [list]="security.accessHardwareData.accessControlPowerSupplies"
      [formControls]="getFormControls('accessControlPowerSupplies')"
      [groupName]="'accessControlPowerSupply'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddClick('accessControlPowerSupplies')"
      (deleteClicked)="onRemoveSection($event, 'accessControlPowerSupplies')"
      itemTitle="Power Supply"
      [minItems]="0"
    >
      <div class="badge-container" *ngIf="security.accessHardwareData.accessControlPowerSupplies.length > 0">
        <ads-badge-text
          text="Power Supply"
          [value]="'' + security.accessHardwareData.accessControlPowerSupplies.length"
        ></ads-badge-text>
      </div>
    </ads-nested-multiple-forms-section>
    <ng-template #formTemplatePowerSupplies let-item="item">
      <ads-ah-power-supply-table-section
        [section]="getItemSection('accessControlPowerSupply#' + item.index, 'accessControlPowerSupplies')"
        [item]="item"
        [lists]="achDropdowns"
      ></ads-ah-power-supply-table-section>
    </ng-template>
  </ng-container>

  <ads-expansion-panel
    title="Power Supply tasks"
    [formGroup]="formGroup"
    [name]="'accessControlPowerSupplyTasks'"
    [class]="'accessControlPowerSupplyTasks'"
  >
    <ads-ah-power-supply-tasks-section
      [section]="getTasksSection('accessControlPowerSupplyTasks')"
    ></ads-ah-power-supply-tasks-section>
  </ads-expansion-panel>

  <ng-container ngModelGroup="accessControlDoors">
    <ads-nested-multiple-forms-section
      #accessHardwareControlDoors
      title="Door Table"
      [formTemplate]="formTemplateDoors"
      [list]="security.accessHardwareData.accessControlDoors"
      [formControls]="getFormControls('accessControlDoors')"
      [groupName]="'accessControlDoor'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddClick('accessControlDoors')"
      (deleteClicked)="onRemoveSection($event, 'accessControlDoors')"
      itemTitle="Door"
      [minItems]="0"
    >
      <div class="badge-container" *ngIf="security.accessHardwareData.accessControlDoors.length > 0">
        <div>
          <ads-badge-text text="Prox" [value]="'' + getDoorsBadges().prox"></ads-badge-text>
          <ads-badge-text text="Smart" [value]="'' + getDoorsBadges().smart"></ads-badge-text>
        </div>
        <div>
          <ads-badge-text text="Biometric" [value]="'' + getDoorsBadges().biometric"></ads-badge-text>
          <ads-badge-text text="Other" [value]="'' + getDoorsBadges().other"></ads-badge-text>
        </div>
      </div>
    </ads-nested-multiple-forms-section>
    <ng-template #formTemplateDoors let-item="item">
      <ads-ah-doors-table-section
        [section]="getItemSection('accessControlDoor#' + item.index, 'accessControlDoors')"
        [item]="item"
        [lists]="achDropdowns"
      ></ads-ah-doors-table-section>
    </ng-template>
  </ng-container>

  <ads-common-text-area
    name="comments"
    label="Comments (optional)"
    [maxLength]="200"
    ngModel
  ></ads-common-text-area>
</ads-simple-form>
