const coeff = 1000 * 60 * 15;

export const roundDateToNearestQuarter = (selectedDate: Date) => new Date(Math.ceil(selectedDate.getTime() / coeff) * coeff);

export function PerformRounding(time: Date): string {
  let finalhour = time.getHours();
  let finalminute = '00';
  const minute = time.getMinutes();

  if (minute > 7 && minute <= 22) {
    finalminute = '15';
  } else if (minute > 22 && minute <= 37) {
    finalminute = '30';
  } else if (minute > 37 && minute <= 52) {
    finalminute = '45';
  } else if (minute > 52) {
    finalhour = (finalhour + 1) % 24;
  }

  return `${finalhour.toString().padStart(2, '0')}:${finalminute}:00`;
}
