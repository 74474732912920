<form class="container">
  <span *ngIf="showNote" class="note">Enter at least first four digits to search.</span>
  <mat-form-field class="field">
    <span matPrefix>{{ type }} -</span>
    <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" />
    <mat-icon matSuffix>search</mat-icon>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onSelectOption($event)">
      @for (option of rmaRequestService.filteredOptions(); track option) {
      <mat-option [value]="option.ServiceOrderId?.replace('WO-', '') ?? option.ProjectId?.replace('PRJ-', '')">
        <div class="search-option">
          <span class="search-option-title">{{ option.ServiceOrderId ?? option.ProjectId }}</span>
          <span class="search-option-info">{{ option.MasterCustomerName }}</span>
          <span class="search-option-info">{{ option.CustomerNameAlias }}</span>
          <span class="search-option-info">{{ option.ProblemCode ?? option.ProjectGroup }}</span>
        </div>
      </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</form>
