<div class="container">
  <span>{{ photoUpload.label }}</span>
  <div *ngIf="!image" class="take-photo">
    <input #imgInput type="file" accept="image/*" hidden (change)="imageChanged($event)" required />
    <button
      type="button"
      mat-button
      mat-stroked-button
      class="icon-button secondary-button"
      [class.error-button]="!isValid()"
      (click)="imgInput.click()"
      [disabled]="getFormDisabled()"
    >
      <mat-icon>photo_camera</mat-icon>
      Take Photo
    </button>
    <span *ngIf="!isValid()" class="supporting-text" [class.supporting-error]="!isValid()">Required</span>
  </div>

  <div *ngIf="!!image" class="update-photo">
    <div class="preview">
      <img
        src="{{ image }}"
        alt="{{ photoUpload.label }}"
        [class.portrait]="!isLandscape"
        [class.landscape]="isLandscape"
      />
    </div>

    <div class="retake" *ngIf="!getFormDisabled()">
      <button type="button" mat-button mat-stroked-button (click)="deleteImage()" class="delete danger-button">
        Delete
      </button>
      <input #imgInput type="file" accept="image/*" hidden (change)="imageChanged($event)" />
      <button
        mat-button
        mat-stroked-button
        class="icon-button secondary-button"
        (click)="imgInput.click()"
        type="button"
      >
        <mat-icon>photo_camera</mat-icon>
        Retake
      </button>
    </div>
  </div>
</div>
