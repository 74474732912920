import { CommonModule } from '@angular/common';
import { Component, Input, AfterViewInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { SafesSectionFormComponent } from './safes-section-form/safes-section-form.component';
import { SecurityService } from 'pages/security/security.service';
import { SafesComboLockTasks, SafesTabFormSection } from 'models/security/safes/safesSecurityForm';
import { NestedMultipleFormsSectionComponent } from 'forms/shared/nested-multiple-forms-section/nested-multiple-forms-section.component';
import { IndexableFormType } from 'app/utils/miscTypes';
import { SecuritySafesDropdowns } from 'models/security/security-dropdowns';
import { getFormGroup } from 'utils';
import { ComboLockTasksSectionComponent } from '../shared/combo-lock-tasks-section/combo-lock-tasks-section.component';
import { TimeClockTasksSectionComponent } from '../shared/time-clock-tasks-section/time-clock-tasks-section.component';
import { SafesSafeTasksSectionComponent } from './safes-safe-tasks-section/safes-safe-tasks-section.component';
import { MatInputModule } from '@angular/material/input';
import { CommonTextAreaComponent } from '../../../shared/material-wrappers/common-text-area/common-text-area.component';
import { getSectionStatusFormGroup } from 'app/utils/form-status';
import { onAddItem, onRemoveItem } from 'app/utils/form-group-array';
import { TimeClockSection } from 'models/security/shared/time-clock';
import { ComboLockSection } from 'models/security/shared/combo-lock';

export class SafesFormSection implements IndexableFormType, SafesTabFormSection {
  index: number;
  safeModel: string | null;
  safeManufacturer: string | null;
  safeManufacturer_Other: string | null;
  safeType: string | null;
  swingType: string | null;
  overallConditionSafe: string | null;
  equipment_Rating_Safe: string | null;
  comboLocks: ComboLockSection[];
  timeClocks: TimeClockSection[];

  constructor(index: number) {
    this.index = index;
    this.safeModel = '';
    this.safeManufacturer = '';
    this.safeManufacturer_Other = '';
    this.safeType = '';
    this.swingType = '';
    this.overallConditionSafe = '';
    this.equipment_Rating_Safe = '';
    this.comboLocks = [];
    this.timeClocks = [];
  }
}

@Component({
  selector: 'ads-safes',
  standalone: true,
  imports: [
    CommonModule,
    SimpleFormComponent,
    FormsModule,
    MatInputModule,
    ExpansionPanelComponent,
    NestedMultipleFormsSectionComponent,
    SafesSectionFormComponent,
    TimeClockTasksSectionComponent,
    ComboLockTasksSectionComponent,
    SafesSafeTasksSectionComponent,
    CommonTextAreaComponent,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './safes.component.html',
})
export class SafesComponent implements AfterViewInit {
  @Input() formGroup?: FormGroup | null;
  @Input() submitted = false;
  expandedList: number[] = [];

  comboLockFields = Object.getOwnPropertyNames(new SafesComboLockTasks());

  @Input() dropdowns: SecuritySafesDropdowns = {
    safesSafesSafeManufacturers: [],
    safesSafesSwingTypes: [],
    safesSafesOverallConditions: [],
  };

  constructor(public readonly security: SecurityService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.formGroup?.controls['safesTasks'].setValue({
        allNa: false,
        ...this.security.safesData.safesTasks,
      });

      this.formGroup?.controls['timeClockTasks'].setValue({
        allNa: false,
        ...this.security.safesData.timeClockTasks,
      });

      this.formGroup?.controls['comboLockTasks'].setValue({
        allNa: false,
        ...this.security.safesData.comboLockTasks,
      });

      this.formGroup?.controls['comments'].setValue(this.security.safesData.comments);

      if (this.submitted) {
        this.formGroup?.disable();
      }
    });
  }

  getSection(sectionName: string) {
    return this.formGroup?.controls[sectionName] as FormGroup | undefined;
  }

  getItemSection(sectionName: string, containerName: string) {
    return getFormGroup(this.formGroup?.controls[containerName])?.controls[sectionName] as FormGroup;
  }

  getFormControls(): Record<string, AbstractControl<string, string>> {
    const fg = this.formGroup?.get('safesPanels') as FormGroup;
    return fg ? fg.controls : {};
  }

  onAddClick = () => onAddItem(this.security.safesData.safesSections, (index) => new SafesFormSection(index));

  onRemoveSafes = (item: SafesFormSection) => onRemoveItem(this.security.safesData.safesSections, item);

  getList() {
    return this.security.safesData.safesSections;
  }

  getSectionStatus = getSectionStatusFormGroup(this.formGroup);

  getTasksSection(containerName: string) {
    return this.formGroup?.controls[containerName] as FormGroup;
  }
}
