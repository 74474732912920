import { FormsModule, NgForm } from '@angular/forms';
import { CommonModule, Location } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationRoutesEnum, RoutesEnum } from 'app/utils/navigation';
import { HeaderService } from 'shared/header/header.service';
import { CallControlPage } from 'pages/page-utils/call-control-page';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { ModalActionsComponent } from 'shared/modal-actions/modal-actions.component';
import { NavigationHeaderComponent } from 'shared/navigation-header/navigation-header.component';
import { CommonFormInputComponent } from 'shared/material-wrappers/common-form-input/common-form-input.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'services';
import { AskTravelBackAndLunchComponent } from 'pages/work-order/shared/ask-travel-back-and-lunch/ask-travel-back-and-lunch.component';

@Component({
  selector: 'ads-forcible-entry',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    NavigationHeaderComponent,
    ModalActionsComponent,
    CommonFormInputComponent,
  ],
  templateUrl: './forcible-entry.component.html',
  styleUrl: './forcible-entry.component.scss',
})
export class ForcibleEntryComponent extends CallControlPage implements OnInit, OnDestroy {
  @ViewChild('completeForm') form!: NgForm;
  public readonly _snackBar = inject(MatSnackBar);

  boxNumber = '';
  otherEquipment = '';
  electronicSignature = '';
  title = '';
  email1 = '';
  email2 = '';
  email3 = '';
  technicianName = '';

  constructor(
    override readonly route: ActivatedRoute,
    override readonly headerService: HeaderService,
    override readonly location: Location,
    override readonly router: Router,
    private readonly authService: AuthService,
    public override workOrderService: WorkOrderService,
    protected readonly layoutNavService: LayoutNavService
  ) {
    super(headerService, location, workOrderService, route, router);
  }

  ngOnInit() {
    this.init();
    this.workOrderService.showModal(AskTravelBackAndLunchComponent);
  }

  ngOnDestroy() {
    if (this.headerService.titleAnnex()) {
      this.headerService.titleAnnex.set(undefined);
    }
  }

  onConfirm() {
    this.form.form.markAllAsTouched();
    if (this.form.form.valid) {
      this.workOrderService
        .completeCall({
          email1: this.email1,
          email2: this.email2,
          email3: this.email3,
          esig: this.electronicSignature,
          otherEquipment: this.otherEquipment,
          otherTechsOnSite: this.workOrderService.otherTechsOnSite,
          projId: this.workOrderService.workOrderDetails().projid,
          safeDepositBoxNo: this.boxNumber,
          techIdMaster: this.authService.user?.personnelNumber ?? '',
          technicianName: this.technicianName,
          title: this.title,
          userId: this.authService.user?.userId,
          workOrder: this.workOrderService.workOrder,
        })
        .subscribe(() => {
          this._snackBar
            .open('Order was completed and submitted successfully.', 'Close', { duration: 4000 })
            .afterDismissed()
            .subscribe(() => {
              this.layoutNavService.navigateTo(NavigationRoutesEnum.CallControl);
            });
        });
    } else {
      this._snackBar.open('Please fill out all required fields.', 'Close', { duration: 4000 });
    }
  }

  onBackArrowClicked() {
    const time = this.route.snapshot.paramMap?.get('time');
    this.router.navigate([`WO/${RoutesEnum.CompleteWorkOrder}/${this.workOrderService.workOrder}`, { time }]);
    this.router.navigate([`${RoutesEnum.WorkOrder}/${this.workOrderService.workOrder}`]);
  }
}
