import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Labor } from 'models/call-control/labor';

@Component({
  selector: 'ads-labor-card',
  standalone: true,
  imports: [CommonModule, FormsModule, MatIconModule, MatCheckboxModule, MatButtonModule],
  templateUrl: './labor-card.component.html',
  styleUrl: './labor-card.component.scss',
})
export class LaborCardComponent {
  @Input() item!: Labor;
  @Output() readonly clicked = new EventEmitter<void>();
  private readonly datepipe: DatePipe = new DatePipe('en-US');

  roundHours(time: string) {
    const hours = Math.floor(Number(time));
    const minutes = 60 * (Number(time) - hours);
    const minutesRounded = Math.floor(minutes);
    return `${hours}h ${minutesRounded}m`;
  }
}
