<ads-simple-form *ngIf="item" [ngModelGroup]="'timeClock#' + item.index">
  <ads-common-form-input
    name="timeClockType"
    label="Time clock type"
    [maxLength]="50"
    [required]="true"
    [value]="item.timeClockType"
    [(ngModel)]="item.timeClockType"
  ></ads-common-form-input>

  <ads-common-form-input
    name="timeClockManufacturer"
    label="Time clock manufacturer"
    [maxLength]="50"
    [required]="true"
    [value]="item.timeClockManufacturer"
    [(ngModel)]="item.timeClockManufacturer"
  ></ads-common-form-input>

  <ads-common-form-input
    name="timeClockModel"
    label="Time clock model"
    [maxLength]="50"
    [required]="true"
    [value]="item.timeClockModel"
    [(ngModel)]="item.timeClockModel"
  ></ads-common-form-input>

  <ads-common-form-input
    name="quantityOfMovements"
    label="Quantity of movements"
    type="number"
    [minValue]="0"
    [maxLength]="8"
    [required]="true"
    [value]="item.quantityOfMovements"
    [(ngModel)]="item.quantityOfMovements"
    inputMode="tel"
  ></ads-common-form-input>

  <ads-radio-button-group
    [options]="options"
    label="Is linkage connected directly to combo lock?"
    name="isLinkageConnectedDirectlyToCombolock"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
