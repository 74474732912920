import { PhotoUpload } from './photo-upload';

export const atmPhotoUploadKeys = [
  'atm-front-distance',
  'atm-card-reader',
  'atm-depositor',
  'atm-dispenser',
  'atm-customer-monitor',
  'atm-serial-number-label',
  'atm-front-close',
  'atm-computer',
  'atm-touchscreen-fdks',
  'atm-printer',
  'atm-operator-panel',
  'atm-software',
  'atm-additional-photo',
] as const;

export type AtmPhotoUploadKey = (typeof atmPhotoUploadKeys)[number];

export const atmPhotoUploads: PhotoUpload<AtmPhotoUploadKey>[] = [
  { label: 'Front distance', key: 'atm-front-distance', required: true },
  { label: 'Card Reader (from inside fascia)', key: 'atm-card-reader', required: true },
  { label: 'Dispenser', key: 'atm-dispenser', required: true },
  { label: 'Customer Monitor (from inside fascia)', key: 'atm-customer-monitor', required: true },
  { label: 'Serial Number Label', key: 'atm-serial-number-label', required: true },
  { label: 'Front Close', key: 'atm-front-close', required: true },
  { label: 'Computer', key: 'atm-computer', required: true },
  { label: 'Touchscreen/FDKs', key: 'atm-touchscreen-fdks', required: true },
  { label: 'Printer', key: 'atm-printer', required: true },
  { label: 'Depositor (optional)', key: 'atm-depositor', required: false },
  { label: 'Operator Panel (optional)', key: 'atm-operator-panel', required: false },
  { label: 'Software - display in one shot (optional)', key: 'atm-software', required: false },
  { label: 'Additional Photo (optional)', key: 'atm-additional-photo', required: false },
];
