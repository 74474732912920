import { Component, Input, Optional } from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, NgModelGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { AccessControlsFormSection } from '../access-hardware.component';
import { AccessControlHardwareDropdowns } from 'models/security/security-dropdowns';
import { MatDividerModule } from '@angular/material/divider';
import { SelectOption } from 'models';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';

@Component({
  selector: 'ads-access-hardware-section-form',
  standalone: true,
  imports: [CommonModule, FormsModule, CommonFormInputComponent, SelectComponent, MatDividerModule, SimpleFormComponent, CommonTextAreaComponent],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
  templateUrl: './access-hardware-section-form.component.html',
})
export class AccessHardwareSectionFormComponent {
  @Input() lists?: AccessControlHardwareDropdowns;
  @Input() section?: FormGroup;
  @Input() item?: AccessControlsFormSection;

  constructor(@Optional() public readonly ngModelGroup: NgModelGroup) {}

  displayOther = (value: string) => {
    const field = this.section?.get(value);
    return Boolean(field && field.value === 'Other');
  };

  displayOtherCondition = () => {
    const condition = this.section?.get('overallConditionAccessControlsystem');
    return Boolean(condition && (condition.value === 'Poor Condition' || condition.value === 'Failed'));
  };

  getControlPanelValues = (): SelectOption<string>[] => {
    const manufacturer = this.section?.get('systemManufacturer');

    if (manufacturer?.value === 'Other') {
      return [
        {
          name: 'Other',
          value: 'Other',
        },
      ];
    }

    return (
      this.lists?.controlPanelDropdowns
        .filter((x) => x.manufacturer === manufacturer?.value)
        .map((x) => {
          return {
            name: x.value,
            value: x.value,
          };
        }) ?? []
    );
  };
}
