import { Injectable, signal } from '@angular/core';
import { Theme, ThemeName, darkTheme, lightTheme } from 'app/styles/theme';
import { LocalStorageService } from './localstorage.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemingService {
  theme = darkTheme;
  _inputHeight = '54px';

  primary = signal(this.theme.primary);
  primaryLight = signal(this.theme.primaryLight);
  ripple = signal(this.theme.ripple);
  primaryDark = signal(this.theme.primaryDark);
  inputHeight = signal(this._inputHeight);
  background = signal(this.theme.background);
  error = signal(this.theme.error);
  name = signal(this.theme.name);
  inputColorSelected = signal(this.theme.inputColorSelected);
  inputBorder = signal(this.theme.inputBorder);
  panelColor = signal(this.theme.panelColor);
  selectedOption = signal(this.theme.selectedOption);
  inputBorderSelected = signal(this.theme.inputBorderSelected);
  inputBackground = signal(this.theme.inputBackground);
  inputBackgroundSelected = signal(this.theme.inputBackgroundSelected);
  buttonDangerColor = signal(this.theme.dangerButton.color);
  buttonDangerColorBackground = signal(this.theme.dangerButton.background);
  buttonDangerColorHover = signal(this.theme.dangerButton.colorHover);
  buttonDangerBorderColor = signal(this.theme.dangerButton.borderColor);
  secondaryButtonColor = signal(this.theme.secondaryButton.color);
  secondaryButtonBackground = signal(this.theme.secondaryButton.background);
  secondaryButtonBorderColor = signal(this.theme.secondaryButton.borderColor);
  secondaryButtonColorHover = signal(this.theme.secondaryButton.colorHover);
  hoverPrimary = signal(this.theme.hoverPrimary);
  tabsBackgroundColor = signal(this.theme.tabs.background);
  tabsColor = signal(this.theme.tabs.color);
  tabsFocusedColor = signal(this.theme.tabs.focusedColor);
  badgeColor = signal(this.theme.badge.color);
  badgeBackground = signal(this.theme.badge.background);
  badgeValueColor = signal(this.theme.badge.valueColor);
  navBackground = signal(this.theme.nav.background);
  navColor = signal(this.theme.nav.color);
  navActiveMobileColor = signal(this.theme.nav.activeColorMobile);
  dividerColor = signal(this.theme.nav.dividerColor);
  cardBackground = signal(this.theme.card.background);
  cardColor = signal(this.theme.card.color);
  cardSubBackground = signal(this.theme.card.subBackground);

  private readonly themeSub = new Subject<string>();
  watchTheme = this.themeSub.asObservable();
  tableHeaderBackground = signal(this.theme.table.headerBackground);
  tableBorder = signal(this.theme.table.border);

  constructor(private readonly localStorageService: LocalStorageService) {
    const theme = this.localStorageService.getItem('theme');
    if (theme) {
      this.theme = theme === ThemeName.Dark ? darkTheme : lightTheme;
    } else {
      const currentBrowserThemeIsDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      this.localStorageService.setItem('theme', currentBrowserThemeIsDark ? ThemeName.Dark : ThemeName.Light);
      this.theme = currentBrowserThemeIsDark ? darkTheme : lightTheme;
    }
    this.applyTheme(this.theme);
  }

  applyTheme(theme: Theme) {
    const {
      primary,
      primaryLight,
      primaryDark,
      ripple,
      background,
      error,
      name,
      inputColorSelected,
      inputBorder,
      panelColor,
      inputBorderSelected,
      inputBackground,
      inputBackgroundSelected,
      dangerButton,
      secondaryButton,
      selectedOption,
      hoverPrimary,
      tabs,
      badge,
      nav,
      card,
      table,
    } = theme;

    this.primary.set(primary);
    this.primaryLight.set(primaryLight);
    this.primaryDark.set(primaryDark);
    this.background.set(background);
    this.ripple.set(ripple);
    this.error.set(error);
    this.name.set(name);
    this.inputColorSelected.set(inputColorSelected);
    this.selectedOption.set(selectedOption);
    this.panelColor.set(panelColor);
    this.inputBorder.set(inputBorder);
    this.inputBorderSelected.set(inputBorderSelected);
    this.inputBackground.set(inputBackground);
    this.inputBackgroundSelected.set(inputBackgroundSelected);
    this.buttonDangerColor.set(dangerButton.color);
    this.buttonDangerColorBackground.set(dangerButton.background);
    this.buttonDangerColorHover.set(dangerButton.colorHover);
    this.buttonDangerBorderColor.set(dangerButton.borderColor);
    this.secondaryButtonColor.set(secondaryButton.color);
    this.secondaryButtonBackground.set(secondaryButton.background);
    this.secondaryButtonBorderColor.set(secondaryButton.borderColor);
    this.secondaryButtonColorHover.set(secondaryButton.colorHover);
    this.hoverPrimary.set(hoverPrimary);
    this.tabsBackgroundColor.set(tabs.background);
    this.tabsColor.set(tabs.color);
    this.tabsFocusedColor.set(tabs.focusedColor);
    this.badgeColor.set(badge.color);
    this.badgeBackground.set(badge.background);
    this.badgeValueColor.set(badge.valueColor);
    this.navBackground.set(nav.background);
    this.navColor.set(nav.color);
    this.navActiveMobileColor.set(nav.activeColorMobile);
    this.dividerColor.set(nav.dividerColor);
    this.cardBackground.set(card.background);
    this.cardColor.set(card.color);
    this.cardSubBackground.set(card.subBackground);
    this.themeSub.next(name);
    this.tableHeaderBackground.set(table.headerBackground);
    this.tableBorder.set(table.border);
  }

  switchCurrentTheme() {
    if (this.name() === ThemeName.Dark) {
      this.applyTheme(lightTheme);
      this.localStorageService.setItem('theme', ThemeName.Light);
    } else {
      this.applyTheme(darkTheme);
      this.localStorageService.setItem('theme', ThemeName.Dark);
    }
  }

  getOtherTheme() {
    if (this.name() === ThemeName.Dark) return lightTheme;
    else return darkTheme;
  }

  getThemeProperties(): Record<string, string> {
    return {
      '--primary': this.primary(),
      '--primary-light': this.primaryLight(),
      '--ripple': this.ripple(),
      '--primary-dark': this.primaryDark(),
      '--input-height': this.inputHeight(),
      '--background': this.background(),
      '--error': this.error(),
      '--input-color-selected': this.inputColorSelected(),
      '--input-border': this.inputBorder(),
      '--panel-color': this.panelColor(),
      '--input-border-selected': this.inputBorderSelected(),
      '--input-background': this.inputBackground(),
      '--input-background-selected': this.inputBackgroundSelected(),
      '--button-danger-color': this.buttonDangerColor(),
      '--button-danger-color-background': this.buttonDangerColorBackground(),
      '--button-danger-color-hover': this.buttonDangerColorHover(),
      '--button-danger-border-color': this.buttonDangerBorderColor(),
      '--secondary-button-color': this.secondaryButtonColor(),
      '--secondary-button-background': this.secondaryButtonBackground(),
      '--secondary-button-border-color': this.secondaryButtonBorderColor(),
      '--secondary-button-color-hover': this.secondaryButtonColorHover(),
      '--selected-option': this.selectedOption(),
      '--hover-primary': this.hoverPrimary(),
      '--tabs-background-color': this.tabsBackgroundColor(),
      '--tabs-color': this.tabsColor(),
      '--tabs-focused-color': this.tabsFocusedColor(),
      '--badge-color': this.badgeColor(),
      '--badge-background': this.badgeBackground(),
      '--badge-value-color': this.badgeValueColor(),
      '--nav-background': this.navBackground(),
      '--nav-color': this.navColor(),
      '--nav-active-mobile-color': this.navActiveMobileColor(),
      '--divider-color': this.dividerColor(),
      '--card-background': this.cardBackground(),
      '--card-color': this.cardColor(),
      '--card-sub-background': this.cardSubBackground(),
      '--table-header-background': this.tableHeaderBackground(),
      '--table-border': this.tableBorder(),
    } as const;
  }
}
