import { AdsCallControl } from 'models';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { Component, AfterViewInit, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TimeZonePipe } from 'app/utils/time-zone.pipe';
import { CallControlService } from './call-control.service';
import { ServiceCallsService } from './service-calls/service-calls.service';
import { CallControlServiceCallsComponent } from './call-control-service-calls/call-control-service-calls.component';
import { CallControlPmBoardComponent } from './call-control-pm-board/call-control-pm-board.component';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { MatButtonModule } from '@angular/material/button';
import { CurrentWorkOrderService } from 'services/current-work-order.service';
import { FabButtonComponent } from "shared/fab-button/fab-button.component";
import { mapCalls } from './utils/calls-mapping';

@Component({
  selector: 'ads-call-control',
  standalone: true,
  providers: [TimeZonePipe],
  imports: [
    CommonModule,
    MatTabsModule,
    CallControlServiceCallsComponent,
    CallControlPmBoardComponent,
    MatIconModule,
    MatButtonModule,
    FabButtonComponent
],
  templateUrl: './call-control.component.html',
  styleUrl: './call-control.component.scss',
})
export class CallControlComponent implements OnInit, AfterViewInit {
  pmBoardCalls: AdsCallControl[] = [];
  serviceCalls: AdsCallControl[] = [];
  isDesktopView = false;
  loading = true;

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly timeZonePipe: TimeZonePipe,
    private readonly ccService: CallControlService,
    public readonly dataService: ServiceCallsService,
    protected readonly layoutNavService: LayoutNavService,
    private readonly currentWorkOrderService: CurrentWorkOrderService,
  ) {}

  ngOnInit(): void {
    this.ccService.callControlList({ pageSize: 20, pageNo: 1 }).subscribe((response) => {
      this.ccService.totalItems = response.totalRecords;
      const items: AdsCallControl[] = this.mapCalls(response.items);

      this.pmBoardCalls = items.filter((x) => x.isPm);
      this.serviceCalls = items.filter((x) => !x.isPm);
      const selectedCall = this.currentWorkOrderService.getSelectedCall();
      if (selectedCall) {
        const call = this.serviceCalls.findIndex((x) => x.recid === selectedCall.recid);
        if (call >= 0) {
          this.serviceCalls = [
            this.serviceCalls[call],
            ...this.serviceCalls.filter((x) => x.recid !== selectedCall.recid),
          ];
        }
      }
      this.loading = false;
      this.dataService.updatePriorityFilter(items);
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe((x) => {
        this.isDesktopView = !x.matches;
      });
    });
  }

  mapCalls = (calls: AdsCallControl[]) => mapCalls(calls, this.timeZonePipe)

  onTabChange() {
    this.dataService.statusFilter.value = 'none';
    this.dataService.scheduledDateFilter.minValue = undefined;
    this.dataService.scheduledDateFilter.maxValue = undefined;
    this.dataService.sorting.activeSortCriteria = 'none';
    this.dataService.sorting.sortAsc = true;
    this.dataService.sorting.tempSortCriteria = 'none';
    this.dataService.sorting.tempSortingLabel = '- None -';
  }
}
