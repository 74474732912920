<div class="title-container">
  <span class="title">Attachments</span>

  <button mat-button mat-stroked-button (click)="addNewAttachment()">
    Add new <mat-icon iconPositionEnd>add</mat-icon>
  </button>
</div>

<div class="container">
  @for (attachment of workOrderService.attachments(); track workOrderService.attachments()) {

  <div class="attachments-container">
    <div class="left">
      <span>Name</span>
      <span>Restriction</span>
      <span>Created Date</span>
      <button mat-button class="view" (click)="viewAttachment(attachment.recId)">View</button>
    </div>

    <div class="right">
      <span>{{ attachment.name }}</span>
      <span>{{ attachment.restrictions }}</span>
      <span>{{ attachment.createdDateTime | date : 'MM/dd/yyyy' }}</span>

      <button mat-icon-button class="delete" (click)="deleteAttachment(attachment.documentId)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  }
</div>

<button mat-button mat-stroked-button (click)="closeDialog()" class="close">Close</button>
