<ads-simple-form *ngIf="item" class="deal-drawer-section-wrapper" [ngModelGroup]="'dealDrawerSection#' + item.index">
  <ads-select
    label="Deal drawer make"
    name="dealDrawerMake"
    [list]="dealDrawerLists?.manufacturerList"
    [value]="item.dealDrawerMake"
    [(ngModel)]="item.dealDrawerMake"
  ></ads-select>
  <ads-common-form-input
    [style.display]="displayOtherManufacturer() ? 'block' : 'none'"
    name="otherMake"
    label="Other Manufacturer"
    [maxLength]="50"
    [required]="displayOtherManufacturer()"
    [value]="item.otherMake"
    [(ngModel)]="item.otherMake"
  ></ads-common-form-input>
  <ads-common-form-input
    name="dealDrawerModel"
    label="Deal Drawer Model"
    [maxLength]="50"
    [required]="true"
    [value]="item.dealDrawerModel"
    [(ngModel)]="item.dealDrawerModel"
  ></ads-common-form-input>
  <ads-select
    label="Overall Condition - Deal Drawer"
    name="overallCondition"
    [list]="dealDrawerLists?.conditionList"
    [value]="item.overallCondition"
    [(ngModel)]="item.overallCondition"
    [required]="true"
  ></ads-select>
  <ads-common-text-area
    [style.display]="displayEquipmentRating() ? 'block' : 'none'"
    name="overallConditionComments"
    label="Substandard equipment comments"
    [maxLength]="500"
    [required]="displayEquipmentRating()"
    [value]="item.overallConditionComments"
    [(ngModel)]="item.overallConditionComments"
  ></ads-common-text-area>
  <ads-common-form-input
    name="laneNumber"
    label="Lane Number"
    type="number"
    [minValue]="0"
    [maxLength]="8"
    [required]="true"
    [value]="item.laneNumber"
    [(ngModel)]="item.laneNumber"
    inputMode="tel"
  ></ads-common-form-input>
</ads-simple-form>
