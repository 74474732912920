<ads-radio-prompt
  icon="swap_vert"
  [label]="label"
  [value]="service.sorting.activeSortCriteria"
  [options]="desktopSortingOptions"
  [isDesktopView]="true"
  [isFilterApplied]="service.sorting.activeSortCriteria !== 'none'"
  (cancel)="onCancelClick()"
  (confirm)="onConfirmClick()"
  (optionSelected)="onColumnSelected($event)"
>
  <ads-radio-values
    class="values"
    [currentCriteria]="service.sorting.tempSortCriteria"
    [currentCriteriaLabel]="service.sorting.tempSortingLabel"
    [options]="service.sortOptions"
    (optionClicked)="onSortingAscClicked($event)"
  ></ads-radio-values>
</ads-radio-prompt>
