<ads-simple-form #nightDropComboLockTasks="ngModelGroup" [ngModelGroup]="'comboLockTasks'">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button>
  <ads-radio-button-group
    [options]="options"
    label="Check combo lock for proper operation"
    name="checkComboLockForProperOperation"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
<mat-divider></mat-divider>
  <ads-radio-button-group

    [options]="options"
    label="Disassemble and inspect combo lock"
    name="disassembleAndInspectComboLock"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Lubricate and reassemble combo lock"
    name="lubricateAndReassembleComboLock"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Check for dial and ring proper operation"
    name="checkForDialAndRingProperOperation"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Disassemble and inspect dial and ring (if removable)"
    name="disassembleAndInspectDialAndRing"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    *ngIf="fields.includes('lubricateAndReassembleDialAndRing')"
    [options]="options"
    label="Lubricate and reassemble dial and ring"
    name="lubricateAndReassembleDialAndRing"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
