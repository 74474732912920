<section>
  <mat-checkbox class="select-all" [checked]="item().completed" [indeterminate]="partiallyComplete()" (change)="update($event.checked)">
    {{ item().name }}
  </mat-checkbox>
  <span>
    @for (subitem of item().subitems; track subitem; let i = $index) {
    <mat-checkbox [checked]="subitem.completed" (change)="update($event.checked, i)">
      {{ subitem.name }}
    </mat-checkbox>
    }
  </span>
</section>
