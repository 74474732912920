import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ServiceCallsService } from '../../service-calls.service';
import { RadioButtonGroupComponent } from 'shared/material-wrappers';
import { RadioValuesComponent } from 'shared/radio-values/radio-values.component';
import { CustomPromptComponent } from 'shared/custom-prompt/custom-prompt.component';
import { PromptWrapperComponent } from 'shared/prompt-wrapper/prompt-wrapper.component';

@Component({
  selector: 'ads-mobile-sorting',
  standalone: true,
  imports: [
    CustomPromptComponent,
    RadioButtonGroupComponent,
    MatMenuModule,
    PromptWrapperComponent,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    RadioValuesComponent,
  ],
  templateUrl: './mobile-sorting.component.html',
  styleUrl: './mobile-sorting.component.scss',
})
export class MobileSortingComponent implements AfterViewInit {
  @Input() isDesktopView = false;
  @Output() menuOpened = new EventEmitter<void>();
  @Output() menuDismissed = new EventEmitter<void>();
  @Output() sortChanged = new EventEmitter<void>();

  @ViewChild('mobilePrompt') mobilePrompt: CustomPromptComponent | null = null;

  constructor(public service: ServiceCallsService) {}

  ngAfterViewInit(): void {
    this.service.mobilePrompt = this.mobilePrompt;
  }

  sortConfirmed() {
    this.service.onConfirmSort();
    this.sortChanged.emit();
  }

  onSortingAscClicked(_: { column: string; asc: boolean; sortingLabel: string }) {
    const { column, asc, sortingLabel } = _;
    this.service.setSort(column, asc, sortingLabel);
  }
}
