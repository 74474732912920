import { SelectionModel } from "@angular/cdk/collections";
import { Injectable } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { EquipmentEntityModel } from "models/equipment/equipment";

@Injectable({
  providedIn: 'root',
})
export class ReviewEquipmentService {
  allEquipment = new MatTableDataSource<EquipmentEntityModel>([]);
  selectedEquipment = new MatTableDataSource<EquipmentEntityModel>([]);
  toAdd  = new SelectionModel<EquipmentEntityModel>(true, []);
  equipmentNotShown = false;
  constructor() {}

  confirmAdd() {
    this.selectedEquipment.data = [...this.toAdd.selected];
  }
}
