import { Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { ApiService, AuthService, PhotoUploadService } from 'services';
import { HttpParams } from '@angular/common/http';
import { TcdTcrResponse } from 'models/api/tcd-tcr/tcd-tcr-response';
import { TcdTcrPhotoUploadKey } from 'app/utils/tcd-tcr-form-constants';
import { TcdTcrForm } from 'models/api/tcd-tcr/tcd-tcr-form';
import { tcdTcrMapRequest } from 'models/api/maps/tcd-tcr/tcd-tcr-map-request';
import { FormPageService, PageRequest } from 'pages/form-page/form-page-service';
import { TcdTcrFormSection } from './tcd-tcr.models';
import { FormGroup } from '@angular/forms';
import { mapTcdTcrSection } from 'models/api/maps/tcd-tcr/tcd-tcr-map';

@Injectable({
  providedIn: 'root',
})
export class TcdTcrService implements FormPageService<TcdTcrForm, TcdTcrResponse> {
  public form: TcdTcrForm = {
    tcdTcrSections: [],
    tcdTcrCassetteTasks: null,
    tcdTcrTasks: null,
    tcdTcrFeedModuleTasks: null,
    tcdTcrInputModuleTasks: null,
    tcdTcrExternalAreaTasks: null,
    tcdTcrVacuumingBlowOut: null,
    comments: '',
  };
  photoUploadForms: FormGroup[] = [];
  workOrder = '';
  tcdTcrSections: TcdTcrFormSection[] = [];

  allQtyOptions: {
    value: number;
    type: 'TCD' | 'TCR';
  }[] = [];

  constructor(
    private readonly service: ApiService,
    private readonly authService: AuthService,
    private readonly photoUploadService: PhotoUploadService
  ) {}

  updateForm(data: TcdTcrForm) {
    this.form = data;
  }

  public getForm(): Observable<TcdTcrResponse> {
    const params = new HttpParams({
      fromObject: { userId: this.authService.user?.personnelNumber ?? '' },
    });
    return this.service.sendRequest<TcdTcrResponse>({
      method: 'get',
      url: `pmforms/tcdtcr/${this.workOrder}`,
      params,
      urlPrefix: 'ccc',
    });
  }

  public loadPhotos = (data: TcdTcrFormSection): Observable<Record<TcdTcrPhotoUploadKey, string>> => {
    const sources = [
      data.photoFront,
      data.photoCoinDispenser,
      data.photoExternalDevices,
      data.photoSerialNumber,
      data.photoAdditionalPhoto,
    ].map((url) => {
      if (!url) {
        return Promise.resolve({ sasUrl: '' });
      }
      return this.photoUploadService.getPhotoUrl(url);
    });

    return forkJoin(sources).pipe(
      map(([front, coinDispenser, externalServices, seriaNumber, photoAdditionalPhoto]) => ({
        photoFront: front.sasUrl,
        photoCoinDispenser: coinDispenser.sasUrl,
        photoExternalDevices: externalServices.sasUrl,
        photoSerialNumber: seriaNumber.sasUrl,
        photoAdditionalPhoto: photoAdditionalPhoto.sasUrl,
      })),
      catchError(() =>
        of({
          photoFront: '',
          photoCoinDispenser: '',
          photoExternalDevices: '',
          photoSerialNumber: '',
          photoAdditionalPhoto: '',
        })
      )
    );
  };

  public saveForm(request: PageRequest<TcdTcrForm>): Observable<string> {
    const saveForm = tcdTcrMapRequest({
      photosForm: this.photoUploadForms.map((form) => form.value),
      fieldsForm: this.form,
      comments: request.comments,
      workOrderDetail: request.workOrderDetail,
      pageSubmitMode: request.pageSubmitMode,
      pageOpenMode: request.pageOpenMode,
      tcdTcrSections: this.tcdTcrSections,
    });
    return this.service.sendRequest<string>({
      method: 'post',
      url: 'pmforms/tcdtcr',
      body: saveForm,
      urlPrefix: 'ccc',
    });
  }

  getPhotos(data: TcdTcrResponse, updateFormPhotos: (photos: Record<TcdTcrPhotoUploadKey, string>) => void) {
    data.tcdTcr.tcdTcr_TcdTcr.forEach((section, index) => {
      this.loadPhotos(mapTcdTcrSection(section, index)).subscribe((photos) => {
        updateFormPhotos(photos);
      });
    });
  }
}
