import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { WorkOrderService } from 'pages/work-order/work-order-service';

@Component({
  selector: 'ads-on-site-by',
  standalone: true,
  imports: [DatePipe],
  templateUrl: './on-site-by.component.html',
  styleUrl: './on-site-by.component.scss',
})
export class OnSiteByComponent {
  constructor(public readonly workorderService: WorkOrderService) {

  }
}
