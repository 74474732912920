import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ads-single-toggle-button',
  standalone: true,
  imports: [CommonModule, MatButtonToggleModule, FormsModule, MatIconModule],
  templateUrl: './single-toggle-button.component.html',
  styleUrl: './single-toggle-button.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SingleToggleButtonComponent,
    },
  ],
})
export class SingleToggleButtonComponent {
  @Input() leftLabel = '';
  @Input() buttonLabel = '';
  @Input() value = false;
  @Input() disabled = false;
  touched = false;

  onChange = () => {};

  onTouched = () => {};

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  propagateChange = (value: boolean) => {
    this.writeValue(value);
  };

  writeValue(newValue: boolean): void {
    this.value = newValue;
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  onModelChange() {
    this.value = !this.value;
    this.propagateChange(this.value);
  }

  onKeyPress(e: Event) {
    const event = e as KeyboardEvent;
    if (event.key == 'Enter') {
      this.onModelChange();
    }
  }
}
