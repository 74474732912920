import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ThemingService } from 'services';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { NavigationRoutesEnum } from 'utils';

@Component({
  selector: 'ads-calls-container',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIcon],
  templateUrl: './calls-container.component.html',
  styleUrl: './calls-container.component.scss',
})
export class CallsContainerComponent implements OnInit{
  @Input() isDesktop = false;
  @Input() newCalls = 0;
  @Input() openCalls = 0;
  currentTheme = '';

  constructor(public readonly layoutNavService: LayoutNavService, public readonly theming: ThemingService) {}

  ngOnInit(): void {
    this.currentTheme = this.theming.theme.name;
    this.theming.watchTheme.subscribe((theme) => {
      this.currentTheme = theme;
    });
  }
  navigateToCallControl() {
    this.layoutNavService.changeTab(NavigationRoutesEnum.CallControl);
  }
}
