import { Component, Inject, inject, ViewChild } from '@angular/core';
import {  NgForm } from '@angular/forms';
import { MatBottomSheet} from '@angular/material/bottom-sheet';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface AddManyModalComponentProps {
  dropdowns: unknown;
}

@Component({
  template: '',
})
export abstract class BaseAddManyModalComponent {
  @ViewChild('form', { static: true }) form?: NgForm;
  readonly dialogRef = inject(MatDialogRef<BaseAddManyModalComponent>);
  protected readonly _bottomSheet = inject(MatBottomSheet);

  constructor(@Inject(MAT_DIALOG_DATA) protected readonly data: AddManyModalComponentProps) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  abstract openBottomSheet: (element: unknown) => () => void;

  onCancel = () => {
    this.dialogRef.close();
  };

  onAccept = (dataSource: unknown[]) => {
    this.dialogRef.close(dataSource[0]);
  };
}
