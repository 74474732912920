export function debounce(delay: number) {
  return (target: unknown, propertyKey: string, descriptor: PropertyDescriptor) => {
    let timer: ReturnType<typeof setTimeout>;
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: unknown[]) {
      clearTimeout(timer);

      timer = setTimeout(() => {
        originalMethod.apply(this, args);
      }, delay);
    };
    return descriptor;
  };
}
