import { IndexableFormType } from 'app/utils/miscTypes';

export interface TimeClock {
  timeClockModel: string | null;
  timeClockType: string | null;
  timeClockManufacturer: string | null;
  quantityOfMovements: string | null;
  isLinkageConnectedDirectlyToCombolock: number | null;
}

export class TimeClockSection implements IndexableFormType, TimeClock {
  index: number;
  isLinkageConnectedDirectlyToCombolock: number | null;
  quantityOfMovements: string | null;
  timeClockManufacturer: string | null;
  timeClockModel: string | null;
  timeClockType: string | null;

  constructor(index: number) {
    this.index = index;
    this.isLinkageConnectedDirectlyToCombolock = null;
    this.quantityOfMovements = '';
    this.timeClockManufacturer = '';
    this.timeClockModel = '';
    this.timeClockType = '';
  }
}
