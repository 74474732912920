<ng-container *ngIf="featureManager.pwaEmbeddedMenu$ | async; else hide_menu">
  <ads-header></ads-header>

  <mat-sidenav-container autosize>
    <mat-sidenav
      #sidenav
      mode="side"
      [opened]="layoutNavService.sideNavOpened"
      class="sidenav"
      *ngIf="layoutNavService.isDesktopView()"
    >
      <div class="header-space"></div>
      <ads-desktop-nav></ads-desktop-nav>
    </mat-sidenav>

    <mat-sidenav-content
      [class.mat-drawer-content-closed]="!layoutNavService.sideNavOpened && layoutNavService.isDesktopView()"
    >
      <div [class.hidden]="!layoutNavService.pwaRoute" style="width: 100%">
        <router-outlet></router-outlet>
      </div>

      <div [class.hidden]="layoutNavService.pwaRoute" class="iframe-container">
        <div
          [class.header-space]="layoutNavService.isDesktopView()"
          [class.header-space-mobile]="!layoutNavService.isDesktopView()"
        ></div>
        <ads-iframe-wrapper></ads-iframe-wrapper>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <mat-tab-group
    headerPosition="below"
    animationDuration="0ms"
    disablePagination
    preserveContent
    class="layout-nav-tabs"
    (selectedTabChange)="layoutNavService.onTabChanged($event)"
    [(selectedIndex)]="layoutNavService.selectedIndex"
    *ngIf="!layoutNavService.isDesktopView() && !shouldHideNav()"
    [class.margin-ios]="layoutNavService.isMobileIOS()"
  >
    <mat-tab>
      <ng-template mat-tab-label class="tab-label">
        <mat-icon class="material-icons-outlined">home</mat-icon>
        Home
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label class="tab-label">
        <mat-icon class="material-icons-outlined">call</mat-icon>
        Calls
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label class="tab-label">
        <mat-icon class="material-icons-outlined">reorder</mat-icon>
        Lines
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label class="tab-label">
        <mat-icon class="material-icons-outlined">description</mat-icon>
        Notes
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label class="tab-label">
        <button class="more-button" (click)="goBackMore()">
          <mat-icon class="material-icons-outlined">more_vert</mat-icon>
          More
        </button>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ng-container>

<ng-template #hide_menu>
  <div [class.hidden]="!layoutNavService.pwaRoute" style="width: 100%">
    <router-outlet></router-outlet>
  </div>

  <div [class.hidden]="layoutNavService.pwaRoute" class="iframe-container">
    <ads-iframe-wrapper></ads-iframe-wrapper>
  </div>
</ng-template>
