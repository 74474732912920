import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { RoutesEnum } from "app/utils/navigation";
import { WorkOrderService } from "pages/work-order/work-order-service";
import { firstValueFrom } from "rxjs";
import { HeaderService } from "shared/header/header.service";

export class CallControlPage {
  constructor(
    protected readonly headerService: HeaderService,
    protected readonly location: Location,
    protected readonly workOrderService: WorkOrderService,
    protected readonly route: ActivatedRoute,
    protected readonly router: Router
  ) {
    this.headerService.backButtonOverride = (): void => {
      if (window.history.state.navigationId > 1) {
        this.location.back();
      } else {
        this.router.navigate([RoutesEnum.CallControl]);
      }
    };
  }

  init = async (workOrderCallback?: () => void) => {
    const paramMap = await firstValueFrom(this.route.paramMap);
    this.workOrderService.workOrder = paramMap.get('work-order') ?? '';
    this.workOrderService.init(workOrderCallback);
  };

}
