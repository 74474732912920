<ads-simple-form #surveillance="ngModelGroup" [ngModelGroup]="'surveillance'">
  <ng-container ngModelGroup="dvrNvrItems">
    <ads-nested-multiple-forms-section
      #dvrNvrSectionsPanels
      title="DVR/NVR"
      [minItems]="0"
      [formTemplate]="formTemplateDVR"
      [list]="security.surveillanceData.dvrNvrSections"
      [formControls]="getFormControls('dvrNvrItems')"
      [groupName]="'dvrNvrItem'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddClick('dvrNvrSections')"
      (deleteClicked)="onRemoveSection($event, 'dvrNvrSections')"
    >
    </ads-nested-multiple-forms-section>
    <ng-template #formTemplateDVR let-item="item">
      <ads-surveillance-dvr-nvr-section
        [section]="getItemSection('dvrNvrItem#' + item.index, 'dvrNvrItems')"
        [item]="item"
        [dvrNvrItems]="surveillanceDropdowns"
      ></ads-surveillance-dvr-nvr-section>
    </ng-template>
  </ng-container>

  <ng-container ngModelGroup="upsItems">
    <ads-nested-table-forms-section
      #upsSectionsPanels
      title="UPS"
      [minItems]="0"
      [formTemplate]="formTemplateUPS"
      [list]="security.surveillanceData.upsSections"
      [formControls]="getFormControls('upsItems')"
      [groupName]="'upsItem'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddManyUpssClick()"
      (deleteClicked)="onRemoveSection($event, 'upsSections')"
    >
    </ads-nested-table-forms-section>
    <ng-template
      #formTemplateUPS
      let-markedForDeletion="deleting"
      let-list="list"
      let-onDeleteChecked="onDeleteChecked"
    >
      <ads-surveillance-ups-section
        [list]="list"
        [dropdowns]="surveillanceDropdowns"
        [addNew]="addUPSTableSection"
        [markedForDeletion]="markedForDeletion"
        [onDeleteChecked]="onDeleteChecked"
      ></ads-surveillance-ups-section>
    </ng-template>
  </ng-container>

  <ng-container ngModelGroup="poeSwitchItems">
    <ads-nested-table-forms-section
      #poeSwitchSectionsPanels
      title="POE Switch"
      [minItems]="0"
      [formTemplate]="formTemplatePOE"
      [list]="security.surveillanceData.poeSwitchSections"
      [formControls]="getFormControls('poeSwitchItems')"
      [groupName]="'poeSwitchItem'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddManyPOEsClick()"
      (deleteClicked)="onRemoveSection($event, 'poeSwitchSections')"
    >
    </ads-nested-table-forms-section>
    <ng-template
      #formTemplatePOE
      let-markedForDeletion="deleting"
      let-list="list"
      let-onDeleteChecked="onDeleteChecked"
    >
      <ads-surveillance-poe-switch-section
        [list]="list"
        [dropdowns]="surveillanceDropdowns"
        [addNew]="addPOETableSection"
        [markedForDeletion]="markedForDeletion"
        [onDeleteChecked]="onDeleteChecked"
      ></ads-surveillance-poe-switch-section>
    </ng-template>
  </ng-container>

  <ng-container ngModelGroup="cameraTableItems">
    <ads-nested-table-forms-section
      #cameraTableSectionsPanels
      title="Camera Table"
      [formTemplate]="formTemplateCameraTable"
      [list]="security.surveillanceData.cameraTableSections"
      [formControls]="getFormControls('cameraTableItems')"
      [groupName]="'cameraTableItem'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddManyCamerasClick()"
      (deleteClicked)="onRemoveSection($event, 'cameraTableSections')"
      itemTitle="Camera"
    >
      <div>
        <div class="badge-container" *ngIf="security.surveillanceData.cameraTableSections.length > 0">
          <ads-badge-text text="IP" [value]="'' + getAnalogIpCameras()[1]"></ads-badge-text>
          <ads-badge-text text="Analog" [value]="'' + getAnalogIpCameras()[0]"></ads-badge-text>
        </div>
      </div>
    </ads-nested-table-forms-section>
    <ng-template
      #formTemplateCameraTable
      let-markedForDeletion="deleting"
      let-list="list"
      let-onDeleteChecked="onDeleteChecked"
    >
      <ads-surveillance-camera-table-section
        [list]="list"
        [dropdowns]="surveillanceDropdowns"
        [addNew]="addCameraTableSection"
        [markedForDeletion]="markedForDeletion"
        [onDeleteChecked]="onDeleteChecked"
      ></ads-surveillance-camera-table-section>
      <div class="overall-container">
        <ads-select
          label="Overall Condition - Cameras"
          name="overallConditionCameras"
          [list]="surveillanceDropdowns.surveillanceCameratableOverallConditionCameras"
          [value]="security.surveillanceData.overallConditionCameras"
          [(ngModel)]="security.surveillanceData.overallConditionCameras"
        ></ads-select>
        <ads-common-text-area
          [style.display]="displayOtherCondition('overallConditionCameras') ? 'block' : 'none'"
          name="equipmentRatingCameras"
          label="Substandard equipment comments"
          [maxLength]="500"
          [required]="displayOtherCondition('overallConditionCameras')"
          [value]="security.surveillanceData.equipmentRatingCameras"
          [(ngModel)]="security.surveillanceData.equipmentRatingCameras"
        >
        </ads-common-text-area>
      </div>
    </ng-template>
  </ng-container>

  <ng-container ngModelGroup="monitorTableItems">
    <ads-nested-table-forms-section
      #monitorTableSectionsPanels
      title="Monitor Table"
      [minItems]="0"
      [formTemplate]="formTemplateMonitorTable"
      [list]="security.surveillanceData.monitorTableSections"
      [formControls]="getFormControls('monitorTableItems')"
      [groupName]="'monitorTableItem'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddManyMonitorsClick()"
      (deleteClicked)="onRemoveSection($event, 'monitorTableSections')"
      itemTitle="Monitor"
    >
      <div>
        <div class="badge-container" *ngIf="security.surveillanceData.monitorTableSections.length > 0">
          <ads-badge-text text="Monitors" [value]="'' + getMonitorCount()"></ads-badge-text>
        </div>
      </div>
    </ads-nested-table-forms-section>
    <ng-template
      #formTemplateMonitorTable
      let-markedForDeletion="deleting"
      let-list="list"
      let-onDeleteChecked="onDeleteChecked"
    >
      <ads-surveillance-monitor-table-section
        [list]="list"
        [dropdowns]="surveillanceDropdowns"
        [addNew]="addMonitorTableSection"
        [markedForDeletion]="markedForDeletion"
        [onDeleteChecked]="onDeleteChecked"
      ></ads-surveillance-monitor-table-section>
      <div class="overall-container">
        <ads-select
          label="Overall Condition - Monitors"
          name="overallConditionMonitors"
          [list]="surveillanceDropdowns.surveillanceMonitorTableOverallConditionMonitors"
          [value]="security.surveillanceData.overallConditionMonitors"
          [(ngModel)]="security.surveillanceData.overallConditionMonitors"
        ></ads-select>
        <ads-common-text-area
          [style.display]="displayOtherCondition('overallConditionMonitors') ? 'block' : 'none'"
          name="equipmentRatingMonitors"
          label="Substandard equipment comments"
          [maxLength]="500"
          [required]="displayOtherCondition('overallConditionMonitors')"
          [value]="security.surveillanceData.equipmentRatingMonitors"
          [(ngModel)]="security.surveillanceData.equipmentRatingMonitors"
        >
        </ads-common-text-area>
      </div>
    </ng-template>
  </ng-container>

  <ads-expansion-panel
    title="DVR Tasks"
    [formGroup]="formGroup"
    [name]="'surveillanceDvrTasks'"
    [class]="'surveillanceDvrTasks'"
  >
    <ads-surveillance-dvr-tasks-section
      [section]="getTasksSection('surveillanceDvrTasks')"
    ></ads-surveillance-dvr-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    title="Camera Tasks"
    [formGroup]="formGroup"
    [name]="'surveillanceCameraTasks'"
    [class]="'surveillanceCameraTasks'"
  >
    <ads-surveillance-camera-tasks-section
      [section]="getTasksSection('surveillanceCameraTasks')"
    ></ads-surveillance-camera-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    title="Monitor Tasks"
    [formGroup]="formGroup"
    [name]="'surveillanceMonitorTasks'"
    [class]="'surveillanceMonitorTasks'"
  >
    <ads-surveillance-monitor-tasks-section
      [section]="getTasksSection('surveillanceMonitorTasks')"
    ></ads-surveillance-monitor-tasks-section>
  </ads-expansion-panel>

  <ads-common-text-area name="comments" label="Comments (optional)" [maxLength]="200" ngModel></ads-common-text-area>
</ads-simple-form>
