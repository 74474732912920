<ads-simple-form #itmReceiptPrinterTasks="ngModelGroup" ngModelGroup="itmReceiptPrinterTasks">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button
  ><mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Remove all paper dust from print area"
    name="removeAllPaperDust"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Open and clean engine and cutter"
    name="openAndCleanEngine"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Check and clean all belts"
    name="checkAndCleanAllBelts"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Check and clean all rollers"
    name="checkAndCleanAllRollers"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Blow out sensor areas"
    name="blowOutSensorAreas"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Blow out cutter area"
    name="blowOutCutterArea"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Perform diagnostic test"
    name="performDiagnosticTest"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
