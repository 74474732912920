import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { CustomButtonComponent } from "shared/custom-button/custom-button.component";

@Component({
  selector: 'ads-notify-out-of-sync',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatTabsModule,
    MatCheckboxModule,
    CustomButtonComponent
],
  templateUrl: './notify-out-of-sync.component.html',
  styleUrl: './notify-out-of-sync.component.scss',
  providers:[
    {
      provide: Window,
      useValue: window
    }
  ]
})
export class NotifyOutOfSyncComponent {
constructor(public worskOrderService: WorkOrderService, @Inject(Window) private readonly window: Window) {}

  refreshPage = () => this.window.location.reload();

}
