import { FormGroup } from '@angular/forms';
import { YesNoNa } from '../models/shared/yes-no-na';

export const checkAllOptions = (shouldSelectAllNa: boolean, fields: string[], section?: FormGroup) => {
  if (section) {
    if (shouldSelectAllNa) {
      // Set N/A on all buttons when SelectAll button is checked
      fields.forEach((field) => {
        if (field !== 'allNa') {
          section?.controls[field]?.setValue(YesNoNa.Na);
        }
      });
    } else {
      // If check is removed, clear them all
      const customFields = fields.filter((field) => field !== 'allNa');
      const areAll = areAllNa(section, customFields);
      if (areAll) {
        fields.forEach((field) => {
          if (field !== 'allNa') {
            section?.controls[field]?.setValue(undefined);
            section?.controls['allNa'].setValue(undefined);
            section?.controls[field].markAsTouched();
          }
        });
      }
    }
  }
};

export const checkIfAllAreNa = (fields: string[], section?: FormGroup) => {
  if (section) {
    const customFields = fields.filter((field) => field !== 'allNa');
    const allAreNa = areAllNa(section, customFields);
    if (allAreNa && !section?.controls['allNa'].value) {
      section?.controls['allNa'].setValue(true);
    } else {
      section?.controls['allNa'].setValue(false);
    }
  }
};

export const areAllNa = (section: FormGroup, fields: string[]) => {
  return fields
    .map((field) => section?.controls[field]?.value === YesNoNa.Na)
    .reduce((partial, newField) => partial && newField, true);
};
