<div class="button-wrapper main-action" *ngIf="!submitted">
  <button mat-button mat-flat-button class="form-button main-button" (click)="onAddClick($event)">
    <mat-icon>add</mat-icon>
  </button>
</div>
<mat-expansion-panel
  class="expansion-panel"
  [expanded]="expanded"
  (opened)="expanded = true"
  (closed)="expanded = false"
  [hideToggle]="list.length === 0"
>
  <mat-expansion-panel-header class="expansion-panel-header wrapping-panel" style="background-color: transparent !important;">
    <mat-panel-title>{{ title }}</mat-panel-title>
  </mat-expansion-panel-header>
  <ng-content></ng-content>
  <div *ngIf="formTemplate">
    <ng-container *ngFor="let item of list">
      <div class="button-wrapper nested-action" *ngIf="getShowDeleteButton()">
        <button mat-button mat-flat-button class="form-button main-button delete" (click)="onDeleteClick($event, item)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <ads-expansion-panel
        [expanded]="expandedList.includes(item.index)"
        [title]="(itemTitle ?? title) + ': ' + item.index"
        [showDeleteButton]="getShowDeleteButton()"
        [formGroup]="getFormGroup(item.index)"
        [name]="groupName + '#' + item.index"
        [class]="groupName + '#' + item.index"
      >
        <ng-container *ngTemplateOutlet="formTemplate; context: { item }"></ng-container>
      </ads-expansion-panel>
    </ng-container>
  </div>
</mat-expansion-panel>
