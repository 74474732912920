import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { fileContentType } from 'app/utils/data-uri-to-blob';
import { SelectOption } from 'models';
import { AdsAttachmentUpload } from 'models/call-control/ads-call-control';
import { CommonFormInputComponent, RadioButtonGroupComponent } from 'shared/material-wrappers';
import { ModalActionsComponent } from 'shared/modal-actions/modal-actions.component';
import { WorkOrderService } from '../work-order-service';

@Component({
  selector: 'ads-attachment-add-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    FormsModule,
    CommonFormInputComponent,
    RadioButtonGroupComponent,
    ModalActionsComponent,
  ],
  templateUrl: './attachment-add-dialog.component.html',
  styleUrl: './attachment-add-dialog.component.scss',
})
export class AttachmentAddDialogComponent {
  name = '';
  restriction = '';
  loading = false;

  public readonly dialogRef = inject(MatDialog);

  constructor(private readonly workOrderService: WorkOrderService) {}

  selectedFile: File | null = null;

  restrictionOptions: SelectOption<string>[] = [
    { name: 'Internal', value: 'Internal' },
    { name: 'External', value: 'External' },
  ];

  onFileSelected(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;

    this.selectedFile = element.files?.[0] ?? null;
  }

  onConfirm() {
    const fileExtension = [...fileContentType.entries()]
      .filter(({ 1: v }) => v === this.selectedFile?.type)
      .map(([k]) => k);

    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile as Blob);
    reader.onload = () => {
      const readerresult = reader.result?.toString();
      const base64 = readerresult?.split(',')[1];

      const attach: AdsAttachmentUpload = {
        name: this.name,
        internal: this.restriction === 'Internal',
        external: this.restriction === 'External',
        extension: fileExtension[0],
        base64: base64,
        recId: this.workOrderService.workOrderDetails().recid,
      };

      this.loading = true;

      this.workOrderService.uploadAttachmentFile(attach).subscribe(() => {
        this.loading = false;
        this.workOrderService.setAttachments();
        this.dialogRef.closeAll();
      });
    };
  }

  onCancel() {
    this.dialogRef.closeAll();
  }
}
