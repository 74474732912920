<ads-simple-form *ngIf="item" class="access-hardware-section-wrapper" [ngModelGroup]="'accessControl#' + item.index">
  <ads-select
    label="System Manufacturer"
    name="systemManufacturer"
    [list]="lists?.acacSystemManufacturers"
    [value]="item.systemManufacturer"
    [(ngModel)]="item.systemManufacturer"
  ></ads-select>
  <ads-common-form-input
    [style.display]="displayOther('systemManufacturer') ? 'block' : 'none'"
    name="systemManufacturer_Other"
    label="Other Manufacturer"
    [maxLength]="50"
    [required]="displayOther('systemManufacturer')"
    [value]="item.systemManufacturer_Other"
    [(ngModel)]="item.systemManufacturer_Other"
  ></ads-common-form-input>
  <ads-select
    label="Control Panel Model"
    name="controlPanelModel"
    [list]="getControlPanelValues()"
    [value]="item.controlPanelModel"
    [(ngModel)]="item.controlPanelModel"
  ></ads-select>
  <ads-common-form-input
    [style.display]="displayOther('controlPanelModel') ? 'block' : 'none'"
    name="controlPanelModel_Other"
    label="Other Control Panel Model"
    [maxLength]="50"
    [required]="displayOther('controlPanelModel')"
    [value]="item.controlPanelModel_Other"
    [(ngModel)]="item.controlPanelModel_Other"
  ></ads-common-form-input>
  <ads-common-form-input
    name="location"
    label="Location"
    [maxLength]="50"
    [required]="true"
    [value]="item.location"
    [(ngModel)]="item.location"
  ></ads-common-form-input>
  <ads-common-form-input
    name="ipAddress"
    label="IP Address"
    [maxLength]="50"
    [required]="true"
    [value]="item.ipAddress"
    [(ngModel)]="item.ipAddress"
  ></ads-common-form-input>
  <ads-select
    label="Overall condition - Access Control System"
    name="overallConditionAccessControlsystem"
    [list]="lists?.acacOverallConditions"
    [value]="item.overallConditionAccessControlsystem"
    [(ngModel)]="item.overallConditionAccessControlsystem"
  ></ads-select>
  <ads-common-text-area
    [style.display]="displayOtherCondition() ? 'block' : 'none'"
    name="equipmentRatingAccessControlsystem"
    label="Substandard equipment comments"
    [maxLength]="500"
    [required]="displayOtherCondition()"
    [value]="item.equipmentRatingAccessControlsystem"
    [(ngModel)]="item.equipmentRatingAccessControlsystem"
  ></ads-common-text-area>
</ads-simple-form>
