import { AfterViewInit, Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormsModule, NgForm } from '@angular/forms';
import { AtmResponse, AtmResponseDropdowns, mapAtmDropdowns, mapAtmForm } from 'models/api';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { AtmService } from './atm-pm.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AtmDropdowns, AtmForm } from 'models';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { OrderInfoComponent } from 'shared/order-info/order-info.component';
import {
  AtmCardReaderTasksSectionComponent,
  AtmCashDepositoryTasksSectionComponent,
  AtmCashDispenserTasksSectionComponent,
  AtmCheckDepositoryTasksSectionComponent,
  AtmEnvDepDispTasksSectionComponent,
  AtmFasciaAdaTasksSectionComponent,
  AtmReceiptPrinterTasksSectionComponent,
  AtmSoftwareSectionComponent,
  AtmSpecsSectionComponent,
  AtmTasksSectionComponent,
  AtmVacuumBlowOutTasksSectionComponent,
  PhotosSectionComponent,
} from 'forms';
import { atmPhotoUploads } from 'utils';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { FormPageComponent } from 'pages/form-page/form-page';
import { LoaderService, PhotoUploadControlService } from 'services';
import { HeaderService } from 'shared/header/header.service';
import { StickyHeaderService } from 'shared/sticky-header/sticky-header.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ads-atm-pm',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    ExpansionPanelComponent,
    AtmSpecsSectionComponent,
    AtmSoftwareSectionComponent,
    AtmTasksSectionComponent,
    AtmCardReaderTasksSectionComponent,
    AtmEnvDepDispTasksSectionComponent,
    AtmReceiptPrinterTasksSectionComponent,
    AtmCashDispenserTasksSectionComponent,
    AtmFasciaAdaTasksSectionComponent,
    AtmCashDepositoryTasksSectionComponent,
    AtmCheckDepositoryTasksSectionComponent,
    AtmVacuumBlowOutTasksSectionComponent,
    PhotosSectionComponent,
    FormsModule,
    OrderInfoComponent,
    CommonTextAreaComponent,
  ],
  providers: [PhotoUploadControlService],
  templateUrl: './atm-pm.component.html',
  styleUrl: './atm-pm.component.scss',
})
export class AtmPmComponent
  extends FormPageComponent<AtmService, AtmForm, AtmResponse, AtmDropdowns, AtmResponseDropdowns>
  implements AfterViewInit, OnDestroy, OnInit
{
  @ViewChild('atmForm', { static: true }) override form?: NgForm;
  @ViewChild('atmSpecsSectionPanel', { static: true }) atmSpecs?: ExpansionPanelComponent;
  @ViewChild('atmSoftwareSectionPanel', { static: true }) software?: ExpansionPanelComponent;
  @ViewChild('atmTasksSectionPanel', { static: true }) atmTasks?: ExpansionPanelComponent;
  @ViewChild('atmCardReaderTasksSectionPanel', { static: true }) cardReaderTasks?: ExpansionPanelComponent;
  @ViewChild('atmEnvDepDispTasksSectionPanel', { static: true }) envDepDispTasks?: ExpansionPanelComponent;
  @ViewChild('atmReceiptPrinterTasksSectionPanel', { static: true }) receiptPrinterTasks?: ExpansionPanelComponent;
  @ViewChild('atmCashDispenserTasksSectionPanel', { static: true }) cashDispenserTasks?: ExpansionPanelComponent;
  @ViewChild('atmFasciaAdaTasksSectionPanel', { static: true }) fasciaAdaTasks?: ExpansionPanelComponent;
  @ViewChild('atmCashDepositoryTasksSectionPanel', { static: true }) cashDepositoryTasks?: ExpansionPanelComponent;
  @ViewChild('atmCheckDepositoryTasksSectionPanel', { static: true }) checkDepositoryTasks?: ExpansionPanelComponent;
  @ViewChild('atmVacuumBlowOutTasksTasksSectionPanel', { static: true }) vacuumBlowOutTasks?: ExpansionPanelComponent;
  @ViewChild('atmPhotosSectionPanel', { static: true }) photos?: ExpansionPanelComponent;

  override mapForm = mapAtmForm;
  override mapDropdowns = mapAtmDropdowns;
  override photoUploads = atmPhotoUploads;
  override sectionNames = [
    'atmSpecs',
    'software',
    'atmTasks',
    'cardReaderTasks',
    'envDepDispTasks',
    'receiptPrinterTasks',
    'cashDispenserTasks',
    'fasciaAdaTasks',
    'cashDepositoryTasks',
    'checkDepositoryTasks',
    'vacuumBlowOutTasks',
    'photoUploads',
  ];
  override sections = {};

  constructor(
    private readonly atmService: AtmService,
    headerService: HeaderService,
    router: Router,
    stickyHeaderService: StickyHeaderService,
    loaderService: LoaderService,
    route: ActivatedRoute,
    snackBar: MatSnackBar,
    private readonly pucs: PhotoUploadControlService
  ) {
    super(atmService, headerService, router, stickyHeaderService, loaderService, route, snackBar);
  }

  ngOnDestroy(): void {
    this.stickyHeaderService.enabled = false;
  }

  ngAfterViewInit() {
    this.form?.control.valueChanges.subscribe((values) => (this.atmService.form = values));
    this.headerService.resetForm = this.resetForm;
    this.headerService.title.set('ATM Form');
    setTimeout(() => this.setDefault());
  }

  ngOnInit() {
    this.photoUploadForm = this.pucs.toFormGroup(atmPhotoUploads);
    this.form?.control.addControl('photoUploads', this.photoUploadForm);
    this.sections = {
      atmSpecs: this.atmSpecs,
      software: this.software,
      atmTasks: this.atmTasks,
      cardReaderTasks: this.cardReaderTasks,
      envDepDispTasks: this.envDepDispTasks,
      receiptPrinterTasks: this.receiptPrinterTasks,
      cashDispenserTasks: this.cashDispenserTasks,
      fasciaAdaTasks: this.fasciaAdaTasks,
      cashDepositoryTasks: this.cashDepositoryTasks,
      checkDepositoryTasks: this.checkDepositoryTasks,
      vacuumBlowOutTasks: this.vacuumBlowOutTasks,
      photoUploads: this.photos,
    };
  }

  getSection(sectionName: string) {
    return this.form?.controls[sectionName] as FormGroup | undefined;
  }
}
