<div class="title-container">
  <span class="title">Add attachment</span>
</div>

<div class="content-container">
  <ads-common-form-input label="Name" [maxLength]="50" [required]="true" [(ngModel)]="name"></ads-common-form-input>

  <ads-radio-button-group
    label="Restriction"
    [options]="restrictionOptions"
    [(ngModel)]="restriction"
  ></ads-radio-button-group>

  <div class="upload-file">
    <input
      #imgInput
      type="file"
      accept="image/*"
      hidden
      (change)="onFileSelected($event)"
      required
      [accept]="
        'image/jpg, image/jpeg, image/png, image/bmp, image/gif, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain, application/vnd.ms-outlook, application/pdf'
      "
    />
    <button type="button" mat-button mat-stroked-button class="icon-button secondary-button" (click)="imgInput.click()">
      <mat-icon>folder_open</mat-icon>
      Upload File
    </button>
    <span class="file-name">{{ selectedFile?.name ?? '*required' }}</span>
  </div>

  <ads-modal-actions
    (cancel)="onCancel()"
    (confirm)="onConfirm()"
    [disableConfirm]="!restriction || !name || !selectedFile || loading"
  ></ads-modal-actions>
</div>
