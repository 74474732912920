import { AuthService } from 'services';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { RoutesEnum } from 'app/utils/navigation';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { CallStatusId } from 'models/call-control/ack-schedule-models';
import { CustomButtonComponent } from 'shared/custom-button/custom-button.component';
import { TimepickerComponent } from 'shared/material-wrappers/timepicker/timepicker.component';
import { PerformRounding } from 'app/utils/dates';
import { OperationResponse } from 'models/call-control/command-response';
import { CallControlService } from 'pages/call-control/call-control.service';
import { LoaderService } from 'shared/loader/loader.service';

@Component({
  selector: 'ads-complete-call',
  standalone: true,
  imports: [CommonModule, FormsModule, TimepickerComponent, CustomButtonComponent],
  templateUrl: './complete-call.component.html',
  styleUrl: './complete-call.component.scss',
})
export class CompleteCallComponent implements OnInit {
  public readonly _snackBar = inject(MatSnackBar);
  public readonly _bottomSheet = inject(MatBottomSheet);
  public readonly dialogRef = inject(MatDialog);
  selectedTime = '';
  validTime = true;
  startedDate: Date | null = null;
  completeDate: Date | null = null;
  maxStartDate = new Date();
  datepipe: DatePipe = new DatePipe('en-US');

  constructor(
    public readonly router: Router,
    public readonly authService: AuthService,
    public readonly workOrderService: WorkOrderService,
    public readonly callControlService: CallControlService,
    public readonly loader: LoaderService
  ) {}

  ngOnInit(): void {
    if (this.authService.authenticated) {
      this.workOrderService
        .getStatusTimestamp({ statusToCheck: CallStatusId.Started_50, workOrder: this.workOrderService.workOrder })
        .subscribe((response) => {
          if (response.Date) {
            this.startedDate = new Date(response.Date);
            this.completeDate = new Date(response.Date);
          }
        });
    }
  }

  onConfirm() {
    this.hideAll();
    if (this.completeDate) {
      this.onTimeChange(this.selectedTime);
      if (this.authService.authenticated) {
        this.callControlService
          .updateCallStatus({
            workOrder: this.workOrderService.workOrderDetails().workorder ?? '',
            dateEnter: this.datepipe.transform(new Date(), 'MM/dd/yyyy')?.toString(),
            timeEnter: this.datepipe.transform(new Date(), 'HH:mm:ss')?.toString(),
            status: CallStatusId.Completed_70,
            techIdMaster: this.authService.user?.personnelNumber ?? '',
            userId: this.authService.user?.userId ?? '',
            utcOffset: new Date().getTimezoneOffset() / -60,
          })
          .subscribe({
            next: this.onCompleteSuccess,
            error: this.onCompleteSubmitError,
          });
      }
    }
  }

  onCompleteSubmitError = (error: unknown) => {
    this.loader.hide();
    console.error(error);
    this._snackBar.open(error as string, 'Close');
  };

  onCompleteSuccess = (result: OperationResponse) => {
    this.hideAll();
    this.loader.show();
    if (result.isSuccess) {
      this._snackBar
        .open('Work Order Completed successfully.', 'Close', { duration: 4000 })
        .afterDismissed()
        .subscribe(() => {
          this.onTimeChange(this.selectedTime);
          this.loader.hide();
          if (this.completeDate) {
            this.router.navigate([
              `WO/${RoutesEnum.ReviewEquipment}/${this.workOrderService.workOrder}`,
              {
                time: PerformRounding(this.completeDate),
              },
            ]);
          }
        });
    } else {
      this.onCompleteSubmitError(result.errorMessage);
    }
  };

  hideAll() {
    this._bottomSheet.dismiss();
    this.dialogRef.closeAll();
  }

  onTimeChange(time: string) {
    this.selectedTime = time;
    this.validTime = false;
    if (time) {
      const hours = parseInt(time.split(' ')[0], 10);
      const isPm = time.split(' ')[2] === 'PM';
      this.completeDate?.setHours(isPm ? hours + 12 : hours);
      this.completeDate?.setMinutes(parseInt(time.split(' ')[1], 10));
      this.validTime = (this.completeDate ?? new Date()) > (this.startedDate ?? new Date());
      this.completeDate?.setSeconds(0);
    }
  }
}
