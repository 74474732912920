<ads-simple-form *ngIf="item" class="ucs-section-wrapper" [ngModelGroup]="'ucsItem#' + item.index">
  <ads-select
    label="UCS Manufacturer"
    name="ucsManufacturer"
    [list]="ucsItems?.ucsUcsManufacturers"
    [value]="item.ucsManufacturer"
    [(ngModel)]="item.ucsManufacturer"
  ></ads-select>
  <ads-common-form-input
    [style.display]="displayOtherManufacturer() ? 'block' : 'none'"
    name="ucsManufacturer_Other"
    label="Other Manufacturer"
    [maxLength]="50"
    [required]="displayOtherManufacturer()"
    [value]="item.ucsManufacturer_Other"
    [(ngModel)]="item.ucsManufacturer_Other"
  ></ads-common-form-input>
  <ads-common-form-input
    name="ucsModel"
    label="UCS Model"
    [maxLength]="50"
    [required]="true"
    [value]="item.ucsModel"
    [(ngModel)]="item.ucsModel"
  ></ads-common-form-input>
  <ads-select
    label="Overall condition - UCS"
    name="overallConditionUCS"
    [list]="ucsItems?.ucsUcsOverallConditions"
    [value]="item.overallConditionUCS"
    [(ngModel)]="item.overallConditionUCS"
  ></ads-select>
  <ads-common-text-area
    [style.display]="displayOtherCondition() ? 'block' : 'none'"
    name="overallConditionUCS_Other"
    label="Substandard equipment comments"
    [maxLength]="500"
    [required]="displayOtherCondition()"
    [value]="item.overallConditionUCS_Other"
    [(ngModel)]="item.overallConditionUCS_Other"
  ></ads-common-text-area>
  <ads-common-form-input
    name="quantityComboLocks"
    label="Quantity of combo locks"
    type="number"
    [minValue]="0"
    [maxLength]="8"
    [required]="true"
    [value]="item.quantityComboLocks"
    [(ngModel)]="item.quantityComboLocks"
    inputMode="tel"
  ></ads-common-form-input>
  <ads-common-form-input
    name="quantityOfPortaLocks"
    label="Quantity of Porta locks"
    type="number"
    [minValue]="0"
    [maxLength]="8"
    [required]="true"
    [value]="item.quantityOfPortaLocks"
    [(ngModel)]="item.quantityOfPortaLocks"
    inputMode="tel"
  ></ads-common-form-input>
</ads-simple-form>
