import { YesNo } from '../shared/yes-no-na';

export class AtmSoftware {
  onsite: YesNo | null;
  operatingSystem: string | null;
  operatingSystemOther: string | null;
  softwareVersion: string | null;
  ncrcd2: string | null;

  constructor() {
    this.onsite = null;
    this.operatingSystem = '';
    this.operatingSystemOther = '';
    this.softwareVersion = '';
    this.ncrcd2 = '';
  }
}
