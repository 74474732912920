import { Component, inject, signal } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ThemingService } from 'app/services/theming.service';
import { CapitalizePipe } from '../../utils/capitalize.pipe';
import { StickyHeaderComponent } from '../sticky-header/sticky-header.component';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { HeaderService } from './header.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoaderService } from 'app/services/loader.service';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ResetFormDialogComponent } from '../reset-form-dialog/reset-form-dialog.component';
import { Alert } from 'models/api/home/home-requests';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
import { getRouteEnum } from 'app/app.routes';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { RoutesEnum } from 'app/utils/navigation';

interface EnableHeaderContent {
  showNotifications?: boolean;
  showMore?: boolean;
  showThemeSwitch?: boolean;
  backToMenu?: boolean;
  showExpandButton?: boolean;
}

const getHeaderContent = (currentRoute: RoutesEnum, isDesktop: boolean): EnableHeaderContent => {
  switch (currentRoute) {
    case RoutesEnum.Home:
      return { showNotifications: true, showThemeSwitch: isDesktop, showExpandButton: true };
    case RoutesEnum.More:
    case RoutesEnum.CallControl:
    case RoutesEnum.Calendar:
    case RoutesEnum.Lines:
    case RoutesEnum.Notes:
    case RoutesEnum.History:
    case RoutesEnum.AtmDown:
    case RoutesEnum.Centran:
    case RoutesEnum.MyOpenCalls:
    case RoutesEnum.PartStatus:
    case RoutesEnum.Inventory:
    case RoutesEnum.OtherTechs:
      return { showThemeSwitch: true, backToMenu: !isDesktop, showExpandButton: isDesktop };
    case RoutesEnum.partRequest:
    case RoutesEnum.mobileAcknowledgement:
      return { backToMenu: true };
    case RoutesEnum.AtmCleaning:
    case RoutesEnum.ItmCleaning:
    case RoutesEnum.Submitted:
    case RoutesEnum.Atm:
    case RoutesEnum.Itm:
    case RoutesEnum.TcdTcr:
    case RoutesEnum.CcCd:
    case RoutesEnum.Security:
      return { showMore: true, showThemeSwitch: false };
    default:
      return { showNotifications: false, showMore: false, backToMenu: false };
  }
};

@Component({
  selector: 'ads-header',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    CapitalizePipe,
    StickyHeaderComponent,
    MatProgressBarModule,
    CommonModule,
    RouterLink,
    MatMenuModule,
    MatExpansionModule,
    MatBadgeModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  themeName = '';
  showResetFormButton = this.headerService.showResetFormButton;
  doc = window;
  currentContent = signal(getHeaderContent('' as RoutesEnum, this.headerService.isDesktopView()));

  readonly dialog = inject(MatDialog);
  isLoading: Subject<boolean> = this.loaderService.isLoading;

  alerts: Alert[] = [];
  unreadAlerts: number[] = [];

  constructor(
    private readonly themingService: ThemingService,
    public readonly headerService: HeaderService,
    private readonly loaderService: LoaderService,
    public layoutService: LayoutNavService,
    private readonly router: Router
  ) {
    this.themeName = this.themingService.getOtherTheme().name;

    this.currentContent.set(getHeaderContent(getRouteEnum(this.router.url), this.headerService.isDesktopView()));

    toObservable(this.headerService.isDesktopView).subscribe((isDesktop) => {
      this.currentContent.set(getHeaderContent(getRouteEnum(this.router.url), isDesktop));
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentContent.set(getHeaderContent(getRouteEnum(event.url), this.headerService.isDesktopView()));
      }
    });
  }

  onSwitchThemeClick = () => {
    this.themingService.switchCurrentTheme();
    this.themeName = this.themingService.getOtherTheme().name;
  };

  onResetFormClick = () => {
    this.dialog.open(ResetFormDialogComponent, {
      data: { formName: this.headerService.title, onConfirm: () => this.headerService.resetForm?.() },
    });
  };

  expandCollapseAll = () => {
    this.headerService.sections.forEach((section) => section.setExpanded(!this.headerService.expanded));
    this.headerService.expanded = !this.headerService.expanded;
  };

  goBack = () => {
    window.top?.close();
  };

  goHome = () => {
    this.layoutService.selectedIndex = 0;
    this.layoutService.pwaRoute = true;
    this.router.navigate(['home']);
  };

  readNotification(event: MouseEvent, alertId: number) {
    event.stopPropagation();
    this.unreadAlerts = this.unreadAlerts.filter((id) => id !== alertId);
  }

  onKeyPress(e: Event, alertId: number) {
    const event = e as KeyboardEvent;
    if (event.key == 'Enter') {
      this.unreadAlerts = this.unreadAlerts.filter((id) => id !== alertId);
    }
  }

  deleteNotification(event: MouseEvent, alertId: number) {
    event.stopPropagation();
    this.headerService.deleteAlert(alertId).subscribe(() => {
      this.alerts = this.alerts.filter((alert) => alert.alertId !== alertId);
    });
  }
}
