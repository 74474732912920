import { SecurityVaultsDto } from 'models/api/security/security';
import {
  SecurityVaultComboLocks,
  SecurityVaultComboLockTasksDto,
  SecurityVaultSectionDto,
  SecurityVaultTimeClocks,
  SecurityVaultTimeClockTasksDto,
} from 'models/api/security/vault/vault-dto';
import { ComboLockTasks, TimeClockTasks } from 'models/security/shared/shared-tasks';
import { VaultComboLockSection } from 'models/security/vault/combo-lock';
import { VaultTimeClockSection } from 'models/security/vault/time-clock';
import { VaultSectionForm, VaultSecurityForm } from 'models/security/vault/vaultSecurityForm';

export const mapVaultSections = (panels: VaultSectionForm[]): SecurityVaultSectionDto[] => {
  return panels.map((panel) => ({
    vaultModel: panel.vaultModel,
    doesVaultDoorRemainStationaryAt90Degrees: panel.doorRotationTest,
    vaultManufacturer: panel.manufacturer,
    equipment_Rating_Vault: panel.overallConditionComments,
    equipment_Rating_Ventilator: panel.ventilatorOverallConditionComments,
    ifnoDoesDoorCreepOpenOrClose: panel.creepDoorCloses,
    overallConditionVault: panel.overallCondition,
    overallConditionVentilator: panel.ventilatorOverallCondition,
    security_Vault_ComboLocks: mapVaultComboLocks(Object.values(panel.comboLocks)),
    security_Vault_TimeClocks: mapVaultTimeClocks(Object.values(panel.timeClocks)),
    swingType: panel.swingType,
    creepDoorCloses: panel.doorRotationTest,
    vaultManufacturer_Other: panel.otherManufacturer,
    ventilatorManufacturer: panel.ventilatorManufacturer,
    ventilatorModel: panel.ventilatorModel,
  }));
};

export const mapVaultComboLocks = (list: VaultComboLockSection[]): SecurityVaultComboLocks[] =>
  list.map((item) => ({
    comboLockChangedDetail: item.comboLockChangedDetail,
    comboLockManufacturer: item.comboLockManufacturer,
    comboLockModel: item.comboLockModel,
    comboLockType: item.comboLockType,
    isComboLockChanged: item.isComboLockChanged,
    isThereKeyLockingDial: item.isThereKeyLockingDial,
  }));

export const mapVaultTimeClocks = (list: VaultTimeClockSection[]): SecurityVaultTimeClocks[] =>
  list.map((item) => ({
    isLinkageConnectedDirectlyToComboLock: item.isLinkageConnectedDirectlyToComboLock,
    quantityOfMovements: item.quantityOfMovements,
    timeClockManufacturer: item.timeClockManufacturer,
    timeClockModel: item.timeClockModel,
    timeClockType: item.timeClockType,
  }));

export const mapVaultTimeClockTasksRequest = (tasks?: TimeClockTasks): SecurityVaultTimeClockTasksDto[] => {
  return [
    {
      checkTimeLocksForProperOperation: tasks?.checkTimeLocksForProperOperation ?? null,
      pullRightTimeClockForCleaning: tasks?.pullRightTimeClockForCleaning ?? null,
      shiftAllTimeClocksRight: tasks?.shiftAllTimeClocksRight ?? null,
      replaceLeftTimeClock: tasks?.replaceLeftTimeClock ?? null,
      markAnyReplacedTimeClocksWithDateInstalled: tasks?.markAnyReplacedTimeClocksWithDateInstalled ?? null,
    },
  ];
};

export const mapVaultComboLockTasksRequest = (tasks?: ComboLockTasks): SecurityVaultComboLockTasksDto[] => {
  return [
    {
      checkComboLockForProperOperation: tasks?.checkComboLockForProperOperation ?? null,
      disassembleAndInspectComboLock: tasks?.disassembleAndInspectComboLock ?? null,
      lubricateAndReassembleComboLock: tasks?.lubricateAndReassembleComboLock ?? null,
      checkForDialAndRingProperOperation: tasks?.checkForDialAndRingProperOperation ?? null,
      disassembleAndInspectDialAndRing: tasks?.disassembleAndInspectDialAndRing ?? null,
    },
  ];
};

export const mapVaultRequest = (vault: VaultSecurityForm | null): SecurityVaultsDto => ({
  security_Vault: {
    security_Vault_Vaults: mapVaultSections(vault?.vaultSections ?? []),
    security_Vault_ComboLockTasks: mapVaultComboLockTasksRequest(vault?.comboLockTasks),
    security_Vault_TimeClockTasks: mapVaultTimeClockTasksRequest(vault?.timeClockTasks),
    comments: vault?.comments ?? null,
  },
});
