import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, Optional, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgModel,
  NgModelGroup,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ValidateRequiredDirective } from 'app/shared/directives/validate-required.directive';
import { NumberInputDirective } from 'shared/directives/number-input.directive';
import { CustomTooltipComponent } from '../custom-tooltip/custom-tooltip.component';

@Component({
  selector: 'ads-common-form-input',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ValidateRequiredDirective,
    NumberInputDirective,
    CustomTooltipComponent,
  ],
  templateUrl: './common-form-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CommonFormInputComponent,
    },
  ],
})
export class CommonFormInputComponent implements ControlValueAccessor, AfterViewInit {
  @Input() name = '';
  @Input() label = '';
  @Input() maxLength = 100;
  @Input() required = false;
  @Input() type: 'string' | 'number' = 'string';
  @Input() value: string | number | undefined | null = '';
  @Input() minValue = 0;
  @Input() formGroupName = '';
  @Input() tooltip = '';
  @Input() inputMode: 'text' | 'tel' = 'text';
  @Input() hideHints = false;
  @ViewChild('customInput') customInput?: NgModel;

  constructor(@Optional() private readonly ngModelGroup: NgModelGroup) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.ngModelGroup?.control !== null && this.name !== '') {
        const control =
          this.formGroupName === ''
            ? this.ngModelGroup.control
            : (this.ngModelGroup.control.controls[this.formGroupName] as FormGroup);
        control.registerControl(this.name, this.customInput);

        control.controls[this.name].valueChanges.subscribe((value) => {
          if (value === null) {
            this.customInput?.control.setValue('');
            this.customInput?.control.markAsPristine();
            this.customInput?.control.markAsUntouched();
          }
          this.value = value;

          if (value) {
            this.onTouched();
          }
        });
      }
    });
  }

  getInvalid = () => {
    return (
      this.ngModelGroup?.control?.controls[this.name].status === 'INVALID' &&
      this.ngModelGroup?.control?.controls[this.name].touched
    );
  };

  getDisabled = () => this.disabled || this.ngModelGroup?.disabled;

  disabled = false;
  formControl = new FormControl('', []);

  onChange = () => {};
  onTouched = () => {};

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    this.ngModelGroup?.control?.controls[this.name].markAllAsTouched();
    if (!this.customInput?.touched) {
      this.onTouched();
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  propagateChange = (value: string) => {
    this.writeValue(value);
  };

  writeValue(newValue: string): void {
    this.value = newValue;
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  onModelChange(value: string) {
    this.propagateChange(value);
  }

  getStringValue() {
    return this.value as string | undefined;
  }

  showStringValueLength() {
    return !this.getDisabled();
  }
}
