<div *ngFor="let option of options">
  <div *ngIf="currentCriteria === option.value && currentCriteria !== 'none'">
    <button mat-button mat-stroked-button class="sort-by-button" [matMenuTriggerFor]="stringSorting">
      {{ currentCriteriaLabel }}
      <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #stringSorting>
      <button mat-menu-item (click)="onOptionClicked(option.value, true, option.ascLabel)">{{option.ascLabel}}</button>
      <button mat-menu-item (click)="onOptionClicked(option.value, false, option.descLabel)">{{option.descLabel}}</button>
    </mat-menu>
  </div>
</div>
