<ads-simple-form #cashDepositoryTasks="ngModelGroup" ngModelGroup="cashDepositoryTasks">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button
  ><mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Clean all sensors"
    name="cleanSensors"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Clean bill scanner lenses"
    name="cleanBillScannerLenses"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Check and clean all rollers"
    name="cleanRollers"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Check and clean all belts"
    name="cleanBelts"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Check and clean all bins"
    name="cleanBins"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Verify infeed alignment to fascia"
    name="verifyInfeedAlignment"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Perform diagnostic test"
    name="diagnostic"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
