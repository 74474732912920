<div class="container">
  <ads-navigation-header
    *ngIf="layoutNavService.isDesktopView()"
    (clicked)="onBackArrowClicked()"
    [label]="'Complete Call ' + workOrderService.workOrder">
  </ads-navigation-header>
  <div class="complete-call-info">
    <h5 class="complete-call-title">Complete Call Information</h5>
    <p class="complete-call-description">
      <span>You are about to complete this call.</span>
      <span>Please confirm that all parts and labor have been added, and that the amounts listed below are accurate.</span>
      <span>If you need to adjust your travel or labor, please edit your timesheet entries in Command Center after
        completing the call.
      </span>
    </p>
    <ads-common-form-input
      class="other-technicians"
      label="Other technicians on site"
      [maxLength]="50"
      [(ngModel)]="otherTechnicians"
    ></ads-common-form-input>
    <ads-common-text-area
      label="Completion Notes"
      class="completion-notes"
      [maxLength]="500"
      [(ngModel)]="completionNotes"
    ></ads-common-text-area>
  </div>
  <div class="complete-call-survey">
    <h5 class="complete-call-title">Call completion Survey</h5>
    <div *ngIf="!disableCausedByThirdParty">
      <span class="radio-option-label">Was this issue caused by a third party?</span>
      <mat-radio-group
        [(ngModel)]="thirdParty"
        aria-label="Select an option for third party"
        (change)="onThirdPartyChange($event)">
        <mat-radio-button [value]="true" [checked]="thirdParty">Yes</mat-radio-button>
        <mat-radio-button [value]="false" [checked]="!thirdParty">No</mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="thirdParty">
      <span class="radio-option-label">Was this issue caused by vandalism?</span>
      <mat-radio-group [(ngModel)]="vandalism" aria-label="Select an option for vandalism">
        <mat-radio-button [value]="true" [checked]="vandalism">Yes</mat-radio-button>
        <mat-radio-button [value]="false" [checked]="!vandalism">No</mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="!disableFLMorSLM">
      <span class="radio-option-label">Is this FLM or SLM?</span>
      <mat-radio-group [(ngModel)]="isFirstLineMaintenance" aria-label="Select an option for SLM">
        <mat-radio-button [value]="true" [checked]="isFirstLineMaintenance">FLM</mat-radio-button>
        <mat-radio-button [value]="false" [checked]="!isFirstLineMaintenance">SLM</mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="!disableObsoleteLock">
      <span class="radio-option-label">Obsolete Lock?</span>
      <mat-radio-group [(ngModel)]="isLockObsolete" aria-label="Select an option for obsolete lock">
        <mat-radio-button [value]="true" [checked]="isLockObsolete">Yes</mat-radio-button>
        <mat-radio-button [value]="false" [checked]="!isLockObsolete">No</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <h5 class="table-title">Parts</h5>
  <p *ngIf="parts.data.length === 0" class="no-parts">No parts to show</p>
  <ads-parts-table
    *ngIf="parts.data.length > 0"
    [dataSource]="parts"
    [canRemove]="true"
    [columnsToShow]="partsColumnsToShow">
  </ads-parts-table>
  <h5 class="table-title">Labor</h5>
  <ads-labor-table
    *ngIf="labor.data.length > 0"
    [dataSource]="labor"
    [canRemove]="true"
    [columnsToShow]="laborColumnsToShow">
  </ads-labor-table>
</div>
<div class="complete-footer">
  <ads-modal-actions
    cancelLabel="Previous"
    confirmLabel="Complete Call"
    (confirm)="onCompleteClick()"
    (cancel)="onBackArrowClicked()">
  </ads-modal-actions>
</div>
