import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { HttpParams } from '@angular/common/http';
import { dataURItoBlob } from 'utils';

export interface UploadedPhoto {
  sasUrl: string;
  fileUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class PhotoUploadService {
  constructor(private readonly service: ApiService) {}

  /**
   * Saves ATM cleaning form
   */
  public uploadPhoto(photo: string, workOrder: string): Observable<UploadedPhoto> {
    const blob = dataURItoBlob(photo);
    const fd = new FormData();
    fd.append('photo', blob);

    return this.service.sendRequest<UploadedPhoto>({
      method: 'post',
      url: 'pmforms/photos/upload',
      params: new HttpParams().set('workOrder', workOrder),
      body: fd,
      urlPrefix: 'ccc',
    });
  }

  /**
   * Get photo url
   * @param url
   * @returns sasUrl: string
   */
  public getPhotoUrl(url: string): Observable<UploadedPhoto> {
    return this.service.sendRequest<UploadedPhoto>({
      method: 'get',
      url: 'pmforms/photos/sas-url',
      params: new HttpParams().set('name', encodeURI(url)),
      urlPrefix: 'ccc',
    });
  }
}
