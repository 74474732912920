import { SelectOption } from 'models';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RadioPromptComponent } from 'shared/radio-prompt/radio-prompt.component';
import { ServiceCallsService } from '../sorting.service';

@Component({
  selector: 'ads-radio-filtering',
  standalone: true,
  imports: [CommonModule, RadioPromptComponent],
  templateUrl: './radio-filtering.component.html',
})
export class RadioFilteringComponent{
  @Input() label = '';
  @Input() filteringOptions: SelectOption<string>[] = [];
  @Output() filterChanged = new EventEmitter<string>();
  value = '';

  constructor(public readonly service: ServiceCallsService) {}

  onDismissed() {

  }

  onConfirmClick() {
    this.filterChanged.emit(this.value);
  }

  onCancelClick() {
    this.onDismissed();
  }

  onFilteringAscClicked(_: { column: string; asc: boolean; filteringLabel: string }) {
    const { column, asc, filteringLabel } = _;
    this.service.setSort(column, asc, filteringLabel);
  }

  onColumnSelected(column: string | (string | undefined)[] | undefined) {
    this.value = (column ?? '') as string;
  }
}
