import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { CommonFormInputComponent } from 'shared/material-wrappers';
import { DetailRowComponent } from '../detail-row/detail-row.component';
import { AdsCallControl, AdsEquipmentOnSite } from 'models';
import { Observable } from 'rxjs';

@Component({
  selector: 'ads-work-order-mobile',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    DetailRowComponent,
    CommonFormInputComponent,
    FormsModule,
    MatButtonModule,
  ],
  templateUrl: './work-order-mobile.component.html',
  styleUrl: './work-order-mobile.component.scss',
})
export class WorkOrderMobileComponent {
  @Input({ required: true }) searchText = '';
  @Input({ required: true }) workOrderDetails?: AdsCallControl;
  @Input({ required: true }) equipmentOnSite: Observable<AdsEquipmentOnSite[]> | undefined | null;
  @Input({ required: true }) workOrder = '';
  @Input({ required: true }) openTicketNotes: () => Promise<void> = () => Promise.resolve();
  @Input({ required: true }) openPhone = () => {};
  @Input({ required: true }) openMap: () => Promise<void> = () => Promise.resolve();
  @Input({ required: true }) openEquipmentOnSite: () => Promise<void> = () => Promise.resolve();
}
