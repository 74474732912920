<ads-simple-form *ngIf="item" class="surveillance-ups-section-wrapper" [ngModelGroup]="'upsItem#' + item.index">
  <ads-select
    label="UPS Manufacturer"
    name="upsManufacturer"
    [list]="upsLists?.surveillanceUpsManufacturers"
    [value]="item.upsManufacturer"
    [(ngModel)]="item.upsManufacturer"
  ></ads-select>
  <ads-common-form-input
    [style.display]="displayOtherManufacturer() ? 'block' : 'none'"
    name="upsManufacturer_Other"
    label="Other Manufacturer"
    [maxLength]="50"
    [required]="displayOtherManufacturer()"
    [value]="item.upsManufacturer_Other"
    [(ngModel)]="item.upsManufacturer_Other"
  ></ads-common-form-input>
  <ads-common-form-input
    name="upsModel"
    label="UPS model"
    [maxLength]="50"
    [required]="true"
    [value]="item.upsModel"
    [(ngModel)]="item.upsModel"
  ></ads-common-form-input>

  <ads-common-form-input
    name="numberOfOutputs"
    label="Number of outputs"
    type="number"
    [minValue]="0"
    [maxLength]="8"
    [required]="true"
    [value]="item.numberOfOutputs"
    [(ngModel)]="item.numberOfOutputs"
    inputMode="tel"
  ></ads-common-form-input>
</ads-simple-form>
