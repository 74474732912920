import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomButtonComponent } from '../custom-button/custom-button.component';
import { CustomPromptComponent } from '../custom-prompt/custom-prompt.component';
import { RadioListComponent } from '../material-wrappers/radio-list/radio-list.component';
import { CommonModule } from '@angular/common';
import { SelectOption } from 'models';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'ads-radio-prompt',
  standalone: true,
  imports: [CommonModule, CustomButtonComponent, CustomPromptComponent, RadioListComponent, MatButtonModule],
  templateUrl: './radio-prompt.component.html',
  styleUrl: './radio-prompt.component.scss',
})
export class RadioPromptComponent<T> {
  @Input() focused = false;
  @Input() value: T | undefined;
  @Input() options: SelectOption<T>[] = [];
  @Input() isDesktopView = false;
  @Input() label = '';
  @Input() icon = '';

  @Output() optionSelected = new EventEmitter<T | (T | undefined)[] | undefined>();
  @Output() cancel = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();

  onOptionSelected(option: T | (T | undefined)[] | undefined) {
    this.optionSelected.emit(option);
  }

  onCancelClick() {
    this.focused = false;
    this.cancel.emit();
  }

  onConfirmClick() {
    this.focused = false;
    this.confirm.emit();
  }

  onClick() {
    this.focused = !this.focused;
  }

  onDismissed() {
    this.onCancelClick();
  }
}
