import { DealDrawerLists } from 'forms/security/deal-drawer/deal-drawer-section-form/deal-drawer-section-form.component';
import { SecurityDealDrawerDropdowns } from 'models/security/security-dropdowns';

export const mapDealDrawerDropdowns = (dropdowns: SecurityDealDrawerDropdowns): DealDrawerLists => ({
  conditionList: dropdowns.dealDrawerDealDrawerOverallConditions.map((manufacturer) => ({
    name: manufacturer.name,
    value: manufacturer.name,
  })),
  manufacturerList: dropdowns.dealDrawerDealDrawerManufacturers.map((manufacturer) => ({
    name: manufacturer.manufacturerName,
    value: manufacturer.manufacturerName,
  })),
});
