import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Chart, ChartConfiguration, Plugin } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import { ThemingService } from 'services';
import { PanelComponent } from 'shared/panel/panel.component';
import { homeBarChartOptions } from '../home-charts-config';
import { HomeService } from '../home.service';
import { CommonModule } from '@angular/common';
import { ChartsComponent } from 'shared/charts/charts.component';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ads-ranking-chart',
  standalone: true,
  imports: [PanelComponent, CommonModule, ChartsComponent],
  templateUrl: './ranking-chart.component.html',
  styleUrl: './ranking-chart.component.scss',
})
export class RankingChartComponent implements OnInit {
  chart?: BaseChartDirective;
  @Input() values: string[] = [];
  @Output() valuesChange = new EventEmitter<string[]>();
  public barChartOptions = homeBarChartOptions(this.theming);
  public barChartPlugins = [pluginDataLabels] as unknown as Plugin<'bar', unknown>[];
  public barChartData: ChartConfiguration<'bar'>['data'] = {
    labels: ['PM', 'FTF', 'Resolve'],
    datasets: [
      {
        data: [],
        maxBarThickness: 3,
        backgroundColor: this.theming.selectedOption,
        datalabels: { formatter: (value, context) => this.values[context.dataIndex] },
      },
    ],
  };

  constructor(private readonly theming: ThemingService, private readonly homeService: HomeService) {
    Chart.register(ChartDataLabels);

    toObservable(this.theming.name).subscribe(() => {
      this.barChartOptions = homeBarChartOptions(this.theming);
    });
  }

  onChartInit = (chart: BaseChartDirective) => {
    this.chart = chart;
  };

  calculateValues(data: string[]) {
    return data.map((item) => {
      let val = 0;
      const value = item.split('/');
      if (value[1] === '0') {
        val = 100;
      } else {
        val = 100 * (parseInt(value[0]) / parseInt(value[1]));
      }

      if (val > 95) {
        val = 95;
      }

      return val;
    });
  }

  ngOnInit(): void {
    this.homeService.individualRank().subscribe((data) => {
      this.barChartData.datasets[0].data = this.calculateValues([data.pm ?? '', data.fTF ?? '', data.resolve ?? '']);
      this.values = [data.pm ?? '', data.fTF ?? '', data.resolve ?? ''];
      this.valuesChange.emit(this.values);
      if (this.chart) {
        this.chart?.update();
      }
    });
  }
}
