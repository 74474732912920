import { AuthService, ThemingService } from 'services';
import { FormsModule } from '@angular/forms';
import { AdsCallControl, SelectOption, YesNo } from 'models';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { Component, Inject, inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { CallControlService } from 'pages/call-control/call-control.service';
import { CallStatusId } from 'models/call-control/ack-schedule-models';
import { DatePickerComponent } from 'shared/material-wrappers/datepicker/datepicker.component';
import { TimepickerComponent } from 'shared/material-wrappers/timepicker/timepicker.component';
import { CustomButtonComponent } from 'shared/custom-button/custom-button.component';
import { WorkOrderCardComponent } from 'shared/work-order-card/work-order-card.component';
import { roundDateToNearestQuarter } from 'app/utils/dates';
import { WorkOrderStatus } from 'pages/work-order/status/status';
import { OperationResponse } from 'models/call-control/command-response';
import { LoaderService } from 'shared/loader/loader.service';

@Component({
  selector: 'ads-travelling',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatTabsModule,
    DatePickerComponent,
    TimepickerComponent,
    CustomButtonComponent,
    WorkOrderCardComponent,
  ],
  templateUrl: './travelling.component.html',
  styleUrl: './travelling.component.scss',
  providers: [
    {
      provide: Window,
      useValue: window,
    },
  ],
})
export class TravellingComponent implements OnInit {
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  travellingDate = roundDateToNearestQuarter(new Date());
  selectedTime = '';
  validTime = false;
  showForm = false;

  public readonly _snackBar = inject(MatSnackBar);
  public readonly _bottomSheet = inject(MatBottomSheet);
  public readonly dialogRef = inject(MatDialog);
  private readonly datepipe: DatePipe = new DatePipe('en-US');
  openCalls: AdsCallControl[] = [];

  yesNoOptions: SelectOption<YesNo>[] = [
    { name: 'Yes', value: YesNo.Yes },
    { name: 'No', value: YesNo.No },
  ];

  constructor(
    public readonly callControlService: CallControlService,
    public readonly authService: AuthService,
    public readonly theming: ThemingService,
    public readonly workOrderService: WorkOrderService,
    public readonly loader: LoaderService,
    @Inject(Window) private readonly window: Window
  ) {}

  ngOnInit(): void {
    if (this.authService.authenticated) {
      this.workOrderService
        .getStatusTimestamp({ workOrder: this.workOrderService.workOrder })
        .subscribe((response) => (this.travellingDate = new Date(response.Date ?? new Date())));

      this.callControlService
        .getOpenCalls(this.workOrderService.workOrder, this.workOrderService.workOrderDetails().nbr ?? '')
        .subscribe(
          (openCalls) =>
            (this.openCalls = openCalls.map((call) => ({
              ...call,
              laststatusname: call.laststatusname === 'Ack' ? 'Acknowledged' : call.laststatusname,
            })))
        );
    }
  }

  onConfirm() {
    if (this.authService.authenticated && this.travellingDate) {
      this.onTimeChange(this.selectedTime);
      this.callControlService
        .updateCallStatus({
          edorDate: '',
          edorTime: '',
          functionalityRestored: '',
          workOrder: this.workOrderService.workOrderDetails().workorder ?? '',
          dateEnter: this.datepipe.transform(this.travellingDate, 'MM/dd/yyyy')?.toString(),
          timeEnter: this.datepipe.transform(this.travellingDate, 'HH:mm:ss')?.toString(),
          techIdMaster: this.authService.user?.personnelNumber ?? '',
          userId: this.authService.user?.userId ?? '',
          utcOffset: new Date().getTimezoneOffset() / -60,
          status: CallStatusId.Travelling_40,
        })
        .subscribe(this.onTravellingSuccess);
    }
  }

  hideAll() {
    this._bottomSheet.dismiss();
    this.dialogRef.closeAll();
  }

  refreshPage = () => this.window.location.reload();
  onTravellingSuccess = (result: OperationResponse) => {
    this.hideAll();
    if (result.isSuccess) {
      this._snackBar.open('Work Order was set to Travelling successfully.', 'Close');
      this.loader.show();
      setTimeout(() => {
        this.loader.hide();
        this.refreshPage();
      }, 3000);
    } else {
      this.onTicketSubmitError(result.errorMessage);
    }
  };

  onTicketSubmitError = (error: unknown) => {
    console.error(error);
    this._snackBar.open('Failed to mark your Work Order as Travelling, please try again later.', 'Close');
  };

  onTimeChange(time: string) {
    this.selectedTime = time;
    this.validTime = false;
    if (time) {
      this.validTime = true;
      const hours = parseInt(time.split(' ')[0], 10);
      const isPm = time.split(' ')[2] === 'PM';
      this.travellingDate.setHours(isPm ? hours + 12 : hours);
      this.travellingDate.setMinutes(parseInt(time.split(' ')[1], 10));
      this.travellingDate.setSeconds(0);
    }
  }

  onNoTravel() {
    this.dialogRef.closeAll();
    this.workOrderService.statusSelected(WorkOrderStatus.Started);
    this.workOrderService.canModifyDate = true;
  }
}
