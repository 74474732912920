import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { environment } from 'environment/environment';
import { HomeService } from '../home.service';
import { MatButtonModule } from '@angular/material/button';
import { CallsContainerComponent } from '../calls-container/calls-container.component';
import { ThemingService, FeatureManagerService } from 'services';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CustomButtonInvertedComponent } from 'shared/custom-button-inverted/custom-button-inverted.component';

@Component({
  selector: 'ads-buttons-container',
  standalone: true,
  imports: [
    MatIconModule,
    MatMenuModule,
    CommonModule,
    MatButtonModule,
    CallsContainerComponent,
    CustomButtonInvertedComponent,
  ],
  templateUrl: './buttons-container.component.html',
  styleUrl: './buttons-container.component.scss',
})
export class ButtonsContainerComponent implements OnInit {
  @Input() isDesktop = false;
  @Input() newCalls = 0;
  @Input() openCalls = 0;
  currentTheme = '';

  constructor(
    private readonly homeService: HomeService,
    public readonly layoutNavService: LayoutNavService,
    private readonly router: Router,
    private readonly featureManagerService: FeatureManagerService,
    public readonly theming: ThemingService,
  ) {}

  ngOnInit(): void {
    this.currentTheme = this.theming.theme.name;
    this.theming.watchTheme.subscribe((theme) => {
      this.currentTheme = theme;
    });
  }

  navigateToCC() {
    this.homeService.navigateCCUrl();
  }

  navigateEmail(): void {
    window.open('https://outlook.office.com', '_blank', 'noopener');
  }

  navigateKaba(): void {
    window.open(
      `${environment.apiConfig.powerAppUrl}${environment.apiConfig.kabaRequest}?source=iframe&authMode=Iframe`,
      '_blank',
      'noopener',
    );
  }

  async navigateRMA(): Promise<void> {
    const newRmaFlagValue = await firstValueFrom(this.featureManagerService.pwaNewRma$);
    if (newRmaFlagValue) {
      this.navigateNewRma();
    } else {
      this.navigateOldRma();
    }
  }

  navigateOldRma(): void {
    window.open(
      `${environment.apiConfig.powerAppUrl}9490fd3d-90ad-4cd6-8823-ccc156b7e641?source=iframe&authMode=Iframe`,
      '_blank',
      'noopener',
    );
  }

  navigateNewRma(): void {
    this.router.navigate(['/rmaRequest']);
  }
}
