<ads-order-info [order]="workOrder" [customerName]="customerName"></ads-order-info>

<form #atmCleaningForm="ngForm" (ngSubmit)="onSubmit()">
  <ads-expansion-panel
    #atmSectionPanel
    title="ATM"
    [formGroup]="form?.form"
    [name]="'atmSection'"
    [class]="'atmSection'"
  >
    <ads-atm-form-section
      [section]="getSection('atmSection')"
      [atmManufacturerList]="dropdowns.manufacturerList"
      [depositorTypeList]="dropdowns.depositorTypeList"
    ></ads-atm-form-section>
  </ads-expansion-panel>
  <ads-expansion-panel
    #atmExteriorEvaluationSectionPanel
    title="ATM Exterior Evaluation"
    [formGroup]="form?.form"
    [name]="'atmExteriorEvaluation'"
    [class]="'atmExteriorEvaluation'"
  >
    <ads-atm-exterior-evaluation [section]="getSection('atmExteriorEvaluation')"></ads-atm-exterior-evaluation>
  </ads-expansion-panel>
  <ads-expansion-panel
    #atmNetworkLogosSectionPanel
    title="ATM network logos"
    [formGroup]="form?.form"
    [name]="'atmNetworkLogos'"
    [class]="'atmNetworkLogos'"
  >
    <ads-network-logos [section]="getSection('atmNetworkLogos')" [groupName]="'atmNetworkLogos'"></ads-network-logos>
  </ads-expansion-panel>
  <ads-expansion-panel
    #atmCleaningTasksSectionPanel
    title="Cleaning Tasks"
    [formGroup]="form?.form"
    [name]="'cleaningTasks'"
    [class]="'cleaningTasks'"
  >
    <ads-atm-cleaning-tasks [section]="getSection('cleaningTasks')"></ads-atm-cleaning-tasks>
  </ads-expansion-panel>
  <ads-expansion-panel
    #atmReplenishmentTasksSectionPanel
    title="Replenishment Tasks"
    [formGroup]="form?.form"
    [name]="'replenishmentTasks'"
    [class]="'replenishmentTasks'"
  >
    <ads-atm-replenishment-tasks [section]="getSection('replenishmentTasks')"></ads-atm-replenishment-tasks>
  </ads-expansion-panel>
  <ads-expansion-panel
    #atmPhotosSectionPanel
    title="Photos"
    [formGroup]="form?.form"
    [name]="'photoUploads'"
    [class]="'photoUploads'"
  >
    <ads-photos-section
      [photoUploads]="photoUploads"
      [photoUploadForm]="getSection('photoUploads')!"
    ></ads-photos-section>
  </ads-expansion-panel>

  <ads-common-text-area
    name="comments"
    label="Comments (optional)"
    [maxLength]="500"
    ngModel
  ></ads-common-text-area>

  <div class="form-buttons" *ngIf="!getIsFormSubmitted()">
    <button
      mat-button
      mat-stroked-button
      (click)="saveForm()"
      [disabled]="form?.form?.disabled || (isLoading | async)"
      type="button"
    >
      Save
    </button>
    <button mat-button mat-flat-button class="main-button" [disabled]="form?.form?.disabled">Submit</button>
  </div>
</form>
<div class="clear"></div>
