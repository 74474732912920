<ads-simple-form #safes="ngModelGroup" ngModelGroup="safes">
  <ng-container ngModelGroup="safesPanels">
    <ads-nested-multiple-forms-section
      #safesSectionsPanels
      title="Safes Panel"
      [formTemplate]="formTemplate"
      [list]="security.safesData.safesSections"
      [formControls]="getFormControls()"
      [groupName]="'safesPanel'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddClick()"
      (deleteClicked)="onRemoveSafes($event)"
    >
    </ads-nested-multiple-forms-section>
    <ng-template #formTemplate let-item="item">
      <ads-safes-section-form
        [section]="getItemSection('safesPanel#' + item.index, 'safesPanels')"
        [item]="item"
        [dropdowns]="dropdowns"
        [submitted]="submitted"
      ></ads-safes-section-form>
    </ng-template>
  </ng-container>

  <ads-expansion-panel
    title="Safe tasks"
    [formGroup]="formGroup"
    [name]="'safesTasks'"
    [class]="'safesTasks'"
  >
    <ads-safes-safe-tasks-section [section]="getTasksSection('safesTasks')"></ads-safes-safe-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    title="Time clock tasks"
    [formGroup]="formGroup"
    [name]="'timeClockTasks'"
    [class]="'timeClockTasks'"
  >
    <ads-time-clock-tasks-section [section]="getTasksSection('timeClockTasks')"></ads-time-clock-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    title="Combo lock tasks"
    [formGroup]="formGroup"
    [name]="'comboLockTasks'"
    [class]="'comboLockTasks'"
  >
    <ads-combo-lock-tasks-section
      [section]="getTasksSection('comboLockTasks')"
      [fields]="comboLockFields"
    ></ads-combo-lock-tasks-section>
  </ads-expansion-panel>

  <ads-common-text-area
    name="comments"
    label="Comments (optional)"
    [maxLength]="200"
    ngModel
  ></ads-common-text-area>
</ads-simple-form>
