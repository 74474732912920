import { Directive, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validators } from '@angular/forms';
import { validateRequired } from '../../utils/validate-required';

@Directive({
  selector: '[adsRequired]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidateRequiredDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class ValidateRequiredDirective implements Validators, OnChanges {
  @Input() adsRequired = '';
  @Input() checkRequired = true;

  private validatorFn = validateRequired(this.checkRequired);

  validate(control: AbstractControl): ValidationErrors | null {
    return this.validatorFn(control);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['checkRequired']) {
      this.validatorFn = validateRequired(this.checkRequired);
    }
  }
}
