<ads-radio-button-group
  [required]="true"
  [options]="options"
  [multiple]="true"
  (optionClicked)="onOptionClicked($event)"
  [(ngModel)]="currentChart"
></ads-radio-button-group>

<ads-panel>
  <div class="charts-header">
    <div class="legend">
      <div *ngIf="activeTabs.includes(0)" class="legend-option">
        <span class="pm-bar-color"></span>
        <span>PM</span>
      </div>
      <div *ngIf="activeTabs.includes(1)" class="legend-option">
        <span class="ftf-bar-color"></span>
        <span>FTF</span>
      </div>
      <div *ngIf="activeTabs.includes(2)" class="legend-option">
        <span class="resolve-bar-color"></span>
        <span>Resolve</span>
      </div>
    </div>
    <button mat-button mat-stroked-button (click)="showFilters = !showFilters">
      Filters <mat-icon iconPositionEnd class="material-icons-outlined">keyboard_arrow_down</mat-icon>
    </button>
    <ads-checkbox-list *ngIf="showFilters" [item]="filters" (itemChange)="onFilterChange($event)"> </ads-checkbox-list>
  </div>

  <ads-charts
    *ngIf="chartsData.pm"
    [customHeight]="allCenters * 60"
    [chartData]="barChartData"
    [chartOptions]="barChartOptions"
    [chartPlugins]="barChartPlugins"
    [legend]="false"
    [onChartInit]="onChartInit"
  ></ads-charts>
</ads-panel>
