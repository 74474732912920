<ads-simple-form #batteryTasks="ngModelGroup" ngModelGroup="batteryTasks">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button
  ><mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test AC loss detection"
    name="acLossTest"
    [options]="completeNaOptions"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test battery loss detection"
    name="batteryLossTest"
    [options]="completeNaOptions"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
</ads-simple-form>
