<div *ngIf="!layoutNavService.isDesktopView()" class="mobile-cards">
  <ads-equipment-card
    *ngFor="let item of dataSource.data; let i = index"
    [item]="item"
    [canRemove]="canRemove"
    [canSelect]="selection !== undefined"
    [isSelected]="selection?.isSelected(item) || isSelected(item)"
    (removeClicked)="removeEquipment(i)"
    (toggled)="onCardSelect(item)"
  ></ads-equipment-card>
</div>
<div *ngIf="layoutNavService.isDesktopView()" class="table">
  <table mat-table [dataSource]="dataSource.data | equipmentFilter : searchText">
    <ng-container *ngIf="selection !== undefined" matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="toggleAllRows($event)"
          [checked]="selection.hasValue() && selection.selected.length === dataSource.data.length"
          [indeterminate]="selection.hasValue() && selection.selected.length !== dataSource.data.length"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row; let i = index">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="onSelect($event, row, i)"
          onKeyPress="event.stopPropagation"
          [checked]="selection.isSelected(row) || isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="remove">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button mat-icon-button class="remove-button" (click)="removeEquipment(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="equipmentType">
      <th mat-header-cell *matHeaderCellDef>Equipment Type</th>
      <td mat-cell *matCellDef="let element">{{ element.equipmentType ?? '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="partsWarrantyEnds">
      <th mat-header-cell *matHeaderCellDef>On Parts Warranty</th>
      <td mat-cell *matCellDef="let element">{{ element.partsWarrantyEnds ?? '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="manufacturer">
      <th mat-header-cell *matHeaderCellDef>Manufacturer</th>
      <td mat-cell *matCellDef="let element">{{ element.manufacturer ?? '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="model">
      <th mat-header-cell *matHeaderCellDef>Model</th>
      <td mat-cell *matCellDef="let element">{{ element.model ?? '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element">{{ element.type ?? '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="terminalID">
      <th mat-header-cell *matHeaderCellDef>Name/Terminal ID</th>
      <td mat-cell *matCellDef="let element">{{ element.terminalID ?? '-' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToShow"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columnsToShow"
      (click)="selection?.toggle(row)"
      onKeyPress="event.stopPropagation"
    ></tr>
  </table>
</div>
