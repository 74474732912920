import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { RoutesEnum } from 'app/utils/navigation';
import { Line } from 'models/rma-request/rma-request';
import { RMARequestService } from '../rma-request.service';

@Component({
  selector: 'ads-parts',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  templateUrl: './parts.component.html',
  styleUrl: './parts.component.scss',
})
export class PartsComponent {
  filteredParts: Line[] = [];
  search = '';

  constructor(private readonly router: Router, protected rmaRequestService: RMARequestService) {}

  addPart = () => {
    this.router.navigate([`${RoutesEnum.RMARequest}/${RoutesEnum.RMARequestParts}`], {
      state: { selectedPart: undefined },
    });
  };

  editPart = (index: number) => {
    this.router.navigate([`${RoutesEnum.RMARequest}/${RoutesEnum.RMARequestParts}`], {
      state: { selectedPart: index },
    });
  };
}
