<ads-simple-form #software="ngModelGroup" ngModelGroup="software">
  <ads-radio-button-group
    [options]="options"
    label="Software Onsite?"
    name="onsite"
    [required]="true"
    ngModel
  ></ads-radio-button-group>

  <ads-select label="Operating System" name="operatingSystem" [list]="operatingSystemsList" ngModel></ads-select>

  <ads-common-form-input
    name="softwareVersion"
    label="Software Version"
    [maxLength]="50"
    [required]="true"
    ngModel
  ></ads-common-form-input>
</ads-simple-form>
