import { CommonModule } from '@angular/common';
import { Component, Input, AfterViewInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { DealDrawerSectionFormComponent } from './deal-drawer-section-form/deal-drawer-section-form.component';
import { PhotoUploadControlService } from 'services';
import { SecurityService } from 'pages/security/security.service';
import { DealDrawerSectionForm } from 'models/security/deal-drawer/dealDrawerSecurityForm';
import { NestedMultipleFormsSectionComponent } from 'forms/shared/nested-multiple-forms-section/nested-multiple-forms-section.component';
import { DealDrawerTasksComponent } from './deal-drawer-tasks/deal-drawer-tasks.component';
import { IndexableFormType } from 'app/utils/miscTypes';
import { MatInputModule } from '@angular/material/input';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { onAddItem, onRemoveItem } from 'app/utils/form-group-array';

export class DealDrawerFormSection implements IndexableFormType, DealDrawerSectionForm {
  index: number;
  dealDrawerModel: string | null;
  dealDrawerMake: string | null;
  otherMake: string | null;
  overallCondition: string | null;
  overallConditionComments: string | null;
  laneNumber: number | null;
  constructor(index: number) {
    this.index = index;
    this.dealDrawerModel = '';
    this.dealDrawerMake = '';
    this.otherMake = '';
    this.overallCondition = '';
    this.overallConditionComments = '';
    this.laneNumber = null;
  }
}

@Component({
  selector: 'ads-deal-drawer',
  standalone: true,
  imports: [
    CommonModule,
    SimpleFormComponent,
    FormsModule,
    MatInputModule,
    ExpansionPanelComponent,
    NestedMultipleFormsSectionComponent,
    DealDrawerSectionFormComponent,
    DealDrawerTasksComponent,
    CommonTextAreaComponent,
  ],
  providers: [PhotoUploadControlService],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './deal-drawer.component.html',
})
export class DealDrawerComponent implements AfterViewInit {
  @Input() formGroup?: FormGroup | null;
  @Input() submitted = false;
  expandedList: number[] = [];
  photoUploadForm: FormGroup[] = [];

  constructor(public readonly security: SecurityService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      const tasksSection = this.formGroup?.controls['dealDrawerTasks'] as FormGroup;
      tasksSection.setValue(this.security.dealDrawerData.dealDrawerTasks);
      this.formGroup?.controls['dealDrawerTasks'].setValue(this.security.dealDrawerData.dealDrawerTasks);
      this.formGroup?.controls['comments'].setValue(this.security.dealDrawerData.comments);

      if (this.submitted) {
        this.formGroup?.disable();
      }
    });
  }

  getSection(sectionName: string) {
    return this.formGroup?.controls[sectionName] as FormGroup | undefined;
  }

  getFormControls(): Record<string, AbstractControl<string, string>> {
    const fg = this.formGroup?.get('dealDrawerSections') as FormGroup;
    return fg ? fg.controls : {};
  }

  onAddClick = () => onAddItem(this.security.dealDrawerData.dealDrawerSections, (index) => new DealDrawerFormSection(index));

  onRemoveDealDrawer = (item: DealDrawerFormSection) => onRemoveItem(this.security.dealDrawerData.dealDrawerSections, item);

  getList() {
    return this.security.dealDrawerData.dealDrawerSections;
  }
}
