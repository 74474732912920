export class ItmSpecs {
  manufacturer: string | null;
  serialNumber: string | null;
  depositorType: string | null;
  kabaSerialNumber: string | null;
  model: string | null;
  communicationType: string | null;
  terminalId: string | null;
  networkTerminalId: string | null;
  overallCondition: string | null;
  equipmentRating: string | null;

  constructor() {
    this.manufacturer = '';
    this.serialNumber = '';
    this.terminalId = '';
    this.depositorType = '';
    this.model = '';
    this.communicationType = '';
    this.networkTerminalId = '';
    this.overallCondition = '';
    this.kabaSerialNumber = '';
    this.equipmentRating = '';
  }
}
