import {
  SecurityAccessControlCardReaderInterfaceDto,
  SecurityAccessControlCardReaderTasksDto,
  SecurityAccessControlDoorsDto,
  SecurityAccessControlPanelTasksDto,
  SecurityAccessControlPowerSupplyTableDto,
  SecurityAccessControlPowerSupplyTasksDto,
  SecurityAccessControlsPanelDto,
} from 'models/api/security/access-hardware/access-hardware-dto';
import { SecurityAccessHardwareDto } from 'models/api/security/security';
import {
  AccessControlCardReaderInterfaceTableForm,
  AccessControlCardReaderTasksForm,
  AccessControlDoorTableForm,
  AccessControlPowerSupplyTableForm,
  AccessControlPowerSupplyTasksForm,
  AccessControlsForm,
  AccessControlTasksForm,
  AccessHardwareSecurityForm,
} from 'models/security/access-hardware/accessHardwareSecurityForm';

export const mapAccessControlsPanels = (panels: AccessControlsForm[]): SecurityAccessControlsPanelDto[] => {
  return panels.map((panel) => ({
    controlPanelModel: panel.controlPanelModel,
    systemManufacturer: panel.systemManufacturer,
    systemManufacturer_Other: panel.systemManufacturer_Other,
    controlPanelModel_Other: panel.controlPanelModel_Other,
    location: panel.location,
    ipAddress: panel.ipAddress,
    overallConditionAccessControlsystem: panel.overallConditionAccessControlsystem,
    equipment_Rating_AccessControlsystem: panel.equipmentRatingAccessControlsystem,
  }));
};

export const mapAccessControlPanelTasksRequest = (
  tasks?: AccessControlTasksForm
): SecurityAccessControlPanelTasksDto[] => {
  return [
    {
      testPowerFail: tasks?.testPowerFail ?? null,
      verifyComms: tasks?.verifyComms ?? null,
      testTamper: tasks?.testTamper ?? null,
    },
  ];
};

export const mapAccessControlCardReaderInterfacesRequest = (
  interfaces: AccessControlCardReaderInterfaceTableForm[]
): SecurityAccessControlCardReaderInterfaceDto[] => {
  return interfaces.map((interfaceItem) => ({
    address: interfaceItem.address,
    interfaceType: interfaceItem.interfaceType,
    interfaceType_Other: interfaceItem.interfaceType_Other,
    location: interfaceItem.location,
    port: interfaceItem.port,
  }));
};

export const mapAccessControlPowerSupplyTableRequest = (
  powerSupplies: AccessControlPowerSupplyTableForm[]
): SecurityAccessControlPowerSupplyTableDto[] => {
  return powerSupplies.map((powerSupply) => ({
    batteryAhr: powerSupply.batteryAhr,
    batteryVoltageAfter1HourPowerLoss: powerSupply.batteryVoltageAfter1HourPowerLoss,
    location: powerSupply.location,
    powerSupply: powerSupply.powerSupply,
    powerSupply_Other: powerSupply.powerSupply_Other,
    voltage: powerSupply.voltage,
  }));
};

export const mapAccessControlTasksItemsRequest = (
  tasks?: AccessControlPowerSupplyTasksForm | AccessControlCardReaderTasksForm
): SecurityAccessControlPowerSupplyTasksDto[] | SecurityAccessControlCardReaderTasksDto[] => {
  return [
    {
      visuallyInspectWire: tasks?.visuallyInspectWire ?? null,
    },
  ];
};

export const mapAccessControlDoorsRequest = (doors: AccessControlDoorTableForm[]): SecurityAccessControlDoorsDto[] => {
  return doors.map((door) => ({
    lockStyle: door.lockStyle,
    lockStyle_Other: door.lockStyle_Other,
    readerLocation: door.readerLocation,
    readerStyle: door.readerStyle,
    readerStyle_Other: door.readerStyle_Other,
    doesAREXDeviceUnlockDoor: door.doesAREXDeviceUnlockDoor,
    testDoorForcedAlarm: door.testDoorForcedAlarm,
    testDoorHelpOpenAlarm: door.testDoorHelpOpenAlarm,
    testLock: door.testLock,
    testReader: door.testReader,
  }));
};

export const mapAccessHardwareRequest = (accessHardware: AccessHardwareSecurityForm | null): SecurityAccessHardwareDto => ({
  security_AccessControlHardware: {
    security_AccessControlHardware_AccessControls: mapAccessControlsPanels(accessHardware?.accessControls ?? []),
    security_AccessControlHardware_AccessControlPanelTasks: mapAccessControlPanelTasksRequest(accessHardware?.accessControlTasks),
    security_AccessControlHardware_CardReaderInterfaces: mapAccessControlCardReaderInterfacesRequest(accessHardware?.accessControlCardReaderInterfaces ?? []),
    security_AccessControlHardware_CardReaderInterfaceTasks: mapAccessControlTasksItemsRequest(accessHardware?.accessControlCardReaderTasks),
    security_AccessControlHardware_PowerSupplytables: mapAccessControlPowerSupplyTableRequest(accessHardware?.accessControlPowerSupplies ?? []),
    security_AccessControlHardware_PowerSupplyTasks: mapAccessControlTasksItemsRequest(accessHardware?.accessControlPowerSupplyTasks),
    security_AccessControlHardware_DoorTables: mapAccessControlDoorsRequest(accessHardware?.accessControlDoors ?? []),
    comments: accessHardware?.comments ?? null,
  },
});
