<ads-simple-form *ngIf="item" [ngModelGroup]="'hubSection#' + item.index">
  <ads-common-form-input
    name="quantity"
    label="Quantity"
    type="number"
    [maxLength]="4"
    [minValue]="0"
    [required]="true"
    [value]="item.quantity"
    [(ngModel)]="item.quantity"
    inputMode="tel"
  ></ads-common-form-input>
  <ads-select
    label="Type"
    name="type"
    [list]="alarmLists?.hubTypes"
    [value]="item.type"
    [(ngModel)]="item.type"
    [required]="true"
  ></ads-select>
</ads-simple-form>
