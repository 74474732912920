import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ControlContainer, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { NetworkLogo, SelectOption } from 'models';
import {
  SingleToggleButtonComponent,
  RadioButtonGroupComponent,
  CommonFormInputComponent,
} from 'shared/material-wrappers';

@Component({
  selector: 'ads-network-logos',
  standalone: true,
  imports: [
    SingleToggleButtonComponent,
    RadioButtonGroupComponent,
    CommonModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    CommonFormInputComponent,
    FormsModule,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './network-logos.component.html',
  styleUrl: './network-logos.component.scss',
})
export class NetworkLogosComponent implements AfterViewInit {
  @Input() section?: FormGroup;
  @Input() groupName = '';

  other = '';
  options: SelectOption<NetworkLogo>[] = [
    { name: 'Visa', value: NetworkLogo.Visa },
    { name: 'Mastercard', value: NetworkLogo.Mastercard },
    { name: 'Discover', value: NetworkLogo.Discover },
    { name: 'American Express', value: NetworkLogo.AmericanExpress },
    { name: 'Plus', value: NetworkLogo.Plus },
    { name: 'Maestro', value: NetworkLogo.Maestro },
    { name: 'Cirrus', value: NetworkLogo.Cirrus },
    { name: 'Star', value: NetworkLogo.Star },
    { name: 'Co-op', value: NetworkLogo.Coop },
    { name: 'None', value: NetworkLogo.None },
  ];

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.section) {
        this.section.controls['logos'].valueChanges.subscribe(() => {
          this.value = (this.section?.controls['logos'].value as string[]) ?? [];
        });
      }
    });
  }

  value: string[] = [];

  isItemSelected = (itemValue: string) => {
    if (this.value) {
      return this.value.find((v) => v === itemValue);
    }
    return [];
  };

  onClick(value: string) {
    const logos = this.section?.controls['logos'].value as string[];
    const removeNone = logos.filter((logo) => logo !== 'none');
    if (value === 'none') {
      if (logos.includes('none')) {
        this.section?.controls['logos'].setValue(['none']);
      } else {
        const allOtherOptions = this.options.map((option) => option.value).filter((option) => option !== 'none');
        this.section?.controls['logos'].setValue(allOtherOptions);
      }
    } else {
      this.section?.controls['logos'].setValue(removeNone);
      if (logos.length === 0) {
        this.section?.controls['logos'].setValue(['none']);
      }
    }
  }

  onKeyPress(e: Event) {
    const event = e as KeyboardEvent;
    if (event.key == 'Enter') {
      this.onClick('none');
    }
  }
}
