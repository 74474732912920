import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { NavigationRoutesEnum } from 'utils';

@Component({
  selector: 'ads-calls-container',
  standalone: true,
  imports: [CommonModule ,MatButtonModule, MatIcon],
  templateUrl: './calls-container.component.html',
  styleUrl: './calls-container.component.scss',
})
export class CallsContainerComponent {
  @Input() isDesktop = false;
  @Input() newCalls = 0;
  @Input() openCalls = 0;

  constructor(public readonly layoutNavService: LayoutNavService) {}

  navigateToCallControl() {
    this.layoutNavService.changeTab(NavigationRoutesEnum.CallControl);
  }
}
