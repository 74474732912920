<mat-toolbar>
  <button
    mat-icon-button
    aria-label="expand menu"
    (click)="layoutService.toggleSideNav()"
    [class.side-nav-closed]="!layoutService.sideNavOpened"
    *ngIf="currentContent().showExpandButton && headerService.isDesktopView()"
  >
    <mat-icon class="material-icons-outlined">menu_open</mat-icon>
  </button>

  <div *ngIf="headerService.title() || currentContent().backToMenu" class="header-title">
    <button mat-icon-button aria-label="close form" *ngIf="doc.opener !== null" (click)="goBack()">
      <mat-icon>close</mat-icon>
    </button>
    <button mat-icon-button aria-label="close form" *ngIf="currentContent().backToMenu" (click)="goHome()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span>{{ currentContent().backToMenu ? 'Menu' : headerService.title() }}</span>
  </div>
  <button [matMenuTriggerFor]="menu" mat-icon-button aria-label="more options" *ngIf="currentContent().showMore">
    <mat-icon>more_vert</mat-icon>
  </button>

  <div class="right-buttons" *ngIf="currentContent().showThemeSwitch || currentContent().showNotifications">
    <button
      mat-button
      mat-stroked-button
      class="theme-button"
      (click)="onSwitchThemeClick()"
      *ngIf="currentContent().showThemeSwitch"
    >
      Change to
      <mat-icon class="material-icons-outlined" iconPositionEnd>{{ themeName }}_mode</mat-icon>
    </button>

    <button
      mat-icon-button
      class="notifications-button"
      [matMenuTriggerFor]="notificationsMenu"
      *ngIf="currentContent().showNotifications"
    >
      <div [matBadge]="unreadAlerts.length || null" matBadgeSize="small">
        <mat-icon class="material-icons-outlined">notifications</mat-icon>
      </div>

      <mat-menu #notificationsMenu="matMenu" yPosition="below">
        <ng-container *ngFor="let alert of alerts">
          <mat-expansion-panel
            hideToggle
            (click)="readNotification($event, alert.alertId)"
            style="box-shadow: unset; border-radius: 0; margin-bottom: 5px; margin-top: 5px"
            (keyup)="onKeyPress($event, alert.alertId)"
          >
            <mat-expansion-panel-header [class.unread]="unreadAlerts.includes(alert.alertId)">
              <mat-panel-title> {{ alert.alertSubject }} </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="alert-content">
              <p>{{ alert.alertText }}</p>
              <button mat-icon-button (click)="deleteNotification($event, alert.alertId)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-menu>
    </button>
  </div>
</mat-toolbar>
<mat-progress-bar *ngIf="isLoading | async" mode="indeterminate" value="40" class="progress-bar"></mat-progress-bar>
<div class="overlay-loading" *ngIf="isLoading | async"></div>
<ads-sticky-header></ads-sticky-header>

<mat-menu #menu="matMenu">
  <button class="menu-button" mat-menu-item (click)="onResetFormClick()" *ngIf="showResetFormButton()">
    Reset Form
  </button>

  <button class="menu-button" mat-menu-item (click)="expandCollapseAll()">
    {{ headerService.expanded ? 'Collapse' : 'Expand' }} All
  </button>
  <button class="menu-button" mat-menu-item (click)="onSwitchThemeClick()">
    <mat-icon class="material-icons-outlined">{{ themeName }}_mode</mat-icon>
    {{ themeName | capitalize }}
  </button>
</mat-menu>
