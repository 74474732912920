import { CompleteNa } from '../shared/complete-na';
import { TcdTcrCommonTasks } from './tcd-tcr-common-tasks';

export class TcdTcrInputModuleTasks extends TcdTcrCommonTasks {
  verifyIndicators: CompleteNa | null;
  checkShutter: CompleteNa | null;
  constructor() {
    super();
    this.verifyIndicators = null;
    this.checkShutter = null;
  }
}
