import { CommonModule } from '@angular/common';
import { Component, Input, AfterViewInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { AlarmSectionFormComponent } from './alarm-section-form/alarm-section-form.component';
import { SecurityService } from 'pages/security/security.service';
import { NestedMultipleFormsSectionComponent } from 'forms/shared/nested-multiple-forms-section/nested-multiple-forms-section.component';
import {
  AlarmPanelFormSection,
  HubFormSection,
  PathTableFormSection,
  WirelessReceiverForm,
  ZoneExapnderForm,
} from 'pages/security/security.service.models';
import { onAddItem, onRemoveItem } from 'app/utils/form-group-array';
import { ZoneExpanderSectionFormComponent } from './zone-expander-section-form/zone-expander-section-form.component';
import { WirelessReceiverSectionFormComponent } from './wireless-receiver/wireless-receiver-section-form.component';
import { PathTableSectionFormComponent } from './path-table/path-table-section-form.component';
import { AlarmPanelTasksComponent } from './alarm-panel-tasks/alarm-panel-tasks.component';
import { BatteriesComponent } from './batteries/batteries.component';
import { BatteryTasksComponent } from './battery-tasks/battery-tasks.component';
import { KeypadsComponent } from './keypads/keypads.component';
import { KeypadTasksComponent } from './keypad-tasks/keypad-tasks.component';
import { SensorsComponent } from './sensors/sensors.component';
import { SensorsTasksComponent } from './sensors-tasks/sensors-tasks.component';
import { HubSectionFormComponent } from './hub/hub-section-form.component';
import { HubTasksComponent } from './hub-tasks/hub-tasks.component';
import { MatInputModule } from '@angular/material/input';
import { CommonTextAreaComponent } from '../../../shared/material-wrappers/common-text-area/common-text-area.component';

@Component({
  selector: 'ads-alarm',
  standalone: true,
  imports: [
    CommonModule,
    SimpleFormComponent,
    FormsModule,
    MatInputModule,
    ExpansionPanelComponent,
    NestedMultipleFormsSectionComponent,
    AlarmSectionFormComponent,
    ZoneExpanderSectionFormComponent,
    WirelessReceiverSectionFormComponent,
    PathTableSectionFormComponent,
    AlarmPanelTasksComponent,
    BatteriesComponent,
    BatteryTasksComponent,
    KeypadsComponent,
    KeypadTasksComponent,
    SensorsComponent,
    SensorsTasksComponent,
    HubSectionFormComponent,
    HubTasksComponent,
    CommonTextAreaComponent,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './alarm.component.html',
})
export class AlarmComponent implements AfterViewInit {
  @Input() formGroup?: FormGroup | null;
  @Input() submitted = false;
  expandedList: number[] = [];

  constructor(public readonly security: SecurityService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      const tasksSection = this.formGroup?.controls['alarmPanelTasks'] as FormGroup;
      const batteriesSection = this.formGroup?.controls['batteries'] as FormGroup;
      const batteryTasksSection = this.formGroup?.controls['batteryTasks'] as FormGroup;
      const keypadsSection = this.formGroup?.controls['keypads'] as FormGroup;
      const keypadTasksSection = this.formGroup?.controls['keypadTasks'] as FormGroup;
      const sensorsSection = this.formGroup?.controls['sensors'] as FormGroup;
      const sensorsTasksSection = this.formGroup?.controls['sensorsTasks'] as FormGroup;

      tasksSection.setValue(this.security.alarmData.alarmPanelTasks);
      batteriesSection.setValue(this.security.alarmData.batteries);
      batteryTasksSection.setValue(this.security.alarmData.batteryTasks);
      keypadsSection.setValue(this.security.alarmData.keypads);
      keypadTasksSection.setValue(this.security.alarmData.keypadTasks);
      sensorsSection.setValue(this.security.alarmData.sensors);
      sensorsTasksSection.setValue(this.security.alarmData.sensorsTasks);
      this.formGroup?.controls['platformTasks'].setValue(this.security.alarmData.platformTasks);
      this.formGroup?.controls['tellerTasks'].setValue(this.security.alarmData.tellerTasks);
      this.formGroup?.controls['comments'].setValue(this.security.alarmData.comments);

      if (this.submitted) {
        this.formGroup?.disable();
      }
    });
  }

  getSection(groupName: string, sectionName: string) {
    const fg = this.formGroup?.controls[groupName] as FormGroup | undefined;
    return fg?.controls[sectionName] as FormGroup | undefined;
  }

  getFormSection(sectionName: string) {
    return this.formGroup?.controls[sectionName] as FormGroup | undefined;
  }

  getFormControls(formGroupName: string): Record<string, AbstractControl<string, string>> {
    const fg = this.formGroup?.get(formGroupName) as FormGroup;
    return fg ? fg.controls : {};
  }

  getHubStatus = (): 'valid' | 'pristine' | 'dirty' => {
    const hubSections = this.formGroup?.controls['hubSections'];
    const platformSections = this.formGroup?.controls['platformSections'];
    const tellerTasks = this.formGroup?.controls['tellerTasks'];
    const platFormTasks = this.formGroup?.controls['platformTasks'];

    const allHubSections = [hubSections, platformSections, tellerTasks, platFormTasks];

    if (this.formGroup?.disabled) {
      return 'valid';
    }

    if (allHubSections.every((section) => section !== undefined)) {
      if (allHubSections.every((section) => section?.valid)) {
        return 'valid';
      }
      if (this.isFormPristine(platformSections, hubSections)) {
        return 'pristine';
      }
    }
    return 'dirty';
  };

  isFormPristine = (
    platformSections: AbstractControl | undefined,
    hubSections: AbstractControl | undefined
  ): boolean => {
    return (
      (hubSections &&
        platformSections &&
        Object.values(hubSections.value).length === 0 &&
        Object.values(platformSections.value).length === 0 &&
        [hubSections, platformSections].every((section) => section?.pristine)) ??
      false
    );
  };

  onAddAlarmClick = () => onAddItem(this.security.alarmData.alarmPanelSections, (index) => new AlarmPanelFormSection(index));
  onZoneExpanderClick = () => onAddItem(this.security.alarmData.zoneExpanderSections, (index) => new ZoneExapnderForm(index));
  onWirelessReceiverClick = () => onAddItem(this.security.alarmData.wirelessReceiverSections, (index) => new WirelessReceiverForm(index));
  onPathAddClick = () => onAddItem(this.security.alarmData.pathTableSections, (index) => new PathTableFormSection(index));
  onHubAddClick = () => onAddItem(this.security.alarmData.hubSections, (index) => new HubFormSection(index));
  onPlatformAddClick = () => onAddItem(this.security.alarmData.platformSections, (index) => new HubFormSection(index));

  onRemoveAlarm = (item: AlarmPanelFormSection) => onRemoveItem(this.security.alarmData.alarmPanelSections, item);
  onRemoveZoneExpander = (item: ZoneExapnderForm) => onRemoveItem(this.security.alarmData.zoneExpanderSections, item);
  onRemoveWirelessReceiver = (item: WirelessReceiverForm) => onRemoveItem(this.security.alarmData.wirelessReceiverSections, item);
  onRemovePath = (item: PathTableFormSection) => onRemoveItem(this.security.alarmData.pathTableSections, item);
  onRemoveHub = (item: HubFormSection) => onRemoveItem(this.security.alarmData.hubSections, item);
  onRemovePlatform = (item: HubFormSection) => onRemoveItem(this.security.alarmData.platformSections, item);

  getList() {
    return this.security.alarmData.alarmPanelSections;
  }
}
