import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LayoutNavService } from '../layout-nav.service';
import { NavigationHandler, NavigationRoutesEnum } from 'utils';
import { Router } from '@angular/router';

@Component({
  selector: 'ads-nav-button',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './nav-button.component.html',
  styleUrl: './nav-button.component.scss',
})
export class NavButtonComponent {
  @Input() title = '';
  @Input() icon = '';
  @Input({ required: true }) navIndex!: number;
  @Input() isDesktop = false;
  @Input() overrideColor = false;

  constructor(public layoutNavService: LayoutNavService, private readonly router: Router) {}

  onClick() {
    const index = this.navIndex as keyof typeof NavigationHandler;

    if (index === NavigationRoutesEnum.Home) {
      this.layoutNavService.goHome();
      return;
    }

    if (NavigationHandler[index]) {
      this.layoutNavService.selectedIndex = this.navIndex;
      this.router.navigate([NavigationHandler[index]]);
      this.layoutNavService.iframeNavigateTo(NavigationHandler[index]);
    }
  }
}
