import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { tcdTcrPhotoUploads } from 'app/utils/tcd-tcr-form-constants';
import { PhotosSectionComponent } from 'forms/shared/photos-section/photos-section.component';
import { PhotoUploadBase } from 'models';
import { TcdTcrService } from 'pages/tcd-tcr-pm/tcd-tcr-pm.service';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { TcdTcrDropdowns } from 'models/atm/tcd-tcr-dropdowns';
import { TcdTcrFormSection } from 'pages/tcd-tcr-pm/tcd-tcr.models';

@Component({
  selector: 'ads-tcd-tcr-section',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CommonFormInputComponent,
    MatFormFieldModule,
    MatInputModule,
    SelectComponent,
    PhotosSectionComponent,
    CommonTextAreaComponent,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './tcd-tcr-section.component.html',
  styleUrl: './tcd-tcr-section.component.scss',
})
export class TcdTcrSectionComponent implements AfterViewInit {
  @Input() ngSectionName = '';
  @Input() item?: TcdTcrFormSection;
  @Input() section?: FormGroup;
  @Input() photoUploadForm!: FormGroup;
  @Input() tcdTcrLists?: TcdTcrDropdowns;

  photoUploads: PhotoUploadBase[] = tcdTcrPhotoUploads;

  constructor(private readonly tcdTcrService: TcdTcrService) {}

  ngAfterViewInit(): void {
    if (this.item) {
      this.tcdTcrService.loadPhotos(this.item).subscribe((photos) => {
        this.photoUploadForm?.setValue(photos);
      });
    }
    setTimeout(() => {
      if (this.tcdTcrLists) {
        this.tcdTcrLists.cassettesRollersList = this.tcdTcrService.allQtyOptions.map((item) => ({
          name: item.value.toString(),
          value: item.value.toString(),
        }));
      }
    });
  }

  displayOtherManufacturer = () => {
    const manufacturer = this.section?.get('manufacturer');
    return Boolean(manufacturer && manufacturer.value === 'Other');
  };

  displayOtherCommunicationType = () => {
    const manufacturer = this.section?.get('communicationType');
    return Boolean(manufacturer && manufacturer.value === 'Other');
  };

  displayEquipmentRating(): boolean {
    return this.item?.overallCondition === 'Poor Condition' || this.item?.overallCondition === 'Failed';
  }

  onTypeChange(type: string) {
    if (this.tcdTcrLists) {
      if (type === 'TCD' || type === 'TCR') {
        this.tcdTcrLists.cassettesRollersList = this.tcdTcrService.allQtyOptions
          .filter((item) => item.type === type)
          .map((item) => ({
            name: item.value.toString(),
            value: item.value.toString(),
          }));
      }
    }
  }
}
