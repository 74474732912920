import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteActivatedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { debounce } from 'app/decorators/debounce.decorator';
import { RMARequestService } from '../rma-request.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ads-search-order',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatIconModule,
    CommonModule,
  ],
  templateUrl: './search-order.component.html',
  styleUrl: './search-order.component.scss',
})
export class SearchOrderComponent implements OnInit {
  @Input() type: 'WO' | 'PRJ' = 'WO';

  myControl = new FormControl('');

  showNote = false;

  constructor(protected readonly rmaRequestService: RMARequestService) {}

  ngOnInit() {
    this.myControl.valueChanges.subscribe((value) => {
      this._filter(value);
    });
  }

  @debounce(550)
  private _filter(value: string | null) {
    if (!value) return;

    if (value.length >= 4) {
      this.getFilteredOptions(value);

      this.showNote = false;
    } else {
      this.showNote = true;
    }
  }

  onSelectOption = (event: MatAutocompleteActivatedEvent) => {
    this.rmaRequestService.selectedControl.set(this.type + '-' + event.option?.value);
  };

  private readonly getFilteredOptions = (value: string) => {
    if (this.type === 'WO') {
      this.rmaRequestService.getServiceOrders(value).subscribe((data) => {
        this.rmaRequestService.filteredOptions.set(data);
      });
    } else {
      this.rmaRequestService.getProjects(value).subscribe((data) => {
        this.rmaRequestService.filteredOptions.set(data);
      });
    }
  };
}
