import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { FormsModule, ControlContainer, NgForm, FormGroup, NgModelGroup } from '@angular/forms';
import { SelectOption, YesNo } from 'models';
import { CommonFormInputComponent, SelectComponent, RadioButtonGroupComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-itm-software-section',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CommonFormInputComponent,
    SimpleFormComponent,
    SelectComponent,
    RadioButtonGroupComponent,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './itm-software-section.component.html',
})
export class ItmSoftwareSectionComponent {
  @Input() section?: FormGroup;
  @Input() operatingSystemsList: SelectOption<string>[] = [];

  constructor(@Optional() public readonly ngModelGroup: NgModelGroup) {}

  options: SelectOption<YesNo>[] = [
    { name: 'Yes', value: YesNo.Yes },
    { name: 'No', value: YesNo.No },
  ];
}
