import { Component, HostListener } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ads-fab-button',
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  templateUrl: './fab-button.component.html',
  styleUrl: './fab-button.component.scss',
})
export class FabButtonComponent {
  showSouth = true;

  onFabClick = () => {
    if (this.showSouth) {
      scrollTo({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    } else {
      scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  @HostListener('document:scroll', ['$event'])
  public onViewportScroll() {
    const isAtBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
    this.showSouth = !isAtBottom;
  }
}
