<span *ngIf="leftLabel !== ''">
  {{ leftLabel }}
</span>
<mat-button-toggle
  class="none-apply-button"
  [checked]="value"
  [disabled]="disabled"
  (click)="onModelChange()"
  (keyup)="onKeyPress($event)"
>
  <mat-icon *ngIf="value" [inline]="true">check</mat-icon>
  {{ buttonLabel }}
</mat-button-toggle>
