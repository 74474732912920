import { getAreAllNa } from 'app/utils/areAllNa';
import { AudioVideoFormSection } from 'forms/security/audio-video/audio-video.component';
import { SecurityAudioMatrixTasksDto } from 'models/api/security/audio-video/audio-matrix-tasks';
import { SecurityAudioVideoSectionDto } from 'models/api/security/audio-video/audio-video-dto';
import { SecurityAudioVideoSectionsDto } from 'models/api/security/security';
import { AudioMatrixTasks } from 'models/security/audio-video/audioMatrixTasks';
import { AudioVideoSecurityForm } from 'models/security/audio-video/audioVideoSecurityForm';

export const mapAudioVideoSectionsResponse = (panels: SecurityAudioVideoSectionDto[]): AudioVideoFormSection[] => {
  if (panels.length === 0) {
    return [new AudioVideoFormSection(1)];
  }
  return panels.map((panel, index) => ({
    audioMatrixModel: panel.audioMatrixModel,
    manufacturer: panel.audioMatrixManufacturer,
    otherManufacturer: panel.audioMatrixManufacturer_Other,
    overallCondition: panel.overallConditionAudioMatrix,
    overallConditionComments: panel.equipment_Rating_AudioMatrix,
    style: panel.style,
    tellerConsolesAmount: panel.quantityOfTellerConsoles ? panel.quantityOfTellerConsoles.toString() : null,
    videoSystem: panel.selectVideoSystem,
    wirelessHeadsetAmount: panel.quantityOfWirelessHeadset ? panel.quantityOfWirelessHeadset.toString() : null,
    index: index + 1,
  }));
};

export const mapSecurityAudioVideoResponse = (data: SecurityAudioVideoSectionsDto): AudioVideoSecurityForm => ({
  audioVideoSections: mapAudioVideoSectionsResponse(data.security_AudioVideoDU_DriveUpAudioMatrix),
  audioMatrixTasks: mapAudioMatrixTasksResponse(data.security_AudioVideoDU_AudioMatrixTasks),
  comments: data.comments ?? null,
});

export const mapAudioMatrixTasksResponse = (data: SecurityAudioMatrixTasksDto[]): AudioMatrixTasks => ({
  allNa: getAreAllNa(data[0]),
  inspectMicrophone: data[0]?.inspectMicrophoneAdSpeakers ?? null,
  inspectTellerConsoles: data[0]?.inspectAndTestTellerConsoles ?? null,
  inspectWiringConnections: data[0]?.inspectAllWiringConnectionForAudioSystem ?? null,
  testCallButtons: data[0]?.testCallButtonForAllLanes ?? null,
  testCustomerGain: data[0]?.testCustomerButtonForAllLanes ?? null,
  testTellerGain: data[0]?.testTellerGainForAllLanes ?? null,
});
