import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';
import { ControlValueAccessor, FormGroup, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ValidateRequiredDirective } from 'app/shared/directives/validate-required.directive';
import { BaseFormInputComponent } from '../form-input-base/form-input.base';

@Component({
  selector: 'ads-common-text-area',
  standalone: true,
  imports: [CommonModule, MatInputModule, MatIconModule, MatButtonModule, ValidateRequiredDirective, FormsModule],
  templateUrl: './common-text-area.component.html',
  styleUrl: './common-text-area.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CommonTextAreaComponent,
    },
  ],
})
export class CommonTextAreaComponent extends BaseFormInputComponent implements ControlValueAccessor, AfterViewInit {
  @Input() override value: string | undefined | null = '';

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.ngModelGroup?.control !== null && this.name !== '') {
        const control =
          this.formGroupName === ''
            ? this.ngModelGroup?.control
            : (this.ngModelGroup?.control.controls[this.formGroupName] as FormGroup);
        control?.registerControl(this.name, this.customInput);

        control?.controls[this.name].valueChanges.subscribe((value) => {
          if (value === null) {
            this.customInput?.control.setValue('');
            this.customInput?.control.markAsPristine();
            this.customInput?.control.markAsUntouched();
          }
          this.value = value;

          if (value) {
            this.onTouched();
          }
        });
      }
    });
  }
}
