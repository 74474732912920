import { FormGroup } from "@angular/forms";

export const photosStatuses = (photoUploadForm: FormGroup[]) => {
  return photoUploadForm.map((photoField) => {
    if (photoField.valid) {
      return 'valid';
    }
    if (photoField.touched) {
      return 'dirty';
    }
    return 'pristine';
  });
};
