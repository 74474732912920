<ads-simple-form #alarm="ngModelGroup" ngModelGroup="alarm">
  <ng-container ngModelGroup="alarmPanels">
    <ads-nested-multiple-forms-section
      #alarmSectionsPanels
      title="Alarm Panel"
      [formTemplate]="alarmPanelFormTemplate"
      [list]="security.alarmData.alarmPanelSections"
      [formControls]="getFormControls('alarmPanels')"
      [groupName]="'alarmPanel'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddAlarmClick()"
      (deleteClicked)="onRemoveAlarm($event)"
    >
    </ads-nested-multiple-forms-section>
    <ng-template #alarmPanelFormTemplate let-item="item">
      <ads-alarm-section-form
        [alarmLists]="security.alarmData.alarmDropdowns"
        [section]="getSection('alarmPanels', 'alarmPanel#' + item.index)"
        [item]="item"
      ></ads-alarm-section-form>
    </ng-template>
  </ng-container>

  <ng-container ngModelGroup="zoneExpanderSections">
    <ads-nested-multiple-forms-section
      #zoneExpanders
      title="Zone Expander"
      [formTemplate]="zoneExpanderformTemplate"
      [list]="security.alarmData.zoneExpanderSections"
      [formControls]="getFormControls('zoneExpanderSections')"
      [groupName]="'zoneExpanderSection'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onZoneExpanderClick()"
      (deleteClicked)="onRemoveZoneExpander($event)"
    >
    </ads-nested-multiple-forms-section>
    <ng-template #zoneExpanderformTemplate let-item="item">
      <ads-zone-expander-section-form
        [section]="getSection('zoneExpanderSections', 'zoneExpanderSection#' + item.index)"
        [item]="item"
      ></ads-zone-expander-section-form>
    </ng-template>
  </ng-container>

  <ng-container ngModelGroup="wirelessReceiverSections">
    <ads-nested-multiple-forms-section
      #wirelessReceiver
      title="Wireless Receiver"
      [formTemplate]="wirelessReceiverFormTemplate"
      [list]="security.alarmData.wirelessReceiverSections"
      [formControls]="getFormControls('wirelessReceiverSections')"
      [groupName]="'wirelessReceiverSection'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onWirelessReceiverClick()"
      (deleteClicked)="onRemoveWirelessReceiver($event)"
    >
    </ads-nested-multiple-forms-section>
    <ng-template #wirelessReceiverFormTemplate let-item="item">
      <ads-wireless-receiver-section-form
        [section]="getSection('wirelessReceiverSections', 'wirelessReceiverSection#' + item.index)"
        [item]="item"
      ></ads-wireless-receiver-section-form>
    </ng-template>
  </ng-container>

  <ng-container ngModelGroup="pathTableSections">
    <ads-nested-multiple-forms-section
      #pathTable
      title="Paths"
      [formTemplate]="pathTableFormTemplate"
      [list]="security.alarmData.pathTableSections"
      [formControls]="getFormControls('pathTableSections')"
      [groupName]="'pathTableSection'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onPathAddClick()"
      (deleteClicked)="onRemovePath($event)"
    >
    </ads-nested-multiple-forms-section>
    <ng-template #pathTableFormTemplate let-item="item">
      <ads-path-table-section-form
        [alarmLists]="security.alarmData.alarmDropdowns"
        [section]="getSection('pathTableSections', 'pathTableSection#' + item.index)"
        [item]="item"
      ></ads-path-table-section-form>
    </ng-template>
  </ng-container>

  <ads-expansion-panel
    #alarmPanelTasksPanel
    title="Panel Tasks"
    [formGroup]="formGroup"
    [name]="'alarmPanelTasks'"
    [class]="'alarmPanelTasks'"
  >
    <ads-alarm-panel-tasks [section]="getFormSection('alarmPanelTasks')"></ads-alarm-panel-tasks>
  </ads-expansion-panel>

  <ads-expansion-panel
    #batteriesPanel
    title="Batteries"
    [formGroup]="formGroup"
    [name]="'batteries'"
    [class]="'batteries'"
  >
    <ads-batteries [section]="getFormSection('batteries')"></ads-batteries>
  </ads-expansion-panel>

  <ads-expansion-panel
    #batteryTasksPanel
    title="Battery Tasks"
    [formGroup]="formGroup"
    [name]="'batteryTasks'"
    [class]="'batteryTasks'"
  >
    <ads-battery-tasks [section]="getFormSection('batteryTasks')"></ads-battery-tasks>
  </ads-expansion-panel>

  <ads-expansion-panel
    #keypadsPanel
    title="Keypads"
    [formGroup]="formGroup"
    [name]="'keypads'"
    [class]="'keypads'"
  >
    <ads-keypads [section]="getFormSection('keypads')"></ads-keypads>
  </ads-expansion-panel>

  <ads-expansion-panel
    #keypadTasksPanel
    title="Keypad Tasks"
    [formGroup]="formGroup"
    [name]="'keypadTasks'"
    [class]="'keypadTasks'"
  >
    <ads-keypad-tasks [section]="getFormSection('keypadTasks')"></ads-keypad-tasks>
  </ads-expansion-panel>

  <ads-expansion-panel
    #keypadsPanel
    title="Sensors"
    [formGroup]="formGroup"
    [name]="'sensors'"
    [class]="'sensors'"
  >
    <ads-sensors [section]="getFormSection('sensors')"></ads-sensors>
  </ads-expansion-panel>

  <ads-expansion-panel
    #sensorsTasksPanel
    title="Sensors Tasks"
    [formGroup]="formGroup"
    [name]="'sensorsTasks'"
    [class]="'sensorsTasks'"
  >
    <ads-sensors-tasks [section]="getFormSection('sensorsTasks')"></ads-sensors-tasks>
  </ads-expansion-panel>

  <ads-expansion-panel
    #hubsPanel
    title="HUBs & HUB tasks"
    [name]="'hubsTasks'"
    [class]="'hubsTasks'"
    [customStatus]="getHubStatus"
  >
    <ng-container ngModelGroup="hubSections">
      <ads-nested-multiple-forms-section
        #tellersPanel
        title="Teller hold up types"
        itemTitle="Teller type"
        [formTemplate]="hubFormTemplate"
        [list]="security.alarmData.hubSections"
        [formControls]="getFormControls('hubSections')"
        [groupName]="'hubSection'"
        [expandedList]="expandedList"
        [submitted]="submitted"
        (addClicked)="onHubAddClick()"
        (deleteClicked)="onRemoveHub($event)"
        [minItems]="0"
      >
      </ads-nested-multiple-forms-section>
      <ng-template #hubFormTemplate let-item="item">
        <ads-hub-section-form
          [alarmLists]="security.alarmData.alarmDropdowns"
          [section]="getSection('hubSections', 'hubSection#' + item.index)"
          [item]="item"
        ></ads-hub-section-form>
      </ng-template>
    </ng-container>
    <ads-hub-tasks [section]="getFormSection('tellerTasks')"></ads-hub-tasks>

    <ng-container ngModelGroup="platformSections">
      <ads-nested-multiple-forms-section
        #platformsPanel
        title="Platform hold up types"
        itemTitle="Platform type"
        [formTemplate]="platformFormTemplate"
        [list]="security.alarmData.platformSections"
        [formControls]="getFormControls('platformSections')"
        [groupName]="'hubSection'"
        [expandedList]="expandedList"
        [submitted]="submitted"
        (addClicked)="onPlatformAddClick()"
        (deleteClicked)="onRemovePlatform($event)"
      >
      </ads-nested-multiple-forms-section>
      <ng-template #platformFormTemplate let-item="item">
        <ads-hub-section-form
          [alarmLists]="security.alarmData.alarmDropdowns"
          [section]="getSection('platformSections', 'hubSection#' + item.index)"
          [item]="item"
        ></ads-hub-section-form>
      </ng-template>
    </ng-container>
    <ads-hub-tasks type="platform" [section]="getFormSection('platformTasks')"></ads-hub-tasks>
  </ads-expansion-panel>
  <ads-common-text-area
    name="comments"
    label="Comments (optional)"
    [maxLength]="200"
    ngModel
  ></ads-common-text-area>
</ads-simple-form>
