import { Component, Input } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, FormsModule, NgModelGroup } from '@angular/forms';
import { VaultSectionFormSection } from '../vault.component';
import { CommonModule } from '@angular/common';
import { CommonFormInputComponent, RadioButtonGroupComponent, SelectComponent } from 'shared/material-wrappers';
import { SelectOption, YesNo } from 'models';
import { NestedMultipleFormsSectionComponent } from 'forms/shared/nested-multiple-forms-section/nested-multiple-forms-section.component';
import { getFormGroup } from 'utils';
import { MatDivider } from '@angular/material/divider';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { VaultComboLockSection } from 'models/security/vault/combo-lock';
import { VaultTimeClockSection } from 'models/security/vault/time-clock';
import { VaultComboLocksSectionComponent } from '../vault-combo-locks-section/vault-combo-locks-section.component';
import { VaultTimeClocksSectionComponent } from '../vault-time-clocks-section/vault-time-clocks-section.component';
import { CommonTextAreaComponent } from '../../../../shared/material-wrappers/common-text-area/common-text-area.component';
import { onAddItem, onRemoveItem } from 'app/utils/form-group-array';

@Component({
  selector: 'ads-vault-section-form',
  standalone: true,
  imports: [
    MatDivider,
    CommonModule,
    FormsModule,
    CommonFormInputComponent,
    SelectComponent,
    SimpleFormComponent,
    NestedMultipleFormsSectionComponent,
    VaultComboLocksSectionComponent,
    VaultTimeClocksSectionComponent,
    RadioButtonGroupComponent,
    CommonTextAreaComponent,
  ],
  templateUrl: './vault-section-form.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class VaultSectionFormComponent {
  @Input() submitted = false;
  @Input() vaultLists?: VaultLists;
  @Input() section?: FormGroup;
  @Input() item?: VaultSectionFormSection;

  expandedList: number[] = [];
  readonly yesNo = YesNo;

  displayOther = () => {
    return this.item?.manufacturer === 'Other';
  };

  displayEquipmentRating = () => {
    return this.item?.overallCondition === 'Poor Condition' || this.item?.overallCondition === 'Failed';
  };

  displayVentilatorEquipmentRating = () => {
    return (
      this.item?.ventilatorOverallCondition === 'Poor Condition' || this.item?.ventilatorOverallCondition === 'Failed'
    );
  };

  getFormControls(containerName: string): Record<string, AbstractControl<string, string>> {
    const fg = this.section?.get(containerName) as FormGroup;
    return fg ? fg.controls : {};
  }

  yesNoOptions: SelectOption<YesNo>[] = [
    { name: 'Yes', value: YesNo.Yes },
    { name: 'No', value: YesNo.No },
  ];

  getSection(sectionName: string, containerName: string) {
    return getFormGroup(this.section?.controls[containerName])?.controls[sectionName] as FormGroup;
  }

  onAddComboLock = () => onAddItem(this.item?.comboLocks ?? [], (index) => new VaultComboLockSection(index));
  onAddTimeClock = () => onAddItem(this.item?.timeClocks ?? [], (index) => new VaultTimeClockSection(index));

  onRemoveComboLock = (item: VaultComboLockSection) => onRemoveItem(this.item?.comboLocks ?? [], item);
  onRemoveTimeClock = (item: VaultTimeClockSection) => onRemoveItem(this.item?.timeClocks ?? [], item);
}

export interface VaultLists {
  manufacturerList: SelectOption<string | number>[];
  creepDoorClosesList: SelectOption<string | number>[];
  overallConditionsList: SelectOption<string | number>[];
  typesList: SelectOption<string | number>[];

}
