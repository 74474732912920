<div class="title-container">
  <span class="title">Open Calls at this Location</span>
</div>

<div class="container">
  <div *ngIf="isLoading()" class="loading">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
  @for (call of calls(); track calls()) {

  <div class="attachments-container">
    <div class="left">
      <span>Work Order</span>
      <span>PO</span>
      <span>Technician</span>
      <span>Call Type</span>
      <span>Problem</span>
      <span>Priority</span>
      <span>Status</span>
      <span>Date Opened</span>
      <span>Due Date</span>
    </div>

    <div class="right">
      <span>{{ call.workorder }}</span>
      <span>{{ call.jo || '-' }}</span>
      <span>{{ call.technician || '-' }}</span>
      <span>{{ call.calltype || '-' }}</span>
      <span>{{ call.problem || '-' }}</span>
      <span>{{ call.priority || '-' }}</span>
      <span>{{ call.laststatusname || '-' }}</span>
      <span>{{ call.dateopened | date : 'MM/dd/yyyy' }}</span>
      <span>{{ call.servicedatetime | date : 'MM/dd/yyyy' }}</span>
    </div>
  </div>
  }
  <div *ngIf="calls().length === 0 && !isLoading" class="no-calls">No open calls at this moment</div>
</div>

<button class="ok-b" mat-button mat-stroked-button (click)="closeDialog()">Ok</button>
