import { HttpParams } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Line, SubmitRmaRequestCommand } from 'models/rma-request/rma-request';
import { CustomHttpUrlEncodingCodec } from 'pages/home/home.service';
import { Observable } from 'rxjs';
import { ApiService } from 'services';

interface ShippingMethodItem {
  Code?: string;
  Txt?: string;
}

export interface ShippingAddress {
  InventLocationId?: string;
  InventSiteId?: string;
  MyTruckStockDefault?: string;
  MyMainStockDefault?: string;
}

export interface SearchResponse {
  ServiceOrderId: string | null;
  ProjectId: string | null;
  MasterCustomerName: string | null;
  ProblemCode: string | null;
  ProjectGroup: string | null;
  CustomerNameAlias: string | null;
}

export interface SearchPartResponse {
  ItemId: string | null;
  Name: string | null;
  Rank: string | null;
  ItemIdWithName: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class RMARequestService {
  parts = signal([] as Line[]);
  selectedControl = signal('');
  filteredOptions = signal([] as SearchResponse[]);

  selectedShipTo: string | null = null;
  selectedShippingMethod: string | null = null;
  shipToAddress = '';
  description = '';
  selectedIndex = signal(0);

  constructor(private readonly service: ApiService) {}

  public clearEntries(): void {
    this.parts.set([]);
    this.selectedControl.set('');
    this.selectedShipTo = null;
    this.selectedShippingMethod = null;
    this.shipToAddress = '';
    this.description = '';
    this.selectedIndex.set(0);
  }

  /**
   * Retrieve a list with the shipping methods.
   *
   */
  public getPartRequestShippingMethod(): Observable<ShippingMethodItem[]> {
    return this.service.sendRequest<ShippingMethodItem[]>({
      method: 'get',
      url: 'part-request/shipping-methods',
      urlPrefix: 'ax',
    });
  }

  /**
   * Retrieve a list with the shipping addresses.
   */
  public getShippingAddresses(inventLocationId: string): Observable<ShippingAddress> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() }).set(
      'inventLocationId',
      inventLocationId,
    );

    return this.service.sendRequest<ShippingAddress>({
      method: 'get',
      url: 'part-request/shipping-address',
      params: queryParameters,
      urlPrefix: 'ax',
    });
  }

  public getServiceOrders(searchText: string): Observable<SearchResponse[]> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() }).set(
      'searchText',
      'WO-' + searchText,
    );

    return this.service.sendRequest<SearchResponse[]>({
      method: 'get',
      url: 'part-request/service-orders',
      params: queryParameters,
      urlPrefix: 'ax',
    });
  }

  public getProjects(searchText: string): Observable<SearchResponse[]> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() }).set(
      'searchText',
      'PRJ-' + searchText,
    );

    return this.service.sendRequest<SearchResponse[]>({
      method: 'get',
      url: 'part-request/projects',
      params: queryParameters,
      urlPrefix: 'ax',
    });
  }

  public searchParts(searchText: string): Observable<SearchPartResponse[]> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() }).set('searchText', searchText);

    return this.service.sendRequest<SearchPartResponse[]>({
      method: 'get',
      url: 'part-request/search',
      params: queryParameters,
      urlPrefix: 'ax',
    });
  }

  public submitRMA(data: SubmitRmaRequestCommand): Observable<void> {
    const body = new FormData();
    body.append('request', JSON.stringify(data));

    return this.service.sendRequest<void>({
      method: 'post',
      url: 'lines/submitrmarequest',
      body,
      urlPrefix: 'ax',
    });
  }
}
