import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, NgModelGroup } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { SelectOption, CompleteNa } from 'models';
import { AccessControlPowerSupplyTasksForm } from 'models/security/access-hardware/accessHardwareSecurityForm';
import { RadioButtonGroupComponent, SingleToggleButtonComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { checkAllOptions, checkIfAllAreNa } from 'app/utils/select-all';

@Component({
  selector: 'ads-ah-power-supply-tasks-section',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SimpleFormComponent,
    RadioButtonGroupComponent,
    MatDividerModule,
    SingleToggleButtonComponent,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
  templateUrl: './ah-power-supply-tasks-section.component.html',
})
export class AhPowerSupplyTasksSectionComponent {
  @Input() section?: FormGroup;

  selectAllChecked = false;
  fields = Object.getOwnPropertyNames(new AccessControlPowerSupplyTasksForm());

  options: SelectOption<CompleteNa>[] = [
    { name: 'Complete', value: CompleteNa.Complete },
    { name: 'N/A', value: CompleteNa.Na },
  ];

  onOptionClicked = () => checkIfAllAreNa(this.fields, this.section);
  onSelectAllChange = (shouldSelectAll: boolean) => checkAllOptions(shouldSelectAll, this.fields, this.section);

}
