import { ChartConfiguration } from 'chart.js';
import { ThemingService } from 'services';

export const homeBarChartOptions = (theming: ThemingService): ChartConfiguration<'bar'>['options'] => ({
  responsive: true,
  indexAxis: 'y',
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'end',
      font: {
        size: 14,
      },
      color: theming.selectedOption,
    },
  },
  scales: {
    x: {
      ticks: {
        display: true,
        color: theming.selectedOption,
        font: {
          size: 14,
        },
        callback: (label) => {
          const value = typeof label === 'string' ? parseInt(label) : label;
          if (value > 100) {
            return '';
          }
          return `${value}`;
        },
        stepSize: 20,
      },
      grid: {
        display: true,
        color: theming.inputBorder,
      },
    },
    y: {
      ticks: {
        color: theming.selectedOption,
        font: {
          size: 13,
        },
      },
      grid: {
        display: false,
      },
    },
  },
});
