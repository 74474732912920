<ads-order-info [order]="workOrder" [customerName]="customerName"></ads-order-info>

<form #atmForm="ngForm" (ngSubmit)="onSubmit()">
  <ads-expansion-panel
    #atmSpecsSectionPanel
    title="ATM Specs"
    [formGroup]="form?.form"
    [name]="'atmSpecs'"
    [class]="'atmSpecs'"
  >
    <ads-atm-specs-section
      [section]="getSection('atmSpecs')"
      [atmManufacturerList]="dropdowns.specsManufacturerList"
      [depositorTypeList]="dropdowns.specsDepositorsList"
      [communicationTypeList]="dropdowns.communicationTypesList"
      [overallConditionList]="dropdowns.specsOverallConditionsList"
    ></ads-atm-specs-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #atmSoftwareSectionPanel
    title="Software"
    [formGroup]="form?.form"
    [name]="'software'"
    [class]="'software'"
  >
    <ads-atm-software-section
      [section]="getSection('software')"
      [operatingSystemsList]="dropdowns.operatingSystemsList"
    ></ads-atm-software-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #atmTasksSectionPanel
    title="ATM Tasks"
    [formGroup]="form?.form"
    [name]="'atmTasks'"
    [class]="'atmTasks'"
  >
    <ads-atm-tasks-section [section]="getSection('atmTasks')"></ads-atm-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #atmCardReaderTasksSectionPanel
    title="Card Reader Tasks"
    [formGroup]="form?.form"
    [name]="'cardReaderTasks'"
    [class]="'cardReaderTasks'"
  >
    <ads-atm-card-reader-tasks-section [section]="getSection('cardReaderTasks')"></ads-atm-card-reader-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #atmEnvDepDispTasksSectionPanel
    title="Envelope Depository & Dispenser Tasks"
    [formGroup]="form?.form"
    [name]="'envDepDispTasks'"
    [class]="'envDepDispTasks'"
  >
    <ads-atm-env-dep-disp-tasks-section [section]="getSection('envDepDispTasks')"></ads-atm-env-dep-disp-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #atmReceiptPrinterTasksSectionPanel
    title="Receipt Printer Tasks"
    [formGroup]="form?.form"
    [name]="'receiptPrinterTasks'"
    [class]="'receiptPrinterTasks'"
  >
    <ads-atm-receipt-printer-tasks-section
      [section]="getSection('receiptPrinterTasks')"
    ></ads-atm-receipt-printer-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #atmCashDispenserTasksSectionPanel
    title="Cash Dispenser Tasks"
    [formGroup]="form?.form"
    [name]="'cashDispenserTasks'"
    [class]="'cashDispenserTasks'"
  >
    <ads-atm-cash-dispenser-tasks-section
      [section]="getSection('cashDispenserTasks')"
    ></ads-atm-cash-dispenser-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #atmFasciaAdaTasksSectionPanel
    title="Fascia & ADA Tasks"
    [formGroup]="form?.form"
    [name]="'fasciaAdaTasks'"
    [class]="'fasciaAdaTasks'"
  >
    <ads-atm-fascia-ada-tasks-section [section]="getSection('fasciaAdaTasks')"></ads-atm-fascia-ada-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #atmCashDepositoryTasksSectionPanel
    title="Cash Depository Tasks"
    [formGroup]="form?.form"
    [name]="'cashDepositoryTasks'"
    [class]="'cashDepositoryTasks'"
  >
    <ads-atm-cash-depository-tasks-section
      [section]="getSection('cashDepositoryTasks')"
    ></ads-atm-cash-depository-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #atmCheckDepositoryTasksSectionPanel
    title="Check Depository & Cash/Check Depository Tasks"
    [formGroup]="form?.form"
    [name]="'checkDepositoryTasks'"
    [class]="'checkDepositoryTasks'"
  >
    <ads-atm-check-depository-tasks-section
      [section]="getSection('checkDepositoryTasks')"
    ></ads-atm-check-depository-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #atmVacuumBlowOutTasksTasksSectionPanel
    title="Vacuuming & Blow Out (POWER MUST BE OFF)"
    [formGroup]="form?.form"
    [name]="'vacuumBlowOutTasks'"
    [class]="'vacuumBlowOutTasks'"
  >
    <ads-atm-vacuum-blow-out-tasks-section
      [section]="getSection('vacuumBlowOutTasks')"
    ></ads-atm-vacuum-blow-out-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #atmPhotosSectionPanel
    title="Photos"
    [formGroup]="form?.form"
    [name]="'photoUploads'"
    [class]="'photoUploads'"
  >
    <ads-photos-section
      [photoUploads]="photoUploads"
      [photoUploadForm]="getSection('photoUploads')!"
    ></ads-photos-section>
  </ads-expansion-panel>

  <ads-common-text-area name="comments" label="Comments (optional)" [maxLength]="500" ngModel></ads-common-text-area>

  <div class="form-buttons">
    <button mat-button mat-stroked-button (click)="saveForm()" type="button">Save</button>
    <button mat-button mat-flat-button class="main-button" [disabled]="form?.form?.disabled">Submit</button>
  </div>
</form>
<div class="clear"></div>
