import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { statusColors } from 'app/utils/call-control';
import { TimeZonePipe } from 'app/utils/time-zone.pipe';
import { AdsCallControl } from 'models';
import { CurrentWorkOrderService } from 'services/current-work-order.service';

@Component({
  selector: 'ads-work-order-card',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  providers: [DatePipe, TimeZonePipe],
  templateUrl: './work-order-card.component.html',
  styleUrl: './work-order-card.component.scss',
})
export class WorkOrderCardComponent {
  @Input() call!: AdsCallControl;
  @Input() isDesktopView = false;
  @Input() currentTheme: string | null = '';
  @Output() callClicked = new EventEmitter<AdsCallControl>();

  statusColors = statusColors;

  constructor(
    private readonly datepipe: DatePipe,
    private readonly timeZonePipe: TimeZonePipe,
    protected readonly currentWorkOrderService: CurrentWorkOrderService,
  ) {}

  onCallClick() {
    this.callClicked.emit(this.call);
  }

  getDateColor(date: string | Date | undefined): [string, string] {
    const dateToParse= new Date(date!);
    if (dateToParse.toString() === 'Invalid Date') {
      return ['', 'transparent'];
    }

    const formatDateStr = date
      ? this.datepipe.transform(this.timeZonePipe.transform(dateToParse.toString()), 'MM/dd/yyyy hh:mm a')!
      : new Date().toISOString();
    const today = new Date();
    const formatDate = new Date(formatDateStr);
    formatDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    if (formatDate.getTime() === today.getTime()) {
      return ['Today', '#2BC954'];
    }
    if (formatDate < today) {
      return ['Past due', '#B3261E'];
    }
    return ['', 'transparent'];
  }

  getValidDate(date: string | Date | undefined): string {
    const dateToParse = new Date(date!);
    if (dateToParse.toString() === 'Invalid Date') {
      return '';
    }
    return this.datepipe.transform(dateToParse.toString(), 'MM/dd/yyyy hh:mm a')!;
  }
}
