import { Component } from '@angular/core';
import { SecuritySurveillanceDropdowns } from 'models/security/security-dropdowns';
import { SurveillancePOESwitchSection } from '../surveillance.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { TableFormBottomSheetInputComponent } from 'shared/material-wrappers/table-form/table-form-bottom-sheet-input/table-form-bottom-sheet-input.component';
import { TableFormInputComponent } from 'shared/material-wrappers/table-form/table-form-input/table-form-input.component';
import { TableBottomSheetsComponent } from 'forms/shared/table-bottom-sheets/table-bottom-sheets.component';
import { BaseAddManyModalComponent } from 'forms/shared/nested-section-base/add-many-modal.base';

export interface SurveillancePOETableSectionAddMany
  extends Omit<SurveillancePOESwitchSection, 'poeSwitchManufacturer_Other' | 'poeSwitchModel'> {
  quantity: string | undefined;
}

@Component({
  selector: 'ads-surveillance-poe-switch-table-add-many-modal',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogContent,
    MatDialogActions,
    MatTableModule,
    CommonModule,
    FormsModule,
    MatInputModule,
    TableFormInputComponent,
    TableFormBottomSheetInputComponent,
    MatBottomSheetModule,
  ],
  templateUrl: './surveillance-poe-switch-table-add-many-modal.component.html',
  styleUrl: './surveillance-poe-switch-table-add-many-modal.component.scss',
})
export class SurveillancePoeSwitchTableAddManyModalComponent extends BaseAddManyModalComponent {
  displayedColumns = ['quantity', 'poeSwitchManufacturer'];

  dataSource: SurveillancePOETableSectionAddMany[] = [{ quantity: undefined, poeSwitchManufacturer: null, index: 0 }];

  openBottomSheet = (e: unknown) => () => {
    const element = e as SurveillancePOESwitchSection;
    const onDismiss = this._bottomSheet.open(TableBottomSheetsComponent, {
      data: {
        selections: [
          {
            title: 'POE Switch Manufacturer',
            control: 'poeSwitchManufacturer',
            options: (this.data.dropdowns as SecuritySurveillanceDropdowns)?.surveillancePoeSwitchManufacturers,
            value: element.poeSwitchManufacturer,
          },
        ],
      },
    });

    onDismiss.afterDismissed().subscribe((result) => {
      if (result) {
        element.poeSwitchManufacturer = result.poeSwitchManufacturer;
      }
    });
  };
}
