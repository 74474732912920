import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { EquipmentEntityModel } from 'models/equipment/equipment';

@Component({
  selector: 'ads-equipment-card',
  standalone: true,
  imports: [CommonModule, FormsModule, MatIconModule, MatCheckboxModule, MatButtonModule],
  templateUrl: './equipment-card.component.html',
  styleUrl: './equipment-card.component.scss',
})
export class EquipmentCardComponent {
  @Input() item!: EquipmentEntityModel;
  @Input() isSelected = false;
  @Input() canRemove = false;
  @Input() canSelect = false;
  @Output() toggled = new EventEmitter<void>();
  @Output() removeClicked = new EventEmitter<boolean>();

  cardClicked() {
    if(this.canSelect) {
      this.toggled.emit();
    }
  }

}
