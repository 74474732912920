import { EventEmitter, Input, Output, TemplateRef, Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { IndexableFormType } from 'app/utils/miscTypes';

@Component({
  template: '',
})
export abstract class BaseNestedFormsSectionComponent<T extends IndexableFormType> {
  @Input() title = '';
  @Input() itemTitle?: string;
  @Input() groupName = '';
  @Input() list: T[] = [];
  @Input() formTemplate?: TemplateRef<object>;
  @Input() formControls?: Record<string, AbstractControl>;
  @Output() addClicked = new EventEmitter<void>();
  @Output() deleteClicked = new EventEmitter<T>();
  @Input() expandedList: number[] = [];
  @Input() submitted = false;
  @Input() control?: AbstractControl;
  @Input() minItems = 1;
  expanded = false;
  deleting = false;
  deletingItemList: T[] = [];

  onAddClick(event: Event): void {
    this.expanded = true;
    event.preventDefault();
    this.addClicked.emit();
  }

  abstract onDelete(): void;

  onDeleteClick(event: Event): void {
    event.preventDefault();

    if (this.deleting && this.deletingItemList.length > 0) {
      this.onDelete();
    }

    this.deleting = true;
  }

  onCancelDeleteClick(): void {
    this.deleting = false;
  }

  getShowDeleteButton(): boolean {
    return this.list.length > this.minItems && !this.submitted;
  }

  getFormStatus = (index: number) => () => {
    const control = this.formControls?.[this.groupName + '#' + index];

    if (this.expandedList.includes(index)) {
      return 'dirty';
    }

    if (control !== undefined) {
      if (control.disabled) {
        return 'valid';
      }
      if (control.valid) {
        control.markAllAsTouched();
        return 'valid';
      }
      if (control.pristine) {
        return 'pristine';
      }
      if (control.touched) {
        return 'dirty';
      }
    }
    return 'pristine';
  };

  customStatus = (): 'valid' | 'pristine' | 'dirty' => {
    let status = '';
    for (let i = 0; i < (this.list?.length ?? 0); i++) {
      const formStatus = this.getFormStatus(i + 1)();
      if (formStatus === 'dirty') {
        return 'dirty';
      }
      if (formStatus === 'valid') {
        status = 'valid';
      }
    }

    if (status === 'valid') {
      return 'valid';
    }

    return 'pristine';
  };
}
