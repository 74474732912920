import { CommonModule } from '@angular/common';
import { Component, Input, AfterViewInit } from '@angular/core';
import { FormsModule, ControlContainer, NgModelGroup, FormGroup } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { SelectOption, YesNo } from 'models';
import { TimeClockSection } from 'models/security/shared/time-clock';
import { CommonFormInputComponent, RadioButtonGroupComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-time-clocks-section',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CommonFormInputComponent,
    MatDividerModule,
    RadioButtonGroupComponent,
    SimpleFormComponent,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
  templateUrl: './time-clocks-section.component.html',
})
export class TimeClocksSectionComponent implements AfterViewInit {
  @Input() section?: FormGroup;
  @Input() submitted = false;
  @Input() item?: TimeClockSection;

  options: SelectOption<YesNo>[] = [
    { name: 'Yes', value: YesNo.Yes },
    { name: 'No', value: YesNo.No },
  ];

  ngAfterViewInit() {
    setTimeout(() => {
      this.section?.controls['isLinkageConnectedDirectlyToCombolock'].setValue(
        this.item?.isLinkageConnectedDirectlyToCombolock
      );

      if (this.submitted) {
        this.section?.disable();
      }
    });
  }
}
