import { TcdTcrRequest, AdsTcrRequest } from '../../tcd-tcr/ads-tcd-tcr-request';
import {
  Blowout,
  CassetteTasks,
  ExternalAreaTasks,
  FeedModuleTasks,
  PresentModuleTasks,
  TcdTcrDto,
  TcdTcrSectionDto,
  TcdTcrTasksDto,
} from '../../tcd-tcr/tcd-tcr-response';

export const tcdTcrMapRequest = ({
  photosForm,
  tcdTcrSections,
  fieldsForm,
  comments,
  workOrderDetail,
  pageSubmitMode,
  pageOpenMode,
}: TcdTcrRequest): AdsTcrRequest => {
  const tcdTcrTasks: TcdTcrTasksDto = fieldsForm.tcdTcrTasks ? {
    tcdTcrTasks_CheckErrorForAbnormalities: fieldsForm.tcdTcrTasks?.checkError,
    tcdTcrTasks_ConfSheetsPresent: fieldsForm.tcdTcrTasks?.confSheetsPresent,
  } : {
    tcdTcrTasks_CheckErrorForAbnormalities: null,
    tcdTcrTasks_ConfSheetsPresent: null,
  };

  const cassetteTasks: CassetteTasks = fieldsForm.tcdTcrCassetteTasks ? {
    cassetteTasks_CheckCleanBelts: fieldsForm.tcdTcrCassetteTasks.checkBelts,
    cassetteTasks_CheckCleanRollers: fieldsForm.tcdTcrCassetteTasks.checkRollers,
    cassetteTasks_CheckCleanSensors: fieldsForm.tcdTcrCassetteTasks.checkSensors,
    cassetteTasks_Diagnostics: fieldsForm.tcdTcrCassetteTasks.diagnosticTests,
    cassetteTasks_RemoveForeignMaterial: fieldsForm.tcdTcrCassetteTasks.foreignMaterial,
    cassetteTasks_VerifyCassettesNotBentOrDamaged: fieldsForm.tcdTcrCassetteTasks.cassetteVerifyCassettesNotBentOrDamaged,
  } : {
    cassetteTasks_CheckCleanBelts: null,
    cassetteTasks_CheckCleanRollers: null,
    cassetteTasks_CheckCleanSensors: null,
    cassetteTasks_Diagnostics: null,
    cassetteTasks_RemoveForeignMaterial: null,
    cassetteTasks_VerifyCassettesNotBentOrDamaged: null,
  };

  const feedModuleTasks: FeedModuleTasks = fieldsForm.tcdTcrFeedModuleTasks ? {
    feedModuleTasks_CheckCleanBelts: fieldsForm.tcdTcrFeedModuleTasks.checkBelts,
    feedModuleTasks_CheckCleanRollers: fieldsForm.tcdTcrFeedModuleTasks.checkRollers,
    feedModuleTasks_RemoveForeignMaterial: fieldsForm.tcdTcrFeedModuleTasks.foreignMaterial,
    feedModuleTasks_CheckCleanSensors: fieldsForm.tcdTcrFeedModuleTasks.checkSensors,
    feedModuleTasks_PerformDiagnosticTests: fieldsForm.tcdTcrFeedModuleTasks.diagnosticTests,
    feedModuleTasks_CheckFilmSpools: null,
    feedModuleTasks_CheckTensionTakeawaySpring_10_24: null,
    feedModuleTasks_ReplaceFeedKit: null,
    feedModuleTasks_VerifySorterOperation: null,
    feedModuleTasks_PerformGauge_4_Calibration: null,
    feedModuleTasks_VerifyWheelCouplingDepth: null,
  } : {
    feedModuleTasks_CheckCleanBelts: null,
    feedModuleTasks_CheckCleanRollers: null,
    feedModuleTasks_RemoveForeignMaterial: null,
    feedModuleTasks_CheckCleanSensors: null,
    feedModuleTasks_PerformDiagnosticTests: null,
    feedModuleTasks_CheckFilmSpools: null,
    feedModuleTasks_CheckTensionTakeawaySpring_10_24: null,
    feedModuleTasks_ReplaceFeedKit: null,
    feedModuleTasks_VerifySorterOperation: null,
    feedModuleTasks_PerformGauge_4_Calibration: null,
    feedModuleTasks_VerifyWheelCouplingDepth: null,
  };

  const presentModuleTasks: PresentModuleTasks = fieldsForm.tcdTcrInputModuleTasks ? {
    presentModuleTasks_CheckCleanBelts: fieldsForm.tcdTcrInputModuleTasks.checkBelts,
    presentModuleTasks_CheckCleanRollers: fieldsForm.tcdTcrInputModuleTasks.checkRollers,
    presentModuleTasks_RemoveForeignMaterial: fieldsForm.tcdTcrInputModuleTasks.foreignMaterial,
    presentModuleTasks_CheckCleanSensors: fieldsForm.tcdTcrInputModuleTasks.checkSensors,
    presentModuleTasks_Diagnostic: fieldsForm.tcdTcrInputModuleTasks.diagnosticTests,
    presentModuleTasks_CheckCleanShutter: fieldsForm.tcdTcrInputModuleTasks.checkShutter,
    presentModuleTasks_VerifyOperationOfIndicators: fieldsForm.tcdTcrInputModuleTasks.verifyIndicators,
    presentModuleTasks_CleanBillScannerLenses: null,
    presentModuleTasks_RemoveCleanInputFeedReader: null,
  } : {
    presentModuleTasks_CheckCleanBelts: null,
    presentModuleTasks_CheckCleanRollers  : null,
    presentModuleTasks_CheckCleanSensors: null,
    presentModuleTasks_CheckCleanShutter: null,
    presentModuleTasks_CleanBillScannerLenses : null,
    presentModuleTasks_Diagnostic: null,
    presentModuleTasks_RemoveCleanInputFeedReader: null,
    presentModuleTasks_RemoveForeignMaterial: null,
    presentModuleTasks_VerifyOperationOfIndicators: null,
  };

  const externalAreaTasks: ExternalAreaTasks = fieldsForm.tcdTcrExternalAreaTasks ? {
    externalAreaTasks_CleanCustomerInterfaceArea: fieldsForm.tcdTcrExternalAreaTasks.customerArea,
    externalAreaTasks_CheckToneVolume: fieldsForm.tcdTcrExternalAreaTasks.toneVolume,
    externalAreaTasks_CleanTestScreen: fieldsForm.tcdTcrExternalAreaTasks.screen,
    externalAreaTasks_CleanEntireExterior: fieldsForm.tcdTcrExternalAreaTasks.exterior,
    externalAreaTasks_CheckAlignmentWithExitInput: fieldsForm.tcdTcrExternalAreaTasks.alignment,
    externalAreaTasks_VerifyCablesAreNeatOrganizedBehindEquip: fieldsForm.tcdTcrExternalAreaTasks.cables,
  } : {
    externalAreaTasks_CleanCustomerInterfaceArea: null,
    externalAreaTasks_CheckToneVolume: null,
    externalAreaTasks_CleanTestScreen: null,
    externalAreaTasks_CleanEntireExterior: null,
    externalAreaTasks_CheckAlignmentWithExitInput: null,
    externalAreaTasks_VerifyCablesAreNeatOrganizedBehindEquip: null
  };

  const vacuumingBlowOut: Blowout = fieldsForm.tcdTcrVacuumingBlowOut ? {
    vaccummingBlowOut_AllFans: fieldsForm.tcdTcrVacuumingBlowOut.fans,
    vaccummingBlowOut_PowerSupply: fieldsForm.tcdTcrVacuumingBlowOut.powerSupply,
    vaccummingBlowOut_Safe: fieldsForm.tcdTcrVacuumingBlowOut.safe,
    vaccummingBlowOut_UpperTrack: fieldsForm.tcdTcrVacuumingBlowOut.upperArea,
  } : {
    vaccummingBlowOut_AllFans: null,
    vaccummingBlowOut_PowerSupply: null,
    vaccummingBlowOut_Safe: null,
    vaccummingBlowOut_UpperTrack: null,
  };

  const tcdTcrSectionsList: TcdTcrSectionDto[] = tcdTcrSections
    ? tcdTcrSections.map((section, index) => ({
        equipment_Rating: section.equipmentRating,
        tcdTcr_TerminalId: section.terminalId,
        tcdTcr_WorkstationQty: section.clientWorkstationsAmount
          ? Number.parseInt(section.clientWorkstationsAmount)
          : null,
        tcdTcr_ManufactureOfCoinDispenser: section.coinDispenserManufacturer,
        tcdTcr_NumberOfCoinDispensers: section.coinDispensersAmount
          ? Number.parseInt(section.coinDispensersAmount)
          : null,
        tcdTcr_CommType: section.communicationType,
        tcdTcr_CommType_Other: section.otherCommunicationType,
        tcdTcr_Manufacturer: section.manufacturer,
        tcdTcr_Manufacturer_Other: section.otherManufacturer,
        tcdTcr_Model: section.model,
        tcdTcr_ExternalEquipmentAttached: section.otherExternalEquipment,
        tcdTcr_OverallCondition: section.overallCondition,
        tcdTcr_Serial: section.serialNumber,
        tcdTcr_Type: section.type,
        tcdTcr_NumberOfCassettes: section.cassettesRollersAmount ? Number(section.cassettesRollersAmount) : null,
        photo_Front: photosForm[index]?.['photoFront']?.split('?sv=')[0] ?? null,
        photo_SerialNumber: photosForm[index]?.['photoSerialNumber']?.split('?sv=')[0] ?? null,
        photo_CoinDispenser: photosForm[index]?.['photoCoinDispenser']?.split('?sv=')[0] ?? null,
        photo_ExternalDevices: photosForm[index]?.['photoExternalDevices']?.split('?sv=')[0] ?? null,
        photo_AdditionalPhoto: photosForm[index]?.['photoAdditionalPhoto']?.split('?sv=')[0] ?? null,
      }))
    : [];

  const tcdTcr: TcdTcrDto = {
    updatedOn: new Date().toUTCString(),
    performedBy: workOrderDetail.performedBy ?? null,
    workOrderId: workOrderDetail.id,
    ...vacuumingBlowOut,
    ...externalAreaTasks,
    ...presentModuleTasks,
    ...feedModuleTasks,
    ...tcdTcrTasks,
    ...cassetteTasks,
    comments,
    tcdTcr_TcdTcr: tcdTcrSectionsList,
  };

  return {
    pageOpenMode,
    pageSubmitMode,
    isEditable: true,
    fromXml: false,
    tcdTcr,
    workOrderDetail: workOrderDetail,
  };
};
