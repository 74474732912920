import { AuthService } from 'services';
import { FormsModule } from '@angular/forms';
import { SelectOption, YesNo } from 'models';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { Component, Inject, inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { CallControlService } from 'pages/call-control/call-control.service';
import { DatePickerComponent } from 'shared/material-wrappers/datepicker/datepicker.component';
import { TimepickerComponent } from 'shared/material-wrappers/timepicker/timepicker.component';
import { CommonFormInputComponent } from 'shared/material-wrappers/common-form-input/common-form-input.component';
import { ModalActionsComponent } from 'shared/modal-actions/modal-actions.component';
import { OnSiteByComponent } from '../on-site-by/on-site-by.component';
import { SelectComponent } from 'shared/material-wrappers/select/select.component';
import { NotesService } from 'services/notes.service';
import { CallStatusId } from 'models/call-control/ack-schedule-models';
import { buildNote, newSlaNoteText } from 'app/utils/notes';
import { environment } from 'environment/environment';
import { OperationResponse } from 'models/call-control/command-response';
import { roundDateToNearestQuarter } from 'app/utils/dates';
import { LoaderService } from 'shared/loader/loader.service';

@Component({
  selector: 'ads-schedule',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatTabsModule,
    CommonFormInputComponent,
    DatePickerComponent,
    TimepickerComponent,
    ModalActionsComponent,
    OnSiteByComponent,
    SelectComponent,
  ],
  templateUrl: './schedule.component.html',
  styleUrl: './schedule.component.scss',
  providers: [
    {
      provide: Window,
      useValue: window,
    },
  ],
})
export class ScheduleComponent implements OnInit {
  siteContact = '';
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  scheduleDate: Date | null = null;
  validTime = false;
  selectedTime = '';
  priority = '';
  onSiteBy: Date | null = null;
  missingSLAReason = '';
  missingSLAreasons: SelectOption<string>[] = [];

  public readonly _snackBar = inject(MatSnackBar);
  public readonly _bottomSheet = inject(MatBottomSheet);
  public readonly dialogRef = inject(MatDialog);
  datepipe: DatePipe = new DatePipe('en-US');

  yesNoOptions: SelectOption<YesNo>[] = [
    { name: 'Yes', value: YesNo.Yes },
    { name: 'No', value: YesNo.No },
  ];

  constructor(
    public readonly callControlService: CallControlService,
    public readonly authService: AuthService,
    public readonly workOrderService: WorkOrderService,
    public readonly notesService: NotesService,
    public readonly loader: LoaderService,
    @Inject(Window) private readonly window: Window
  ) {}

  ngOnInit(): void {
    this.missingSLAreasons = this.workOrderService.missingSLAreasons;
    if (this.authService.authenticated) {
      this.workOrderService.getStatusTimestamp({ workOrder: this.workOrderService.workOrder }).subscribe((response) => {
        this.scheduleDate = roundDateToNearestQuarter(new Date(response.Date ?? new Date()));
      });
    }
    this.priority = this.workOrderService.workOrderDetails().priority ?? '';
    const date = this.workOrderService.workOrderDetails().onSiteBy;
    this.onSiteBy = date ? new Date(date) : null;

    if (this.workOrderService.workOrderDetails().masterAccount !== environment.wfAccountNum) {
      this.missingSLAreasons.push({
        name: 'Remote location',
        value: 'Remote location',
      });
    }
  }

  onConfirm() {
    this.onTimeChange(this.selectedTime);
    const shouldShowSlaReason = this.onSiteBy && this.scheduleDate && this.onSiteBy < this.scheduleDate;
    if (shouldShowSlaReason && !this.missingSLAReason) {
      this._snackBar.open('Please select a reason for missing SLA.', 'Close');
      return;
    }
    if (this.authService.authenticated && this.siteContact && this.scheduleDate) {
      const note =
        `Tech acknowledged call. ${this.siteContact}` +
        ' (site contact) was notified and service was scheduled for ' +
        this.datepipe.transform(this.scheduleDate, 'MM-dd-yyyy hh:mm a');
      this.callControlService
        .updateCallStatus({
          edorDate: '',
          edorTime: '',
          functionalityRestored: '',
          workOrder: this.workOrderService.workOrderDetails().workorder ?? '',
          dateEnter: this.datepipe.transform(this.scheduleDate, 'MM/dd/yyyy')?.toString(),
          timeEnter: this.datepipe.transform(this.scheduleDate, 'HH:mm:ss')?.toString(),
          noteSubject: 'Call Acknowledged',
          noteText: note,
          status: CallStatusId.Scheduled_20,
          techIdMaster: this.authService.user?.personnelNumber ?? '',
          userId: this.authService.user?.userId ?? '',
          utcOffset: new Date().getTimezoneOffset() / -60,
        })
        .subscribe(this.onScheduleSuccess);
    }
  }

  hideAll() {
    this._bottomSheet.dismiss();
    this.dialogRef.closeAll();
  }

  refreshPage = () => this.window.location.reload();

  onScheduleSuccess = (result: OperationResponse) => {
    this.hideAll();
    if (result.isSuccess) {
      const shouldShowSLA =
        this.onSiteBy && this.priority !== 'NO SLA' && this.scheduleDate && this.onSiteBy < this.scheduleDate;
      if (shouldShowSLA) {
        const workOrder = this.workOrderService.workOrderDetails();
        const noteText = newSlaNoteText(
          new Date(workOrder.onSiteBy ?? ''),
          this.scheduleDate ?? new Date(),
          this.missingSLAReason,
          ''
        );
        const noteModel = buildNote(
          'Missed SLA',
          noteText,
          workOrder.recid,
          Number(this.workOrderService.workOrderDetails().ticketRecId),
          this.authService.user?.userId
        );
        if (this.authService.authenticated) {
          this.notesService.addNote(noteModel).subscribe({
            next: () => {
              this._snackBar.open(`Missing SLA note was submitted successfully.`, 'Close', { duration: 3000 });
              this.reloadPage();
            },
            error: (error) => {
              console.error(error);
              this.reloadPage();
            },
          });
        }
      } else {
        this.reloadPage();
      }
      this._snackBar.open(
        `Work Order scheduled successfully to be on ${this.datepipe.transform(this.scheduleDate, 'MM-dd-yyyy')}.`,
        'Close'
      );
    } else {
      this.onTicketSubmitError(result.errorMessage);
      this.reloadPage();
    }
  };

  reloadPage() {
    this.loader.show();
    setTimeout(() => {
      this.loader.hide();
      this.refreshPage();
    }, 3000);
  }

  onTicketSubmitError = (error: unknown) => {
    console.error(error);
    this._snackBar.open('Failed to schedule your Work Order, please try again later.', 'Close');
  };

  onTimeChange(time: string) {
    this.selectedTime = time;
    this.validTime = false;
    if (time) {
      this.validTime = true;
      const hours = parseInt(time.split(' ')[0], 10);
      const isPm = time.split(' ')[2] === 'PM';
      this.scheduleDate?.setHours(isPm ? hours + 12 : hours);
      this.scheduleDate?.setMinutes(parseInt(time.split(' ')[1], 10));
      this.scheduleDate?.setSeconds(0);
    }
  }
}
