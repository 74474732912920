<ads-simple-form #atmSpecs="ngModelGroup" ngModelGroup="atmSpecs">
  <ads-select label="ATM Manufacturer" name="manufacturer" [list]="atmManufacturerList" ngModel></ads-select>

  <ads-common-form-input
    [style.display]="displayOtherManufacturer() ? 'block' : 'none'"
    name="otherAtmManufacturer"
    label="Other Manufacturer"
    [maxLength]="50"
    [required]="displayOtherManufacturer()"
    ngModel
  ></ads-common-form-input>

  <ads-common-form-input
    name="serialNumber"
    label="ATM Serial Number"
    [maxLength]="50"
    [required]="true"
    ngModel
  ></ads-common-form-input>

  <ads-select label="Depositor type" name="depositorType" [list]="depositorTypeList" ngModel></ads-select>

  <ads-common-form-input
    name="kabaSerialNumber"
    label="Kaba Serial Number"
    [maxLength]="50"
    [required]="true"
    ngModel
  ></ads-common-form-input>

  <ads-common-form-input
    name="model"
    label="ATM Model"
    [maxLength]="50"
    [required]="true"
    ngModel
  ></ads-common-form-input>

  <ads-select label="Communication Type" name="communicationType" [list]="communicationTypeList" ngModel></ads-select>

  <ads-common-form-input
    [style.display]="displayOtherCommType() ? 'block' : 'none'"
    name="communicationTypeOther"
    label="Other Communication Type"
    [maxLength]="50"
    [required]="displayOtherCommType()"
    ngModel
  ></ads-common-form-input>

  <ads-common-form-input
    name="terminalId"
    label="Terminal ID"
    [maxLength]="50"
    [required]="true"
    ngModel
  ></ads-common-form-input>

  <ads-select
    label="Overall Condition - ATM"
    name="overallCondition"
    [list]="overallConditionList"
    ngModel
  ></ads-select>

  <ads-common-text-area
    [style.display]="displaySubCondition() ? 'block' : 'none'"
    name="equipmentRating"
    label="Substandard equipment comments"
    [maxLength]="500"
    [required]="displaySubCondition()"
    ngModel>
  </ads-common-text-area>

</ads-simple-form>
