import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogModule,
  MatDialogTitle,
} from '@angular/material/dialog';

interface RemoveFormSectionDialogComponentProps {
  sectionName: string;
  onConfirm: () => void;
}

@Component({
  selector: 'ads-remove-form-section',
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogModule, MatButtonModule],
  templateUrl: './remove-form-section.component.html',
})
export class RemoveFormSectionComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: RemoveFormSectionDialogComponentProps) {}
}
