import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { PhotoUploadBase } from 'models';
import { PhotoUploadService } from 'services';

@Component({
  selector: 'ads-photo-upload',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, CommonModule],
  templateUrl: './photo-upload.component.html',
  styleUrl: './photo-upload.component.scss',
})
export class PhotoUploadComponent implements OnInit {
  @Input() photoUpload!: PhotoUploadBase;
  @Input() form!: FormGroup;

  image: string | ArrayBuffer | null | undefined = '';
  isLandscape = false;
  workOrder = '';

  constructor(private readonly photoUploadService: PhotoUploadService, private readonly route: ActivatedRoute) {
    this.route.paramMap.subscribe((params) => {
      this.workOrder = params.get('work-order') ?? '';
    });
  }

  ngOnInit() {
    if (this.form.controls?.[this.photoUpload.key]?.value) {
      this.image = this.form.controls[this.photoUpload.key].value;
    }

    this.form.controls[this.photoUpload.key]?.valueChanges.subscribe((imageUrl) => {
      this.image = imageUrl;

      const img = new Image();
      img.src = imageUrl;
      this.isLandscape = img.width > img.height;

      if (imageUrl) {
        this.form.markAsDirty();
        this.form.markAsTouched();
      }
    });
  }

  imageChanged = (e: Event) => {
    const element = e.currentTarget as HTMLInputElement;

    if (element.files?.[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target?.result as string;

        img.onload = () => {
          this.isLandscape = img.width > img.height;
          this.form.markAsDirty();
          this.form.markAsTouched();
          this.photoUploadService.uploadPhoto(img.src, this.workOrder).subscribe((uploadedPhoto) => {
            this.form.controls[this.photoUpload.key]?.setValue(uploadedPhoto.fileUrl);
            this.image = img.src;
          });
        };
      };

      reader.readAsDataURL(element.files[0]);
    }
  };

  getFormDisabled = () => {
    return this.form.controls[this.photoUpload.key]?.disabled;
  };

  deleteImage = () => {
    this.image = '';
    this.form.controls[this.photoUpload.key]?.setValue('');
  };

  isValid = () => {
    return this.form.controls[this.photoUpload.key]?.valid || !this.form.controls[this.photoUpload.key]?.touched;
  };
}
