import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { VaultSectionFormComponent } from './vault-section-form/vault-section-form.component';
import { PhotoUploadControlService } from 'services';
import { SecurityService } from 'pages/security/security.service';
import { VaultSectionForm } from 'models/security/vault/vaultSecurityForm';
import { NestedMultipleFormsSectionComponent } from 'forms/shared/nested-multiple-forms-section/nested-multiple-forms-section.component';
import { IndexableFormType } from 'app/utils/miscTypes';
import { TimeClockTasksSectionComponent } from '../shared/time-clock-tasks-section/time-clock-tasks-section.component';
import { ComboLockTasksSectionComponent } from '../shared/combo-lock-tasks-section/combo-lock-tasks-section.component';
import { getFormGroup } from 'utils';
import { MatInputModule } from '@angular/material/input';
import { YesNo } from 'models';
import { VaultComboLockSection } from 'models/security/vault/combo-lock';
import { VaultTimeClockSection } from 'models/security/vault/time-clock';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { ComboLockTasks } from 'models/security/shared/shared-tasks';
import { onAddItem, onRemoveItem } from 'app/utils/form-group-array';

export class VaultSectionFormSection implements IndexableFormType, VaultSectionForm {
  index: number;
  vaultModel: string | null;
  manufacturer: string | null;
  otherManufacturer: string | null;
  swingType: string | null;
  overallCondition: string | null;
  overallConditionComments: string | null;
  ventilatorManufacturer: string | null;
  ventilatorModel: string | null;
  ventilatorOverallCondition: string | null;
  ventilatorOverallConditionComments: string | null;
  creepDoorCloses: string | null;
  doorRotationTest: YesNo | null;
  comboLocks: VaultComboLockSection[];
  timeClocks: VaultTimeClockSection[];
  constructor(index: number) {
    this.index = index;
    this.vaultModel = '';
    this.manufacturer = '';
    this.otherManufacturer = '';
    this.swingType = '';
    this.overallCondition = '';
    this.overallConditionComments = '';
    this.ventilatorManufacturer = '';
    this.ventilatorModel = '';
    this.ventilatorOverallCondition = '';
    this.ventilatorOverallConditionComments = '';
    this.creepDoorCloses = '';
    this.doorRotationTest = null;
    this.comboLocks = [];
    this.timeClocks = [];
  }
}

@Component({
  selector: 'ads-vault',
  standalone: true,
  imports: [
    CommonModule,
    SimpleFormComponent,
    FormsModule,
    MatInputModule,
    ExpansionPanelComponent,
    NestedMultipleFormsSectionComponent,
    VaultSectionFormComponent,
    TimeClockTasksSectionComponent,
    ComboLockTasksSectionComponent,
    CommonTextAreaComponent,
  ],
  providers: [PhotoUploadControlService],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './vault.component.html',
})
export class VaultComponent implements AfterViewInit {
  @Input() formGroup?: FormGroup | null;
  @Input() submitted = false;
  expandedList: number[] = [];
  photoUploadForm: FormGroup[] = [];

  comboLockFields = Object.getOwnPropertyNames(new ComboLockTasks());

  constructor(public readonly security: SecurityService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.formGroup?.controls['timeClockTasks'].setValue({
        allNa: false,
        ...this.security.vaultData.timeClockTasks,
      });

      this.formGroup?.controls['comboLockTasks'].setValue({
        allNa: false,
        ...this.security.vaultData.comboLockTasks,
      });

      this.formGroup?.controls['comments'].setValue(this.security.vaultData.comments);

      if (this.submitted) {
        this.formGroup?.disable();
      }
    });
  }

  getSection(sectionName: string) {
    return this.formGroup?.controls[sectionName] as FormGroup | undefined;
  }

  getFormControls(): Record<string, AbstractControl<string, string>> {
    const fg = this.formGroup?.get('vaultSections') as FormGroup;
    return fg ? fg.controls : {};
  }

  onAddClick = () =>
    onAddItem(this.security.vaultData.vaultSectionSections, (index) => new VaultSectionFormSection(index));

  onRemoveVault = (item: VaultSectionFormSection) => onRemoveItem(this.security.vaultData.vaultSectionSections, item);

  getList() {
    return this.security.vaultData.vaultSectionSections;
  }

  getItemSection(sectionName: string, containerName: string) {
    return getFormGroup(this.formGroup?.controls[containerName])?.controls[sectionName] as FormGroup;
  }
}
