<mat-form-field class="common-form-field" appearance="outline" hideRequiredMarker>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select
    #customSelect="ngModel"
    adsRequired
    [checkRequired]="required"
    [required]="required"
    [disabled]="disabled"
    [name]="name"
    [(ngModel)]="value"
    (ngModelChange)="onModelChange($event)"
    (opened)="markAsTouched()"
  >
    <mat-option *ngIf="showEmptyOption" [value]="''">
      <span class="item">-- Select --</span>
    </mat-option>
    @for (item of list; track item.name) {
      <mat-option [value]="item.value">
        <span class="item">{{ item.name }}</span>
      </mat-option>
    }
  </mat-select>
  <mat-hint align="start" class="custom-error" *ngIf="getInvalid() && !disabled">
    This field is required
  </mat-hint>
</mat-form-field>
