import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { YesNo } from 'models';
import { ValidateRequiredDirective } from 'shared/directives/validate-required.directive';
import { BaseTableFormComponent } from '../table-form-base';

@Component({
  selector: 'ads-table-form-bottom-sheet-input',
  standalone: true,
  imports: [CommonModule, MatInputModule, MatIconModule, MatButtonModule, FormsModule, ValidateRequiredDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TableFormBottomSheetInputComponent,
    },
  ],
  templateUrl: './table-form-bottom-sheet-input.component.html',
  styleUrl: './table-form-bottom-sheet-input.component.scss',
})
export class TableFormBottomSheetInputComponent extends BaseTableFormComponent implements ControlValueAccessor {
  @Input() override type: 'string' | 'boolean' = 'string';
  @Input() openBottomSheet: () => void = () => {};
  @Input() maxWidth = '100%';

  getValue = () => {
    if (this.value === undefined || this.value === null) return '';

    if (this.type === 'boolean') {
      return this.value.toString() === YesNo.No.toString() ? 'No' : 'Yes';
    }

    return this.value;
  };
}
