import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { FormsModule, ControlContainer, NgModelGroup, FormGroup } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { SecuritySurveillanceDropdowns } from 'models/security/security-dropdowns';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { SurveillanceCameraTableSection } from '../surveillance.component';
import { SimpleFormComponent } from '../../../../shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-surveillance-camera-table-section',
  standalone: true,
  imports: [CommonModule, FormsModule, CommonFormInputComponent, SelectComponent, MatDividerModule, SimpleFormComponent],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
  templateUrl: './surveillance-camera-table-section.component.html',
})
export class SurveillanceCameraTableSectionComponent {
  @Input() lists?: SecuritySurveillanceDropdowns;
  @Input() section?: FormGroup;
  @Input() item?: SurveillanceCameraTableSection;

  constructor(@Optional() public readonly ngModelGroup: NgModelGroup) {}
}
