<ads-simple-form #alarmPanelTasks="ngModelGroup" ngModelGroup="alarmPanelTasks">
  <ads-radio-button-group
    label="Is programming sheet with control panel?"
    name="isProgrammingSheetPresent"
    [required]="true"
    [options]="yesNoOptions"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Monitoring sheet with control panel?"
    name="isMonitoringSheetPresent"
    [required]="true"
    [options]="yesNoOptions"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect all wire and PCB connection in control panel"
    name="wireAndPcbInspection"
    [required]="true"
    [options]="completeNaOptions"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test control panel tamper switch"
    name="testTamperSwith"
    [required]="true"
    [options]="completeNaOptions"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test phone communications"
    name="testPhoneComms"
    [required]="true"
    [options]="completeNaOptions"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test phone communications loss detection"
    name="testPhoneComsLoss"
    [required]="true"
    [options]="completeNaOptions"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Verified receiver primary dialer phone number"
    name="verifyPrimaryDialer"
    [required]="true"
    [options]="completeNaOptions"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Verified secondary receiver phone number"
    name="verifySecondaryReceiver"
    [required]="true"
    [options]="completeNaOptions"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test IP communications"
    name="testIpComms"
    [required]="true"
    [options]="completeNaOptions"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test cellular communications"
    name="testCellularComms"
    [required]="true"
    [options]="completeNaOptions"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
</ads-simple-form>
