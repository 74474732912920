<div *ngIf="!loading" class="call-control-page" [ngClass]="{ desktop: isDesktopView }">
  <h2 *ngIf="isDesktopView">
    <button mat-icon-button (click)="layoutNavService.goHome()">
      <mat-icon class="material-icons-outlined">arrow_back</mat-icon></button
    >Call Control
  </h2>
  <mat-tab-group (selectedTabChange)="onTabChange()">
    <mat-tab label="Service Calls">
      <ads-call-control-service-calls
        [calls]="serviceCalls"
        [isDesktopView]="isDesktopView"
      ></ads-call-control-service-calls>
    </mat-tab>
    <mat-tab label="PM Board">
      <ads-call-control-pm-board [calls]="pmBoardCalls" [isDesktopView]="isDesktopView"></ads-call-control-pm-board>
    </mat-tab>
  </mat-tab-group>
</div>
<ads-fab-button></ads-fab-button>
