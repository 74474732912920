import { CompleteNa } from '../shared/complete-na';

export class ItmCleaningTasks {
  allNa: boolean;
  thoroughInspection: CompleteNa | null;
  fasciaTrim: CompleteNa | null;
  customerDisplay: CompleteNa | null;
  functionKeys: CompleteNa | null;
  itmSidesBackAndTop: CompleteNa | null;
  itmArea: CompleteNa | null;
  vacuumSafe: CompleteNa | null;
  vacuumTopHalf: CompleteNa | null;
  vacuumAllFans: CompleteNa | null;
  vacuumAllFilters: CompleteNa | null;
  cleanSignaturePad: CompleteNa | null;
  cleanIdScanner: CompleteNa | null;
  cleanCoinHolder: CompleteNa | null;

  constructor() {
    this.allNa = false;
    this.thoroughInspection = null;
    this.fasciaTrim = null;
    this.customerDisplay = null;
    this.functionKeys = null;
    this.itmSidesBackAndTop = null;
    this.itmArea = null;
    this.vacuumSafe = null;
    this.vacuumTopHalf = null;
    this.vacuumAllFans = null;
    this.vacuumAllFilters = null;
    this.cleanSignaturePad = null;
    this.cleanIdScanner = null;
    this.cleanCoinHolder = null;
  }
}
