<div *ngIf="item && ccCdDropdowns" class="cc-cd-section-wrapper" [ngModelGroup]="'ccCdSections#' + item.index">
  <ads-select
    label="CC/CD Manufacturer"
    name="manufacturer"
    [list]="ccCdDropdowns.manufacturerList"
    [value]="item.manufacturer"
    [(ngModel)]="item.manufacturer"
    [required]="true"
  >
  </ads-select>

  <ads-common-form-input
    name="model"
    label="CC/CD Model"
    [maxLength]="30"
    [required]="true"
    [value]="item.model"
    [(ngModel)]="item.model"
  ></ads-common-form-input>

  <ads-common-form-input
    name="serialNumber"
    label="CC/CD Serial Number"
    [maxLength]="30"
    [required]="true"
    [value]="item.serial"
    [(ngModel)]="item.serial"
  ></ads-common-form-input>

  <ads-select
    label="Type"
    name="type"
    [list]="ccCdDropdowns.currencyCounterDiscriminatorTypeList"
    [value]="item.type"
    [(ngModel)]="item.type"
    [required]="true"
  >
  </ads-select>

  <ads-select
    label="Quantity of pockets"
    name="quantityOfPockets"
    [list]="ccCdDropdowns.ccdPocketsList"
    [value]="item.quantityOfPockets"
    [(ngModel)]="item.quantityOfPockets"
    [required]="true"
  >
  </ads-select>

  <ads-select
    label="Quantity of printers"
    name="quantityOfPrinters"
    [list]="ccCdDropdowns.ccdPrintersList"
    [value]="item.quantityOfPrinters"
    [(ngModel)]="item.quantityOfPrinters"
    [required]="true"
  >
  </ads-select>

  <ads-select
    label="Manufacturer of printer"
    name="manufacturerOfPrinter"
    [list]="ccCdDropdowns.printerManufacturerList"
    [value]="item.manufacturerOfPrinter"
    [(ngModel)]="item.manufacturerOfPrinter"
  >
  </ads-select>

  <ads-common-text-area
    name="externalEquipmentAttached"
    label="Other external equipment attached"
    [maxLength]="200"
    [(ngModel)]="item.externalEquipmentAttached"
  ></ads-common-text-area>

  <ads-select
    label="Overall Condition - Currency Counter/Discriminator"
    name="overallCondition"
    [list]="ccCdDropdowns.currencyCounterOverallConditionsList"
    [value]="item.overallCondition"
    [(ngModel)]="item.overallCondition"
    [required]="true"
  >
  </ads-select>

  <ads-common-text-area
    [style.display]="displayEquipmentRating() ? 'block' : 'none'"
    name="equipmentRating"
    label="Substandard equipment comments"
    [maxLength]="500"
    [required]="displayEquipmentRating()"
    ngModel
  >
  </ads-common-text-area>

  <h3>Photos</h3>

  <ads-photos-section [photoUploads]="photoUploads" [photoUploadForm]="photoUploadForm"></ads-photos-section>
</div>
