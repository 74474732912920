import { FormGroup, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PhotoUploadBase } from 'models';
import { PageOpenMode, PageSubmitMode, WorkOrderDetailDto, WorkStatus } from 'models/api';
import { Subject } from 'rxjs';
import { LoaderService } from 'services';
import { HeaderService } from 'shared/header/header.service';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { StickyHeaderService } from 'shared/sticky-header/sticky-header.service';
import { FormComments, FormPageService } from './form-page-service';
import { RoutesEnum } from 'app/utils/navigation';

export interface CommonDtoPage<ResponseDropdows> {
  workOrderDetail: WorkOrderDetailDto;
  customerName: string;
  dropdowns: ResponseDropdows;
}

export interface FormPage<Form, FormDto, Dropdowns, ResponseDropdows> {
  sections: Record<string, ExpansionPanelComponent | undefined>;
  sectionNames: string[];
  photoUploads?: PhotoUploadBase[];
  loadPhotos?: () => void;
  mapForm: (data: FormDto) => Form;
  mapDropdowns: (data: ResponseDropdows) => Dropdowns;
}

export abstract class FormPageComponent<
  Service extends FormPageService<Form, FormDto>,
  Form extends FormComments,
  FormDto extends CommonDtoPage<ResponseDropdows>,
  Dropdowns,
  ResponseDropdows
> implements FormPage<Form, FormDto, Dropdowns, ResponseDropdows>
{
  form?: NgForm;
  photoUploadForm!: FormGroup;
  workOrder?: WorkOrderDetailDto;
  customerName = '';
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  dropdowns: Dropdowns = {} as Dropdowns;
  photoUploads: PhotoUploadBase[] = [];
  overrideLoadForm?: (data: FormDto) => void;
  scrollToFirstInvalidNestedSection?: (key: string) => number;

  abstract sections: Record<string, ExpansionPanelComponent | undefined>;
  abstract sectionNames: string[];
  abstract mapForm: (data: FormDto) => Form;
  abstract mapDropdowns: (data: ResponseDropdows) => Dropdowns;

  constructor(
    public readonly service: Service,
    protected headerService: HeaderService,
    protected router: Router,
    protected stickyHeaderService: StickyHeaderService,
    protected loaderService: LoaderService,
    protected route: ActivatedRoute,
    protected snackBar: MatSnackBar
  ) {}

  getIsFormSubmitted = () => !!this.workOrder?.workStatus && this.workOrder.workStatus === WorkStatus.Submitted;

  setDefault = () => {
    if (this.form) {
      this.form?.resetForm();
      this.stickyHeaderService.enableStickyHeader('', '');
      this.route.params.subscribe((params) => {
        this.service.workOrder = params['work-order'];
        if (this.service.workOrder?.length > 0) {
          this.getFormData();
        }
      });
    }
  };

  getFormData = () => {
    this.service.getForm().subscribe((data) => {
      this.workOrder = data.workOrderDetail;
      if (this.getIsFormSubmitted()) {
        this.form?.form.disable();
      } else {
        this.headerService.resetForm = this.resetForm;
        this.headerService.showResetFormButton.set(true);
      }
      this.customerName = data.customerName;
      this.service.updateForm(this.mapForm(data));
      this.dropdowns = this.mapDropdowns(data.dropdowns);
      this.stickyHeaderService.enableStickyHeader(data.customerName, data.workOrderDetail.city);
      if (this.overrideLoadForm) {
        this.overrideLoadForm(data);
      } else if (this.service.getPhotos) {
        this.service.getPhotos(data, (photos) => {
          this.form?.form.setValue({
            ...this.service.form,
            photoUploads: photos,
          });
        });
      }
    });
  };

  resetForm = this.getFormData;

  scrollToFirstInvalidSection = () => {
    let panelToScroll = -1;
    this.sectionNames.forEach((key, index) => {
      const section = this.form?.controls[key];
      if (this.scrollToFirstInvalidNestedSection) {
        panelToScroll = this.scrollToFirstInvalidNestedSection(key);
        this.scroll(panelToScroll);
        return;
      }
      if (section?.invalid) {
        this.sections[key]?.setExpanded(true);
        if (panelToScroll === -1) {
          panelToScroll = index;
        }
      }
    });
    this.scroll(panelToScroll);
  };

  scroll = (panelToScroll: number) => {
    if (panelToScroll !== -1) {
      setTimeout(() => {
        const element = document.querySelectorAll('mat-expansion-panel-header')[panelToScroll];
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 300);
    }
  };

  onSubmit = () => {
    if (!this.workOrder) {
      return;
    }
    this.form?.form.markAllAsTouched();
    this.form?.controls['photoUploads'].markAllAsTouched();
    if (!this.form?.valid) {
      this.scrollToFirstInvalidSection();
      return;
    }
    this.sendForm(PageSubmitMode.PAGE_SUBMIT_MODE_SUBMIT, () =>
      this.router.navigate([`${RoutesEnum.Submitted}/${RoutesEnum.Atm}`])
    );
  };

  saveForm = () =>
    this.sendForm(PageSubmitMode.PAGE_SUBMIT_MODE_SAVE, () => {
      this.openSnackBar('Form saved successfully', 'Close');
    });

  sendForm = (pageSubmitMode: PageSubmitMode, callback: () => void) => {
    if (!this.workOrder) {
      return;
    }
    this.service
      .saveForm({
        photosForm: this.photoUploadForm.controls,
        fieldsForm: this.service.form,
        comments: this.service.form.comments,
        workOrderDetail: this.workOrder,
        pageSubmitMode: pageSubmitMode,
        pageOpenMode: this.workOrder.workStatus === null ? PageOpenMode.PAGE_MODE_ADD : PageOpenMode.PAGE_MODE_UPDATE,
      })
      .subscribe(callback);
  };

  openSnackBar = (message: string, action: string) => {
    this.snackBar.open(message, action, {
      verticalPosition: 'top',
      panelClass: 'snackbar-position',
      duration: 5000,
    });
  };
}
