import { CommonModule } from '@angular/common';
import { Component, AfterViewInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { LoginComponent } from 'pages/login/login.component';
import { AuthService, LoaderService } from 'services';
import { MatMenuModule } from '@angular/material/menu';
import { HomeService } from './home.service';
import { Alert } from 'models/api/home/home-requests';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
import { RankingChartComponent } from './ranking-chart/ranking-chart.component';
import { HomeChartsComponent } from "./home-charts/home-charts.component";
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { HeaderService } from 'shared/header/header.service';
import { CallsContainerComponent } from "./calls-container/calls-container.component";
import { ButtonsContainerComponent } from "./buttons-container/buttons-container.component";

@Component({
  selector: 'ads-home',
  standalone: true,
  imports: [
    MatButtonModule,
    LoginComponent,
    MatInputModule,
    CommonModule,
    FormsModule,
    MatIconModule,
    MatMenuModule,
    MatExpansionModule,
    MatBadgeModule,
    RankingChartComponent,
    HomeChartsComponent,
    CallsContainerComponent,
    ButtonsContainerComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements AfterViewInit {
  openCalls = 0;
  newCalls = 0;
  userName = '';

  alerts: Alert[] = [];
  unreadAlerts: number[] = [];
  rankingValues: string[] = [];

  constructor(
    public readonly authService: AuthService,
    private readonly homeService: HomeService,
    public readonly loaderService: LoaderService,
    public readonly layoutNavService: LayoutNavService,
    public readonly headerService: HeaderService
  ) {
    this.headerService.title.set('Welcome, ' + (this.authService.user?.fullName ?? ''));
  }

  navigateToCallControl() {
    this.layoutNavService.selectedIndex = 1;
    this.layoutNavService.iframeNavigateTo('callControl');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.homeService.getCalls().subscribe((calls) => {
        this.openCalls = calls.openCalls;
        this.newCalls = calls.unAckCalls;
      });

      this.homeService.getAlerts().subscribe((alerts) => {
        this.alerts = alerts;
        this.unreadAlerts = alerts.map((alert) => alert.alertId);
      });

      this.userName = this.authService.user?.fullName ?? '';
    });
  }

  readNotification(event: MouseEvent, alertId: number) {
    event.stopPropagation();
    this.unreadAlerts = this.unreadAlerts.filter((id) => id !== alertId);
  }

  onKeyPress(e: Event, alertId: number) {
    const event = e as KeyboardEvent;
    if (event.key == 'Enter') {
      this.unreadAlerts = this.unreadAlerts.filter((id) => id !== alertId);
    }
  }

  deleteNotification(event: MouseEvent, alertId: number) {
    event.stopPropagation();
    this.homeService.deleteAlert(alertId).subscribe(() => {
      this.alerts = this.alerts.filter((alert) => alert.alertId !== alertId);
    });
  }

  logOut() {
    this.authService.logout(true);
  }
}
