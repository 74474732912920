import { PhotoUpload } from './photo-upload';

export const tcdTcrPhotoUploadKeys = [
  'photoFront',
  'photoCoinDispenser',
  'photoExternalDevices',
  'photoSerialNumber',
  'photoAdditionalPhoto',
] as const;

export type TcdTcrPhotoUploadKey = (typeof tcdTcrPhotoUploadKeys)[number];

export const tcdTcrPhotoUploads: PhotoUpload<TcdTcrPhotoUploadKey>[] = [
  { label: 'Front', key: 'photoFront', required: true },
  { label: 'Serial Number Label', key: 'photoSerialNumber', required: true },
  { label: 'Coin Dispenser (optional)', key: 'photoCoinDispenser', required: false },
  { label: 'Other External Devices (optional)', key: 'photoExternalDevices', required: false },
  { label: 'Additional Photo (optional)', key: 'photoAdditionalPhoto', required: false },
];
