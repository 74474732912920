<h3>Parts</h3>

<div class="add-container">
  <div *ngIf="rmaRequestService.parts().length === 0" class="no-parts">No parts</div>

  <ng-container *ngIf="rmaRequestService.parts().length > 0">
    <form class="search-container">
      <mat-form-field class="field">
        <input type="text" matInput [(ngModel)]="search" [ngModelOptions]="{ standalone: true }" placeholder="Search" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </form>
  </ng-container>

  <button mat-button mat-stroked-button (click)="addPart()" class="add-part">Add Part</button>
</div>

<div *ngIf="rmaRequestService.parts().length > 0" class="parts-container">
  <div *ngFor="let part of rmaRequestService.parts(); let i = index">
    <button class="part" (click)="editPart(i)">
      <div class="header">
        <div class="sub-header">
          <mat-icon class="material-icons-outlined">settings</mat-icon>
          <span>{{ part.partNumber }}</span>
        </div>
        <mat-icon class="material-icons-outlined">edit</mat-icon>
      </div>
      <div class="body">{{ part.partDescription }}</div>
    </button>
  </div>
</div>
