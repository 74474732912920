import { SelectOption } from './../../../../models/shared/select-option';
import { AuthService } from 'services';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { Component, Inject, inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule, DatePipe } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NotesService } from 'services/notes.service';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { CallControlService } from 'pages/call-control/call-control.service';
import { DatePickerComponent } from 'shared/material-wrappers/datepicker/datepicker.component';
import { TimepickerComponent } from 'shared/material-wrappers/timepicker/timepicker.component';
import { ModalActionsComponent } from 'shared/modal-actions/modal-actions.component';
import { RadioListComponent } from 'shared/material-wrappers/radio-list/radio-list.component';
import { OperationResponse } from 'models/call-control/command-response';
import { roundDateToNearestQuarter } from 'app/utils/dates';
import { Router } from '@angular/router';
import { LoaderService } from 'shared/loader/loader.service';
import { CallControlDateModel } from 'models/call-control/call-control-date-model';

@Component({
  selector: 'ads-travel-back-and-lunch',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatTabsModule,
    DatePickerComponent,
    TimepickerComponent,
    ModalActionsComponent,
    MatCheckboxModule,
    RadioListComponent,
  ],
  templateUrl: './travel-back-and-lunch.component.html',
  styleUrl: './travel-back-and-lunch.component.scss',
  providers: [
    {
      provide: Window,
      useValue: window,
    },
  ],
})
export class TravelBackAndLunchComponent implements OnInit {
  travelBackDateTime: Date = new Date();
  leaveDateTime: Date = new Date();
  arrivalDateTime: Date = new Date();
  lunchDateTime: Date = new Date();
  startDateTime: Date = new Date();
  endDateTime: Date = new Date();

  includeTravelBack = true;
  includeLunchTime = false;
  options: SelectOption<boolean>[] = [
    { value: false, name: 'Call completed' },
    { value: true, name: 'Leave site' },
  ];

  public readonly _snackBar = inject(MatSnackBar);
  public readonly _bottomSheet = inject(MatBottomSheet);
  public readonly _dialogRef = inject(MatDialog);
  datepipe: DatePipe = new DatePipe('en-US');

  constructor(
    public readonly callControlService: CallControlService,
    public readonly authService: AuthService,
    public readonly workOrderService: WorkOrderService,
    public readonly notesService: NotesService,
    public readonly router: Router,
    public readonly loader: LoaderService,
    @Inject(Window) private readonly window: Window
  ) {}

  ngOnInit(): void {
    if (this.authService.authenticated) {
      this.workOrderService.getStatusTimestamp({ workOrder: this.workOrderService.workOrder }).subscribe(this.loadDates);
    }
  }

  loadDates = (response: CallControlDateModel) => {
    const lastDate = response.Date ?? new Date();
    this.travelBackDateTime = roundDateToNearestQuarter(lastDate);
    this.leaveDateTime = roundDateToNearestQuarter(lastDate);
    this.arrivalDateTime = roundDateToNearestQuarter(lastDate);
    this.lunchDateTime = roundDateToNearestQuarter(lastDate);
    this.startDateTime = roundDateToNearestQuarter(lastDate);
    this.endDateTime = roundDateToNearestQuarter(lastDate);
  }

  hideAll = () => {
    this._dialogRef.closeAll();
    this._bottomSheet.dismiss();
    this.reloadPage();
  };

  checkTimes = () => {
    if (this.includeTravelBack) {
      if (this.arrivalDateTime < this.leaveDateTime) {
        this._snackBar.open('Arrival time cannot be earlier than Leave Site time!', 'Close', { duration: 4000 });
        return false;
      }
    }

    if (this.includeLunchTime) {
      if (this.endDateTime < this.startDateTime) {
        this._snackBar.open('Lunch End time cannot be earlier than Lunch Start time!', 'Close', { duration: 4000 });
        return false;
      }
    }

    if (this.includeLunchTime && this.includeTravelBack && this.lunchDateTime == this.travelBackDateTime) {
      if (
        this.leaveDateTime < this.endDateTime &&
        this.arrivalDateTime > this.startDateTime &&
        this.arrivalDateTime != this.leaveDateTime
      ) {
        this._snackBar.open('Time entries are overalapping. Please check the times and try again.', 'Close', {
          duration: 4000,
        });
        return false;
      }
    }
    return true;
  };

  onConfirm = () => {
    const isFormValid = this.checkTimes();
    if (isFormValid) {
      this.workOrderService
        .processTravelBack({
          isTravelBackChecked: this.includeTravelBack,
          date1: this.travelBackDateTime,
          arrival: this.arrivalDateTime,
          leaveSite: this.leaveDateTime,

          lunchEntry: this.includeLunchTime,
          lunchDate: this.lunchDateTime,
          lunchStartTime: this.startDateTime,
          lunchEndTime: this.endDateTime,

          markingCallAs: this.workOrderService.leaveSite ? 'LeaveSite' : 'Completed',
          techIdMaster: this.authService.user?.personnelNumber,
          utcOffset: new Date().getTimezoneOffset() / -60,
          userId: this.authService.user?.userId,
          workOrder: this.workOrderService.workOrder,
        })
        .subscribe({ next: this.onSuccess, error: this.onError });
    }
  };

  onSuccess = (response: OperationResponse) => {
    if (response.isSuccess) {
      this.hideAll();
      this._snackBar.open('Travel Back & Lunch processed successfully.', 'Close', { duration: 4000 });
    } else {
      this.onError(response.errorMessage as string);
    }
    this.reloadPage();
  };

  getDateTime = (date: Date, time: string) => {
    const tempDate = new Date(date);
    if (time) {
      const hours = parseInt(time.split(' ')[0], 10);
      const isPm = time.split(' ')[2] === 'PM';
      tempDate.setHours(isPm ? hours + 12 : hours);
      tempDate.setMinutes(parseInt(time.split(' ')[1], 10));
      tempDate.setSeconds(0);
    }
    return tempDate;
  };

  onError = (error: string) => {
    console.error(error);
    this._snackBar.open(error, 'Close');
    this.reloadPage();
  };

  refreshPage = () => this.window.location.reload();

  reloadPage = () => {
    if (!this.router.url.includes('forcible')) {
      this.loader.show();
      setTimeout(() => {
        this.loader.hide();
        this.refreshPage();
      }, 3000);
    }
  };
}
