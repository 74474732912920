<ads-simple-form #customerUnit="ngModelGroup" ngModelGroup="customerUnit">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button
  ><mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect and test door motors"
    name="inspectDoorMotors"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect and test windgate assy"
    name="testWindgateAssy"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect and test motor starter/contactor"
    name="testMotorStarter"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect and test blower assy"
    name="testBlowerAssy"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    label="Inspect and test safety devices"
    name="testSafetyDevices"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect and test send/call switches"
    name="testCallSwitches"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect and test carrier/limit switches"
    name="testCarrierLimitSwitches"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect and test relays"
    name="testRelays"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>


  <ads-radio-button-group
    label="Inspect and test bumper and pads"
    name="testBumperPads"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect and test indicator lights"
    name="testIndicatorLights"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Inspect and test carrier arrival catch"
    name="testCarrierCatch"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Clean outside of unit with appropriate cleaner"
    name="cleanOutside"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Vacuum inside of unit"
    name="vaccumInside"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
</ads-simple-form>
