export class ItmReceiptPrinterTasks {
  allNa: boolean;
  removeAllPaperDust: number | null;
  openAndCleanEngine: number | null;
  checkAndCleanAllBelts: number | null;
  checkAndCleanAllRollers: number | null;
  blowOutSensorAreas: number | null;
  blowOutCutterArea: number | null;
  performDiagnosticTest: number | null;

  constructor() {
    this.allNa = false;
    this.removeAllPaperDust = null;
    this.openAndCleanEngine = null;
    this.checkAndCleanAllBelts = null;
    this.checkAndCleanAllRollers = null;
    this.blowOutSensorAreas = null;
    this.blowOutCutterArea = null;
    this.performDiagnosticTest = null;
  }
}
