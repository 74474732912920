<ads-simple-form *ngIf="item" class="surveillance-poe-section-wrapper" [ngModelGroup]="'poeSwitchItem#' + item.index">
  <ads-select
    label="POE Switch Manufacturer"
    name="poeSwitchManufacturer"
    [list]="lists?.surveillancePoeSwitchManufacturers"
    [value]="item.poeSwitchManufacturer"
    [(ngModel)]="item.poeSwitchManufacturer"
  ></ads-select>
  <ads-common-form-input
    [style.display]="displayOtherManufacturer() ? 'block' : 'none'"
    name="poeSwitchManufacturer_Other"
    label="Other Manufacturer"
    [maxLength]="50"
    [required]="displayOtherManufacturer()"
    [value]="item.poeSwitchManufacturer_Other"
    [(ngModel)]="item.poeSwitchManufacturer_Other"
  ></ads-common-form-input>

  <ads-common-form-input
    name="poeSwitchModel"
    label="POE Switch model"
    [maxLength]="50"
    [required]="true"
    [value]="item.poeSwitchModel"
    [(ngModel)]="item.poeSwitchModel"
  ></ads-common-form-input>
</ads-simple-form>
