import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogModule,
  MatDialogTitle,
} from '@angular/material/dialog';

interface ResetFormDialogComponentProps {
  formName: string;
  onConfirm: () => void;
}

@Component({
  selector: 'ads-reset-form-dialog',
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogModule, MatButtonModule],
  templateUrl: './reset-form-dialog.component.html',
})
export class ResetFormDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ResetFormDialogComponentProps) {}
}
