<ads-simple-form *ngIf="item" class="safes-section-wrapper" [ngModelGroup]="'safesPanel#' + item.index">
  <ads-common-form-input
    name="safeType"
    label="Safe type"
    tooltip="Like 'Cash safe', 'SD safe', etc."
    [maxLength]="50"
    [required]="true"
    [value]="item.safeType"
    [(ngModel)]="item.safeType"
  ></ads-common-form-input>

  <ads-select
    label="Safe manufacturer"
    name="safeManufacturer"
    [list]="dropdowns?.safesSafesSafeManufacturers"
    [value]="item.safeManufacturer"
    [(ngModel)]="item.safeManufacturer"
  ></ads-select>

  <ads-common-form-input
    [style.display]="displayOther('safeManufacturer') ? 'block' : 'none'"
    name="safeManufacturer_Other"
    label="Other safe manufacturer"
    [maxLength]="50"
    [required]="displayOther('safeManufacturer')"
    [value]="item.safeManufacturer_Other"
    [(ngModel)]="item.safeManufacturer_Other"
  ></ads-common-form-input>

  <ads-common-form-input
    name="safeModel"
    label="Safe Model"
    [maxLength]="50"
    [required]="true"
    [value]="item.safeModel"
    [(ngModel)]="item.safeModel"
  ></ads-common-form-input>

  <ads-select
    label="Swing type"
    name="swingType"
    [list]="dropdowns?.safesSafesSwingTypes"
    [value]="item.swingType"
    [(ngModel)]="item.swingType"
  ></ads-select>

  <ads-select
    label="Overall condition - Safe"
    name="overallConditionSafe"
    [list]="dropdowns?.safesSafesOverallConditions"
    [value]="item.overallConditionSafe"
    [(ngModel)]="item.overallConditionSafe"
  ></ads-select>

  <ads-common-text-area
    [style.display]="displayOtherCondition() ? 'block' : 'none'"
    name="equipment_Rating_Safe"
    label="Substandard equipment comments"
    [maxLength]="500"
    [required]="displayOtherCondition()"
    [value]="item.equipment_Rating_Safe"
    [(ngModel)]="item.equipment_Rating_Safe"
  >
  </ads-common-text-area>

  <ng-container ngModelGroup="comboLocks">
    <ads-nested-multiple-forms-section
      #safesSectionLocks
      title="Combo Locks"
      itemTitle="Lock"
      [formTemplate]="formTemplateLocks"
      [list]="item.comboLocks"
      [formControls]="getFormControls('comboLocks')"
      [groupName]="'comboLock'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddComboLock()"
      (deleteClicked)="onRemoveComboLock($event)"
      [minItems]="0"
    >
    </ads-nested-multiple-forms-section>
    <ng-template #formTemplateLocks let-item="item">
      <ads-combo-locks-section
        [section]="getSection('comboLock#' + item.index, 'comboLocks')"
        [item]="item"
      ></ads-combo-locks-section>
    </ng-template>
  </ng-container>

  <ng-container ngModelGroup="timeClocks">
    <ads-nested-multiple-forms-section
      #safesSectionClocks
      title="Time Clocks"
      itemTitle="Clock"
      [formTemplate]="formTemplateSafesClocks"
      [list]="item.timeClocks"
      [formControls]="getFormControls('timeClocks')"
      [groupName]="'timeClock'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddTimeClock()"
      (deleteClicked)="onRemoveTimeClock($event)"
      [minItems]="0"
    >
    </ads-nested-multiple-forms-section>
    <ng-template #formTemplateSafesClocks let-item="item">
      <ads-time-clocks-section
        [section]="getSection('timeClock#' + item.index, 'timeClocks')"
        [item]="item"
      ></ads-time-clocks-section>
    </ng-template>
  </ng-container>
</ads-simple-form>
