import { AuthService } from 'services';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { Component, Inject, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { CallControlService } from 'pages/call-control/call-control.service';
import { DatePickerComponent } from 'shared/material-wrappers/datepicker/datepicker.component';
import { TimepickerComponent } from 'shared/material-wrappers/timepicker/timepicker.component';
import { CallStatusId } from 'models/call-control/ack-schedule-models';
import { OperationResponse } from 'models/call-control/command-response';
import { CustomButtonComponent } from 'shared/custom-button/custom-button.component';
import { AskForCompleteOrSuspendComponent } from '../ask-for-complete-or-suspend/ask-for-complete-or-suspend.component';
import { LoaderService } from 'shared/loader/loader.service';

@Component({
  selector: 'ads-start-call',
  standalone: true,
  imports: [CommonModule, FormsModule, DatePickerComponent, TimepickerComponent, CustomButtonComponent],
  templateUrl: './start-call.component.html',
  styleUrl: './start-call.component.scss',
  providers: [
    {
      provide: Window,
      useValue: window,
    },
  ],
})
export class StartCallComponent implements OnInit {
  public readonly _snackBar = inject(MatSnackBar);
  public readonly _bottomSheet = inject(MatBottomSheet);
  public readonly dialogRef = inject(MatDialog);
  selectedTime = '';
  validTime = false;
  startDate: Date | null = null;
  maxStartDate = new Date();
  datepipe: DatePipe = new DatePipe('en-US');
  loaded = false;

  constructor(
    public readonly callControlService: CallControlService,
    public readonly authService: AuthService,
    public readonly workOrderService: WorkOrderService,
    public readonly loader: LoaderService,
    @Inject(Window) private readonly window: Window
  ) {}

  ngOnInit(): void {
    if (this.authService.authenticated) {
      this.workOrderService
        .getStatusTimestamp({ workOrder: this.workOrderService.workOrder })
        .subscribe((response) => {
          this.startDate = new Date(response.Date ?? new Date());
          this.loaded = true;
        });
    }
  }

  onConfirm() {
    if (this.authService.authenticated) {
      try {
        this.onTimeChange(this.selectedTime);
        this.callControlService
          .updateCallStatus({
            workOrder: this.workOrderService.workOrderDetails().workorder ?? '',
            dateEnter: this.datepipe.transform(this.startDate, 'MM/dd/yyyy')?.toString(),
            timeEnter: this.datepipe.transform(this.startDate, 'HH:mm:ss')?.toString(),
            status: CallStatusId.Started_50,
            techIdMaster: this.authService.user?.personnelNumber ?? '',
            userId: this.authService.user?.userId ?? '',
            utcOffset: new Date().getTimezoneOffset() / -60,
          })
          .subscribe(this.onStartCallSuccess);
      } catch (error) {
        this.onStartCallError(error);
      }
      this.hideAll();
    }
  }

  hideAll() {
    this._bottomSheet.dismiss();
    this.dialogRef.closeAll();
  }

  refreshPage = () => this.window.location.reload();
  onStartCallSuccess = (result: OperationResponse) => {
    this.hideAll();
    if (result.isSuccess) {
      this.loader.show();
      setTimeout(() => {
        this.refreshPage();
        this.loader.hide();
      }, 3000);
      this._snackBar.open('Workorder started successfully.', 'Close');
    } else {
      this.onStartCallError(result.errorMessage);
    }
  };

  onStartCallError = (error: unknown) => {
    console.error(error);
    this._snackBar.open('Failed to start workorder, please try again later.', 'Close');
  };

  onTimeChange(time: string) {
    this.selectedTime = time;
    this.validTime = false;
    if (time) {
      this.validTime = true;
      const hours = parseInt(time.split(' ')[0], 10);
      const isPm = time.split(' ')[2] === 'PM';
      this.startDate?.setHours(isPm ? hours + 12 : hours);
      this.startDate?.setMinutes(parseInt(time.split(' ')[1], 10));
      this.startDate?.setSeconds(0);
    }
  }

  onNoLaborClick() {
    this.hideAll();
    this.workOrderService.showModal(AskForCompleteOrSuspendComponent);
  }
}
