import { Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { ApiService, AuthService, PhotoUploadService } from 'services';
import { HttpParams } from '@angular/common/http';
import {
  AtmCardReaderTasks,
  AtmForm,
  AtmSoftware,
  AtmSpecs,
  AtmTasks,
  AtmEnvDepDispTasks,
  AtmReceiptPrinterTasks,
  AtmCashDispenserTasks,
  AtmFasciaAdaTasks,
  AtmCashDepositoryTasks,
  AtmCheckDepositoryTasks,
  AtmVacuumBlowOutTasks,
} from 'models';
import { AtmDto, atmMapRequest, AtmRequest, AtmResponse } from 'models/api';
import { AtmPhotoUploadKey } from 'utils';
import { FormPageService } from 'pages/form-page/form-page-service';

@Injectable({
  providedIn: 'root',
})
export class AtmService implements FormPageService<AtmForm, AtmResponse> {
  public form: AtmForm = {
    atmSpecs: new AtmSpecs(),
    software: new AtmSoftware(),
    atmTasks: new AtmTasks(),
    cardReaderTasks: new AtmCardReaderTasks(),
    envDepDispTasks: new AtmEnvDepDispTasks(),
    receiptPrinterTasks: new AtmReceiptPrinterTasks(),
    cashDispenserTasks: new AtmCashDispenserTasks(),
    fasciaAdaTasks: new AtmFasciaAdaTasks(),
    cashDepositoryTasks: new AtmCashDepositoryTasks(),
    checkDepositoryTasks: new AtmCheckDepositoryTasks(),
    vacuumBlowOutTasks: new AtmVacuumBlowOutTasks(),
    comments: '',
  };

  workOrder = '';

  constructor(
    private readonly service: ApiService,
    private readonly authService: AuthService,
    private readonly photoUploadService: PhotoUploadService
  ) {}

  updateForm(data: AtmForm) {
    this.form = data;
  }

  public getForm(): Observable<AtmResponse> {
    const params = new HttpParams({
      fromObject: { userId: this.authService.user?.personnelNumber ?? '' },
    });
    return this.service.sendRequest<AtmResponse>({
      method: 'get',
      url: `pmforms/atm/${this.workOrder}`,
      params,
      urlPrefix: 'ccc',
    });
  }

  /**
   * Saves ATM cleaning form
   */
  public saveForm(request: AtmRequest): Observable<string> {
    const saveForm = atmMapRequest(request);
    return this.service.sendRequest<string>({
      method: 'post',
      url: 'pmforms/atm',
      body: saveForm,
      urlPrefix: 'ccc',
    });
  }

  public loadPhotos = (data: AtmDto): Observable<Record<AtmPhotoUploadKey, string>> => {
    const sources = [
      data.photo_Front_Distance,
      data.photo_Card_Reader,
      data.photo_Depositories,
      data.photo_Dispenser,
      data.photo_CustomerMonitor,
      data.photo_SerialNumberLabel,
      data.photo_FrontClose,
      data.photo_Computer,
      data.photo_CustomerScreen,
      data.photo_Printer,
      data.photo_OperatorPanel,
      data.photo_Software,
      data.photo_AdditionalPhoto,
    ].map((url) => {
      if (!url) {
        return Promise.resolve({ sasUrl: '' });
      }
      return this.photoUploadService.getPhotoUrl(url);
    });

    return forkJoin(sources).pipe(
      map(
        ([
          photo_Front_Distance,
          photo_Card_Reader,
          photo_Depositories,
          photo_Dispenser,
          photo_CustomerMonitor,
          photo_SerialNumberLabel,
          photo_FrontClose,
          photo_Computer,
          photo_CustomerScreen,
          photo_Printer,
          photo_OperatorPanel,
          photo_Software,
          photo_AdditionalPhoto,
        ]) => ({
          'atm-front-distance': photo_Front_Distance.sasUrl,
          'atm-card-reader': photo_Card_Reader.sasUrl,
          'atm-depositor': photo_Depositories.sasUrl,
          'atm-dispenser': photo_Dispenser.sasUrl,
          'atm-customer-monitor': photo_CustomerMonitor.sasUrl,
          'atm-serial-number-label': photo_SerialNumberLabel.sasUrl,
          'atm-front-close': photo_FrontClose.sasUrl,
          'atm-computer': photo_Computer.sasUrl,
          'atm-touchscreen-fdks': photo_CustomerScreen.sasUrl,
          'atm-printer': photo_Printer.sasUrl,
          'atm-operator-panel': photo_OperatorPanel.sasUrl,
          'atm-software': photo_Software.sasUrl,
          'atm-additional-photo': photo_AdditionalPhoto.sasUrl,
        })
      ),
      catchError(() =>
        of({
          'atm-front-distance': '',
          'atm-card-reader': '',
          'atm-depositor': '',
          'atm-dispenser': '',
          'atm-customer-monitor': '',
          'atm-serial-number-label': '',
          'atm-front-close': '',
          'atm-computer': '',
          'atm-touchscreen-fdks': '',
          'atm-printer': '',
          'atm-operator-panel': '',
          'atm-software': '',
          'atm-additional-photo': '',
        })
      )
    );
  };

  getPhotos(data: AtmResponse, updateFormPhotos: (photos: Record<AtmPhotoUploadKey, string>) => void) {
    this.loadPhotos(data.atm).subscribe((photos) => {
      updateFormPhotos(photos);
    });
  }
}
