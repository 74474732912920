import { Component, Input } from '@angular/core';
import { ServiceCallsComponent } from '../service-calls/service-calls.component';
import { AdsCallControl } from 'models';

@Component({
  selector: 'ads-call-control-pm-board',
  standalone: true,
  imports: [ServiceCallsComponent],
  templateUrl: './call-control-pm-board.component.html',
})
export class CallControlPmBoardComponent {
  @Input() isDesktopView = false;
  @Input() calls: AdsCallControl[] = [];
  constructor() {}
}
