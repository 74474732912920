import { CompleteNa } from '../shared/complete-na';

export class AtmReplenishmentTasks {
  allNa: boolean;
  paperRoll: CompleteNa | null;
  depositoryInk: CompleteNa | null;
  envelopeDispenser: CompleteNa | null;

  constructor() {
    this.allNa = false;
    this.paperRoll = null;
    this.depositoryInk = null;
    this.envelopeDispenser = null;
  }
}
