<h4 class="title">Travel Back & Lunch</h4>
<mat-checkbox (change)="($event)" [(ngModel)]="includeTravelBack"> Travel back time </mat-checkbox>
<div *ngIf="includeTravelBack">
  <ads-datepicker [value]="travelBackDateTime" label="Date" [disabled]="true"></ads-datepicker>
  <ads-timepicker label="Leave Time" (valueChange)="leaveDateTime = getDateTime(travelBackDateTime, $event)"></ads-timepicker>
  <ads-timepicker label="Arrival Time" (valueChange)="arrivalDateTime = getDateTime(travelBackDateTime, $event)"></ads-timepicker>
</div>
<ads-radio-list [disabled]="true" [value]="workOrderService.leaveSite" [options]="options"></ads-radio-list>
<mat-checkbox class="lunch-time-checkbox" (change)="($event)" [(ngModel)]="includeLunchTime"> Travel lunch time </mat-checkbox>
<div *ngIf="includeLunchTime">
  <ads-datepicker [value]="lunchDateTime" label="Lunch Date" [disabled]="true"></ads-datepicker>
  <ads-timepicker label="Lunch Start Time" (valueChange)="startDateTime = getDateTime(lunchDateTime, $event)"></ads-timepicker>
  <ads-timepicker label="Lunch End Time" (valueChange)="endDateTime = getDateTime(lunchDateTime, $event)"></ads-timepicker>
</div>

<ads-modal-actions
  (cancel)="hideAll()"
  (confirm)="onConfirm()"
></ads-modal-actions>
