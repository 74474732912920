import { getAreAllNa } from 'app/utils/areAllNa';
import { SecurityAlarmPanelDto } from 'models/api/security/alarm/alarm-panel-dto';
import { SecurityAlarmPanelTasksDto } from 'models/api/security/alarm/alarm-panel-tasks-dto';
import { SecurityBatteriesDto } from 'models/api/security/alarm/batteries-dto';
import { SecurityBatteryTasksDto } from 'models/api/security/alarm/battery-tasks-dto';
import { SecurityHubDto } from 'models/api/security/alarm/hub-dto';
import { SecurityKeypadTasksDto } from 'models/api/security/alarm/keypad-tasks-dto';
import { SecurityKeypadsDto } from 'models/api/security/alarm/keypads-dto';
import { SecurityPathTableSectionDto } from 'models/api/security/alarm/path-table-dto';
import { SecuritySensorsDto } from 'models/api/security/alarm/sensors-dto';
import { SecuritySensorsTasksDto } from 'models/api/security/alarm/sensors-tasks-dto';
import { SecurityWirelessReceiverPanelDto } from 'models/api/security/alarm/wireless-receiver-panel-dto';
import { SecurityZoneExpanderSectionDto } from 'models/api/security/alarm/zone-expander-panel-dto';
import { SecurityAlarmDto } from 'models/api/security/security';
import { AlarmPanelTasks } from 'models/security/alarm/alarmPanelTasks';
import { AlarmSecurityForm } from 'models/security/alarm/alarmSecurityForm';
import { BatteriesForm } from 'models/security/alarm/batteries';
import { BatteryTasks } from 'models/security/alarm/batteryTasks';
import { HubTasks } from 'models/security/alarm/hubTasks';
import { KeypadsForm } from 'models/security/alarm/keypads';
import { KeypadTasks } from 'models/security/alarm/keypadTasks';
import { SensorsForm } from 'models/security/alarm/sensors';
import { SensorsTasksForm } from 'models/security/alarm/sensorsTasks';
import { SecurityAlarmDropdowns } from 'models/security/security-dropdowns';
import {
  AlarmLists,
  AlarmPanelFormSection,
  HubFormSection,
  PathTableFormSection,
  WirelessReceiverForm,
  ZoneExapnderForm,
} from 'pages/security/security.service.models';
import { PhoneNumber } from 'shared/models/phone-number';

export const mapAlarmPanelsResponse = (panels: SecurityAlarmPanelDto[]): AlarmPanelFormSection[] => {
  if (panels.length === 0) {
    return [new AlarmPanelFormSection(1)];
  }
  return panels.map((panel, index) => {
    const formattedPhoneNumber = new PhoneNumber('', '', '');
    formattedPhoneNumber.area = panel?.phoneNumber?.split(')')[0].split('(')[1] ?? '';
    formattedPhoneNumber.exchange = panel?.phoneNumber?.split(')')[1].split('-')[0].trim() ?? '';
    formattedPhoneNumber.subscriber = panel?.phoneNumber?.split(')')[1].split('-')[1] ?? '';
    return {
      alarmModel: panel.alarmModel,
      accountNumber: panel.account,
      firmwareVersion: panel.firmwareVersion,
      overallCondition: panel.overallcondition,
      phoneNumber: formattedPhoneNumber.area.length !== 0 ? formattedPhoneNumber : null,
      ipAddress: panel.ipAddress,
      meId: panel.meid,
      zonesAmount: panel.totalZonesAvailableForUse,
      alarmPanelZonesAmount: panel.totalZonesInAlarmPanel,
      manufacturer: panel.alarmManufacturer,
      otherManufacturer: panel.alarmManufacturer_Other,
      centralStation: panel.centralStation,
      otherCentralStation: panel.centralStation_Other,
      otherEquipmentRating: panel.equipment_Rating,
      index: index + 1,
    };
  });
};

export const mapZoneExpanderSectionsResponse = (panels: SecurityZoneExpanderSectionDto[]): ZoneExapnderForm[] => {
  if (panels.length === 0) {
    return [];
  }
  return panels.map((panel, index) => ({
    location: panel.locationOfZoneExpander ?? '',
    index: index + 1,
  }));
};

export const mapWirelessReceiverSectionsResponse = (
  panels: SecurityWirelessReceiverPanelDto[]
): WirelessReceiverForm[] => {
  if (panels.length === 0) {
    return [];
  }
  return panels.map((panel, index) => ({
    location: panel.locationOfWirelessReceiver ?? '',
    lastBatterChangeButtons: panel.dateBatteriesWereChangedInHoldupButtons
      ? new Date(panel.dateBatteriesWereChangedInHoldupButtons)
      : null,
    lastBatterChangeContacts: panel.dateBatteriesWereChangedInContacts
      ? new Date(panel.dateBatteriesWereChangedInContacts)
      : null,
    lastBatterChangeMoneyClips: panel.dateBatteriesWereChangedInMoneyClips
      ? new Date(panel.dateBatteriesWereChangedInMoneyClips)
      : null,
    lastBatterChangeMotions: panel.dateBatteriesWereChangedInMotions
      ? new Date(panel.dateBatteriesWereChangedInMotions)
      : null,
    manufacturer: panel.wirelessReceiverManufacturer ?? '',
    model: panel.wirelessReceiverModel ?? '',
    index: index + 1,
  }));
};

export const mapSecurityAlarmResponse = (data: SecurityAlarmDto): AlarmSecurityForm => ({
  alarmPanels: mapAlarmPanelsResponse(data.security_Alarm_AlarmPanels),
  pathTableSections: mapPathTableSectionsResponse(data.security_Alarm_PathTables),
  wirelessReceiverSections: mapWirelessReceiverSectionsResponse(data.security_Alarm_Wireless_Receivers),
  zoneExpanderSections: mapZoneExpanderSectionsResponse(data.security_Alarm_ZoneExpanders),
  alarmPanelTasks: mapAlarmPanelTasksResponse(data.security_Alarm_PanelTasks),
  batteries: mapBatteriesResponse(data.security_Alarm_Batteries),
  batteryTasks: mapBatteryTasksResponse(data.security_Alarm_BatteryTasks),
  keypads: mapKeypadsResponse(data.security_Alarm_Keypads),
  keypadTasks: mapKeypadTasksResponse(data.security_Alarm_KeypadTasks),
  sensors: mapSensorsResponse(data.security_Alarm_Sensors),
  sensorsTasks: mapSensorsTasksResponse(data.security_Alarm_SensorTasks),
  hubSections: mapHubsResponse(data.security_Alarm_HubTasks_TellerHoldUpTypes),
  platformSections: mapHubsResponse(data.security_Alarm_HubTasks_PlatformHoldupTypes),
  platformTasks: mapPlatformTasksResponse(data),
  tellerTasks: mapTellerTasksResponse(data),
  comments: data.additionalComments ?? null,
});

export const mapPathTableSectionsResponse = (sections: SecurityPathTableSectionDto[]): PathTableFormSection[] => {
  if (sections.length === 0) {
    return [new PathTableFormSection(1)];
  }

  return sections.map((panel, index) => ({
    communicationType: panel.commType ?? '',
    path: panel.path ?? '',
    pathType: panel.pathType ?? '',
    receiverAddress: panel.receiverAddressNumber ?? '',
    index: index + 1,
  }));
};

export const mapHubsResponse = (sections: SecurityHubDto[]): HubFormSection[] => {
  if (sections.length === 0) {
    return [];
  }
  return sections.map((panel, index) => ({
    index: index + 1,
    quantity: panel.quantity?.toString() ?? '',
    type: panel.type ?? '',
  }));
};

export const mapPlatformTasksResponse = (alarm: SecurityAlarmDto): HubTasks => ({
  areDevicesZonedOut: alarm.platformHoldUpTypes_AreHoldUpDevicesZonedOut,
  testHoldUpDevices: alarm.platformHoldUpTypes_TestTellerHoldUpTypes,
});

export const mapTellerTasksResponse = (alarm: SecurityAlarmDto): HubTasks => ({
  areDevicesZonedOut: alarm.tellerHoldUpTypes_AreHoldUpDevicesZonedOut,
  testHoldUpDevices: alarm.tellerHoldUpTypes_TestTellerHoldUpTypes,
});

export const mapAlarmPanelTasksResponse = (data: SecurityAlarmPanelTasksDto[]): AlarmPanelTasks => ({
  isMonitoringSheetPresent: data[0]?.monitoringSheetWithControlPane ?? null,
  isProgrammingSheetPresent: data[0]?.isProgrammingSheetWithControlPanel ?? null,
  testCellularComms: data[0]?.testCellularComms ?? null,
  testIpComms: data[0]?.testIPComms ?? null,
  testPhoneComms: data[0]?.testPhoneComms ?? null,
  testPhoneComsLoss: data[0]?.testPhoneCommsLossDetection ?? null,
  testTamperSwith: data[0]?.testControlPanelTamperSwitch ?? null,
  verifyPrimaryDialer: data[0]?.verifiedReceiverPrimaryDialerPhone ?? null,
  verifySecondaryReceiver: data[0]?.verifiedSecondaryReceiverPhone ?? null,
  wireAndPcbInspection: data[0]?.inspecAllWireAndPCBConnectionInControlPane ?? null,
});

export const mapBatteriesResponse = (data: SecurityBatteriesDto[]): BatteriesForm => ({
  batteriesAmount: data[0]?.quantityOfBatteriesInstalled ?? null,
  installation: data[0]?.dateOfBatteryInstallation ? new Date(data[0]?.dateOfBatteryInstallation) : null,
  secondaryBatteryInstallation: data[0]?.dateOfSecondaryPowerSupplyInstallation
    ? new Date(data[0]?.dateOfSecondaryPowerSupplyInstallation)
    : null,
  secondaryPowerSupply: data[0]?.secondaryPowerSupplyInstalled ?? null,
  voltageTestResult: data[0]?.batteryVoltageAfter1HourPowerLoss ?? null,
});

export const mapBatteryTasksResponse = (data: SecurityBatteryTasksDto[]): BatteryTasks => ({
  allNa: getAreAllNa(data[0]),
  acLossTest: data[0]?.testACLossDetection ?? null,
  batteryLossTest: data[0]?.testBatteryLossDetection ?? null,
});

export const mapKeypadsResponse = (data: SecurityKeypadsDto[]): KeypadsForm => ({
  keypadsWithoutZones: data[0]?.quantityOfKepadsWithoutZones?.toString() ?? '',
  keypadsWithZones: data[0]?.quantityOfKepadsWithZones?.toString() ?? '',
});

export const mapKeypadTasksResponse = (data: SecurityKeypadTasksDto[]): KeypadTasks => ({
  ack70Test: data[0]?.testAck ?? null,
  duressCodeTest: data[0]?.testDuressCode ?? null,
  keypadTamperTest: data[0]?.testKeypadTemper ?? null,
  allNa: getAreAllNa(data[0]),
});

export const mapSensorsResponse = (data: SecuritySensorsDto[]): SensorsForm => ({
  armedCashContainers: data[0]?.quantityOfArmedCashContainers?.toString() ?? '',
  audioSensors: data[0]?.quantityOfAudioSensors?.toString() ?? '',
  atmsAmount: data[0]?.quantityOfATMs?.toString() ?? '',
  hardWiredDoorContacts: data[0]?.quantityOfPremiseDoorContactsHardwired?.toString() ?? '',
  hardwiredMotionSensors: data[0]?.quantityOfMotionSensorsHardwired?.toString() ?? '',
  hardwiredNightDropContacts: data[0]?.quantityOfNightDropContactsHardwired?.toString() ?? '',
  tcdTcrAmount: data[0]?.quantityOfTCRTCD?.toString() ?? '',
  vaultDoorContacts: data[0]?.quantityOfVaultDoorContacts?.toString() ?? '',
  wirelessDoorContacts: data[0]?.quantityOfPremiseDoorContactsWireless?.toString() ?? '',
  wirelessMotionSensors: data[0]?.quantityOfMotionSensorsWireless?.toString() ?? '',
  wirelessNightDropContacts: data[0]?.quantityOfNightDropContactsWireless?.toString() ?? '',
});

export const mapSensorsTasksResponse = (data: SecuritySensorsTasksDto[]): SensorsTasksForm => ({
  allNa: getAreAllNa(data[0]),
  testAtmDoorContact: data[0]?.testATMDoorContact ?? null,
  testAtmHeatSensors: data[0]?.testATMHeatSensors ?? null,
  testAtmVib: data[0]?.testATMVib ?? null,
  testCashContainerContacts: data[0]?.testCashContainerContacts ?? null,
  testCashContainerHeatSensors: data[0]?.testCashContainerHeatSensors ?? null,
  testCashContainerVibes: data[0]?.testCashContainerVibes ?? null,
  testMotionSensors: data[0]?.testMotionSensors ?? null,
  testNightDropContacts: data[0]?.testNightDropContacts ?? null,
  testNightDropHead: data[0]?.testNightDropHead ?? null,
  testNightDropHeatSensors: data[0]?.testNightDropHeatSensors ?? null,
  testNightDropVib: data[0]?.testNightDropVib ?? null,
  testPremiseDoorContacts: data[0]?.testPremiseDoorContacts ?? null,
  testTcrTcdDoorContacts: data[0]?.testTCR_TCDDoorContacts ?? null,
  testTcrTcdHeatSensors: data[0]?.testTCR_TCDHeatSensors ?? null,
  testTcrTcdVib: data[0]?.testTCR_TCDVib ?? null,
  testVaultAudioSensors: data[0]?.testVaultAudioSensors ?? null,
  testVaultDoor: data[0]?.testVaultDoor ?? null,
  testVaultDoorHeatSensors: data[0]?.testVaultDoorHeatSensors ?? null,
  verifiedAtmVibSetting: data[0]?.verifiedAtmVibSettingATM ?? null,
  verifiedNightDropVibSettings: data[0]?.verifiedNightDropVibSettings ?? null,
  verifiedTcdTcrVibSetting: data[0]?.verifiedTcd_TcrVibSettingTCD_TCR ?? null,
  verifiedTestCashVibSetting: data[0]?.verifiedTestCashVibSetting ?? null,
});

export const mapAlarmDropdowns = (dropdowns: SecurityAlarmDropdowns): AlarmLists => ({
  centralStationsList: dropdowns.alarmAlarmPanelCentralStation.map((station) => ({
    name: station.name,
    value: station.name,
  })),
  alarmPanelOverallConditionsList: dropdowns.alarmAlarmPanelOverallCondition.map((condition) => ({
    name: condition.name,
    value: condition.name,
  })),
  manufacturerList: dropdowns.alarmAlarmPanelsAlarmManufacturers.map((manufacturer) => ({
    name: manufacturer.manufacturerName,
    value: manufacturer.manufacturerName,
  })),
  pathTypesList: dropdowns.alarmPathTablePathTypes.map((pathType) => ({
    name: pathType.name,
    value: pathType.name,
  })),
  communicationTypeList: dropdowns.alarmPathTableCommTypes.map((commType) => ({
    name: commType.name,
    value: commType.name,
  })),
  hubTypes: dropdowns.alarmHubTasksPlatformHoldUpTypesTypes.map((hubType) => ({
    name: hubType.name,
    value: hubType.name,
  })),
});
