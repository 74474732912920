import { ThemingService } from 'services';
import { AdsCallControl } from 'models';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Component, Input, OnInit } from '@angular/core';
import { ServiceCallsService } from './call-control-filters/sorting.service';
import { DataRange, ServiceCallsHeaderComponent } from './call-control-filters/service-calls-header.component';
import { Router } from '@angular/router';
import { RoutesEnum } from 'app/utils/navigation';
import { statusColors } from 'app/utils/call-control';

@Component({
  selector: 'ads-service-calls',
  standalone: true,
  imports: [CommonModule, ServiceCallsHeaderComponent, MatIconModule],
  templateUrl: './service-calls.component.html',
  styleUrl: './service-calls.component.scss',
})
export class ServiceCallsComponent implements OnInit {
  @Input() isDesktopView = false;
  @Input() calls: AdsCallControl[] = [];
  @Input() allCalls: AdsCallControl[] = [];
  _calls: AdsCallControl[] = [];
  filterCriteria: Map<string, DataRange> = new Map<string, DataRange>();
  customCompare = new Map<string, (call1: AdsCallControl, call2: AdsCallControl) => number>([
    [
      'address',
      (call1, call2) => {
        if (call1 && call2) {
          return this.compareAddresses(call1, call2);
        } else {
          return 0;
        }
      },
    ],
    [
      'customer',
      (call1, call2) => {
        if (call1 && call2) {
          const value1 = `${call1.center}, ${call1.customer}`;
          const value2 = `${call2.center}, ${call2.customer}`;
          return value1.localeCompare(value2);
        } else {
          return 0;
        }
      },
    ],
  ]);

  getDate(date: Date | string | undefined) {
    if (date) {
      return new Date(date).toLocaleDateString();
    } else {
      return '';
    }
  }

  getTime(date: Date | string | undefined) {
    if (date) {
      return new Date(date).toLocaleTimeString();
    } else {
      return '';
    }
  }

  currentTheme = '';
  statusColors = statusColors;

  constructor(
    public readonly theming: ThemingService,
    public readonly service: ServiceCallsService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.currentTheme = this.theming.theme.name;
    this.theming.watchTheme.subscribe((theme) => {
      this.currentTheme = theme;
    });
  }

  refreshData(): void {
    this.filter();
    this.sort();
  }

  filter() {
    // Status & Date filters
    this.calls = [...this.allCalls];
    if (this.service.statusFilter.value !== 'none') {
      this.calls = [...this.allCalls.filter((call) => call.laststatusname === this.service.statusFilter.value)];
    }
    const minDate = this.service.dateFilter.minValue;
    const maxDate = this.service.dateFilter.maxValue;
    if (minDate) {
      this.calls = this.calls.filter((call) => new Date(call.dateopened ?? '') >= new Date(minDate));
    }
    if (maxDate) {
      this.calls = this.calls.filter((call) => new Date(call.dateopened ?? '') <= new Date(maxDate));
    }
    // Search text filter: any match in any field
    this.calls = this.calls.filter((call) => {
      const values = Object.values(call)
        .filter((call) => !!call)
        .map((call) => call.toString().toLowerCase());
      return values.some((value) => value.includes(this.service.searchText.toLowerCase()));
    });
    this._calls = [...this.calls];
  }

  sort() {
    const asc = this.service.sorting.sortAsc;
    const column = this.service.sorting.activeSortCriteria;

    if (this._calls.length === 0) {
      this._calls = [...this.calls];
    }
    if (column === 'none') {
      this.calls = [...this._calls];
    }

    if (this.customCompare.has(column)) {
      this.calls = [...this.calls].sort((call1, call2) => {
        return this.customCompare.get(column)?.(call1, call2) ?? 0;
      });
      if (!asc) {
        this.calls = [...this.calls].reverse();
      }
    } else {
      this.calls = [...this.calls].sort((call1, call2) => {
        if (column === 'none') return 0;
        let value1 = call1[column as keyof AdsCallControl] as string | Date;
        let value2 = call2[column as keyof AdsCallControl] as string | Date;
        if (value1 && value2) {
          if (column.includes('date')) {
            value1 = new Date(value1);
            value2 = new Date(value2);
          }
          if (value1 < value2) return asc ? -1 : 1;
          if (value1 > value2) return asc ? 1 : -1;
        }
        return 0;
      });
    }
  }

  compareAddresses(call1: AdsCallControl, call2: AdsCallControl): number {
    let compare = (call1.state ?? '')?.toLocaleLowerCase().localeCompare((call2.state ?? '')?.toLocaleLowerCase());
    if (compare === 0)
      compare = (call1.city ?? '')?.toLocaleLowerCase().localeCompare((call2.city ?? '')?.toLocaleLowerCase());
    if (compare === 0)
      compare = (call1.zip ?? '')?.toLocaleLowerCase().localeCompare((call2.zip ?? '')?.toLocaleLowerCase());
    if (compare === 0) {
      const streetNumber1 = call1.address?.split(' ')[0];
      const streetName1 = call1.address?.split(' ')[1];
      const streetNumber2 = call2.address?.split(' ')[0];
      const streetName2 = call2.address?.split(' ')[1];
      compare = (streetName1 ?? '')?.toLocaleLowerCase().localeCompare((streetName2 ?? '')?.toLocaleLowerCase());
      if (compare === 0)
        compare = (streetNumber1 ?? '')?.toLocaleLowerCase().localeCompare((streetNumber2 ?? '')?.toLocaleLowerCase());
    }
    return compare;
  }

  clearFilters() {
    this.service.statusFilter.value = 'none';
    this.service.dateFilter.minValue = undefined;
    this.service.dateFilter.maxValue = undefined;
    this.service.searchText = '';
    this.refreshData();
  }

  onCallClick(call: AdsCallControl) {
    this.router.navigateByUrl(`${RoutesEnum.WorkOrder}/${call.workorder}`, { state: { call } });
  }

  getMinDate() {
    const call = this.calls.reduce(
      (min, call) => (new Date(call?.dateopened ?? '') < new Date(min?.dateopened ?? '') ? call : min),
      this.calls[0],
    );
    return new Date(call?.dateopened ?? '');
  }
}
