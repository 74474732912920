<h5>Select Serviced Equipment</h5>
<div class="search-header">
  <div class="search-box">
    <ads-common-form-input label="Search" [maxLength]="50" [(ngModel)]="searchText"></ads-common-form-input>
    <mat-icon>search</mat-icon>
  </div>
  <div *ngIf="!layoutNavService.isDesktopView()" class="mobile-count">
    <span *ngIf="!reviewEquipmentService.toAdd.selected.length" class="count">
      No selected items
    </span>
    <span *ngIf="reviewEquipmentService.toAdd.selected.length" class="count">
      {{reviewEquipmentService.toAdd.selected.length}} selected items
    </span>
    <mat-checkbox [(ngModel)]="areAllSelected" labelPosition="before" (change)="onMobileSelectAll($event)" color="primary">Select all</mat-checkbox>
  </div>
  <ng-container *ngIf="layoutNavService.isDesktopView()">
    <span *ngIf="!reviewEquipmentService.toAdd.selected.length" class="count">
      No selected items
    </span>
    <span *ngIf="reviewEquipmentService.toAdd.selected.length" class="count">
      {{reviewEquipmentService.toAdd.selected.length}} selected items
    </span>
  </ng-container>
</div>
<ads-equipment-table
  *ngIf="reviewEquipmentService.allEquipment.data.length > 0"
  [dataSource]="getFilteredEquipment()"
  [searchText]="searchText"
  [columnsToShow]="displayedColumnsKeys"
  [selection]="reviewEquipmentService.toAdd"
  (selectionChange)="areAllSelected = $event"
></ads-equipment-table>

<div class="menu-actions">
  <ads-custom-button label="Cancel" (clicked)="hideAll()"></ads-custom-button>
  <button mat-button mat-stroked-button (click)="equipmentNotShown()">Not shown</button>
  <ads-custom-button class="main-button confirm" label="Confirm" (clicked)="onConfirm()"></ads-custom-button>
</div>
