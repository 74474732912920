import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogActions, MatDialogContent } from '@angular/material/dialog';
import { AccessControlCardReaderInterfaceTableFormSection } from '../access-hardware.component';
import { AccessControlHardwareDropdowns } from 'models/security/security-dropdowns';
import { TableBottomSheetsComponent } from 'forms/shared/table-bottom-sheets/table-bottom-sheets.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { TableFormBottomSheetInputComponent } from 'shared/material-wrappers/table-form/table-form-bottom-sheet-input/table-form-bottom-sheet-input.component';
import { TableFormInputComponent } from 'shared/material-wrappers/table-form/table-form-input/table-form-input.component';
import { BaseAddManyModalComponent } from 'forms/shared/nested-section-base/add-many-modal.base';

export interface SurveillanceCameraTableSectionAddMany {
  quantity: string | undefined;
  interfaceType: string | undefined;
  index: number;
}

@Component({
  selector: 'ads-ah-card-reader-table-add-many-modal',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogContent,
    MatDialogActions,
    MatTableModule,
    CommonModule,
    FormsModule,
    MatInputModule,
    TableFormInputComponent,
    TableFormBottomSheetInputComponent,
    MatBottomSheetModule,
  ],
  templateUrl: './ah-card-reader-table-add-many-modal.component.html',
  styleUrl: './ah-card-reader-table-add-many-modal.component.scss',
})
export class AhCardReaderTableAddManyModalComponent extends BaseAddManyModalComponent {
  displayedColumns = ['quantity', 'interfaceType'];

  dataSource: SurveillanceCameraTableSectionAddMany[] = [{ quantity: undefined, interfaceType: undefined, index: 0 }];

  openBottomSheet = (e: unknown) => () => {
    const element = e as AccessControlCardReaderInterfaceTableFormSection;
    const onDismiss = this._bottomSheet.open(TableBottomSheetsComponent, {
      data: {
        selections: [
          {
            title: 'Interface Type',
            control: 'interfaceType',
            options: (this.data.dropdowns as AccessControlHardwareDropdowns)?.acCardReaderInterfaceTableInterfaceTypes,
            value: element.interfaceType,
          },
        ],
      },
    });

    onDismiss.afterDismissed().subscribe((result) => {
      if (result) {
        element.interfaceType = result.interfaceType;
      }
    });
  };
}
