import { Component, inject } from '@angular/core';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormsModule, NgModelGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { RemoveFormSectionComponent } from 'shared/remove-form-section/remove-form-section.component';
import { IndexableFormType } from 'app/utils/miscTypes';
import { BaseNestedFormsSectionComponent } from '../nested-section-base/nested-section.base';

@Component({
  selector: 'ads-nested-multiple-forms-section',
  standalone: true,
  imports: [CommonModule, FormsModule, ExpansionPanelComponent, MatButtonModule, MatIconModule, MatExpansionModule],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
  templateUrl: './nested-multiple-forms-section.component.html',
  styleUrl: './nested-multiple-forms-section.component.scss',
})
export class NestedMultipleFormsSectionComponent<
  T extends IndexableFormType,
> extends BaseNestedFormsSectionComponent<T> {
  dialog = inject(MatDialog);

  onDeleteChecked(item: T): void {
    const index = this.deletingItemList.findIndex(x => x.index === item.index);

    if (index >= 0) {
      this.deletingItemList.splice(index, 1);
    } else {
      this.deletingItemList.push(item);
    }
  }

  onDelete(): void {
    this.dialog.open(RemoveFormSectionComponent, {
      data: {
        sectionName: `selected ${this.title}s from the `,
        onConfirm: () => {
          this.deletingItemList.forEach((item) => {
            this.deleteClicked.emit(item);
          });
          this.deletingItemList = [];
          this.deleting = false;
        },
      },
    });
  }
}
