import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, AuthService } from 'services';
import { HttpParams } from '@angular/common/http';
import { securityMapRequest, SecurityRequest, SecurityResponse } from 'models/api';
import {
  AlarmPanelTasks,
  AudioMatrixTasks,
  BatteriesForm,
  BatteryTasks,
  CustomerUnitForm,
  DealDrawerTasks,
  HubTasks,
  KeypadTasks,
  SecurityForm,
  SensorsTasks,
  TellerUnitForm,
} from 'models';
import { mapSecuritySafesResponse } from 'models/api/maps/security-map/safes/safes-map-response';
import {
  SecurityAccessHardwareDto,
  SecurityAlarmDto,
  SecurityAudioVideoSectionsDto,
  SecurityDealDrawerSectionsDto,
  SecurityNightDropSectionsDto,
  SecurityRtsSectionsDto,
  SecuritySafesSectionsDto,
  SecuritySurveillanceDto,
  SecurityUCSDto,
  SecurityVaultDto,
} from 'models/api/security/security';
import {
  SecurityAlarmDropdowns,
  SecurityDealDrawerDropdowns,
  SecurityNightDropDropdowns,
  SecurityRtsDropdowns,
  SecuritySafesDropdowns,
  SecurityVaultDropdowns,
  SecurityUCSDropdowns,
  AccessControlHardwareDropdowns,
  SecurityAudioVideoDropdowns,
  SecuritySurveillanceDropdowns,
} from 'models/security/security-dropdowns';
import {
  mapAlarmDropdowns,
  mapAlarmPanelsResponse,
  mapAlarmPanelTasksResponse,
  mapBatteriesResponse,
  mapBatteryTasksResponse,
  mapHubsResponse,
  mapKeypadsResponse,
  mapKeypadTasksResponse,
  mapPathTableSectionsResponse,
  mapPlatformTasksResponse,
  mapSensorsResponse,
  mapSensorsTasksResponse,
  mapTellerTasksResponse,
  mapWirelessReceiverSectionsResponse,
  mapZoneExpanderSectionsResponse,
} from 'models/api/maps/security-map/alarm/alarm-map-response';
import {
  mapCustomerUnitResponse,
  mapRtsDropdowns,
  mapRtsSectionsResponse,
  mapTellerUnitResponse,
} from 'models/api/maps/security-map/rts/rts-map-response';
import {
  AccessHardwarePageData,
  AlarmPageData,
  AudioVideoPageData,
  DealDrawerPageData,
  NightDropPageData,
  RtsPageData,
  SafesPageData,
  SurveillancePageData,
  UCSPageData,
  VaultPageData,
} from './security.service.models';
import {
  mapVaultComboLockTasksResponse,
  mapVaultSectionsResponse,
  mapVaultTimeClockTasksResponse,
} from 'models/api/maps/security-map/vault/vault-map-response';
import { mapUCSSections } from 'models/api/maps/security-map/ucs/ucs-map';
import {
  mapDealDrawerSectionsResponse,
  mapDealDrawerTasksResponse,
} from 'models/api/maps/security-map/deal-drawer/deal-drawer-map-response';
import {
  mapAudioMatrixTasksResponse,
  mapAudioVideoSectionsResponse,
} from 'models/api/maps/security-map/audio-video/audio-video-map-response';
import {
  mapSurveillanceDVRNVRSections,
  mapSurveillanceUPSSections,
  mapSurveillancePOESwitchSections,
  mapSurveillanceCameraTableSections,
  mapSurveillanceMonitorTableSections,
  mapSurveillanceDVRTasks,
  mapSurveillanceCameraTasks,
  mapSurveillanceMonitorTasks,
} from 'models/api/maps/security-map/surveillance/surveillance-map';
import {
  SurveillanceCameraTasksForm,
  SurveillanceDVRTasksForm,
  SurveillanceMonitorTasksForm,
} from 'models/security/surveillance/surveillanceSecurityForm';
import { mapSecurityAccessHardwareForm } from 'models/api/maps/security-map/access-hardware/access-hardware-map';
import {
  AccessControlCardReaderTasksForm,
  AccessControlPowerSupplyTasksForm,
  AccessControlTasksForm,
} from 'models/security/access-hardware/accessHardwareSecurityForm';
import { mapSecurityNightDropForm } from 'models/api/maps/security-map/night-drop/night-drop-map';
import {
  NightDropComboLockTasks,
  NightDropDropDoorTasks,
  NightDropHeadTasks,
  NightDropTimeClockTasks,
} from 'models/security/night-drop/nightDropSecurityForm';
import { ComboLockTasks, TimeClockTasks } from 'models/security/shared/shared-tasks';
import { SafesTasks } from 'models/security/safes/safesSecurityForm';
import { mapDealDrawerDropdowns } from 'models/api/maps/security-map/deal-drawer/deal-drawer-dropdown';
import { mapAudioVideoDropdowns } from 'models/api/security/audio-video/audio-video-dropdowns-maps';
import { mapVaultDropdowns } from 'models/api/maps/security-map/vault/vault-dropdowns-map';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  workOrder = '';
  alarmData: AlarmPageData = {
    alarmDropdowns: {
      manufacturerList: [],
      centralStationsList: [],
      alarmPanelOverallConditionsList: [],
      pathTypesList: [],
      communicationTypeList: [],
      hubTypes: [],
    },
    alarmPanelSections: [],
    zoneExpanderSections: [],
    wirelessReceiverSections: [],
    pathTableSections: [],
    hubSections: [],
    alarmPanelTasks: new AlarmPanelTasks(),
    batteries: new BatteriesForm(),
    batteryTasks: new BatteryTasks(),
    keypadTasks: new KeypadTasks(),
    keypads: {},
    sensors: {},
    sensorsTasks: new SensorsTasks(),
    platformSections: [],
    platformTasks: new HubTasks(),
    tellerTasks: new HubTasks(),
    comments: '',
  };
  ucsData: UCSPageData = {
    ucsDropdowns: {
      ucsUcsManufacturers: [],
      ucsUcsOverallConditions: [],
    },
    ucsSections: [],
    ucsTasks: {
      checkForProperOperation: null,
      inspectForExcessiveWear: null,
      cleanAndLubricate: null,
    },
    comments: '',
  };

  accessHardwareData: AccessHardwarePageData = {
    accessHardwareDropdowns: {
      acacSystemManufacturers: [],
      acacOverallConditions: [],
      acCardReaderInterfaceTableInterfaceTypes: [],
      acwPowerSupplyTablePowerSupply: [],
      acwPowerSupplyTableVoltage: [],
      acwDoorTableReaderStyles: [],
      acwDoorTableLockStyles: [],
      controlPanelDropdowns: [],
    },
    accessControls: [],
    accessControlTasks: new AccessControlTasksForm(),
    accessControlCardReaderInterfaces: [],
    accessControlCardReaderTasks: new AccessControlCardReaderTasksForm(),
    accessControlPowerSupplies: [],
    accessControlPowerSupplyTasks: new AccessControlPowerSupplyTasksForm(),
    accessControlDoors: [],
    comments: '',
  };

  nightDropData: NightDropPageData = {
    nightDropDropdowns: {
      nightDropNightDropManufacturers: [],
      nightDropNightDropSwingTypes: [],
      nightDropNightDropDoorStyle: [],
      nightDropNightDropOverallConditions: [],
    },
    nightDropSections: [],
    nightDropDropDoorTasks: new NightDropDropDoorTasks(),
    timeClockTasks: new NightDropTimeClockTasks(),
    comboLockTasks: new NightDropComboLockTasks(),
    nightDropHeadTasks: new NightDropHeadTasks(),
    comments: '',
  };

  safesData: SafesPageData = {
    safesDropdowns: {
      safesSafesSafeManufacturers: [],
      safesSafesSwingTypes: [],
      safesSafesOverallConditions: [],
    },
    safesSections: [],
    safesTasks: new SafesTasks(),
    timeClockTasks: new TimeClockTasks(),
    comboLockTasks: new ComboLockTasks(),
    comments: '',
  };

  vaultData: VaultPageData = {
    vaultDropdowns: {
      manufacturerList: [],
      creepDoorClosesList: [],
      overallConditionsList: [],
      typesList: [],
    },
    vaultSectionSections: [],
    comboLockTasks: new ComboLockTasks(),
    timeClockTasks: new TimeClockTasks(),
    comments: '',
  };

  rtsData: RtsPageData = {
    rtsDropdowns: {
      overallConditionList: [],
      rtsRtsMakeList: [],
      upsendDownSendList: [],
    },
    rtsSections: [],
    customerUnit: new CustomerUnitForm(),
    tellerUnit: new TellerUnitForm(),
    comments: '',
  };

  dealDrawerData: DealDrawerPageData = {
    dealDrawerDropdowns: {
      manufacturerList: [],
      conditionList: [],
    },
    dealDrawerSections: [],
    dealDrawerTasks: new DealDrawerTasks(),
    comments: '',
  };

  audioVideoData: AudioVideoPageData = {
    audioVideoDropdowns: {
      manufacturerList: [],
      conditionList: [],
      videoSystemList: [],
    },
    audioVideoSections: [],
    audioMatrixTasks: new AudioMatrixTasks(),
    comments: '',
  };

  surveillanceData: SurveillancePageData = {
    dvrNvrSections: [],
    upsSections: [],
    poeSwitchSections: [],
    cameraTableSections: [],
    monitorTableSections: [],
    surveillanceDvrTasks: new SurveillanceDVRTasksForm(),
    surveillanceCameraTasks: new SurveillanceCameraTasksForm(),
    surveillanceMonitorTasks: new SurveillanceMonitorTasksForm(),
    overallConditionCameras: '',
    equipmentRatingCameras: '',
    overallConditionMonitors: '',
    equipmentRatingMonitors: '',
    surveillanceDropdowns: {
      surveillanceDvrDvrManufacturers: [],
      surveillanceDVRNVROverallConditions: [],
      surveillanceUpsManufacturers: [],
      surveillancePoeSwitchManufacturers: [],
      surveillanceCameraTableCameraType: [],
      surveillanceCameraTableIsPtz: [],
      surveillanceCameratableOverallConditionCameras: [],
      surveillanceMonitorTableMonitorType: [],
      surveillanceMonitorTableOverallConditionMonitors: [],
    },
    comments: '',
  };

  public securityForm: SecurityForm = {
    alarm: null,
    accessHardware: null,
    ucs: null,
    nightDrop: null,
    dealDrawer: null,
    audioVideo: null,
    safes: null,
    vault: null,
    rts: null,
    surveillance: null,
    selectedEquipment: [],
    applyCSGProtectionDecalInBottomRightCorner: null,
  };

  constructor(private readonly service: ApiService, private readonly authService: AuthService) {}

  public getSecurityForm(): Observable<SecurityResponse> {
    const params = new HttpParams({
      fromObject: { userId: this.authService.user?.personnelNumber ?? '' },
    });
    return this.service.sendRequest<SecurityResponse>({
      method: 'get',
      url: `pmforms/security/${this.workOrder}`,
      params,
      urlPrefix: 'ccc',
    });
  }

  public saveSecurityForm(request: SecurityRequest): Observable<string> {
    const saveForm = securityMapRequest(request);
    return this.service.sendRequest<string>({
      method: 'post',
      url: 'pmforms/security',
      body: saveForm,
      urlPrefix: 'ccc',
    });
  }

  public loadAlarmData(alarmData: SecurityAlarmDto, dropdowns: SecurityAlarmDropdowns): void {
    this.alarmData = {
      alarmDropdowns: mapAlarmDropdowns(dropdowns),
      alarmPanelSections: mapAlarmPanelsResponse(alarmData.security_Alarm_AlarmPanels),
      zoneExpanderSections: mapZoneExpanderSectionsResponse(alarmData.security_Alarm_ZoneExpanders),
      wirelessReceiverSections: mapWirelessReceiverSectionsResponse(alarmData.security_Alarm_Wireless_Receivers),
      pathTableSections: mapPathTableSectionsResponse(alarmData.security_Alarm_PathTables),
      alarmPanelTasks: mapAlarmPanelTasksResponse(alarmData.security_Alarm_PanelTasks),
      batteries: mapBatteriesResponse(alarmData.security_Alarm_Batteries),
      batteryTasks: mapBatteryTasksResponse(alarmData.security_Alarm_BatteryTasks),
      keypads: mapKeypadsResponse(alarmData.security_Alarm_Keypads),
      keypadTasks: mapKeypadTasksResponse(alarmData.security_Alarm_KeypadTasks),
      sensors: mapSensorsResponse(alarmData.security_Alarm_Sensors),
      sensorsTasks: mapSensorsTasksResponse(alarmData.security_Alarm_SensorTasks),
      hubSections: mapHubsResponse(alarmData.security_Alarm_HubTasks_TellerHoldUpTypes),
      platformSections: mapHubsResponse(alarmData.security_Alarm_HubTasks_PlatformHoldupTypes),
      platformTasks: mapPlatformTasksResponse(alarmData),
      tellerTasks: mapTellerTasksResponse(alarmData),
      comments: alarmData.additionalComments ?? '',
    };
  }

  public loadAccessHardwareData(
    accessHardware: SecurityAccessHardwareDto,
    dropdowns: AccessControlHardwareDropdowns
  ): void {
    this.accessHardwareData = {
      accessHardwareDropdowns: dropdowns,
      ...mapSecurityAccessHardwareForm(accessHardware.security_AccessControlHardware),
    };
  }

  public loadNightDropData(nightDropData: SecurityNightDropSectionsDto, dropdowns: SecurityNightDropDropdowns): void {
    this.nightDropData = {
      nightDropDropdowns: dropdowns,
      ...mapSecurityNightDropForm(nightDropData),
    };
  }

  public loadSafesData(safesData: SecuritySafesSectionsDto, dropdowns: SecuritySafesDropdowns): void {
    this.safesData = {
      safesDropdowns: dropdowns,
      ...mapSecuritySafesResponse(safesData),
    };
  }

  public loadVaultData(vaultData: SecurityVaultDto, dropdowns: SecurityVaultDropdowns): void {
    this.vaultData = {
      vaultDropdowns: mapVaultDropdowns(dropdowns),
      vaultSectionSections: mapVaultSectionsResponse(vaultData.security_Vault_Vaults),
      comboLockTasks: mapVaultComboLockTasksResponse(vaultData.security_Vault_ComboLockTasks ?? []),
      timeClockTasks: mapVaultTimeClockTasksResponse(vaultData.security_Vault_TimeClockTasks ?? []),
      comments: vaultData.comments ?? '',
    };
  }

  public loadRtsData(rtsData: SecurityRtsSectionsDto, dropdowns: SecurityRtsDropdowns): void {
    this.rtsData = {
      rtsDropdowns: mapRtsDropdowns(dropdowns),
      rtsSections: mapRtsSectionsResponse(rtsData.security_RTS_RTS),
      customerUnit: mapCustomerUnitResponse(rtsData.security_RTS_CustomerUnit),
      tellerUnit: mapTellerUnitResponse(rtsData.security_RTS_TellerUnit),
      comments: rtsData.comments ?? '',
    };
  }

  public loadUCSData(ucsData: SecurityUCSDto, dropdowns: SecurityUCSDropdowns): void {
    this.ucsData = {
      ucsDropdowns: dropdowns,
      ucsSections: mapUCSSections(ucsData.security_Ucs.security_Ucs_Ucs),
      ucsTasks: ucsData.security_Ucs.security_Ucs_Ucs_Tasks[0] ?? {
        checkForProperOperation: '',
        cleanAndLubricate: '',
        inspectForExcessiveWear: '',
      },
      comments: ucsData.security_Ucs.additionalComments ?? '',
    };
  }

  public loadDealDrawerData(
    dealDrawerData: SecurityDealDrawerSectionsDto,
    dropdowns: SecurityDealDrawerDropdowns
  ): void {
    this.dealDrawerData = {
      dealDrawerDropdowns: mapDealDrawerDropdowns(dropdowns),
      dealDrawerSections: mapDealDrawerSectionsResponse(dealDrawerData.security_DealDrawer_DealDrawers),
      dealDrawerTasks: mapDealDrawerTasksResponse(dealDrawerData.security_DealDrawer_DealDrawerTasks),
      comments: dealDrawerData.comments ?? '',
    };
  }

  public loadAudioVideoData(
    audioVideoData: SecurityAudioVideoSectionsDto,
    dropdowns: SecurityAudioVideoDropdowns
  ): void {
    this.audioVideoData = {
      audioVideoDropdowns: mapAudioVideoDropdowns(dropdowns),
      audioVideoSections: mapAudioVideoSectionsResponse(audioVideoData.security_AudioVideoDU_DriveUpAudioMatrix),
      audioMatrixTasks: mapAudioMatrixTasksResponse(audioVideoData.security_AudioVideoDU_AudioMatrixTasks),
      comments: audioVideoData.comments ?? '',
    };
  }

  public loadSurveillanceData(
    surveillanceData: SecuritySurveillanceDto,
    dropdowns: SecuritySurveillanceDropdowns
  ): void {
    this.surveillanceData = {
      surveillanceDropdowns: dropdowns,
      overallConditionCameras: surveillanceData.security_Surveillance.overallCondition_Cameras ?? '',
      equipmentRatingCameras: surveillanceData.security_Surveillance.equipment_Rating_Cameras ?? '',
      overallConditionMonitors: surveillanceData.security_Surveillance.overallCondition_Monitor ?? '',
      equipmentRatingMonitors: surveillanceData.security_Surveillance.equipment_Rating_Monitor ?? '',
      dvrNvrSections: mapSurveillanceDVRNVRSections(
        surveillanceData.security_Surveillance.security_Surveillance_DVRNVRs
      ),
      upsSections: mapSurveillanceUPSSections(surveillanceData.security_Surveillance.security_Surveillance_UPSs),
      poeSwitchSections: mapSurveillancePOESwitchSections(
        surveillanceData.security_Surveillance.security_Surveillance_POESwitchs
      ),
      cameraTableSections: mapSurveillanceCameraTableSections(
        surveillanceData.security_Surveillance.security_Surveillance_CameraTables
      ),
      monitorTableSections: mapSurveillanceMonitorTableSections(
        surveillanceData.security_Surveillance.security_Surveillance_MonitorTables
      ),
      surveillanceDvrTasks: mapSurveillanceDVRTasks(
        surveillanceData.security_Surveillance.security_Surveillance_DVRTasks?.[0] ?? []
      ),
      surveillanceCameraTasks: mapSurveillanceCameraTasks(
        surveillanceData.security_Surveillance.security_Surveillance_CameraTasks?.[0] ?? []
      ),
      surveillanceMonitorTasks: mapSurveillanceMonitorTasks(
        surveillanceData.security_Surveillance.security_Surveillance_MonitorTasks?.[0] ?? []
      ),
      comments: surveillanceData.security_Surveillance.comments ?? '',
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public parseSecurityForm(activeEquipment: Record<string, boolean>, form: any): SecurityForm {
    const fieldsForm: SecurityForm = {} as never;

    if (activeEquipment['Alarm']) {
      fieldsForm.alarm = {
        alarmPanels: Object.values(form?.value.alarm.alarmPanels),
        pathTableSections: Object.values(form?.value.alarm.pathTableSections),
        zoneExpanderSections: Object.values(form?.value.alarm.zoneExpanderSections),
        wirelessReceiverSections: Object.values(form?.value.alarm.wirelessReceiverSections),
        alarmPanelTasks: form?.value.alarm.alarmPanelTasks,
        batteries: form?.value.alarm.batteries,
        batteryTasks: form?.value.alarm.batteryTasks,
        keypads: form?.value.alarm.keypads,
        keypadTasks: form?.value.alarm.keypadTasks,
        sensors: form?.value.alarm.sensors,
        sensorsTasks: form?.value.alarm.sensorsTasks,
        hubSections: Object.values(form?.value.alarm.hubSections),
        platformSections: Object.values(form?.value.alarm.platformSections),
        tellerTasks: form?.value.alarm.tellerTasks,
        platformTasks: form?.value.alarm.platformTasks,
        comments: form?.value.alarm.comments,
      };
    }

    if (activeEquipment['Access Control Hardware']) {
      fieldsForm.accessHardware = {
        accessControls: Object.values(form?.value.accessHardware.accessControls),
        accessControlTasks: form?.value.accessHardware.accessControlTasks,
        accessControlCardReaderInterfaces: Object.values(form?.value.accessHardware.accessControlCardReaderInterfaces),
        accessControlCardReaderTasks: form?.value.accessHardware.accessControlCardReaderTasks,
        accessControlPowerSupplies: Object.values(form?.value.accessHardware.accessControlPowerSupplies),
        accessControlPowerSupplyTasks: form?.value.accessHardware.accessControlPowerSupplyTasks,
        accessControlDoors: Object.values(form?.value.accessHardware.accessControlDoors),
        comments: form?.value.accessHardware.comments,
      };
    }

    if (activeEquipment['Night Drop']) {
      fieldsForm.nightDrop = {
        nightDropSections: Object.values(form?.value.nightDrop.nightDropSections),
        nightDropDropDoorTasks: form?.value.nightDrop.nightDropDropDoorTasks,
        timeClockTasks: form?.value.nightDrop.timeClockTasks,
        comboLockTasks: form?.value.nightDrop.comboLockTasks,
        nightDropHeadTasks: form?.value.nightDrop.nightDropHeadTasks,
        comments: form?.value.nightDrop.comments,
      };
    }

    if (activeEquipment['Deal Drawer']) {
      fieldsForm.dealDrawer = {
        dealDrawerSections: Object.values(form?.value.dealDrawer.dealDrawerSections),
        dealDrawerTasks: form?.value.dealDrawer.dealDrawerTasks,
        comments: form?.value.dealDrawer.comments,
      };
    }

    if (activeEquipment['Audio/Video DU']) {
      fieldsForm.audioVideo = {
        audioVideoSections: Object.values(form?.value.audioVideo.audioVideoSections),
        audioMatrixTasks: form?.value.audioVideo.audioMatrixTasks,
        comments: form?.value.audioVideo.comments,
      };
    }

    if (activeEquipment['Safes']) {
      fieldsForm.safes = {
        safesSections: Object.values(form?.value.safes.safesPanels),
        safesTasks: form?.value.safes.safesTasks,
        timeClockTasks: form?.value.safes.timeClockTasks,
        comboLockTasks: form?.value.safes.comboLockTasks,
        comments: form?.value.safes.comments,
      };
    }

    if (activeEquipment['Vault']) {
      fieldsForm.vault = {
        vaultSections: Object.values(form?.value.vault.vaultSections),
        timeClockTasks: form?.value.vault.timeClockTasks,
        comboLockTasks: form?.value.vault.comboLockTasks,
        comments: form?.value.vault.comments,
      };
    }

    if (activeEquipment['RTS']) {
      fieldsForm.rts = {
        rtsPanels: Object.values(form?.value.rts.rtsPanels),
        tellerUnit: form?.value.rts.tellerUnit,
        customerUnit: form?.value.rts.customerUnit,
        comments: form?.value.rts.comments,
      };
    }

    if (activeEquipment['UCS']) {
      fieldsForm.ucs = {
        ucsList: Object.values(form?.value.ucs.ucsItems),
        ucsTasks: form?.value.ucs.ucsTasks,
        comments: form?.value.ucs.comments,
      };
    }

    if (activeEquipment['Surveillance']) {
      fieldsForm.surveillance = {
        overallConditionCameras: this.surveillanceData.overallConditionCameras,
        equipmentRatingCameras: this.surveillanceData.equipmentRatingCameras,
        overallConditionMonitor: this.surveillanceData.overallConditionMonitors,
        equipmentRatingMonitor: this.surveillanceData.equipmentRatingMonitors,
        surveillanceDvrNvr: Object.values(form?.value.surveillance.dvrNvrItems),
        surveillanceUps: Object.values(form?.value.surveillance.upsItems),
        surveillancePoeSwitch: Object.values(form?.value.surveillance.poeSwitchItems),
        surveillanceCameraTable: Object.values(form?.value.surveillance.cameraTableItems),
        surveillanceMonitorTable: Object.values(form?.value.surveillance.monitorTableItems),
        surveillanceDvrTasks: form?.value.surveillance.surveillanceDvrTasks,
        surveillanceCameraTasks: form?.value.surveillance.surveillanceCameraTasks,
        surveillanceMonitorTasks: form?.value.surveillance.surveillanceMonitorTasks,
        comments: form?.value.surveillance.comments,
      };
    }

    return fieldsForm;
  }
}
