import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, NgModelGroup } from '@angular/forms';
import { AudioVideoFormSection } from '../audio-video.component';
import { CommonModule } from '@angular/common';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { SelectOption } from 'models';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';

@Component({
  selector: 'ads-audio-video-section-form',
  standalone: true,
  styleUrl: './audio-video-section-form.component.scss',
  imports: [CommonModule, FormsModule, CommonFormInputComponent, SelectComponent, SimpleFormComponent, CommonTextAreaComponent],
  templateUrl: './audio-video-section-form.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class AudioVideoSectionFormComponent {
  @Input() audioVideoLists?: AudioVideoLists;
  @Input() section?: FormGroup;
  @Input() item?: AudioVideoFormSection;

  displayOtherManufacturer = () => {
    return Boolean(this.item?.manufacturer === 'Other');
  };

  displayEquipmentRating(): boolean {
    return this.item?.overallCondition === 'Poor Condition' || this.item?.overallCondition === 'Failed';
  }
}

export interface AudioVideoLists {
  manufacturerList: SelectOption<string | number>[];
  conditionList: SelectOption<string | number>[];
  videoSystemList: SelectOption<string | number>[];
}
