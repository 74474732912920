import { Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { ApiService, AuthService, PhotoUploadService } from 'services';
import { ItmCleaning, ItmCleaningTasks, ItmNetworkLogos, ItmReplenishmentTasks, ItmSection } from 'models';
import { ItmExteriorEvaluation } from 'models/itm-cleaning/itm-exterior-evaluation';
import { ItmCleaningPhotoUploadKey } from 'app/utils/itm-cleaning-form-constants';
import { HttpParams } from '@angular/common/http';
import { ItmCleaningResponse } from 'models/api';
import { ItmCleaningRequest } from 'models/api/itm-cleaning/ads-itm-cleaning-request';
import { itmCleaningMapRequest } from 'models/api/maps/itm-cleaning/itm-cleaning-map-request';
import { ItmCleaningDto } from 'models/api/itm-cleaning/itm-cleaning';
import { FormPageService } from 'pages/form-page/form-page-service';

@Injectable({
  providedIn: 'root',
})
export class ItmCleaningService implements FormPageService<ItmCleaning, ItmCleaningResponse> {
  public form: ItmCleaning = {
    itmSection: new ItmSection(),
    itmExteriorEvaluation: new ItmExteriorEvaluation(),
    itmNetworkLogos: new ItmNetworkLogos(),
    cleaningTasks: new ItmCleaningTasks(),
    replenishmentTasks: new ItmReplenishmentTasks(),
    comments: '',
  };
  workOrder = '';

  constructor(
    private readonly service: ApiService,
    private readonly authService: AuthService,
    private readonly photoUploadService: PhotoUploadService
  ) {}

  updateForm(data: ItmCleaning) {
    this.form = data;
  }

  public getForm(): Observable<ItmCleaningResponse> {
    const params = new HttpParams({
      fromObject: { userId: this.authService.user?.personnelNumber ?? '' },
    });
    return this.service.sendRequest<ItmCleaningResponse>({
      method: 'get',
      url: `pmforms/itmcleaning/${this.workOrder}`,
      params,
      urlPrefix: 'ccc',
    });
  }

  public loadPhotos = (data: ItmCleaningDto): Observable<Record<ItmCleaningPhotoUploadKey, string>> => {
    const sources = [
      data.photoFrontClose,
      data.photoItmScreen,
      data.photoItmNetworkDecals,
      data.photoLongDistance,
      data.photoSerialNumber,
      data.photoItmFeeNotice,
      data.photoMissingParts,
      data.photoDamage,
      data.photo_AdditionalPhoto,
      data.photoMissingStickers,
    ].map((url) => {
      if (!url) {
        return Promise.resolve({ sasUrl: '' });
      }
      return this.photoUploadService.getPhotoUrl(url);
    });

    return forkJoin(sources).pipe(
      map(
        ([
          frontClose,
          screen,
          networkDecals,
          longDistance,
          seriaNumber,
          feeNotice,
          missingParts,
          damage,
          additionalPhoto,
          missingStickers,
        ]) => ({
          'itm-front-close': frontClose.sasUrl,
          'itm-screen': screen.sasUrl,
          'itm-network-decals': networkDecals.sasUrl,
          'itm-long-distance': longDistance.sasUrl,
          'itm-serial-number': seriaNumber.sasUrl,
          'itm-fee-notice': feeNotice.sasUrl,
          'itm-missing-parts': missingParts.sasUrl,
          'itm-damage': damage.sasUrl,
          'itm-additional-photo': additionalPhoto.sasUrl,
          'itm-missing-stickers': missingStickers.sasUrl,
        })
      ),
      catchError(() =>
        of({
          'itm-front-close': '',
          'itm-screen': '',
          'itm-network-decals': '',
          'itm-long-distance': '',
          'itm-serial-number': '',
          'itm-fee-notice': '',
          'itm-missing-parts': '',
          'itm-damage': '',
          'itm-additional-photo': '',
          'itm-missing-stickers': '',
        })
      )
    );
  };

  public saveForm(request: ItmCleaningRequest): Observable<string> {
    const saveForm = itmCleaningMapRequest(request);
    return this.service.sendRequest<string>({
      method: 'post',
      url: 'pmforms/itmcleaning',
      body: saveForm,
      urlPrefix: 'ccc',
    });
  }

  getPhotos(data: ItmCleaningResponse, updateFormPhotos: (photos: Record<ItmCleaningPhotoUploadKey, string>) => void) {
    this.loadPhotos(data.itmCleaning).subscribe((photos) => {
      updateFormPhotos(photos);
    });
  }
}
