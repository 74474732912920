export interface UpdateStatusCommand {
  workOrder?: string;
  status?: number;
  dateEnter?: string;
  timeEnter?: string;
  edorDate?: string;
  edorTime?: string;
  userId?: string;
  functionalityRestored?: string;
  techIdMaster?: string;
  utcOffset?: number;
  noteSubject?: string;
  noteText?: string;
}

export enum DocuRestriction {
  Internal = 0,
  External = 1,
}

export class NoteTableName {
  static readonly TableName = 'SMAServiceOrderTable';
}

export interface AdsNoteRequestModel {
  createdBy?: string;
  description?: string;
  docuRestriction?: number;
  notes?: string;
  recId?: number;
  refRecId?: number;
  refTableId?: number;
  typeId?: string;
  tableName?: string;
}

export class CallStatusId {
  static readonly Acknowledged_0 = 0;
  static readonly Opened_1 = 1;
  static readonly Assigned_2 = 2;
  static readonly Reassigned_3 = 3;
  static readonly Rescheduled_4 = 4;
  static readonly Notified_10 = 10;
  static readonly Scheduled_20 = 20;
  static readonly NoAnswer_30 = 30;
  static readonly Travelling_40 = 40;
  static readonly Started_50 = 50;
  static readonly Suspended_60 = 60;
  static readonly Completed_70 = 70;
  static readonly Billed_80 = 80;
  static readonly PartsRun_102 = 102;
}

