import { Injectable } from '@angular/core';
import { AdsCallControl, SelectOption } from 'models';
import { FilterOption, newDateFilter } from 'models/shared/filter-option';
import { newSelectOption } from 'models/shared/select-option';
import { newSortingOption, SortingOption } from 'models/shared/sorting-option';
import { CustomPromptComponent } from 'shared/custom-prompt/custom-prompt.component';

@Injectable({
  providedIn: 'root',
})
export class ServiceCallsService {
  sortOptions: SortingOption<string>[] = [
    newSortingOption('- None -', 'none', '', ''),
    newSortingOption('Workorder', 'workorder', 'Lowest First', 'Highest First'),
    newSortingOption('Priority', 'priority', 'Highest First', 'Lowest First'),
    newSortingOption('Scheduled', 'dateopened', 'Oldest First', 'Recent First'),
    newSortingOption('On-site by', 'onSiteBy ', 'Oldest First', 'Recent First'),
    newSortingOption('Customer', 'customer ', 'A-Z', 'Z-A'),
    newSortingOption('Address', 'address', 'State: A-Z', 'State: Z-A'),
  ];

  scheduledDateFilter = newDateFilter('dateopened', 'Scheduled');
  onSiteByDateFilter = newDateFilter('onSiteBy', 'On-site by');

  statusFilter: FilterOption<SelectOption<string>> = {
    value: 'none',
    name: 'Status',
    isList: true,
    options: [
      newSelectOption('- None -', 'none'),
      newSelectOption('Opened', 'Opened'),
      newSelectOption('Notified', 'Notified'),
      newSelectOption('Started', 'Started'),
      newSelectOption('Scheduled', 'Scheduled'),
      newSelectOption('Travelling', 'Travelling'),
      newSelectOption('Suspended', 'Susp-Other'),
      newSelectOption('Acknowledged', 'Acknowledged'),
    ],
  };

  priorityFilter: FilterOption<SelectOption<string>> = {
    value: 'none',
    name: 'Priority',
    isList: true,
    options: [],
  };

  sorting: Sorting = {
    sortAsc: false,
    sortSaved: false,
    tempSortCriteria: '',
    tempSortingLabel: '',
    activeSortCriteria: 'none',
    activeSortingLabel: '- None -',
  };

  mobilePrompt: CustomPromptComponent | null = null;
  searchText = '';

  constructor() {}

  updatePriorityFilter(calls: AdsCallControl[]) {
    const priorities = calls.map((call) => call.priority).filter((call) => call !== undefined);
    const priorityOptions = [...new Set(priorities)];
    this.priorityFilter.options = [{
      name: '- None -',
      value: 'none',
    }, ...priorityOptions.map(
      (priority) =>
        ({
          name: priority,
          value: priority,
        } as SelectOption<string>)
    )];
  }

  onConfirmSort() {
    this.sorting.activeSortCriteria = this.sorting.tempSortCriteria;
    this.sorting.activeSortingLabel = this.sorting.tempSortingLabel;
    this.mobilePrompt?.close();
    this.sorting.sortSaved = true;
  }

  sortClicked(v: string | string[]) {
    const column = v as string;
    let newSortingLabel = '';
    let asc = true;
    switch (column) {
      case 'priority':
        newSortingLabel = 'Highest First';
        break;
      case 'workorder':
        newSortingLabel = 'Lowest First';
        break;
      case 'dateopened':
      case 'onSiteBy':
        newSortingLabel = 'Recent First';
        asc = false;
        break;
      case 'address':
        newSortingLabel = 'State: A-Z';
        break;
      default:
        newSortingLabel = 'A-Z';
    }
    this.setSort(column, asc, newSortingLabel);
  }

  setSort(column: string, asc: boolean, sortingLabel: string) {
    this.sorting.tempSortCriteria = column;
    this.sorting.sortAsc = asc;
    this.sorting.tempSortingLabel = sortingLabel;
  }

  onSortOpened() {
    this.setSort(this.sorting.activeSortCriteria, false, this.sorting.activeSortingLabel);
  }

  onSortDismissed() {
    if (this.sorting.sortSaved) {
      this.sorting.tempSortCriteria = this.sorting.activeSortCriteria;
      this.sorting.tempSortingLabel = this.sorting.activeSortingLabel;
    }
    this.sorting.sortSaved = false;
  }

  clearFilters() {
    this.statusFilter.value = 'none';
    this.scheduledDateFilter.minValue = undefined;
    this.scheduledDateFilter.maxValue = undefined;
    this.onSiteByDateFilter.minValue = undefined;
    this.onSiteByDateFilter.maxValue = undefined;
    this.scheduledDateFilter.value = '';
    this.onSiteByDateFilter.value = '';
    this.searchText = '';
    this.priorityFilter.value = 'none';
  }
}

export interface Sorting {
  sortAsc: boolean;
  sortSaved: boolean;
  tempSortCriteria: string;
  tempSortingLabel: string;
  activeSortCriteria: string;
  activeSortingLabel: string;
}
