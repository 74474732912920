import { Component, Input } from '@angular/core';

@Component({
  selector: 'ads-badge-text',
  standalone: true,
  imports: [],
  templateUrl: './badge-text.component.html',
  styleUrl: './badge-text.component.scss',
})
export class BadgeTextComponent {
  @Input() text = '';
  @Input() value = '';
}
