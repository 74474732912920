<ads-simple-form #itmFasciaAndAdaTasks="ngModelGroup" ngModelGroup="itmFasciaAndAdaTasks">
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Clean customer interface area"
    name="cleanCustomerInterfaceArea"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="yesNoOptions"
    label="Are ADA stickers present and readable?"
    name="areAdaStickersPresentAndReadable"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Check tone volume and adjust (if necessary)"
    name="checkToneVolumeAndAdjust"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Test voice guidance (confrim that it is on in software)"
    name="testVoiceGuidance"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Test all keypad and function keys"
    name="testAllKeypadAndFunctionKeys"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Check alignment with card reader"
    name="checkAlignWithCardReader"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Check alignment and operation of dispenser shutter"
    name="checkAlignAndOperationOfDispensorShutter"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Check alignment with receipt printer"
    name="checkAlignWithReceiptPrinter"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Clean operator panel"
    name="cleanOperatorPanel"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Clean entire exterior of stand alone ATMs"
    name="cleanEntireExteriorOfStandaloneAtms"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Verify touch screen calibration"
    name="verifyTouchScreenCalibration"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Test open/close indicator"
    name="testOpenCloseIndicator"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Replace fascia light bulbs if necessary"
    name="replaceLightBulbs"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="yesNoOptions"
    label="Top hat light operational"
    name="topHatLightOperational"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Wipe down all ITM devices"
    name="wipeDownAllAtmDevices"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Verify anti-skimming module is in device configuration mode"
    name="antiSkimmingInDeviceConfigMode"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
