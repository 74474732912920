export class CcCdSection {
  manufacturer: string | null;
  model: string | null;
  serial: string | null;
  type: string | null;
  quantityOfPockets: string | null;
  quantityOfPrinters: string | null;
  manufacturerOfPrinter: string | null;
  externalEquipmentAttached: string | null;
  overallCondition: string | null;
  equipmentRating: string | null;
  photoFront: string | null;
  photoSerialNumber: string | null;
  photoPrinter: string | null;
  photoAdditionalPhoto: string | null;
  photoExternalDevices: string | null;
  index: number;

  constructor(index: number) {
    this.manufacturer = '';
    this.model = '';
    this.serial = '';
    this.type = '';
    this.quantityOfPockets = '';
    this.quantityOfPrinters = '';
    this.manufacturerOfPrinter = '';
    this.externalEquipmentAttached = '';
    this.overallCondition = '';
    this.equipmentRating = '';
    this.index = index;
    this.photoFront = '';
    this.photoSerialNumber = '';
    this.photoPrinter = '';
    this.photoAdditionalPhoto = '';
    this.photoExternalDevices = '';
  }
}
