import { Component, OnDestroy, OnInit, effect, inject, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from 'environment/environment';
import { ThemingService, AuthService, LoggingService } from 'services';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { LoaderService } from 'shared/loader/loader.service';
import PullToRefresh from 'pulltorefreshjs';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ads-pwa-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, MatProgressSpinnerModule, CommonModule, MatIconModule],
})
export class AppComponent implements OnInit, OnDestroy {
  env = environment.name;
  themeSelectorOpen = signal(false);
  componentSelectorOpen = signal(true);
  themingService = inject(ThemingService);
  setTheme = effect(() => {
    const themeProperties = this.themingService.getThemeProperties();
    for (const property in themeProperties) {
      document.body.style.setProperty(property, themeProperties[property]);
    }
  });

  constructor(
    public readonly authService: AuthService,
    public readonly loggingService: LoggingService,
    public loader: LoaderService,
  ) {}

  ngOnInit(): void {
    this.authService.init();
    this.authService.checkUserValidity();
    const isInWebAppiOS =
      window.matchMedia('(display-mode: standalone)').matches && /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    if (isInWebAppiOS) {
      PullToRefresh.init({
        mainElement: 'body',
        onRefresh() {
          window.location.reload();
        },
        distMax: 120,
        distReload: 120,
        instructionsPullToRefresh: ' ',
        instructionsRefreshing: ' ',
        iconRefreshing: '<mat-icon class="material-icons-outlined spin">sync</mat-icon>',
      });
    }
  }

  ngOnDestroy(): void {
    this.authService.onDestroy();
  }

  componentWillUnmount() {
    // Don't forget to destroy all instances on unmout
    // or you will get some glitches.
    PullToRefresh.destroyAll();
  }
}
