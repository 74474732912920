import { Component, Input, Optional, ViewChild } from '@angular/core';
import { NgModel, NgModelGroup, FormControl } from '@angular/forms';

@Component({
  template: '',
})
export abstract class BaseFormInputComponent {
  @Input() name = '';
  @Input() label = '';
  @Input() maxLength = 100;
  @Input() required = false;
  @Input() type: 'string' | 'number' | 'boolean' = 'string';
  @Input() value: string | number | undefined | null = '';
  @Input() minValue = 0;
  @Input() formGroupName = '';
  @Input() tooltip = '';
  @Input() inputMode: 'text' | 'tel' = 'text';
  @Input() hideHints = false;
  @ViewChild('customInput') customInput?: NgModel;

  constructor(@Optional() protected readonly ngModelGroup: NgModelGroup) {}

  getInvalid = () => {
    return (
      this.ngModelGroup?.control?.controls[this.name].status === 'INVALID' &&
      this.ngModelGroup?.control?.controls[this.name].touched
    );
  };

  getDisabled = () => this.disabled || this.ngModelGroup?.disabled;

  disabled = false;
  formControl = new FormControl('', []);

  onChange = () => {};
  onTouched = () => {};

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    this.ngModelGroup?.control?.controls[this.name].markAllAsTouched();
    if (!this.customInput?.touched) {
      this.onTouched();
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  propagateChange = (value: string) => {
    this.writeValue(value);
  };

  writeValue(newValue: string): void {
    this.value = newValue;
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  onModelChange(value: string) {
    this.propagateChange(value);
  }

  getStringValue() {
    return this.value as string | undefined;
  }

  showStringValueLength() {
    return !this.getDisabled();
  }
}
