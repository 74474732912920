<ads-simple-form #fasciaAdaTasks="ngModelGroup" ngModelGroup="fasciaAdaTasks">
  <ads-radio-button-group
    [options]="options"
    label="Clean customer interface area"
    name="cleanCustomerInterfaceArea"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="yesNoOptions"
    label="Are ADA stickers present and readable?"
    name="adaStickersPresentReadable"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Check tone volume and adjust (if necessary)"
    name="cleanToneVolume"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Test voice guidance (confirm that it is on in software)"
    name="testVoiceGuidance"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Test all keypad and function keys"
    name="testKeypadFunctionKeys"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Check alignment with card reader"
    name="checkAlignCardReader"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Check alignment and operation of dispenser shutter"
    name="checkAlignOperDispShutter"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Check alignment with receipt printer"
    name="checkAlignReceiptPrinter"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Clean operator panel"
    name="cleanOperatorPanel"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Clean entire exterior of stand alone ATMs"
    name="cleanEntireExterior"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Verify touch screen calibration"
    name="verifyTouchScreen"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Test open/close indicator"
    name="testOpenCloseIndicator"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Replace fascia light bulbs if necessary"
    name="replaceLightBulbs"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="yesNoOptions"
    label="Top hat light operational"
    name="topHatLightOperational"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Verify anti-skimming module is in device configuration mode"
    name="antiSkimmingInDeviceConfigMode"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
</ads-simple-form>
