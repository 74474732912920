import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PhotoUploadBase } from 'models';

@Injectable()
export class PhotoUploadControlService {
  toFormGroup(photos: PhotoUploadBase[]) {
    const group: Record<string, FormControl<string | null>> = {};
    photos.forEach((photo) => {
      group[photo.key] = photo.required
        ? new FormControl(photo.value ?? '', Validators.required)
        : new FormControl(photo.value ?? '');
    });
    return new FormGroup(group);
  }
}
