import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdsEquipmentOnSite, AdsTicketNotes } from 'models';
import { CustomHttpUrlEncodingCodec } from 'pages/home/home.service';
import { Observable } from 'rxjs';
import { ApiService, AuthService } from 'services';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderService {
  constructor(private readonly service: ApiService, private readonly authService: AuthService) {}

  /**
   * Gets a call list according with the parameters used into the request
   *
   * @param techIdMaster technician id
   * @param isMyOpenCalls flag to indicate when the result will be filtered by open  status call
   */
  public getEquipmentOnSite(branchAccount?: string): Observable<AdsEquipmentOnSite[]> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (branchAccount !== undefined && branchAccount !== null) {
      queryParameters = queryParameters.set('branchAccount', branchAccount);
    }

    return this.service.sendRequest<AdsEquipmentOnSite[]>({
      method: 'get',
      url: `equipment/onsiteequipmentsbybranchaccount`,
      params: queryParameters,
    });
  }

  /**
   * Gets a URL according to the parameters in the request.
   *
   * @param workOrder Work order
   */
  public getEquipmentOnSiteUrl(workOrder?: string): Observable<string> {
    if (!this.authService.user?.personnelNumber || !workOrder) {
      throw new Error('User not authenticated');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() })
      .set('workOrder', workOrder)
      .set('type', 'EQ')
      .set('techIdMaster', this.authService.user?.personnelNumber);

    return this.service.sendRequest<string>({
      method: 'get',
      url: 'layout/url',
      params: queryParameters,
    });
  }

  public getTicketNotes(ticketNumber?: string): Observable<AdsTicketNotes> {
    if (!ticketNumber) {
      throw new Error('Ticket number is required');
    }

    return this.service.sendRequest<AdsTicketNotes>({
      method: 'get',
      url: `support/tickets/${ticketNumber}/notes`,
      urlPrefix: 'ccc',
    });
  }
}
