import { Component, Input, AfterViewInit, ChangeDetectorRef, inject } from '@angular/core';
import {
  SurveillanceCameraTableForm,
  SurveillanceDVRNVRForm,
  SurveillanceMonitorTableForm,
  SurveillancePOESwitchForm,
  SurveillanceUPSForm,
} from 'models/security/surveillance/surveillanceSecurityForm';
import { AbstractControl, ControlContainer, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NestedMultipleFormsSectionComponent } from 'forms/shared/nested-multiple-forms-section/nested-multiple-forms-section.component';
import { ExpansionPanelComponent, SelectComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { SecuritySurveillanceDropdowns } from 'models/security/security-dropdowns';
import { SecurityService } from 'pages/security/security.service';
import { getFormGroup } from 'utils';
import { SurveillanceDvrNvrSectionComponent } from './surveillance-dvr-nvr-section/surveillance-dvr-nvr-section.component';
import { SurveillanceUpsSectionComponent } from './surveillance-ups-section/surveillance-ups-section.component';
import { SurveillancePoeSwitchSectionComponent } from './surveillance-poe-switch-section/surveillance-poe-switch-section.component';
import { SurveillanceCameraTableSectionComponent } from './surveillance-camera-table-section/surveillance-camera-table-section.component';
import { BadgeTextComponent } from 'shared/badge-text/badge-text.component';
import { SurveillanceMonitorTableSectionComponent } from './surveillance-monitor-table-section/surveillance-monitor-table-section.component';
import { SurveillanceDvrTasksSectionComponent } from './surveillance-dvr-tasks-section/surveillance-dvr-tasks-section.component';
import { SurveillanceCameraTasksSectionComponent } from './surveillance-camera-tasks-section/surveillance-camera-tasks-section.component';
import { SurveillanceMonitorTasksSectionComponent } from './surveillance-monitor-tasks-section/surveillance-monitor-tasks-section.component';
import { IndexableFormType, Section } from 'app/utils/miscTypes';
import { MatInputModule } from '@angular/material/input';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { getSectionStatusFormGroup } from 'app/utils/form-status';
import { NestedTableFormsSectionComponent } from 'forms/shared/nested-table-forms-section/nested-table-forms-section.component';
import { MatDialog } from '@angular/material/dialog';
import {
  SurveillanceCameraTableAddManyModalComponent,
  SurveillanceCameraTableSectionAddMany,
} from './surveillance-camera-table-add-many-modal/surveillance-camera-table-add-many-modal.component';
import {
  SurveillanceUpsTableAddManyModalComponent,
  SurveillanceUPSTableSectionAddMany,
} from './surveillance-ups-table-add-many-modal/surveillance-ups-table-add-many-modal.component';
import {
  SurveillanceMonitorsTableAddManyModalComponent,
  SurveillanceMonitorTableSectionAddMany,
} from './surveillance-monitors-table-add-many-modal/surveillance-monitors-table-add-many-modal.component';
import {
  SurveillancePoeSwitchTableAddManyModalComponent,
  SurveillancePOETableSectionAddMany,
} from './surveillance-poe-switch-table-add-many-modal/surveillance-poe-switch-table-add-many-modal.component';

export interface SurveillanceDVRNVRSection extends IndexableFormType, SurveillanceDVRNVRForm {}
export interface SurveillanceUPSSection extends IndexableFormType, SurveillanceUPSForm {}
export interface SurveillancePOESwitchSection extends IndexableFormType, SurveillancePOESwitchForm {}
export interface SurveillanceCameraTableSection extends IndexableFormType, SurveillanceCameraTableForm {}
export interface SurveillanceMonitorTableSection extends IndexableFormType, SurveillanceMonitorTableForm {}

@Component({
  selector: 'ads-surveillance',
  standalone: true,
  imports: [
    CommonModule,
    SimpleFormComponent,
    FormsModule,
    MatInputModule,
    ExpansionPanelComponent,
    NestedMultipleFormsSectionComponent,
    BadgeTextComponent,
    SelectComponent,
    SurveillanceDvrNvrSectionComponent,
    SurveillanceUpsSectionComponent,
    SurveillancePoeSwitchSectionComponent,
    SurveillanceCameraTableSectionComponent,
    SurveillanceMonitorTableSectionComponent,
    SurveillanceDvrTasksSectionComponent,
    SurveillanceCameraTasksSectionComponent,
    SurveillanceMonitorTasksSectionComponent,
    CommonTextAreaComponent,
    NestedTableFormsSectionComponent,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './surveillance.component.html',
  styleUrl: './surveillance.component.scss',
})
export class SurveillanceComponent implements AfterViewInit {
  @Input() formGroup?: FormGroup | null;
  @Input() submitted = false;
  @Input() surveillanceDropdowns: SecuritySurveillanceDropdowns = {
    surveillanceDvrDvrManufacturers: [],
    surveillanceDVRNVROverallConditions: [],
    surveillanceUpsManufacturers: [],
    surveillancePoeSwitchManufacturers: [],
    surveillanceCameraTableCameraType: [],
    surveillanceCameraTableIsPtz: [],
    surveillanceCameratableOverallConditionCameras: [],
    surveillanceMonitorTableMonitorType: [],
    surveillanceMonitorTableOverallConditionMonitors: [],
  };

  readonly dialog = inject(MatDialog);

  expandedList: number[] = [];

  constructor(public readonly security: SecurityService, private readonly cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.formGroup?.controls['surveillanceDvrTasks'].setValue({
        allNa: false,
        checkTimeDate: this.security.surveillanceData.surveillanceDvrTasks.checkTimeDate,
        verifiedAllCamerasShowLiveVideo:
          this.security.surveillanceData.surveillanceDvrTasks.verifiedAllCamerasShowLiveVideo,
        verifiedAllCamerasShowRecordedVideo:
          this.security.surveillanceData.surveillanceDvrTasks.verifiedAllCamerasShowRecordedVideo,
        cleanedDVRCase: this.security.surveillanceData.surveillanceDvrTasks.cleanedDVRCase,
        inspectConnectors: this.security.surveillanceData.surveillanceDvrTasks.inspectConnectors,
        inspectInternalFanOperation: this.security.surveillanceData.surveillanceDvrTasks.inspectInternalFanOperation,
        cleanedInsideEnclosure: this.security.surveillanceData.surveillanceDvrTasks.cleanedInsideEnclosure,
        verifyXterms: this.security.surveillanceData.surveillanceDvrTasks.verifyXterms,
        inspectAllWireConnectionsInPowerSupply:
          this.security.surveillanceData.surveillanceDvrTasks.inspectAllWireConnectionsInPowerSupply,
        inspectAndCleanPOESwitch: this.security.surveillanceData.surveillanceDvrTasks.inspectAndCleanPOESwitch,
      });

      this.formGroup?.controls['surveillanceCameraTasks'].setValue({
        allNa: false,
        cleanCamerasAndHousings: this.security.surveillanceData.surveillanceCameraTasks.cleanCamerasAndHousings,
        inspectCameraPositionFocus: this.security.surveillanceData.surveillanceCameraTasks.inspectCameraPositionFocus,
        inspectCameraWireConnection: this.security.surveillanceData.surveillanceCameraTasks.inspectCameraWireConnection,
      });

      this.formGroup?.controls['surveillanceMonitorTasks'].setValue({
        allNa: false,
        cleanMonitor: this.security.surveillanceData.surveillanceMonitorTasks.cleanMonitor,
        inspectMonitorOperation: this.security.surveillanceData.surveillanceMonitorTasks.inspectMonitorOperation,
      });

      this.formGroup?.controls['comments'].setValue(this.security.surveillanceData.comments);

      if (this.submitted) {
        this.formGroup?.disable();
      }
    });
  }

  getItemSection(sectionName: string, containerName: string) {
    return getFormGroup(this.formGroup?.controls[containerName])?.controls[sectionName] as FormGroup;
  }

  getTasksSection(containerName: string) {
    return this.formGroup?.controls[containerName] as FormGroup;
  }

  getFormControls(containerName: string): Record<string, AbstractControl<string, string>> {
    const fg = this.formGroup?.get(containerName) as FormGroup;
    return fg ? fg.controls : {};
  }

  onAddClick(
    sectionType:
      | 'dvrNvrSections'
      | 'upsSections'
      | 'poeSwitchSections'
      | 'cameraTableSections'
      | 'monitorTableSections',
  ) {
    const sections = this.security.surveillanceData[sectionType];

    const maxIndex = sections.length === 0 ? 1 : Math.max(...sections.map((item) => item.index)) + 1;

    const newEmptySection: Section = { index: maxIndex };

    switch (sectionType) {
      case 'dvrNvrSections':
        newEmptySection['dvrModel'] = '';
        break;
      case 'upsSections':
        newEmptySection['upsModel'] = '';
        break;
      case 'poeSwitchSections':
        newEmptySection['poeSwitchModel'] = '';
        break;
      case 'cameraTableSections':
        newEmptySection['camera'] = '';
        break;
    }

    (this.security.surveillanceData[sectionType] as unknown) = [...sections, newEmptySection];

    //https://stackoverflow.com/a/56891326/4789720
    this.cdr.detectChanges();
  }

  onAddManyCamerasClick = () => {
    const dialog = this.dialog.open(SurveillanceCameraTableAddManyModalComponent, {
      data: { dropdowns: this.surveillanceDropdowns },
    });

    dialog.afterClosed().subscribe((result: SurveillanceCameraTableSectionAddMany) => {
      if (result) {
        const sections = this.security.surveillanceData['cameraTableSections'];
        const maxIndex = sections.length === 0 ? 1 : Math.max(...sections.map((item) => item.index)) + 1;

        const newSections = Array(parseInt(result?.quantity ?? '0', 10))
          .fill('')
          .map((_, index) => ({
            camera: '',
            cameraType: result.cameraType,
            isPTZ: result.isPTZ,
            index: maxIndex + index,
          }));

        this.security.surveillanceData['cameraTableSections'] = [...sections, ...newSections];
        this.cdr.detectChanges();
      }
    });
  };

  onAddManyUpssClick = () => {
    const dialog = this.dialog.open(SurveillanceUpsTableAddManyModalComponent, {
      data: { dropdowns: this.surveillanceDropdowns },
    });

    dialog.afterClosed().subscribe((result: SurveillanceUPSTableSectionAddMany) => {
      if (result) {
        const sections = this.security.surveillanceData['upsSections'] ?? [];
        const maxIndex = sections.length === 0 ? 1 : Math.max(...sections.map((item) => item.index)) + 1;

        const newSections = Array(parseInt(result?.quantity ?? '0', 10))
          .fill('')
          .map((_, index) => ({
            upsManufacturer: result.upsManufacturer,
            upsModel: '',
            numberOfOutputs: '',
            upsManufacturer_Other: '',
            index: maxIndex + index,
          }));

        this.security.surveillanceData['upsSections'] = [...sections, ...newSections];
        this.cdr.detectChanges();
      }
    });
  };

  onAddManyMonitorsClick = () => {
    const dialog = this.dialog.open(SurveillanceMonitorsTableAddManyModalComponent, {
      data: { dropdowns: this.surveillanceDropdowns },
    });

    dialog.afterClosed().subscribe((result: SurveillanceMonitorTableSectionAddMany) => {
      if (result) {
        const sections = this.security.surveillanceData['monitorTableSections'];
        const maxIndex = sections.length === 0 ? 1 : Math.max(...sections.map((item) => item.index)) + 1;

        const newSections = Array(parseInt(result?.quantity ?? '0', 10))
          .fill('')
          .map((_, index) => ({
            monitor: '',
            monitorType: result.monitorType,
            index: maxIndex + index,
          }));

        this.security.surveillanceData['monitorTableSections'] = [...sections, ...newSections];
        this.cdr.detectChanges();
      }
    });
  };

  onAddManyPOEsClick = () => {
    const dialog = this.dialog.open(SurveillancePoeSwitchTableAddManyModalComponent, {
      data: { dropdowns: this.surveillanceDropdowns },
    });

    dialog.afterClosed().subscribe((result: SurveillancePOETableSectionAddMany) => {
      if (result) {
        const sections = this.security.surveillanceData['poeSwitchSections'];
        const maxIndex = sections.length === 0 ? 1 : Math.max(...sections.map((item) => item.index)) + 1;

        const newSections = Array(parseInt(result?.quantity ?? '0', 10))
          .fill('')
          .map((_, index) => ({
            poeSwitchManufacturer: result.poeSwitchManufacturer,
            poeSwitchManufacturer_Other: '',
            poeSwitchModel: '',
            index: maxIndex + index,
          }));

        this.security.surveillanceData['poeSwitchSections'] = [...sections, ...newSections];

        this.cdr.detectChanges();
      }
    });
  };

  addCameraTableSection = () => {
    this.onAddClick('cameraTableSections');
    setTimeout(() => {
      document.getElementById('cameraTableItem#' + this.security.surveillanceData.cameraTableSections.length)?.focus();
    }, 100);
  };

  addUPSTableSection = () => {
    this.onAddClick('upsSections');
    setTimeout(() => {
      document.getElementById('upsItem#' + this.security.surveillanceData.upsSections.length)?.focus();
    }, 100);
  };

  addPOETableSection = () => {
    this.onAddClick('poeSwitchSections');
    setTimeout(() => {
      document.getElementById('poeSwitchItem#' + this.security.surveillanceData.poeSwitchSections.length)?.focus();
    }, 100);
  };

  addMonitorTableSection = () => {
    this.onAddClick('monitorTableSections');
    setTimeout(() => {
      document
        .getElementById('monitorTableItem#' + this.security.surveillanceData.monitorTableSections.length)
        ?.focus();
    }, 100);
  };

  onRemoveSection(
    item:
      | SurveillanceDVRNVRSection
      | SurveillanceUPSSection
      | SurveillancePOESwitchSection
      | SurveillanceCameraTableSection
      | SurveillanceMonitorTableSection,
    sectionName:
      | 'dvrNvrSections'
      | 'upsSections'
      | 'poeSwitchSections'
      | 'cameraTableSections'
      | 'monitorTableSections',
  ) {
    const indexAt = this.security.surveillanceData[sectionName].indexOf(item as never);
    this.security.surveillanceData[sectionName].splice(indexAt, 1);
    this.security.surveillanceData[sectionName] = this.security.surveillanceData[sectionName].map((x, index) => ({
      ...x,
      index: index + 1,
    })) as never;
  }

  getSectionStatus = getSectionStatusFormGroup(this.formGroup);

  getAnalogIpCameras() {
    return [
      this.security.surveillanceData.cameraTableSections.filter((x) => x.cameraType === 'Analog').length,
      this.security.surveillanceData.cameraTableSections.filter((x) => x.cameraType === 'IP').length,
    ];
  }

  getMonitorCount() {
    return this.security.surveillanceData.monitorTableSections.length;
  }

  displayOtherCondition = (condition: 'overallConditionCameras' | 'overallConditionMonitors') => {
    const val = this.security.surveillanceData[condition];
    return Boolean(val && (val === 'Poor Condition' || val === 'Failed'));
  };
}
