export class CcCdVacuumingBlowOutTasks {
  allNa: boolean;
  mainBody: number | null;
  allFans: number | null;
  powerSupply: number | null;

  constructor() {
    this.allNa = false;
    this.mainBody = null;
    this.allFans = null;
    this.powerSupply = null;
  }
}
