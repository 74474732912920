import { AuthService } from 'services';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { Component, inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { CallControlService } from 'pages/call-control/call-control.service';
import { DatePickerComponent } from 'shared/material-wrappers/datepicker/datepicker.component';
import { TimepickerComponent } from 'shared/material-wrappers/timepicker/timepicker.component';
import { ModalActionsComponent } from 'shared/modal-actions/modal-actions.component';
import { NotesService } from 'services/notes.service';
import { CallStatusId } from 'models/call-control/ack-schedule-models';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { OperationResponse } from 'models/call-control/command-response';
import { AskTravelBackAndLunchComponent } from '../ask-travel-back-and-lunch/ask-travel-back-and-lunch.component';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { SelectOption } from 'models';
import { environment } from 'environment/environment';
import { SelectComponent } from '../../../../shared/material-wrappers/select/select.component';
import { buildNote, newSlaNoteText } from 'app/utils/notes';
import { CompleteWoNoteModel } from 'models/notes/complete-wo-note-model';
import { AdsNoteRequestModel } from 'models/notes/notes-request';
import { CallControlDateModel } from 'models/call-control/call-control-date-model';

@Component({
  selector: 'ads-suspend',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatTabsModule,
    DatePickerComponent,
    TimepickerComponent,
    ModalActionsComponent,
    MatCheckboxModule,
    CommonTextAreaComponent,
    SelectComponent,
  ],
  templateUrl: './suspend.component.html',
  styleUrl: './suspend.component.scss',
})
export class SuspendComponent implements OnInit {
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);
  startedDate: Date | null = null;
  returnDate = new Date();
  validSuspendTime = true;
  validReturnTime = true;
  functionalityRestored = false;
  loaded = false;
  techNotes = '';
  lastNote: CompleteWoNoteModel | null = null;
  onSiteBy: Date | null = null;
  isWf = false;
  priority = '';
  missingSLAReason = '';
  missingSLAreasons: SelectOption<string>[] = [];

  public readonly _snackBar = inject(MatSnackBar);
  public readonly _bottomSheet = inject(MatBottomSheet);
  public readonly dialogRef = inject(MatDialog);
  datepipe: DatePipe = new DatePipe('en-US');

  constructor(
    public readonly callControlService: CallControlService,
    public readonly authService: AuthService,
    public readonly workOrderService: WorkOrderService,
    public readonly notesService: NotesService
  ) {}

  ngOnInit(): void {
    this.missingSLAreasons = this.workOrderService.missingSLAreasons;
    if (this.authService.authenticated) {
      this.workOrderService.getLastTechNote(this.workOrderService.workOrder).subscribe(this.loadTechNotes);
      this.workOrderService.getStatusTimestamp({ workOrder: this.workOrderService.workOrder }).subscribe(this.loadSuspendDate);
    }
    this.loadSuspendDataFromWorkOrder();
  }

  loadSuspendDate = (startedDate: CallControlDateModel) => {
    if (startedDate.Date) {
      this.startedDate = new Date(startedDate.Date);
      this.returnDate = new Date(startedDate.Date);
      this.loaded = true;
    }
  }

  loadTechNotes = (note: CompleteWoNoteModel[]) => {
    this.techNotes = note[0].note ?? '';
    this.lastNote = note[0];
  }

  loadSuspendDataFromWorkOrder() {
    const date = this.workOrderService.workOrderDetails().onSiteBy;
    this.onSiteBy = date ? new Date(date) : null;
    this.priority = this.workOrderService.workOrderDetails().priority ?? '';
    this.isWf = this.workOrderService.workOrderDetails().masterAccount === environment.wfAccountNum;
  }

  onConfirm() {
    if (this.authService.authenticated && this.validReturnTime && this.validSuspendTime) {
      if (this.showMissingSLA() && !this.missingSLAReason) {
        this._snackBar.open('Please select a reason for missing SLA.', 'Close');
        return;
      };
      this.callControlService
        .updateCallStatus({
          dateEnter: this.datepipe.transform(this.startedDate, 'MM/dd/yyyy')?.toString(),
          timeEnter: this.datepipe.transform(this.startedDate, 'HH:mm:ss')?.toString(),
          edorDate: this.datepipe.transform(this.returnDate, 'MM/dd/yyyy')?.toString(),
          edorTime: this.datepipe.transform(this.returnDate, 'HH:mm:ss')?.toString(),
          workOrder: this.workOrderService.workOrderDetails().workorder,
          techIdMaster: this.authService.user?.personnelNumber,
          utcOffset: new Date().getTimezoneOffset() / -60,
          userId: this.authService.user?.userId,
          status: CallStatusId.Suspended_60,
        })
        .subscribe({
          next: this.onSuspendSuccess,
          error: this.onTicketSubmitError,
        });
    }
  }

  hideAll() {
    this._bottomSheet.dismiss();
    this.dialogRef.closeAll();
  }

  onSuspendSuccess = (result: OperationResponse) => {
    this.hideAll();
    if (result.isSuccess) {
      const refRecId = Number(this.workOrderService.workOrderDetails().ticketRecId);
      const userId = this.authService.user?.userId;
      this.notesService.addNotesForSuspendOrComplete(this.lastNote, this.techNotes, userId?.toString() ?? '', refRecId);
      if (this.showMissingSLA()) {
        const workOrder = this.workOrderService.workOrderDetails();
        const noteText = newSlaNoteText(
          new Date(workOrder.onSiteBy ?? new Date()),
          this.returnDate ?? new Date(),
          this.missingSLAReason,
          ''
        );
        this.sendMissingSLANote(this.createNote(
          'Missed SLA',
          noteText,
          workOrder.recid,
          Number(this.workOrderService.workOrderDetails().ticketRecId),
          this.authService.user?.userId
        ));
      }
    } else {
      this.onTicketSubmitError(result.errorMessage);
    }
  };

  createNote = buildNote;

  sendMissingSLANote = (noteModel: AdsNoteRequestModel[]) => {
    this.notesService.addNote(noteModel).subscribe({
      next: () => {
        this.workOrderService.showModal(AskTravelBackAndLunchComponent);
        this._snackBar.open('Work Order suspended successfully.', 'Close');
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  onTicketSubmitError = (error: unknown) => {
    console.error(error);
    this._snackBar.open('Failed to suspend your Work Order, please try again later.', 'Close');
  };

  onSuspendTimeChange(time: string) {
    this.validSuspendTime = false;
    if (time) {
      this.validSuspendTime = true;
      const hours = parseInt(time.split(' ')[0], 10);
      const isPm = time.split(' ')[2] === 'PM';
      this.startedDate?.setHours(isPm ? hours + 12 : hours);
      this.startedDate?.setMinutes(parseInt(time.split(' ')[1], 10));
      this.startedDate?.setSeconds(0);
    }
  }

  onReturnTimeChange(time: string) {
    this.validReturnTime = false;
    if (time) {
      this.validReturnTime = true;
      const hours = parseInt(time.split(' ')[0], 10);
      const isPm = time.split(' ')[2] === 'PM';
      this.returnDate.setHours(isPm ? hours + 12 : hours);
      this.returnDate.setMinutes(parseInt(time.split(' ')[1], 10));
      this.returnDate.setSeconds(0);
    }
  }

  functionalityRestoredChanged = (event: MatCheckboxChange) => (this.functionalityRestored = event.checked);

  showMissingSLA() {
    return (
      this.onSiteBy && this.isWf && this.priority !== 'NO SLA' && this.returnDate && this.onSiteBy < this.returnDate
    );
  }
}
