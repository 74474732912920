import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FabButtonComponent } from 'shared/fab-button/fab-button.component';
import { HeaderComponent } from 'shared/header/header.component';

@Component({
  selector: 'ads-layout',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FabButtonComponent],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent {}
