<ads-simple-form #sensorsTasks="ngModelGroup" ngModelGroup="sensorsTasks">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button
  ><mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test motion sensors"
    name="testMotionSensors"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test premise door contacts"
    name="testPremiseDoorContacts"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test vault doors"
    name="testVaultDoor"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test vault door heat sensors (if resettable)"
    name="testVaultDoorHeatSensors"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test vault audio sensors"
    name="testVaultAudioSensors"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    label="Test cash container contacts"
    name="testCashContainerContacts"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test cash container heat sensors (if resettable)"
    name="testCashContainerHeatSensors"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test cash container vibes"
    name="testCashContainerVibes"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Verified cash container vib setting"
    name="verifiedTestCashVibSetting"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test night drop contacts"
    name="testNightDropContacts"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test night drop heat sensors (if resettable)"
    name="testNightDropHeatSensors"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test night drop vib"
    name="testNightDropVib"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Verified night drop vib setting"
    name="verifiedNightDropVibSettings"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test night drop head"
    name="testNightDropHead"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test ATM door contact"
    name="testAtmDoorContact"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test ATM heat sensors (if resettable)"
    name="testAtmHeatSensors"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test ATM vib"
    name="testAtmVib"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Verified ATM vib setting"
    name="verifiedAtmVibSetting"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test TCR/TCD door contacts"
    name="testTcrTcdDoorContacts"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test TCR/TCD heat sensors (if resettable)"
    name="testTcrTcdHeatSensors"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test TCR/TCD vib"
    name="testTcrTcdVib"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Verified TCD/TCR vib setting"
    name="verifiedTcdTcrVibSetting"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
</ads-simple-form>



