<ads-simple-form
  *ngIf="item"
  class="access-hardware-card-reader-wrapper"
  [ngModelGroup]="'accessControlCardReaderInterface#' + item.index"
>
  <ads-common-form-input
    name="location"
    label="Location"
    [maxLength]="50"
    [required]="true"
    [value]="item.location"
    [(ngModel)]="item.location"
  ></ads-common-form-input>

  <ads-select
    label="Interface Type"
    name="interfaceType"
    [list]="lists?.acCardReaderInterfaceTableInterfaceTypes"
    [value]="item.interfaceType"
    [(ngModel)]="item.interfaceType"
  ></ads-select>

  <ads-common-form-input
    [style.display]="displayOtherInterface() ? 'block' : 'none'"
    name="interfaceType_Other"
    label="Other Interface Type"
    [maxLength]="50"
    [required]="displayOtherInterface()"
    [value]="item.interfaceType_Other"
    [(ngModel)]="item.interfaceType_Other"
  ></ads-common-form-input>

  <ads-common-form-input
    name="port"
    label="Port"
    type="number"
    [minValue]="0"
    [maxLength]="8"
    [required]="true"
    [value]="item.port"
    [(ngModel)]="item.port"
    inputMode="tel"
  ></ads-common-form-input>

  <ads-common-form-input
    name="address"
    label="Address"
    [maxLength]="50"
    [required]="true"
    [value]="item.address"
    [(ngModel)]="item.address"
  ></ads-common-form-input>
</ads-simple-form>
