import { SecurityAudioMatrixTasksDto } from 'models/api/security/audio-video/audio-matrix-tasks';
import { SecurityAudioVideoSectionDto } from 'models/api/security/audio-video/audio-video-dto';
import { AudioMatrixTasks } from 'models/security/audio-video/audioMatrixTasks';
import { AudioVideoSectionForm, AudioVideoSecurityForm } from 'models/security/audio-video/audioVideoSecurityForm';

export const mapAudioVideoSections = (panels: AudioVideoSectionForm[]): SecurityAudioVideoSectionDto[] => {
  return panels.map((panel) => ({
    audioMatrixModel: panel.audioMatrixModel,
    audioMatrixManufacturer: panel.manufacturer,
    audioMatrixManufacturer_Other: panel.otherManufacturer,
    equipment_Rating_AudioMatrix: panel.overallConditionComments,
    overallConditionAudioMatrix: panel.overallCondition,
    quantityOfTellerConsoles: panel.tellerConsolesAmount,
    selectVideoSystem: panel.videoSystem,
    style: panel.style,
    quantityOfWirelessHeadset: panel.wirelessHeadsetAmount,
  }));
};

export const mapAudioMatrixTasks = (data: AudioMatrixTasks): SecurityAudioMatrixTasksDto[] => [
  {
    inspectAllWiringConnectionForAudioSystem: data.inspectWiringConnections,
    inspectAndTestTellerConsoles: data.inspectTellerConsoles,
    inspectMicrophoneAdSpeakers: data.inspectMicrophone,
    testCallButtonForAllLanes: data.testCallButtons,
    testCustomerButtonForAllLanes: data.testCustomerGain,
    testTellerGainForAllLanes: data.testTellerGain,
  },
];

export const mapSecurityAudioVideo = (data: AudioVideoSecurityForm) => ({
  audioVideoSections: mapAudioVideoSections(data.audioVideoSections),
});

export const mapAudioVideoRequest = (audioVideo: AudioVideoSecurityForm | null) => ({
  security_AudioVideoDU: {
    security_AudioVideoDU_DriveUpAudioMatrix: mapAudioVideoSections(audioVideo?.audioVideoSections ?? []),
    security_AudioVideoDU_AudioMatrixTasks: mapAudioMatrixTasks(audioVideo?.audioMatrixTasks ?? new AudioMatrixTasks()),
    comments: audioVideo?.comments ?? null,
  },
});
