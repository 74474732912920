import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeZone',
})
export class TimeZonePipe implements PipeTransform {
  transform(value: string): string | Date {
    if (!value) return '';

    let dateValue = new Date(value);
    const quit = new Date().getTimezoneOffset() / -60;
    dateValue = new Date(dateValue.setHours(dateValue.getHours() + quit));

    return dateValue;
  }
}
