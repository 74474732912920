import { ApplicationConfig, provideZoneChangeDetection, isDevMode, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { provideRouter } from '@angular/router';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import {
  MsalInterceptor,
  MSAL_INSTANCE,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
  MsalGuard,
  MsalBroadcastService,
} from '@azure/msal-angular';
import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { environment } from 'environment/environment';
import { ApimInterceptor, TrackRequestInterceptor } from './interceptors';
import { AuthService, ApiService, ErrorHandlerService, PageTrackingService, LoggingService, FeatureManagerService } from './services';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { RetryHttpErrorsInterceptor } from 'interceptors/retry-request-interceptor/retry-request-interceptor';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: environment.msalConfig.redirectUrl,
      postLogoutRedirectUri: environment.msalConfig.redirectUrl,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>();
  protectedResourceMap.set(environment.apiConfig.ccCoreServiceUri, environment.apiConfig.scopes);
  protectedResourceMap.set(environment.apiConfig.axServiceUri, environment.apiConfig.scopes);
  protectedResourceMap.set(environment.apiConfig.adsServiceUri, environment.apiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes],
    },
    loginFailedRoute: '/login-failed',
  };
}

export function appInitializationFactory(authService: AuthService, loggingService: LoggingService): () => Promise<void> {
  return async () => {
    try {
      await authService.checkUserOnStartup();
    } catch (error) {
      loggingService.logException({
        message: 'Could not initialize the app. Please check AuthInit error in console.',
        name: 'AuthInit',
      });
      return Promise.reject(error as Error);
    }
  };
}

const providers = [
  provideCharts(withDefaultRegisterables()),
  { provide: LocationStrategy, useClass: PathLocationStrategy },
  provideZoneChangeDetection({ eventCoalescing: true }),
  provideRouter(routes),
  provideServiceWorker('ngsw-worker.js', {
    enabled: !isDevMode(),
    registrationStrategy: 'registerWhenStable:30000',
  }),
  provideAnimationsAsync(),
  provideHttpClient(withInterceptorsFromDi(), withFetch()),
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApimInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TrackRequestInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RetryHttpErrorsInterceptor,
    multi: true,
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializationFactory,
    deps: [AuthService, ApiService, LoggingService],
    multi: true,
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService,
  PageTrackingService,
  FeatureManagerService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
] as any;

// Only use the error handler outside localhost
if (location.hostname !== 'localhost') {
  providers.push({
    provide: ErrorHandler,
    useClass: ErrorHandlerService,
  });
}

export const appConfig: ApplicationConfig = {
  providers: providers,
};
