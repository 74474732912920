import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from 'services';
import { HeaderService } from 'shared/header/header.service';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';

@Component({
  selector: 'ads-profile-name',
  standalone: true,
  imports: [MatIconModule, CommonModule, MatButtonModule],
  templateUrl: './profile-name.component.html',
  styleUrl: './profile-name.component.scss',
})
export class ProfileNameComponent {
  @Input() showIcon = true;

  constructor(
    public authService: AuthService,
    public headerService: HeaderService,
    protected readonly layoutNavService: LayoutNavService,
  ) {}
}
