import { NetworkLogo } from '../../../shared/network-logo';
import { AdsAtmCleaningRequest, AtmCleaningRequest } from '../../atm-cleaning/ads-atm-cleaning-request';
import {
  ATMCleaningATM,
  ATMCleaningExtEval,
  ATMCleaningNetworkLogos,
  ATMCleaningPhotos,
  ATMCleaningReplenishmentTasks,
  ATMCleaningTasks,
  AtmCleaningDto,
} from '../../atm-cleaning/atm-cleaning';

const getLogos = (logos: NetworkLogo[], other: string): ATMCleaningNetworkLogos => {
  const atmNetworkLogos = {
    atmNetworkLogos_Visa: false,
    atmNetworkLogos_Mastercard: false,
    atmNetworkLogos_Discover: false,
    atmNetworkLogos_AmericanExpress: false,
    atmNetworkLogos_Plus: false,
    atmNetworkLogos_Maestro: false,
    atmNetworkLogos_Cirrus: false,
    atmNetworkLogos_Star: false,
    atmNetworkLogos_Coop: false,
    atmNetworkLogos_Other: other,
  };

  logos.forEach((logo) => {
    switch (logo) {
      case NetworkLogo.Visa:
        atmNetworkLogos.atmNetworkLogos_Visa = true;
        break;
      case NetworkLogo.Mastercard:
        atmNetworkLogos.atmNetworkLogos_Mastercard = true;
        break;
      case NetworkLogo.Discover:
        atmNetworkLogos.atmNetworkLogos_Discover = true;
        break;
      case NetworkLogo.AmericanExpress:
        atmNetworkLogos.atmNetworkLogos_AmericanExpress = true;
        break;
      case NetworkLogo.Plus:
        atmNetworkLogos.atmNetworkLogos_Plus = true;
        break;
      case NetworkLogo.Maestro:
        atmNetworkLogos.atmNetworkLogos_Maestro = true;
        break;
      case NetworkLogo.Cirrus:
        atmNetworkLogos.atmNetworkLogos_Cirrus = true;
        break;
      case NetworkLogo.Star:
        atmNetworkLogos.atmNetworkLogos_Star = true;
        break;
      case NetworkLogo.Coop:
        atmNetworkLogos.atmNetworkLogos_Coop = true;
        break;
    }
  });

  return atmNetworkLogos;
};

export const atmCleaningMapRequest = ({
  photosForm,
  fieldsForm,
  comments,
  workOrderDetail,
  pageSubmitMode,
  pageOpenMode,
}: AtmCleaningRequest): AdsAtmCleaningRequest => {
  const atm: ATMCleaningATM = {
    atm_Manufacturer: fieldsForm.atmSection.atmManufacturer,
    atm_ManufacturerOther: fieldsForm.atmSection.otherAtmManufacturer,
    atm_Model: fieldsForm.atmSection.atmModel,
    atm_DepositorType: fieldsForm.atmSection.depositorType,
    atm_SerialNumber: fieldsForm.atmSection.atmSerialNumber,
    atm_TerminalID: fieldsForm.atmSection.terminalId,
  };

  const atmExtEval: ATMCleaningExtEval = {
    atmExtEval_IsSurchargeStickerPresent: fieldsForm.atmExteriorEvaluation.surchargeStickerPresent,
    atmExtEval_IsDepositoryAvailabilityStickerPresent: fieldsForm.atmExteriorEvaluation.depositoryAvailabilityStickerPresent,
    atmExtEval_AreAudioInstructionsFunctioning: fieldsForm.atmExteriorEvaluation.audioInstructionsFunctioning,
    atmExtEval_IsSoftwareOnsite: fieldsForm.atmExteriorEvaluation.isSoftwareOnsite,
  };

  const atmNetworkLogos = getLogos(fieldsForm.atmNetworkLogos.logos, fieldsForm.atmNetworkLogos.other);

  const cleaningTasks: ATMCleaningTasks = {
    cleaningTasks_ThoroughlyInspectATM: fieldsForm.cleaningTasks.thoroughInspection,
    cleaningTasks_CleanWipeFasciaTrim: fieldsForm.cleaningTasks.fasciaTrim,
    cleaningTasks_CleanWipeCustomerDisplay: fieldsForm.cleaningTasks.customerDisplay,
    cleaningTasks_CleanWipeFunctionKeys: fieldsForm.cleaningTasks.functionKeys,
    cleaningTasks_CleanWipeAtmSides: fieldsForm.cleaningTasks.atmSidesBackAndTop,
    cleaningTasks_CleanAtmAreaAndDisposeGarbage: fieldsForm.cleaningTasks.atmArea,
    cleaningTasks_VaccumBlowoutSafe: fieldsForm.cleaningTasks.vacuumSafe,
    cleaningTasks_VaccumBlowoutTopHalf: fieldsForm.cleaningTasks.vacuumTopHalf,
    cleaningTasks_VaccumBlowoutAllFans: fieldsForm.cleaningTasks.vacuumAllFans,
    cleaningTasks_VaccumBlowoutAllFilters: fieldsForm.cleaningTasks.vacuumAllFilters,
  };

  const replenishmentTasks: ATMCleaningReplenishmentTasks = {
    replenishmentTasks_CheckPrinterPaperRoll: fieldsForm.replenishmentTasks.paperRoll,
    replenishmentTasks_CheckDepositoryInkCartridge: fieldsForm.replenishmentTasks.depositoryInk,
    replenishmentTasks_CheckEnvelopDispenserHopper: fieldsForm.replenishmentTasks.envelopeDispenser,
  };

  const photo: ATMCleaningPhotos = {
    photoFrontClose: photosForm['atm-front-close'].value?.split('?sv=')[0] ?? null,
    photoATMFeeNotice: photosForm['atm-fee-notice'].value?.split('?sv=')[0] ?? null,
    photoATMScreen: photosForm['atm-screen'].value?.split('?sv=')[0] ?? null,
    photoATMNetworkDecals: photosForm['atm-network-decals'].value?.split('?sv=')[0] ?? null,
    photoLongDistance: photosForm['atm-long-distance'].value?.split('?sv=')[0] ?? null,
    photoDamage: photosForm['atm-damage'].value?.split('?sv=')[0] ?? null,
    photoMissingParts: photosForm['atm-missing-parts'].value?.split('?sv=')[0] ?? null,
    photoMissingStickers: photosForm['atm-missing-stickers'].value?.split('?sv=')[0] ?? null,
    photo_AdditionalPhoto: photosForm['atm-additional-photo'].value?.split('?sv=')[0] ?? null,
    photoSerialNumber: photosForm['atm-serial-number'].value?.split('?sv=')[0] ?? null,
  };

  const atmCleaning: AtmCleaningDto = {
    updatedOn: new Date().toUTCString(),
    performedBy: workOrderDetail.performedBy ?? null,
    workOrderId: workOrderDetail.id,
    ...atm,
    ...atmExtEval,
    ...atmNetworkLogos,
    ...cleaningTasks,
    ...replenishmentTasks,
    ...photo,
    comments,
  };

  return {
    pageOpenMode,
    pageSubmitMode,
    isEditable: true,
    fromXml: false,
    atmCleaning,
    workOrderDetail: workOrderDetail,
  };
};
