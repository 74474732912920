import { Component, Inject, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

interface Selection {
  title: string;
  control: string;
  options: {
    name: string;
    value: string;
  }[];
  value: string;
}

interface TableBottomSheetsComponentProps {
  selections: Selection[];
  addNew?: () => void;
  showCancel?: boolean;
}

@Component({
  selector: 'ads-table-bottom-sheets',
  standalone: true,
  imports: [MatButtonModule, MatButtonToggleModule, ReactiveFormsModule, FormsModule, CommonModule],
  templateUrl: './table-bottom-sheets.component.html',
  styleUrl: './table-bottom-sheets.component.scss',
})
export class TableBottomSheetsComponent {
  private readonly _bottomSheetRef = inject<MatBottomSheetRef<TableBottomSheetsComponent>>(MatBottomSheetRef);

  formGroup: FormGroup;

  addEnabled = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: TableBottomSheetsComponentProps,
  ) {
    this.formGroup = this.formBuilder.group({});
    this.initializeForm();

    this.addEnabled = !!this.data.addNew;
  }

  initializeForm(): void {
    this.data.selections.forEach((selection) => {
      const defaultValue = selection.value || null;
      const control = this.formBuilder.control(defaultValue);
      this.formGroup.addControl(selection.control, control);
    });
  }

  onAccept(): void {
    this._bottomSheetRef.dismiss(this.formGroup.value);
  }

  onCancel(): void {
    this._bottomSheetRef.dismiss();
  }

  onAcceptAndAddNew(): void {
    this._bottomSheetRef.dismiss(this.formGroup.value);
    this.data.addNew?.();
  }
}
