export class ItmFasciaAndAdaTasks {
  cleanCustomerInterfaceArea: number | null;
  areAdaStickersPresentAndReadable: number | null;
  checkToneVolumeAndAdjust: number | null;
  testVoiceGuidance: number | null;
  testAllKeypadAndFunctionKeys: number | null;
  checkAlignWithCardReader: number | null;
  checkAlignAndOperationOfDispensorShutter: number | null;
  checkAlignWithReceiptPrinter: number | null;
  cleanOperatorPanel: number | null;
  cleanEntireExteriorOfStandaloneAtms: number | null;
  verifyTouchScreenCalibration: number | null;
  testOpenCloseIndicator: number | null;
  replaceLightBulbs: number | null;
  wipeDownAllAtmDevices: number | null;
  antiSkimmingInDeviceConfigMode: number | null;
  topHatLightOperational: number | null;

  constructor() {
    this.cleanCustomerInterfaceArea = null;
    this.areAdaStickersPresentAndReadable = null;
    this.checkToneVolumeAndAdjust = null;
    this.testVoiceGuidance = null;
    this.testAllKeypadAndFunctionKeys = null;
    this.checkAlignWithCardReader = null;
    this.checkAlignAndOperationOfDispensorShutter = null;
    this.checkAlignWithReceiptPrinter = null;
    this.cleanOperatorPanel = null;
    this.cleanEntireExteriorOfStandaloneAtms = null;
    this.verifyTouchScreenCalibration = null;
    this.testOpenCloseIndicator = null;
    this.replaceLightBulbs = null;
    this.wipeDownAllAtmDevices = null;
    this.antiSkimmingInDeviceConfigMode = null;
    this.topHatLightOperational = null;
  }
}
