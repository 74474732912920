interface Button {
  color: string;
  background: string;
  borderColor: string;
  colorHover: string;
}

interface Tabs {
  background: string;
  color: string;
  focusedColor: string;
}

interface Badge {
  color: string;
  valueColor: string;
  background: string;
}

interface Nav {
  color: string;
  background: string;
  activeColorMobile: string;
  dividerColor: string;
}

interface Card {
  background: string;
  color: string;
  subBackground: string;
}

interface Table {
  headerBackground: string;
  border: string;
}

export enum ThemeName {
  Dark = 'dark',
  Light = 'light',
}

export interface Theme {
  name: ThemeName;
  background: string;
  primary: string;
  primaryLight: string;
  ripple: string;
  primaryDark: string;
  error: string;
  selectedOption: string;
  inputColorSelected: string;
  panelColor: string;
  inputBorder: string;
  inputBorderSelected: string;
  inputBackground: string;
  inputBackgroundSelected: string;
  secondaryButton: Button;
  dangerButton: Button;
  hoverPrimary: string;
  tabs: Tabs;
  badge: Badge;
  nav: Nav;
  card: Card;
  table: Table;
}

export const darkTheme: Theme = {
  name: ThemeName.Dark,
  background: '#1B1B1B',
  error: '#B3261E',
  primary: '#9B9B9B',
  primaryDark: '#d9d9d9',
  primaryLight: '#1B1B1B',
  ripple: '#2bc954A0',
  selectedOption: '#fff',
  inputColorSelected: '#FFFFFF',
  inputBorder: '#3a3a3a',
  panelColor: '#000',
  inputBorderSelected: '#2bc954',
  inputBackground: '#1B1B1B',
  inputBackgroundSelected: '#1B1B1B',
  secondaryButton: {
    color: '#9B9B9B',
    background: '#242424',
    borderColor: '#242424',
    colorHover: '#242424A0',
  },
  dangerButton: {
    color: '#9B9B9B',
    background: '#500E0B',
    borderColor: '#500E0B',
    colorHover: '#7D211C',
  },
  hoverPrimary: '#2bc95d2b',
  tabs: {
    background: '#242424',
    color: '#fff',
    focusedColor: '#1D1B20',
  },
  badge: {
    color: '#9B9B9B',
    background: '#9B9B9B',
    valueColor: '#1B1B1B',
  },
  nav: {
    background: '#292929',
    color: '#d9d9d9',
    activeColorMobile: '#70e07f',
    dividerColor: '#000',
  },
  card: {
    background: '#1f2520',
    color: '#D9D9D9',
    subBackground: '#1B1B1B',
  },
  table: {
    headerBackground: '#242424',
    border: '#3A3A3A',
  },
};

export const lightTheme: Theme = {
  name: ThemeName.Light,
  background: '#FFF',
  error: '#B3261E',
  primary: '#1D1B20',
  primaryDark: '#1D1B20',
  primaryLight: '#FFF',
  ripple: '#2bc954A0',
  selectedOption: '#000',
  inputColorSelected: '#1D1B20',
  inputBorder: '#F1F1F1',
  panelColor: '#F1F1F1',
  inputBorderSelected: '#2bc954',
  inputBackground: '#F1F1F1',
  inputBackgroundSelected: '#2bc954',
  secondaryButton: {
    color: '#1D1B20',
    background: '#FFF',
    borderColor: '#1D1B20',
    colorHover: '#636363',
  },
  dangerButton: {
    color: '#B3261E',
    background: '#FFF',
    borderColor: '#B3261E',
    colorHover: '#BD5E59',
  },
  hoverPrimary: '#2bc95d2b',
  tabs: {
    background: '#F1F1F1',
    color: '#242424',
    focusedColor: '#1D1B20',
  },
  badge: {
    color: '#1D1B20',
    background: '#9B9B9B',
    valueColor: '#FFF',
  },
  nav: {
    background: '#f1f1f1',
    color: '#242424',
    activeColorMobile: '#242424',
    dividerColor: '#FFF',
  },
  card: {
    background: '#fff',
    color: '#1b1b1b',
    subBackground: '#f1f1f1',
  },
  table: {
    headerBackground: '#DDDDDD',
    border: '#E5E5E5',
  },
};
