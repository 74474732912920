<div class="container">
  <mat-expansion-panel class="expansion-panel" expanded>
    <mat-expansion-panel-header class="expansion-panel-header">
      <mat-panel-title> Work Order Details </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansion-panel-header-content">
      <div *ngIf="workOrderDetails as details" class="details">
        <p>{{ details.calltype }}</p>
        <ads-detail-row
          name="Scheduled:"
          [value]="details.servicedatetime | date : 'MM/dd/yyyy - HH:mm a'"
        ></ads-detail-row>
        <ads-detail-row name="Problem:" [value]="details.problem"></ads-detail-row>
        <ads-detail-row name="Call Type:" [value]="details.problemTypeId"></ads-detail-row>
        <ads-detail-row name="Opened:" [value]="details.dateopened | date : 'MM/dd/yyyy - HH:mm a'"></ads-detail-row>
        <ads-detail-row name="Ticket#:" [value]="details.ticketRecId" [valueAction]="openTicketNotes"></ads-detail-row>
        <ads-detail-row name="PO:" [value]="details.customerpo"></ads-detail-row>
        <ads-detail-row name="On-site by:" [value]="details.onSiteBy | date : 'MM/dd/yyyy - HH:mm a'"></ads-detail-row>
        <ads-detail-row name="Resolve by:" [value]="details.resolveBy | date : 'MM/dd/yyyy - HH:mm a'"></ads-detail-row>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel class="expansion-panel" expanded>
    <mat-expansion-panel-header class="expansion-panel-header">
      <mat-panel-title> Customer Info </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansion-panel-header-content">
      <div *ngIf="workOrderDetails as details" class="details">
        <ads-detail-row name="Customer:" [value]="details.customer"></ads-detail-row>
        <ads-detail-row name="Search name:" [value]="details.nameAlias"></ads-detail-row>
        <ads-detail-row name="City:" [value]="details.city"></ads-detail-row>
        <ads-detail-row name="Contact:" [value]="details.contact"></ads-detail-row>
        <ads-detail-row name="Phone:" [value]="details.phone" [valueAction]="openPhone"></ads-detail-row>
        <ads-detail-row name="Address:" [value]="details.address" [valueAction]="openMap"></ads-detail-row>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel class="expansion-panel" expanded>
    <mat-expansion-panel-header class="expansion-panel-header">
      <mat-panel-title> Equipment On-Site </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansion-panel-header-content">
      <div *ngIf="workOrderDetails as details" class="details">
        <div class="search-header">
          <div class="search-box">
            <ads-common-form-input label="Search" [maxLength]="50" [(ngModel)]="searchText"></ads-common-form-input>
            <mat-icon>search</mat-icon>
          </div>
          <button mat-button mat-stroked-button (click)="openEquipmentOnSite()">
            Open list <mat-icon iconPositionEnd>open_in_new</mat-icon>
          </button>
        </div>

        <ng-container *ngFor="let equipment of (equipmentOnSite | async)">
          <div class="equipment-container">
            <div class="equipment-header">
              <h3>{{ equipment.equipmentType || '-' }}</h3>
              <span>{{ equipment.terminalID || '-' }}</span>
            </div>

            <div class="equipment-manufacturer">
              <div class="manufacturer">
                <mat-icon class="material-icons-outlined">domain</mat-icon>
                <span>{{ equipment.manufacturer || '-' }}</span>
              </div>
              {{ equipment.model || '-' }}
            </div>

            <div class="equipment-type">
              <span>{{ equipment.type || '-' }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-expansion-panel>
</div>
