import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { FormsModule, ControlContainer, NgModelGroup, FormGroup } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { SelectOption, CompleteNa, YesNoNa } from 'models';
import { AccessControlHardwareDropdowns } from 'models/security/security-dropdowns';
import { CommonFormInputComponent, SelectComponent, RadioButtonGroupComponent } from 'shared/material-wrappers';
import { AccessControlDoorTableFormSection } from '../access-hardware.component';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-ah-doors-table-section',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CommonFormInputComponent,
    SelectComponent,
    MatDividerModule,
    RadioButtonGroupComponent,
    SimpleFormComponent
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
  templateUrl: './ah-doors-table-section.component.html',
})
export class AhDoorsTableSectionComponent {
  @Input() lists?: AccessControlHardwareDropdowns;
  @Input() section?: FormGroup;
  @Input() item?: AccessControlDoorTableFormSection;

  constructor(@Optional() public readonly ngModelGroup: NgModelGroup) {}

  optionsCompleteNa: SelectOption<CompleteNa>[] = [
    { name: 'Complete', value: CompleteNa.Complete },
    { name: 'N/A', value: CompleteNa.Na },
  ];

  optionsYesNoNa: SelectOption<YesNoNa>[] = [
    { name: 'Yes', value: YesNoNa.Yes },
    { name: 'No', value: YesNoNa.No },
    { name: 'N/A', value: YesNoNa.Na },
  ];

  displayOther = (value: string) => {
    const field = this.section?.get(value);
    return Boolean(field && field.value === 'Other');
  };
}
