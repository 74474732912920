<form class="container">
  <h3 class="go-back" *ngIf="layoutNavService.isDesktopView()">
    <button mat-icon-button (click)="goBack()" type="button">
      <mat-icon class="material-icons-outlined">arrow_back</mat-icon>
    </button>
    Add part
  </h3>
  <div style="margin-left: 15px; margin-right: 15px">
    <mat-form-field class="field">
      <input
        type="text"
        matInput
        [(ngModel)]="search"
        [ngModelOptions]="{ standalone: true }"
        placeholder="Search by number or description"
      />
      <button mat-icon-button matSuffix (click)="onSearch()">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="field">
      <input
        type="text"
        matInput
        [formControl]="searchedPartsControl"
        [matAutocomplete]="auto"
        placeholder="Part Number & Description"
      />
      <mat-icon matSuffix>arrow_drop_down</mat-icon>
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        @for (option of filteredOptions | async; track option) {
        <mat-option [value]="option">
          <span class="search-option">
            {{ option }}
          </span>
        </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    <ads-common-form-input
      label="Quantity"
      type="number"
      [(ngModel)]="quantity"
      [ngModelOptions]="{ standalone: true }"
      [required]="true"
    ></ads-common-form-input>

    <ads-common-text-area
      label="Description of the problem"
      [maxLength]="500"
      [required]="true"
      [(ngModel)]="problemDescription"
      [ngModelOptions]="{ standalone: true }"
    ></ads-common-text-area>

    <ads-photo-upload [form]="photosControl" [photoUpload]="photoUpload"></ads-photo-upload>

    <ads-common-form-input
      label="Part serial number"
      [(ngModel)]="partSerialNumber"
      [ngModelOptions]="{ standalone: true }"
    ></ads-common-form-input>

    <ads-common-form-input
      label="Tech support case number"
      [(ngModel)]="techSupportCaseNumber"
      [ngModelOptions]="{ standalone: true }"
    ></ads-common-form-input>

    <ads-select
      label="Part is"
      [list]="reasonsItems"
      [(ngModel)]="selectedReasons"
      [required]="true"
      [ngModelOptions]="{ standalone: true }"
    ></ads-select>

    <ng-container *ngIf="selectedReasons === '0'">
      <ads-select
        label="How was defective part discovered?"
        [list]="discoveredDuringItems"
        [(ngModel)]="selectedDiscoveredDuring"
        [required]="true"
        [ngModelOptions]="{ standalone: true }"
      ></ads-select>

      <ng-container *ngIf="selectedDiscoveredDuring !== '3'">
        <ads-common-form-input
          label="Serial number of machine"
          [(ngModel)]="machineSerialNumber"
          [ngModelOptions]="{ standalone: true }"
          [required]="true"
        ></ads-common-form-input>
      </ng-container>

      <ng-container *ngIf="selectedDiscoveredDuring === '1' || selectedDiscoveredDuring === '2'">
        <ads-datepicker label="From" [(ngModel)]="fromDate" [ngModelOptions]="{ standalone: true }"> </ads-datepicker>
      </ng-container>
    </ng-container>

    <div class="button-container">
      <button mat-button mat-stroked-button type="button" (click)="goBack()">Cancel</button>
      <button *ngIf="selectedPart !== undefined" class="delete" mat-button type="button" (click)="deletePart()">
        Delete
      </button>
      <button mat-button mat-flat-button type="button" (click)="save()" [disabled]="!saveEnabled()">Save</button>
    </div>
  </div>
</form>
