import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, Optional, Output, ViewChild } from '@angular/core';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, NgModel, NgModelGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ValidateRequiredDirective } from 'app/shared/directives/validate-required.directive';
import { SelectOption } from 'models';

@Component({
  selector: 'ads-radio-button-group',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatButtonToggleModule, FormsModule, ValidateRequiredDirective, MatButtonModule],
  templateUrl: './radio-button-group.component.html',
  styleUrl: './radio-button-group.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioButtonGroupComponent,
    },
  ],
})
export class RadioButtonGroupComponent<T> implements AfterViewInit {
  @Input() label = '';
  @Input() name = '';
  @Input() options: SelectOption<T>[] = [];
  @Input() required = false;
  @Input() multiple = false;
  @Input() removeIcon = false;
  @Input() verticalLayout = false;
  @Output() optionClicked = new EventEmitter<T | T[]>();
  value: T = '' as T;

  @ViewChild('customSelect') customSelect?: NgModel;

  touched = false;
  valid = true;
  disabled = false;
  formControl = new FormControl('', []);

  constructor(@Optional() private readonly ngModelGroup: NgModelGroup) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.ngModelGroup?.control) {
        this.ngModelGroup.control.registerControl(this.name, this.customSelect);
        this.ngModelGroup.control.controls[this.name].statusChanges.subscribe(() => {
          this.touched = this.ngModelGroup.control.touched;
        });
        this.ngModelGroup.control.controls[this.name].valueChanges.subscribe((value) => {
          if (value === null) {
            this.customSelect?.control.setValue('');
            this.customSelect?.control.markAsPristine();
            this.customSelect?.control.markAsUntouched();
            this.touched = false;
          }

          if (value) {
            this.onTouched();
          }
        });
        this.disabled = this.ngModelGroup.control.controls[this.name].disabled;
      }
    });
  }

  getInvalid = () => {
    return (
      this.ngModelGroup?.control?.controls[this.name].invalid && this.ngModelGroup?.control?.controls[this.name].touched
    );
  };

  onChange = () => {};

  onTouched = () => {};

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  propagateChange = (value: T) => {
    this.writeValue(value);
  };

  writeValue(newValue: T): void {
    this.value = newValue;
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  onModelChange(value: T) {
    this.propagateChange(value);
    if (this.ngModelGroup) {
      this.ngModelGroup.control.controls[this.name].setValue(value);
      this.ngModelGroup.control.markAsTouched();
    }
    this.optionClicked.emit(value);
  }
}
