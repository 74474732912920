import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ValidateRequiredDirective } from 'app/shared/directives/validate-required.directive';
import { NumberInputDirective } from 'shared/directives/number-input.directive';
import { CustomTooltipComponent } from '../custom-tooltip/custom-tooltip.component';
import { BaseFormInputComponent } from '../form-input-base/form-input.base';

@Component({
  selector: 'ads-common-form-input',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ValidateRequiredDirective,
    NumberInputDirective,
    CustomTooltipComponent,
  ],
  templateUrl: './common-form-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CommonFormInputComponent,
    },
  ],
})
export class CommonFormInputComponent extends BaseFormInputComponent implements ControlValueAccessor, AfterViewInit {
  @Input() suffixIcon = '';
  @Output() fieldFocused = new EventEmitter<FocusEvent>();

  ngAfterViewInit(): void {
    setTimeout(() => {
      const group = this.ngModelGroup ?? this.ngForm;
      if (group?.control !== null && this.name !== '') {
        const control =
          this.formGroupName === ''
            ? group.control
            : (group.control.controls[this.formGroupName] as FormGroup);
        control.registerControl(this.name, this.customInput);

        control.controls[this.name].valueChanges.subscribe((value) => {
          if (value === null) {
            this.customInput?.control.setValue('');
            this.customInput?.control.markAsPristine();
            this.customInput?.control.markAsUntouched();
          }
          this.value = value;

          if (value) {
            this.onTouched();
          }
        });
      }
    });
  }
}
