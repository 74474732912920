<ads-simple-form #dealDrawer="ngModelGroup" ngModelGroup="dealDrawer">
  <ng-container ngModelGroup="dealDrawerSections">
    <ads-nested-multiple-forms-section
      #dealDrawerSections
      title="Deal Drawer"
      [formTemplate]="formTemplate"
      [list]="security.dealDrawerData.dealDrawerSections"
      [formControls]="getFormControls()"
      [groupName]="'dealDrawerSection'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddClick()"
      (deleteClicked)="onRemoveDealDrawer($event)"
    >
    </ads-nested-multiple-forms-section>
    <ng-template #formTemplate let-item="item">
      <ads-deal-drawer-section-form
        [section]="getSection('dealDrawerSection#' + item.index)"
        [item]="item"
        [dealDrawerLists]="security.dealDrawerData.dealDrawerDropdowns"
      ></ads-deal-drawer-section-form>
    </ng-template>
  </ng-container>

  <ads-expansion-panel
    #dealDrawerTasksPanel
    title="Deal Drawer Tasks"
    [formGroup]="formGroup"
    [name]="'dealDrawerTasks'"
    [class]="'dealDrawerTasks'"
  >
    <ads-deal-drawer-tasks [section]="getSection('dealDrawerTasks')"></ads-deal-drawer-tasks>
  </ads-expansion-panel>
  <ads-common-text-area
    name="comments"
    label="Comments (optional)"
    [maxLength]="200"
    ngModel
  ></ads-common-text-area>
</ads-simple-form>
