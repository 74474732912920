<div *ngIf="isDesktopView" class="search-box">
  <ads-common-form-input label="Search" [maxLength]="50" [(ngModel)]="service.searchText" (ngModelChange)="onSortingChanged()"></ads-common-form-input>
  <mat-icon>search</mat-icon>
</div>
<div *ngIf="isDesktopView" class="filter-buttons">
  <span class="filter-title">Filter by: </span>
  <ads-date-filtering
    label="Date"
    [isDesktopView]="true"
    [minDate]="minDate"
    (confirm)="onDateFilterChanged()"
    (fromDateChanged)="fromDate = $event"
    (toDateChanged)="toDate = $event"
  ></ads-date-filtering>
  <ads-radio-filtering
    [filteringOptions]="service.statusFilter.options ?? []"
    label="Status"
    (filterChanged)="onStatusFilterChanged($event)"
  ></ads-radio-filtering>
  <span class="separator"></span>
  <ads-desktop-sorting [label]="'Sort'" (sortChanged)="onSortingChanged()"></ads-desktop-sorting>
</div>
<div *ngIf="!isDesktopView" class="mobile-wrapper">
  <div class="mobile-search-box">
    <div class="search-box">
      <ads-common-form-input label="Search" [maxLength]="50" [(ngModel)]="service.searchText"> </ads-common-form-input>
      <mat-icon>search</mat-icon>
    </div>
    <ads-mobile-sorting
      (menuOpened)="service.onSortOpened()"
      (menuDismissed)="service.onSortDismissed()"
      (sortChanged)="onSortingChanged()"
    ></ads-mobile-sorting>
  </div>
  <div class="filter-buttons mobile-filter-buttons">
    <span class="fitler-title">Filter by: </span>
    <ads-mobile-date-filtering
      [minDate]="minDate"
      (fromDateChanged)="fromDate = $event"
      (toDateChanged)="toDate = $event"
      (dateFilterConfirmed)="onDateFilterChanged()"
    ></ads-mobile-date-filtering>
    <ads-mobile-status-filtering
      [filteringOptions]="service.statusFilter.options ?? []"
      (statusFilterConfirmed)="onStatusFilterChanged($event)"
    ></ads-mobile-status-filtering>
  </div>
</div>
