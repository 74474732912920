<div *ngIf="isDesktopView" class="search-box">
  <ads-common-form-input label="Search" [maxLength]="50" [(ngModel)]="searchText"></ads-common-form-input>
  <mat-icon>search</mat-icon>
</div>
<div *ngIf="isDesktopView" class="filter-buttons">
  <span class="filter-title">Filter by: </span>
  <button mat-button mat-stroked-button (click)="toggleFilter('date')">
    Date
    <mat-icon *ngIf="criteria.filters.includes('date')" iconPositionEnd>check</mat-icon>
  </button>
  <button mat-button mat-stroked-button (click)="toggleFilter('location')">
    Location
    <mat-icon *ngIf="criteria.filters.includes('location')" iconPositionEnd>check</mat-icon>
  </button>
  <button mat-button mat-stroked-button (click)="toggleFilter('status')">
    Status
    <mat-icon *ngIf="criteria.filters.includes('status')" iconPositionEnd>check</mat-icon>
  </button>
  <button mat-button mat-stroked-button (click)="toggleFilter('type')">
    Type
    <mat-icon *ngIf="criteria.filters.includes('type')" iconPositionEnd>check</mat-icon>
  </button>
  <span class="separator"></span>
  <button mat-button mat-stroked-button>Sort <mat-icon iconPositionEnd>swap_vert</mat-icon></button>
</div>

<div *ngIf="!isDesktopView" class="mobile-wrapper">
  <div class="mobile-search-box">
    <div class="search-box">
      <ads-common-form-input label="Search" [maxLength]="50" [(ngModel)]="searchText"></ads-common-form-input>
      <mat-icon>search</mat-icon>
    </div>
    <button mat-button mat-stroked-button>Sort <mat-icon iconPositionEnd>swap_vert</mat-icon></button>
  </div>
  <div class="filter-buttons mobile-filter-buttons">
    <span class="fitler-title">Filter by: </span>
    <button mat-button mat-stroked-button (click)="toggleFilter('date')">
      Date
      <mat-icon *ngIf="criteria.filters.includes('date')" iconPositionEnd>check</mat-icon>
    </button>
    <button mat-button mat-stroked-button (click)="toggleFilter('location')">
      Location
      <mat-icon *ngIf="criteria.filters.includes('location')" iconPositionEnd>check</mat-icon>
    </button>
    <button mat-button mat-stroked-button (click)="toggleFilter('status')">
      Status
      <mat-icon *ngIf="criteria.filters.includes('status')" iconPositionEnd>check</mat-icon>
    </button>
    <button mat-button mat-stroked-button (click)="toggleFilter('type')">
      Type
      <mat-icon *ngIf="criteria.filters.includes('type')" iconPositionEnd>check</mat-icon>
    </button>
  </div>
</div>
