@if (!authService.authenticated) {
<ads-login></ads-login>
} @if (authService.authenticated) {
<div class="home-page" [class.horizontal-margins]="layoutNavService.isDesktopView()">
  <h2>Dashboard</h2>
  <div *ngIf="layoutNavService.isDesktopView(); else mobileTemplate">
    <ads-buttons-container
      [isDesktop]="true"
      [newCalls]="newCalls"
      [openCalls]="openCalls"
    ></ads-buttons-container>
  </div>
  <ng-template #mobileTemplate>
    <ads-buttons-container [isDesktop]="false"></ads-buttons-container>
    <ads-calls-container
      [newCalls]="newCalls"
      [openCalls]="openCalls"
      [isDesktop]="false"
    ></ads-calls-container>
  </ng-template>

  <div class="ranking-charts">
    <h3>My Performance</h3>
    <ads-ranking-chart (valuesChange)="rankingValues = $event"></ads-ranking-chart>
  </div>
  <h3>Overall Performance (%)</h3>
  <ads-home-charts [rankingValues]="rankingValues"></ads-home-charts>
</div>
}
