export class AtmSection {
  atmManufacturer: string | null;
  otherAtmManufacturer: string | null;
  atmModel: string | null;
  depositorType: string | null;
  atmSerialNumber: string | null;
  terminalId: string | null;
  constructor() {
    this.atmManufacturer = '';
    this.otherAtmManufacturer = '';
    this.atmModel = '';
    this.depositorType = '';
    this.atmSerialNumber = '';
    this.terminalId = '';
  }
}
