<mat-form-field class="common-form-field" appearance="outline" hideRequiredMarker>
  <mat-label>{{ label }}</mat-label>
  <input
    #customInput="ngModel"
    matInput
    adsRequired
    [checkRequired]="required"
    [required]="required"
    [disabled]="getDisabled() ? 'disabled' : false"
    [name]="name"
    [maxLength]="maxLength"
    [ngModelOptions]="{ standalone: true }"
    [(ngModel)]="value"
    (ngModelChange)="onModelChange($event)"
    (blur)="markAsTouched()"
    adsNumberInput
    [shouldModify]="type === 'number'"
    [minValue]="minValue"
    [inputMode]="inputMode"
  />
  <ads-custom-tooltip *ngIf="tooltip && hideHints" [tooltip]="tooltip"></ads-custom-tooltip>
  <mat-hint align="start" class="custom-error" *ngIf="getInvalid() && !getDisabled()">
    This field is required{{ type === 'number' ? '. Digits only' : '' }}
  </mat-hint>
  <mat-hint align="end" *ngIf="showStringValueLength() && hideHints"
    >{{ getStringValue()?.length ?? 0 }} / {{ maxLength }} characters</mat-hint
  >
</mat-form-field>
