import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environment/environment';
import { MsalService } from '@azure/msal-angular';

type HttpUri = 'ads' | 'ax' | 'ccc' | 'override';

const getUrl = (uri: HttpUri) => {
  switch (uri) {
    case 'ax':
      return environment.apiConfig.axServiceUri;
    case 'ccc':
      return environment.apiConfig.ccCoreServiceUri;
    case 'ads':
      return environment.apiConfig.adsServiceUri;
    case 'override':
      return '';
  }
};

interface RequestOptions {
  method: string;
  url: string;
  body?: unknown;
  params?: HttpParams;
  urlPrefix?: HttpUri;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  protected axApiBasePath = environment.apiConfig.axServiceUri;
  protected ccApiBasePath = environment.apiConfig.ccCoreServiceUri;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient, private readonly authService: MsalService) {}

  /**
   * Sends an HTTP request using the Angular HttpClient module.
   *
   * @param method The HTTP method of the request (e.g., 'GET', 'POST', etc.).
   * @param url The URL of the request.
   * @param body An optional object that represents the request body.
   * @param params An optional HttpParams object that represents the query parameters of the request.
   *
   * @returns An Observable that emits the response of the HTTP request.
   */
  public sendRequest<ResponseType>({
    method,
    url,
    body,
    params,
    urlPrefix = 'ads',
  }: RequestOptions): Observable<ResponseType> {
    let headers = this.defaultHeaders;

    if (this.authService.instance.getAllAccounts().length <= 0) {
      throw new Error('User is not authenticated');
    }

    headers = headers.set('Accept', 'application/json');

    url = getUrl(urlPrefix) + url;

    return this.httpClient.request<ResponseType>(method, url, {
      body: body,
      params: params,
      headers: headers,
    });
  }
}
