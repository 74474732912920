<ads-simple-form #vault="ngModelGroup" ngModelGroup="vault">
  <ng-container ngModelGroup="vaultSections">
    <ads-nested-multiple-forms-section
      #vaultSectionsPanels
      title="Vault"
      [formTemplate]="formTemplate"
      [list]="security.vaultData.vaultSectionSections"
      [formControls]="getFormControls()"
      [groupName]="'vaultSection'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddClick()"
      (deleteClicked)="onRemoveVault($event)"
    >
    </ads-nested-multiple-forms-section>
    <ng-template #formTemplate let-item="item">
      <ads-vault-section-form
        [section]="getItemSection('vaultSection#' + item.index, 'vaultSections')"
        [vaultLists]="security.vaultData.vaultDropdowns"
        [item]="item"
        [submitted]="submitted"
      ></ads-vault-section-form>
    </ng-template>
  </ng-container>
  <ads-expansion-panel
    title="Time clock tasks"
    [formGroup]="formGroup"
    [name]="'timeClockTasks'"
    [class]="'timeClockTasks'"
  >
    <ads-time-clock-tasks-section [section]="getSection('timeClockTasks')"></ads-time-clock-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    title="Combo lock tasks"
    [formGroup]="formGroup"
    [name]="'comboLockTasks'"
    [class]="'comboLockTasks'"
  >
    <ads-combo-lock-tasks-section
      [section]="getSection('comboLockTasks')"
      [fields]="comboLockFields"
    ></ads-combo-lock-tasks-section>
  </ads-expansion-panel>

  <ads-common-text-area name="comments" label="Comments (optional)" [maxLength]="200" ngModel></ads-common-text-area>
</ads-simple-form>
