<ads-simple-form #nightDropHeadTasks="ngModelGroup" [ngModelGroup]="'nightDropHeadTasks'">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button>

  <ads-radio-button-group
    [options]="options"
    label="Lubricate and reassemble dial and ring"
    name="lubricateAndReassembleDialAndRing"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Check for proper operation of head"
    name="checkForProperOperationOfHead"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Inspect head for excessive wear"
    name="inspectHeadForExcessiveWear"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Lubricate head bolt track"
    name="lubricateHeadBoltTrack"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Clean head with appropriate cleaner"
    name="cleanHeadWithAppropriateCleaner"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
