import { Component, ViewChild, OnDestroy, AfterViewInit, OnInit } from '@angular/core';
import { CcCdService } from './cc-cd-pm.service';
import { HeaderService } from 'shared/header/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StickyHeaderService } from 'shared/sticky-header/sticky-header.service';
import { LoaderService, PhotoUploadControlService } from 'services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormsModule, NgForm } from '@angular/forms';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { CcCdResponse, CcCdResponseDropdowns, PageOpenMode, PageSubmitMode } from 'models/api';
import { CcCdDropdowns, CcCdForm, CcCdSection } from 'models';
import { CommonModule } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MultipleFormsSectionComponent } from 'forms/shared/multiple-forms-section/multiple-forms-section.component';
import { OrderInfoComponent } from 'shared/order-info/order-info.component';
import { CcCdSectionComponent } from 'forms/cc-cd/cc-cd-section/cc-cd-section.component';
import { ccCdPhotoUploads } from 'utils';
import { CcCdTasksSectionComponent } from 'forms/cc-cd/cc-cd-tasks-section/cc-cd-tasks-section.component';
import { CcCdFeedModuleTasksSectionComponent } from 'forms/cc-cd/cc-cd-feed-module-tasks-section/cc-cd-feed-module-tasks-section.component';
import { CcCdPresentModuleTasksSectionComponent } from 'forms/cc-cd/cc-cd-present-module-tasks-section/cc-cd-present-module-tasks-section.component';
import { CcCdExternalAreaTasksSectionComponent } from 'forms/cc-cd/cc-cd-external-area-tasks-section/cc-cd-external-area-tasks-section.component';
import { CcCdVacuumingTasksSectionComponent } from 'forms/cc-cd/cc-cd-vacuuming-tasks-section/cc-cd-vacuuming-tasks-section.component';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { mapCcCdDropdowns, mapCcCdForm, mapCcCdSection } from 'models/api/maps/cc-cd/cc-cd-map';
import { photosStatuses } from 'app/utils/photo-status';
import { FormPageComponent } from 'pages/form-page/form-page';
import { RoutesEnum } from 'app/utils/navigation';

@Component({
  selector: 'ads-cc-cd-pm',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MultipleFormsSectionComponent,
    OrderInfoComponent,
    MatFormFieldModule,
    MatInputModule,
    MatButton,
    CcCdSectionComponent,
    CcCdTasksSectionComponent,
    CcCdFeedModuleTasksSectionComponent,
    CcCdPresentModuleTasksSectionComponent,
    CcCdExternalAreaTasksSectionComponent,
    CcCdVacuumingTasksSectionComponent,
    ExpansionPanelComponent,
    CommonTextAreaComponent,
  ],
  providers: [PhotoUploadControlService],
  templateUrl: './cc-cd-pm.component.html',
  styleUrl: './cc-cd-pm.component.scss',
})
export class CcCdPmComponent
  extends FormPageComponent<CcCdService, CcCdForm, CcCdResponse, CcCdDropdowns, CcCdResponseDropdowns>
  implements OnDestroy, AfterViewInit, OnInit
{
  @ViewChild('ccCdForm', { static: true }) override form?: NgForm;
  @ViewChild('ccCdsSectionPanel', { static: true }) ccCds?: ExpansionPanelComponent;
  @ViewChild('ccCdTasksSectionPanel', { static: true }) ccCdTasks?: ExpansionPanelComponent;
  @ViewChild('ccCdFeedModuleTasksSectionPanel', { static: true }) ccCdFeedModuleTasks?: ExpansionPanelComponent;
  @ViewChild('ccCdPresentModuleTasksSectionPanel', { static: true }) ccCdPresentModuleTasks?: ExpansionPanelComponent;
  @ViewChild('ccCdExternalAreaTasksSectionPanel', { static: true }) ccCdExternalAreaTasks?: ExpansionPanelComponent;
  @ViewChild('ccCdVacuumingBlowOutTasksSectionPanel', { static: true })
  ccCdVacuumingBlowOutTasks?: ExpansionPanelComponent;

  override sections = {};

  override sectionNames = [
    'ccCds',
    'ccCdTasks',
    'ccCdFeedModuleTasks',
    'ccCdPresentModuleTasks',
    'ccCdExternalAreaTasks',
    'ccCdVacuumingBlowOutTasks',
  ];
  override mapForm = mapCcCdForm;
  override mapDropdowns = mapCcCdDropdowns;
  override photoUploads = ccCdPhotoUploads;
  override overrideLoadForm = (data: CcCdResponse) => this.loadCcCdForm(data);

  constructor(
    public readonly ccCdService: CcCdService,
    headerService: HeaderService,
    route: ActivatedRoute,
    router: Router,
    stickyHeaderService: StickyHeaderService,
    loaderService: LoaderService,
    snackBar: MatSnackBar,
    private readonly pucs: PhotoUploadControlService
  ) {
    super(ccCdService, headerService, router, stickyHeaderService, loaderService, route, snackBar);
  }

  ngOnInit() {
    if (this.getIsFormSubmitted()) {
      this.form?.form.disable();
    } else {
      this.headerService.resetForm = this.resetForm;
      this.headerService.showResetFormButton.set(true);
    }

    this.sections = {
      ccCds: this.ccCds,
      ccCdTasks: this.ccCdTasks,
      ccCdFeedModuleTasks: this.ccCdFeedModuleTasks,
      ccCdPresentModuleTasks: this.ccCdPresentModuleTasks,
      ccCdExternalAreaTasks: this.ccCdExternalAreaTasks,
      ccCdVacuumingBlowOutTasks: this.ccCdVacuumingBlowOutTasks,
    };
  }

  ngOnDestroy(): void {
    this.stickyHeaderService.enabled = false;
  }

  ngAfterViewInit() {
    this.form?.control.valueChanges.subscribe((values) => (this.ccCdService.form = values));
    this.headerService.resetForm = this.resetForm;
    this.headerService.title.set('CC/CD Form');
    setTimeout(() => this.setDefault());
  }

  onAddClick() {
    this.service.ccCdSections.push(new CcCdSection(this.service.ccCdSections.length + 1));
    this.service.photoUploadForms.push(this.pucs.toFormGroup(ccCdPhotoUploads));
  }

  expandedList: number[] = [];

  override scrollToFirstInvalidNestedSection = (key: string) => {
    if (key === 'ccCds') {
      const sections = this.service.ccCdSections.map((x) => [this.getSection('ccCdSections#' + x.index), x.index]);
      const invalidSections = sections.filter((x) => (x[0] as FormGroup).invalid);
      const invalidPhotos = this.service.photoUploadForms
        .map((x, idx) => (x.invalid ? idx + 1 : -1))
        .filter((x) => x !== -1);
      if (invalidSections.length > 0 || invalidPhotos.length > 0) {
        this.expandedList = invalidSections.map((x) => x[1] as number);
        this.expandedList.push(...invalidPhotos);
        return this.expandedList[0];
      }
    }
    return -1;
  };

  getPhotosStatuses = () => photosStatuses(this.service.photoUploadForms);

  getSection(sectionName: string) {
    if (sectionName.includes('ccCdSection') && this.getIsFormSubmitted()) {
      this.form?.controls[sectionName]?.disable();
    }
    return this.form?.controls[sectionName] as FormGroup | undefined;
  }

  onRemoveCcCd(item: CcCdSection) {
    const indexAt = this.service.ccCdSections.indexOf(item);
    this.service.ccCdSections.splice(indexAt, 1);
    this.service.ccCdSections = this.service.ccCdSections.map((x, idx) => ({ ...x, index: idx + 1 }));
    this.service.photoUploadForms.splice(indexAt, 1);
  }

  loadCcCdForm = (data: CcCdResponse) => {
    data.currencyCounter.currencyCounterDiscriminatorItems.forEach((item, index) => {
      this.service.ccCdSections.push(mapCcCdSection(item, index + 1));
      this.service.photoUploadForms.push(this.pucs.toFormGroup(ccCdPhotoUploads));
    });
    this.form?.form.setValue({
      ccCdSections: {},
      ccCdTasks: this.service.form.ccCdTasks,
      ccCdFeedModuleTasks: this.service.form.ccCdFeedModuleTasks,
      ccCdPresentModuleTasks: this.service.form.ccCdPresentModuleTasks,
      ccCdExternalAreaTasks: this.service.form.ccCdExternalAreaTasks,
      ccCdVacuumingBlowOutTasks: this.service.form.ccCdVacuumingBlowOutTasks,
      comments: data.currencyCounter.comments,
    });
  };

  override saveForm = () =>
    this.sendForm(PageSubmitMode.PAGE_SUBMIT_MODE_SAVE, () => {
      this.openSnackBar('CC/CD Form saved successfully!', 'Close');
    });

  override onSubmit = () => {
    if (!this.workOrder) {
      return;
    }
    this.form?.form.markAllAsTouched();
    this.service.photoUploadForms.forEach((x) => x.markAllAsTouched());
    if (!this.form?.valid) {
      this.scrollToFirstInvalidSection();
      return;
    }
    this.sendForm(PageSubmitMode.PAGE_SUBMIT_MODE_SUBMIT, () =>
      this.router.navigate([`${RoutesEnum.Submitted}/${RoutesEnum.Atm}`])
    );
  };

  override sendForm = (pageSubmitMode: PageSubmitMode, callback: () => void) => {
    if (!this.workOrder) {
      return;
    }
    this.ccCdService
      .saveForm({
        comments: this.form?.value.comments,
        workOrderDetail: this.workOrder,
        pageSubmitMode: pageSubmitMode,
        pageOpenMode: this.workOrder.workStatus === null ? PageOpenMode.PAGE_MODE_ADD : PageOpenMode.PAGE_MODE_UPDATE,
        photosForm: {},
        fieldsForm: this.service.form,
      })
      .subscribe(callback);
  };
}
