import { CommonModule } from '@angular/common';
import { Component, Input, AfterViewInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { AudioVideoSectionFormComponent } from './audio-video-section-form/audio-video-section-form.component';
import { PhotoUploadControlService } from 'services';
import { SecurityService } from 'pages/security/security.service';
import { AudioVideoSectionForm } from 'models/security/audio-video/audioVideoSecurityForm';
import { AudioMatrixTasksComponent } from './audio-video-section-form/audio-matrix-tasks/audio-matrix-tasks.component';
import { NestedMultipleFormsSectionComponent } from 'forms/shared/nested-multiple-forms-section/nested-multiple-forms-section.component';
import { IndexableFormType } from 'app/utils/miscTypes';
import { MatInputModule } from '@angular/material/input';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { onAddItem, onRemoveItem } from 'app/utils/form-group-array';

export class AudioVideoFormSection implements IndexableFormType, AudioVideoSectionForm {
  index: number;
  audioMatrixModel: string | null;
  manufacturer: string | null;
  otherManufacturer: string | null;
  overallCondition: string | null;
  overallConditionComments: string | null;
  tellerConsolesAmount: string | null;
  wirelessHeadsetAmount: string | null;
  style: string | null;
  videoSystem: string | null;

  constructor(index: number) {
    this.index = index;
    this.audioMatrixModel = '';
    this.manufacturer = '';
    this.otherManufacturer = '';
    this.overallCondition = '';
    this.overallConditionComments = '';
    this.tellerConsolesAmount = '';
    this.wirelessHeadsetAmount = '';
    this.style = '';
    this.videoSystem = '';
  }
}

@Component({
  selector: 'ads-audio-video',
  standalone: true,
  imports: [
    CommonModule,
    SimpleFormComponent,
    FormsModule,
    MatInputModule,
    ExpansionPanelComponent,
    NestedMultipleFormsSectionComponent,
    AudioVideoSectionFormComponent,
    AudioMatrixTasksComponent,
    CommonTextAreaComponent,
  ],
  providers: [PhotoUploadControlService],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './audio-video.component.html',
})
export class AudioVideoComponent implements AfterViewInit {
  @Input() formGroup?: FormGroup | null;
  @Input() submitted = false;
  expandedList: number[] = [];
  photoUploadForm: FormGroup[] = [];

  constructor(public readonly security: SecurityService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      const tasksSection = this.formGroup?.controls['audioMatrixTasks'] as FormGroup;
      tasksSection.setValue(this.security.audioVideoData.audioMatrixTasks);
      this.formGroup?.controls['comments'].setValue(this.security.audioVideoData.comments);

      if (this.submitted) {
        this.formGroup?.disable();
      }
    });
  }

  getSection(sectionName: string) {
    return this.formGroup?.controls[sectionName] as FormGroup | undefined;
  }

  getFormControls(): Record<string, AbstractControl<string, string>> {
    const fg = this.formGroup?.get('audioVideoSections') as FormGroup;
    return fg ? fg.controls : {};
  }

  onAddClick = () => onAddItem(this.security.audioVideoData.audioVideoSections, (index) => new AudioVideoFormSection(index));

  onRemoveAudioVideo = (item: AudioVideoFormSection) => onRemoveItem(this.security.audioVideoData.audioVideoSections, item);

  getList() {
    return this.security.audioVideoData.audioVideoSections;
  }
}
