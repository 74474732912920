import { Component, Input, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'ads-custom-tooltip',
  standalone: true,
  imports: [
    MatTooltipModule,
    MatIconModule,
  ],
  templateUrl: './custom-tooltip.component.html',
  styleUrl: './custom-tooltip.component.scss',
})
export class CustomTooltipComponent {
  @Input() tooltip = '';
  @ViewChild('matTooltip') matTooltip?: MatTooltip;

  infoIconClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.matTooltip) {
      this.matTooltip.toggle();
    }
  }
}
