export enum YesNoNa {
  Na = 0,
  Yes = 1,
  No = 2,
}

export enum YesNo {
  No = 0,
  Yes = 1,
}
