<div
  class="phone-input-container"
  [formGroup]="parts"
  [attr.aria-labelledby]="_formField?.getLabelId()"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut($event)"
>
  <span [hidden]="!focused">(</span>
  <input
    class="phone-input-element"
    formControlName="area"
    size="3"
    maxLength="3"
    aria-label="Area code"
    (input)="_handleInput(parts.controls.area, exchange)"
    #area
    inputmode="tel"
  />
  <span [hidden]="!focused">)</span>
  <input
    class="phone-input-element"
    formControlName="exchange"
    maxLength="3"
    size="3"
    aria-label="Exchange code"
    (input)="_handleInput(parts.controls.exchange, subscriber)"
    (keyup.backspace)="autoFocusPrev(parts.controls.exchange, area)"
    #exchange
    inputmode="tel"
  />
  <span [hidden]="!focused">&ndash;</span>
  <input
    class="phone-input-element"
    formControlName="subscriber"
    maxLength="4"
    size="4"
    aria-label="Subscriber number"
    (input)="_handleInput(parts.controls.subscriber)"
    (keyup.backspace)="autoFocusPrev(parts.controls.subscriber, exchange)"
    #subscriber
    inputmode="tel"
  />
</div>
