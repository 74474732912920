<ng-container *ngIf="!showForm">
  <h4 *ngIf="!!openCalls[0]" class="title">Open calls in {{openCalls[0].center}} ({{openCalls.length}})</h4>
  <h4 *ngIf="!openCalls[0]" class="title">No open calls to show.</h4>
  <div class="travelling-form">
    <div class="work-order-list">
      <ads-work-order-card
        *ngFor="let call of openCalls"
        [call]="call"
        [currentTheme]="theming.watchTheme | async"
      ></ads-work-order-card>
    </div>
  </div>
  <div class="menu-actions">
    <ads-custom-button label="Cancel" (clicked)="hideAll()"></ads-custom-button>
    <ads-custom-button class="main-button confirm" label="Next" (clicked)="showForm = true"></ads-custom-button>
  </div>
</ng-container>
<ng-container *ngIf="showForm">
  <h4 class="title">Travelling</h4>
  <div class="travelling-form">
    <ads-datepicker [maxDate]="maxDate" [(ngModel)]="travellingDate" label="Date"></ads-datepicker>
    <ads-timepicker [dateValue]="travellingDate" (valueChange)="onTimeChange($event)"></ads-timepicker>
  </div>
  <div class="menu-actions">
    <ads-custom-button label="No travel" (clicked)="onNoTravel()"></ads-custom-button>
    <ads-custom-button label="Now"></ads-custom-button>
    <ads-custom-button class="main-button confirm" label="Confirm" (clicked)="onConfirm()"></ads-custom-button>
  </div>
</ng-container>
