<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->

<div class="container">
  <div class="login" (click)="loginPopup()" (keyup)="onKeyPress($event)">
    <div class="logo">
      <img src="assets/logo/logo-100x100.png" width="100" height="100" alt="CSG Logo" />
    </div>
    <button ID="btnLogin" class="login_button">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        height="50px"
        viewBox="0 0 578 522"
        class="SignInButton"
      >
        <rect x="150" y="129" width="122" height="122" fill="#F35325" />
        <rect x="284" y="129" width="122" height="122" fill="#81BC06" />
        <rect x="150" y="263" width="122" height="122" fill="#05A6F0" />
        <rect x="284" y="263" width="122" height="122" fill="#FFBA08" />
        <text x="470" y="357" fill="white" class="fnt0"></text>
      </svg>
      <span class="signintext">Sign in with Microsoft</span>
    </button>
  </div>
  <div class="footer-copyright">
    <span>&copy; {{ currentYear }}. Cook Solutions Group. All Rights Reserved.</span>
  </div>
</div>
