import { PhotoUpload } from './photo-upload';

export const ccCdPhotoUploadKeys = [
  'photoFront',
  'photoSerialNumber',
  'photoPrinter',
  'photoExternalDevices',
  'photoAdditionalPhoto',
] as const;

export type CcCdPhotoUploadKey = (typeof ccCdPhotoUploadKeys)[number];

export const ccCdPhotoUploads: PhotoUpload<CcCdPhotoUploadKey>[] = [
  {
    label: 'Front',
    key: 'photoFront',
    required: true,
  },
  {
    label: 'Serial number label',
    key: 'photoSerialNumber',
    required: true,
  },
  {
    label: 'Printer (optional)',
    key: 'photoPrinter',
    required: false,
  },
  {
    label: 'Other external devices (optional)',
    key: 'photoExternalDevices',
    required: false,
  },
  {
    label: 'Additional photo (optional)',
    key: 'photoAdditionalPhoto',
    required: false,
  },
];
