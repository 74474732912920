import { VaultLists } from 'forms/security/vault/vault-section-form/vault-section-form.component';
import { SecurityVaultDropdowns } from 'models/security/security-dropdowns';

export const mapVaultDropdowns = (dropdowns: SecurityVaultDropdowns): VaultLists => ({
  manufacturerList: dropdowns.vaultVaultVaultManufacturers.map((item) => ({
    name: item.manufacturerName,
    value: item.manufacturerName,
  })),
  creepDoorClosesList: dropdowns.vaultVaultOverallCreep.map((item) => ({
    name: item.name,
    value: item.name,
  })),
  overallConditionsList: dropdowns.vaultVaultOverallConditionsVault.map((item) => ({
    name: item.name,
    value: item.name,
  })),
  typesList: dropdowns.vaultVaultSwingTypes.map((item) => ({
    name: item.name,
    value: item.name,
  })),
});
