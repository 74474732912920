<mat-form-field class="common-form-field" appearance="outline" hideRequiredMarker>
  <input
    #customInput="ngModel"
    matInput
    adsRequired
    [checkRequired]="required"
    [required]="required"
    [disabled]="getDisabled() ? 'disabled' : false"
    [name]="name"
    [maxLength]="maxLength"
    [ngModelOptions]="{ standalone: true }"
    [(ngModel)]="value"
    (ngModelChange)="onModelChange($event)"
    (blur)="onBlur()"
    [id]="ngModelGroup.name"
    adsNumberInput
    [shouldModify]="type === 'number'"
    [minValue]="minValue"
  />
</mat-form-field>
