import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ServiceCallsService } from '../service-calls.service';
import { DateFilteringComponent } from './date-filtering/date-filtering.component';
import { MobileSortingComponent } from './mobile-sorting/mobile-sorting.component';
import { DesktopSortingComponent } from './desktop-sorting/desktop-sorting.component';
import { RadioFilteringComponent } from './radio-filtering/radio-filtering.component';
import { CommonFormInputComponent } from 'shared/material-wrappers/common-form-input/common-form-input.component';
import { MobileDateFilteringComponent } from './mobile-filtering/mobile-date-filtering/mobile-date-filtering.component';
import { MobileRadioFilteringComponent } from './mobile-filtering/mobile-radio-filtering/mobile-radio-filtering.component';
import { AdsCallControl } from 'models';
import { debounce } from 'app/decorators/debounce.decorator';

export interface DataRange {
  min: number | string | Date;
  max: number | string | Date;
}

export interface SortCriteria {
  column: string;
  asc: boolean;
}

@Component({
  selector: 'ads-service-calls-header',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CommonFormInputComponent,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MobileSortingComponent,
    DesktopSortingComponent,
    RadioFilteringComponent,
    DateFilteringComponent,
    MobileDateFilteringComponent,
    MobileRadioFilteringComponent,
  ],
  templateUrl: './service-calls-header.component.html',
  styleUrl: './service-calls-header.component.scss',
})
export class ServiceCallsHeaderComponent {
  @Input() isDesktopView = false;
  @Input() minDate?: Date;
  @Input() calls: AdsCallControl[] = [];
  @Input() allCalls: AdsCallControl[] = [];
  @Output() filterChanged = new EventEmitter<void>();
  @Output() sortingChanged = new EventEmitter<SortCriteria>();
  scheduledFromDate?: Date;
  scheduledToDate?: Date;
  onSiteByFromDate?: Date;
  onSiteByToDate?: Date;

  maxDate = new Date();
  filterCriteria = new Map<string, DataRange>();

  @ViewChild('scheduledFilter') scheduledFilter: DateFilteringComponent | undefined;
  @ViewChild('onSiteFilter') onSiteFilter: DateFilteringComponent | undefined;

  constructor(public service: ServiceCallsService) {
    this.maxDate.setMonth(this.maxDate.getMonth() + 1);
  }

  @debounce(750)
  onSearchChanged() {
    this.sortingChanged.emit();
  }

  onSortingChanged() {
    this.sortingChanged.emit();
  }

  onScheduledDateFilterChanged() {
    this.service.scheduledDateFilter.minValue = this.scheduledFromDate;
    this.service.scheduledDateFilter.maxValue = this.scheduledToDate;
    this.filterChanged.emit();
  }

  onOnSiteByDateFilterChanged() {
    this.service.onSiteByDateFilter.minValue = this.onSiteByFromDate;
    this.service.onSiteByDateFilter.maxValue = this.onSiteByToDate;
    this.filterChanged.emit();
  }

  onStatusFilterChanged(someStatus: string) {
    this.service.statusFilter.value = someStatus;
    this.filterChanged.emit();
  }

  onPriorityFilterChanged(somePriority: string) {
    this.service.priorityFilter.value = somePriority;
    this.filterChanged.emit();
  }

  clearFilters() {
    this.service.clearFilters();
    this.scheduledFilter?.clearDates();
    this.onSiteFilter?.clearDates();
    this.filterChanged.emit();
  }
}
