export class SelectedEquipment {
  alarm: boolean;
  surveillance: boolean;
  vault: boolean;
  safes: boolean;
  ucs: boolean;
  nightDrop: boolean;
  accessControlHardware: boolean;
  dealDrawer: boolean;
  rts: boolean;
  audioVideoDU: boolean;

  constructor() {
    this.alarm = false;
    this.surveillance = false;
    this.vault = false;
    this.safes = false;
    this.ucs = false;
    this.nightDrop = false;
    this.accessControlHardware = false;
    this.dealDrawer = false;
    this.rts = false;
    this.audioVideoDU = false;
  }
}

export enum Equipment {
  Alarm = 'Alarm',
  Surveillance = 'Surveillance',
  Vault = 'Vault',
  Safes = 'Safes',
  UCS = 'UCS',
  NightDrop = 'Night Drop',
  AccessControlHardware = 'Access Control Hardware',
  DealDrawer = 'Deal Drawer',
  RTS = 'RTS',
  AudioVideoDU = 'Audio/Video DU',
}
