import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceCallsService } from '../sorting.service';
import { CommonModule } from '@angular/common';
import { RadioValuesComponent } from 'shared/radio-values/radio-values.component';
import { RadioPromptComponent } from 'shared/radio-prompt/radio-prompt.component';
import { SelectOption } from 'models';

@Component({
  selector: 'ads-desktop-sorting',
  standalone: true,
  imports: [CommonModule, RadioValuesComponent, RadioPromptComponent],
  templateUrl: './desktop-sorting.component.html',
  styleUrl: './desktop-sorting.component.scss',
})
export class DesktopSortingComponent implements OnInit {
  @Input() label = '';
  @Output() sortChanged = new EventEmitter<void>();
  showMenu = false;
  desktopSortingOptions: SelectOption<string>[] = [];

  constructor(public service: ServiceCallsService) {}

  ngOnInit(): void {
    this.desktopSortingOptions = this.service.sortOptions.map((option) => ({
      value: option.value,
      name: option.name,
    }));
  }

  setSort(column: string, asc: boolean, sortingLabel: string) {
    this.service.setSort(column, asc, sortingLabel);
  }

  onDismissed() {
    this.service.sorting.tempSortCriteria = this.service.sorting.activeSortCriteria;
  }

  onConfirmClick() {
    this.service.onConfirmSort();
    this.service.onSortDismissed();
    this.sortChanged.emit();
  }

  onCancelClick() {
    this.onDismissed();
  }

  onSortingAscClicked(_: { column: string; asc: boolean; sortingLabel: string }) {
    const { column, asc, sortingLabel } = _;
    this.service.setSort(column, asc, sortingLabel);
  }

  onColumnSelected(column: string | (string | undefined)[] | undefined) {
    if (column) {
      this.setSort(column as string, true, this.service.sortOptions.find((option) => option.value === column)?.ascLabel ?? '');
    }
  }
}
