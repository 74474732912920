<div class="desktop-nav-container">
  <div class="buttons-container">
    <ads-nav-button title="Calls" icon="call" [navIndex]="1" [isDesktop]="true"></ads-nav-button>
    <mat-divider></mat-divider>
    <ads-nav-button title="Lines" icon="reorder" [navIndex]="2" [isDesktop]="true"></ads-nav-button>
    <mat-divider></mat-divider>
    <ads-nav-button title="Notes" icon="description" [navIndex]="3" [isDesktop]="true"></ads-nav-button>
    <mat-divider></mat-divider>
    <ads-nav-button title="Calendar" icon="calendar_month" [navIndex]="4" [isDesktop]="true"></ads-nav-button>
    <mat-divider></mat-divider>
    <ads-nav-button title="History" icon="history" [navIndex]="5" [isDesktop]="true"></ads-nav-button>
    <mat-divider></mat-divider>
    <ads-nav-button title="Centran" icon="dns" [navIndex]="6" [isDesktop]="true"></ads-nav-button>
    <mat-divider></mat-divider>
    <ads-nav-button title="My Open Calls" icon="phonelink_ring" [navIndex]="7" [isDesktop]="true"></ads-nav-button>
    <mat-divider></mat-divider>
    <ads-nav-button title="ATM Down Overnight" icon="cloud_off" [navIndex]="8" [isDesktop]="true"></ads-nav-button>
    <mat-divider></mat-divider>
    <ads-nav-button title="Other Techs" icon="dynamic_feed" [navIndex]="9" [isDesktop]="true"></ads-nav-button>
    <mat-divider></mat-divider>
    <ads-nav-button title="Inventory" icon="inventory" [navIndex]="10" [isDesktop]="true"></ads-nav-button>
    <mat-divider></mat-divider>
    <ads-nav-button title="Part Status" icon="table" [navIndex]="11" [isDesktop]="true"></ads-nav-button>
  </div>

  <button
    mat-icon-button
    aria-label="expand menu"
    (click)="layoutNavService.toggleSideNav()"
    class="expand-button"
    *ngIf="!layoutNavService.sideNavOpened"
  >
    <mat-icon class="material-icons-outlined">menu_open</mat-icon>
  </button>
  <div style="margin-bottom: 60px" *ngIf="layoutNavService.sideNavOpened">
    <ads-profile-name></ads-profile-name>
    <ads-logout-footer></ads-logout-footer>
  </div>
</div>
