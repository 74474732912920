export class TimeClockTasks {
  checkTimeLocksForProperOperation: number | null;
  pullRightTimeClockForCleaning: number | null;
  shiftAllTimeClocksRight: number | null;
  replaceLeftTimeClock: number | null;
  markAnyReplacedTimeClocksWithDateInstalled: number | null;

  constructor() {
    this.checkTimeLocksForProperOperation = null;
    this.pullRightTimeClockForCleaning = null;
    this.shiftAllTimeClocksRight = null;
    this.replaceLeftTimeClock = null;
    this.markAnyReplacedTimeClocksWithDateInstalled = null;
  }
}

export class ComboLockTasks {
  checkComboLockForProperOperation: number | null;
  disassembleAndInspectComboLock: number | null;
  lubricateAndReassembleComboLock: number | null;
  checkForDialAndRingProperOperation: number | null;
  disassembleAndInspectDialAndRing: number | null;

  constructor() {
    this.checkComboLockForProperOperation = null;
    this.disassembleAndInspectComboLock = null;
    this.lubricateAndReassembleComboLock = null;
    this.checkForDialAndRingProperOperation = null;
    this.disassembleAndInspectDialAndRing = null;
  }
}
