export class AtmFasciaAdaTasks {
  cleanCustomerInterfaceArea: number | null;
  adaStickersPresentReadable: number | null;
  cleanToneVolume: number | null;
  testVoiceGuidance: number | null;
  testKeypadFunctionKeys: number | null;
  checkAlignCardReader: number | null;
  checkAlignOperDispShutter: number | null;
  checkAlignReceiptPrinter: number | null;
  cleanOperatorPanel: number | null;
  cleanEntireExterior: number | null;
  verifyTouchScreen: number | null;
  testOpenCloseIndicator: number | null;
  replaceLightBulbs: number | null;
  antiSkimmingInDeviceConfigMode: number | null;
  topHatLightOperational: number | null;

  constructor() {
    this.cleanCustomerInterfaceArea = null;
    this.adaStickersPresentReadable = null;
    this.cleanToneVolume = null;
    this.testVoiceGuidance = null;
    this.testKeypadFunctionKeys = null;
    this.checkAlignCardReader = null;
    this.checkAlignOperDispShutter = null;
    this.checkAlignReceiptPrinter = null;
    this.cleanOperatorPanel = null;
    this.cleanEntireExterior = null;
    this.verifyTouchScreen = null;
    this.testOpenCloseIndicator = null;
    this.replaceLightBulbs = null;
    this.antiSkimmingInDeviceConfigMode = null;
    this.topHatLightOperational = null;
  }
}
