import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { RoutesEnum } from 'app/utils/navigation';
import { formNamesMap } from 'utils';

@Component({
  selector: 'ads-submit-redirect',
  standalone: true,
  imports: [MatButtonModule, RouterLink],
  templateUrl: './submit-redirect.component.html',
  styleUrl: './submit-redirect.component.scss',
})
export class SubmitRedirectComponent {
  formName = '';

  constructor(private readonly route: ActivatedRoute) {
    window.scrollTo(0, 0);

    this.route.paramMap.subscribe((params) => {
      this.formName = formNamesMap[params.get('formSubmitted') ?? ''] as RoutesEnum;
    });
  }

  goBack = () => {
    window.top?.close();
  };
}
