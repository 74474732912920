<ads-call-control-filters [isDesktopView]="isDesktopView"></ads-call-control-filters>
<div class="calls-list">
  <div *ngFor="let call of calls" class="call-container" [ngClass]="{'dark-theme': theming.theme.name === 'dark'}">
    <div class="call-header">
      <h3 class="work-order">{{ call.workorder }}, {{ call.city }}</h3>
      <span
        class="status-chip"
        [ngStyle]="{background: statusColors.get(call.laststatusname ?? 'Started')}"
        [ngClass]="{black: call.laststatusname === 'Scheduled'}">
        {{ call.laststatusname }}
      </span>
    </div>
    <div class="call-description-row">
      <mat-icon class="material-icons-outlined">bolt</mat-icon><span class="time-range">{{ call.priority }}</span>
      <span *ngIf="isDesktopView" class="description-row-end">
        <mat-icon class="material-icons-outlined">description</mat-icon><span>{{ call.customerpo }}</span>
      </span>
    </div>
    <div class="call-details-row">
      <span class="detail-content">
        <span class="line">{{ call.calltype }}</span>
        <span class="line">{{ call.problem }}</span>
      </span>
      <div *ngIf="isDesktopView" class="details-row-end">
        <div *ngIf="call.ticketRecId">
          <mat-icon class="material-icons-outlined">confirmation_number</mat-icon><span>{{ call.ticketRecId }}</span>
        </div>
        <div><mat-icon class="material-icons-outlined">map</mat-icon><span>{{call.center}}</span></div>
      </div>
    </div>
    <div class="call-footer">
      <span class="center">{{call.customer}}</span>
      <div class="call-footer-end">
        <span *ngIf="isDesktopView" class="gray-out"> <mat-icon>event</mat-icon>{{call.createddatetime}}</span>
        <span><mat-icon>event</mat-icon>{{call.dateopened}}</span>
      </div>
    </div>
  </div>
</div>
