import { SecurityUCSDto } from 'models/api/security/security';
import { SecurityUCSListUCSDto } from 'models/api/security/ucs/ucs-list-dto';
import { UCSSecurityForm } from 'models/security';

export const mapSecurityUCSRequest = (ucsList: SecurityUCSListUCSDto[]): SecurityUCSListUCSDto[] => {
  return ucsList.map((panel) => ({
    ucsModel: panel.ucsModel,
    ucsManufacturer: panel.ucsManufacturer,
    ucsManufacturer_Other: panel.ucsManufacturer_Other,
    overallConditionUCS: panel.overallConditionUCS,
    overallConditionUCS_Other: panel.overallConditionUCS_Other,
    quantityComboLocks: panel.quantityComboLocks,
    quantityOfPortaLocks: panel.quantityOfPortaLocks,
  }));
};

export const mapUcsRequest = (ucs: UCSSecurityForm | null): SecurityUCSDto => ({
  security_Ucs: {
    security_Ucs_Ucs: mapSecurityUCSRequest(ucs?.ucsList ?? []),
    security_Ucs_Ucs_Tasks: [
      {
        checkForProperOperation: ucs?.ucsTasks.checkForProperOperation ?? null,
        inspectForExcessiveWear: ucs?.ucsTasks.inspectForExcessiveWear ?? null,
        cleanAndLubricate: ucs?.ucsTasks.cleanAndLubricate ?? null,
      },
    ],
    additionalComments: ucs?.comments ?? null,
  },
});
