import { CompleteNa } from '../shared/complete-na';

export class AtmCleaningTasks {
  allNa: boolean;
  thoroughInspection: CompleteNa | null;
  fasciaTrim: CompleteNa | null;
  customerDisplay: CompleteNa | null;
  functionKeys: CompleteNa | null;
  atmSidesBackAndTop: CompleteNa | null;
  atmArea: CompleteNa | null;
  vacuumSafe: CompleteNa | null;
  vacuumTopHalf: CompleteNa | null;
  vacuumAllFans: CompleteNa | null;
  vacuumAllFilters: CompleteNa | null;

  constructor() {
    this.allNa = false;
    this.thoroughInspection = null;
    this.fasciaTrim = null;
    this.customerDisplay = null;
    this.functionKeys = null;
    this.atmSidesBackAndTop = null;
    this.atmArea = null;
    this.vacuumSafe = null;
    this.vacuumTopHalf = null;
    this.vacuumAllFans = null;
    this.vacuumAllFilters = null;
  }
}
