import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkOrderDetailDto, WorkStatus } from 'models/api';

@Component({
  selector: 'ads-order-info',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './order-info.component.html',
  styleUrl: './order-info.component.scss',
})
export class OrderInfoComponent {
  @Input() order?: WorkOrderDetailDto;
  @Input() customerName = '';

  getOrderStatus = () => {
    switch (this.order?.workStatus) {
      case WorkStatus.New:
        return 'New';
      case WorkStatus.Draft:
        return 'Draft';
      case WorkStatus.Submitted:
        return 'Submitted';
      default:
        return 'New';
    }
  };
}
