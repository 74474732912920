<div *ngIf="!layoutNavService.isDesktopView()" class="mobile-cards">
  <ads-part-card
    *ngFor="let item of dataSource.data; let i = index"
    [item]="item"
    (removeClicked)="equipmentRemoved.emit(i)"
    (toggled)="onCardSelect(item)"
  ></ads-part-card>
</div>
<div *ngIf="layoutNavService.isDesktopView()" class="table">
  <table mat-table [dataSource]="dataSource.data">
    <ng-container *ngIf="selection !== undefined" matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="toggleAllRows($event)"
          [checked]="selection.hasValue() && selection.selected.length === dataSource.data.length"
          [indeterminate]="selection.hasValue() && selection.selected.length !== dataSource.data.length"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row; let i = index">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="onSelect($event, row, i)"
          onKeyPress="event.stopPropagation"
          [checked]="selection.isSelected(row) || isSelected(row)"
          [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">{{ element.Date | date: 'MM/dd/yyyy' }}</td>
    </ng-container>

    <ng-container matColumnDef="part">
      <th mat-header-cell *matHeaderCellDef>Part</th>
      <td mat-cell *matCellDef="let element">{{ element.Part ?? '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef>Quantity</th>
      <td mat-cell *matCellDef="let element">{{ element.Quantity ?? '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">{{ element.Description ?? '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element">{{ element.Type ?? '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="transferType">
      <th mat-header-cell *matHeaderCellDef>Transfer Type</th>
      <td mat-cell *matCellDef="let element">{{ element.transferType ?? '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="addedBy">
      <th mat-header-cell *matHeaderCellDef>Added By</th>
      <td mat-cell *matCellDef="let element">{{ element.AddedBy ?? '-' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToShow"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columnsToShow"
      (click)="selection?.toggle(row)"
      onKeyPress="event.stopPropagation"
    ></tr>
  </table>
</div>
