import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { DialogLogoutComponent } from './dialog-logout/dialog-logout.component';

@Component({
  selector: 'ads-logout-footer',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './logout-footer.component.html',
  styleUrl: './logout-footer.component.scss',
})
export class LogoutFooterComponent {
  readonly dialog = inject(MatDialog);
  currentYear = new Date().getFullYear();

  openDialog() {
    this.dialog.open(DialogLogoutComponent);
  }
}
