<ads-simple-form #rts="ngModelGroup" ngModelGroup="rts">
  <ng-container ngModelGroup="rtsPanels">
    <ads-nested-multiple-forms-section
      #rtsSectionsPanels
      title="RTS Panel"
      [formTemplate]="formTemplate"
      [list]="security.rtsData.rtsSections"
      [formControls]="getFormControls()"
      [groupName]="'rtsPanel'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddClick()"
      (deleteClicked)="onRemoveRts($event)"
    >
    </ads-nested-multiple-forms-section>
    <ng-template #formTemplate let-item="item">
      <ads-rts-section-form
        [rtsLists]="security.rtsData.rtsDropdowns"
        [section]="getSection('rtsPanel#' + item.index)"
        [item]="item"
      ></ads-rts-section-form>
    </ng-template>
  </ng-container>

  <ads-expansion-panel
    #sensorsTasksPanel
    title="Teller Unit"
    [formGroup]="formGroup"
    [name]="'tellerUnit'"
    [class]="'tellerUnit'"
  >
    <ads-teller-unit [section]="getSection('tellerUnit')"></ads-teller-unit>
  </ads-expansion-panel>

  <ads-expansion-panel
    #sensorsTasksPanel
    title="Customer Unit"
    [formGroup]="formGroup"
    [name]="'customerUnit'"
    [class]="'customerUnit'"
  >
    <ads-customer-unit [section]="getSection('customerUnit')"></ads-customer-unit>
  </ads-expansion-panel>
  <ads-common-text-area
    name="comments"
    label="Comments (optional)"
    [maxLength]="200"
    ngModel
  ></ads-common-text-area>
</ads-simple-form>
