<div class="container">
  <span
    class="name"
    [class.name-mobile]="!headerService.isDesktopView()"
    [class.name-desktop]="headerService.isDesktopView()"
    >{{ authService.user?.fullName }}</span
  >
  <mat-icon
    *ngIf="showIcon"
    class="material-icons-outlined"
    [class.icon-mobile]="!headerService.isDesktopView()"
    [class.icon-desktop]="headerService.isDesktopView()"
    >account_circle</mat-icon
  >
</div>
