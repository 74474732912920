import { CompleteNa } from 'models/shared/complete-na';

export class DealDrawerTasks {
  allNa: boolean;
  testMotorizedOperation: CompleteNa | null;
  testManualOperation: CompleteNa | null;
  testDrawerMotor: CompleteNa | null;
  testDrawerTracks: CompleteNa | null;
  cleanDrawerTracks: CompleteNa | null;
  testSwitches: CompleteNa | null;
  testHeaterAssy: CompleteNa | null;
  cleanUnit: CompleteNa | null;

  constructor() {
    this.allNa = false;
    this.cleanDrawerTracks = null;
    this.cleanUnit = null;
    this.testDrawerMotor = null;
    this.testDrawerTracks = null;
    this.testHeaterAssy = null;
    this.testManualOperation = null;
    this.testMotorizedOperation = null;
    this.testSwitches = null;
  }
}
