import { CompleteNa } from 'models/shared/complete-na';

export class CustomerUnitForm {
  allNa: boolean;
  inspectDoorMotors: CompleteNa | null;
  testWindgateAssy: CompleteNa | null;
  testMotorStarter: CompleteNa | null;
  testBlowerAssy: CompleteNa | null;
  testSafetyDevices: CompleteNa | null;
  testCallSwitches: CompleteNa | null;
  testCarrierLimitSwitches: CompleteNa | null;
  testRelays: CompleteNa | null;
  testBumperPads: CompleteNa | null;
  testIndicatorLights: CompleteNa | null;
  testCarrierCatch: CompleteNa | null;
  cleanOutside: CompleteNa | null;
  vaccumInside: CompleteNa | null;

  constructor() {
    this.allNa = false;
    this.inspectDoorMotors = null;
    this.testWindgateAssy = null;
    this.testMotorStarter = null;
    this.testBlowerAssy = null;
    this.testSafetyDevices = null;
    this.testCallSwitches = null;
    this.testCarrierLimitSwitches = null;
    this.testRelays = null;
    this.testBumperPads = null;
    this.testIndicatorLights = null;
    this.testCarrierCatch = null;
    this.cleanOutside = null;
    this.vaccumInside = null;
  }
}
