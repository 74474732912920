import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, Optional, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgModel,
  NgModelGroup,
  TouchedChangeEvent,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CustomTooltipComponent } from '../custom-tooltip/custom-tooltip.component';

@Component({
  selector: 'ads-datepicker',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    CustomTooltipComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './datepicker.component.html',
  styleUrl: './datepicker.component.scss',
  providers: [
    provideNativeDateAdapter(),
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DatePickerComponent,
    },
  ],
})
export class DatePickerComponent implements AfterViewInit, ControlValueAccessor {
  @Input() name = '';
  @Input() label = '';
  @Input() tooltip = '';
  @Input() required = false;
  @Input() value?: Date | null = new Date();
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @ViewChild('customInput') customInput?: NgModel;
  @ViewChild('picker') picker?: NgModel;
  @ViewChild(MatDatepicker) datepicker?: MatDatepicker<Date>;

  touched = false;
  disabled = false;
  formControl = new FormControl('', []);

  constructor(@Optional() private readonly ngModelGroup: NgModelGroup) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.ngModelGroup?.control) {
        this.ngModelGroup.control.registerControl(this.name, this.picker);

        this.ngModelGroup.control.controls[this.name].events.subscribe((value) => {
          if ((value as TouchedChangeEvent).touched) {
            this.customInput?.control.updateValueAndValidity();
          }
        });

        this.ngModelGroup.control.controls[this.name].valueChanges.subscribe((value) => {
          if (value === null) {
            this.picker?.control?.setValue(null);
            this.picker?.control?.markAsPristine();
            this.picker?.control?.markAsUntouched();
            this.touched = false;
          }

          if (value) {
            this.datepicker!.disabled = true;
            this.datepicker!.disabled = false;
          }
        });
      }
    });
  }

  getDisabled = () => this.disabled || this.ngModelGroup.disabled;

  onChange = () => {};

  onTouched = () => {};

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  propagateChange = (value: Date | null) => {
    this.writeValue(value);
  };

  writeValue(newValue: Date | null): void {
    this.value = newValue;
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  onModelChange(val: Date | null) {
    this.propagateChange(val);
  }

  getPicker() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.picker as any;
  }

  previousDaysFilter(date: Date | null): boolean {
    return date ? date < new Date() : false;
  }
}
