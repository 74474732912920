import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { environment } from 'environment/environment';
import { HomeService } from '../home.service';
import { MatButtonModule } from '@angular/material/button';
import { CallsContainerComponent } from "../calls-container/calls-container.component";

@Component({
  selector: 'ads-buttons-container',
  standalone: true,
  imports: [MatIconModule, MatMenuModule, CommonModule, MatButtonModule, CallsContainerComponent],
  templateUrl: './buttons-container.component.html',
  styleUrl: './buttons-container.component.scss',
})
export class ButtonsContainerComponent {
  @Input() isDesktop = false;
  @Input() newCalls = 0;
  @Input() openCalls = 0;

  constructor(private readonly homeService: HomeService) {}

  navigateToCC() {
    this.homeService.navigateCCUrl();
  }

  navigateEmail(): void {
    window.open('https://outlook.office.com', '_blank', 'noopener');
  }

  navigateKaba(): void {
    window.open(
      `${environment.apiConfig.powerAppUrl}${environment.apiConfig.kabaRequest}?source=iframe&authMode=Iframe`,
      '_blank',
      'noopener'
    );
  }

  navigateRMA(): void {
    window.open(
      `${environment.apiConfig.powerAppUrl}${environment.apiConfig.rmaRequest}?source=iframe&authMode=Iframe`,
      '_blank',
      'noopener'
    );
  }
}
