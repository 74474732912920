import { getAreAllNa } from 'app/utils/areAllNa';
import { RtsFormSection } from 'forms/security/rts/rts.component';
import { RtsLists } from 'forms/security/rts/rts.models';
import { SecurityRtsCustomerUnitDto } from 'models/api/security/rts/customer-unit-dto';
import { SecurityRtsSectionDto } from 'models/api/security/rts/rts-dto';
import { SecurityRtsTellerUnitDto } from 'models/api/security/rts/teller-unit-dto';
import { SecurityRtsSectionsDto } from 'models/api/security/security';
import { CustomerUnitForm } from 'models/security/rts/customerUnit';
import { RtsSecurityForm } from 'models/security/rts/rtsSecurityForm';
import { TellerUnitForm } from 'models/security/rts/tellerUnit';
import { SecurityRtsDropdowns } from 'models/security/security-dropdowns';

export const mapRtsSectionsResponse = (panels: SecurityRtsSectionDto[]): RtsFormSection[] => {
  if (panels.length === 0) {
    return [new RtsFormSection(1)];
  }
  return panels.map((panel, index) => ({
    rtsModel: panel.rtsModel,
    laneNumber: panel.lane ? panel.lane.toString() : null,
    overallCondition: panel.overallConditionRTS,
    overallConditionComments: panel.equipment_Rating_RTS,
    tubeLength: panel.lengthofTubeRunIfNotStandardConfiguration ? panel.lengthofTubeRunIfNotStandardConfiguration.toString() : null,
    rtsMake: panel.rtsMake,
    rtsMakeOther: panel.rtsMake_Other,
    upsendOrDownsend: panel.upsendDownsend,
    index: index + 1,
  }));
};

export const mapTellerUnitResponse = (data: SecurityRtsTellerUnitDto[]): TellerUnitForm => ({
  allNa: getAreAllNa(data[0]),
  cleanOutside: data[0]?.cleanOutsideOfUnitWithAppropriateCleaner ?? null,
  inspectDoorMotors: data[0]?.inspectAndTestDoorMotors ?? null,
  sendRecallSwitches: data[0]?.inspectAndTestSendrecallSwitches ?? null,
  testBumperPads: data[0]?.inspectAndTestBumperAndPads ?? null,
  testCarrierCatch: data[0]?.inspectAndTestCarrierArrivalCatch ?? null,
  testCarrierSwitches: data[0]?.inspectAndTestcarrierLimitSwitches ?? null,
  testIndicatorLights: data[0]?.inspectAndTestIndicatorLights ?? null,
  testRelays: data[0]?.inspectAndTestRelays ?? null,
  vaccumInside: data[0]?.vacuumInsideOfUnit ?? null,
});

export const mapCustomerUnitResponse = (data: SecurityRtsCustomerUnitDto[]): CustomerUnitForm => ({
  allNa: getAreAllNa(data[0]),
  cleanOutside: data[0]?.cleanoutSideOfUnitWithAppropriateCleaner ?? null,
  inspectDoorMotors: data[0]?.inspectAndTestDoorMotors ?? null,
  testBumperPads: data[0]?.inspectAndTestBumperAndPads ?? null,
  testCarrierCatch: data[0]?.inspectAndTestCarrierArrivalCatch ?? null,
  testCarrierLimitSwitches: data[0]?.inspectAndTestCarrierLimitSwitches ?? null,
  testIndicatorLights: data[0]?.inspectAndTestIndicatorLights ?? null,
  testRelays: data[0]?.inspectAndTestRelays ?? null,
  vaccumInside: data[0]?.vacuumInsideOfUnit ?? null,
  testBlowerAssy: data[0]?.inspectAndTestBlowerAssy ?? null,
  testCallSwitches: data[0]?.inspectAndTestSendCallSwitches ?? null,
  testMotorStarter: data[0]?.inspectAndTestMotorStarter ?? null,
  testSafetyDevices: data[0]?.inspectAndTestSafetyDevices ?? null,
  testWindgateAssy: data[0]?.inspectAndTestWindgateAssy ?? null,
});

export const mapSecurityRtsResponse = (data: SecurityRtsSectionsDto): RtsSecurityForm => ({
  rtsPanels: mapRtsSectionsResponse(data.security_RTS_RTS),
  tellerUnit: mapTellerUnitResponse(data.security_RTS_TellerUnit),
  customerUnit: mapCustomerUnitResponse(data.security_RTS_CustomerUnit),
  comments: data.comments ?? null,
});

export const mapRtsDropdowns = (dropdowns: SecurityRtsDropdowns): RtsLists => ({
  overallConditionList: dropdowns.rtsRtsOverallConditions.map((item) => ({
    name: item.name,
    value: item.name,
  })),
  rtsRtsMakeList: dropdowns.rtsRtsMake.map((item) => ({
    name: item.manufacturerName,
    value: item.manufacturerName,
  })),
  upsendDownSendList: dropdowns.rtsRtsUpsendDownsend.map((item) => ({
    name: item.name,
    value: item.name,
  })),
});
