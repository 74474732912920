import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { FormsModule, FormGroup, NgModelGroup, ControlContainer, NgForm } from '@angular/forms';
import { MatDivider } from '@angular/material/divider';
import { CompleteNa, SelectOption, YesNo } from 'models';
import { RadioButtonGroupComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-tcd-tcr-tasks',
  standalone: true,
  imports: [CommonModule, FormsModule, SimpleFormComponent, RadioButtonGroupComponent, MatDivider],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './tcd-tcr-tasks.component.html',
})
export class TcdTcrTasksComponent {
  @Input() section?: FormGroup;

  constructor(@Optional() public readonly ngModelGroup: NgModelGroup) {}

  yesNoOptions: SelectOption<YesNo>[] = [
    { name: 'Yes', value: YesNo.Yes },
    { name: 'No', value: YesNo.No },
  ];

  completeNaOptions: SelectOption<CompleteNa>[] = [
    { name: 'Complete', value: CompleteNa.Complete },
    { name: 'N/A', value: CompleteNa.Na },
  ];
}
