import { SecurityDropdowns } from 'models';

export const initialSecurityDropdowns: SecurityDropdowns = {
  alarm: {
    alarmAlarmPanelsAlarmManufacturers: [],
    alarmAlarmPanelOverallCondition: [],
    alarmAlarmPanelCentralStation: [],
    alarmPathTablePathTypes: [],
    alarmPathTableCommTypes: [],
    alarmHubTasksTellerHoldUpTypesTypes: [],
    alarmHubTasksPlatformHoldUpTypesTypes: [],
  },
  surveillance: {
    surveillanceDvrDvrManufacturers: [],
    surveillanceDVRNVROverallConditions: [],
    surveillanceUpsManufacturers: [],
    surveillancePoeSwitchManufacturers: [],
    surveillanceCameraTableCameraType: [],
    surveillanceCameraTableIsPtz: [],
    surveillanceCameratableOverallConditionCameras: [],
    surveillanceMonitorTableMonitorType: [],
    surveillanceMonitorTableOverallConditionMonitors: [],
  },
  vault: {
    vaultVaultVaultManufacturers: [],
    vaultVaultSwingTypes: [],
    vaultVaultOverallConditionsVault: [],
    vaultVaultOverallConditionsVentilator: [],
    vaultVaultOverallCreep: [],
  },
  safes: {
    safesSafesSafeManufacturers: [],
    safesSafesSwingTypes: [],
    safesSafesOverallConditions: [],
  },
  ucs: {
    ucsUcsManufacturers: [],
    ucsUcsOverallConditions: [],
  },
  nightDrop: {
    nightDropNightDropManufacturers: [],
    nightDropNightDropSwingTypes: [],
    nightDropNightDropDoorStyle: [],
    nightDropNightDropOverallConditions: [],
  },
  accessControlHardware: {
    acacSystemManufacturers: [],
    acacOverallConditions: [],
    acCardReaderInterfaceTableInterfaceTypes: [],
    acwPowerSupplyTablePowerSupply: [],
    acwPowerSupplyTableVoltage: [],
    acwDoorTableReaderStyles: [],
    acwDoorTableLockStyles: [],
    controlPanelDropdowns: [],
  },
  dealDrawer: {
    dealDrawerDealDrawerManufacturers: [],
    dealDrawerDealDrawerOverallConditions: [],
  },
  rts: {
    rtsRtsMake: [],
    rtsRtsUpsendDownsend: [],
    rtsRtsOverallConditions: [],
  },
  audioVideoDU: {
    audioVideoDUAudioMatrixManufacturers: [],
    audioVideoDUDriveUpAudioMatrixVideoSystem: [],
    audioVideoDUDriveUpAudioMatrixOverallConditions: [],
  },
};
