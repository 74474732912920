import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'shared/header/header.service';
import { WorkOrderService } from './work-order-service';
import { WorkOrderMobileComponent } from './work-order-mobile/work-order-mobile.component';
import { WorkOrderDesktopComponent } from './work-order-desktop/work-order-desktop.component';
import { CallControlPage } from 'pages/page-utils/call-control-page';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { AdsEquipmentOnSite } from 'models';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'ads-work-order',
  standalone: true,
  imports: [WorkOrderMobileComponent, CommonModule, WorkOrderDesktopComponent],
  templateUrl: './work-order.component.html',
  styleUrl: './work-order.component.scss',
})
export class WorkOrderComponent extends CallControlPage implements OnInit, OnDestroy {

  equipmentData = new MatTableDataSource<AdsEquipmentOnSite>([]);

  constructor(
    override readonly route: ActivatedRoute,
    override readonly headerService: HeaderService,
    override readonly location: Location,
    override readonly router: Router,
    public override readonly workOrderService: WorkOrderService,
    public readonly layoutNavService: LayoutNavService
  ) {
    super(headerService, location, workOrderService, route, router);
  }


  ngOnInit() {
    this.init(() => {
      this.workOrderService.loadContactData();
      this.workOrderService.getEquipmentOnSite(this.workOrderService.workOrderDetails().nbr)?.subscribe((equipment) => {
        this.equipmentData.data = equipment;
      });
    });
  }

  ngOnDestroy() {
    if (this.headerService.titleAnnex()) {
      this.headerService.titleAnnex.set(undefined);
    }
  }
}
