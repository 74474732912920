<div class="container">
  <ads-navigation-header
    *ngIf="layoutNavService.isDesktopView()"
    (clicked)="onBackArrowClicked()"
    [label]="'Complete Call ' + workOrderService.workOrder"
  ></ads-navigation-header>
  <h5>Review Serviced Equipment</h5>
  <p class="review-equipment-description">
    <span>Please review the associated equipment below to confirm that the equipment serviced is selected.</span>
    <span>If the equipment selected below is not the equipment serviced, please delete and add the correct equipment.</span>
    <span>If the equipment servicedis not present please select "Equipment not shown".</span>
  </p>
  <div *ngIf="layoutNavService.isDesktopView()" class="search-header">
    <button class="main-button" mat-button mat-flat-button (click)="addEquipment()">
      Select <mat-icon>add</mat-icon>
    </button>
    <div *ngIf="reviewEquipmentService.selectedEquipment.data.length > 0" class="search-box">
      <ads-common-form-input
        label="Search"
        [maxLength]="50"
        [(ngModel)]="workOrderService.searchText"
      ></ads-common-form-input>
      <mat-icon>search</mat-icon>
    </div>
  </div>
  <div *ngIf="!layoutNavService.isDesktopView()" class="search-header">
    <div *ngIf="reviewEquipmentService.selectedEquipment.data.length > 0" class="search-box">
      <ads-common-form-input
        label="Search"
        [maxLength]="50"
        [(ngModel)]="workOrderService.searchText"
      ></ads-common-form-input>
      <mat-icon>search</mat-icon>
    </div>
    <button class="main-button" mat-button mat-flat-button (click)="addEquipment()">
      Select <mat-icon>add</mat-icon>
    </button>
  </div>

  <ads-equipment-table
    *ngIf="!reviewEquipmentService.equipmentNotShown"
    [dataSource]="reviewEquipmentService.selectedEquipment"
    [canRemove]="true"
    [searchText]="workOrderService.searchText"
    [columnsToShow]="displayedColumnsKeys"
    (equipmentRemoved)="onEquipmentRemoved($event)"
  >
  </ads-equipment-table>
  <div *ngIf="reviewEquipmentService.equipmentNotShown" class="equipment-not-shown">Equipment not shown.</div>
</div>

<div
  *ngIf="reviewEquipmentService.equipmentNotShown || reviewEquipmentService.selectedEquipment.data.length !== 0"
  class="complete-footer"
>
  <ads-modal-actions cancelLabel="Previous" (confirm)="onNextClick()" (cancel)="onBackArrowClicked()"></ads-modal-actions>
</div>
