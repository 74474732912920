import { CommonModule } from '@angular/common';
import { Component, inject, input, Input, OnInit, signal } from '@angular/core';
import { FormsModule, ControlContainer, NgModelGroup } from '@angular/forms';
import { AccessControlHardwareDropdowns } from 'models/security/security-dropdowns';
import { AccessControlDoorTableFormSection } from '../access-hardware.component';
import { TableSectionComponentBaseComponent } from 'forms/shared/table-section-component-base/table-section-component.base';
import { SelectionModel } from '@angular/cdk/collections';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { TableBottomSheetsComponent } from 'forms/shared/table-bottom-sheets/table-bottom-sheets.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { TableFormBottomSheetInputComponent } from 'shared/material-wrappers/table-form/table-form-bottom-sheet-input/table-form-bottom-sheet-input.component';
import { TableFormInputComponent } from 'shared/material-wrappers/table-form/table-form-input/table-form-input.component';
import { AhDoorsTableBottomSheetComponent } from '../ah-doors-table-bottom-sheet/ah-doors-table-bottom-sheet.component';
import { RadioButtonGroupComponent } from 'shared/material-wrappers';
import { SelectOption, CompleteNa, YesNoNa } from 'models';

@Component({
  selector: 'ads-ah-doors-table-section',
  standalone: true,
  imports: [
    MatTableModule,
    CommonModule,
    FormsModule,
    MatInputModule,
    TableFormInputComponent,
    TableFormBottomSheetInputComponent,
    MatBottomSheetModule,
    MatCheckboxModule,
    RadioButtonGroupComponent,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
  templateUrl: './ah-doors-table-section.component.html',
  styleUrl: './ah-doors-table-section.component.scss',
})
export class AhDoorsTableSectionComponent extends TableSectionComponentBaseComponent implements OnInit {
  displayedColumnsKeys = signal(['readerLocation', 'readerStyle', 'lockStyle', 'tasks']);

  @Input() list: AccessControlDoorTableFormSection[] = [];
  readonly dropdowns = input<AccessControlHardwareDropdowns>();

  selection = new SelectionModel<AccessControlDoorTableFormSection>(true, []);

  private readonly _bottomSheet = inject(MatBottomSheet);

  ngOnInit() {
    this.selection.changed.subscribe((change) => {
      change.added.concat(change.removed).forEach((item) => {
        this.onDeleteChecked(item.index);
      });
    });
  }

  optionsCompleteNa: SelectOption<CompleteNa>[] = [
    { name: 'Complete', value: CompleteNa.Complete },
    { name: 'N/A', value: CompleteNa.Na },
  ];

  optionsYesNoNa: SelectOption<YesNoNa>[] = [
    { name: 'Yes', value: YesNoNa.Yes },
    { name: 'No', value: YesNoNa.No },
    { name: 'N/A', value: YesNoNa.Na },
  ];

  onBlur = (element: AccessControlDoorTableFormSection) => () => {
    if (!element.lockStyle) {
      this.openBottomSheet(element)();
    }
  };

  openBottomSheet = (element: AccessControlDoorTableFormSection) => () => {
    const onDismiss = this._bottomSheet.open(TableBottomSheetsComponent, {
      data: {
        selections: [
          {
            title: 'Reader Style',
            control: 'readerStyle',
            options: this.dropdowns()?.acwDoorTableReaderStyles,
            value: element.readerStyle,
          },
          {
            title: 'Lock Style',
            control: 'lockStyle',
            options: this.dropdowns()?.acwDoorTableLockStyles,
            value: element.lockStyle,
          },
        ],
        addNew: this.addNew,
        showCancel: true,
      },
    });

    onDismiss.afterDismissed().subscribe((result) => {
      if (result) {
        element.readerStyle = result.readerStyle;
        element.lockStyle = result.lockStyle;
      }
    });
  };

  openTasksBottomSheet = (element: AccessControlDoorTableFormSection) => () => {
    const onDismiss = this._bottomSheet.open(AhDoorsTableBottomSheetComponent, {
      data: {
        tasks: {
          doesAREXDeviceUnlockDoor: element.doesAREXDeviceUnlockDoor,
          testLock: element.testLock,
          testReader: element.testReader,
          testDoorHelpOpenAlarm: element.testDoorHelpOpenAlarm,
          testDoorForcedAlarm: element.testDoorForcedAlarm,
        },
        addNew: this.addNew,
      },
    });

    onDismiss.afterDismissed().subscribe((result) => {
      if (result) {
        element.doesAREXDeviceUnlockDoor = result.doesAREXDeviceUnlockDoor;
        element.testLock = result.testLock;
        element.testReader = result.testReader;
        element.testDoorHelpOpenAlarm = result.testDoorHelpOpenAlarm;
        element.testDoorForcedAlarm = result.testDoorForcedAlarm;

        this.ngModelGroup.control.controls['accessControlDoor#' + element.index].markAsDirty();
      }
    });
  };

  displaySelectKeys = () => {
    this.displayedColumnsKeys.set(['select', 'readerLocation', 'readerStyle', 'lockStyle', 'tasks']);
  };

  displayKeys = () => {
    this.displayedColumnsKeys.set(['readerLocation', 'readerStyle', 'lockStyle', 'tasks']);
  };

  displayOtherReaderStyle = (element: AccessControlDoorTableFormSection) => {
    return element.readerStyle === 'Other';
  };

  displayOtherLockStyle = (element: AccessControlDoorTableFormSection) => {
    return element.lockStyle === 'Other';
  };

  elementTasksCompleted(element: AccessControlDoorTableFormSection): [number, boolean] {
    const completed = [
      element.doesAREXDeviceUnlockDoor,
      element.testLock,
      element.testReader,
      element.testDoorHelpOpenAlarm,
      element.testDoorForcedAlarm,
    ].filter((task) => task !== null && task !== undefined && '' + task !== '').length;

    const elementStatus = this.ngModelGroup?.control.controls['accessControlDoor#' + element.index];

    return [completed, completed < 5 && !elementStatus?.pristine];
  }
}
