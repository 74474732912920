import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { HeaderService } from 'shared/header/header.service';
import { NavButtonComponent } from 'shared/layout-nav/nav-button/nav-button.component';
import { LogoutFooterComponent } from 'shared/logout-footer/logout-footer.component';
import { ProfileNameComponent } from 'shared/profile-name/profile-name.component';

@Component({
  selector: 'ads-mobile-more',
  standalone: true,
  imports: [NavButtonComponent, MatDividerModule, ProfileNameComponent, LogoutFooterComponent],
  templateUrl: './mobile-more.component.html',
  styleUrl: './mobile-more.component.scss',
})
export class MobileMoreComponent {
  @Input() icon = 'calendar_month';

  constructor(private readonly headerService: HeaderService) {
    this.headerService.title.set('Menu');
  }
}
