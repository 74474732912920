export class ItmTasks {
  areConfigSheetsPresent: number | null;
  isMaintainanceLogSheetPresent: number | null;
  verifySiteProfileOnSharepoint: number | null;
  checkErrorLog: number | null;
  clearTallies: number | null;
  requestKabaCombo: number | null;

  constructor() {
    this.areConfigSheetsPresent = null;
    this.isMaintainanceLogSheetPresent = null;
    this.verifySiteProfileOnSharepoint = null;
    this.checkErrorLog = null;
    this.clearTallies = null;
    this.requestKabaCombo = null;
  }
}
