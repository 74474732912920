<ads-simple-form *ngIf="item" [ngModelGroup]="'comboLock#' + item.index">
  <ads-common-form-input
    name="comboLockType"
    label="Combo Lock Type"
    [maxLength]="50"
    [required]="true"
    [value]="item.comboLockType"
    [(ngModel)]="item.comboLockType"
  ></ads-common-form-input>

  <ads-common-form-input
    name="comboLockManufacturer"
    label="Combo Lock Manufacturer"
    [maxLength]="50"
    [required]="true"
    [value]="item.comboLockManufacturer"
    [(ngModel)]="item.comboLockManufacturer"
  ></ads-common-form-input>

  <ads-common-form-input
    name="comboLockModel"
    label="Combo Lock Model"
    [maxLength]="50"
    [required]="true"
    [value]="item.comboLockModel"
    [(ngModel)]="item.comboLockModel"
  ></ads-common-form-input>

  <ads-radio-button-group
    [options]="options"
    label="Is there a key locking dial?"
    name="isThereAKeyLockingDial"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
<mat-divider></mat-divider>
  <ads-radio-button-group

    [options]="options"
    label="Was the combo changed?"
    name="isComboLockChanged"
    [required]="true"
    ngModel
  ></ads-radio-button-group>

  <ads-common-form-input
    [style.display]="displayOtherCondition() ? 'block' : 'none'"
    name="comboLockChangedDetail"
    label="If no, why?"
    [maxLength]="30"
    [required]="true"
    [value]="item.comboLockChangedDetail"
    [(ngModel)]="item.comboLockChangedDetail"
    [required]="displayOtherCondition()"
  ></ads-common-form-input>
</ads-simple-form>
