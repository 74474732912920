export class AtmCheckDepositoryTasks {
  allNa: boolean;
  cleanSensors: number | null;
  cleanRollers: number | null;
  cleanBelts: number | null;
  cleanInkArea: number | null;
  checkScannerLenses: number | null;
  checkPrinterQuality: number | null;
  cleanAllBins: number | null;
  verifyInfeedAlignment: number | null;
  diagnostic: number | null;

  constructor() {
    this.allNa = false;
    this.cleanSensors = null;
    this.cleanRollers = null;
    this.cleanBelts = null;
    this.cleanInkArea = null;
    this.checkScannerLenses = null;
    this.checkPrinterQuality = null;
    this.cleanAllBins = null;
    this.verifyInfeedAlignment = null;
    this.diagnostic = null;
  }
}
