import { CommonModule } from '@angular/common';
import { Component, OnInit, SecurityContext } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import { environment } from 'environment/environment';
import { LoginComponent } from 'pages/login/login.component';
import { AuthService } from 'services';

@Component({
  selector: 'ads-iframe-wrapper',
  standalone: true,
  imports: [RouterLink, MatButtonModule, LoginComponent, MatInputModule, CommonModule, FormsModule],
  templateUrl: './iframe-wrapper.component.html',
})
export class IframeWrapperComponent implements OnInit {
  workOrder = '';
  adsUrl: SafeResourceUrl|null = null;

  constructor(
    public readonly authService: AuthService,
    private readonly _sanitizer: DomSanitizer,
    private readonly router: Router,
  ) {
  }

  ngOnInit(): void {
    const sanitazedUrl = this._sanitizer.sanitize(SecurityContext.URL, this.router.url);
    const adsUrl = `${environment.apiConfig.adsUrl}${sanitazedUrl}`;
    this.adsUrl = this._sanitizer.bypassSecurityTrustResourceUrl(adsUrl);
  }
}
