<ads-simple-form *ngIf="item" class="surveillance-camera-section-wrapper" [ngModelGroup]="'cameraTableItem#' + item.index">
  <ads-common-form-input
    name="camera"
    label="Camera"
    [maxLength]="50"
    [required]="true"
    [value]="item.camera"
    [(ngModel)]="item.camera"
  ></ads-common-form-input>
  <ads-select
    label="Camera Type"
    name="cameraType"
    [list]="lists?.surveillanceCameraTableCameraType"
    [value]="item.cameraType"
    [(ngModel)]="item.cameraType"
  ></ads-select>

  <ads-select
    label="Is PTZ"
    name="isPTZ"
    [list]="lists?.surveillanceCameraTableIsPtz"
    [value]="item.isPTZ"
    [(ngModel)]="item.isPTZ"
  ></ads-select>
</ads-simple-form>
