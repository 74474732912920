import { IframeMessageService, ThemingService } from 'services';
import { AdsCallControl } from 'models';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ServiceCallsService } from './service-calls.service';
import { DataRange, ServiceCallsHeaderComponent } from './call-control-filters/service-calls-header.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesEnum } from 'app/utils/navigation';
import { statusColors } from 'app/utils/call-control';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { WorkOrderCardComponent } from 'shared/work-order-card/work-order-card.component';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { CallControlService } from '../call-control.service';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { mapCalls } from '../utils/calls-mapping';
import { TimeZonePipe } from 'app/utils/time-zone.pipe';

@Component({
  selector: 'ads-service-calls',
  standalone: true,
  imports: [CommonModule, ServiceCallsHeaderComponent, MatIconModule, WorkOrderCardComponent, MatPaginatorModule],
  providers: [TimeZonePipe],
  templateUrl: './service-calls.component.html',
  styleUrl: './service-calls.component.scss',
})
export class ServiceCallsComponent implements OnInit {
  @Input() isDesktopView = false;
  @Input() calls: AdsCallControl[] = [];
  @Input() allCalls: AdsCallControl[] = [];
  _calls: AdsCallControl[] = [];
  filterCriteria: Map<string, DataRange> = new Map<string, DataRange>();
  pageIndex = 0;

  currentTheme = '';
  statusColors = statusColors;
  private readonly _bottomSheet = inject(MatBottomSheet);
  public readonly _snackBar = inject(MatSnackBar);

  constructor(
    private readonly timeZonePipe: TimeZonePipe,
    public readonly theming: ThemingService,
    public readonly service: ServiceCallsService,
    private readonly router: Router,
    private readonly iframeMessageService: IframeMessageService,
    private readonly workOrderService: WorkOrderService,
    protected readonly callControlService: CallControlService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.currentTheme = this.theming.theme.name;
    this.theming.watchTheme.subscribe((theme) => {
      this.currentTheme = theme;
    });

    const state = window.history.state;
    const noWoSelected = state?.['noWOSelected'] as boolean;
    if (noWoSelected) {
      this._snackBar.open('Please select a call.', 'Close', {
        verticalPosition: 'top',
      });
      window.history.replaceState({}, '');
    }
  }

  refreshData(): void {
    this.filter(1);
    this._bottomSheet.dismiss();
  }

  filter(pageNo?: number) {
    this.callControlService
      .callControlList({
        pageNo: pageNo,
        pageSize: 20,
        sortField: this.service.sorting.activeSortCriteria,
        sortOrder: this.service.sorting.sortAsc ? 'asc' : 'desc',
        searchText: this.service.searchText || undefined,
        priorities: this.service.priorityFilter.value,
        onSiteByFrom: this.service.onSiteByDateFilter.minValue?.toISOString(),
        onSiteByTo: this.service.onSiteByDateFilter.maxValue?.toISOString(),
        scheduledFrom: this.service.scheduledDateFilter.minValue?.toISOString(),
        scheduledTo: this.service.scheduledDateFilter.maxValue?.toISOString(),
        statuses: this.service.statusFilter.value,
      })
      .subscribe((response) => {
        this.callControlService.totalItems = response.totalRecords;
        this.calls = mapCalls(response.items, this.timeZonePipe);
      });
  }

  clearFilters() {
    this.service.clearFilters();
    this.refreshData();
  }

  onCallClick(call: AdsCallControl) {
    this.workOrderService.workOrderDetails.set(call);
    this.iframeMessageService.setSelectedWorkOrderInAds(call);
    this.router.navigateByUrl(`${RoutesEnum.WorkOrder}/${call.workorder}`, { state: { call } });
  }

  getMinDate() {
    const call = this.calls.reduce(
      (min, call) => (new Date(call?.dateopened ?? '') < new Date(min?.dateopened ?? '') ? call : min),
      this.calls[0],
    );
    return new Date(call?.dateopened ?? '');
  }

  handlePageEvent(e: PageEvent) {
    this.pageIndex = e.pageIndex;
  }

  public getPaginatorData(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.filter(event.pageIndex + 1);
  }
}
