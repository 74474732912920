import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { FormsModule, ControlContainer, NgModelGroup, FormGroup } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { SecuritySurveillanceDropdowns } from 'models/security/security-dropdowns';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { SurveillanceDVRNVRSection } from '../surveillance.component';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';

@Component({
  selector: 'ads-surveillance-dvr-nvr-section',
  standalone: true,
  imports: [CommonModule, FormsModule, CommonFormInputComponent, SelectComponent, MatDividerModule, SimpleFormComponent, CommonTextAreaComponent],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
  templateUrl: './surveillance-dvr-nvr-section.component.html',
})
export class SurveillanceDvrNvrSectionComponent {
  @Input() dvrNvrItems?: SecuritySurveillanceDropdowns;
  @Input() section?: FormGroup;
  @Input() item?: SurveillanceDVRNVRSection;

  constructor(@Optional() public readonly ngModelGroup: NgModelGroup) {}

  displayOtherManufacturer = () => {
    const manufacturer = this.section?.get('dvrManufacturer');
    return Boolean(manufacturer && manufacturer.value === 'Other');
  };

  displayOtherCondition = () => {
    const condition = this.section?.get('overallCondition_DVRNVR');
    return Boolean(condition && (condition.value === 'Poor Condition' || condition.value === 'Failed'));
  };
}
