import { SecurityRtsCustomerUnitDto } from 'models/api/security/rts/customer-unit-dto';
import { SecurityRtsSectionDto } from 'models/api/security/rts/rts-dto';
import { SecurityRtsTellerUnitDto } from 'models/api/security/rts/teller-unit-dto';
import { SecurityRtsDto } from 'models/api/security/security';
import { CustomerUnitForm } from 'models/security/rts/customerUnit';
import { RtsPanelForm, RtsSecurityForm } from 'models/security/rts/rtsSecurityForm';
import { TellerUnitForm } from 'models/security/rts/tellerUnit';

export const mapRtsPanels = (panels: RtsPanelForm[]): SecurityRtsSectionDto[] => {
  return panels.map((panel) => ({
    equipment_Rating_RTS: panel.overallConditionComments,
    lane: panel.laneNumber ? Number.parseInt(panel.laneNumber) : null,
    lengthofTubeRunIfNotStandardConfiguration: panel.tubeLength ? Number.parseInt(panel.tubeLength) : null,
    overallConditionRTS: panel.overallCondition,
    rtsMake: panel.rtsMake,
    rtsMake_Other: panel.rtsMakeOther,
    upsendDownsend: panel.upsendOrDownsend,
    rtsModel: panel.rtsModel,
  }));
};

export const mapRtsCustomerUnit = (panels: CustomerUnitForm): SecurityRtsCustomerUnitDto[] => {
  return [
    {
      cleanoutSideOfUnitWithAppropriateCleaner: panels.cleanOutside ?? null,
      inspectAndTestDoorMotors: panels.inspectDoorMotors ?? null,
      inspectAndTestBumperAndPads: panels.testBumperPads ?? null,
      inspectAndTestCarrierArrivalCatch: panels.testCarrierCatch ?? null,
      inspectAndTestCarrierLimitSwitches: panels.testCarrierLimitSwitches ?? null,
      inspectAndTestIndicatorLights: panels.testIndicatorLights ?? null,
      inspectAndTestRelays: panels.testRelays ?? null,
      vacuumInsideOfUnit: panels.vaccumInside ?? null,
      inspectAndTestBlowerAssy: panels.testBlowerAssy ?? null,
      inspectAndTestSendCallSwitches: panels.testCallSwitches ?? null,
      inspectAndTestMotorStarter: panels.testMotorStarter ?? null,
      inspectAndTestSafetyDevices: panels.testSafetyDevices ?? null,
      inspectAndTestWindgateAssy: panels.testWindgateAssy ?? null,
    },
  ];
};

export const mapRtsTellerUnit = (panels: TellerUnitForm): SecurityRtsTellerUnitDto[] => {
  return [
    {
      cleanOutsideOfUnitWithAppropriateCleaner: panels.cleanOutside ?? null,
      inspectAndTestDoorMotors: panels.inspectDoorMotors ?? null,
      inspectAndTestBumperAndPads: panels.testBumperPads ?? null,
      inspectAndTestCarrierArrivalCatch: panels.testCarrierCatch ?? null,
      inspectAndTestIndicatorLights: panels.testIndicatorLights ?? null,
      inspectAndTestRelays: panels.testRelays ?? null,
      vacuumInsideOfUnit: panels.vaccumInside ?? null,
      inspectAndTestcarrierLimitSwitches: panels.testCarrierSwitches ?? null,
      inspectAndTestSendrecallSwitches: panels.sendRecallSwitches ?? null,
    },
  ];
};

export const mapSecurityRts = (data: RtsSecurityForm) => ({
  rtsPanels: mapRtsPanels(data.rtsPanels),
  tellerUnit: mapRtsTellerUnit(data.tellerUnit),
  customerUnit: mapRtsCustomerUnit(data.customerUnit),
});

export const mapRtsRequest = (rts: RtsSecurityForm | null): SecurityRtsDto => ({
  security_RTS: {
    security_RTS_RTS: mapRtsPanels(rts?.rtsPanels ?? []),
    security_RTS_CustomerUnit: mapRtsCustomerUnit(rts?.customerUnit ?? new CustomerUnitForm()),
    security_RTS_TellerUnit: mapRtsTellerUnit(rts?.tellerUnit ?? new TellerUnitForm()),
    comments: rts?.comments ?? null,
  },
});
