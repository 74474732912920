import { Component, Input } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, FormsModule, NgModelGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { SafesFormSection } from '../safes.component';
import { SecuritySafesDropdowns } from 'models/security/security-dropdowns';
import { TimeClocksSectionComponent } from 'forms/security/shared/time-clocks-section/time-clocks-section.component';
import { NestedMultipleFormsSectionComponent } from 'forms/shared/nested-multiple-forms-section/nested-multiple-forms-section.component';
import { ComboLocksSectionComponent } from 'forms/security/shared/combo-locks-section/combo-locks-section.component';
import { getFormGroup } from 'utils';
import { ComboLockSection } from 'models/security/shared/combo-lock';
import { TimeClockSection } from 'models/security/shared/time-clock';
import { SimpleFormComponent } from '../../../../shared/simple-form/simple-form.component';
import { CommonTextAreaComponent } from '../../../../shared/material-wrappers/common-text-area/common-text-area.component';
import { onAddItem, onRemoveItem } from 'app/utils/form-group-array';

@Component({
  selector: 'ads-safes-section-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CommonFormInputComponent,
    SelectComponent,
    NestedMultipleFormsSectionComponent,
    ComboLocksSectionComponent,
    TimeClocksSectionComponent,
    SimpleFormComponent,
    CommonTextAreaComponent,
  ],
  templateUrl: './safes-section-form.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class SafesSectionFormComponent {
  @Input() dropdowns?: SecuritySafesDropdowns;
  @Input() section?: FormGroup;
  @Input() item?: SafesFormSection;
  @Input() submitted = false;

  expandedList: number[] = [];

  displayOther = (value: string) => {
    const field = this.section?.get(value);
    return Boolean(field && field.value === 'Other');
  };

  displayOtherCondition = () => {
    const condition = this.section?.get('overallConditionSafe');
    return Boolean(condition && (condition.value === 'Poor Condition' || condition.value === 'Failed'));
  };

  getFormControls(sectionName: string): Record<string, AbstractControl<string, string>> {
    const fg = this.section?.get(sectionName) as FormGroup;
    return fg ? fg.controls : {};
  }

  onAddComboLock = () => onAddItem(this.item?.comboLocks ?? [], (index) => new ComboLockSection(index));
  onAddTimeClock = () => onAddItem(this.item?.timeClocks ?? [], (index) => new TimeClockSection(index));

  onRemoveComboLock = (item: ComboLockSection) => onRemoveItem(this.item?.comboLocks ?? [], item);
  onRemoveTimeClock = (item: TimeClockSection) => onRemoveItem(this.item?.timeClocks ?? [], item);

  getSection(sectionName: string, containerName: string) {
    return getFormGroup(this.section?.controls[containerName])?.controls[sectionName] as FormGroup;
  }

}
