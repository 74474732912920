import { CommonModule, DatePipe, Location } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { RoutesEnum } from 'app/utils/navigation';
import { CommonFormInputComponent } from 'shared/material-wrappers/common-form-input/common-form-input.component';
import { EquipmentTableComponent } from 'shared/equipment-table/equipment-table.component';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { HeaderService } from 'shared/header/header.service';
import { CallControlPage } from 'pages/page-utils/call-control-page';
import { ReviewEquipmentService } from './review-equipment.service';
import { AddEquipmentComponent } from './add-equipment/add-equipment.component';
import { NavigationHeaderComponent } from 'shared/navigation-header/navigation-header.component';
import { ModalActionsComponent } from 'shared/modal-actions/modal-actions.component';
import { AssociatedEquipmentModel, EquipmentEntityModel } from 'models/equipment/equipment';

@Component({
  selector: 'ads-review-equipment',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    EquipmentTableComponent,
    CommonFormInputComponent,
    NavigationHeaderComponent,
    ModalActionsComponent,
  ],
  templateUrl: './review-equipment.component.html',
  styleUrl: './review-equipment.component.scss',
})
export class ReviewEquipmentComponent extends CallControlPage implements OnInit, OnDestroy {
  displayedColumnsKeys = [
    'remove',
    'equipmentType',
    'partsWarrantyEnds',
    'manufacturer',
    'model',
    'type',
    'terminalID',
  ];
  readonly _dialogRef = inject(MatDialog);
  readonly _bottomSheet = inject(MatBottomSheet);
  private readonly datepipe: DatePipe = new DatePipe('en-US');

  constructor(
    override readonly route: ActivatedRoute,
    override readonly headerService: HeaderService,
    override readonly location: Location,
    override readonly router: Router,
    public override workOrderService: WorkOrderService,
    protected readonly layoutNavService: LayoutNavService,
    public readonly reviewEquipmentService: ReviewEquipmentService
  ) {
    super(headerService, location, workOrderService, route, router);
  }

  ngOnInit() {
    this.reviewEquipmentService.equipmentNotShown = false;
    this.init().then(() => {
      this.workOrderService
        .getAssociatedEquipmentByWorkOrder(this.workOrderService.workOrder)
        .subscribe(this.onEquipmentLoad);
    });
  }

  onEquipmentLoad = (equipmentList: EquipmentEntityModel[]) => {
    this.reviewEquipmentService.selectedEquipment.data = equipmentList;
  };

  ngOnDestroy() {
    if (this.headerService.titleAnnex()) {
      this.headerService.titleAnnex.set(undefined);
    }
  }

  isEquipmentShown() {
    return (
      this.reviewEquipmentService.equipmentNotShown ||
      this.reviewEquipmentService.selectedEquipment.data.some((e) => e.isEquipmentNotShown)
    );
  }

  onNextClick = () => {
    const time = this.route.snapshot?.paramMap.get('time');
    const toAdd: AssociatedEquipmentModel[] = this.reviewEquipmentService.selectedEquipment.data.map((e) => ({
      equipmentId: Number(e.equipmentId),
      equipmentTypeId: Number(e.equipmentTypeId),
      id: 0,
      isEquipmentNotShown: false,
    }));
    this.workOrderService
      .updateAssociatedEquipment({
        serviceOrderId: this.workOrderService.workOrder,
        associatedEquipments: this.isEquipmentShown() ? [{ isEquipmentNotShown: true }] : toAdd,
      })
      .subscribe(() =>
        this.router.navigate([`WO/${RoutesEnum.CompleteWorkOrder}/${this.workOrderService.workOrder}`, { time }])
      );
  };

  addEquipment() {
    if (this.layoutNavService.isDesktopView()) {
      this._dialogRef.open(AddEquipmentComponent);
    } else {
      this._bottomSheet.open(AddEquipmentComponent);
    }
  }

  onEquipmentRemoved(equipmentIndex: number) {
    const temp = [...this.reviewEquipmentService.selectedEquipment.data];
    temp.splice(equipmentIndex, 1);
    this.reviewEquipmentService.selectedEquipment.data = temp;
    this.reviewEquipmentService.toAdd.setSelection(...temp);
  }

  onBackArrowClicked() {
    this.router.navigate([`${RoutesEnum.WorkOrder}/${this.workOrderService.workOrder}`]);
  }
}
