<button *ngIf="showPicker" class="picker-overlay" (click)="hidePicker()"> </button>
<div class="timepicker">
  <ads-common-form-input suffixIcon="schedule" [label]="label" [value]="displayValue" (fieldFocused)="onFieldFocus()"></ads-common-form-input>
  <div *ngIf="showPicker" class="picker-wrapper">
    <h4>Pick a time</h4>
    <div class="picker-container">
      <div class="picker-column hour">
        <button mat-button mat-raised-button class="chevron-button" (click)="incrementHour()">
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
        <span class="time-label">{{ hour }}</span>
        <button mat-button mat-raised-button class="chevron-button" (click)="decrementHour()">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      </div>
      <div class="picker-column minute">
        <button mat-button mat-raised-button class="chevron-button" (click)="incrementMinute()">
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
        <span class="time-label">{{ minute }}</span>
        <button mat-button mat-raised-button class="chevron-button" (click)="decrementMinute()">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      </div>
      <div class="picker-column">
        <button mat-button mat-raised-button class="chevron-button" (click)="toggleAmPm()">
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
        <span class="time-label">{{ ampm }}</span>
        <button mat-button mat-raised-button class ="chevron-button" (click)="toggleAmPm()">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      </div>
    </div>
    <ads-modal-actions (confirm)="onConfirmTime()" (cancel)="onCancel()"></ads-modal-actions>
  </div>
</div>
