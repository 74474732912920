<ads-simple-form *ngIf="item" class="surveillance-monitor-section-wrapper" [ngModelGroup]="'monitorTableItem#' + item.index">
  <ads-common-form-input
    name="monitor"
    label="Monitor"
    [maxLength]="30"
    [required]="true"
    [value]="item.monitor"
    [(ngModel)]="item.monitor"
  ></ads-common-form-input>
  <ads-select
    label="Monitor Type"
    name="monitorType"
    [list]="lists?.surveillanceMonitorTableMonitorType"
    [value]="item.monitorType"
    [(ngModel)]="item.monitorType"
  ></ads-select>
</ads-simple-form>
