import { AfterViewInit, Component, inject, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { HeaderService } from 'shared/header/header.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CustomPromptComponent } from 'shared/custom-prompt/custom-prompt.component';
import { SupportRequestComponent } from '../../shared/support-request/support-request.component';
import { CancelRequestComponent } from '../../shared/cancel-request/cancel-request.component';
import { ChangePriorityComponent } from '../../shared/change-priority/change-priority.component';
import { MatButtonModule } from '@angular/material/button';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { MatDialog } from '@angular/material/dialog';
import { RelatedArticlesDialogComponent } from 'pages/work-order/related-articles-dialog/related-articles-dialog.component';
import { OpenCallsDialogComponent } from 'pages/work-order/open-calls-dialog/open-calls-dialog.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ads-work-order-mobile-menu',
  standalone: true,
  imports: [
    CommonModule,
    SupportRequestComponent,
    CustomPromptComponent,
    CancelRequestComponent,
    ChangePriorityComponent,
    MatButtonModule,
    CommonModule,
  ],
  templateUrl: './work-order-mobile-menu.component.html',
  styleUrl: './work-order-mobile-menu.component.scss',
})
export class WorkOrderMobileMenuComponent implements AfterViewInit, OnDestroy {
  public readonly _bottomSheet = inject(MatBottomSheet);
  @ViewChild('menu', { read: TemplateRef }) menu!: TemplateRef<unknown>;
  @ViewChild('requestSupportTemplate', { read: TemplateRef }) requestSupportTemplate!: TemplateRef<unknown>;
  @ViewChild('requestCancelTemplate', { read: TemplateRef }) requestCancelTemplate!: TemplateRef<unknown>;
  @ViewChild('changePriorityTemplate', { read: TemplateRef }) changePriorityTemplate!: TemplateRef<unknown>;
  readonly dialogRef = inject(MatDialog);

  constructor(public headerService: HeaderService, protected readonly workOrderService: WorkOrderService) {}

  ngAfterViewInit(): void {
    this.headerService.customMenu = this.menu;
  }

  ngOnDestroy(): void {
    this.headerService.showMenuHighlight = false;
  }

  launchFormClicked() {
    this.workOrderService.handlePMForm();
  }

  openCallsClicked() {
    this.headerService.showCustomMenu = false;
    this.dialogRef.open(OpenCallsDialogComponent);
  }

  siteProfileClicked() {
    this.workOrderService.handleSiteProfile();
  }

  articleClicked() {
    this.dialogRef.open(RelatedArticlesDialogComponent);
    this.headerService.showCustomMenu = false;
  }

  supportClicked() {
    this._bottomSheet.open(this.requestSupportTemplate);
    this.headerService.showCustomMenu = false;
  }

  changePriorityClicked() {
    this._bottomSheet.open(this.changePriorityTemplate);
    this.headerService.showCustomMenu = false;
  }

  cancelClicked() {
    this._bottomSheet.open(this.requestCancelTemplate);
    this.headerService.showCustomMenu = false;
  }
}
