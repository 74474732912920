<ads-simple-form #atmSection="ngModelGroup" ngModelGroup="atmSection">
  <ads-select
    label="ATM Manufacturer"
    name="atmManufacturer"
    [list]="atmManufacturerList"
    ngModel
  ></ads-select>
  <ads-common-form-input
    [style.display]="displayOther() ? 'block' : 'none'"
    name="otherAtmManufacturer"
    label="Other Manufacturer"
    [maxLength]="64"
    [required]="displayOther()"
    ngModel
  ></ads-common-form-input>
  <ads-common-form-input
    name="atmModel"
    label="ATM Model"
    [maxLength]="30"
    [required]="true"
    ngModel
  ></ads-common-form-input>
  <ads-select label="Depositor type" name="depositorType" [list]="depositorTypeList" ngModel></ads-select>
  <ads-common-form-input
    name="atmSerialNumber"
    label="ATM Serial number"
    [maxLength]="30"
    [required]="true"
    ngModel
  ></ads-common-form-input>
  <ads-common-form-input
    name="terminalId"
    label="Terminal ID"
    [maxLength]="50"
    [required]="true"
    ngModel
  ></ads-common-form-input>
</ads-simple-form>
