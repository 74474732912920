import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, RedirectRequest } from '@azure/msal-browser';
import { environment } from 'environment/environment';
import { BehaviorSubject, Subject, filter, lastValueFrom, Observable } from 'rxjs';
import { UserService } from './user.service';
import { AdsActiveUserModel } from 'models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isIframe = false;
  authenticated = false;
  user?: AdsActiveUserModel;
  private userAccount: AccountInfo | undefined;
  private readonly _destroying$ = new Subject<void>();
  private readonly authenticatedUserSubject = new BehaviorSubject<AdsActiveUserModel | null>(null);

  public loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private readonly msalGuardConfig: MsalGuardConfiguration,
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly userService: UserService
  ) {}

  init = (): void => {
    this.msalService.handleRedirectObservable().subscribe();
    this.isIframe = window !== window.parent && !window.opener;

    this.setLoginDisplay();

    this.msalService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS))
      .subscribe(async (msg: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.checkAccount(msg.payload as AuthenticationResult);
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe(async () => {
        await this.checkUserValidity();
      });
  };

  setLoginDisplay() {
    this.authenticated = this.msalService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  private checkAccount(authResponse: AuthenticationResult): void {
    this.setLoginDisplay();
    this.userAccount = authResponse.account;
    if (authResponse) {
      this.checkAndSetActiveAccount();
    }
  }

  async checkUserOnStartup(): Promise<boolean> {
    try {
      const res = await this.refreshToken();
      return !!res;
    } catch {
      return false;
    }
  }

  async checkUserValidity(): Promise<void> {
    try {
      this.loading$.next(true);
      const user = await lastValueFrom(this.userService.employeeDetail());
      this.user = user;
      if (!this.user?.personnelNumber) {
        this.logout();
      }
      else {
        this.authenticatedUserSubject.next(user);
      }
    } catch {
      this.loading$.next(false);
    } finally {
      this.loading$.next(false);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect().subscribe(() => {
        const accounts = this.msalService.instance.getAllAccounts();
        this.msalService.instance.setActiveAccount(accounts[0]);
      });
    } else {
      this.msalService.loginPopup().subscribe((response: AuthenticationResult) => {
        this.msalService.instance.setActiveAccount(response.account);
      });
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.msalService.logoutRedirect();
    }
    this.authenticatedUserSubject.next(null);
  }

  onDestroy = (): void => {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  };

  async refreshToken(): Promise<AuthenticationResult | void> {
    const scopes = [...environment.apiConfig.scopes, ...environment.msalConfig.aadScopes.loginRequest, 'mail.send'];

    await lastValueFrom(this.msalService.handleRedirectObservable());
    const accounts = this.msalService.instance.getAllAccounts();

    if (!accounts.length || !accounts[0]) {
      return;
    }

    this.userAccount = accounts[0];

    const silentRequest = {
      account: this.userAccount,
      scopes: scopes,
    };
    return await lastValueFrom(this.msalService.acquireTokenSilent(silentRequest))
      .then((authResponse) => {
        this.checkAccount(authResponse);
        this.authenticated = true;
        return authResponse;
      })
      .catch(() => {
        const redirectStartPage = window.location.href;
        this.msalService.acquireTokenRedirect({ scopes, redirectStartPage });
        this.authenticated = false;
      });
  }

  get authenticatedUser$(): Observable<AdsActiveUserModel | null> {
    return this.authenticatedUserSubject.asObservable();
  }
}
