import { Injectable, signal, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, AuthService } from 'services';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { HeaderButton } from './header-button';
import { Alert } from 'models/api/home/home-requests';

interface TitleChip {
  text: string;
  color: string;
  backgroundColor: string;
  border: string;
}

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  public showResetFormButton = signal(false);
  public sections = [] as ExpansionPanelComponent[];
  public expanded = false;
  public title = signal('');
  public titleAnnex = signal<TitleChip | undefined>({ text: '', color: '', backgroundColor: '', border: '' });
  public resetForm: (() => void) | undefined;
  public backButtonOverride: (() => void) | undefined;
  public contextActions: HeaderButton[] = [];

  public welcomeTitle = '';
  public customMenu: TemplateRef<unknown> | null = null;
  public showCustomMenu = false;
  public showMenuHighlight = false;

  constructor(private readonly service: ApiService, private readonly authService: AuthService) {
    this.welcomeTitle = 'Welcome, ' + (this.authService.user?.fullName ?? '');
  }

  public checkAllCollapsed = () => {
    if (this.sections.every((section) => !section.expanded)) {
      this.expanded = false;
    }
  };

  public deleteAlert(alertId: number): Observable<void> {
    return this.service.sendRequest<void>({
      method: 'delete',
      url: `technician/deletealert?alertId=${alertId}`,
    });
  }

  public getAlerts(): Observable<Alert[]> {
    return this.service.sendRequest<Alert[]>({
      method: 'get',
      url: `technician/alerts?techIdMaster=${this.authService.user?.personnelNumber ?? ''}`,
    });
  }
}
