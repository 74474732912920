import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SelectionModel } from '@angular/cdk/collections';
import { MatButtonModule } from '@angular/material/button';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { PartCardComponent } from '../part-card/part-card.component';
import { PartDetail } from 'models/call-control/part';

@Component({
  selector: 'ads-parts-table',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatCheckboxModule, MatIconModule, MatButtonModule, PartCardComponent],
  templateUrl: './parts-table.component.html',
  styleUrl: './parts-table.component.scss',
})
export class PartsTableComponent {
  @Input() dataSource!: MatTableDataSource<PartDetail>;
  @Input() columnsToShow: string[] = [];
  @Input() selection: SelectionModel<PartDetail> | undefined;
  @Input() canRemove = false;
  @Output() readonly equipmentRemoved = new EventEmitter<number>();

  constructor(public readonly layoutNavService: LayoutNavService) {}

  isAllSelected() {
    if (this.selection) {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
    return false;
  }

  toggleAllRows(change: MatCheckboxChange) {
    if (this.selection) {
      this.selection.clear();
      if (change.checked) {
        this.selection.select(...this.dataSource.data);
      }
    }
  }

  checkboxLabel(row?: PartDetail): string {
    if (this.selection) {
      if (!row) {
        return `${this.selection.selected.length === this.dataSource.data.length ? 'Deselect' : 'Select'} all`;
      }
      return `${this.selection.isSelected(row) ? 'Deselect' : 'Select'} row ${row.Part ?? ''}`;
    }
    return '';
  }

  isSelected(row: PartDetail) {
    return this.selection?.selected.some((selected) => selected?.index === row?.index) ?? false;
  }

  onSelect(change: MatCheckboxChange, row: PartDetail, index: number) {
    if (index !== -1) {
      if (change.checked) {
        this.selection?.select(this.dataSource.filteredData[index]);
      } else {
        const deselectIndex =
          this.selection?.selected.findIndex((selected) => selected?.index === row?.index) ?? -1;
        this.selection?.deselect(this.selection.selected[deselectIndex]);
      }
    }
  }

  onCardSelect(item: PartDetail) {
    if(this.isSelected(item)) {
      this.selection?.deselect(item);
    } else {
      this.selection?.select(item);
    }
  }

}
