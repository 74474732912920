import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { AtmCleaningPhotoUploadKey } from 'utils';
import {
  AtmCleaning,
  AtmSection,
  AtmExteriorEvaluation,
  AtmNetworkLogos,
  AtmCleaningTasks,
  AtmReplenishmentTasks,
} from 'models';
import { ApiService, AuthService, PhotoUploadService } from 'services';
import { AtmCleaningResponse, AtmCleaningDto, AtmCleaningRequest } from 'models/api';
import { atmCleaningMapRequest } from 'models/api/maps/atm-cleaning/atm-cleaning-map-request';
import { FormPageService } from 'pages/form-page/form-page-service';

@Injectable({
  providedIn: 'root',
})
export class AtmCleaningService implements FormPageService<AtmCleaning, AtmCleaningResponse> {
  public form: AtmCleaning = {
    atmSection: new AtmSection(),
    atmExteriorEvaluation: new AtmExteriorEvaluation(),
    atmNetworkLogos: new AtmNetworkLogos(),
    cleaningTasks: new AtmCleaningTasks(),
    replenishmentTasks: new AtmReplenishmentTasks(),
    comments: '',
  };
  workOrder = '';

  constructor(
    private readonly service: ApiService,
    private readonly authService: AuthService,
    private readonly photoUploadService: PhotoUploadService
  ) {}

  updateForm(data: AtmCleaning) {
    this.form = data;
  }

  public getForm(): Observable<AtmCleaningResponse> {
    const params = new HttpParams({
      fromObject: { userId: this.authService.user?.personnelNumber ?? '' },
    });
    return this.service.sendRequest<AtmCleaningResponse>({
      method: 'get',
      url: `pmforms/atmcleaning/${this.workOrder}`,
      params,
      urlPrefix: 'ccc',
    });
  }

  /**
   * Saves ATM cleaning form
   */
  public saveForm(request: AtmCleaningRequest): Observable<string> {
    const saveForm = atmCleaningMapRequest(request);
    return this.service.sendRequest<string>({
      method: 'post',
      url: 'pmforms/atmcleaning',
      body: saveForm,
      urlPrefix: 'ccc',
    });
  }

  public loadPhotos = (data: AtmCleaningDto): Observable<Record<AtmCleaningPhotoUploadKey, string>> => {
    const sources = [
      data.photoFrontClose,
      data.photoATMScreen,
      data.photoATMNetworkDecals,
      data.photoLongDistance,
      data.photoSerialNumber,
      data.photoATMFeeNotice,
      data.photoMissingParts,
      data.photoDamage,
      data.photo_AdditionalPhoto,
      data.photoMissingStickers,
    ].map((url) => {
      if (!url) {
        return Promise.resolve({ sasUrl: '' });
      }
      return this.photoUploadService.getPhotoUrl(url);
    });

    return forkJoin(sources).pipe(
      map(
        ([
          frontClose,
          screen,
          networkDecals,
          longDistance,
          seriaNumber,
          feeNotice,
          missingParts,
          damage,
          additionalPhoto,
          missingStickers,
        ]) => ({
          'atm-front-close': frontClose.sasUrl,
          'atm-screen': screen.sasUrl,
          'atm-network-decals': networkDecals.sasUrl,
          'atm-long-distance': longDistance.sasUrl,
          'atm-serial-number': seriaNumber.sasUrl,
          'atm-fee-notice': feeNotice.sasUrl,
          'atm-missing-parts': missingParts.sasUrl,
          'atm-damage': damage.sasUrl,
          'atm-additional-photo': additionalPhoto.sasUrl,
          'atm-missing-stickers': missingStickers.sasUrl,
        })
      ),
      catchError(() =>
        of({
          'atm-front-close': '',
          'atm-screen': '',
          'atm-network-decals': '',
          'atm-long-distance': '',
          'atm-serial-number': '',
          'atm-fee-notice': '',
          'atm-missing-parts': '',
          'atm-damage': '',
          'atm-additional-photo': '',
          'atm-missing-stickers': '',
        })
      )
    );
  };

  getPhotos(data: AtmCleaningResponse, updateFormPhotos: (photos: Record<AtmCleaningPhotoUploadKey, string>) => void) {
    this.loadPhotos(data.atmCleaning).subscribe((photos) => {
      updateFormPhotos(photos);
    });
  }
}
