import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { WorkOrderStatus } from 'pages/work-order/status/status';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { ModalActionsComponent } from "shared/modal-actions/modal-actions.component";

@Component({
  selector: 'ads-ask-for-complete-or-suspend',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    ModalActionsComponent
],
  templateUrl: './ask-for-complete-or-suspend.component.html',
  styleUrl: './ask-for-complete-or-suspend.component.scss',
})
export class AskForCompleteOrSuspendComponent {
  public readonly _snackBar = inject(MatSnackBar);
  public readonly _bottomSheet = inject(MatBottomSheet);
  public readonly _dialogRef = inject(MatDialog);

  constructor(public workOrderService: WorkOrderService) {}

  hideAll() {
    this._dialogRef.closeAll();
    this._bottomSheet.dismiss();
  }

  onSuspendClick() {
    this.hideAll();
    this.workOrderService.statusSelected(WorkOrderStatus.Suspended);
  }

  onCompleteClick() {
    this.hideAll();
    this.workOrderService.statusSelected(WorkOrderStatus.Completed);
  }

}
