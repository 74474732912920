<canvas
  baseChart
  [data]="chartData"
  [options]="chartOptions"
  [plugins]="chartPlugins ?? []"
  [legend]="legend"
  [type]="'bar'"
  [height]="customHeight"
  [style.maxHeight]="customHeight + 'px'"
></canvas>
