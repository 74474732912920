import { CompleteNa } from '../shared/complete-na';

export class TcdTcrVacuumingBlowOut {
  allNa: boolean;
  safe: CompleteNa | null;
  upperArea: CompleteNa | null;
  fans: CompleteNa | null;
  powerSupply: CompleteNa | null;
  constructor() {
    this.allNa = false;
    this.safe = null;
    this.upperArea = null;
    this.fans = null;
    this.powerSupply = null;
  }
}
