import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'ads-custom-button-inverted',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatMenuModule],
  templateUrl: './custom-button-inverted.component.html',
  styleUrl: './custom-button-inverted.component.scss',
})
export class CustomButtonInvertedComponent {
  @Input() icon = '';
  @Input() label = '';
  @Input() matMenu: MatMenu | null = null;
  @Input() disabled = false;
  @Output() clicked = new EventEmitter<MouseEvent>();
  currentTheme = '';

  onClick(e: MouseEvent) {
    this.clicked.emit(e);
  }
}
