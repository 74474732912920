import { CommonModule } from '@angular/common';
import { Component, Input, AfterViewInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { NightDropSectionFormComponent } from './night-drop-section-form/night-drop-section-form.component';
import { SecurityService } from 'pages/security/security.service';
import { NightDropSectionForm } from 'models/security/night-drop/nightDropSecurityForm';
import { NestedMultipleFormsSectionComponent } from 'forms/shared/nested-multiple-forms-section/nested-multiple-forms-section.component';
import { getFormGroup } from 'utils';
import { SecurityNightDropDropdowns } from 'models/security/security-dropdowns';
import { NdDropDoorTasksSectionComponent } from './nd-drop-door-tasks-section/nd-drop-door-tasks-section.component';
import { NdHeadTasksSectionComponent } from './nd-head-tasks-section/nd-head-tasks-section.component';
import { IndexableFormType } from 'app/utils/miscTypes';
import { TimeClockTasksSectionComponent } from '../shared/time-clock-tasks-section/time-clock-tasks-section.component';
import { ComboLockTasksSectionComponent } from '../shared/combo-lock-tasks-section/combo-lock-tasks-section.component';
import { ComboLockTasks } from 'models/security/shared/shared-tasks';
import { MatInputModule } from '@angular/material/input';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { getSectionStatusFormGroup } from 'app/utils/form-status';
import { onAddItem, onRemoveItem } from 'app/utils/form-group-array';
import { ComboLockSection } from 'models/security/shared/combo-lock';
import { TimeClockSection } from 'models/security/shared/time-clock';

export class NightDropFormSection implements IndexableFormType, NightDropSectionForm {
  index: number;
  nightDropModel: string | null;
  comboLocks: ComboLockSection[];
  timeClocks: TimeClockSection[];
  nightDropManufacturer: string | null;
  nightDropManufacturer_Other: string | null;
  isNightDropEncasedInConcrete: number | null;
  swingType: string | null;
  overallConditionNightDrop: string | null;
  equipment_Rating_NightDrops: string | null;
  nightDropSafeManufacturer: string | null;
  nightDropSafeManufacturer_Other: string | null;
  nightDropDoorStyle: string | null;

  constructor(index: number) {
    this.index = index;
    this.nightDropModel = '';
    this.comboLocks = [];
    this.timeClocks = [];
    this.nightDropManufacturer = '';
    this.nightDropManufacturer_Other = '';
    this.isNightDropEncasedInConcrete = null;
    this.swingType = '';
    this.overallConditionNightDrop = '';
    this.equipment_Rating_NightDrops = '';
    this.nightDropSafeManufacturer = '';
    this.nightDropSafeManufacturer_Other = '';
    this.nightDropDoorStyle = '';
  }
}

@Component({
  selector: 'ads-night-drop',
  standalone: true,
  imports: [
    CommonModule,
    SimpleFormComponent,
    FormsModule,
    MatInputModule,
    ExpansionPanelComponent,
    NestedMultipleFormsSectionComponent,
    NightDropSectionFormComponent,
    TimeClockTasksSectionComponent,
    NdHeadTasksSectionComponent,
    ComboLockTasksSectionComponent,
    NdDropDoorTasksSectionComponent,
    CommonTextAreaComponent,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './night-drop.component.html',
})
export class NightDropComponent implements AfterViewInit {
  @Input() formGroup?: FormGroup | null;
  @Input() submitted = false;
  @Input() dropdowns: SecurityNightDropDropdowns = {
    nightDropNightDropManufacturers: [],
    nightDropNightDropSwingTypes: [],
    nightDropNightDropDoorStyle: [],
    nightDropNightDropOverallConditions: [],
  };

  comboLockFields = Object.getOwnPropertyNames(new ComboLockTasks());
  expandedList: number[] = [];

  constructor(public readonly security: SecurityService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.formGroup?.controls['nightDropDropDoorTasks'].setValue({
        allNa: false,
        ...this.security.nightDropData.nightDropDropDoorTasks,
      });

      this.formGroup?.controls['timeClockTasks'].setValue({
        allNa: false,
        ...this.security.nightDropData.timeClockTasks,
      });

      this.formGroup?.controls['comboLockTasks'].setValue({
        allNa: false,
        ...this.security.nightDropData.comboLockTasks,
      });

      this.formGroup?.controls['nightDropHeadTasks'].setValue({
        allNa: false,
        ...this.security.nightDropData.nightDropHeadTasks,
      });
      this.formGroup?.controls['comments'].setValue(this.security.nightDropData.comments);

      if (this.submitted) {
        this.formGroup?.disable();
      }
    });
  }

  getSection(sectionName: string) {
    return this.formGroup?.controls[sectionName] as FormGroup | undefined;
  }

  getItemSection(sectionName: string, containerName: string) {
    return getFormGroup(this.formGroup?.controls[containerName])?.controls[sectionName] as FormGroup;
  }

  getFormControls(): Record<string, AbstractControl<string, string>> {
    const fg = this.formGroup?.get('nightDropSections') as FormGroup;
    return fg ? fg.controls : {};
  }

  getTasksSection(containerName: string) {
    return this.formGroup?.controls[containerName] as FormGroup;
  }

  onAddClick = () => onAddItem(this.security.nightDropData.nightDropSections, (index) => new NightDropFormSection(index));

  onRemoveNightDrop = (item: NightDropFormSection) => onRemoveItem(this.security.nightDropData.nightDropSections, item);

  getSectionStatus = getSectionStatusFormGroup(this.formGroup);
}
