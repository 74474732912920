<ads-simple-form #tellerTasks="ngModelGroup" [ngModelGroup]="type+'Tasks'">
  <ads-radio-button-group
    label="Are hold up devices zoned out?"
    name="areDevicesZonedOut"
    [required]="true"
    [options]="completeNaOptions"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test {{type}} hold up devices"
    name="testHoldUpDevices"
    [required]="true"
    [options]="completeNaOptions"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
</ads-simple-form>
