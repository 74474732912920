<ads-simple-form #keypads="ngModelGroup" ngModelGroup="keypads">
  <ads-common-form-input
    name="keypadsWithoutZones"
    label="Quantity of keypads (no zones)"
    [maxLength]="4"
    type="number"
    [minValue]="0"
    [required]="true"
    ngModel
  ></ads-common-form-input>
  <ads-common-form-input
    name="keypadsWithZones"
    label="Quantity of keypads (with zones)"
    [maxLength]="4"
    type="number"
    [minValue]="0"
    [required]="true"
    ngModel
    inputMode="tel"
  ></ads-common-form-input>
</ads-simple-form>
