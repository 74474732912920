import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ServiceCallsService } from './sorting.service';
import { DateFilteringComponent } from './date-filtering/date-filtering.component';
import { MobileSortingComponent } from "./mobile-sorting/mobile-sorting.component";
import { DesktopSortingComponent } from "./desktop-sorting/desktop-sorting.component";
import { RadioFilteringComponent } from "./radio-filtering/radio-filtering.component";
import { CommonFormInputComponent } from 'shared/material-wrappers/common-form-input/common-form-input.component';
import { MobileDateFilteringComponent } from "./mobile-filtering/date-status-filtering/mobile-date-filtering.component";
import { MobileStatusFilteringComponent } from "./mobile-filtering/mobile-status-filtering/mobile-status-filtering.component";

export interface DataRange {
  min: number | string | Date;
  max: number | string | Date;
}

export interface SortCriteria {
  column: string;
  asc: boolean;
}

@Component({
  selector: 'ads-service-calls-header',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CommonFormInputComponent,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MobileSortingComponent,
    DesktopSortingComponent,
    RadioFilteringComponent,
    DateFilteringComponent,
    MobileDateFilteringComponent,
    MobileStatusFilteringComponent,
  ],
  templateUrl: './service-calls-header.component.html',
  styleUrl: './service-calls-header.component.scss',
})
export class ServiceCallsHeaderComponent {
  @Input() isDesktopView = false;
  @Input() minDate?: Date;
  @Output() filterChanged = new EventEmitter<void>();
  @Output() sortingChanged = new EventEmitter<SortCriteria>();
  fromDate?: Date;
  toDate?: Date;

  filterCriteria = new Map<string, DataRange>();

  constructor(public service: ServiceCallsService) {}

  onSortingChanged() {
    this.sortingChanged.emit();
  }

  onDateFilterChanged() {
    this.service.dateFilter.minValue = this.fromDate;
    this.service.dateFilter.maxValue = this.toDate;
    this.filterChanged.emit();
  }

  onStatusFilterChanged(newStatus: string) {
    this.service.statusFilter.value = newStatus;
    this.filterChanged.emit();
  }
}
