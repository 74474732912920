import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NumberInputDirective } from 'shared/directives/number-input.directive';
import { ValidateRequiredDirective } from 'shared/directives/validate-required.directive';
import { BaseTableFormComponent } from '../table-form-base';

@Component({
  selector: 'ads-table-form-input',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ValidateRequiredDirective,
    NumberInputDirective,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TableFormInputComponent,
    },
  ],
  templateUrl: './table-form-input.component.html',
  styleUrl: './table-form-input.component.scss',
})
export class TableFormInputComponent extends BaseTableFormComponent implements ControlValueAccessor {
  @Input() onBlur: () => void = () => {};
}
