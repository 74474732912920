import { DifferencePipe } from 'ngx-moment';
import { Component, AfterViewInit, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { CallControlServiceCallsComponent } from './call-control-service-calls/call-control-service-calls.component';
import { CallControlPmBoardComponent } from './call-control-pm-board/call-control-pm-board.component';
import { MatIconModule } from '@angular/material/icon';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { AdsCallControl, CallControlService } from './call-control.service';
import { TimeZonePipe } from 'app/utils/time-zone.pipe';

@Component({
  selector: 'ads-call-control',
  standalone: true,
  providers: [TimeZonePipe],
  imports: [CommonModule, MatTabsModule, CallControlServiceCallsComponent, CallControlPmBoardComponent, MatIconModule],
  templateUrl: './call-control.component.html',
  styleUrl: './call-control.component.scss',
})
export class CallControlComponent implements OnInit, AfterViewInit {
  pmBoardCalls: AdsCallControl[] = [];
  serviceCalls: AdsCallControl[] = [];
  isDesktopView = false;

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly timeZonePipe: TimeZonePipe,
    private readonly ccService: CallControlService
  ) {}

  ngOnInit(): void {
    this.ccService.callControlList().subscribe((response) => {
      const items: AdsCallControl[] = this.mapCalls(response);
      this.pmBoardCalls = items.filter((x) => x.isPm);
      this.serviceCalls = items.filter((x) => !x.isPm);
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe((x) => {
        this.isDesktopView = !x.matches;
      });
    });
  }

  mapCalls(data: AdsCallControl[]) {
    // Migrating logic from previous ADS implementation
    const items: AdsCallControl[] = [];
    const differencePipe = new DifferencePipe();
    data.forEach((x) => {
      let pmFlag = false;
      const now = new Date();
      const dueDate = x.servicedatetime ? this.timeZonePipe.transform(x.servicedatetime.toString()) : new Date();
      const daydiff = differencePipe.transform(now, dueDate, 'day', false);

      if (Math.abs(daydiff) >= 31 && x.problem?.includes('Preventative')) {
        pmFlag = true;
      }

      if (now > dueDate) {
        pmFlag = false;
      }

      items.push({ ...x, isPm: pmFlag });
    });
    return items;
  }
}
