export class AtmCashDispenserTasks {
  allNa: boolean;
  cleanSensors: number | null;
  removeForeignMaterial: number | null;
  cleanRollers: number | null;
  cleanBelts: number | null;
  cleanShutter: number | null;
  replacePickupFeedRollers: number | null;
  checkInterlockSwitch: number | null;
  diagnostic: number | null;

  constructor() {
    this.allNa = false;
    this.cleanSensors = null;
    this.removeForeignMaterial = null;
    this.cleanRollers = null;
    this.cleanBelts = null;
    this.cleanShutter = null;
    this.replacePickupFeedRollers = null;
    this.checkInterlockSwitch = null;
    this.diagnostic = null;
  }
}
