<div class="auth-spinner" *ngIf="authService.loading$ | async">
  <span style="margin-bottom: 10px">Authenticating user</span>
  <mat-spinner color="#2bc954" class="mat-primary"></mat-spinner>
</div>
<div *ngIf="loader.shouldShow" class="loader-overlay">
  <mat-spinner color="#2bc954" class="mat-primary"></mat-spinner>
</div>
@if ((authService.loading$ | async) === false) {
<router-outlet></router-outlet>
}
