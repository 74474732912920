<ads-simple-form *ngIf="item" class="night-drop-section-wrapper" [ngModelGroup]="'nightDropSection#' + item.index">
  <ads-select
    label="Night drop head manufacturer"
    name="nightDropManufacturer"
    [list]="lists?.nightDropNightDropManufacturers"
    [value]="item.nightDropManufacturer"
    [(ngModel)]="item.nightDropManufacturer"
  ></ads-select>
  <ads-common-form-input
    [style.display]="displayOther('nightDropManufacturer') ? 'block' : 'none'"
    name="nightDropManufacturer_Other"
    label="Other night drop head manufacturer"
    [maxLength]="50"
    [required]="displayOther('nightDropManufacturer')"
    [value]="item.nightDropManufacturer_Other"
    [(ngModel)]="item.nightDropManufacturer_Other"
  ></ads-common-form-input>
  <ads-select
    label="Night drop safe manufacturer"
    name="nightDropSafeManufacturer"
    [list]="lists?.nightDropNightDropManufacturers"
    [value]="item.nightDropSafeManufacturer"
    [(ngModel)]="item.nightDropSafeManufacturer"
  ></ads-select>
  <ads-common-form-input
    [style.display]="displayOther('nightDropSafeManufacturer') ? 'block' : 'none'"
    name="nightDropSafeManufacturer_Other"
    label="Other night drop safe manufacturer"
    [maxLength]="50"
    [required]="displayOther('nightDropSafeManufacturer')"
    [value]="item.nightDropSafeManufacturer_Other"
    [(ngModel)]="item.nightDropSafeManufacturer_Other"
  ></ads-common-form-input>
  <ads-common-form-input
    name="nightDropModel"
    label="Night Drop Head Model"
    [maxLength]="50"
    [required]="true"
    [value]="item.nightDropModel"
    [(ngModel)]="item.nightDropModel"
  ></ads-common-form-input>
  <ads-select
    label="Night drop door style"
    name="nightDropDoorStyle"
    [list]="lists?.nightDropNightDropDoorStyle"
    [value]="item.nightDropDoorStyle"
    [(ngModel)]="item.nightDropDoorStyle"
  ></ads-select>
  <ads-radio-button-group
    [options]="options"
    label="Is night drop encased in concrete?"
    name="isNightDropEncasedInConcrete"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <ads-select
    label="Swing Type"
    name="swingType"
    [list]="lists?.nightDropNightDropSwingTypes"
    [value]="item.swingType"
    [(ngModel)]="item.swingType"
  ></ads-select>
  <ads-select
    label="Overall condition - Night Drop"
    name="overallConditionNightDrop"
    [list]="lists?.nightDropNightDropOverallConditions"
    [value]="item.overallConditionNightDrop"
    [(ngModel)]="item.overallConditionNightDrop"
  ></ads-select>
  <ads-common-text-area
    [style.display]="displayOtherCondition() ? 'block' : 'none'"
    name="equipment_Rating_NightDrops"
    label="Substandard equipment comments"
    [maxLength]="500"
    [required]="displayOtherCondition()"
    [value]="item.equipment_Rating_NightDrops"
    [(ngModel)]="item.equipment_Rating_NightDrops"
  ></ads-common-text-area>
  <ng-container ngModelGroup="comboLocks">
    <ads-nested-multiple-forms-section
      #nightDropSectionLocks
      title="Combo Locks"
      itemTitle="Lock"
      [formTemplate]="formTemplateLocks"
      [list]="item.comboLocks"
      [formControls]="getFormControls('comboLocks')"
      [groupName]="'comboLock'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddComboLock()"
      (deleteClicked)="onRemoveComboLock($event)"
      [minItems]="0"
    >
    </ads-nested-multiple-forms-section>
    <ng-template #formTemplateLocks let-item="item">
      <ads-combo-locks-section
        [section]="getSection('comboLock#' + item.index, 'comboLocks')"
        [item]="item"
      ></ads-combo-locks-section>
    </ng-template>
  </ng-container>
  <ng-container ngModelGroup="timeClocks">
    <ads-nested-multiple-forms-section
      #nightDropSectionClocks
      title="Time Clocks"
      itemTitle="Clock"
      [formTemplate]="formTemplateClocks"
      [list]="item.timeClocks"
      [formControls]="getFormControls('timeClocks')"
      [groupName]="'timeClock'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddTimeClock()"
      (deleteClicked)="onRemoveTimeClock($event)"
      [minItems]="0"
    ></ads-nested-multiple-forms-section>
    <ng-template #formTemplateClocks let-item="item">
      <ads-time-clocks-section
        [section]="getSection('timeClock#' + item.index, 'timeClocks')"
        [item]="item"
      ></ads-time-clocks-section>
    </ng-template>
  </ng-container>
</ads-simple-form>
