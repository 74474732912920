import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { AdsNoteRequestModel, DocuRestriction } from 'models/call-control/ack-schedule-models';
import { HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from 'pages/home/home.service';
import { CompleteWoNoteModel } from 'models/notes/complete-wo-note-model';
import { buildNote } from 'app/utils/notes';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  constructor(private readonly service: ApiService, private readonly msalService: MsalService) {}

  createNote = buildNote;

  addNotesForSuspendOrComplete(
    lastNote: CompleteWoNoteModel | null,
    techNotes: string,
    userId: string,
    refRecId: number,
    otherTechnicians?: string
  ) {
    // Build and send tech notes. If a tech note already exists, update it instead of creating a new one.
    if (lastNote !== null && lastNote !== undefined) {
      if (lastNote.nbr && lastNote.nbr > 0) {
        const restriction = lastNote.qcustview == 'N' ? DocuRestriction.Internal : DocuRestriction.External;
        const techNotesModel = this.createNote('Tech Notes', techNotes, refRecId, lastNote.nbr, userId, restriction);
        this.updateNote(techNotesModel).subscribe();
      } else {
        const techNotesModel = this.createNote('Tech Notes', techNotes, DocuRestriction.External, refRecId, userId);
        this.addNote(techNotesModel).subscribe();
      }
    }
    if (otherTechnicians) {
      const noteModel = this.createNote('Other Techs Onsite', otherTechnicians, DocuRestriction.Internal, refRecId, userId);
      this.addNote(noteModel).subscribe();
    }
  }

  /**
   * Gets the personnel id of the user authenticated.
   */
  public addNote(note: AdsNoteRequestModel[]): Observable<void> {
    return this.service.sendRequest<void>({
      method: 'post',
      url: 'docuref',
      urlPrefix: 'ax',
      body: note,
    });
  }

  /**
   * Gets the personnel id of the user authenticated.
   */
  public getNotes(workOrder: string): Observable<AdsNoteRequestModel[]> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    queryParameters.set('workOrder', workOrder);
    return this.service.sendRequest<AdsNoteRequestModel[]>({
      method: 'get',
      url: 'serviceorders/notes/ads',
      urlPrefix: 'ads',
    });
  }

  /**
   * Gets the personnel id of the user authenticated.
   */
  public updateNote(note: AdsNoteRequestModel[]): Observable<void> {
    return this.service.sendRequest<void>({
      method: 'put',
      url: 'docuref',
      urlPrefix: 'ax',
      body: note,
    });
  }
}
