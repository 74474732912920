import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, NgForm, NgModelGroup } from '@angular/forms';
import { SelectOption } from 'models';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-itm-form-section',
  standalone: true,
  imports: [CommonModule, FormsModule, CommonFormInputComponent, SimpleFormComponent, SelectComponent],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './itm-form-section.component.html',
})
export class ItmFormSectionComponent {
  @Input() section?: FormGroup;
  @Input() itmManufacturerList: SelectOption<string>[] = [];
  @Input() depositorTypeList: SelectOption<string>[] = [];
  @Input() communicationTypesList: SelectOption<string>[] = [];

  constructor(@Optional() public readonly ngModelGroup: NgModelGroup) {}

  getValue() {
    return JSON.stringify(this.section?.value);
  }
}
