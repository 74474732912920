import { VaultSectionFormSection } from 'forms/security/vault/vault.component';
import {
  SecurityVaultComboLockTasksDto,
  SecurityVaultSectionDto,
  SecurityVaultTimeClockTasksDto,
} from 'models/api/security/vault/vault-dto';
import { SecurityVaultDto } from 'models/api/security/security';
import { VaultSecurityForm } from 'models/security/vault/vaultSecurityForm';
import { ComboLockTasks, TimeClockTasks } from 'models/security/shared/shared-tasks';

export const mapVaultSectionsResponse = (panels: SecurityVaultSectionDto[]): VaultSectionFormSection[] => {
  if (panels.length === 0) {
    return [new VaultSectionFormSection(1)];
  }
  return panels.map((panel, index) => ({
    vaultModel: panel.vaultModel,
    comboLocks: panel.security_Vault_ComboLocks.map((comboLock, idx) => ({
      ...comboLock,
      index: idx + 1,
    })),
    timeClocks: panel.security_Vault_TimeClocks.map((timeClock, idx) => ({
      ...timeClock,
      isLinkageConnectedDirectlyToComboLock: timeClock.isLinkageConnectedDirectlyToComboLock,
      index: idx + 1,
    })),
    doorRotationTest: panel.doesVaultDoorRemainStationaryAt90Degrees,
    creepDoorCloses: panel.ifnoDoesDoorCreepOpenOrClose,
    manufacturer: panel.vaultManufacturer,
    otherManufacturer: panel.vaultManufacturer_Other,
    overallCondition: panel.overallConditionVault,
    overallConditionComments: panel.equipment_Rating_Vault,
    swingType: panel.swingType,
    ventilatorManufacturer: panel.ventilatorManufacturer,
    ventilatorModel: panel.ventilatorModel,
    ventilatorOverallCondition: panel.overallConditionVentilator,
    ventilatorOverallConditionComments: panel.equipment_Rating_Ventilator,
    index: index + 1,
  }));
};

export const mapSecurityVaultResponse = (data: SecurityVaultDto): VaultSecurityForm => ({
  vaultSections: mapVaultSectionsResponse(data.security_Vault_Vaults),
  timeClockTasks: {
    checkTimeLocksForProperOperation: data.security_Vault_TimeClockTasks[0]?.checkTimeLocksForProperOperation,
    markAnyReplacedTimeClocksWithDateInstalled: data.security_Vault_TimeClockTasks[0]?.markAnyReplacedTimeClocksWithDateInstalled,
    pullRightTimeClockForCleaning: data.security_Vault_TimeClockTasks[0]?.pullRightTimeClockForCleaning,
    replaceLeftTimeClock: data.security_Vault_TimeClockTasks[0]?.replaceLeftTimeClock,
    shiftAllTimeClocksRight: data.security_Vault_TimeClockTasks[0]?.shiftAllTimeClocksRight,
  },
  comboLockTasks: {
    checkComboLockForProperOperation: data.security_Vault_ComboLockTasks[0]?.checkComboLockForProperOperation,
    checkForDialAndRingProperOperation: data.security_Vault_ComboLockTasks[0]?.checkForDialAndRingProperOperation,
    disassembleAndInspectComboLock: data.security_Vault_ComboLockTasks[0]?.disassembleAndInspectComboLock,
    disassembleAndInspectDialAndRing: data.security_Vault_ComboLockTasks[0]?.disassembleAndInspectDialAndRing,
    lubricateAndReassembleComboLock: data.security_Vault_ComboLockTasks[0]?.lubricateAndReassembleComboLock,
  },
  comments: data.comments ?? null,
});

export const mapVaultTimeClockTasksResponse = (tasks: SecurityVaultTimeClockTasksDto[]): TimeClockTasks => {
  if (!tasks || tasks?.length === 0) return new TimeClockTasks();
  return {
    checkTimeLocksForProperOperation: tasks[0].checkTimeLocksForProperOperation,
    pullRightTimeClockForCleaning: tasks[0].pullRightTimeClockForCleaning,
    shiftAllTimeClocksRight: tasks[0].shiftAllTimeClocksRight,
    replaceLeftTimeClock: tasks[0].replaceLeftTimeClock,
    markAnyReplacedTimeClocksWithDateInstalled: tasks[0].markAnyReplacedTimeClocksWithDateInstalled,
  };
};

export const mapVaultComboLockTasksResponse = (tasks: SecurityVaultComboLockTasksDto[]): ComboLockTasks => {
  if (!tasks || tasks?.length === 0) return new ComboLockTasks();
  return {
    checkComboLockForProperOperation: tasks[0].checkComboLockForProperOperation,
    disassembleAndInspectComboLock: tasks[0].disassembleAndInspectComboLock,
    lubricateAndReassembleComboLock: tasks[0].lubricateAndReassembleComboLock,
    checkForDialAndRingProperOperation: tasks[0].checkForDialAndRingProperOperation,
    disassembleAndInspectDialAndRing: tasks[0].disassembleAndInspectDialAndRing,
  };
};
