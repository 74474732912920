import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CustomButtonComponent } from '../custom-button/custom-button.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ads-prompt-wrapper',
  standalone: true,
  imports: [CommonModule, MatButtonModule, CustomButtonComponent],
  templateUrl: './prompt-wrapper.component.html',
  styleUrl: './prompt-wrapper.component.scss',
})
export class PromptWrapperComponent {
  @Input() primaryButtonLabel = 'Confirm';
  @Input() secondaryButtonLabel = '';
  @Input() cancelButtonLabel = 'Cancel';

  @Output() primaryButtonClicked = new EventEmitter<MouseEvent>();
  @Output() secondaryButtonClicked = new EventEmitter<MouseEvent>();
  @Output() cancelButtonClicked = new EventEmitter<MouseEvent>();

  onCancelClick() {
    this.cancelButtonClicked.emit();
  }

  onSecondaryClick() {
    this.secondaryButtonClicked.emit();
  }

  primaryButtonClick() {
    this.primaryButtonClicked.emit();
  }

}
