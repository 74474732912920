import { PhotoUpload } from './photo-upload';

export const itmCleaningPhotoUploadKeys = [
  'itm-front-close',
  'itm-screen',
  'itm-network-decals',
  'itm-long-distance',
  'itm-serial-number',
  'itm-fee-notice',
  'itm-missing-parts',
  'itm-damage',
  'itm-additional-photo',
  'itm-missing-stickers',
] as const;

export type ItmCleaningPhotoUploadKey = (typeof itmCleaningPhotoUploadKeys)[number];

export const itmCleaningPhotoUploads: PhotoUpload<ItmCleaningPhotoUploadKey>[] = [
  {
    label: 'Front close (showing stickers)',
    key: 'itm-front-close',
    required: true,
  },
  {
    label: 'ITM screen',
    key: 'itm-screen',
    required: true,
  },
  {
    label: 'ITM network decals',
    key: 'itm-network-decals',
    required: true  ,
  },
  {
    label: "Long distance, approx. 20'",
    key: 'itm-long-distance',
    required: true,
  },
  {
    label: 'Serial number',
    key: 'itm-serial-number',
    required: true,
  },
  {
    label: 'ITM fee notice (optional)',
    key: 'itm-fee-notice',
    required: false,
  },
  {
    label: 'Missing parts (optional)',
    key: 'itm-missing-parts',
    required: false,
  },
  {
    label: 'Damage (optional)',
    key: 'itm-damage',
    required: false,
  },
  {
    label: 'Additional photo (optional)',
    key: 'itm-additional-photo',
    required: false,
  },
  {
    label: 'Missing stickers (optional)',
    key: 'itm-missing-stickers',
    required: false,
  },
];
