<ads-simple-form
  *ngIf="item"
  class="access-hardware-power-supply-wrapper"
  [ngModelGroup]="'accessControlPowerSupply#' + item.index"
>
  <ads-common-form-input
    name="location"
    label="Location"
    [maxLength]="50"
    [required]="true"
    [value]="item.location"
    [(ngModel)]="item.location"
  ></ads-common-form-input>

  <ads-select
    label="Power supply"
    name="powerSupply"
    [list]="lists?.acwPowerSupplyTablePowerSupply"
    [value]="item.powerSupply"
    [(ngModel)]="item.powerSupply"
  ></ads-select>

  <ads-common-form-input
    [style.display]="displayOtherPowerSupply() ? 'block' : 'none'"
    name="powerSupply_Other"
    label="Other Power Supply"
    [maxLength]="50"
    [required]="displayOtherPowerSupply()"
    [value]="item.powerSupply_Other"
    [(ngModel)]="item.powerSupply_Other"
  ></ads-common-form-input>

  <ads-select
    label="Voltage"
    name="voltage"
    [list]="lists?.acwPowerSupplyTableVoltage"
    [value]="item.voltage"
    [(ngModel)]="item.voltage"
  ></ads-select>

  <ads-common-form-input
    name="batteryAhr"
    label="Battery Ahr"
    type="number"
    [minValue]="0"
    [maxLength]="8"
    [required]="true"
    [value]="item.batteryAhr"
    [(ngModel)]="item.batteryAhr"
    inputMode="tel"
  ></ads-common-form-input>

  <ads-common-form-input
    name="batteryVoltageAfter1HourPowerLoss"
    label="Voltage test"
    tooltip="After 1 hour of power loss"
    [maxLength]="10"
    [required]="true"
    [value]="item.batteryVoltageAfter1HourPowerLoss"
    [(ngModel)]="item.batteryVoltageAfter1HourPowerLoss"
  ></ads-common-form-input>
</ads-simple-form>
