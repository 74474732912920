<ads-simple-form #keypadTasks="ngModelGroup" ngModelGroup="keypadTasks">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button
  ><mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test keypad tamper (WFB only)"
    name="keypadTamperTest"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test duress code"
    name="duressCodeTest"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    label="Test Ack70 (WFB only)"
    name="ack70Test"
    [required]="true"
    [options]="completeNaOptions"
    (optionClicked)="onOptionClicked()"
    ngModel
  >
  </ads-radio-button-group>
</ads-simple-form>



