import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';
import { FormsModule, ControlContainer, NgForm, FormGroup, AbstractControl } from '@angular/forms';
import { NestedMultipleFormsSectionComponent } from 'forms/shared/nested-multiple-forms-section/nested-multiple-forms-section.component';
import { PhotoUploadControlService } from 'services';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { UcsSectionFormComponent } from './ucs-section-form/ucs-section-form.component';
import { SecurityService } from 'pages/security/security.service';
import { UCSForm } from 'models/security';
import { SecurityUCSDropdowns } from 'models/security/security-dropdowns';
import { UcsTasksSectionComponent } from './ucs-tasks-section/ucs-tasks-section.component';
import { getFormGroup } from 'utils';
import { IndexableFormType } from 'app/utils/miscTypes';
import { MatInputModule } from '@angular/material/input';
import { CommonTextAreaComponent } from '../../../shared/material-wrappers/common-text-area/common-text-area.component';
import { getSectionStatusFormGroup } from 'app/utils/form-status';

export class UCSFormSection implements IndexableFormType, UCSForm {
  index: number;
  ucsModel: string | null;
  ucsManufacturer: string | null;
  ucsManufacturer_Other: string | null;
  overallConditionUCS: string | null;
  overallConditionUCS_Other: string | null;
  quantityComboLocks: string | null;
  quantityOfPortaLocks: string | null;

  constructor(index: number) {
    this.index = index;
    this.ucsModel = '';
    this.ucsManufacturer = '';
    this.ucsManufacturer_Other = '';
    this.overallConditionUCS = '';
    this.overallConditionUCS_Other = '';
    this.quantityComboLocks = '';
    this.quantityOfPortaLocks = '';
  }
}

@Component({
  selector: 'ads-ucs',
  standalone: true,
  imports: [
    CommonModule,
    SimpleFormComponent,
    FormsModule,
    MatInputModule,
    ExpansionPanelComponent,
    NestedMultipleFormsSectionComponent,
    UcsSectionFormComponent,
    UcsTasksSectionComponent,
    CommonTextAreaComponent,
  ],
  providers: [PhotoUploadControlService],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './ucs.component.html',
  styleUrl: './ucs.component.scss',
})
export class UcsComponent implements AfterViewInit {
  @Input() formGroup?: FormGroup | null;
  @Input() submitted = false;
  @Input() ucsDropdowns: SecurityUCSDropdowns = {
    ucsUcsManufacturers: [],
    ucsUcsOverallConditions: [],
  };
  expandedList: number[] = [];

  constructor(public readonly security: SecurityService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.formGroup?.controls['ucsTasks'].setValue({
        allNa: false,
        checkForProperOperation: this.security.ucsData.ucsTasks.checkForProperOperation ?? null,
        inspectForExcessiveWear: this.security.ucsData.ucsTasks.inspectForExcessiveWear ?? null,
        cleanAndLubricate: this.security.ucsData.ucsTasks.cleanAndLubricate ?? null,
      });
      this.formGroup?.controls['comments'].setValue(this.security.ucsData.comments);

      if (this.submitted) {
        this.formGroup?.disable();
      }
    });
  }

  getItemSection(sectionName: string) {
    return getFormGroup(this.formGroup?.controls['ucsItems'])?.controls[sectionName] as FormGroup;
  }

  getTasksSection() {
    return this.formGroup?.controls['ucsTasks'] as FormGroup;
  }

  getFormControls(): Record<string, AbstractControl<string, string>> {
    const fg = this.formGroup?.get('ucsItems') as FormGroup;
    return fg ? fg.controls : {};
  }

  onAddClick() {
    const maxIndex =
      this.security.ucsData.ucsSections.length === 0
        ? 1
        : Math.max(...this.security.ucsData.ucsSections.map((item) => item.index)) + 1;
    const newEmptyUcs: UCSFormSection = {
      index: maxIndex,
      ucsModel: '',
      ucsManufacturer: '',
      ucsManufacturer_Other: '',
      overallConditionUCS: '',
      overallConditionUCS_Other: '',
      quantityComboLocks: '',
      quantityOfPortaLocks: '',
    };
    this.security.ucsData.ucsSections.push(newEmptyUcs);
  }

  onRemoveUCS(item: UCSFormSection) {
    const indexAt = this.security.ucsData.ucsSections.indexOf(item);
    this.security.ucsData.ucsSections.splice(indexAt, 1);
    this.security.ucsData.ucsSections = this.security.ucsData.ucsSections.map((x, index) => ({
      ...x,
      index: index + 1,
    }));
  }

  getSectionStatus = getSectionStatusFormGroup(this.formGroup);
}
