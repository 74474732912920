import { AbstractControl, ValidationErrors } from '@angular/forms';

export const validateRequired =
  (shouldCheck: boolean) =>
  (control: AbstractControl): ValidationErrors | null => {
    if (!shouldCheck) {
      return null;
    }
    const value = control.value;
    if (value === null) {
      return null;
    }
    if (typeof value === 'string' && value.length > 0) {
      return null;
    } else {
      return { required: true };
    }
  };
