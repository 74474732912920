<ng-template #menu>
  <div class="menu">
    <button
      mat-button
      mat-flat-button
      class="main-button launch"
      (click)="launchFormClicked()"
      *ngIf="
        workOrderService.workOrderDetails().problemTypeId === 'PM' ||
        workOrderService.workOrderDetails().problemTypeId === 'Survey'
      "
    >
      Launch Form
    </button>
    <ads-custom-prompt label="Open calls" (clicked)="openCallsClicked()"></ads-custom-prompt>
    <ads-custom-prompt label="Site profile" (clicked)="siteProfileClicked()"></ads-custom-prompt>
    <button
      mat-button
      mat-flat-button
      *ngIf="workOrderService.workOrderDetails().isArticleAssociated"
      class="launch main-button"
      label="Articles"
      (click)="articleClicked()"
    >
      Articles
    </button>
    <ads-custom-prompt label="Support" (clicked)="supportClicked()"></ads-custom-prompt>
    <ads-custom-prompt label="Change Priority" (clicked)="changePriorityClicked()"></ads-custom-prompt>
    <ads-custom-prompt label="Cancel" (clicked)="cancelClicked()"></ads-custom-prompt>
  </div>
</ng-template>

<ng-template #requestSupportTemplate>
  <ads-support-request></ads-support-request>
</ng-template>

<ng-template #requestCancelTemplate>
  <ads-cancel-request></ads-cancel-request>
</ng-template>
<ng-template #changePriorityTemplate>
  <ads-change-priority></ads-change-priority>
</ng-template>
