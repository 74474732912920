export class ItmSection {
  manufacturer: string | null;
  serialNumber: string | null;
  terminalId: string | null;
  depositorType: string | null;
  model: string | null;
  communicationType: string | null;
  networkTerminalId: string | null;

  constructor() {
    this.manufacturer = '';
    this.serialNumber = '';
    this.terminalId = '';
    this.depositorType = '';
    this.model = '';
    this.communicationType = '';
    this.networkTerminalId = '';
  }
}
