import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ControlContainer, FormGroup, FormsModule, NgModelGroup } from '@angular/forms';
import { CompleteNa, SelectOption } from 'models';
import { SingleToggleButtonComponent, RadioButtonGroupComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-hub-tasks',
  standalone: true,
  imports: [SingleToggleButtonComponent, RadioButtonGroupComponent, SimpleFormComponent, MatDividerModule, FormsModule],
  templateUrl: './hub-tasks.component.html',
  styleUrl: './hub-tasks.component.scss',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class HubTasksComponent {
  @Input() section?: FormGroup;
  @Input() type = 'teller';

  completeNaOptions: SelectOption<CompleteNa>[] = [
    { name: 'Complete', value: CompleteNa.Complete },
    { name: 'N/A', value: CompleteNa.Na },
  ];
}
