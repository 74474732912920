import { SurveillanceCameraTableSection, SurveillanceDVRNVRSection, SurveillanceMonitorTableSection, SurveillancePOESwitchSection, SurveillanceUPSSection } from "forms/security/surveillance/surveillance.component";

export interface SurveillanceDVRNVRForm {
  dvrManufacturer: string | null;
  dvrManufacturer_Other: string | null;
  overallCondition_DVRNVR: string | null;
  dvrModel: string | null;
  dvrSerialNumber: string | null;
  softwareVersion: string | null;
  totalChannelsInDVR: string | null;
  totalChannelsAvailableForUse: string | null;
  totalAnalogInputs: string | null;
  totalAnalogInputsAvailableForUse: string | null;
  totalHardDriveSize: string | null;
  daysOfRetention: string | null;
  equipment_Rating: string | null;
  security_Surveillance: string | null;
}

export interface SurveillanceUPSForm {
  upsManufacturer: string | null;
  upsManufacturer_Other: string | null;
  upsModel: string | null;
  numberOfOutputs: string | null;
}

export interface SurveillancePOESwitchForm {
  poeSwitchManufacturer: string | null;
  poeSwitchManufacturer_Other: string | null;
  poeSwitchModel: string | null;
}

export interface SurveillanceCameraTableForm {
  camera: string | null;
  cameraType: string | null;
  isPTZ: string | null;
}

export interface SurveillanceMonitorTableForm {
  monitor: string | null;
  monitorType: string | null;
}

export class SurveillanceDVRTasksForm {
  checkTimeDate: number | null;
  verifiedAllCamerasShowLiveVideo: number | null;
  verifiedAllCamerasShowRecordedVideo: number | null;
  cleanedDVRCase: number | null;
  inspectConnectors: number | null;
  inspectInternalFanOperation: number | null;
  cleanedInsideEnclosure: number | null;
  verifyXterms: number | null;
  inspectAllWireConnectionsInPowerSupply: number | null;
  inspectAndCleanPOESwitch: number | null;

  constructor() {
    this.checkTimeDate = null;
    this.verifiedAllCamerasShowLiveVideo = null;
    this.verifiedAllCamerasShowRecordedVideo = null;
    this.cleanedDVRCase = null;
    this.inspectConnectors = null;
    this.inspectInternalFanOperation = null;
    this.cleanedInsideEnclosure = null;
    this.verifyXterms = null;
    this.inspectAllWireConnectionsInPowerSupply = null;
    this.inspectAndCleanPOESwitch = null;
  }
}

export class SurveillanceCameraTasksForm {
  inspectCameraPositionFocus: number | null;
  inspectCameraWireConnection: number | null;
  cleanCamerasAndHousings: number | null;

  constructor() {
    this.inspectCameraPositionFocus = null;
    this.inspectCameraWireConnection = null;
    this.cleanCamerasAndHousings = null;
  }
}

export class SurveillanceMonitorTasksForm {
  inspectMonitorOperation: number | null;
  cleanMonitor: number | null;

  constructor() {
    this.inspectMonitorOperation = null;
    this.cleanMonitor = null;
  }
}

export interface SurveillanceSecurityForm {
  overallConditionCameras: string | null;
  equipmentRatingCameras: string | null;
  overallConditionMonitor: string | null;
  equipmentRatingMonitor: string | null;
  surveillanceDvrNvr: SurveillanceDVRNVRSection[];
  surveillanceUps: SurveillanceUPSSection[];
  surveillancePoeSwitch: SurveillancePOESwitchSection[];
  surveillanceCameraTable: SurveillanceCameraTableSection[];
  surveillanceMonitorTable: SurveillanceMonitorTableSection[];
  surveillanceDvrTasks: SurveillanceDVRTasksForm;
  surveillanceCameraTasks: SurveillanceCameraTasksForm;
  surveillanceMonitorTasks: SurveillanceMonitorTasksForm;
  comments: string | null;
}
