<table mat-table [dataSource]="list" *ngIf="list.length > 0">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? toggleAllRows(list, selection) : null"
        [checked]="selection.hasValue() && isAllSelected(list, selection)"
        [indeterminate]="selection.hasValue() && !isAllSelected(list, selection)"
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        color="#b3261e"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="monitor">
    <th mat-header-cell *matHeaderCellDef>Monitor</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-input
        [ngModelGroup]="'monitorTableItem#' + (i + 1)"
        name="monitor"
        [maxLength]="50"
        [required]="true"
        [value]="element.monitor"
        [(ngModel)]="element.monitor"
      ></ads-table-form-input>
    </td>
  </ng-container>

  <ng-container matColumnDef="monitorType">
    <th mat-header-cell *matHeaderCellDef>Monitor Type</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-bottom-sheet-input
        [ngModelGroup]="'monitorTableItem#' + (i + 1)"
        name="monitorType"
        [required]="true"
        [value]="element.monitorType"
        [(ngModel)]="element.monitorType"
        [openBottomSheet]="openBottomSheet(element)"
      ></ads-table-form-bottom-sheet-input>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumnsKeys()"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsKeys()"></tr>
</table>
