import { Component } from '@angular/core';
import { BaseAddManyModalComponent } from 'forms/shared/nested-section-base/add-many-modal.base';
import { AccessControlDoorTableFormSection } from '../access-hardware.component';
import { TableBottomSheetsComponent } from 'forms/shared/table-bottom-sheets/table-bottom-sheets.component';
import { AccessControlHardwareDropdowns } from 'models/security/security-dropdowns';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { TableFormBottomSheetInputComponent } from 'shared/material-wrappers/table-form/table-form-bottom-sheet-input/table-form-bottom-sheet-input.component';
import { TableFormInputComponent } from 'shared/material-wrappers/table-form/table-form-input/table-form-input.component';

export interface AhDoorsTableSectionAddMany {
  quantity: string | undefined;
  readerStyle: string | undefined;
  lockStyle: string | undefined;
  index: number;
}

@Component({
  selector: 'ads-ah-doors-table-add-many-modal',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogContent,
    MatDialogActions,
    MatTableModule,
    CommonModule,
    FormsModule,
    MatInputModule,
    TableFormInputComponent,
    TableFormBottomSheetInputComponent,
    MatBottomSheetModule,
  ],
  templateUrl: './ah-doors-table-add-many-modal.component.html',
  styleUrl: './ah-doors-table-add-many-modal.component.scss',
})
export class AhDoorsTableAddManyModalComponent extends BaseAddManyModalComponent {
  displayedColumns = ['quantity', 'readerStyle', 'lockStyle'];

  dataSource: AhDoorsTableSectionAddMany[] = [
    { quantity: undefined, readerStyle: undefined, lockStyle: undefined, index: 0 },
  ];

  openBottomSheet = (e: unknown) => () => {
    const element = e as AccessControlDoorTableFormSection;
    const onDismiss = this._bottomSheet.open(TableBottomSheetsComponent, {
      data: {
        selections: [
          {
            title: 'Reader Style',
            control: 'readerStyle',
            options: (this.data.dropdowns as AccessControlHardwareDropdowns)?.acwDoorTableReaderStyles,
            value: element.readerStyle,
          },
          {
            title: 'Lock Style',
            control: 'lockStyle',
            options: (this.data.dropdowns as AccessControlHardwareDropdowns)?.acwDoorTableLockStyles,
            value: element.lockStyle,
          },
        ],
      },
    });

    onDismiss.afterDismissed().subscribe((result) => {
      if (result) {
        element.readerStyle = result.readerStyle;
        element.lockStyle = result.lockStyle;
      }
    });
  };
}
