<ads-simple-form #selectedEquipment="ngModelGroup" [ngModelGroup]="groupName">
  <div class="select-all">
    <span class="label-all" [style.width]="'45%'">Select all</span>
    <mat-button-toggle-group
      class="two-column-group"
      [style.width]="'45%'"
      name="all"
      [value]="options.length === value.length ? 'all' : ''"
    >
      <mat-button-toggle
        class="option"
        [checked]="options.length === value.length"
        value="all"
        [style.width]="'100%'"
        (click)="selectAll()"
        (keyup)="onKeyPress($event)"
        [disabled]="submitted"
      >
        <span class="label" [ngClass]="{ selected: options.length === value.length }">Select all</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <mat-button-toggle-group multiple class="two-column-group" name="selectedEquipment" ngModel>
    @for (item of options; track item) {
    <mat-button-toggle
      class="option"
      [ngClass]="{ defaultWidth: item.width === '95%' }"
      [style.width]="item.width"
      [value]="item.value"
      [disabled]="submitted"
    >
      <span class="label" [ngClass]="{ selected: isItemSelected(item.value) }">{{ item.name }}</span>
    </mat-button-toggle>
    }
  </mat-button-toggle-group>
</ads-simple-form>
