import { Injectable } from '@angular/core';
import { SelectOption } from 'models';
import { FilterOption } from 'models/shared/filter-option';
import { SortingOption } from 'models/shared/sorting-option';
import { CustomPromptComponent } from 'shared/custom-prompt/custom-prompt.component';

@Injectable({
  providedIn: 'root',
})
export class ServiceCallsService {
  sortOptions: SortingOption<string>[] = [
    {
      name: '- None -',
      value: 'none',
      isAsc: true,
      ascLabel: '',
      descLabel: '',
    },
    {
      name: 'Workorder',
      value: 'workorder',
      isAsc: true,
      ascLabel: 'Lowest First',
      descLabel: 'Highest First',
    },
    {
      name: 'Date',
      value: 'dateopened',
      isAsc: true,
      ascLabel: 'Oldest First',
      descLabel: 'Recent First',
    },
    {
      name: 'Customer',
      value: 'customer',
      isAsc: true,
      ascLabel: 'A-Z',
      descLabel: 'Z-A',
    },
    {
      name: 'Address',
      value: 'address',
      isAsc: true,
      ascLabel: 'State: A-Z',
      descLabel: 'State: Z-A',
    },
  ];

  dateFilter: FilterOption<Date> = {
    value: 'dateopened',
    name: 'Date',
    isList: false,
    maxValue: undefined,
    minValue: undefined,
    defaultMinValue: new Date('01/01/1999'),
    defaultMaxValue: new Date('01/01/2099'),
  };

  statusFilter: FilterOption<SelectOption<string>> = {
    value: 'none',
    name: 'Status',
    isList: true,
    options: [
      {
        name: '- None -',
        value: 'none',
      },
      {
        name: 'Opened',
        value: 'Opened',
      },
      {
        name: 'Notified',
        value: 'Notified',
      },
      {
        name: 'Started',
        value: 'Started',
      },
      {
        name: 'Scheduled',
        value: 'Scheduled',
      },
      {
        name: 'Travelling',
        value: 'Travelling',
      },
      {
        name: 'Suspended',
        value: 'Suspended',
      },
      {
        name: 'Acknowledged',
        value: 'Ack',
      },
    ],
  };

  sorting: Sorting = {
    sortAsc: false,
    sortSaved: false,
    tempSortCriteria: '',
    tempSortingLabel: '',
    activeSortCriteria: 'none',
    activeSortingLabel: '- None -',
  };

  mobilePrompt: CustomPromptComponent | null = null;
  searchText = '';

  constructor() {}

  onConfirmSort() {
    this.sorting.activeSortCriteria = this.sorting.tempSortCriteria;
    this.sorting.activeSortingLabel = this.sorting.tempSortingLabel;
    this.mobilePrompt?.close();
    this.sorting.sortSaved = true;
  }

  sortClicked(v: string | string[]) {
    const column = v as string;
    let newSortingLabel = '';
    let asc = true;
    switch (column) {
      case 'workorder':
        newSortingLabel = 'Lowest First';
        break;
      case 'dateopened':
        newSortingLabel = 'Recent First';
        asc = false;
        break;
      case 'address':
        newSortingLabel = 'State: A-Z';
        break;
      default:
        newSortingLabel = 'A-Z';
    }
    this.setSort(column, asc, newSortingLabel);
  }

  setSort(column: string, asc: boolean, sortingLabel: string) {
    this.sorting.tempSortCriteria = column;
    this.sorting.sortAsc = asc;
    this.sorting.tempSortingLabel = sortingLabel;
  }

  onSortOpened() {
    this.setSort(this.sorting.activeSortCriteria, false, this.sorting.activeSortingLabel);
  }

  onSortDismissed() {
    if (this.sorting.sortSaved) {
      this.sorting.tempSortCriteria = this.sorting.activeSortCriteria;
      this.sorting.tempSortingLabel = this.sorting.activeSortingLabel;
    }
    this.sorting.sortSaved = false;
  }
}

export interface Sorting {
  sortAsc: boolean;
  sortSaved: boolean;
  tempSortCriteria: string;
  tempSortingLabel: string;
  activeSortCriteria: string;
  activeSortingLabel: string;
}

export interface Filtering {
  minValue: string;
}
