import { Component, EventEmitter, Input, Output } from '@angular/core';
 import { FormsModule } from '@angular/forms';
import { DateFilteringComponent } from '../../date-filtering/date-filtering.component';

@Component({
  selector: 'ads-mobile-date-filtering',
  standalone: true,
  imports: [FormsModule, DateFilteringComponent],
  templateUrl: './mobile-date-filtering.component.html',
})
export class MobileDateFilteringComponent {
  @Input() label = '';
  @Input() minDate?: Date;
  @Output() dateFilterConfirmed = new EventEmitter<void>();
  @Output() toDateChanged = new EventEmitter<Date>();
  @Output() fromDateChanged = new EventEmitter<Date>();
}
