import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { getFormStatus } from 'app/utils/form-status';
import { HeaderService } from 'shared/header/header.service';

@Component({
  selector: 'ads-expansion-panel',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatIconModule],
  templateUrl: './expansion-panel.component.html',
  styleUrl: './expansion-panel.component.scss',
})
export class ExpansionPanelComponent implements OnInit {
  @Input() title = '';
  @Input() expanded = false;
  @Input() showDeleteButton = false;
  @Input() name = '';
  @Input() hideStatusIcon = false;
  @Input() formGroup?: FormGroup | null;
  @Input() customStatus?: (sectionName: string) => 'dirty' | 'valid' | 'pristine';

  constructor(public readonly headerService: HeaderService) {
    headerService.sections.push(this);
  }

  ngOnInit(): void {
    if (this.name !== '') {
      document.addEventListener(`expand-${this.name}`, () => (this.expanded = true), false);
    }
  }

  getFormStatus = () => {
    if (this.customStatus) {
      return this.customStatus;
    }
    return getFormStatus(this.formGroup);
  };

  setExpanded(newValue: boolean) {
    this.expanded = newValue;
  }

  onExpand() {
    this.headerService.expanded = true;
  }

  checkIfAllSectionsAreCollapsed() {
    this.headerService.checkAllCollapsed();
  }
}
