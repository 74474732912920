import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdsCallControl } from 'models';
import { CustomHttpUrlEncodingCodec } from 'pages/home/home.service';
import { Observable } from 'rxjs';
import { ApiService, AuthService } from 'services';

@Injectable({
  providedIn: 'root',
})
export class CallControlService {
  constructor(private readonly service: ApiService, private readonly authService: AuthService) {}

  /**
   * Gets a call list according with the parameters used into the request
   *
   * @param techIdMaster technician id
   * @param isMyOpenCalls flag to indicate when the result will be filtered by open  status call
   */
  public callControlList(isMyOpenCalls?: boolean): Observable<AdsCallControl[]> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (isMyOpenCalls !== undefined && isMyOpenCalls !== null) {
      queryParameters = queryParameters.set('isMyOpenCalls', isMyOpenCalls);
    }

    const clientOffsetHours = (new Date().getTimezoneOffset() / 60) * -1;
    queryParameters = queryParameters.set('clientOffsetHours', clientOffsetHours);

    return this.service.sendRequest<AdsCallControl[]>({
      method: 'get',
      url: `callcontrol?techIdMaster=${this.authService.user?.personnelNumber ?? ''}`,
      params: queryParameters,
    });
  }
}
