export enum WorkOrderStatus {
  'Opened' = 'Opened',
  'Notified' = 'Notified',
  'Acknowledged' = 'Acknowledged',
  'Scheduled' = 'Scheduled',
  'Travelling' = 'Travelling',
  'Started' = 'Started',
  'Completed' = 'Completed',
  'Suspended' = 'Susp-Other',
}

export const nextStatus = new Map<WorkOrderStatus, WorkOrderStatus[]>([
  [WorkOrderStatus.Suspended, [WorkOrderStatus.Travelling, WorkOrderStatus.Acknowledged, WorkOrderStatus.Scheduled]],
  [WorkOrderStatus.Opened, [WorkOrderStatus.Acknowledged, WorkOrderStatus.Scheduled]],
  [WorkOrderStatus.Acknowledged, [WorkOrderStatus.Scheduled, WorkOrderStatus.Travelling]],
  [WorkOrderStatus.Scheduled, [WorkOrderStatus.Acknowledged, WorkOrderStatus.Travelling]],
  [WorkOrderStatus.Notified, [WorkOrderStatus.Acknowledged, WorkOrderStatus.Scheduled]],
  [WorkOrderStatus.Started, [WorkOrderStatus.Completed, WorkOrderStatus.Suspended]],
  [WorkOrderStatus.Travelling, [WorkOrderStatus.Started]],
  [WorkOrderStatus.Completed, []],
]);

export const workOrderStatuses = Object.values(WorkOrderStatus);
