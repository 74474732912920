export class ItmPeripheralTasks {
  cleanPreHead: number | null;
  cleanAllBelts: number | null;
  cleanReadHead: number | null;
  cleanWithFeltCard: number | null;
  blowOutSensorAreas: number | null;
  performDiagnosticTest: number | null;
  isCardReaderEmvCapable: number | null;
  connectToTeller: number | null;
  testAudioVideo: number | null;
  testSignaturePad: number | null;
  testPhone: number | null;
  testScanner: number | null;
  testCoinDispenser: number | null;
  testVideoFromTeller: number | null;

  constructor() {
    this.cleanPreHead = null;
    this.cleanAllBelts = null;
    this.cleanReadHead = null;
    this.cleanWithFeltCard = null;
    this.blowOutSensorAreas = null;
    this.performDiagnosticTest = null;
    this.isCardReaderEmvCapable = null;
    this.connectToTeller = null;
    this.testAudioVideo = null;
    this.testSignaturePad = null;
    this.testPhone = null;
    this.testScanner = null;
    this.testCoinDispenser = null;
    this.testVideoFromTeller = null;
  }
}
