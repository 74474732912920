<ads-order-info [order]="workOrder" [customerName]="customerName"></ads-order-info>

<form #itmForm="ngForm" (ngSubmit)="onSubmit()">
  <ads-expansion-panel
    #itmSpecsSectionPanel
    title="ITM Specs"
    [formGroup]="form?.form"
    [name]="'itmSpecs'"
    [class]="'itmSpecs'"
  >
    <ads-itm-specs-section
      [section]="getSection('itmSpecs')"
      [itmManufacturerList]="dropdowns.specsManufacturerList"
      [depositorTypeList]="dropdowns.specsDepositorsList"
      [communicationTypeList]="dropdowns.communicationTypesList"
      [overallConditionList]="dropdowns.specsOverallConditionsList"
    ></ads-itm-specs-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #softwareSectionPanel
    title="Software"
    [formGroup]="form?.form"
    [name]="'software'"
    [class]="'software'"
  >
    <ads-itm-software-section
      [section]="getSection('software')"
      [operatingSystemsList]="dropdowns.operatingSystemsList"
    ></ads-itm-software-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #itmTasksSectionPanel
    title="ITM Tasks"
    [formGroup]="form?.form"
    [name]="'itmTasks'"
    [class]="'itmTasks'"
  >
    <ads-itm-tasks-section [section]="getSection('itmTasks')"></ads-itm-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #itmPeripheralTasksSectionPanel
    title="Peripheral Tasks"
    [formGroup]="form?.form"
    [name]="'itmPeripheralTasks'"
    [class]="'itmPeripheralTasks'"
  >
    <ads-itm-peripheral-tasks-section [section]="getSection('itmPeripheralTasks')"></ads-itm-peripheral-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #itmReceiptPrinterTasksSectionPanel
    title="Receipt Printer Tasks"
    [formGroup]="form?.form"
    [name]="'itmReceiptPrinterTasks'"
    [class]="'itmReceiptPrinterTasks'"
  >
    <ads-itm-receipt-printer-tasks-section
      [section]="getSection('itmReceiptPrinterTasks')"
    ></ads-itm-receipt-printer-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #itmCashDispenserTasksSectionPanel
    title="Cash Dispenser Tasks"
    [formGroup]="form?.form"
    [name]="'itmCashDispenserTasks'"
    [class]="'itmCashDispenserTasks'"
  >
    <ads-itm-cash-dispenser-tasks-section
      [section]="getSection('itmCashDispenserTasks')"
    ></ads-itm-cash-dispenser-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #itmFasciaAndAdaTasksSectionPanel
    title="Fascia & ADA Tasks"
    [formGroup]="form?.form"
    [name]="'itmFasciaAndAdaTasks'"
    [class]="'itmFasciaAndAdaTasks'"
  >
    <ads-itm-fascia-ada-tasks-section [section]="getSection('itmFasciaAndAdaTasks')"></ads-itm-fascia-ada-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #itmCashCheckDepositorySectionPanel
    title="Cash/Check Depository Tasks"
    [formGroup]="form?.form"
    [name]="'itmCashCheckDepositoryTasks'"
    [class]="'itmCashCheckDepositoryTasks'"
  >
    <ads-itm-cash-check-depository-tasks-section
      [section]="getSection('itmCashCheckDepositoryTasks')"
    ></ads-itm-cash-check-depository-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #itmVacuumingBlowOutSectionPanel
    title="Vacuuming & Blow Out (POWER MUST BE OFF)"
    [formGroup]="form?.form"
    [name]="'itmVacuumingAndBlowOutTasks'"
    [class]="'itmVacuumingAndBlowOutTasks'"
  >
    <ads-itm-vacuuming-blow-out-tasks-section
      [section]="getSection('itmVacuumingAndBlowOutTasks')"
    ></ads-itm-vacuuming-blow-out-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #itmPhotosSectionPanel
    title="Photos"
    [formGroup]="form?.form"
    [name]="'photoUploads'"
    [class]="'photoUploads'"
  >
    <ads-photos-section
      [photoUploads]="photoUploads"
      [photoUploadForm]="getSection('photoUploads')!"
    ></ads-photos-section>
  </ads-expansion-panel>

  <ads-common-text-area
    name="comments"
    label="Comments (optional)"
    [maxLength]="500"
    ngModel
  ></ads-common-text-area>

  <div class="form-buttons" *ngIf="!getIsFormSubmitted()">
    <button
      mat-button
      mat-stroked-button
      (click)="saveForm()"
      [disabled]="form?.form?.disabled || (isLoading | async)"
      type="button"
    >
      Save
    </button>
    <button mat-button mat-flat-button class="main-button" [disabled]="form?.form?.disabled">Submit</button>
  </div>
</form>
<div class="clear"></div>
