import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomButtonComponent } from 'shared/custom-button/custom-button.component';

@Component({
  selector: 'ads-modal-actions',
  standalone: true,
  imports: [CommonModule, CustomButtonComponent],
  templateUrl: './modal-actions.component.html',
  styleUrl: './modal-actions.component.scss',
})
export class ModalActionsComponent {
  @Input() showCancel = true;
  @Input() disableConfirm = false;
  @Input() cancelLabel = 'Cancel';
  @Input() confirmLabel = 'Confirm';

  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
}
