import { RoutesEnum } from './navigation';

export const formNames = ['AtmCleaning', 'submitted'] as const;

export const formNamesMap: Record<RoutesEnum | string, string> = {
  AtmCleaning: 'ATM Cleaning PM',
  submitted: 'Form Submitted',
  Atm: 'ATM PM',
  Itm: 'ITM PM',
  CcCd: 'CC/CD PM',
  TcdTcr: 'TCD/TCR PM',
  Security: 'Security PM',
};
