import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AdsOpenCall } from 'models/call-control/ads-call-control';
import { WorkOrderService } from '../work-order-service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ads-open-calls-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule],
  templateUrl: './open-calls-dialog.component.html',
  styleUrl: './open-calls-dialog.component.scss',
})
export class OpenCallsDialogComponent {
  calls = signal<AdsOpenCall[]>([]);
  isLoading = signal(true);

  public readonly dialogRef = inject(MatDialog);

  constructor(public readonly workOrderService: WorkOrderService) {
    this.workOrderService.getOpenCalls().subscribe((calls) => {
      this.calls.set(calls);
      this.isLoading.set(false);
    });
  }

  closeDialog() {
    this.dialogRef.closeAll();
  }
}
