/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[adsNumberInput]',
  standalone: true,
})
export class NumberInputDirective implements OnInit {
  @Input() shouldModify = false;
  @Input() minValue = 0;
  constructor(private readonly ngControl: NgControl) {}

  ngOnInit() {
    const initialOnChange = (this.ngControl.valueAccessor as any).onChange;

    (this.ngControl.valueAccessor as any).onChange = (value: any) => initialOnChange(this.processInput(value));
  }

  processInput(value: any) {
    if (!this.shouldModify) {
      return value;
    }

    const initalValue = value;

    if (!initalValue) {
      return value;
    }

    if (Number(initalValue) < this.minValue) {
      return '';
    }

    value = initalValue.replace(/\D*/g, '');

    return value;
  }

  @HostListener('ngModelChange', ['$event'])
  ngModelChange(value: any) {
    this.ngControl.valueAccessor?.writeValue(this.processInput(value));
  }
}
