import { Component, HostListener } from '@angular/core';
import { StickyHeaderService } from './sticky-header.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ads-sticky-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sticky-header.component.html',
  styleUrl: './sticky-header.component.scss',
})
// It seems we won't be always showing this component.
// We might need an Angular Service to handle this state and the 'sticky'px value
export class StickyHeaderComponent {
  shouldShow = false;

  constructor(public readonly stickyHeaderService: StickyHeaderService) {}

  @HostListener('document:scroll', ['$event'])
  public onViewportScroll() {
    const header = document.getElementById('myHeader'); // we can make this a prop, or emit events to update the classlist
    const sticky = 171; // target element offset
    if (header) {
      if (window.scrollY > sticky) {
        this.shouldShow = true;
      } else {
        this.shouldShow = false;
      }
    }
  }
}
