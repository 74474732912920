import { Injectable } from '@angular/core';
import { AdsCallControl } from 'models';

@Injectable({
  providedIn: 'root',
})
export class CurrentWorkOrderService {
  readonly SELECTED_CALL: string = 'selectedCall';

  constructor() {}

  public getSelectedCall(): AdsCallControl | null {
    const call = sessionStorage.getItem(this.SELECTED_CALL);
    if (call) {
      return JSON.parse(call);
    }
    return null;
  }

  public setSelectedCall(call: AdsCallControl): void {
    const workOrder = JSON.stringify(call);
    sessionStorage.setItem(this.SELECTED_CALL, workOrder);
  }

  public clearSelectedCall(): void {
    sessionStorage.removeItem(this.SELECTED_CALL);
  }
}
