import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PartDetail } from 'models/call-control/part';

@Component({
  selector: 'ads-part-card',
  standalone: true,
  imports: [CommonModule, FormsModule, MatIconModule, MatCheckboxModule, MatButtonModule],
  templateUrl: './part-card.component.html',
  styleUrl: './part-card.component.scss',
})
export class PartCardComponent {
  @Input() item!: PartDetail;
  @Output() readonly clicked = new EventEmitter<void>();

}
