<ads-custom-prompt
  #mobilePrompt
  class="sort-button"
  icon="swap_vert"
  label="Sort"
  [mobileContent]="mobileSort"
  [isDesktopView]="isDesktopView"
  (clicked)="menuOpened.emit()"
  (dismissed)="mobilePrompt?.close()"
></ads-custom-prompt>
<ng-template #mobileSort>
  <ads-prompt-wrapper (primaryButtonClicked)="sortConfirmed()" (cancelButtonClicked)="mobilePrompt?.close()">
    <div class="sort-by-wrapper">
      <span class="sort-by-title">Sort by</span>
      <div class="sort-by-content">
        <ads-radio-button-group
          class="sort-by-options"
          [options]="service.sortOptions"
          [verticalLayout]="true"
          (optionClicked)="service.sortClicked($event)"
          [(ngModel)]="service.sorting.tempSortCriteria"
        ></ads-radio-button-group>
        <ads-radio-values
          [currentCriteria]="service.sorting.tempSortCriteria"
          [currentCriteriaLabel]="service.sorting.tempSortingLabel"
          [options]="service.sortOptions"
          (optionClicked)="onSortingAscClicked($event)"
        ></ads-radio-values>
      </div>
    </div>
  </ads-prompt-wrapper>
</ng-template>
