import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdsCallControl, AdsCallControlPaged } from 'models';
import { DepartmentModel } from 'models/call-control/department';
import { TicketInformation, TicketRequestModel } from 'models/call-control/ticket';
import { CustomHttpUrlEncodingCodec } from 'pages/home/home.service';
import { UpdateStatusCommand } from 'models/call-control/ack-schedule-models';
import { Observable } from 'rxjs';
import { ApiService, AuthService } from 'services';
import { OperationResponse } from 'models/call-control/command-response';
import { AdsCallControlFilters } from 'models/call-control/ads-call-control';

@Injectable({
  providedIn: 'root',
})
export class CallControlService {
  totalItems = 0;

  constructor(private readonly service: ApiService, private readonly authService: AuthService) {}

  private setQueryParameter(params: HttpParams, paramName: string, value?: string | boolean | number): HttpParams {
    if (value !== undefined && value !== null && value !== 'none') {
      if (paramName === 'statuses' && value === 'Acknowledged') {
        return params.set('statuses', 'Ack');
      }
      return params.set(paramName, value);
    }
    return params;
  }

  /**
   * Gets a call list according with the parameters used into the request
   *
   * @param techIdMaster technician id
   * @param isMyOpenCalls flag to indicate when the result will be filtered by open  status call
   */
  public callControlList(filters: AdsCallControlFilters): Observable<AdsCallControlPaged> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    const paramMapping = {
      isMyOpenCalls: filters.isMyOpenCalls,
      pageNo: filters.pageNo,
      pageSize: filters.pageSize,
      sortField: filters.sortField,
      sortOrder: filters.sortOrder,
      searchText: filters.searchText,
      priorities: filters.priorities,
      onSiteByTo: filters.onSiteByTo,
      onSiteByFrom: filters.onSiteByFrom,
      scheduledFrom: filters.scheduledFrom,
      scheduledTo: filters.scheduledTo,
      statuses: filters.statuses,
    };

    Object.entries(paramMapping).forEach(([key, value]) => {
      queryParameters = this.setQueryParameter(queryParameters, key, value);
    });

    const clientOffsetHours = (new Date().getTimezoneOffset() / 60) * -1;
    queryParameters = queryParameters.set('clientOffsetHours', clientOffsetHours);

    return this.service.sendRequest<AdsCallControlPaged>({
      method: 'get',
      url: `callcontrol?techIdMaster=${this.authService.user?.personnelNumber ?? ''}`,
      params: queryParameters,
    });
  }

  public getOpenCalls(serviceOrderId: string, customerAccount: string): Observable<AdsCallControl[]> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() })
      .set('customerAccount', customerAccount)
      .set('serviceOrderToExclude', serviceOrderId);
    return this.service.sendRequest<AdsCallControl[]>({
      method: 'get',
      url: 'callcontrol/open-calls',
      params: queryParameters,
    });
  }

  public getDepartmentList(): Observable<DepartmentModel[]> {
    return this.service.sendRequest<DepartmentModel[]>({ method: 'get', url: 'callcontrol/departments' });
  }

  public submitTicket(body: TicketRequestModel): Observable<TicketInformation> {
    return this.service.sendRequest<TicketInformation>({ method: 'post', url: 'callcontrol/ticket', body });
  }

  public updateCallStatus(body?: UpdateStatusCommand): Observable<OperationResponse> {
    return this.service.sendRequest<OperationResponse>({ method: 'post', url: 'callcontrol/updatecallstatus', body });
  }
}
