<div [attr.class]="isDesktop ? 'widgets-container desktop' : 'widgets-container'">
  <button
    mat-button
    mat-stroked-button
    class="home-button calls large-button"
    type="button"
    (click)="navigateToCallControl()"
  >
    <div class="calls-container">
      <div class="labels">
        <span class="call-number" style="margin-right: 3px"><mat-icon>call</mat-icon>{{ newCalls }}</span>
        <span class="call-description"> New {{ newCalls === 0 || newCalls === 1 ? 'call' : 'calls' }}</span>
      </div>
      <div class="labels">
        <span class="call-number" style="margin-left: 6px"> {{ openCalls }} </span>
        <span class="call-description" style="margin-right: 6px">Open {{ openCalls === 0 || openCalls === 1 ? 'call' : 'calls' }}</span>
      </div>
    </div>
  </button>
</div>
