<ads-simple-form #audioVideo="ngModelGroup" ngModelGroup="audioVideo">
  <ng-container ngModelGroup="audioVideoSections">
    <ads-nested-multiple-forms-section
      #audioVideoSections
      title="Drive Up Audio Matrix"
      itemTitle="Audio Matrix"
      [formTemplate]="formTemplate"
      [list]="security.audioVideoData.audioVideoSections"
      [formControls]="getFormControls()"
      [groupName]="'audioVideoSection'"
      [expandedList]="expandedList"
      [submitted]="submitted"
      (addClicked)="onAddClick()"
      (deleteClicked)="onRemoveAudioVideo($event)"
    >
    </ads-nested-multiple-forms-section>
    <ng-template #formTemplate let-item="item">
      <ads-audio-video-section-form
        [section]="getSection('audioVideoSection#' + item.index)"
        [audioVideoLists]="security.audioVideoData.audioVideoDropdowns"
        [item]="item"
      ></ads-audio-video-section-form>
    </ng-template>
  </ng-container>
  <ads-expansion-panel
    #audioMatrixTasksPanel
    title="Audio Matrix Tasks"
    [formGroup]="formGroup"
    [name]="'audioMatrixTasks'"
    [class]="'audioMatrixTasks'"
  >
    <ads-audio-matrix-tasks [section]="getSection('audioMatrixTasks')"></ads-audio-matrix-tasks>
  </ads-expansion-panel>
  <ads-common-text-area
    name="comments"
    label="Comments (optional)"
    [maxLength]="200"
    ngModel
  ></ads-common-text-area>
</ads-simple-form>
