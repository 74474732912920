import { CompleteNa } from 'models/shared/complete-na';

export class KeypadTasks {
  allNa: boolean;
  keypadTamperTest: CompleteNa | null;
  duressCodeTest: CompleteNa | null;
  ack70Test: CompleteNa | null;

  constructor() {
    this.allNa = false;
    this.ack70Test = null;
    this.duressCodeTest = null;
    this.keypadTamperTest = null;
  }
}
