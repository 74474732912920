<mat-radio-group
  #customSelect="ngModel"
  *ngIf="options && options.length > 0"
  class="group"
  required
  adsRequired
  [name]="name"
  [ngModel]="value"
  [disabled]="disabled"
  (ngModelChange)="onChange($event)"
  aria-label="Select an option"
>
  @for (item of options; track item) {
    <mat-radio-button [value]="item.value" [checked]="value === item.value">{{ item.name }}</mat-radio-button>
  }
</mat-radio-group>
