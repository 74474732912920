import {
  AccessControlCardReaderInterfaceTableFormSection,
  AccessControlDoorTableFormSection,
  AccessControlPowerSupplyTableFormSection,
  AccessControlsFormSection,
} from 'forms/security/access-hardware/access-hardware.component';

export interface AccessControlsForm {
  systemManufacturer: string | null;
  systemManufacturer_Other: string | null;
  controlPanelModel: string | null;
  controlPanelModel_Other: string | null;
  location: string | null;
  ipAddress: string | null;
  overallConditionAccessControlsystem: string | null;
  equipmentRatingAccessControlsystem: string | null;
}
export class AccessControlTasksForm {
  verifyComms: number | null;
  testTamper: number | null;
  testPowerFail: number | null;

  constructor() {
    this.verifyComms = null;
    this.testTamper = null;
    this.testPowerFail = null;
  }
}

export interface AccessControlCardReaderInterfaceTableForm {
  location: string | null;
  interfaceType: string | null;
  interfaceType_Other: string | null;
  port: string | null;
  address: string | null;
}

export class AccessControlCardReaderTasksForm {
  visuallyInspectWire: number | null;

  constructor() {
    this.visuallyInspectWire = null;
  }
}

export interface AccessControlPowerSupplyTableForm {
  location: string | null;
  powerSupply: string | null;
  powerSupply_Other: string | null;
  voltage: string | null;
  batteryAhr: string | null;
  batteryVoltageAfter1HourPowerLoss: string | null;
}

export class AccessControlPowerSupplyTasksForm {
  visuallyInspectWire: number | null;

  constructor() {
    this.visuallyInspectWire = null;
  }
}

export interface AccessControlDoorTableForm {
  readerLocation: string | null;
  readerStyle: string | null;
  readerStyle_Other: string | null;
  lockStyle: string | null;
  lockStyle_Other: string | null;
  doesAREXDeviceUnlockDoor: number | null;
  testLock: number | null;
  testReader: number | null;
  testDoorHelpOpenAlarm: number | null;
  testDoorForcedAlarm: number | null;
}

export interface AccessHardwareSecurityForm {
  accessControls: AccessControlsFormSection[];
  accessControlTasks: AccessControlTasksForm;
  accessControlCardReaderInterfaces: AccessControlCardReaderInterfaceTableFormSection[];
  accessControlCardReaderTasks: AccessControlCardReaderTasksForm;
  accessControlPowerSupplies: AccessControlPowerSupplyTableFormSection[];
  accessControlPowerSupplyTasks: AccessControlPowerSupplyTasksForm;
  accessControlDoors: AccessControlDoorTableFormSection[];
  comments: string | null;
}
