<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <textarea
    matInput
    [maxlength]="maxLength"
    adsRequired
    [checkRequired]="required"
    [required]="required"
    [disabled]="getDisabled() ? 'disabled' : false"
    [name]="name"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="onModelChange($event)"
    (blur)="markAsTouched()"
    [(ngModel)]="value"
  ></textarea>
  <mat-hint [align]="'start'" *ngIf="!getDisabled() && getInvalid()" class="custom-error">
    This field is required
  </mat-hint>
  <mat-hint [align]="'end'">
    {{ value ? value.length : 0 }} / {{maxLength}} characters
  </mat-hint>
</mat-form-field>
