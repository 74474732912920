import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CustomPromptComponent } from "shared/custom-prompt/custom-prompt.component";
import { PromptWrapperComponent } from "shared/prompt-wrapper/prompt-wrapper.component";
import { RadioButtonGroupComponent } from "shared/material-wrappers/radio-button-group/radio-button-group.component";
import { SelectOption } from 'models';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'ads-mobile-status-filtering',
  standalone: true,
  imports: [CustomPromptComponent, PromptWrapperComponent, RadioButtonGroupComponent, FormsModule],
  templateUrl: './mobile-status-filtering.component.html',
})
export class MobileStatusFilteringComponent {
  @Input() filteringOptions: SelectOption<string>[] = [];
  @Output() statusFilterConfirmed = new EventEmitter<string>();
  @ViewChild('mobilePrompt') mobilePrompt: CustomPromptComponent | null = null;
  value = 'none';

  onConfirmed() {
    this.statusFilterConfirmed.emit(this.value);
    this.mobilePrompt?.close();
  }

  onOptionClicked(value: string | string []) {
    this.value = value as string;
  }
}
