import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser';
import { environment } from 'environment/environment';
import { BehaviorSubject, Subject, filter, lastValueFrom, Observable } from 'rxjs';
import { UserService } from './user.service';
import { AdsActiveUserModel } from 'models';
import { Client } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isIframe = false;
  authenticated = false;
  user?: AdsActiveUserModel;
  private userAccount: AccountInfo | undefined;
  private readonly _destroying$ = new Subject<void>();
  private readonly authenticatedUserSubject = new BehaviorSubject<AdsActiveUserModel | null>(null);

  public graphClient?: Client;

  public loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private readonly msalGuardConfig: MsalGuardConfiguration,
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly userService: UserService,
  ) {}

  init = (): void => {
    this.msalService.handleRedirectObservable().subscribe();
    this.isIframe = window !== window.parent && !window.opener;

    this.setLoginDisplay();

    this.msalService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS))
      .subscribe(async (msg: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.checkAccount(msg.payload as AuthenticationResult);
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe(async () => {
        await this.checkUserValidity(true);
      });
  };

  setLoginDisplay() {
    this.authenticated = this.msalService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  private checkAccount(authResponse: AuthenticationResult): void {
    this.setLoginDisplay();
    this.userAccount = authResponse.account;
    if (authResponse) {
      this.checkAndSetActiveAccount();
    }
  }

  async checkUserOnStartup(): Promise<boolean> {
    try {
      const res = await this.refreshToken();
      return !!res;
    } catch {
      return false;
    }
  }

  async checkUserValidity(forceRefreshDetails = false): Promise<void> {
    try {
      this.loading$.next(true);
      const user = await lastValueFrom(this.userService.employeeDetail(forceRefreshDetails));
      this.user = user;
      if (!this.user?.personnelNumber) {
        this.logout();
      } else {
        this.userService.techDetail().subscribe();
        this.authenticatedUserSubject.next(user);
        this.initializeGraphClient();
      }
    } catch {
      this.loading$.next(false);
    } finally {
      this.loading$.next(false);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect().subscribe(() => {
        const accounts = this.msalService.instance.getAllAccounts();
        this.msalService.instance.setActiveAccount(accounts[0]);
      });
    } else {
      this.msalService.loginPopup().subscribe((response: AuthenticationResult) => {
        this.msalService.instance.setActiveAccount(response.account);
      });
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.msalService.logoutRedirect();
    }
    this.authenticatedUserSubject.next(null);
  }

  onDestroy = (): void => {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  };

  async refreshToken(): Promise<AuthenticationResult | void> {
    const scopes = environment.apiConfig.scopes;
    const account = this.msalService.instance.getActiveAccount();

    await lastValueFrom(this.msalService.handleRedirectObservable());

    if (!account) {
      return;
    }

    this.userAccount = account;

    const silentRequest = {
      account: account,
      scopes: scopes,
    };

    return await lastValueFrom(this.msalService.acquireTokenSilent(silentRequest))
      .then((authResponse) => {
        this.checkAccount(authResponse);
        this.authenticated = true;
        return authResponse;
      })
      .catch(() => {
        const redirectStartPage = window.location.href;
        this.msalService.acquireTokenRedirect({ scopes, redirectStartPage });
        this.authenticated = false;
      });
  }

  initializeGraphClient() {
    if (this.authenticated && this.msalService.instance.getActiveAccount() !== null) {
      // Create an authentication provider for the current user
      const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        this.msalService.instance as PublicClientApplication,
        {
          account: this.userAccount!,
          scopes: ['mail.send'],
          interactionType: InteractionType.Redirect,
        },
      );
      // Initialize the Graph client
      this.graphClient = Client.initWithMiddleware({
        authProvider: authProvider,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendMail(content: any) {
    return this.graphClient?.api('/me/sendMail').post(content.data);
  }

  sendMailMessage = (subject: string, body: string) =>
    this.sendMail({
      data: {
        message: {
          subject: subject,
          body: {
            contentType: 'Text',
            content: body,
          },
          toRecipients: [
            {
              emailAddress: {
                address: environment.emailRecipient,
              },
            },
          ],
        },
        saveToSentItems: 'true',
      },
    });

  get authenticatedUser$(): Observable<AdsActiveUserModel | null> {
    return this.authenticatedUserSubject.asObservable();
  }
}
