<div class="info-table">
  <div class="info-column">
    <span>Status:</span>
    <span>Client: </span>
    <span>Work Order: </span>
    <span>Address: </span>
    <span>City: </span>
    <span>State: </span>
    <span>Zip Code: </span>
    <span>Branch Number: </span>
  </div>
  <div class="info-column" style="text-align: right;">
    <span class="work-order" [class.submitted]="order?.workStatus === 2" [class.draft]="order?.workStatus === 1"
      >[{{ getOrderStatus() }}]</span
    >
    <span>{{ customerName === '' ? '-' : customerName }}</span>
    <span>{{ order?.workOrder ?? '-' }}</span>
    <span>{{ order?.address ?? '-' }}</span>
    <span>{{ order?.city ?? '-' }}</span>
    <span>{{ order?.state ?? '-' }}</span>
    <span>{{ order?.zipCode ?? '-' }}</span>
    <span>{{ order?.branchPhoneNumber ?? '-' }}</span>
  </div>
</div>
