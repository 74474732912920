import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { ApiService } from './api.service';
import { AdsActiveUserModel } from 'models';
import { LocalStorageService } from './localstorage.service';
import { HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from 'pages/home/home.service';

interface TechnicianDetails {
  personnelNumber?: string;
  inventLocationId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly cacheKey = 'adsActiveUser';

  constructor(
    private readonly service: ApiService,
    private readonly msalService: MsalService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  /**
   * Gets the personnel id of the user authenticated.
   */
  public employeeDetail(force = false): Observable<AdsActiveUserModel> {
    const activeAccount = this.msalService.instance.getActiveAccount();
    const cachedUser = this.getCachedUser();

    if (!force && cachedUser && activeAccount && cachedUser.userName === activeAccount.username) {
      return of(cachedUser);
    } else {
      return this.service
        .sendRequest<AdsActiveUserModel>({
          method: 'get',
          url: 'user/employeeDetail',
          urlPrefix: 'ads',
        })
        .pipe(tap((user: AdsActiveUserModel) => this.cacheUser(user)));
    }
  }

  /**
   * Gets technician details for the cached technician
   */
  public techDetail(): Observable<TechnicianDetails> {
    const cachedUser = this.getCachedUser();

    if ((cachedUser?.inventLocationId ?? '').length > 0) {
      return of({});
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() }).set(
      'personnelNumber',
      cachedUser?.personnelNumber ?? '',
    );

    return this.service
      .sendRequest<TechnicianDetails>({
        method: 'get',
        url: 'technician/techdetail',
        params: queryParameters,
      })
      .pipe(
        tap((techDetails: TechnicianDetails) =>
          this.cacheUser({ ...cachedUser, inventLocationId: techDetails.inventLocationId } as AdsActiveUserModel),
        ),
      );
  }

  private getCachedUser(): AdsActiveUserModel | null {
    const cachedData = this.localStorageService.getItem(this.cacheKey);
    return cachedData ? JSON.parse(cachedData) : null;
  }

  private cacheUser(user: AdsActiveUserModel | null): void {
    this.localStorageService.setItem(this.cacheKey, JSON.stringify(user));
  }
}
