import { CommonModule } from '@angular/common';
import { Component, AfterViewInit, Input } from '@angular/core';
import { FormsModule, ControlContainer, NgForm, FormGroup } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Equipment, SelectOption } from 'models';

type SelectOptionWidth = (SelectOption<Equipment> & { width: string })[];
@Component({
  selector: 'ads-security-selected-equipment',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    FormsModule,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './security-selected-equipment.component.html',
  styleUrl: './security-selected-equipment.component.scss',
})
export class SecuritySelectedEquipmentComponent implements AfterViewInit {
  @Input() section?: FormGroup;
  @Input() groupName = '';
  @Input() submitted = false;

  options: SelectOptionWidth = [
    { name: 'Alarm', value: Equipment.Alarm, width: '45%' },
    { name: 'Surveillance', value: Equipment.Surveillance, width: '45%' },
    { name: 'Vault', value: Equipment.Vault, width: '45%' },
    { name: 'Safes', value: Equipment.Safes, width: '45%' },
    { name: 'UCS', value: Equipment.UCS, width: '45%' },
    { name: 'Night Drop', value: Equipment.NightDrop, width: '45%' },
    { name: 'Access Control Hardware', value: Equipment.AccessControlHardware, width: '95%' },
    { name: 'Deal Drawer', value: Equipment.DealDrawer, width: '45%' },
    { name: 'RTS', value: Equipment.RTS, width: '45%' },
    { name: 'Audio/Video DU', value: Equipment.AudioVideoDU, width: '95%' },
  ];

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.section) {
        this.section.controls['selectedEquipment'].valueChanges.subscribe((newValue) => {
          if (newValue.length === 0) {
            this.section?.controls['selectedEquipment'].setValue(this.value, { emitEvent: false });
          } else {
            this.value = (this.section?.controls['selectedEquipment'].value as string[]) ?? [];
          }
        });
      }
    });
  }

  value: string[] = [];

  isItemSelected = (itemValue: string) => {
    if (this.value) {
      return this.value.find((v) => v === itemValue);
    }
    return [];
  };

  selectAll() {
    const allOptions = this.options.map((option) => option.value);
    this.section?.controls['selectedEquipment'].setValue(allOptions);
  }

  onKeyPress(e: Event) {
    const event = e as KeyboardEvent;
    if (event.key == 'Enter') {
      this.selectAll();
    }
  }
}
