import { CompleteNa } from 'models/shared/complete-na';
import { YesNoNa } from 'models/shared/yes-no-na';

export class AlarmPanelTasks {
  isProgrammingSheetPresent: YesNoNa | null;
  isMonitoringSheetPresent: YesNoNa | null;
  wireAndPcbInspection: CompleteNa | null;
  testTamperSwith: CompleteNa | null;
  testPhoneComms: CompleteNa | null;
  testPhoneComsLoss: CompleteNa | null;
  verifyPrimaryDialer: CompleteNa | null;
  verifySecondaryReceiver: CompleteNa | null;
  testIpComms: CompleteNa | null;
  testCellularComms: CompleteNa | null;

  constructor() {
    this.isProgrammingSheetPresent = null;
    this.isMonitoringSheetPresent = null;
    this.wireAndPcbInspection = null;
    this.testTamperSwith = null;
    this.testPhoneComms = null;
    this.testPhoneComsLoss = null;
    this.verifyPrimaryDialer = null;
    this.verifySecondaryReceiver = null;
    this.testIpComms = null;
    this.testCellularComms = null;
  }
}
