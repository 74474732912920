<ads-simple-form *ngIf="item" [ngModelGroup]="'wirelessReceiverSection#' + item.index">
  <ads-common-form-input
    name="manufacturer"
    label="Wireless receiver manufacturer"
    [maxLength]="50"
    [required]="true"
    [value]="item.manufacturer"
    [(ngModel)]="item.manufacturer"
  ></ads-common-form-input>
  <ads-common-form-input
    name="model"
    label="Wireless receiver model"
    [maxLength]="50"
    [required]="true"
    [value]="item.model"
    [(ngModel)]="item.model"
  ></ads-common-form-input>
  <ads-common-form-input
    name="location"
    label="Location of wireless receiver"
    [maxLength]="50"
    [required]="true"
    [value]="item.location"
    [(ngModel)]="item.location"
  ></ads-common-form-input>
  <h5>
    Last Date batteries were changed in:
    <ads-custom-tooltip tooltip="Must replace after 2 years"></ads-custom-tooltip>
  </h5>
  <ads-datepicker
    name="lastBatterChangeMoneyClips"
    label="Money clips (Replace every year)"
    [required]="true"
    [value]="item.lastBatterChangeMoneyClips"
    [(ngModel)]="item.lastBatterChangeMoneyClips"
  ></ads-datepicker>
  <ads-datepicker
    name="lastBatterChangeButtons"
    label="Hold up buttons"
    [required]="true"
    [value]="item.lastBatterChangeButtons"
    [(ngModel)]="item.lastBatterChangeButtons"
  ></ads-datepicker>
  <ads-datepicker
    name="lastBatterChangeMotions"
    label="Motions"
    [required]="true"
    [value]="item.lastBatterChangeMotions"
    [(ngModel)]="item.lastBatterChangeMotions"
  ></ads-datepicker>
  <ads-datepicker
    name="lastBatterChangeContacts"
    label="Contacts"
    [required]="true"
    [value]="item.lastBatterChangeContacts"
    [(ngModel)]="item.lastBatterChangeContacts"
  ></ads-datepicker>
</ads-simple-form>
