import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ControlContainer, FormGroup, FormsModule, NgModelGroup } from '@angular/forms';
import { SensorsTasks, CompleteNa, SelectOption } from 'models';
import { SingleToggleButtonComponent, RadioButtonGroupComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { checkAllOptions, checkIfAllAreNa } from 'app/utils/select-all';

@Component({
  selector: 'ads-sensors-tasks',
  standalone: true,
  imports: [SingleToggleButtonComponent, RadioButtonGroupComponent, SimpleFormComponent, MatDividerModule, FormsModule],
  templateUrl: './sensors-tasks.component.html',
  styleUrl: './sensors-tasks.component.scss',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class SensorsTasksComponent {
  @Input() section?: FormGroup;

  selectAllChecked = false;
  fields = Object.getOwnPropertyNames(new SensorsTasks());

  completeNaOptions: SelectOption<CompleteNa>[] = [
    { name: 'Complete', value: CompleteNa.Complete },
    { name: 'N/A', value: CompleteNa.Na },
  ];

  onOptionClicked = () => checkIfAllAreNa(this.fields, this.section);
  onSelectAllChange = (shouldSelectAll: boolean) => checkAllOptions(shouldSelectAll, this.fields, this.section);
}
