import { CommonModule } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TableBottomSheetsComponent } from 'forms/shared/table-bottom-sheets/table-bottom-sheets.component';
import { CompleteNa, SelectOption, YesNoNa } from 'models';
import { RadioButtonGroupComponent } from 'shared/material-wrappers';

interface AHDoorTasks {
  doesAREXDeviceUnlockDoor: number | null;
  testLock: number | null;
  testReader: number | null;
  testDoorHelpOpenAlarm: number | null;
  testDoorForcedAlarm: number | null;
}

interface AhDoorsTableBottomSheetComponentProps {
  tasks: AHDoorTasks;
  addNew?: () => void;
}

@Component({
  selector: 'ads-ah-doors-table-bottom-sheet',
  standalone: true,
  imports: [
    MatButtonModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RadioButtonGroupComponent,
  ],
  templateUrl: './ah-doors-table-bottom-sheet.component.html',
  styleUrl: './ah-doors-table-bottom-sheet.component.scss',
})
export class AhDoorsTableBottomSheetComponent {
  private readonly _bottomSheetRef = inject<MatBottomSheetRef<TableBottomSheetsComponent>>(MatBottomSheetRef);

  formGroup: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: AhDoorsTableBottomSheetComponentProps,
  ) {
    this.formGroup = this.formBuilder.group({
      doesAREXDeviceUnlockDoor: null,
      testLock: null,
      testReader: null,
      testDoorHelpOpenAlarm: null,
      testDoorForcedAlarm: null,
    });
    this.initializeForm();
  }

  optionsCompleteNa: SelectOption<CompleteNa>[] = [
    { name: 'Complete', value: CompleteNa.Complete },
    { name: 'N/A', value: CompleteNa.Na },
  ];

  optionsYesNoNa: SelectOption<YesNoNa>[] = [
    { name: 'Yes', value: YesNoNa.Yes },
    { name: 'No', value: YesNoNa.No },
    { name: 'N/A', value: YesNoNa.Na },
  ];

  initializeForm(): void {
    const tasks = this.data.tasks;

    this.formGroup.setValue({
      doesAREXDeviceUnlockDoor: tasks.doesAREXDeviceUnlockDoor ?? null,
      testLock: tasks.testLock ?? null,
      testReader: tasks.testReader ?? null,
      testDoorHelpOpenAlarm: tasks.testDoorHelpOpenAlarm ?? null,
      testDoorForcedAlarm: tasks.testDoorForcedAlarm ?? null,
    });
  }

  onAccept(): void {
    this._bottomSheetRef.dismiss(this.formGroup.value);
    console.log('formGroup.value:', this.formGroup.value);
  }

  onCancel(): void {
    this._bottomSheetRef.dismiss();
  }

  onAcceptAndAddNew(): void {
    this._bottomSheetRef.dismiss(this.formGroup.value);
    this.data.addNew?.();
  }
}
