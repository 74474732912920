export class AtmVacuumBlowOutTasks {
  allNa: boolean;
  safe: number | null;
  topHalf: number | null;
  dispenser: number | null;
  depositories: number | null;
  receiptPrinter: number | null;
  allFans: number | null;
  allFilters: number | null;
  powerSupply: number | null;
  safeHeaters: number | null;

  constructor() {
    this.allNa = false;
    this.safe = null;
    this.topHalf = null;
    this.dispenser = null;
    this.depositories = null;
    this.receiptPrinter = null;
    this.allFans = null;
    this.allFilters = null;
    this.powerSupply = null;
    this.safeHeaters = null;
  }
}