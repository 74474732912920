import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { AuthService } from 'services';

@Component({
  selector: 'ads-dialog-logout',
  standalone: true,
  imports: [MatButtonModule, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose],
  templateUrl: './dialog-logout.component.html',
})
export class DialogLogoutComponent {
  readonly dialogRef = inject(MatDialogRef<DialogLogoutComponent>);
  constructor(private readonly authService: AuthService) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  logOut() {
    this.authService.logout(true);
    this.dialogRef.close();
  }
}
