export class AtmEnvDepDispTasks {
  allNa: boolean;
  cleanAllSensors: number | null;
  cleanAllBelts: number | null;
  cleanShutter: number | null;
  cleanInkArea: number | null;
  cleanInkPad: number | null;
  replaceCartridgeRibbon: number | null;
  replenishEnvelops: number | null;
  diagnosticTests: number | null;

  constructor() {
    this.allNa = false;
    this.cleanAllSensors = null;
    this.cleanAllBelts = null;
    this.cleanShutter = null;
    this.cleanInkArea = null;
    this.cleanInkPad = null;
    this.replaceCartridgeRibbon = null;
    this.replenishEnvelops = null;
    this.diagnosticTests = null;
  }
}
