<div class="container">
  <h3 class="go-back" *ngIf="layoutNavService.isDesktopView()">
    <button mat-icon-button (click)="goBack()">
      <mat-icon class="material-icons-outlined">arrow_back</mat-icon>
    </button>
    RMA Request
  </h3>
  <mat-tab-group
    animationDuration="0"
    disableRipple
    disablePagination
    #tabGroup
    (selectedTabChange)="onTabChanged($event)"
    [selectedIndex]="rmaRequestService.selectedIndex()"
  >
    <mat-tab label="Service Order">
      <ads-search-order [type]="'WO'"></ads-search-order>
    </mat-tab>
    <mat-tab label="Project">
      <ads-search-order type="PRJ"></ads-search-order>
    </mat-tab>
    <mat-tab label="Vanstock" style="margin-bottom: 50px">
      <ads-common-form-input [readonly]="true" [value]="vanstockName"></ads-common-form-input>
    </mat-tab>
  </mat-tab-group>

  <div>
    <div class="input-container">
      <ads-select
        label="Ship to for replacement/repair"
        [list]="shipToOptions"
        [(ngModel)]="rmaRequestService.selectedShipTo"
        [required]="true"
      ></ads-select>

      <div
        class="ship-to-address"
        *ngIf="rmaRequestService.selectedShipTo === '1' || rmaRequestService.selectedShipTo === '2'"
      >
        <span class="title">Ship to address</span>
        <span class="address">
          {{
            rmaRequestService.selectedShipTo === '1' ? addresses?.MyTruckStockDefault : addresses?.MyMainStockDefault
          }}</span
        >
        <span *ngIf="rmaRequestService.selectedShipTo === '1'" class="note">
          {{
            '*If this default address is not correct, please contact PartsRequest@cooksolutionsgroup.com to have it updated.'
          }}
        </span>
      </div>

      <div style="margin-bottom: 20px" *ngIf="rmaRequestService.selectedShipTo === '3'">
        <ads-common-text-area
          label="Ship to address"
          [maxLength]="500"
          [required]="rmaRequestService.selectedShipTo === '3'"
          [(ngModel)]="rmaRequestService.shipToAddress"
        ></ads-common-text-area>
      </div>

      <ads-select
        label="Shipping method"
        [list]="shippingMethodItems"
        [(ngModel)]="rmaRequestService.selectedShippingMethod"
        [required]="true"
      ></ads-select>

      <ads-common-text-area
        label="Notes to RMA"
        [maxLength]="500"
        [required]="true"
        [(ngModel)]="rmaRequestService.description"
      ></ads-common-text-area>

      <ads-parts></ads-parts>
    </div>

    <div class="button-container">
      <button mat-button mat-stroked-button type="button" (click)="clearEntries()">Cancel</button>
      <button mat-button mat-flat-button type="button" (click)="onSubmit()" [disabled]="!submitEnabled()">
        Submit
      </button>
    </div>
  </div>
</div>
