<ads-simple-form #tcdTcrInputModuleTasks="ngModelGroup" ngModelGroup="tcdTcrInputModuleTasks">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button
  ><mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Check and clean all sensors"
    name="checkSensors"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Remove any foreign material"
    name="foreignMaterial"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Check and clean all rollers"
    name="checkRollers"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Check and clean all belts"
    name="checkBelts"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Check and clean shutter"
    name="checkShutter"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Verify operation of left/right indicators"
    name="verifyIndicators"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Perform diagnostic tests"
    name="diagnosticTests"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
