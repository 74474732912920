import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  shouldShow = false;
  constructor() { }

  show(): void {
    this.shouldShow = true;
  }

  hide(): void {
    this.shouldShow = false;
  }
}
