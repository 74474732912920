import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { atmCleaningPhotoUploads } from 'utils';
import {
  PhotosSectionComponent,
  AtmCleaningTasksComponent,
  AtmExteriorEvaluationComponent,
  AtmFormSectionComponent,
  NetworkLogosComponent,
  AtmReplenishmentTasksComponent,
} from 'forms';
import { AtmCleaning, AtmCleaningDropdowns } from 'models';
import { LoaderService, PhotoUploadControlService } from 'services';
import { AtmCleaningService } from './atm-cleaning.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { AtmCleaningResponse, AtmCleaningResponseDropdowns } from 'models/api';
import { HeaderService } from 'shared/header/header.service';
import { OrderInfoComponent } from 'shared/order-info/order-info.component';
import { StickyHeaderService } from 'shared/sticky-header/sticky-header.service';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { mapAtmCleaningDropdowns, mapAtmCleaningForm } from 'models/api/maps/atm-cleaning/atm-cleaning-map';
import { FormPageComponent } from 'pages/form-page/form-page';

@Component({
  selector: 'ads-atm-cleaning-pm',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    ExpansionPanelComponent,
    FormsModule,
    PhotosSectionComponent,
    OrderInfoComponent,
    NetworkLogosComponent,
    CommonTextAreaComponent,
    AtmFormSectionComponent,
    AtmExteriorEvaluationComponent,
    AtmCleaningTasksComponent,
    AtmReplenishmentTasksComponent,
  ],
  templateUrl: './atm-cleaning-pm.component.html',
  styleUrl: './atm-cleaning-pm.component.scss',
  providers: [PhotoUploadControlService],
})
export class AtmCleaningPmComponent
  extends FormPageComponent<
    AtmCleaningService,
    AtmCleaning,
    AtmCleaningResponse,
    AtmCleaningDropdowns,
    AtmCleaningResponseDropdowns
  >
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild('atmCleaningForm', { static: true }) override form?: NgForm;
  @ViewChild('atmSectionPanel', { static: true }) atm?: ExpansionPanelComponent;
  @ViewChild('atmExteriorEvaluationSectionPanel', { static: true }) exteriorEvaluation?: ExpansionPanelComponent;
  @ViewChild('atmNetworkLogosSectionPanel', { static: true }) networkLogos?: ExpansionPanelComponent;
  @ViewChild('atmCleaningTasksSectionPanel', { static: true }) cleaningTasks?: ExpansionPanelComponent;
  @ViewChild('atmReplenishmentTasksSectionPanel', { static: true }) replenishmentTasks?: ExpansionPanelComponent;
  @ViewChild('atmPhotosSectionPanel', { static: true }) photos?: ExpansionPanelComponent;

  override mapForm = mapAtmCleaningForm;
  override mapDropdowns = mapAtmCleaningDropdowns;
  override photoUploads = atmCleaningPhotoUploads;
  override sectionNames = [
    'atmSection',
    'atmExteriorEvaluation',
    'atmNetworkLogos',
    'cleaningTasks',
    'replenishmentTasks',
    'photoUploads',
  ];
  override sections = {};

  constructor(
    private readonly atmCleaningService: AtmCleaningService,
    private readonly pucs: PhotoUploadControlService,
    headerService: HeaderService,
    route: ActivatedRoute,
    router: Router,
    stickyHeaderService: StickyHeaderService,
    loaderService: LoaderService,
    snackBar: MatSnackBar
  ) {
    super(atmCleaningService, headerService, router, stickyHeaderService, loaderService, route, snackBar);
  }

  ngOnDestroy(): void {
    this.stickyHeaderService.enabled = false;
  }

  ngAfterViewInit() {
    this.form?.control.valueChanges.subscribe((values) => (this.atmCleaningService.form = values));
    this.headerService.title.set('ATM Cleaning Form');
    setTimeout(() => this.setDefault());
  }

  ngOnInit() {
    this.photoUploadForm = this.pucs.toFormGroup(atmCleaningPhotoUploads);
    this.form?.control.addControl('photoUploads', this.photoUploadForm);
    this.sections = {
      atmSection: this.atm,
      atmExteriorEvaluation: this.exteriorEvaluation,
      atmNetworkLogos: this.networkLogos,
      cleaningTasks: this.cleaningTasks,
      replenishmentTasks: this.replenishmentTasks,
      photoUploads: this.photos,
    };
  }

  getSection(sectionName: string) {
    return this.form?.controls[sectionName] as FormGroup | undefined;
  }
}
