import { CompleteNa } from '../shared/complete-na';

export class TcdTcrExternalAreaTasks {
  allNa: boolean;
  customerArea: CompleteNa | null;
  toneVolume: CompleteNa | null;
  screen: CompleteNa | null;
  exterior: CompleteNa | null;
  alignment: CompleteNa | null;
  cables: CompleteNa | null;
  constructor() {
    this.allNa = false;
    this.customerArea = null;
    this.toneVolume = null;
    this.screen = null;
    this.exterior = null;
    this.alignment = null;
    this.cables = null;
  }
}
