import { Equipment, SecurityDropdowns, SecurityForm } from '../../security';
import { SecurityDto, SecurityEquipmentsDto } from '../security/security';
import { SecurityResponseDropdowns } from '../security/security-response';
import { mapSecurityUCS } from './security-map/ucs/ucs-map';
import { mapSecuritySafesResponse } from './security-map/safes/safes-map-response';
import { mapSecurityVaultResponse } from './security-map/vault/vault-map-response';
import { mapSecurityRtsResponse } from './security-map/rts/rts-map-response';
import { mapSecurityDealDrawerResponse } from './security-map/deal-drawer/deal-drawer-map-response';
import { mapSecurityAudioVideoResponse } from './security-map/audio-video/audio-video-map-response';
import { mapSurveillanceForm } from './security-map/surveillance/surveillance-map';
import { mapSecurityAlarmResponse } from './security-map/alarm/alarm-map-response';
import { mapSecurityAccessHardwareForm } from './security-map/access-hardware/access-hardware-map';
import { mapSecurityNightDropForm } from './security-map/night-drop/night-drop-map';

export const mapSecurityEquipment = (equipment: SecurityEquipmentsDto): Equipment[] => {
  const selectedEquipment: Equipment[] = [];
  if (equipment.alarm) {
    selectedEquipment.push(Equipment.Alarm);
  }
  if (equipment.surveillance) {
    selectedEquipment.push(Equipment.Surveillance);
  }
  if (equipment.vault) {
    selectedEquipment.push(Equipment.Vault);
  }
  if (equipment.safes) {
    selectedEquipment.push(Equipment.Safes);
  }
  if (equipment.ucs) {
    selectedEquipment.push(Equipment.UCS);
  }
  if (equipment.nightDrop) {
    selectedEquipment.push(Equipment.NightDrop);
  }
  if (equipment.accessControlHardware) {
    selectedEquipment.push(Equipment.AccessControlHardware);
  }
  if (equipment.dealDrawer) {
    selectedEquipment.push(Equipment.DealDrawer);
  }
  if (equipment.rts) {
    selectedEquipment.push(Equipment.RTS);
  }
  if (equipment.audioVideoDU) {
    selectedEquipment.push(Equipment.AudioVideoDU);
  }
  return selectedEquipment;
};

export const mapSecurityForm = (data: SecurityDto): SecurityForm => {
  return {
    alarm: mapSecurityAlarmResponse(data.security_Alarm),
    accessHardware: mapSecurityAccessHardwareForm(data.security_AccessControlHardware),
    ucs: mapSecurityUCS(data.security_Ucs),
    nightDrop: mapSecurityNightDropForm(data.security_NightDrop),
    dealDrawer: mapSecurityDealDrawerResponse(data.security_DealDrawer),
    audioVideo: mapSecurityAudioVideoResponse(data.security_AudioVideoDU),
    safes: mapSecuritySafesResponse(data.security_Safes),
    vault: mapSecurityVaultResponse(data.security_Vault),
    rts: mapSecurityRtsResponse(data.security_RTS),
    selectedEquipment: mapSecurityEquipment(data.securityTab),
    surveillance: mapSurveillanceForm(data.security_Surveillance),
    applyCSGProtectionDecalInBottomRightCorner: data.applyCSGProtectionDecalInBottomRightCorner,
  };
};

export const mapSecurityDropdowns = (data: SecurityResponseDropdowns): SecurityDropdowns => {
  const acacManufacturers: Record<string, string> = {};
  data.acacSystemManufacturers.forEach((m) => {
    acacManufacturers[m.manufacturerId] = m.manufacturerName;
  });

  return {
    alarm: {
      alarmAlarmPanelsAlarmManufacturers: data.alarmAlarmPanelsAlarmManufacturers,
      alarmAlarmPanelOverallCondition: data.alarmAlarmPanelOverallCondition,
      alarmAlarmPanelCentralStation: data.alarmAlarmPanelCentralStation,
      alarmPathTablePathTypes: data.alarmPathTablePathTypes,
      alarmPathTableCommTypes: data.alarmPathTableCommTypes,
      alarmHubTasksTellerHoldUpTypesTypes: data.alarmHubTasksTellerHoldUpTypesTypes,
      alarmHubTasksPlatformHoldUpTypesTypes: data.alarmHubTasksPlatformHoldUpTypesTypes,
    },
    surveillance: {
      surveillanceDvrDvrManufacturers:
        data.surveillanceDvrDvrManufacturers.map((manufacturer) => ({
          value: manufacturer.manufacturerName,
          name: manufacturer.manufacturerName,
        })),
      surveillanceDVRNVROverallConditions:
        data.surveillanceDVRNVROverallConditions.map((condition) => ({
          name: condition.name,
          value: condition.name,
        })),
      surveillanceUpsManufacturers:
        data.surveillanceUpsManufacturers.map((manufacturer) => ({
          value: manufacturer.name,
          name: manufacturer.name,
        })),
      surveillancePoeSwitchManufacturers:
        data.surveillancePoeSwitchManufacturers.map((manufacturer) => ({
          value: manufacturer.manufacturerName,
          name: manufacturer.manufacturerName,
        })),
      surveillanceCameraTableCameraType: data.surveillanceCameraTableCameraType.map((t) => ({
        value: t.name,
        name: t.name,
      })),
      surveillanceCameraTableIsPtz: data.surveillanceCameraTableIsPtz.map((t) => ({
        value: t.value,
        name: t.text,
      })),
      surveillanceCameratableOverallConditionCameras:
        data.surveillanceCameratableOverallConditionCameras.map((condition) => ({
          name: condition.name,
          value: condition.name,
        })),
      surveillanceMonitorTableMonitorType: data.surveillanceMonitorTableMonitorType.map((t) => ({
        value: t.name,
        name: t.name,
      })),
      surveillanceMonitorTableOverallConditionMonitors:
        data.surveillanceMonitorTableOverallConditionMonitors.map((condition) => ({
          name: condition.name,
          value: condition.name,
        })),
    },
    vault: {
      vaultVaultVaultManufacturers: data.vaultVaultVaultManufacturers,
      vaultVaultSwingTypes: data.vaultVaultSwingTypes,
      vaultVaultOverallConditionsVault: data.vaultVaultOverallConditionsVault,
      vaultVaultOverallConditionsVentilator: data.vaultVaultOverallConditionsVentilator,
      vaultVaultOverallCreep: data.vaultVaultOverallCreep,
    },
    safes: {
      safesSafesSafeManufacturers: data.safesSafesSafeManufacturers.map((t) => ({
        value: t.manufacturerName,
        name: t.manufacturerName,
      })),
      safesSafesSwingTypes: data.safesSafesSwingTypes.map((t) => ({
        value: t.name,
        name: t.name,
      })),
      safesSafesOverallConditions: data.safesSafesOverallConditions.map((t) => ({
        value: t.name,
        name: t.name,
      })),
    },
    ucs: {
      ucsUcsManufacturers:
        data.ucsUcsUcsManufacturers.map((manufacturer) => ({
          value: manufacturer.manufacturerName,
          name: manufacturer.manufacturerName,
        })),
      ucsUcsOverallConditions:
        data.ucsUcsOverallConditions.map((condition) => ({
          name: condition.name,
          value: condition.name,
        })),
    },
    nightDrop: {
      nightDropNightDropManufacturers:
        data.nightDropNightDropManufacturers.map((manufacturer) => ({
          value: manufacturer.manufacturerName,
          name: manufacturer.manufacturerName,
        })),
      nightDropNightDropSwingTypes: data.nightDropNightDropSwingTypes.map((t) => ({
        value: t.name,
        name: t.name,
      })),
      nightDropNightDropDoorStyle: data.nightDropNightDropDoorStyle.map((t) => ({
        value: t.name,
        name: t.name,
      })),
      nightDropNightDropOverallConditions: data.nightDropNightDropOverallConditions.map((t) => ({
        value: t.name,
        name: t.name,
      })),
    },
    accessControlHardware: {
      acacSystemManufacturers:
        data.acacSystemManufacturers.map((manufacturer) => ({
          value: manufacturer.manufacturerName,
          name: manufacturer.manufacturerName,
        })),
      acacOverallConditions:
        data.acacOverallConditions.map((condition) => ({
          name: condition.name,
          value: condition.name,
        })),
      acCardReaderInterfaceTableInterfaceTypes:
        data.acCardReaderInterfaceTableInterfaceTypes.map((t) => ({
          name: t.name,
          value: t.name,
        })),
      acwPowerSupplyTablePowerSupply:
        data.acwPowerSupplyTablePowerSupply.map((t) => ({
          name: t.name,
          value: t.name,
        })),
      acwPowerSupplyTableVoltage:
        data.acwPowerSupplyTableVoltage.map((t) => ({
          name: t.name,
          value: t.name,
        })),
      acwDoorTableReaderStyles:
        data.acwDoorTableReaderStyles.map((t) => ({
          name: t.name,
          value: t.name,
        })),
      acwDoorTableLockStyles:
        data.acwDoorTableLockStyles.map((t) => ({
          name: t.name,
          value: t.name,
        })),
      controlPanelDropdowns:
        data.controlPanelDropdown?.map((t) => ({
          manufacturer: acacManufacturers[t.acacSystemManufacturers],
          text: t.text,
          value: t.value,
        })),
    },
    dealDrawer: {
      dealDrawerDealDrawerManufacturers: data.dealDrawerDealDrawerManufacturers,
      dealDrawerDealDrawerOverallConditions: data.dealDrawerDealDrawerOverallConditions,
    },
    rts: {
      rtsRtsMake: data.rtsRtsMake,
      rtsRtsUpsendDownsend: data.rtsRtsUpsendDownsend,
      rtsRtsOverallConditions: data.rtsRtsOverallConditions,
    },
    audioVideoDU: {
      audioVideoDUAudioMatrixManufacturers: data.audioVideoDUAudioMatrixManufacturers,
      audioVideoDUDriveUpAudioMatrixVideoSystem: data.audioVideoDUDriveUpAudioMatrixVideoSystem,
      audioVideoDUDriveUpAudioMatrixOverallConditions: data.audioVideoDUDriveUpAudioMatrixOverallConditions,
    },
  };
};
