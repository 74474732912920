import { IndexableFormType } from './miscTypes';

export const onRemoveItem = <T>(list: T[], item: T) => {
  const indexAt = list.indexOf(item);
  list.splice(indexAt, 1);
  return list.map((x, index) => ({
    ...x,
    index: index + 1,
  }));
};

export const onAddItem = <T extends IndexableFormType>(list: T[], newItem: (index: number) => T) => {
  const maxIndex = list.length === 0 ? 1 : Math.max(...list.map((item) => item.index)) + 1;
  const newEmptyItem = newItem(maxIndex);
  list.push(newEmptyItem);
};
