<h4 class="title">Complete Work Order</h4>
<p>Started on: {{ startedDate | date : 'MM/dd/yyyy' }} - {{ startedDate | date : 'hh:mm a' }}</p>
<p>Pick a time to complete the work order:</p>
<ads-timepicker *ngIf="startedDate" [dateValue]="startedDate" (valueChange)="onTimeChange($event)"></ads-timepicker>
<p *ngIf="!validTime" class="invalid-time-warning">Complete Time should be greater than Started Time.</p>
<div class="menu-actions">
  <ads-custom-button label="Cancel" (clicked)="hideAll()"></ads-custom-button>
  <ads-custom-button
    class="main-button confirm"
    label="Confirm"
    (clicked)="onConfirm()"
    [disabled]="!validTime"
  ></ads-custom-button>
</div>
