<ads-simple-form #cardReaderTasks="ngModelGroup" ngModelGroup="cardReaderTasks">
  <ads-single-toggle-button
    [leftLabel]="'Select All'"
    [buttonLabel]="'N/A'"
    [(ngModel)]="selectAllChecked"
    name="allNa"
    (ngModelChange)="onSelectAllChange($event)"
  ></ads-single-toggle-button
  ><mat-divider></mat-divider>
  <ads-radio-button-group
    [options]="options"
    label="Clean pre-head (MOTORIZED CARD READER)"
    name="cleanPrehead"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Check and clean all rollers (MOTORIZED CARD READER)"
    name="cleanAllRollers"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Check and clean all belts (MOTORIZED CARD READER)"
    name="cleanAllBelts"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Clean read head"
    name="cleanReadHead"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Clean with felt card"
    name="cleanFeltCard"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Blow out sensor areas"
    name="blowSensorAreas"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Perform diagnostic test"
    name="diagnosticTest"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="options"
    label="Is card reader EMV capable?"
    name="evmCapable"
    [required]="true"
    (optionClicked)="onOptionClicked()"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
