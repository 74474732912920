import { Component } from '@angular/core';
import { LayoutNavService } from '../layout-nav.service';
import { CommonModule } from '@angular/common';
import { ProfileNameComponent } from 'shared/profile-name/profile-name.component';
import { LogoutFooterComponent } from 'shared/logout-footer/logout-footer.component';
import { NavButtonComponent } from '../nav-button/nav-button.component';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'ads-desktop-nav',
  standalone: true,
  imports: [CommonModule, ProfileNameComponent, LogoutFooterComponent, NavButtonComponent, MatDividerModule],
  templateUrl: './desktop-nav.component.html',
  styleUrl: './desktop-nav.component.scss',
})
export class DesktopNavComponent {
  constructor(public layoutNavService: LayoutNavService) {}
}
