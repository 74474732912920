import { Component, Input } from "@angular/core";
import { ServiceCallsComponent } from "../service-calls-component/service-calls.component";
import { AdsCallControl } from "../call-control.service";

@Component({
  selector: 'ads-call-control-service-calls',
  standalone: true,
  imports: [ServiceCallsComponent],
  templateUrl: './call-control-service-calls.component.html',
})
export class CallControlServiceCallsComponent {
  @Input() isDesktopView = false;
  @Input() calls: AdsCallControl[] = [];
  constructor() {}
}
