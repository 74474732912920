import { CommonModule } from '@angular/common';
import { Component, Input, AfterViewInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { AccessHardwareSectionFormComponent } from './access-hardware-section-form/access-hardware-section-form.component';
import { SecurityService } from 'pages/security/security.service';
import {
  AccessControlCardReaderInterfaceTableForm,
  AccessControlDoorTableForm,
  AccessControlPowerSupplyTableForm,
  AccessControlsForm,
} from 'models/security/access-hardware/accessHardwareSecurityForm';
import { NestedMultipleFormsSectionComponent } from 'forms/shared/nested-multiple-forms-section/nested-multiple-forms-section.component';
import { AhAccessControlTasksSectionComponent } from './ah-access-control-tasks-section/ah-access-control-tasks-section.component';
import { IndexableFormType, Section } from 'app/utils/miscTypes';
import { AhCardReaderInterfaceTableSectionComponent } from './ah-card-reader-interface-table-section/ah-card-reader-interface-table-section.component';
import { AccessControlHardwareDropdowns } from 'models/security/security-dropdowns';
import { getFormGroup } from 'utils';
import { AhCardReaderInterfaceTasksSectionComponent } from './ah-card-reader-interface-tasks-section/ah-card-reader-interface-tasks-section.component';
import { AhPowerSupplyTableSectionComponent } from './ah-power-supply-table-section/ah-power-supply-table-section.component';
import { AhPowerSupplyTasksSectionComponent } from './ah-power-supply-tasks-section/ah-power-supply-tasks-section.component';
import { AhDoorsTableSectionComponent } from './ah-doors-table-section/ah-doors-table-section.component';
import { BadgeTextComponent } from 'shared/badge-text/badge-text.component';
import { MatInputModule } from '@angular/material/input';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { getSectionStatusFormGroup } from 'app/utils/form-status';

export interface AccessControlsFormSection extends IndexableFormType, AccessControlsForm {}
export interface AccessControlCardReaderInterfaceTableFormSection
  extends IndexableFormType,
    AccessControlCardReaderInterfaceTableForm {}
export interface AccessControlPowerSupplyTableFormSection
  extends IndexableFormType,
    AccessControlPowerSupplyTableForm {}

export interface AccessControlDoorTableFormSection extends IndexableFormType, AccessControlDoorTableForm {}

@Component({
  selector: 'ads-access-hardware',
  standalone: true,
  imports: [
    CommonModule,
    SimpleFormComponent,
    FormsModule,
    MatInputModule,
    ExpansionPanelComponent,
    NestedMultipleFormsSectionComponent,
    AccessHardwareSectionFormComponent,
    AhAccessControlTasksSectionComponent,
    AhCardReaderInterfaceTableSectionComponent,
    AhCardReaderInterfaceTasksSectionComponent,
    AhPowerSupplyTableSectionComponent,
    AhPowerSupplyTasksSectionComponent,
    AhDoorsTableSectionComponent,
    BadgeTextComponent,
    CommonTextAreaComponent,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './access-hardware.component.html',
  styleUrl: './access-hardware.component.scss',
})
export class AccessHardwareComponent implements AfterViewInit {
  @Input() formGroup?: FormGroup | null;
  @Input() submitted = false;

  @Input() achDropdowns: AccessControlHardwareDropdowns = {
    acacSystemManufacturers: [],
    acacOverallConditions: [],
    acCardReaderInterfaceTableInterfaceTypes: [],
    acwPowerSupplyTablePowerSupply: [],
    acwPowerSupplyTableVoltage: [],
    acwDoorTableReaderStyles: [],
    acwDoorTableLockStyles: [],
    controlPanelDropdowns: [],
  };

  expandedList: number[] = [];

  constructor(public readonly security: SecurityService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.formGroup?.controls['accessControlTasks'].setValue({
        allNa: false,
        verifyComms: this.security.accessHardwareData.accessControlTasks.verifyComms,
        testTamper: this.security.accessHardwareData.accessControlTasks.testTamper,
        testPowerFail: this.security.accessHardwareData.accessControlTasks.testPowerFail,
      });

      this.formGroup?.controls['accessControlCardReaderTasks'].setValue({
        allNa: false,
        visuallyInspectWire: this.security.accessHardwareData.accessControlCardReaderTasks.visuallyInspectWire,
      });

      this.formGroup?.controls['accessControlPowerSupplyTasks'].setValue({
        allNa: false,
        visuallyInspectWire: this.security.accessHardwareData.accessControlPowerSupplyTasks.visuallyInspectWire,
      });

      const doors: Record<string, AccessControlDoorTableForm> = {};

      this.security.accessHardwareData.accessControlDoors.forEach((door, index) => {
        const doorWithoutIndex: Partial<AccessControlDoorTableFormSection> = { ...door };
        delete doorWithoutIndex.index;
        doors['accessControlDoor#' + (index + 1)] = doorWithoutIndex as AccessControlDoorTableForm;
      });

      this.formGroup?.controls['accessControlDoors'].setValue(doors);

      this.formGroup?.controls['comments'].setValue(this.security.accessHardwareData.comments);

      if (this.submitted) {
        this.formGroup?.disable();
      }
    });
  }

  getItemSection(sectionName: string, containerName: string) {
    return getFormGroup(this.formGroup?.controls[containerName])?.controls[sectionName] as FormGroup;
  }

  getFormControls(containerName: string): Record<string, AbstractControl<string, string>> {
    const fg = this.formGroup?.get(containerName) as FormGroup;
    return fg ? fg.controls : {};
  }

  onAddClick(
    sectionType:
      | 'accessControls'
      | 'accessControlCardReaderInterfaces'
      | 'accessControlPowerSupplies'
      | 'accessControlDoors'
  ) {
    const sections = this.security.accessHardwareData[sectionType];

    const maxIndex = sections.length === 0 ? 1 : Math.max(...sections.map((item) => item.index)) + 1;

    const newEmptySection: Section = {
      index: maxIndex,
    };

    switch (sectionType) {
      case 'accessControls':
        newEmptySection['systemManufacturer'] = '';
        break;
      case 'accessControlCardReaderInterfaces':
        newEmptySection['location'] = '';
        break;
      case 'accessControlPowerSupplies':
        newEmptySection['location'] = '';
        break;
      case 'accessControlDoors':
        newEmptySection['readerLocation'] = '';
        break;
    }

    sections.push(newEmptySection as never);
  }

  onRemoveSection(
    item:
      | AccessControlsFormSection
      | AccessControlCardReaderInterfaceTableFormSection
      | AccessControlPowerSupplyTableFormSection
      | AccessControlDoorTableFormSection,
    sectionName:
      | 'accessControls'
      | 'accessControlCardReaderInterfaces'
      | 'accessControlPowerSupplies'
      | 'accessControlDoors'
  ) {
    const indexAt = this.security.accessHardwareData[sectionName].indexOf(item as never);
    this.security.accessHardwareData[sectionName].splice(indexAt, 1);
    this.security.accessHardwareData[sectionName] = this.security.accessHardwareData[sectionName].map((x, index) => ({
      ...x,
      index: index + 1,
    })) as never;
  }

  getSectionStatus = getSectionStatusFormGroup(this.formGroup);

  getTasksSection(containerName: string) {
    return this.formGroup?.controls[containerName] as FormGroup;
  }

  getDoorsBadges() {
    const doors = this.security.accessHardwareData.accessControlDoors;
    const totalBiometric = doors.filter((door) => door.readerStyle === 'Biometric').length;
    const totalOther = doors.filter((door) => door.readerStyle === 'Other').length;
    const totalProx = doors.filter(
      (door) => door.readerStyle?.includes('Prox') || door.readerStyle?.includes('Thin')
    ).length;

    return {
      biometric: totalBiometric,
      prox: totalProx,
      other: totalOther,
      smart: doors.length - totalBiometric - totalProx - totalOther,
    };
  }
}
