<ads-simple-form *ngIf="item" class="rts-section-wrapper" [ngModelGroup]="'rtsPanel#' + item.index">
  <ads-select
    label="RTS Make"
    name="rtsMake"
    [required]="true"
    [list]="rtsLists?.rtsRtsMakeList"
    [value]="item.rtsMake"
    [(ngModel)]="item.rtsMake"
  ></ads-select>
  <ads-common-form-input
    [style.display]="displayOtherMake() ? 'block' : 'none'"
    name="rtsMakeOther"
    label="Other Make"
    [maxLength]="50"
    [required]="displayOtherMake()"
    [value]="item.rtsMakeOther"
    [(ngModel)]="item.rtsMakeOther"
  ></ads-common-form-input>
  <ads-common-form-input
    name="rtsModel"
    label="RTS Model"
    [maxLength]="50"
    [required]="true"
    [value]="item.rtsModel"
    [(ngModel)]="item.rtsModel"
  ></ads-common-form-input>
  <ads-common-form-input
    name="laneNumber"
    label="Lane Number"
    type="number"
    [minValue]="0"
    [maxLength]="4"
    [required]="true"
    [value]="item.laneNumber"
    [(ngModel)]="item.laneNumber"
    inputMode="tel"
  ></ads-common-form-input>
  <ads-select
    label="Upsend or Downsend?"
    name="upsendOrDownsend"
    [required]="true"
    [list]="rtsLists?.upsendDownSendList"
    [value]="item.upsendOrDownsend"
    [(ngModel)]="item.upsendOrDownsend"
  ></ads-select>
  <ads-common-form-input
    name="tubeLength"
    label="Length of tube run if not standard configuration"
    type="number"
    [minValue]="0"
    [maxLength]="4"
    [required]="false"
    [value]="item.tubeLength"
    [(ngModel)]="item.tubeLength"
    inputMode="tel"
  ></ads-common-form-input>
  <ads-select
    label="Overall Condition - RTS Unit"
    name="overallCondition"
    [required]="true"
    [list]="rtsLists?.overallConditionList"
    [value]="item.overallCondition"
    [(ngModel)]="item.overallCondition"
  ></ads-select>
  <ads-common-text-area
    [style.display]="displayEquipmentRating() ? 'block' : 'none'"
    name="overallConditionComments"
    label="Substandard equipment comments"
    [maxLength]="500"
    [required]="displayEquipmentRating()"
    [value]="item.overallConditionComments"
    [(ngModel)]="item.overallConditionComments"
  >  </ads-common-text-area>
</ads-simple-form>
