import { ItmCleaning, NetworkLogo } from 'models';
import { ItmCleaningResponse } from 'models/api/itm-cleaning/itm-cleaning-response';
import { ItmCleaningDropdowns } from 'models/itm-cleaning/itm-cleaning-dropdowns';

export const mapItmCleaningForm = (response: ItmCleaningResponse): ItmCleaning => {
  const data = response.itmCleaning;
  const logos: { logo: NetworkLogo; dto: boolean }[] = [
    { logo: NetworkLogo.AmericanExpress, dto: data.itmNetworkLogos_AmericanExpress ?? false },
    { logo: NetworkLogo.Cirrus, dto: data.itmNetworkLogos_Cirrus ?? false },
    { logo: NetworkLogo.Coop, dto: data.itmNetworkLogos_Coop ?? false },
    { logo: NetworkLogo.Discover, dto: data.itmNetworkLogos_Discover ?? false },
    { logo: NetworkLogo.Maestro, dto: data.itmNetworkLogos_Maestro ?? false },
    { logo: NetworkLogo.Mastercard, dto: data.itmNetworkLogos_Mastercard ?? false },
    { logo: NetworkLogo.Plus, dto: data.itmNetworkLogos_Plus ?? false },
    { logo: NetworkLogo.Star, dto: data.itmNetworkLogos_Star ?? false },
    { logo: NetworkLogo.Visa, dto: data.itmNetworkLogos_Visa ?? false },
  ];

  const getLogos = () => {
    const someLogo = logos.reduce((value, nextLogo) => value || nextLogo.dto, false);
    if (someLogo) {
      return logos.filter((logo) => logo.dto).map((logo) => logo.logo);
    } else {
      return [NetworkLogo.None];
    }
  };

  return {
    itmExteriorEvaluation: {
      allNa: false,
      audioInstructionsFunctioning: data.itmExtEval_AreAudioInstructionsFunctioning,
      isSoftwareOnsite: data.itmExtEval_IsSoftwareOnsite,
      depositoryAvailabilityStickerPresent: data.itmExtEval_IsDepositoryAvailabilityStickerPresent,
      surchargeStickerPresent: data.itmExtEval_IsSurchargeStickerPresent,
    },
    itmNetworkLogos: {
      other: '',
      logos: getLogos(),
    },
    itmSection: {
      communicationType: data.itm_CommunicationType,
      manufacturer: data.itm_Manufacturer,
      depositorType: data.itm_DepositorType,
      terminalId: data.itm_TerminalID,
      model: data.itm_Model,
      serialNumber: data.itm_SerialNumber,
      networkTerminalId: data.itm_NetworkTerminalId,
    },
    cleaningTasks: {
      allNa: false,
      cleanCoinHolder: data.cleaningTasks_CleanCoinHolder,
      cleanIdScanner: data.cleaningTasks_CleanIdScanner,
      cleanSignaturePad: data.cleaningTasks_CleanSignaturePad,
      customerDisplay: data.cleaningTasks_CleanWipeCustomerDisplay,
      fasciaTrim: data.cleaningTasks_CleanWipeFasciaTrim,
      functionKeys: data.cleaningTasks_CleanWipeFunctionKeys,
      vacuumAllFans: data.cleaningTasks_VaccumBlowoutAllFans,
      itmArea: data.cleaningTasks_CleanItmAreaAndDisposeGarbage,
      vacuumSafe: data.cleaningTasks_VaccumBlowoutSafe,
      vacuumTopHalf: data.cleaningTasks_VaccumBlowoutTopHalf,
      itmSidesBackAndTop: data.cleaningTasks_CleanWipeItmSides,
      vacuumAllFilters: data.cleaningTasks_VaccumBlowoutAllFilters,
      thoroughInspection: data.cleaningTasks_ThoroughlyInspectItm,
    },
    replenishmentTasks: {
      allNa: false,
      depositoryInk: data.replenishmentTasks_CheckDepositoryInkCartridge,
      paperRoll: data.replenishmentTasks_CheckPrinterPaperRoll,
    },
    comments: data.comments ?? '',
  } as ItmCleaning;
};

export interface ItmCleaningResponseDropdowns {
  itmManufactures: {
    manufacturerName: string;
    manufacturerId: string;
  }[];
  itmDepositors: {
    depositorTypeName: string;
    depositorTypeCode: string;
  }[];
  communicationTypes: {
    name: string;
    code: number;
  }[];
}

export const mapItmCleaningDropdowns = (data: ItmCleaningResponseDropdowns): ItmCleaningDropdowns => {
  return {
    manufacturerList: data.itmManufactures.map((item) => ({
      name: item.manufacturerName,
      value: item.manufacturerName,
    })),
    depositorTypeList: data.itmDepositors.map((item) => ({
      name: item.depositorTypeName,
      value: item.depositorTypeName,
    })),
    communicationTypes: data.communicationTypes.map((item) => ({
      name: item.name,
      value: item.name,
    })),
  };
};
