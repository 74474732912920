import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { FormsModule, ControlContainer, NgModelGroup, FormGroup } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { SecuritySurveillanceDropdowns } from 'models/security/security-dropdowns';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { SurveillanceUPSSection } from '../surveillance.component';
import { SimpleFormComponent } from '../../../../shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-surveillance-ups-section',
  standalone: true,
  imports: [CommonModule, FormsModule, CommonFormInputComponent, SelectComponent, MatDividerModule, SimpleFormComponent],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
  templateUrl: './surveillance-ups-section.component.html',
})
export class SurveillanceUpsSectionComponent {
  @Input() upsLists?: SecuritySurveillanceDropdowns;
  @Input() section?: FormGroup;
  @Input() item?: SurveillanceUPSSection;

  constructor(@Optional() public readonly ngModelGroup: NgModelGroup) {}

  displayOtherManufacturer = () => {
    const manufacturer = this.section?.get('upsManufacturer');
    return Boolean(manufacturer && manufacturer.value === 'Other');
  };
}
