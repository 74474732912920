import {
  AccessControlCardReaderInterfaceTableFormSection,
  AccessControlDoorTableFormSection,
  AccessControlPowerSupplyTableFormSection,
  AccessControlsFormSection,
} from 'forms/security/access-hardware/access-hardware.component';
import { AudioVideoLists } from 'forms/security/audio-video/audio-video-section-form/audio-video-section-form.component';
import { AudioVideoFormSection } from 'forms/security/audio-video/audio-video.component';
import { DealDrawerLists } from 'forms/security/deal-drawer/deal-drawer-section-form/deal-drawer-section-form.component';
import { DealDrawerFormSection } from 'forms/security/deal-drawer/deal-drawer.component';
import { NightDropFormSection } from 'forms/security/night-drop/night-drop.component';
import { RtsFormSection } from 'forms/security/rts/rts.component';
import { SafesFormSection } from 'forms/security/safes/safes.component';
import {
  SurveillanceUPSSection,
  SurveillanceDVRNVRSection,
  SurveillancePOESwitchSection,
  SurveillanceCameraTableSection,
  SurveillanceMonitorTableSection,
} from 'forms/security/surveillance/surveillance.component';
import { UCSFormSection } from 'forms/security/ucs/ucs.component';
import { VaultLists } from 'forms/security/vault/vault-section-form/vault-section-form.component';
import { VaultSectionFormSection } from 'forms/security/vault/vault.component';
import { AlarmPanelForm } from 'models/security/alarm/alarmPanels';
import {
  AccessControlCardReaderTasksForm,
  AccessControlPowerSupplyTasksForm,
  AccessControlTasksForm,
} from 'models/security/access-hardware/accessHardwareSecurityForm';

import {
  AccessControlHardwareDropdowns,
  SecurityNightDropDropdowns,
  SecuritySafesDropdowns,
  SecuritySurveillanceDropdowns,
  SecurityUCSDropdowns,
} from 'models/security/security-dropdowns';

import {
  SurveillanceCameraTasksForm,
  SurveillanceDVRTasksForm,
  SurveillanceMonitorTasksForm,
} from 'models/security/surveillance/surveillanceSecurityForm';
import { UCSTasks } from 'models/security/ucs/ucsSecurityForm';
import { PhoneNumber } from 'shared/models/phone-number';
import { WireLessReceiverSectionForm } from 'models/security/alarm/wirelessReceiverSection';
import { ZoneExpanderSectionForm } from 'models/security/alarm/zoneExpanderSection';
import { PathSectionForm } from 'models/security/alarm/pathTableSection';
import {
  AlarmPanelTasks,
  AudioMatrixTasks,
  BatteryTasks,
  CustomerUnitForm,
  DealDrawerTasks,
  HubTasks,
  KeypadsForm,
  KeypadTasks,
  SelectOption,
  SensorsForm,
  SensorsTasks,
  TellerUnitForm,
  YesNoNa,
} from 'models';
import { BatteriesForm } from 'models/security/alarm/batteries';
import { HubSectionForm } from 'models/security/alarm/hubSection';
import { RtsLists } from 'forms/security/rts/rts.models';
import {
  NightDropComboLockTasks,
  NightDropDropDoorTasks,
  NightDropHeadTasks,
  NightDropTimeClockTasks,
} from 'models/security/night-drop/nightDropSecurityForm';
import { IndexableFormType } from 'app/utils/miscTypes';
import { SafesTasks } from 'models/security/safes/safesSecurityForm';
import { ComboLockTasks, TimeClockTasks } from 'models/security/shared/shared-tasks';

export class AlarmPanelFormSection implements IndexableFormType, AlarmPanelForm {
  alarmModel: string | null;
  firmwareVersion: string | null;
  overallCondition: string | null;
  accountNumber: string | null;
  phoneNumber: PhoneNumber | null;
  ipAddress: string | null;
  meId: string | null;
  zonesAmount: string | null;
  alarmPanelZonesAmount: string | null;
  manufacturer: string | null;
  otherManufacturer: string | null;
  otherEquipmentRating: string | null;
  otherCentralStation: string | null;
  centralStation: string | null;
  index: number;
  constructor(index: number) {
    this.index = index;
    this.alarmModel = '';
    this.accountNumber = '';
    this.firmwareVersion = '';
    this.overallCondition = '';
    this.meId = '';
    this.phoneNumber = new PhoneNumber('', '', '');
    this.ipAddress = '';
    this.zonesAmount = '';
    this.alarmPanelZonesAmount = '';
    this.manufacturer = '';
    this.otherManufacturer = '';
    this.centralStation = '';
    this.otherEquipmentRating = '';
    this.otherCentralStation = '';
  }
}
export class ZoneExapnderForm implements IndexableFormType, ZoneExpanderSectionForm {
  location: string | null;
  index: number;
  constructor(index: number) {
    this.index = index;
    this.location = '';
  }
}

export class WirelessReceiverForm implements IndexableFormType, WireLessReceiverSectionForm {
  location: string | null;
  manufacturer: string | null;
  model: string | null;
  lastBatterChangeButtons: Date | null;
  lastBatterChangeContacts: Date | null;
  lastBatterChangeMoneyClips: Date | null;
  lastBatterChangeMotions: Date | null;
  index: number;
  constructor(index: number) {
    this.index = index;
    this.location = '';
    this.lastBatterChangeButtons = null;
    this.lastBatterChangeContacts = null;
    this.lastBatterChangeMoneyClips = null;
    this.lastBatterChangeMotions = null;
    this.manufacturer = '';
    this.model = '';
  }
}

export class WireLessReceiverFormSection implements IndexableFormType, WireLessReceiverSectionForm {
  location: string | null;
  manufacturer: string | null;
  model: string | null;
  lastBatterChangeButtons: Date | null;
  lastBatterChangeContacts: Date | null;
  lastBatterChangeMoneyClips: Date | null;
  lastBatterChangeMotions: Date | null;
  index: number;
  constructor(index: number) {
    this.index = index;
    this.location = '';
    this.lastBatterChangeButtons = new Date();
    this.lastBatterChangeContacts = new Date();
    this.lastBatterChangeMoneyClips = new Date();
    this.lastBatterChangeMotions = new Date();
    this.manufacturer = '';
    this.model = '';
  }
}
export class PathTableFormSection implements IndexableFormType, PathSectionForm {
  communicationType: string | null;
  path: string | null;
  pathType: string | null;
  receiverAddress: string | null;
  index: number;
  constructor(index: number) {
    this.index = index;
    this.path = '';
    this.pathType = '';
    this.communicationType = '';
    this.receiverAddress = '';
  }
}

export class HubFormSection implements IndexableFormType, HubSectionForm {
  index: number;
  quantity: string | null;
  type: string | null;
  constructor(index: number) {
    this.index = index;
    this.quantity = '';
    this.type = '';
  }
}

export class BatteriesFormSection implements IndexableFormType, BatteriesForm {
  index: number;
  batteriesAmount: string | null;
  installation: Date | null;
  secondaryBatteryInstallation: Date | null;
  secondaryPowerSupply: YesNoNa | null;
  voltageTestResult: string | null;
  constructor(index: number) {
    this.index = index;
    this.installation = null;
    this.secondaryPowerSupply = null;
    this.batteriesAmount = null;
    this.voltageTestResult = null;
    this.secondaryBatteryInstallation = null;
  }
}

export interface AlarmPageData {
  alarmPanelSections: AlarmPanelFormSection[];
  zoneExpanderSections: ZoneExapnderForm[];
  wirelessReceiverSections: WireLessReceiverFormSection[];
  pathTableSections: PathTableFormSection[];
  alarmDropdowns: AlarmLists;
  alarmPanelTasks: AlarmPanelTasks;
  batteries: BatteriesForm;
  batteryTasks: BatteryTasks;
  keypads: KeypadsForm;
  keypadTasks: KeypadTasks;
  sensors: SensorsForm;
  sensorsTasks: SensorsTasks;
  hubSections: HubFormSection[];
  platformSections: HubFormSection[];
  tellerTasks: HubTasks;
  platformTasks: HubTasks;
  comments: string | null;
}

export interface VaultPageData {
  vaultSectionSections: VaultSectionFormSection[];
  vaultDropdowns: VaultLists;
  comboLockTasks: ComboLockTasks;
  timeClockTasks: TimeClockTasks;
  comments: string | null;
}

export interface SafesPageData {
  safesSections: SafesFormSection[];
  safesTasks: SafesTasks;
  timeClockTasks: TimeClockTasks;
  comboLockTasks: ComboLockTasks;
  safesDropdowns: SecuritySafesDropdowns;
  comments: string | null;
}

export interface UCSPageData {
  ucsSections: UCSFormSection[];
  ucsDropdowns: SecurityUCSDropdowns;
  ucsTasks: UCSTasks;
  comments: string | null;
}

export interface NightDropPageData {
  nightDropSections: NightDropFormSection[];
  nightDropDropdowns: SecurityNightDropDropdowns;
  nightDropDropDoorTasks: NightDropDropDoorTasks;
  timeClockTasks: NightDropTimeClockTasks;
  comboLockTasks: NightDropComboLockTasks;
  nightDropHeadTasks: NightDropHeadTasks;
  comments: string | null;
}

export interface DealDrawerPageData {
  dealDrawerSections: DealDrawerFormSection[];
  dealDrawerDropdowns: DealDrawerLists;
  dealDrawerTasks: DealDrawerTasks;
  comments: string | null;
}

export interface RtsPageData {
  rtsSections: RtsFormSection[];
  rtsDropdowns: RtsLists;
  tellerUnit: TellerUnitForm;
  customerUnit: CustomerUnitForm;
  comments: string | null;
}

export interface AccessHardwarePageData {
  accessHardwareDropdowns: AccessControlHardwareDropdowns;
  accessControls: AccessControlsFormSection[];
  accessControlTasks: AccessControlTasksForm;
  accessControlCardReaderInterfaces: AccessControlCardReaderInterfaceTableFormSection[];
  accessControlCardReaderTasks: AccessControlCardReaderTasksForm;
  accessControlPowerSupplies: AccessControlPowerSupplyTableFormSection[];
  accessControlPowerSupplyTasks: AccessControlPowerSupplyTasksForm;
  accessControlDoors: AccessControlDoorTableFormSection[];
  comments: string | null;
}

export interface AudioVideoPageData {
  audioVideoSections: AudioVideoFormSection[];
  audioVideoDropdowns: AudioVideoLists;
  audioMatrixTasks: AudioMatrixTasks;
  comments: string | null;
}

export interface SurveillancePageData {
  overallConditionCameras: string | null;
  equipmentRatingCameras: string | null;
  overallConditionMonitors: string | null;
  equipmentRatingMonitors: string | null;
  dvrNvrSections: SurveillanceDVRNVRSection[];
  upsSections: SurveillanceUPSSection[];
  poeSwitchSections: SurveillancePOESwitchSection[];
  cameraTableSections: SurveillanceCameraTableSection[];
  monitorTableSections: SurveillanceMonitorTableSection[];
  surveillanceDropdowns: SecuritySurveillanceDropdowns;
  surveillanceDvrTasks: SurveillanceDVRTasksForm;
  surveillanceCameraTasks: SurveillanceCameraTasksForm;
  surveillanceMonitorTasks: SurveillanceMonitorTasksForm;
  comments: string | null;
}

export interface AlarmLists {
  manufacturerList: SelectOption<string | number>[];
  alarmPanelOverallConditionsList: SelectOption<string | number>[];
  centralStationsList: SelectOption<string | number>[];
  pathTypesList: SelectOption<string | number>[];
  communicationTypeList: SelectOption<string | number>[];
  hubTypes: SelectOption<string | number>[];
}
