import { PhotoUpload } from './photo-upload';

export const itmPhotoUploadKeys = [
  'itm-front-distance',
  'itm-front-close',
  'itm-card-reader-from-inside-fascia',
  'itm-depositories',
  'itm-dispenser',
  'itm-customer-monitor-from-inside-fascia',
  'itm-serial-number-label',
  'itm-computer',
  'itm-touchscreen-fdks',
  'itm-printer',
  'itm-id-scanner',
  'itm-additional-photo',
  'itm-operator-panel',
  'itm-software-display-in-one-shot',
  'itm-coin-dispenser',
] as const;

export type ItmPhotoUploadKey = (typeof itmPhotoUploadKeys)[number];

export const itmPhotoUploads: PhotoUpload<ItmPhotoUploadKey>[] = [
  {
    label: 'Front Distance',
    key: 'itm-front-distance',
    required: true,
  },
  {
    label: 'Front Close',
    key: 'itm-front-close',
    required: true,
  },
  {
    label: 'Card Reader (from inside fascia)',
    key: 'itm-card-reader-from-inside-fascia',
    required: true,
  },
  {
    label: 'Depositor(ies)',
    key: 'itm-depositories',
    required: true,
  },
  {
    label: 'Dispenser',
    key: 'itm-dispenser',
    required: true,
  },
  {
    label: 'Customer Monitor (from inside fascia)',
    key: 'itm-customer-monitor-from-inside-fascia',
    required: true,
  },
  {
    label: 'Serial Number Label',
    key: 'itm-serial-number-label',
    required: true,
  },
  {
    label: 'Computer',
    key: 'itm-computer',
    required: true,
  },
  {
    label: 'Touchscreen/FDKs',
    key: 'itm-touchscreen-fdks',
    required: true,
  },
  {
    label: 'Printer',
    key: 'itm-printer',
    required: true,
  },
  {
    label: 'ID Scanner (optional)',
    key: 'itm-id-scanner',
    required: false,
  },
  {
    label: 'Additional Photo (optional)',
    key: 'itm-additional-photo',
    required: false,
  },
  {
    label: 'Operator Panel (optional)',
    key: 'itm-operator-panel',
    required: false,
  },
  {
    label: 'Software - display in one shot (optional)',
    key: 'itm-software-display-in-one-shot',
    required: false,
  },
  {
    label: 'Coin Dispenser (optional)',
    key: 'itm-coin-dispenser',
    required: false,
  },
];
