import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WorkOrderService } from '../work-order-service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { b64toBlob, saveAs } from 'app/utils/data-uri-to-blob';
import { AttachmentAddDialogComponent } from '../attachment-add-dialog/attachment-add-dialog.component';

@Component({
  selector: 'ads-attachment-list-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './attachment-list-dialog.component.html',
  styleUrl: './attachment-list-dialog.component.scss',
})
export class AttachmentListDialogComponent {
  public readonly dialogRef = inject(MatDialog);

  constructor(public readonly workOrderService: WorkOrderService) {}

  viewAttachment(recId: number): void {
    this.workOrderService.getAttachmentFile(recId).subscribe((response) => {
      if (response.data.base64str) {
        const fileExt = response.data.fileExt?.toLowerCase();
        const name = response.data.name;
        const blob = b64toBlob(response.data.base64str, fileExt);
        saveAs(blob, `${name}.${fileExt}`);
      }
    });
  }

  deleteAttachment(documentId: string): void {
    this.workOrderService.deleteAttachment(documentId).subscribe(() => {
      this.workOrderService.attachments.set(
        this.workOrderService.attachments().filter((attachment) => attachment.documentId !== documentId),
      );
    });
  }

  addNewAttachment(): void {
    this.dialogRef.open(AttachmentAddDialogComponent);
  }

  closeDialog(): void {
    this.dialogRef.closeAll();
  }
}
