import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { ValidateRequiredDirective } from 'app/shared/directives/validate-required.directive';
import { SelectOption } from 'models';

@Component({
  selector: 'ads-radio-list',
  standalone: true,
  imports: [CommonModule, MatRadioModule, FormsModule, ValidateRequiredDirective],
  templateUrl: './radio-list.component.html',
  styleUrl: './radio-list.component.scss',
})
export class RadioListComponent<T> {
  @Input() label = '';
  @Input() name = '';
  @Input() options: SelectOption<T>[] = [];
  @Input() required = false;
  @Input() multiple = false;
  @Input() removeIcon = false;
  @Input() verticalLayout = false;
  @Input() value: T = '' as T;
  @Output() optionClicked = new EventEmitter<T | T[]>();

  constructor() {}

  onChange(value: T) {
    this.optionClicked.emit(value);
  }
}
