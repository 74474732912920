import { AuthService } from 'services';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { CallControlService } from 'pages/call-control/call-control.service';
import { TicketInformation } from 'models/call-control/ticket';
import { ModalActionsComponent } from 'shared/modal-actions/modal-actions.component';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';

@Component({
  selector: 'ads-cancel-request',
  standalone: true,
  imports: [CommonModule, FormsModule, CommonTextAreaComponent, ModalActionsComponent],
  templateUrl: './cancel-request.component.html',
  styleUrl: './cancel-request.component.scss',
})
export class CancelRequestComponent {
  reason = '';

  public readonly _snackBar = inject(MatSnackBar);
  public readonly _bottomSheet = inject(MatBottomSheet);
  public readonly dialogRef = inject(MatDialog);

  constructor(
    public readonly callControlService: CallControlService,
    public readonly authService: AuthService,
    public readonly workOrderService: WorkOrderService
  ) {}

  onConfirm() {
    if (this.reason && this.authService.authenticated && this.workOrderService.workOrderDetails()) {
      try {
        this.authService
          .sendMailMessage(
            `Cancellation Request - ${this.workOrderService.workOrderDetails().workorder}`,
            `${this.authService.user?.fullName} has requested cancellation on call number ${
              this.workOrderService.workOrderDetails().workorder
            }: ${this.reason}`
          )
          ?.then(this.onWoCancelSuccess);
      } catch (error) {
        this.onWoCancelError(error);
      }
      this.onCancel();
    }
  }

  onCancel() {
    this._bottomSheet.dismiss();
    this.dialogRef.closeAll();
  }

  onWoCancelSuccess = (result: TicketInformation) => {
    if (result.ticketRecId > 0) {
      this._snackBar.open('Cancellation request submitted successfully.', 'Close');
    } else {
      this.onWoCancelError('Failed to request cancellation, please try again later.');
    }
  };

  onWoCancelError = (error: unknown) => {
    console.error(error);
    this._snackBar.open('Failed to request cancellation, please try again later.', 'Close');
  };
}
