<div class="container">
  <h3>
    <button mat-icon-button (click)="goBack()">
      <mat-icon class="material-icons-outlined">arrow_back</mat-icon>
    </button>
    {{ workOrderService.workOrder }}

    <span
      class="status-chip"
      [ngStyle]="{ background: statusColors.get(workOrderService.workOrderDetails().laststatusname ?? 'Susp-Other') }"
      [ngClass]="{ black: ['Scheduled', 'Susp-Other'].includes(workOrderService.workOrderDetails().laststatusname ?? '') }"
    >
      {{ workOrderService.workOrderDetails().laststatusname }}</span
    >
  </h3>
  <ads-work-order-desktop-menu></ads-work-order-desktop-menu>
  <div class="info-container">
    <div class="details-info">
      <ads-panel class="panel">
        <div class="panel-header">
          <strong>Work order details</strong>
        </div>
        <div class="panel-content">
          <div *ngIf="workOrderService.workOrderDetails() as details" class="details">
            <div class="description">
              <p>{{ details.calltype }}</p>
              <p>{{ details.description }}</p>
            </div>
            <ads-detail-row name="Problem code:" [value]="details.problem"></ads-detail-row>
            <ads-detail-row name="Ticket #:" [value]="details.ticketRecId" [valueAction]="workOrderService.openTicketNotes"></ads-detail-row>
            <ads-detail-row name="Customer PO:" [value]="details.customerpo"></ads-detail-row>
            <ads-detail-row name="Opened:" [value]="details.createddatetime | date : 'MM/dd/yyyy - hh:mm a'"></ads-detail-row>
            <ads-detail-row name="On-site by:" [value]="details.onSiteBy | date : 'MM/dd/yyyy - hh:mm a'"></ads-detail-row>
            <ads-detail-row name="Resolve by:" [value]="details.resolveBy | date : 'MM/dd/yyyy - hh:mm a'"></ads-detail-row>
            <ads-detail-row name="Scheduled:" [value]="details.servicedatetime | date : 'MM/dd/yyyy - hh:mm a'"></ads-detail-row>
          </div>
        </div>
      </ads-panel>
      <ads-panel class="panel">
        <div class="panel-header">
          <strong>Customer info</strong>
        </div>
        <div class="panel-content">
          <div *ngIf="workOrderService.workOrderDetails() as details" class="details">
            <ads-detail-row name="Customer:" [value]="details.customer"></ads-detail-row>
            <ads-detail-row name="Search name:" [value]="details.nameAlias"></ads-detail-row>
            <ads-detail-row
              name="Address:"
              [tallRow]="true"
              [value]="details.address + '<br/>' + details.city + ', ' + details.state + ' '+ details.zip"
              [valueAction]="workOrderService.openMap">
            </ads-detail-row>
            <ads-detail-row
              name="Contact Method:"
              [value]="workOrderService.email ? 'Email' : 'Phone'"
            ></ads-detail-row>
            <ads-detail-row
              *ngIf="workOrderService.contactName"
              name="Name:"
              [value]="workOrderService.contactName"
            ></ads-detail-row>
            <ads-detail-row
              *ngIf="workOrderService.email"
              name="Email Address:"
              [value]="workOrderService.email"
              [valueAction]="workOrderService.openEmail"
            ></ads-detail-row>
            <ads-detail-row
            *ngIf="workOrderService.phone"
              name="Phone:"
              [value]="workOrderService.phone"
              [valueAction]="workOrderService.openPhone"
            ></ads-detail-row>
          </div>
        </div>
      </ads-panel>
    </div>
    <ads-panel class="panel">
      <div class="panel-header" >
        <strong>Equipment on-site</strong>
      </div>

      <div *ngIf="workOrderService.workOrderDetails() as details" class="details">
        <div class="search-header">
          <div class="search-box">
            <ads-common-form-input
              label="Search"
              [maxLength]="50"
              [(ngModel)]="workOrderService.searchText"
            ></ads-common-form-input>
            <mat-icon>search</mat-icon>
          </div>
          <button mat-button mat-stroked-button (click)="workOrderService.openEquipmentOnSite()">
            Open list <mat-icon iconPositionEnd>open_in_new</mat-icon>
          </button>
        </div>
        <ads-equipment-table
          [dataSource]="equipment"
          [searchText]="workOrderService.searchText"
          [columnsToShow]="displayedColumnsKeys"
        >
        </ads-equipment-table>
      </div>
    </ads-panel>
  </div>
</div>
