import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { NavigationRoutesEnum } from 'utils';
import { routeMap, RoutesEnum } from './utils/navigation';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { LayoutComponent } from 'shared/layout/layout.component';
import { LayoutNavComponent } from 'shared/layout-nav/layout-nav.component';
import { MobileMoreComponent } from 'shared/layout-nav/mobile-more/mobile-more.component';
import { AtmPmComponent } from 'pages/atm-pm/atm-pm.component';
import { ItmPmComponent } from 'pages/itm-pm/itm-pm.component';
import { TcdTcrPmComponent } from 'pages/tcd-tcr-pm/tcd-tcr-pm.component';
import { SecurityComponent } from 'pages/security/security.component';
import { CcCdPmComponent } from 'pages/cc-cd-pm/cc-cd-pm.component';
import { AtmCleaningPmComponent } from './pages/atm-cleaning-pm/atm-cleaning-pm.component';
import { PlaceHolderComponent } from 'pages/place-holder/place-holder/place-holder.component';
import { SubmitRedirectComponent } from './pages/submit-redirect/submit-redirect.component';
import { ItmCleaningPmComponent } from 'pages/itm-cleaning-pm/itm-cleaning-pm.component';
import { CallControlComponent } from 'pages/call-control/call-control.component';
import { WorkOrderComponent } from 'pages/work-order/work-order.component';
import { RmaRequestComponent } from 'pages/rma-request/rma-request.component';
import { AddPartComponent } from 'pages/rma-request/parts/add-part/add-part.component';
import { CompleteWorkOrderComponent } from 'pages/complete-work-order/complete-work-order.component';
import { ReviewEquipmentComponent } from 'pages/review-equipment/review-equipment.component';
import { ForcibleEntryComponent } from 'pages/forcible-entry/forcible-entry.component';

export const getRouteEnum = (url: string): RoutesEnum => {
  for (const route in routeMap) {
    if (url.startsWith(route)) {
      return routeMap[route];
    }
  }

  return RoutesEnum.Home;
};

export const routesMapADS = (route: string): RoutesEnum => {
  switch (route) {
    case 'Security':
      return RoutesEnum.Security;
    case 'ITM':
      return RoutesEnum.Itm;
    case 'ATM_PM':
      return RoutesEnum.Atm;
    case 'TCR':
      return RoutesEnum.TcdTcr;
    case 'CurrencyCounter':
      return RoutesEnum.CcCd;
    case 'ATM_Cleaning':
      return RoutesEnum.AtmCleaning;
    case 'ITM_Cleaning':
      return RoutesEnum.ItmCleaning;
    default:
      return RoutesEnum.Submitted;
  }
};

export const routes: Routes = [
  {
    path: '',
    component: LayoutNavComponent,
    canActivate: [MsalGuard],
    children: [
      { path: RoutesEnum.Home, component: HomeComponent, data: { tabIndex: NavigationRoutesEnum.Home } },
      { path: RoutesEnum.More, component: MobileMoreComponent },
      {
        path: RoutesEnum.CallControl,
        component: CallControlComponent,
        data: { tabIndex: NavigationRoutesEnum.CallControl },
      },
      {
        path: RoutesEnum.Calendar,
        component: PlaceHolderComponent,
        data: { tabIndex: NavigationRoutesEnum.Calendar },
      },
      {
        path: RoutesEnum.Lines,
        component: PlaceHolderComponent,
        data: { tabIndex: NavigationRoutesEnum.Lines },
      },
      {
        path: RoutesEnum.Notes,
        component: PlaceHolderComponent,
        data: { tabIndex: NavigationRoutesEnum.Notes },
      },
      {
        path: RoutesEnum.History,
        component: PlaceHolderComponent,
        data: { tabIndex: NavigationRoutesEnum.History },
      },
      {
        path: RoutesEnum.AtmDown,
        component: PlaceHolderComponent,
        data: { tabIndex: NavigationRoutesEnum.AtmDown },
      },
      {
        path: RoutesEnum.Centran,
        component: PlaceHolderComponent,
        data: { tabIndex: NavigationRoutesEnum.Centran },
      },
      {
        path: RoutesEnum.MyOpenCalls,
        component: PlaceHolderComponent,
        data: { tabIndex: NavigationRoutesEnum.MyOpenCalls },
      },
      {
        path: RoutesEnum.PartStatus,
        component: PlaceHolderComponent,
        data: { tabIndex: NavigationRoutesEnum.PartStatus },
      },
      {
        path: RoutesEnum.Inventory,
        component: PlaceHolderComponent,
        data: { tabIndex: NavigationRoutesEnum.Inventory },
      },
      {
        path: RoutesEnum.OtherTechs,
        component: PlaceHolderComponent,
        data: { tabIndex: NavigationRoutesEnum.OtherTechs },
      },
      {
        path: RoutesEnum.partRequest,
        component: PlaceHolderComponent,
        pathMatch: 'full',
      },
      {
        path: RoutesEnum.mobileAcknowledgement,
        component: PlaceHolderComponent,
        pathMatch: 'full',
      },
      {
        path: 'viewArticle',
        component: PlaceHolderComponent,
        pathMatch: 'full',
      },
      {
        path: RoutesEnum.RMARequest,
        component: RmaRequestComponent,
        data: { tabIndex: NavigationRoutesEnum.Home },
      },
      {
        path: `${RoutesEnum.RMARequest}/${RoutesEnum.RMARequestParts}`,
        component: AddPartComponent,
        data: { tabIndex: NavigationRoutesEnum.Home },
      },
      {
        path: `${RoutesEnum.WorkOrder}/:work-order`,
        component: WorkOrderComponent,
        data: { tabIndex: NavigationRoutesEnum.CallControl },
      },
      {
        path: `WO/${RoutesEnum.CompleteWorkOrder}/:work-order`,
        component: CompleteWorkOrderComponent,
        data: { tabIndex: NavigationRoutesEnum.CallControl },
      },
      {
        path: `WO/${RoutesEnum.ReviewEquipment}/:work-order`,
        component: ReviewEquipmentComponent,
        data: { tabIndex: NavigationRoutesEnum.CallControl },
      },
      {
        path: `WO/${RoutesEnum.forcibleEntry}/:work-order`,
        component: ForcibleEntryComponent,
        data: { tabIndex: NavigationRoutesEnum.CallControl },
      },
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    ],
  },
  { path: '404', component: PageNotFoundComponent },
  {
    path: 'PM',
    component: LayoutComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: RoutesEnum.AtmCleaning,
        children: [{ path: ':work-order', component: AtmCleaningPmComponent }],
      },
      {
        path: RoutesEnum.ItmCleaning,
        children: [{ path: ':work-order', component: ItmCleaningPmComponent }],
      },
      {
        path: RoutesEnum.TcdTcr,
        children: [
          {
            path: ':work-order',
            component: TcdTcrPmComponent,
          },
        ],
      },
      {
        path: RoutesEnum.Atm,
        children: [
          {
            path: ':work-order',
            component: AtmPmComponent,
          },
        ],
      },
      {
        path: RoutesEnum.Itm,
        children: [
          {
            path: ':work-order',
            component: ItmPmComponent,
          },
        ],
      },
      {
        path: RoutesEnum.CcCd,
        children: [
          {
            path: ':work-order',
            component: CcCdPmComponent,
          },
        ],
      },
      {
        path: RoutesEnum.Security,
        children: [
          {
            path: ':work-order',
            component: SecurityComponent,
          },
        ],
      },
    ],
  },
  {
    path: RoutesEnum.Submitted,
    canActivate: [MsalGuard],
    children: [
      { path: '', component: SubmitRedirectComponent },
      { path: ':formSubmitted', component: SubmitRedirectComponent },
    ],
  },
  { path: '**', redirectTo: '/dashboard' },
];
