<ads-simple-form #tcdTcrTasks="ngModelGroup" ngModelGroup="tcdTcrTasks">
  <ads-radio-button-group
    [options]="yesNoOptions"
    label="Are configuration sheets present?"
    name="confSheetsPresent"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
<mat-divider></mat-divider>
  <ads-radio-button-group

    [options]="completeNaOptions"
    label="Check error log for abnormalities"
    name="checkError"
    [required]="true"
    ngModel
  ></ads-radio-button-group>

</ads-simple-form>
