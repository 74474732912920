import { AsyncPipe, CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { RoutesEnum } from 'app/utils/navigation';
import { PhotoUploadBase, SelectOption } from 'models';
import { Line } from 'models/rma-request/rma-request';
import { RMARequestService, SearchPartResponse } from 'pages/rma-request/rma-request.service';
import { map, Observable, startWith } from 'rxjs';
import { HeaderService } from 'shared/header/header.service';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';
import { DatePickerComponent } from 'shared/material-wrappers/datepicker/datepicker.component';
import { PhotoUploadComponent } from 'shared/photo-upload/photo-upload.component';

const REASONS: SelectOption<string>[] = [
  { value: '0', name: 'Defective (ATM/ITM)' },
  { value: '1', name: 'Defective (security)' },
  { value: '2', name: 'Not needed (factory sealed)' },
  { value: '3', name: 'Not needed (opened)' },
];

const DISCOVERED_DURING: SelectOption<string>[] = [
  { value: '0', name: 'Install - found during staging' },
  { value: '1', name: 'Install - found during bring-live or install' },
  { value: '2', name: 'Service - found after newly installed machine' },
  { value: '3', name: 'Service - found during normal service call' },
];

@Component({
  selector: 'ads-add-part',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatIconModule,
    CommonModule,
    MatButtonModule,
    AsyncPipe,
    CommonFormInputComponent,
    CommonTextAreaComponent,
    PhotoUploadComponent,
    SelectComponent,
    DatePickerComponent,
  ],
  templateUrl: './add-part.component.html',
  styleUrl: './add-part.component.scss',
})
export class AddPartComponent implements OnInit {
  search = '';
  searchedPartsControl = new FormControl('');
  photosControl = new FormGroup<{ photos: FormControl<string | null> }>({
    photos: new FormControl(''),
  });
  quantity = '';
  problemDescription = '';
  partSerialNumber = '';
  techSupportCaseNumber = '';

  machineSerialNumber = '';
  fromDate?: Date;

  photoUpload: PhotoUploadBase = {
    key: 'photos',
    label: 'Photos',
    required: false,
  };

  unmappedOptions: SearchPartResponse[] = [];
  options: string[] = [];
  filteredOptions: Observable<string[]> | undefined;

  reasonsItems = REASONS;
  selectedReasons: string | null = null;

  discoveredDuringItems = DISCOVERED_DURING;
  selectedDiscoveredDuring: string | null = null;

  selectedPart: number | undefined;

  constructor(
    private readonly rmaService: RMARequestService,
    private readonly headerService: HeaderService,
    private readonly location: Location,
    private readonly router: Router,
    protected readonly layoutNavService: LayoutNavService,
  ) {
    this.headerService.backButtonOverride = (): void => {
      if (window.history.state.navigationId > 1) {
        this.location.back();
      } else {
        this.router.navigate([RoutesEnum.Home]);
      }
    };

    const state = window.history.state;

    if (state?.selectedPart !== undefined) {
      this.selectedPart = state.selectedPart;
      const selectedPart = this.rmaService.parts()[state.selectedPart];
      this.searchedPartsControl.setValue(selectedPart.partNumber!);
      this.quantity = selectedPart.quantity!.toString();
      this.problemDescription = selectedPart.problemDescription!;
      this.selectedReasons = selectedPart.reason ?? '';
      this.partSerialNumber = selectedPart.partSerialNumber ?? '';
      this.techSupportCaseNumber = selectedPart.supportCaseNumber ?? '';
      this.selectedDiscoveredDuring = selectedPart.discovered ?? '';
      this.machineSerialNumber = selectedPart.machineSerialNumber ?? '';
      this.fromDate = selectedPart.installDate ? new Date(selectedPart.installDate) : undefined;

      this.photosControl.controls['photos'].setValue(selectedPart.uploadImage);
    }
  }

  goBack = () => {
    return window.history.state.navigationId > 1 ? this.location.back() : this.router.navigate([RoutesEnum.Home]);
  };

  ngOnInit() {
    this.filteredOptions = this.searchedPartsControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value ?? '')),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }

  onSearch = () => {
    if (!this.search) return;

    this.rmaService.searchParts(this.search).subscribe((parts) => {
      this.unmappedOptions = parts;
      this.options = parts.filter((part) => !!part.ItemIdWithName).map((part) => part.ItemIdWithName ?? '');
      this.searchedPartsControl.setValue('');
    });
  };

  save = () => {
    const part = this.unmappedOptions.find((part) => part.ItemIdWithName === this.searchedPartsControl.value)!;

    let lineToSave: Line = {
      quantity: Number(this.quantity),
      problemDescription: this.problemDescription,
      reason: this.selectedReasons!,
      partSerialNumber: this.partSerialNumber,
      supportCaseNumber: this.techSupportCaseNumber,
      discovered: this.selectedDiscoveredDuring ?? '',
      machineSerialNumber: this.machineSerialNumber,
      installDate: this.fromDate?.toISOString(),
      uploadImage: this.photosControl.controls['photos'].value ?? '',
    };

    if (this.selectedPart !== undefined) {
      const linePart = this.rmaService.parts()[this.selectedPart];
      lineToSave = { ...linePart, ...lineToSave };
      this.rmaService.parts()[this.selectedPart] = lineToSave;
    }

    if (part) {
      lineToSave = {
        ...lineToSave,
        partNumber: part.ItemId!,
        partDescription: part.ItemIdWithName!,
      };
      this.rmaService.parts.set([...this.rmaService.parts(), lineToSave]);
    }

    this.router.navigate([RoutesEnum.RMARequest]);
  };

  deletePart = () => {
    if (this.selectedPart !== undefined) {
      const parts = [
        ...this.rmaService.parts().slice(0, this.selectedPart),
        ...this.rmaService.parts().slice(this.selectedPart + 1),
      ];
      this.rmaService.parts.set(parts);
      this.router.navigate([RoutesEnum.RMARequest]);
    }
  };

  saveEnabled = () => {
    let valid = !!this.quantity && !!this.problemDescription && !!this.searchedPartsControl.value;

    if (valid && this.selectedReasons === '0') {
      valid = valid && !!this.selectedReasons;
    }

    if (valid && this.selectedReasons === '0' && this.selectedDiscoveredDuring !== '3') {
      valid = valid && !!this.machineSerialNumber;
    }

    return valid;
  };
}
