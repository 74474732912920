<mat-toolbar>
  <ads-nav-button
    *ngIf="layoutService.isDesktopView() && !currentContent().backToMenu"
    title="Home"
    icon="home"
    [navIndex]="0"
    [isDesktop]="true"
    [overrideColor]="true"
  ></ads-nav-button>

  <div *ngIf="headerService.title() || currentContent().backToMenu" class="header-title">
    <button mat-icon-button aria-label="close form" *ngIf="doc.opener !== null" (click)="goBack()">
      <mat-icon>close</mat-icon>
    </button>
    <button mat-icon-button aria-label="close form" *ngIf="currentContent().backToMenu" (click)="goHome()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span *ngIf="!layoutService.isDesktopView()">{{
      (currentContent().title ?? headerService.title()) || headerService.welcomeTitle
    }}</span>
    <span
      *ngIf="headerService.titleAnnex()?.text && !layoutService.isDesktopView()"
      class="title-chip"
      [ngClass]="{ black: ['Scheduled', 'Susp-Other'].includes(workOrderService.workOrderDetails().laststatusname ?? '') }"
      [ngStyle]="{
        color: headerService.titleAnnex()?.color,
        backgroundColor: headerService.titleAnnex()?.backgroundColor,
        border: headerService.titleAnnex()?.border
      }"
      >{{ headerService.titleAnnex()?.text }}</span
    >
  </div>

  <button
    *ngIf="!headerService.customMenu && currentContent().showMore"
    [matMenuTriggerFor]="menu"
    mat-icon-button
    aria-label="more options"
  >
    <mat-icon>more_vert</mat-icon>
  </button>

  <div *ngIf="headerService.customMenu && currentContent().showMore" class="custom-menu-wrapper">
    <button *ngIf="!layoutService.isDesktopView()" mat-icon-button aria-label="more options" (click)="toggleCustomMenu()">
      <div [matBadge]="headerService.showMenuHighlight ? '!' : null">
        <mat-icon>more_vert</mat-icon>
      </div>
    </button>

    <div *ngIf="headerService.showCustomMenu" class="custom-menu">
      <ng-container [ngTemplateOutlet]="headerService.customMenu"></ng-container>
    </div>
  </div>
  <button
    *ngIf="headerService.customMenu && headerService.showCustomMenu"
    class="custom-menu-overlay"
    (click)="headerService.showCustomMenu = false"
  > </button>

  <div class="right-buttons" *ngIf="currentContent().showThemeSwitch || currentContent().showNotifications">
    <button
      mat-button
      mat-raised-button
      class="theme-button"
      (click)="onSwitchThemeClick()"
      *ngIf="currentContent().showThemeSwitch"
    >
      Change to
      <mat-icon class="material-icons-outlined" iconPositionEnd>{{ themeName }}_mode</mat-icon>
    </button>

    <button
      mat-icon-button
      class="notifications-button"
      [matMenuTriggerFor]="notificationsMenu"
      *ngIf="currentContent().showNotifications"
    >
      <div [matBadge]="unreadAlerts.length || null" matBadgeSize="medium">
        <mat-icon class="material-icons-outlined">notifications</mat-icon>
      </div>

      <mat-menu #notificationsMenu="matMenu" yPosition="below">
        <ng-container *ngFor="let alert of alerts">
          <mat-expansion-panel
            hideToggle
            (click)="readNotification($event, alert.alertId)"
            style="box-shadow: unset; border-radius: 0; margin-bottom: 5px; margin-top: 5px"
            (keyup)="onKeyPress($event, alert.alertId)"
          >
            <mat-expansion-panel-header [class.unread]="unreadAlerts.includes(alert.alertId)">
              <mat-panel-title> {{ alert.alertSubject }} </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="alert-content">
              <p>{{ alert.alertText }}</p>
              <button mat-icon-button (click)="deleteNotification($event, alert.alertId)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-expansion-panel>
        </ng-container>
        <ng-container *ngIf="!alerts || alerts?.length === 0">
          <mat-panel-title style="padding: 5px"> No notifications </mat-panel-title>
        </ng-container>
      </mat-menu>
    </button>
  </div>
</mat-toolbar>
<mat-progress-bar *ngIf="isLoading | async" mode="indeterminate" value="40" class="progress-bar"></mat-progress-bar>
<div class="overlay-loading" *ngIf="isLoading | async"></div>
<ads-sticky-header></ads-sticky-header>

<mat-menu #menu="matMenu">
  <button class="menu-button" mat-menu-item (click)="onResetFormClick()" *ngIf="showResetFormButton()">
    Reset Form
  </button>
  <button class="menu-button" mat-menu-item (click)="expandCollapseAll()">
    {{ headerService.expanded ? 'Collapse' : 'Expand' }} All
  </button>
  <button class="menu-button" mat-menu-item (click)="onSwitchThemeClick()">
    <mat-icon class="material-icons-outlined">{{ themeName }}_mode</mat-icon>
    {{ themeName | capitalize }}
  </button>
  <button
    *ngFor="let button of headerService.contextActions"
    class="menu-button"
    mat-menu-item
    (click)="button.action()"
  >
    {{ button.label }}
  </button>
</mat-menu>
