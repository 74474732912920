<div class="container">
  <div *ngIf="workOrder">
    <ads-work-order-mobile
      [workOrderDetails]="workOrderDetails()"
      [equipmentOnSite]="equipmentOnSite"
      [searchText]="searchText"
      [openPhone]="openPhone"
      [openMap]="openMap"
      [openEquipmentOnSite]="openEquipmentOnSite"
      [workOrder]="workOrder"
      [openTicketNotes]="openTicketNotes"
      *ngIf="!layoutNavService.isDesktopView()"
    ></ads-work-order-mobile>
    <ads-work-order-desktop
      [workOrderDetails]="workOrderDetails()"
      [equipmentOnSite]="equipmentOnSite"
      [searchText]="searchText"
      [openPhone]="openPhone"
      [openMap]="openMap"
      [openEquipmentOnSite]="openEquipmentOnSite"
      [workOrder]="workOrder"
      [openTicketNotes]="openTicketNotes"
      *ngIf="layoutNavService.isDesktopView()"
    ></ads-work-order-desktop>
  </div>
</div>
