import { ComboLock, ComboLockSection } from './combo-lock';
import { TimeClock, TimeClockSection } from './time-clock';

export const mapComboLocks = (list: ComboLockSection[]): ComboLock[] =>
  list.map((item) => ({
    comboLockChangedDetail: item.comboLockChangedDetail,
    comboLockManufacturer: item.comboLockManufacturer,
    comboLockModel: item.comboLockModel,
    comboLockType: item.comboLockType,
    isComboLockChanged: item.isComboLockChanged,
    isThereAKeyLockingDial: item.isThereAKeyLockingDial,
  }));

export const mapTimeClocks = (list: TimeClockSection[]): TimeClock[] =>
  list.map((item) => ({
    isLinkageConnectedDirectlyToCombolock: item.isLinkageConnectedDirectlyToCombolock,
    quantityOfMovements: item.quantityOfMovements,
    timeClockManufacturer: item.timeClockManufacturer,
    timeClockModel: item.timeClockModel,
    timeClockType: item.timeClockType,
  }));
