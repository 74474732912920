import { CompleteNa } from 'models/shared/complete-na';

export class HubTasks {
  areDevicesZonedOut: CompleteNa | null;
  testHoldUpDevices: CompleteNa | null;

  constructor() {
    this.areDevicesZonedOut = null;
    this.testHoldUpDevices = null;
  }
}
