<div
  *ngIf="stickyHeaderService.enabled"
  class="subheader"
  id="myHeader"
  [class.show]="shouldShow"
  [class.hide]="!shouldShow"
>
  <span>{{ stickyHeaderService.city || '-' }}</span>
  <span>{{ stickyHeaderService.customerName || '-' }}</span>
</div>
