<ads-order-info [order]="workOrder" [customerName]="customerName"></ads-order-info>

<form #securityForm="ngForm" (ngSubmit)="onSubmit()">
  <ads-simple-form
    #applyCSGProtectionDecalInBottomRightCorner="ngModelGroup"
    [ngModelGroup]="'applyCSGProtectionDecalInBottomRightCorner'"
  >
    <ads-radio-button-group
      [options]="options"
      label="Apply CSG protection decal to covered equipment"
      name="applyCSGProtectionDecalInBottomRightCorner"
      [required]="true"
      ngModel
      [disabled]="getIsFormSubmitted()"
    ></ads-radio-button-group>
  </ads-simple-form>

  <div class="top-radio"></div>

  <ads-expansion-panel #selectedEquipmentSectionPanel [hideStatusIcon]="true" title="Equipment types">
    <ads-security-selected-equipment
      [section]="getSection('selectedEquipment')"
      [groupName]="'selectedEquipment'"
      [submitted]="getIsFormSubmitted()"
    ></ads-security-selected-equipment>
  </ads-expansion-panel>

  <mat-tab-group
    animationDuration="0"
    style="padding-bottom: 20px"
    *ngIf="showTabGroup()"
    disableRipple
    disablePagination
    #tabGroup
    (selectedTabChange)="onTabChanged($event)"
    [(selectedIndex)]="selectedIndex"
  >
    <mat-tab label="Alarm" *ngIf="activeEquipment['Alarm']">
      <ng-template mat-tab-label *ngIf="getTabStatus('alarm') !== ''">
        <span
          matBadge="&#8288;"
          [class.badge-success]="getTabStatus('alarm') === 'valid'"
          matBadgeSize="small"
          matBadgeOverlap="false"
          >Alarm</span
        >
      </ng-template>
      <ads-alarm [formGroup]="getFm('alarm')" [submitted]="getIsFormSubmitted()"> </ads-alarm>
    </mat-tab>
    <mat-tab label="Surveillance" *ngIf="activeEquipment['Surveillance']">
      <ng-template mat-tab-label *ngIf="getTabStatus('surveillance') !== ''">
        <span
          matBadge="&#8288;"
          [class.badge-success]="getTabStatus('surveillance') === 'valid'"
          matBadgeSize="small"
          matBadgeOverlap="false"
          >Surveillance</span
        >
      </ng-template>
      <ads-surveillance
        [formGroup]="getFm('surveillance')"
        [submitted]="getIsFormSubmitted()"
        [surveillanceDropdowns]="securityDropdowns.surveillance"
      >
      </ads-surveillance>
    </mat-tab>
    <mat-tab [label]="'Vault'" *ngIf="activeEquipment['Vault']">
      <ng-template mat-tab-label *ngIf="getTabStatus('vault') !== ''">
        <span
          matBadge="&#8288;"
          [class.badge-success]="getTabStatus('vault') === 'valid'"
          matBadgeSize="small"
          matBadgeOverlap="false"
          >Vault</span
        >
      </ng-template>
      <ads-vault [formGroup]="getFm('vault')" [submitted]="getIsFormSubmitted()"> </ads-vault>
    </mat-tab>
    <mat-tab [label]="'Safes'" *ngIf="activeEquipment['Safes']">
      <ng-template mat-tab-label *ngIf="getTabStatus('safes') !== ''">
        <span
          matBadge="&#8288;"
          [class.badge-success]="getTabStatus('safes') === 'valid'"
          matBadgeSize="small"
          matBadgeOverlap="false"
          >Safes</span
        >
      </ng-template>
      <ads-safes [formGroup]="getFm('safes')" [submitted]="getIsFormSubmitted()" [dropdowns]="securityDropdowns.safes">
      </ads-safes>
    </mat-tab>
    <mat-tab [label]="'UCS'" *ngIf="activeEquipment['UCS']">
      <ng-template mat-tab-label *ngIf="getTabStatus('ucs') !== ''">
        <span
          matBadge="&#8288;"
          [class.badge-success]="getTabStatus('ucs') === 'valid'"
          matBadgeSize="small"
          matBadgeOverlap="false"
          >UCS</span
        >
      </ng-template>
      <ads-ucs
        [formGroup]="getFm('ucs')"
        [submitted]="getIsFormSubmitted()"
        [ucsDropdowns]="securityDropdowns.ucs"
      ></ads-ucs>
    </mat-tab>
    <mat-tab [label]="'Night Drop'" *ngIf="activeEquipment['Night Drop']">
      <ng-template mat-tab-label *ngIf="getTabStatus('nightDrop') !== ''">
        <span
          matBadge="&#8288;"
          [class.badge-success]="getTabStatus('nightDrop') === 'valid'"
          matBadgeSize="small"
          matBadgeOverlap="false"
          >Night Drop</span
        >
      </ng-template>
      <ads-night-drop
        [formGroup]="getFm('nightDrop')"
        [submitted]="getIsFormSubmitted()"
        [dropdowns]="securityDropdowns.nightDrop"
      >
      </ads-night-drop>
    </mat-tab>
    <mat-tab [label]="'Access Control Hardware'" *ngIf="activeEquipment['Access Control Hardware']">
      <ng-template mat-tab-label *ngIf="getTabStatus('accessHardware') !== ''">
        <span
          matBadge="&#8288;"
          [class.badge-success]="getTabStatus('accessHardware') === 'valid'"
          matBadgeSize="small"
          matBadgeOverlap="false"
          >Access Control Hardware</span
        >
      </ng-template>
      <ads-access-hardware
        [formGroup]="getFm('accessHardware')"
        [submitted]="getIsFormSubmitted()"
        [achDropdowns]="securityDropdowns.accessControlHardware"
      >
      </ads-access-hardware>
    </mat-tab>
    <mat-tab [label]="'Deal Drawer'" *ngIf="activeEquipment['Deal Drawer']">
      <ng-template mat-tab-label *ngIf="getTabStatus('dealDrawer') !== ''">
        <span
          matBadge="&#8288;"
          [class.badge-success]="getTabStatus('dealDrawer') === 'valid'"
          matBadgeSize="small"
          matBadgeOverlap="false"
          >Deal Drawer</span
        >
      </ng-template>
      <ads-deal-drawer [formGroup]="getFm('dealDrawer')" [submitted]="getIsFormSubmitted()"> </ads-deal-drawer>
    </mat-tab>
    <mat-tab [label]="'RTS'" *ngIf="activeEquipment['RTS']">
      <ng-template mat-tab-label *ngIf="getTabStatus('rts') !== ''">
        <span
          matBadge="&#8288;"
          [class.badge-success]="getTabStatus('rts') === 'valid'"
          matBadgeSize="small"
          matBadgeOverlap="false"
          >RTS</span
        >
      </ng-template>
      <ads-rts [formGroup]="getFm('rts')" [submitted]="getIsFormSubmitted()"> </ads-rts>
    </mat-tab>
    <mat-tab [label]="'Audio/Video DU'" *ngIf="activeEquipment['Audio/Video DU']">
      <ng-template mat-tab-label *ngIf="getTabStatus('audioVideo') !== ''">
        <span
          matBadge="&#8288;"
          [class.badge-success]="getTabStatus('audioVideo') === 'valid'"
          matBadgeSize="small"
          matBadgeOverlap="false"
          >Audio/Video DU</span
        >
      </ng-template>
      <ads-audio-video [formGroup]="getFm('audioVideo')" [submitted]="getIsFormSubmitted()"> </ads-audio-video>
    </mat-tab>
  </mat-tab-group>

  <div class="form-buttons" *ngIf="!getIsFormSubmitted()">
    <button mat-button mat-stroked-button (click)="saveForm()" type="button">Save</button>
    <button mat-button mat-flat-button class="main-button" [disabled]="form?.form?.disabled">Submit</button>
  </div>
</form>

<div class="clear"></div>
