<mat-form-field class="common-form-field" appearance="outline" hideRequiredMarker>
  <mat-label>{{ label }}</mat-label>
  <input
    #customInput="ngModel"
    [required]="required"
    [disabled]="disabled"
    [(ngModel)]="value"
    (ngModelChange)="onModelChange($event)"
    [name]="name"
    matInput
    [matDatepicker]="picker"
    (blur)="markAsTouched()"
    [matDatepickerFilter]="previousDaysFilter"
    (focus)="picker.open()"
    [min]="minDate ?? null"
    [max]="maxDate ?? null"
  />
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <ads-custom-tooltip *ngIf="tooltip" [tooltip]="tooltip"></ads-custom-tooltip>
  <mat-hint align="start" class="custom-error" *ngIf="touched && required && !value"> This field is required </mat-hint>
</mat-form-field>
