<mat-expansion-panel
  hideToggle
  class="expansion-panel"
  [expanded]="expanded"
  (opened)="expanded = true"
  (closed)="expanded = false"
  (afterExpand)="onExpand()"
  (afterCollapse)="checkIfAllSectionsAreCollapsed()"
>
  <mat-expansion-panel-header class="expansion-panel-header" [class.spaced]="showDeleteButton">
    <div class="expansion-panel-header-content">
      <ng-container *ngIf="!hideStatusIcon">
        <img *ngIf="getFormStatus()(name) === 'pristine'" class="section-icon" src="/assets/icons/pending.svg" alt="Pending Section" />
        <img *ngIf="getFormStatus()(name) === 'valid'" class="section-icon" src="/assets/icons/complete.svg" alt="Completed Section" />
        <img *ngIf="getFormStatus()(name) === 'dirty'" class="section-icon" src="/assets/icons/form-in-progress.svg" alt="Completed Section"
/>
      </ng-container>
      <span class="expansion-panel-header-title" [class.long-title]="title.length > 30">{{ title }}</span>
      <mat-icon *ngIf="!expanded" class="custom-toggle">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="expanded" class="custom-toggle">arrow_drop_up</mat-icon>
    </div>
  </mat-expansion-panel-header>
  <ng-content></ng-content>
</mat-expansion-panel>
