import { UCSFormSection } from 'forms/security/ucs/ucs.component';
import { UCSListDto } from 'models/api/security/security';
import { SecurityUCSListUCSDto } from 'models/api/security/ucs/ucs-list-dto';
import { UCSSecurityForm } from 'models/security';

export const mapUCSSections = (panels: SecurityUCSListUCSDto[]): UCSFormSection[] => {
  if (!panels || panels.length === 0)
    return [
      {
        ucsModel: null,
        ucsManufacturer: null,
        ucsManufacturer_Other: null,
        overallConditionUCS: null,
        overallConditionUCS_Other: null,
        quantityComboLocks: null,
        quantityOfPortaLocks: null,
        index: 1,
      },
    ];

  return panels.map((panel, idx) => ({
    ucsModel: panel.ucsModel,
    ucsManufacturer: panel.ucsManufacturer,
    ucsManufacturer_Other: panel.ucsManufacturer_Other,
    overallConditionUCS: panel.overallConditionUCS,
    overallConditionUCS_Other: panel.overallConditionUCS_Other,
    quantityComboLocks: panel.quantityComboLocks,
    quantityOfPortaLocks: panel.quantityOfPortaLocks,
    index: idx + 1,
  }));
};

export const mapSecurityUCS = (ucsList: UCSListDto): UCSSecurityForm => {
  return {
    ucsList: mapUCSSections(ucsList.security_Ucs_Ucs),
    ucsTasks: ucsList.security_Ucs_Ucs_Tasks[0],
    comments: ucsList.additionalComments ?? null,
  };
};
