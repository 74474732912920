import { AtmDropdowns, AtmForm } from '../../../atm';
import { AtmResponse, AtmResponseDropdowns } from '../../atm/atm.response';

export const mapAtmForm = (response: AtmResponse): AtmForm => {
  const data = response.atm;
  return {
    software: {
      onsite: data.software_Onsite,
      operatingSystem: data.software_OS,
      operatingSystemOther: data.software_OS_Other,
      softwareVersion: data.software_Version,
      ncrcd2: data.software_NCR_CD2,
    },
    atmSpecs: {
      manufacturer: data.atmSpecs_Manufacturer,
      otherAtmManufacturer: data.atmSpecs_Manufacturer_Other,
      serialNumber: data.atmSpecs_Serial,
      depositorType: data.atmSpecs_DepositorType,
      kabaSerialNumber: data.atmSpecs_KabaSerialNumber,
      model: data.atmSpecs_Model,
      communicationType: data.atmSpecs_CommType,
      communicationTypeOther: data.atmSpecs_CommType_Other,
      terminalId: data.atmSpecs_TerminalId,
      overallCondition: data.atmSpecs_OverallCondition,
      equipmentRating: data.atmSpecs_Equipment_Rating,
    },
    atmTasks: {
      confSheetsPresent: data.atmTasks_ConfSheetsPresent,
      logSheetPresent: data.atmTasks_LogSheetPresent,
      verifySiteProfileSP: data.atmTasks_VerifySiteProfileSP,
      talliesAbnormalities: data.atmTasks_TalliesAbnormalities,
      clearTallies: data.atmTasks_ClearTallies,
      requestKabaCombo: data.atmTasks_RequestKabaCombo,
    },
    cardReaderTasks: {
      allNa: false,
      cleanPrehead: data.cardReaderTasks_CleanPrehead,
      cleanAllRollers: data.cardReaderTasks_CleanAllRollers,
      cleanAllBelts: data.cardReaderTasks_CleanAllBelts,
      cleanReadHead: data.cardReaderTasks_CleanReadHead,
      cleanFeltCard: data.cardReaderTasks_CleanFeltCard,
      blowSensorAreas: data.cardReaderTasks_BlowSensorAreas,
      diagnosticTest: data.cardReaderTasks_DiagnosticTest,
      evmCapable: data.cardReaderTasks_EvmCapable,
    },
    envDepDispTasks: {
      allNa: false,
      cleanAllSensors: data.envDepDispTasks_CleanAllSensors,
      cleanAllBelts: data.envDepDispTasks_CleanAllBelts,
      cleanShutter: data.envDepDispTasks_CleanShutter,
      cleanInkArea: data.envDepDispTasks_CleanInkArea,
      cleanInkPad: data.envDepDispTasks_CleanInkPad,
      replaceCartridgeRibbon: data.envDepDispTasks_ReplaceCartridgeRibbon,
      replenishEnvelops: data.envDepDispTasks_ReplenishEnvelops,
      diagnosticTests: data.envDepDispTasks_DiagnosticTests,
    },
    receiptPrinterTasks: {
      allNa: false,
      removeAllPaperDust: data.receiptPrinterTasks_RemoveAllPaperDust,
      openCleanEngine: data.receiptPrinterTasks_OpenCleanEngine,
      cleanAllBelts: data.receiptPrinterTasks_CleanAllBelts,
      cleanAllRollers: data.receiptPrinterTasks_CleanAllRollers,
      blowSensorAreas: data.receiptPrinterTasks_BlowSensorAreas,
      blowCutterArea: data.receiptPrinterTasks_BlowCutterArea,
      diagnostic: data.receiptPrinterTasks_Diagnostic,
    },
    cashDispenserTasks: {
      allNa: false,
      cleanSensors: data.cashDispenserTasks_CleanSensors,
      removeForeignMaterial: data.cashDispenserTasks_RemoveForeignMaterial,
      cleanRollers: data.cashDispenserTasks_CleanRollers,
      cleanBelts: data.cashDispenserTasks_CleanBelts,
      cleanShutter: data.cashDispenserTasks_CleanShutter,
      replacePickupFeedRollers: data.cashDispenserTasks_ReplacePickupFeedRollers,
      checkInterlockSwitch: data.cashDispenserTasks_CheckInterlockSwitch,
      diagnostic: data.cashDispenserTasks_Diagnostic,
    },
    fasciaAdaTasks: {
      cleanCustomerInterfaceArea: data.fascia_CleanCustomerInterfaceArea,
      adaStickersPresentReadable: data.fascia_AdaStickersPresentReadable,
      cleanToneVolume: data.fascia_CleanToneVolume,
      testVoiceGuidance: data.fascia_TestVoiceGuidance,
      testKeypadFunctionKeys: data.fascia_TestKeypadFunctionKeys,
      checkAlignCardReader: data.fascia_CheckAlignCardReader,
      checkAlignOperDispShutter: data.fascia_CheckAlignOperDispShutter,
      checkAlignReceiptPrinter: data.fascia_CheckAlignReceiptPrinter,
      cleanOperatorPanel: data.fascia_CleanOperatorPanel,
      cleanEntireExterior: data.fascia_CleanEntireExterior,
      verifyTouchScreen: data.fascia_VerifyTouchScreen,
      testOpenCloseIndicator: data.fascia_TestOpenCloseIndicator,
      replaceLightBulbs: data.fascia_ReplaceLightBulbs,
      antiSkimmingInDeviceConfigMode: data.fascia_AntiSkimmingInDeviceConfigMode,
      topHatLightOperational: data.topHatLightOperational,
    },
    cashDepositoryTasks: {
      allNa: false,
      cleanSensors: data.cashDepositoryTasks_CleanSensors,
      cleanBillScannerLenses: data.cashDepositoryTasks_CleanBillScannerLenses,
      cleanRollers: data.cashDepositoryTasks_CleanRollers,
      cleanBelts: data.cashDepositoryTasks_CleanBelts,
      cleanBins: data.cashDepositoryTasks_CleanBins,
      verifyInfeedAlignment: data.cashDepositoryTasks_VerifyInfeedAlignment,
      diagnostic: data.cashDepositoryTasks_Diagnostic,
    },
    checkDepositoryTasks: {
      allNa: false,
      cleanSensors: data.checkDepository_CleanSensors,
      cleanRollers: data.checkDepository_CleanRollers,
      cleanBelts: data.checkDepository_CleanBelts,
      cleanInkArea: data.checkDepository_CleanInkArea,
      checkScannerLenses: data.checkDepository_CheckScannerLenses,
      checkPrinterQuality: data.checkDepository_CheckPrinterQuality,
      cleanAllBins: data.checkDepository_CleanAllBins,
      verifyInfeedAlignment: data.checkDepository_VerifyInfeedAlignment,
      diagnostic: data.checkDepository_Diagnostic,
    },
    vacuumBlowOutTasks: {
      allNa: false,
      safe: data.vaccumBlowOut_Safe,
      topHalf: data.vaccumBlowOut_TopHalf,
      dispenser: data.vaccumBlowOut_Dispenser,
      depositories: data.vaccumBlowOut_Depositories,
      receiptPrinter: data.vaccumBlowOut_ReceiptPrinter,
      allFans: data.vaccumBlowOut_AllFans,
      allFilters: data.vaccumBlowOut_AllFilters,
      powerSupply: data.vaccumBlowOut_PowerSupply,
      safeHeaters: data.vaccumBlowOut_SafeHeaters,
    },
    comments: data.comments,
  };
};

export const mapAtmDropdowns = (data: AtmResponseDropdowns): AtmDropdowns => {
  return {
    specsManufacturerList: data.atmSpecsManufactures.map((item) => ({
      value: item.manufacturerName,
      name: item.manufacturerName,
    })),
    specsDepositorsList: data.atmSpecsDepositors.map((item) => ({
      value: item.depositorTypeCode,
      name: item.depositorTypeName,
    })),
    specsOverallConditionsList: data.atmSpecsOverallConditions.map((item) => ({
      value: item.name,
      name: item.name,
    })),
    communicationTypesList: data.communicationTypes.map((item) => ({
      value: item.name,
      name: item.name,
    })),
    operatingSystemsList: data.operatingSystems.map((item) => ({
      value: item.name,
      name: item.name,
    })),
  };
};
