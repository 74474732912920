export enum WorkStatus {
  New = 0,
  Draft = 1,
  Submitted = 2,
}

export interface WorkOrderDetailDto {
  id: number | null;
  createdOn: string | null;
  updatedOn: string | null;
  performedBy: number | null;
  workOrder: string | null;
  terminalId: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  branchPhoneNumber: string | null;
  formType: number | null;
  customerId: string | null;
  workStatus: WorkStatus | null;
}
