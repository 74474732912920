import { AdsItmRequest, ItmRequest } from '../../itm/ads-itm-request';
import {
  ItmCashCheckDepositoryTasks,
  ItmCashDispenserTasks,
  ItmDto,
  ItmFasciaAndAdaTasks,
  ItmPeripheralTasks,
  ItmPhotos,
  ItmReceiptPrinterTasks,
  ItmSoftware,
  ItmSpecs,
  ItmTasks,
  ItmVacuumingBlowOut,
} from '../../itm/itm';

export const itmMapRequest = ({
  fieldsForm,
  photosForm,
  comments,
  workOrderDetail,
  pageSubmitMode,
  pageOpenMode,
}: ItmRequest): AdsItmRequest => {
  const itmSpecs: ItmSpecs = {
    itmSpecs_ItmManufacturer: fieldsForm.itmSpecs.manufacturer,
    itmSpecs_ItmModel: fieldsForm.itmSpecs.model,
    itmSpecs_ItmSerialNumber: fieldsForm.itmSpecs.serialNumber,
    itmSpecs_CommType: fieldsForm.itmSpecs.communicationType,
    itmSpecs_DepositorType: fieldsForm.itmSpecs.depositorType,
    itmSpecs_ItmTerminalId: fieldsForm.itmSpecs.terminalId,
    itmSpecs_ItmNetworkTerminalId: fieldsForm.itmSpecs.networkTerminalId,
    itmSpecs_OverallCondition: fieldsForm.itmSpecs.overallCondition,
    itmSpecs_KabaSerialNumber: fieldsForm.itmSpecs.kabaSerialNumber,
    itmSpecs_Equipment_Rating: fieldsForm.itmSpecs.equipmentRating,
  };

  const software: ItmSoftware = {
    software_SoftwareOnsite: fieldsForm.software.onsite,
    software_Version: fieldsForm.software.softwareVersion,
    software_OS: fieldsForm.software.operatingSystem,
  };

  const tasks: ItmTasks = {
    itmTasks_AreConfigSheetsPresent: fieldsForm.itmTasks.areConfigSheetsPresent,
    itmTasks_IsMaintainanceLogSheetPresent: fieldsForm.itmTasks.isMaintainanceLogSheetPresent,
    itmTasks_VerifySiteProfileOnSharepoint: fieldsForm.itmTasks.verifySiteProfileOnSharepoint,
    itmTasks_CheckErrorLog: fieldsForm.itmTasks.checkErrorLog,
    itmTasks_ClearTallies: fieldsForm.itmTasks.clearTallies,
    itmTasks_RequestKabaCombo: fieldsForm.itmTasks.requestKabaCombo,
  };

  const peripheralTasks: ItmPeripheralTasks = {
    peripheralTasks_CleanPreHead: fieldsForm.itmPeripheralTasks.cleanPreHead,
    peripheralTasks_CleanAllBelts: fieldsForm.itmPeripheralTasks.cleanAllBelts,
    peripheralTasks_CleanReadHead: fieldsForm.itmPeripheralTasks.cleanReadHead,
    peripheralTasks_CleanWithFeltCard: fieldsForm.itmPeripheralTasks.cleanWithFeltCard,
    peripheralTasks_BlowOutSenserAreas: fieldsForm.itmPeripheralTasks.blowOutSensorAreas,
    peripheralTasks_PerformDiagnosticTest: fieldsForm.itmPeripheralTasks.performDiagnosticTest,
    peripheralTasks_IsCardReaderEmvCapable: fieldsForm.itmPeripheralTasks.isCardReaderEmvCapable,
    peripheralTasks_ConnectToTeller: fieldsForm.itmPeripheralTasks.connectToTeller,
    peripheralTasks_TestAudioVideo: fieldsForm.itmPeripheralTasks.testAudioVideo,
    peripheralTasks_TestSignaturePad: fieldsForm.itmPeripheralTasks.testSignaturePad,
    peripheralTasks_TestPhone: fieldsForm.itmPeripheralTasks.testPhone,
    peripheralTasks_TestScanner: fieldsForm.itmPeripheralTasks.testScanner,
    peripheralTasks_TestCoinDispenser: fieldsForm.itmPeripheralTasks.testCoinDispenser,
    peripheralTasks_TestVideoFromTeller: fieldsForm.itmPeripheralTasks.testVideoFromTeller,
  };

  const receiptPrinterTasks: ItmReceiptPrinterTasks = {
    receiptPrinterTasks_RemoveAllPaperDust: fieldsForm.itmReceiptPrinterTasks.removeAllPaperDust,
    receiptPrinterTasks_OpenAndCleanEngine: fieldsForm.itmReceiptPrinterTasks.openAndCleanEngine,
    receiptPrinterTasks_CheckAndCleanAllBelts: fieldsForm.itmReceiptPrinterTasks.checkAndCleanAllBelts,
    receiptPrinterTasks_CheckAndCleanAllRollers: fieldsForm.itmReceiptPrinterTasks.checkAndCleanAllRollers,
    receiptPrinterTasks_BlowOutSensorAreas: fieldsForm.itmReceiptPrinterTasks.blowOutSensorAreas,
    receiptPrinterTasks_BlowOutCutterArea: fieldsForm.itmReceiptPrinterTasks.blowOutCutterArea,
    receiptPrinterTasks_PerformDiagnosticTest: fieldsForm.itmReceiptPrinterTasks.performDiagnosticTest,
  };

  const cashDispenserTasks: ItmCashDispenserTasks = {
    cashDispenserTasks_CheckAndCleanAllSensors: fieldsForm.itmCashDispenserTasks.checkAndCleanAllSensors,
    cashDispenserTasks_RemoveAnyForeignMaterialFromDispenser: fieldsForm.itmCashDispenserTasks.removeAnyForeignMaterialFromDispenser,
    cashDispenserTasks_CheckAndCleanAllRollers: fieldsForm.itmCashDispenserTasks.checkAndCleanAllRollers,
    cashDispenserTasks_CheckAndCleanAllBelts: fieldsForm.itmCashDispenserTasks.checkAndCleanAllBelts,
    cashDispenserTasks_CheckAndCleanShutter: fieldsForm.itmCashDispenserTasks.checkAndCleanShutter,
    cashDispenserTasks_ReplacePickCupsOrFeedRollers: fieldsForm.itmCashDispenserTasks.replacePickCupsOrFeedRollers,
    cashDispenserTasks_CheckInterlockSwitch: fieldsForm.itmCashDispenserTasks.checkInterlockSwitch,
    cashDispenserTasks_PerformDiagnosticTest: fieldsForm.itmCashDispenserTasks.performDiagnosticTest,
  };

  const fasciaAndAdaTasks: ItmFasciaAndAdaTasks = {
    fasciaAndAdaTasks_CleanCustomerInterfaceArea: fieldsForm.itmFasciaAndAdaTasks.cleanCustomerInterfaceArea,
    fasciaAndAdaTasks_AreAdaStickersPresentAndReadable: fieldsForm.itmFasciaAndAdaTasks.areAdaStickersPresentAndReadable,
    fasciaAndAdaTasks_CheckToneVolumeAndAdjust: fieldsForm.itmFasciaAndAdaTasks.checkToneVolumeAndAdjust,
    fasciaAndAdaTasks_TestVoiceGuidance: fieldsForm.itmFasciaAndAdaTasks.testVoiceGuidance,
    fasciaAndAdaTasks_TestAllKeypadAndFunctionKeys: fieldsForm.itmFasciaAndAdaTasks.testAllKeypadAndFunctionKeys,
    fasciaAndAdaTasks_CheckAlignWithCardReader: fieldsForm.itmFasciaAndAdaTasks.checkAlignWithCardReader,
    fasciaAndAdaTasks_CheckAlignAndOperationOfDispensorShutter: fieldsForm.itmFasciaAndAdaTasks.checkAlignAndOperationOfDispensorShutter,
    fasciaAndAdaTasks_CheckAlignWithReceiptPrinter: fieldsForm.itmFasciaAndAdaTasks.checkAlignWithReceiptPrinter,
    fasciaAndAdaTasks_CleanOperatorPanel: fieldsForm.itmFasciaAndAdaTasks.cleanOperatorPanel,
    fasciaAndAdaTasks_CleanEntireExteriorOfStandaloneAtms: fieldsForm.itmFasciaAndAdaTasks.cleanEntireExteriorOfStandaloneAtms,
    fasciaAndAdaTasks_VerifyTouchScreenCalibration: fieldsForm.itmFasciaAndAdaTasks.verifyTouchScreenCalibration,
    fasciaAndAdaTasks_TestOpenCloseIndicator: fieldsForm.itmFasciaAndAdaTasks.testOpenCloseIndicator,
    fasciaAndAdaTasks_ReplaceLightBulbs: fieldsForm.itmFasciaAndAdaTasks.replaceLightBulbs,
    fasciaAndAdaTasks_WipeDownAllAtmDevices: fieldsForm.itmFasciaAndAdaTasks.wipeDownAllAtmDevices,
    fasciaAndAdaTasks_AntiSkimmingInDeviceConfigMode: fieldsForm.itmFasciaAndAdaTasks.antiSkimmingInDeviceConfigMode,
    topHatLightOperational: fieldsForm.itmFasciaAndAdaTasks.topHatLightOperational,
  };

  const cashCheckDepositoryTasks: ItmCashCheckDepositoryTasks = {
    cashCheckDepositoryTasks_CleanAllSensors: fieldsForm.itmCashCheckDepositoryTasks.cleanAllSensors,
    cashCheckDepositoryTasks_CheckAndCleanAllRollers: fieldsForm.itmCashCheckDepositoryTasks.checkAndCleanAllRollers,
    cashCheckDepositoryTasks_CheckAndCleanAllBelts: fieldsForm.itmCashCheckDepositoryTasks.checkAndCleanAllBelts,
    cashCheckDepositoryTasks_CleanInkArea: fieldsForm.itmCashCheckDepositoryTasks.cleanInkArea,
    cashCheckDepositoryTasks_CleanCheckScannerLenses: fieldsForm.itmCashCheckDepositoryTasks.cleanCheckScannerLenses,
    cashCheckDepositoryTasks_CheckPrintQuality: fieldsForm.itmCashCheckDepositoryTasks.checkPrintQuality,
    cashCheckDepositoryTasks_CheckAndCleanAllBins: fieldsForm.itmCashCheckDepositoryTasks.checkAndCleanAllBins,
    cashCheckDepositoryTasks_VerifyInfeedAlignmentToFascia: fieldsForm.itmCashCheckDepositoryTasks.verifyInfeedAlignmentToFascia,
    cashCheckDepositoryTasks_PerformDiagnosticTest:fieldsForm.itmCashCheckDepositoryTasks.performDiagnosticTest,
    cashCheckDepositoryTasks_ReplaceRetardCartridges: fieldsForm.itmCashCheckDepositoryTasks.replaceRetardCartridges,
  };

  const vacuumBlowOutTasks: ItmVacuumingBlowOut = {
    vacummingAndBlowOut_Safe: fieldsForm.itmVacuumingAndBlowOutTasks.safe,
    vacummingAndBlowOut_TopHalf: fieldsForm.itmVacuumingAndBlowOutTasks.topHalf,
    vacummingAndBlowOut_Dispenser: fieldsForm.itmVacuumingAndBlowOutTasks.dispenser,
    vacummingAndBlowOut_Depositories: fieldsForm.itmVacuumingAndBlowOutTasks.depositories,
    vacummingAndBlowOut_ReceiptPrinter: fieldsForm.itmVacuumingAndBlowOutTasks.receiptPrinter,
    vacummingAndBlowOut_AllFans: fieldsForm.itmVacuumingAndBlowOutTasks.allFans,
    vacummingAndBlowOut_AllFilters: fieldsForm.itmVacuumingAndBlowOutTasks.allFilters,
    vacummingAndBlowOut_PowerSupply: fieldsForm.itmVacuumingAndBlowOutTasks.powerSupply,
    vacummingAndBlowOut_SafeHeaters: fieldsForm.itmVacuumingAndBlowOutTasks.safeHeaters,
  };

  const photos: ItmPhotos = {
    photos_FrontDistance: photosForm['itm-front-distance'].value?.split('?sv=')[0] ?? null,
    photos_FrontClose: photosForm['itm-front-close'].value?.split('?sv=')[0] ?? null,
    photos_CardReader: photosForm['itm-card-reader-from-inside-fascia'].value?.split('?sv=')[0] ?? null,
    photos_Depositories: photosForm['itm-depositories'].value?.split('?sv=')[0] ?? null,
    photos_Dispenser: photosForm['itm-dispenser'].value?.split('?sv=')[0] ?? null,
    photos_CustomerMonitor: photosForm['itm-customer-monitor-from-inside-fascia'].value?.split('?sv=')[0] ?? null,
    photos_SerialNumberLabel: photosForm['itm-serial-number-label'].value?.split('?sv=')[0] ?? null,
    photos_Computer: photosForm['itm-computer'].value?.split('?sv=')[0] ?? null,
    photos_CustomerScreen: photosForm['itm-touchscreen-fdks'].value?.split('?sv=')[0] ?? null,
    photos_Printer: photosForm['itm-printer'].value?.split('?sv=')[0] ?? null,
    photos_IdScanner: photosForm['itm-id-scanner'].value?.split('?sv=')[0] ?? null,
    photo_AdditionalPhoto: photosForm['itm-additional-photo'].value?.split('?sv=')[0] ?? null,
    photos_OperatorPanel: photosForm['itm-operator-panel'].value?.split('?sv=')[0] ?? null,
    photos_Software: photosForm['itm-software-display-in-one-shot'].value?.split('?sv=')[0] ?? null,
    photos_CoinDispenser: photosForm['itm-coin-dispenser'].value?.split('?sv=')[0] ?? null,
  };

  const itm: ItmDto = {
    id: workOrderDetail.id,
    updatedOn: new Date().toUTCString(),
    performedBy: workOrderDetail.performedBy,
    workOrderId: workOrderDetail.id,
    ...itmSpecs,
    ...software,
    ...tasks,
    ...peripheralTasks,
    ...receiptPrinterTasks,
    ...cashDispenserTasks,
    ...fasciaAndAdaTasks,
    ...cashCheckDepositoryTasks,
    ...vacuumBlowOutTasks,
    ...photos,
    comments,
  };

  return {
    pageOpenMode,
    pageSubmitMode,
    isEditable: true,
    fromXml: false,
    itm,
    workOrderDetail: workOrderDetail,
  };
};
