import { AdsCcCdRequest, CcCdRequest } from 'models/api/cc-cd/ads-cc-cd-request';
import {
  CcCdDto,
  CcCdExternalAreaTasksDto,
  CcCdFeedModuleTasksDto,
  CcCdPresentModuleTasksDto,
  CcCdSectionDto,
  CcCdTasksDto,
  CcCdVacuumingBlowOutTasksDto,
} from 'models/api/cc-cd/cc-cd';

export const ccCdMapRequest = ({
  photosForm,
  fieldsForm,
  ccCdSections,
  comments,
  workOrderDetail,
  pageSubmitMode,
  pageOpenMode,
}: CcCdRequest): AdsCcCdRequest => {
  const currencyCounterDiscriminatorItems: CcCdSectionDto[] = ccCdSections.map((item, idx) => {
    return {
      currencyCounterDiscriminator_Manufacturer: item.manufacturer,
      currencyCounterDiscriminator_Model: item.model,
      currencyCounterDiscriminator_Serial: item.serial,
      currencyCounterDiscriminator_Type: item.type,
      currencyCounterDiscriminator_QuantityOfPockets: item.quantityOfPockets ? parseInt(item.quantityOfPockets) : null,
      currencyCounterDiscriminator_QuantityOfPrinters: item.quantityOfPrinters
        ? parseInt(item.quantityOfPrinters)
        : null,
      currencyCounterDiscriminator_ManufacturerOfPrinter: item.manufacturerOfPrinter,
      currencyCounterDiscriminator_ExternalEquipmentAttached: item.externalEquipmentAttached,
      currencyCounterDiscriminator_OverallCondition: item.overallCondition,
      equipment_Rating: item.equipmentRating,
      photo_Front: photosForm[idx]?.['photoFront']?.split('?sv=')[0] ?? null,
      photo_SerialNumber: photosForm[idx]?.['photoSerialNumber']?.split('?sv=')[0] ?? null,
      photo_Printer: photosForm[idx]?.['photoPrinter']?.split('?sv=')[0] ?? null,
      photo_ExternalDevices: photosForm[idx]?.['photoExternalDevices']?.split('?sv=')[0] ?? null,
      photo_AdditionalPhoto: photosForm[idx]?.['photoAdditionalPhoto']?.split('?sv=')[0] ?? null,
    };
  });

  const ccTasks: CcCdTasksDto = fieldsForm?.ccCdTasks ? {
    currencyCounterDiscriminatorTasks_ConfSheetsPresent: fieldsForm?.ccCdTasks.confSheetsPresent,
    currencyCounterDiscriminatorTasks_CheckErrorForAbnormalities: fieldsForm?.ccCdTasks.checkErrorForAbnormalities,
  } : {
    currencyCounterDiscriminatorTasks_ConfSheetsPresent: null,
    currencyCounterDiscriminatorTasks_CheckErrorForAbnormalities: null,
  };

  const ccCdFeedModuleTasks: CcCdFeedModuleTasksDto = fieldsForm.ccCdFeedModuleTasks ? {
    feedModuleTasks_CheckCleanSensors: fieldsForm.ccCdFeedModuleTasks.checkCleanSensors,
    feedModuleTasks_RemoveForeignMaterial: fieldsForm.ccCdFeedModuleTasks.removeForeignMaterial,
    feedModuleTasks_CheckCleanRollers: fieldsForm.ccCdFeedModuleTasks.checkCleanRollers,
    feedModuleTasks_CheckCleanBelts: fieldsForm.ccCdFeedModuleTasks.checkCleanBelts,
    feedModuleTasks_PerformDiagnosticTests: fieldsForm.ccCdFeedModuleTasks.performDiagnosticTests,
  } : {
    feedModuleTasks_CheckCleanSensors: null,
    feedModuleTasks_RemoveForeignMaterial: null,
    feedModuleTasks_CheckCleanRollers: null,
    feedModuleTasks_CheckCleanBelts: null,
    feedModuleTasks_PerformDiagnosticTests: null,
  };

  const ccCdPresentModuleTasks: CcCdPresentModuleTasksDto = fieldsForm.ccCdPresentModuleTasks ? {
    presentModuleTasks_CheckCleanSensors: fieldsForm.ccCdPresentModuleTasks.checkCleanSensors,
    presentModuleTasks_RemoveForeignMaterial: fieldsForm.ccCdPresentModuleTasks.removeForeignMaterial,
    presentModuleTasks_CheckCleanRollers: fieldsForm.ccCdPresentModuleTasks.checkCleanRollers,
    presentModuleTasks_CheckCleanBelts: fieldsForm.ccCdPresentModuleTasks.checkCleanBelts,
    presentModuleTasks_PerformDiagnosticTests: fieldsForm.ccCdPresentModuleTasks.performDiagnosticTests,
  } : {
    presentModuleTasks_CheckCleanSensors: null,
    presentModuleTasks_RemoveForeignMaterial: null,
    presentModuleTasks_CheckCleanRollers: null,
    presentModuleTasks_CheckCleanBelts: null,
    presentModuleTasks_PerformDiagnosticTests: null,
  };

  const ccCdExternalAreaTasks: CcCdExternalAreaTasksDto = fieldsForm.ccCdExternalAreaTasks
    ? {
        externalAreaTasks_CleanCustomerInterfaceArea: fieldsForm.ccCdExternalAreaTasks.cleanCustomerInterfaceArea,
        externalAreaTasks_CleanTestScreen: fieldsForm.ccCdExternalAreaTasks.cleanTestScreen,
        externalAreaTasks_CleanEntireExterior: fieldsForm.ccCdExternalAreaTasks.cleanEntireExterior,
        externalAreaTasks_CheckAdjustmentScrewCalibration: fieldsForm.ccCdExternalAreaTasks.checkAdjustmentScrewCalibration,
        externalAreaTasks_VerifyCablesAreNeatOrganizedBehindEquip: fieldsForm.ccCdExternalAreaTasks.verifyCablesAreNeatOrganizedBehindEquip,
      }
    : {
        externalAreaTasks_CleanCustomerInterfaceArea: null,
        externalAreaTasks_CleanTestScreen: null,
        externalAreaTasks_CleanEntireExterior: null,
        externalAreaTasks_CheckAdjustmentScrewCalibration: null,
        externalAreaTasks_VerifyCablesAreNeatOrganizedBehindEquip: null,
      };

  const ccCdVacuumingBlowOutTasks: CcCdVacuumingBlowOutTasksDto = fieldsForm.ccCdVacuumingBlowOutTasks ? {
    vaccummingBlowOut_MainBody: fieldsForm.ccCdVacuumingBlowOutTasks.mainBody,
    vaccummingBlowOut_AllFans: fieldsForm.ccCdVacuumingBlowOutTasks.allFans,
    vaccummingBlowOut_PowerSupply: fieldsForm.ccCdVacuumingBlowOutTasks.powerSupply,
  } : {
    vaccummingBlowOut_MainBody: null,
    vaccummingBlowOut_AllFans: null,
    vaccummingBlowOut_PowerSupply: null,
  };

  const currencyCounter: CcCdDto = {
    updatedOn: new Date().toUTCString(),
    performedBy: workOrderDetail.performedBy ?? null,
    workOrderId: workOrderDetail.id,
    currencyCounterDiscriminatorItems,
    ...ccTasks,
    ...ccCdFeedModuleTasks,
    ...ccCdPresentModuleTasks,
    ...ccCdExternalAreaTasks,
    ...ccCdVacuumingBlowOutTasks,
    comments,
  };

  return {
    pageOpenMode,
    pageSubmitMode,
    isEditable: true,
    fromXml: false,
    currencyCounter,
    workOrderDetail: workOrderDetail,
  };
};
