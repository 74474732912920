import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StickyHeaderService {
  enabled = false;
  city = '';
  customerName = '';

  enableStickyHeader = (city: string, customerName: string | null) => {
    this.enabled = true;
    this.city = city;
    this.customerName = customerName ?? '';
  };
}
