import { AuthService } from 'services';
import { CommonModule, Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PanelComponent } from 'shared/panel/panel.component';
import { DetailRowComponent } from '../detail-row/detail-row.component';
import { FormsModule } from '@angular/forms';
import { CommonFormInputComponent } from 'shared/material-wrappers';
import { Router } from '@angular/router';
import { RoutesEnum } from 'app/utils/navigation';
import { statusColors } from 'app/utils/call-control';
import { WorkOrderDesktopMenuComponent } from './work-order-desktop-menu/work-order-desktop-menu.component';
import { WorkOrderService } from '../work-order-service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { EquipmentTableComponent } from "shared/equipment-table/equipment-table.component";
import { AdsEquipmentOnSite } from 'models';

@Component({
  selector: 'ads-work-order-desktop',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    PanelComponent,
    MatButtonModule,
    DetailRowComponent,
    CommonFormInputComponent,
    FormsModule,
    WorkOrderDesktopMenuComponent,
    MatTableModule,
    EquipmentTableComponent
],
  templateUrl: './work-order-desktop.component.html',
  styleUrl: './work-order-desktop.component.scss',
})
export class WorkOrderDesktopComponent{
  displayedColumnsKeys = ['equipmentType', 'partsWarrantyEnds', 'manufacturer', 'model', 'type', 'terminalID'];
  statusColors = statusColors;
  @Input() equipment = new MatTableDataSource<AdsEquipmentOnSite>([]);

  constructor(
    protected readonly router: Router,
    public readonly location: Location,
    public workOrderService: WorkOrderService,
    public authService: AuthService,
  ) {}

  goBack = () => {
    return this.router.navigate([RoutesEnum.CallControl]);
  };
}
