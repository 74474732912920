import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, NgForm, NgModelGroup } from '@angular/forms';
import { SelectOption } from 'models';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-atm-form-section',
  standalone: true,
  imports: [CommonModule, FormsModule, CommonFormInputComponent, SimpleFormComponent, SelectComponent],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './atm-form-section.component.html',
})
export class AtmFormSectionComponent {
  @Input() section?: FormGroup;
  @Input() atmManufacturerList: SelectOption<string>[] = [];
  @Input() depositorTypeList: SelectOption<string>[] = [];

  constructor(@Optional() public readonly ngModelGroup: NgModelGroup) {}

  displayOther = () => {
    const control = this.section?.get('atmManufacturer');
    return Boolean(control && control.value === 'Other');
  };
}
