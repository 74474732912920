<table mat-table [dataSource]="list" *ngIf="list.length > 0">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? toggleAllRows(list, selection) : null"
        [checked]="selection.hasValue() && isAllSelected(list, selection)"
        [indeterminate]="selection.hasValue() && !isAllSelected(list, selection)"
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        color="#b3261e"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="readerLocation">
    <th mat-header-cell *matHeaderCellDef>Location</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-input
        [ngModelGroup]="'accessControlDoor#' + (i + 1)"
        name="readerLocation"
        [maxLength]="50"
        [required]="true"
        [value]="element.readerLocation"
        [(ngModel)]="element.readerLocation"
        [onBlur]="onBlur(element)"
      ></ads-table-form-input>

      <div style="display: none">
        <ads-simple-form
          *ngIf="element"
          class="access-hardware-access-doors-wrapper"
          [ngModelGroup]="'accessControlDoor#' + (i + 1)"
        >
          <ads-radio-button-group
            [options]="optionsYesNoNa"
            label="Does a REX device unlock door?"
            name="doesAREXDeviceUnlockDoor"
            [(ngModel)]="element.doesAREXDeviceUnlockDoor"
            [required]="true"
          ></ads-radio-button-group>
          <ads-radio-button-group
            [options]="optionsCompleteNa"
            label="Test Lock"
            name="testLock"
            [required]="true"
            [(ngModel)]="element.testLock"
          ></ads-radio-button-group>
          <ads-radio-button-group
            [options]="optionsCompleteNa"
            label="Test Reader"
            name="testReader"
            [required]="true"
            [(ngModel)]="element.testReader"
          ></ads-radio-button-group>
          <ads-radio-button-group
            [options]="optionsCompleteNa"
            label="Test door held open alarm"
            name="testDoorHelpOpenAlarm"
            [required]="true"
            [(ngModel)]="element.testDoorHelpOpenAlarm"
          ></ads-radio-button-group>
          <ads-radio-button-group
            [options]="optionsCompleteNa"
            label="Test door forced alarm"
            name="testDoorForcedAlarm"
            [required]="true"
            [(ngModel)]="element.testDoorForcedAlarm"
          ></ads-radio-button-group
        ></ads-simple-form>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="readerStyle">
    <th mat-header-cell *matHeaderCellDef>Reader</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-bottom-sheet-input
        [ngModelGroup]="'accessControlDoor#' + (i + 1)"
        name="readerStyle"
        [required]="true"
        [value]="element.readerStyle"
        [(ngModel)]="element.readerStyle"
        [openBottomSheet]="openBottomSheet(element)"
        [maxWidth]="'105px'"
        [maxWidthInput]="'75px'"
      ></ads-table-form-bottom-sheet-input>
      <div style="margin: 6px 0px" *ngIf="displayOtherReaderStyle(element)">
        <ads-table-form-input
          [ngModelGroup]="'accessControlDoor#' + (i + 1)"
          name="readerStyle_Other"
          [maxLength]="50"
          [required]="true"
          [value]="element.readerStyle_Other"
          [(ngModel)]="element.readerStyle_Other"
        ></ads-table-form-input>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="lockStyle">
    <th mat-header-cell *matHeaderCellDef>Lock</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-bottom-sheet-input
        [ngModelGroup]="'accessControlDoor#' + (i + 1)"
        name="lockStyle"
        [required]="true"
        [value]="element.lockStyle"
        [(ngModel)]="element.lockStyle"
        [openBottomSheet]="openBottomSheet(element)"
        [maxWidth]="'105px'"
        [maxWidthInput]="'75px'"
      ></ads-table-form-bottom-sheet-input>
      <div style="margin: 6px 0px" *ngIf="displayOtherLockStyle(element)">
        <ads-table-form-input
          [ngModelGroup]="'accessControlDoor#' + (i + 1)"
          name="lockStyle_Other"
          [maxLength]="50"
          [required]="true"
          [value]="element.lockStyle_Other"
          [(ngModel)]="element.lockStyle_Other"
        ></ads-table-form-input>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="tasks">
    <th mat-header-cell *matHeaderCellDef>Tasks</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-bottom-sheet-input
        [ngModelGroup]="'accessControlDoor#' + (i + 1)"
        [value]="elementTasksCompleted(element)[0] + '/5'"
        [openBottomSheet]="openTasksBottomSheet(element)"
        [maxWidth]="'25px'"
        [invalid]="elementTasksCompleted(element)[1]"
      ></ads-table-form-bottom-sheet-input>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumnsKeys()"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsKeys()"></tr>
</table>
