import { IndexableFormType } from 'app/utils/miscTypes';

export interface ComboLock {
  comboLockModel: string | null;
  comboLockType: string | null;
  comboLockManufacturer: string | null;
  isThereKeyLockingDial: number | null;
  isComboLockChanged: number | null;
  comboLockChangedDetail: string | null;
}

export class VaultComboLockSection implements IndexableFormType, ComboLock {
  index: number;
  comboLockModel: string | null;
  comboLockType: string | null;
  comboLockManufacturer: string | null;
  isThereKeyLockingDial: number | null;
  isComboLockChanged: number | null;
  comboLockChangedDetail: string | null;

  constructor(index: number) {
    this.index = index;
    this.comboLockModel = '';
    this.comboLockType = '';
    this.comboLockManufacturer = '';
    this.isThereKeyLockingDial = null;
    this.isComboLockChanged = null;
    this.comboLockChangedDetail = '';
  }
}
