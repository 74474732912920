import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, NgModelGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { PhoneInputComponent } from 'shared/material-wrappers/phone-input/phone-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AlarmLists, HubFormSection } from 'pages/security/security.service.models';
import { DatePickerComponent } from 'shared/material-wrappers/datepicker/datepicker.component';

@Component({
  selector: 'ads-hub-section-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CommonFormInputComponent,
    SelectComponent,
    SimpleFormComponent,
    PhoneInputComponent,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    DatePickerComponent,
  ],
  templateUrl: './hub-section-form.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class HubSectionFormComponent {
  @Input() section?: FormGroup;
  @Input() item?: HubFormSection;
  @Input() alarmLists?: AlarmLists;
}

