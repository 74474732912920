import { Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { ApiService, AuthService, PhotoUploadService } from 'services';
import { HttpParams } from '@angular/common/http';
import {
  ItmCashCheckDepositoryTasks,
  ItmCashDispenserTasks,
  ItmFasciaAndAdaTasks,
  ItmForm,
  ItmPeripheralTasks,
  ItmReceiptPrinterTasks,
  ItmSoftware,
  ItmSpecs,
  ItmTasks,
  ItmVacuumingAndBlowOutTasks,
} from 'models';
import { itmMapRequest, ItmRequest, ItmResponse } from 'models/api';
import { ItmPhotoUploadKey } from 'utils';
import { ItmDto } from 'models/api/itm/itm';
import { FormPageService } from 'pages/form-page/form-page-service';

@Injectable({
  providedIn: 'root',
})
export class ItmService implements FormPageService<ItmForm, ItmResponse> {
  public form: ItmForm = {
    itmSpecs: new ItmSpecs(),
    software: new ItmSoftware(),
    itmTasks: new ItmTasks(),
    itmPeripheralTasks: new ItmPeripheralTasks(),
    itmReceiptPrinterTasks: new ItmReceiptPrinterTasks(),
    itmCashDispenserTasks: new ItmCashDispenserTasks(),
    itmFasciaAndAdaTasks: new ItmFasciaAndAdaTasks(),
    itmCashCheckDepositoryTasks: new ItmCashCheckDepositoryTasks(),
    itmVacuumingAndBlowOutTasks: new ItmVacuumingAndBlowOutTasks(),
    comments: '',
  };

  workOrder = '';

  constructor(
    private readonly service: ApiService,
    private readonly authService: AuthService,
    private readonly photoUploadService: PhotoUploadService
  ) {}

  updateForm(data: ItmForm) {
    this.form = data;
  }

  public getForm(): Observable<ItmResponse> {
    const params = new HttpParams({
      fromObject: { userId: this.authService.user?.personnelNumber ?? '' },
    });
    return this.service.sendRequest<ItmResponse>({
      method: 'get',
      url: `pmforms/itm/${this.workOrder}`,
      params,
      urlPrefix: 'ccc',
    });
  }

  /**
   * Saves ATM cleaning form
   */
  public saveForm(request: ItmRequest): Observable<string> {
    const saveForm = itmMapRequest(request);
    return this.service.sendRequest<string>({
      method: 'post',
      url: 'pmforms/itm',
      body: saveForm,
      urlPrefix: 'ccc',
    });
  }

  public loadPhotos = (data: ItmDto): Observable<Record<ItmPhotoUploadKey, string>> => {
    const sources = [
      data.photos_FrontDistance,
      data.photos_FrontClose,
      data.photos_CardReader,
      data.photos_Computer,
      data.photos_Depositories,
      data.photos_CustomerScreen,
      data.photos_Dispenser,
      data.photos_Printer,
      data.photos_CustomerMonitor,
      data.photos_OperatorPanel,
      data.photos_SerialNumberLabel,
      data.photos_Software,
      data.photos_IdScanner,
      data.photos_CoinDispenser,
      data.photo_AdditionalPhoto,
    ].map((url) => {
      if (!url) {
        return Promise.resolve({ sasUrl: '' });
      }
      return this.photoUploadService.getPhotoUrl(url);
    });

    return forkJoin(sources).pipe(
      map(
        ([
          photos_FrontDistance,
          photos_FrontClose,
          photos_CardReader,
          photos_Computer,
          photos_Depositories,
          photos_CustomerScreen,
          photos_Dispenser,
          photos_Printer,
          photos_CustomerMonitor,
          photos_OperatorPanel,
          photos_SerialNumberLabel,
          photos_Software,
          photos_IdScanner,
          photos_CoinDispenser,
          photo_AdditionalPhoto,
        ]) => ({
          'itm-front-distance': photos_FrontDistance.sasUrl,
          'itm-front-close': photos_FrontClose.sasUrl,
          'itm-card-reader-from-inside-fascia': photos_CardReader.sasUrl,
          'itm-depositories': photos_Depositories.sasUrl,
          'itm-dispenser': photos_Dispenser.sasUrl,
          'itm-customer-monitor-from-inside-fascia': photos_CustomerMonitor.sasUrl,
          'itm-serial-number-label': photos_SerialNumberLabel.sasUrl,
          'itm-computer': photos_Computer.sasUrl,
          'itm-touchscreen-fdks': photos_CustomerScreen.sasUrl,
          'itm-printer': photos_Printer.sasUrl,
          'itm-id-scanner': photos_IdScanner.sasUrl,
          'itm-additional-photo': photo_AdditionalPhoto.sasUrl,
          'itm-operator-panel': photos_OperatorPanel.sasUrl,
          'itm-software-display-in-one-shot': photos_Software.sasUrl,
          'itm-coin-dispenser': photos_CoinDispenser.sasUrl,
        })
      ),
      catchError(() =>
        of({
          'itm-front-distance': '',
          'itm-front-close': '',
          'itm-card-reader-from-inside-fascia': '',
          'itm-depositories': '',
          'itm-dispenser': '',
          'itm-customer-monitor-from-inside-fascia': '',
          'itm-serial-number-label': '',
          'itm-computer': '',
          'itm-touchscreen-fdks': '',
          'itm-printer': '',
          'itm-id-scanner': '',
          'itm-additional-photo': '',
          'itm-operator-panel': '',
          'itm-software-display-in-one-shot': '',
          'itm-coin-dispenser': '',
        })
      )
    );
  };

  getPhotos(data: ItmResponse, updateFormPhotos: (photos: Record<ItmPhotoUploadKey, string>) => void) {
    this.loadPhotos(data.itm).subscribe((photos) => {
      updateFormPhotos(photos);
    });
  }
}
