<ads-simple-form #sensors="ngModelGroup" ngModelGroup="sensors">
  <ads-common-form-input
    name="hardwiredMotionSensors"
    label="Hardwired Motion Sensors"
    type="number"
    [maxLength]="4"
    [minValue]="0"
    [required]="true"
    ngModel
    inputMode="tel"
  ></ads-common-form-input>
  <mat-divider></mat-divider>
  <ads-common-form-input
    name="wirelessMotionSensors"
    label="Wireless Motion Sensors"
    type="number"
    [maxLength]="4"
    [minValue]="0"
    [required]="true"
    ngModel
    inputMode="tel"
  ></ads-common-form-input>
  <mat-divider></mat-divider>
  <ads-common-form-input
    name="hardWiredDoorContacts"
    label="Hardwired Door Contacts"
    type="number"
    [maxLength]="4"
    [minValue]="0"
    [required]="true"
    ngModel
    inputMode="tel"
  ></ads-common-form-input>
  <mat-divider></mat-divider>
  <ads-common-form-input
    name="wirelessDoorContacts"
    label="Wireless Door Contacts"
    type="number"
    [maxLength]="4"
    [minValue]="0"
    [required]="true"
    ngModel
    inputMode="tel"
  ></ads-common-form-input>
  <mat-divider></mat-divider>
  <ads-common-form-input
    name="vaultDoorContacts"
    label="Vault Door Contacts"
    type="number"
    [maxLength]="4"
    [minValue]="0"
    [required]="true"
    ngModel
    inputMode="tel"
  ></ads-common-form-input>
  <mat-divider></mat-divider>
  <ads-common-form-input
    name="audioSensors"
    label="Audio Sensors"
    type="number"
    [maxLength]="4"
    [minValue]="0"
    [required]="true"
    ngModel
    inputMode="tel"
  ></ads-common-form-input>
  <mat-divider></mat-divider>
  <ads-common-form-input
    name="armedCashContainers"
    label="Armed Cash Container"
    type="number"
    [maxLength]="4"
    [minValue]="0"
    [required]="true"
    ngModel
    inputMode="tel"
  ></ads-common-form-input>
  <mat-divider></mat-divider>
  <ads-common-form-input
    name="hardwiredNightDropContacts"
    label="Hardwired Night Drop Contacts"
    type="number"
    [maxLength]="4"
    [minValue]="0"
    [required]="true"
    ngModel
    inputMode="tel"
  ></ads-common-form-input>
  <mat-divider></mat-divider>
  <ads-common-form-input
    name="wirelessNightDropContacts"
    label="Wireless Night Drop Contacts"
    type="number"
    [maxLength]="4"
    [minValue]="0"
    [required]="true"
    ngModel
    inputMode="tel"
  ></ads-common-form-input>
  <mat-divider></mat-divider>
  <ads-common-form-input
    name="atmsAmount"
    label="ATMs"
    type="number"
    [maxLength]="4"
    [minValue]="0"
    [required]="true"
    ngModel
    inputMode="tel"
  ></ads-common-form-input>
  <mat-divider></mat-divider>
  <ads-common-form-input
    name="tcdTcrAmount"
    label="TCR/TCDs"
    type="number"
    [maxLength]="4"
    [minValue]="0"
    [required]="true"
    ngModel
    inputMode="tel"
  ></ads-common-form-input>
  <mat-divider></mat-divider>
</ads-simple-form>
