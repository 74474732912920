import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { LoggingService } from 'services';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class TrackRequestInterceptor implements HttpInterceptor {
  constructor(private readonly loggingService: LoggingService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const eventName = `HttpRequest (${uuidv4()})`;

    this.loggingService.startTrackEvent(eventName);

    return next
        .handle(req)
        .pipe(
            finalize(() => this.loggingService.stopTrackEvent(eventName, req.urlWithParams)));
  }
}
