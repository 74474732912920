export const environment = {
  name: '#{ENV_NAME}#',
  msalConfig: {
    auth: {
      clientId: '#{MSAL_AUTH_CLIENT_ID}#',
      authority: '#{MSAL_AUTH_AUTHORITY}#',
    },
    redirectUrl: '/',
    aadScopes: {
      loginRequest: ['openid', 'profile'],
    },
  },
  apiConfig: {
    adsServiceUri: '#{ADS_SERVICE_URI}#',
    scopes: ['#{ADS_SERVICE_SCOPE}#'],
    axServiceUri: '#{AX_SERVICE_URI}#',
    ccCoreServiceUri: '#{CC_CORE_SERVICE_URI}#',
    apimSubscriptionKey: '#{APIM_SUBSCRIPTION_KEY}#',
    adsUrl: '#{ADS_URL}#',
    powerAppUrl: 'https://apps.powerapps.com/play/providers/Microsoft.PowerApps/apps/',
    kabaRequest: '46755c82-112c-45f5-9222-11044acdac9a',
  },
  loggers: {
    appInsightsConnectionString: '#{APP_INSIGHTS_CONNECTION_STRING}#',
    clarityTrackingCode: '#{CLARITY_TRACKING_CODE}#',
  },
  emailRecipient: '#{EMAIL_RECIPIENT}#',
  launchDarklyClientId: '#{LAUNCH_DARKLY_CLIENT_ID}#',
  wfAccountNum: 'CST-0000398',
};
