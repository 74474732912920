<ads-order-info [order]="workOrder" [customerName]="customerName"></ads-order-info>
<form #tcdTcrForm="ngForm" (ngSubmit)="onSubmit()">
  <ads-multiple-forms-section
    #tcdTcrSectionsPanels
    title="TCD/TCR"
    [formTemplate]="formTemplate"
    [list]="service.tcdTcrSections"
    [formControls]="form?.form?.controls"
    [groupName]="'tcdTcrSections'"
    [expandedList]="expandedList"
    [submitted]="getIsFormSubmitted()"
    [photosStatus]="getPhotosStatuses()"
    (addClicked)="onAddClick()"
    (deleteClicked)="onRemoveTcr($event)"
  >
  </ads-multiple-forms-section>
  <ng-template #formTemplate let-item="item">
    <ads-tcd-tcr-section
      [section]="getSection('tcdTcrSections#' + item.index)"
      [item]="item"
      [photoUploadForm]="service.photoUploadForms[item.index - 1]"
      [tcdTcrLists]="dropdowns"
    ></ads-tcd-tcr-section>
  </ng-template>
  <ads-expansion-panel
    #tcdTcrTasksSectionPanel
    title="TCD/TCR Tasks"
    [formGroup]="form?.form"
    [name]="'tcdTcrTasks'"
    [class]="'tcdTcrTasks'"
  >
    <ads-tcd-tcr-tasks [section]="getSection('tcdTcrTasks')"></ads-tcd-tcr-tasks>
  </ads-expansion-panel>
  <ads-expansion-panel
    #cassetteTasksSectionPanel
    title="Cassette Tasks"
    [formGroup]="form?.form"
    [name]="'tcdTcrCassetteTasks'"
    [class]="'tcdTcrCassetteTasks'"
  >
    <ads-tcd-tcr-cassettes-tasks [section]="getSection('tcdTcrCassetteTasks')"></ads-tcd-tcr-cassettes-tasks>
  </ads-expansion-panel>
  <ads-expansion-panel
    #feedModuleTasksSectionPanel
    title="Feed Module Tasks"
    [formGroup]="form?.form"
    [name]="'tcdTcrFeedModuleTasks'"
    [class]="'tcdTcrFeedModuleTasks'"
  >
    <ads-tcd-tcr-feed-module-tasks [section]="getSection('tcdTcrFeedModuleTasks')"></ads-tcd-tcr-feed-module-tasks>
  </ads-expansion-panel>
  <ads-expansion-panel
    #inputModuleTasksSectionPanel
    title="Present Input Module Tasks"
    [formGroup]="form?.form"
    [name]="'tcdTcrInputModuleTasks'"
    [class]="'tcdTcrInputModuleTasks'"
  >
    <ads-tcd-tcr-input-module-tasks [section]="getSection('tcdTcrInputModuleTasks')"></ads-tcd-tcr-input-module-tasks>
  </ads-expansion-panel>
  <ads-expansion-panel
    #externalAreaTasksSectionPanel
    title="External Area Tasks"
    [formGroup]="form?.form"
    [name]="'tcdTcrExternalAreaTasks'"
    [class]="'tcdTcrExternalAreaTasks'"
  >
    <ads-tcd-tcr-external-area-tasks
      [section]="getSection('tcdTcrExternalAreaTasks')"
    ></ads-tcd-tcr-external-area-tasks>
  </ads-expansion-panel>
  <ads-expansion-panel
    #vacuumingBlowOutSectionPanel
    title="Vacuuming & Blow Out"
    [formGroup]="form?.form"
    [name]="'tcdTcrVacuumingBlowOut'"
    [class]="'tcdTcrVacuumingBlowOut'"
  >
    <ads-tcd-tcr-vacuuming-blow-out [section]="getSection('tcdTcrVacuumingBlowOut')"></ads-tcd-tcr-vacuuming-blow-out>
  </ads-expansion-panel>

  <ads-common-text-area name="comments" label="Comments (optional)" [maxLength]="500" ngModel></ads-common-text-area>

  <div class="form-buttons" *ngIf="true">
    <button
      mat-button
      mat-stroked-button
      (click)="saveForm()"
      [disabled]="form?.form?.disabled || (isLoading | async)"
      type="button"
    >
      Save
    </button>
    <button mat-button mat-flat-button class="main-button" [disabled]="form?.form?.disabled">Submit</button>
  </div>
</form>
<div class="clear"></div>
