<ads-order-info [order]="workOrder" [customerName]="customerName"></ads-order-info>

<form #ccCdForm="ngForm" (ngSubmit)="onSubmit()">
  <ads-multiple-forms-section
    [groupName]="'ccCdSections'"
    [formTemplate]="formTemplate"
    title="CC/CD"
    (addClicked)="onAddClick()"
    (deleteClicked)="onRemoveCcCd($event)"
    [list]="service.ccCdSections"
    [formControls]="form?.controls"
    [expandedList]="expandedList"
    [submitted]="getIsFormSubmitted()"
    [photosStatus]="getPhotosStatuses()"
  >
  </ads-multiple-forms-section>

  <ng-template #formTemplate let-item="item">
    <ads-cc-cd-section
      [section]="getSection('ccCdSections#' + item.index)"
      [ngSectionName]="'ccCdSection'"
      [item]="item"
      [photoUploadForm]="service.photoUploadForms[item.index - 1]"
      [ccCdDropdowns]="dropdowns"
    ></ads-cc-cd-section>
  </ng-template>

  <ads-expansion-panel
    #ccCdTasksSectionPanel
    title="CC/CD Tasks"
    [formGroup]="form?.form"
    [name]="'ccCdTasks'"
    [class]="'ccCdTasks'"
  >
    <ads-cc-cd-tasks-section [section]="getSection('ccCdTasks')"></ads-cc-cd-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #ccCdFeedModuleTasksSectionPanel
    title="Feed Module Tasks"
    [formGroup]="form?.form"
    [name]="'ccCdFeedModuleTasks'"
    [class]="'ccCdFeedModuleTasks'"
  >
    <ads-cc-cd-feed-module-tasks-section
      [section]="getSection('ccCdFeedModuleTasks')"
    ></ads-cc-cd-feed-module-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #ccCdPresentModuleTasksSectionPanel
    title="Present/Input Module Tasks"
    [formGroup]="form?.form"
    [name]="'ccCdPresentModuleTasks'"
    [class]="'ccCdPresentModuleTasks'"
  >
    <ads-cc-cd-present-module-tasks-section
      [section]="getSection('ccCdPresentModuleTasks')"
    ></ads-cc-cd-present-module-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #ccCdExternalAreaTasksSectionPanel
    title="External Area Tasks"
    [formGroup]="form?.form"
    [name]="'ccCdExternalAreaTasks'"
    [class]="'ccCdExternalAreaTasks'"
  >
    <ads-cc-cd-external-area-tasks-section
      [section]="getSection('ccCdExternalAreaTasks')"
    ></ads-cc-cd-external-area-tasks-section>
  </ads-expansion-panel>

  <ads-expansion-panel
    #ccCdVacuumingBlowOutTasksSectionPanel
    title="Vacuuming & Blow Out (POWER MUST BE OFF)"
    [formGroup]="form?.form"
    [name]="'ccCdVacuumingBlowOutTasks'"
    [class]="'ccCdVacuumingBlowOutTasks'"
  >
    <ads-cc-cd-vacuuming-tasks-section
      [section]="getSection('ccCdVacuumingBlowOutTasks')"
    ></ads-cc-cd-vacuuming-tasks-section>
  </ads-expansion-panel>

  <ads-common-text-area name="comments" label="Comments (optional)" [maxLength]="500" ngModel></ads-common-text-area>

  <div class="form-buttons" *ngIf="!getIsFormSubmitted()">
    <button
      mat-button
      mat-stroked-button
      (click)="saveForm()"
      [disabled]="form?.form?.disabled || (isLoading | async)"
      type="button"
    >
      Save
    </button>
    <button mat-button mat-flat-button class="main-button" [disabled]="form?.form?.disabled">Submit</button>
  </div>
</form>

<div class="clear"></div>
