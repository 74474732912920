import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ControlContainer, FormGroup, FormsModule, NgModelGroup } from '@angular/forms';
import { KeypadsForm, SelectOption, YesNo } from 'models';
import {
  SingleToggleButtonComponent,
  RadioButtonGroupComponent,
  CommonFormInputComponent,
  SelectComponent,
} from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { DatePickerComponent } from 'shared/material-wrappers/datepicker/datepicker.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ads-keypads',
  standalone: true,
  imports: [
    CommonModule,
    SingleToggleButtonComponent,
    RadioButtonGroupComponent,
    SimpleFormComponent,
    MatDividerModule,
    FormsModule,
    CommonFormInputComponent,
    SelectComponent,
    DatePickerComponent,
  ],
  templateUrl: './keypads.component.html',
  styleUrl: './keypads.component.scss',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class KeypadsComponent {
  @Input() section?: FormGroup;

  selectAllChecked = false;
  fields = Object.getOwnPropertyNames(new KeypadsForm());

  yesNoOptions: SelectOption<YesNo>[] = [
    { name: 'Yes', value: YesNo.Yes },
    { name: 'No', value: YesNo.No },
  ];
}
