export enum NavigationRoutesEnum {
  Home,
  CallControl,
  Lines,
  Notes,
  Calendar,
  History,
  Centran,
  MyOpenCalls,
  AtmDown,
  OtherTechs,
  Inventory,
  PartStatus,
}

export const NavigationHandler = {
  [NavigationRoutesEnum.Home]: 'dashboard',
  [NavigationRoutesEnum.CallControl]: 'callControl',
  [NavigationRoutesEnum.Lines]: 'lines',
  [NavigationRoutesEnum.Notes]: 'notes',
  [NavigationRoutesEnum.Calendar]: 'calendar',
  [NavigationRoutesEnum.History]: 'history',
  [NavigationRoutesEnum.Centran]: 'centran',
  [NavigationRoutesEnum.MyOpenCalls]: 'myOpenCalls',
  [NavigationRoutesEnum.AtmDown]: 'atmDown',
  [NavigationRoutesEnum.OtherTechs]: 'otherTechs',
  [NavigationRoutesEnum.Inventory]: 'inventory',
  [NavigationRoutesEnum.PartStatus]: 'partStatus',
} as const;

export enum RoutesEnum {
  AtmCleaning = 'AtmCleaning',
  ItmCleaning = 'ItmCleaning',
  Submitted = 'submitted',
  Atm = 'Atm',
  Itm = 'Itm',
  TcdTcr = 'TcdTcr',
  CcCd = 'CcCd',
  Security = 'Security',
  Home = 'dashboard',
  More = 'more',
  CallControl = 'callControl',
  Calendar = 'calendar',
  Lines = 'lines',
  Notes = 'notes',
  History = 'history',
  AtmDown = 'atmDown',
  Centran = 'centran',
  MyOpenCalls = 'myOpenCalls',
  PartStatus = 'partStatus',
  Inventory = 'inventory',
  OtherTechs = 'otherTechs',
  partRequest = 'part-request',
  mobileAcknowledgement = 'mobileAcknowledgement',
  rmaRequest = 'rmaRequest',
  WorkOrder = 'WO',
}

export const routeMap: Record<string, RoutesEnum> = {
  [`/PM/${RoutesEnum.AtmCleaning}`]: RoutesEnum.AtmCleaning,
  [`/PM/${RoutesEnum.ItmCleaning}`]: RoutesEnum.ItmCleaning,
  [`/PM/${RoutesEnum.Atm}`]: RoutesEnum.Atm,
  [`/PM/${RoutesEnum.Itm}`]: RoutesEnum.Itm,
  [`/PM/${RoutesEnum.TcdTcr}`]: RoutesEnum.TcdTcr,
  [`/PM/${RoutesEnum.CcCd}`]: RoutesEnum.CcCd,
  [`/PM/${RoutesEnum.Security}`]: RoutesEnum.Security,
  [`/${RoutesEnum.Submitted}`]: RoutesEnum.Submitted,
  [`/${RoutesEnum.Home}`]: RoutesEnum.Home,
  [`/${RoutesEnum.More}`]: RoutesEnum.More,
  [`/${RoutesEnum.CallControl}`]: RoutesEnum.CallControl,
  [`/${RoutesEnum.Calendar}`]: RoutesEnum.Calendar,
  [`/${RoutesEnum.Lines}`]: RoutesEnum.Lines,
  [`/${RoutesEnum.Notes}`]: RoutesEnum.Notes,
  [`/${RoutesEnum.History}`]: RoutesEnum.History,
  [`/${RoutesEnum.AtmDown}`]: RoutesEnum.AtmDown,
  [`/${RoutesEnum.Centran}`]: RoutesEnum.Centran,
  [`/${RoutesEnum.MyOpenCalls}`]: RoutesEnum.MyOpenCalls,
  [`/${RoutesEnum.PartStatus}`]: RoutesEnum.PartStatus,
  [`/${RoutesEnum.Inventory}`]: RoutesEnum.Inventory,
  [`/${RoutesEnum.OtherTechs}`]: RoutesEnum.OtherTechs,
  [`/${RoutesEnum.partRequest}`]: RoutesEnum.partRequest,
  [`/${RoutesEnum.mobileAcknowledgement}`]: RoutesEnum.mobileAcknowledgement,
  [`/${RoutesEnum.rmaRequest}`]: RoutesEnum.rmaRequest,
  [`/${RoutesEnum.WorkOrder}`]: RoutesEnum.WorkOrder,
} as const;

const HideNavRoutes: Record<string, boolean> = {
  [`/${RoutesEnum.CallControl}`]: true,
  [`/${RoutesEnum.More}`]: true,
  [`/${RoutesEnum.WorkOrder}`]: true,
} as const;

export const isNavHidden = (route: string): boolean => {
  if (route.startsWith(`/${RoutesEnum.WorkOrder}`)) {
    return true;
  }
  return !!HideNavRoutes[route];
};