import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { IndexableFormType } from 'app/utils/miscTypes';
import { ExpansionPanelComponent } from 'shared/material-wrappers';
import { RemoveFormSectionComponent } from 'shared/remove-form-section/remove-form-section.component';
import { BaseNestedFormsSectionComponent } from '../nested-section-base/nested-section.base';

@Component({
  selector: 'ads-nested-table-forms-section',
  standalone: true,
  imports: [CommonModule, ExpansionPanelComponent, MatButtonModule, MatIconModule, MatExpansionModule],
  templateUrl: './nested-table-forms-section.component.html',
  styleUrl: './nested-table-forms-section.component.scss',
})
export class NestedTableFormsSectionComponent<T extends IndexableFormType> extends BaseNestedFormsSectionComponent<T> {
  readonly dialog = inject(MatDialog);

  onDeleteChecked = (index: number) => {
    if (this.deletingItemList.includes(this.list[index - 1])) {
      this.deletingItemList = this.deletingItemList.filter((item) => item !== this.list[index - 1]);
    } else {
      this.deletingItemList.push(this.list[index - 1]);
    }
  };

  onDelete() {
    this.dialog.open(RemoveFormSectionComponent, {
      data: {
        sectionName: 'selected cameras from the ',
        onConfirm: () => {
          this.deletingItemList.forEach((item) => {
            this.deleteClicked.emit(item);
          });
          this.deletingItemList = [];
          this.deleting = false;
        },
      },
    });
  }
}
