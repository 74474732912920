<ads-expansion-panel class="expansion-panel" [customStatus]="customStatus" [title]="title" [name]="groupName + 's'">
  <div class="button-wrapper" *ngIf="!submitted">
    <button mat-button mat-stroked-button *ngIf="deleting" class="delete" (click)="onCancelDeleteClick()" type="button">
      <span *ngIf="deleting">Cancel</span>
    </button>
    <button
      mat-button
      mat-stroked-button
      [class.form-button]="!deleting"
      class="delete"
      *ngIf="getShowDeleteButton()"
      (click)="onDeleteClick($event)"
    >
      <mat-icon class="material-icons-outlined">delete</mat-icon>
      <span *ngIf="deleting">Delete</span>
    </button>
    <button mat-button mat-stroked-button (click)="onAddClick($event)">
      <mat-icon class="add-button">add</mat-icon>
      Add
    </button>
  </div>
  <ng-content></ng-content>
  <div *ngIf="formTemplate">
    <ng-container *ngFor="let item of list">
      <ads-expansion-panel
        [title]="(itemTitle ?? title) + ': ' + item.index"
        [class]="groupName + '#' + item.index"
        [name]="groupName + '#' + item.index"
        [hideStatusIcon]="true"
        [inverted]="true"
        [showDeleteButton]="deleting"
        (deleteChecked)="onDeleteChecked(item)"
      >
        <ng-container *ngTemplateOutlet="formTemplate; context: { item }"></ng-container>
      </ads-expansion-panel>
    </ng-container>
  </div>
</ads-expansion-panel>
