import { Equipment } from '../../security';
import { AdsSecurityRequest, SecurityRequest } from '../ads-security-request';
import {
  mapAlarmRequest,
} from './security-map/alarm/alarm-map';
import {
  SecurityAlarmsDto,
  SecurityDto,
  SecurityEquipmentsDto,
  SecurityDealDrawerDto,
  SecurityNightDropDto,
  SecurityRtsDto,
  SecuritySafesDto,
  SecurityTabsDto,
  SecurityUCSDto,
  SecurityVaultsDto,
  SecurityAudioVideoDto,
  SecuritySurveillanceDto,
  SecurityAccessHardwareDto,
} from '../security/security';
import { mapSecuritySurveillanceRequest } from './security-map/surveillance/surveillance-map-request';
import { mapAccessHardwareRequest } from './security-map/access-hardware/access-hardware-map-request';
import { mapSafesRequest } from './security-map/safes/safes-map';
import { mapVaultRequest } from './security-map/vault/vault-map';
import { mapNightDropRequest } from './security-map/night-drop/night-drop-map-request';
import { mapDealDrawerRequest } from './security-map/deal-drawer/deal-drawer-map';
import { mapUcsRequest } from './security-map/ucs/ucs-map-request';
import { mapRtsRequest } from './security-map/rts-map';
import { mapAudioVideoRequest } from './security-map/audio-video/audio-video-map';

export const mapSecurityEquipment = (equipment: Equipment[]): SecurityEquipmentsDto => {
  const selectedEquipment: SecurityEquipmentsDto = {
    alarm: equipment.includes(Equipment.Alarm),
    surveillance: equipment.includes(Equipment.Surveillance),
    vault: equipment.includes(Equipment.Vault),
    safes: equipment.includes(Equipment.Safes),
    ucs: equipment.includes(Equipment.UCS),
    nightDrop: equipment.includes(Equipment.NightDrop),
    accessControlHardware: equipment.includes(Equipment.AccessControlHardware),
    dealDrawer: equipment.includes(Equipment.DealDrawer),
    rts: equipment.includes(Equipment.RTS),
    audioVideoDU: equipment.includes(Equipment.AudioVideoDU),
  };
  return selectedEquipment;
};

export const securityMapRequest = ({
  fieldsForm,
  workOrderDetail,
  pageSubmitMode,
  pageOpenMode,
}: SecurityRequest): AdsSecurityRequest => {
  const securityTab: SecurityEquipmentsDto = mapSecurityEquipment(fieldsForm.selectedEquipment);
  const securityAlarm: SecurityAlarmsDto = mapAlarmRequest(fieldsForm?.alarm);
  const securityAccessHardware: SecurityAccessHardwareDto = mapAccessHardwareRequest(fieldsForm?.accessHardware);
  const securitySafes: SecuritySafesDto = mapSafesRequest(fieldsForm?.safes);
  const securityVault: SecurityVaultsDto = mapVaultRequest(fieldsForm?.vault);
  const securityNightDrop: SecurityNightDropDto = mapNightDropRequest(fieldsForm?.nightDrop);
  const securityDealDrawer: SecurityDealDrawerDto = mapDealDrawerRequest(fieldsForm?.dealDrawer);
  const ucsTab: SecurityUCSDto = mapUcsRequest(fieldsForm.ucs);
  const rtsTab: SecurityRtsDto = mapRtsRequest(fieldsForm.rts);
  const audioVideoTab: SecurityAudioVideoDto = mapAudioVideoRequest(fieldsForm.audioVideo);
  const surveillanceTab: SecuritySurveillanceDto = mapSecuritySurveillanceRequest(fieldsForm.surveillance);

  const securityTabs: SecurityTabsDto = {
    securityTab,
  };

  const security: SecurityDto = {
    updatedOn: new Date().toUTCString(),
    performedBy: workOrderDetail.performedBy ?? null,
    workOrderId: workOrderDetail.id,
    applyCSGProtectionDecalInBottomRightCorner: fieldsForm.applyCSGProtectionDecalInBottomRightCorner,
    ...securityTabs,
    ...securityAlarm,
    ...securityAccessHardware,
    ...audioVideoTab,
    ...ucsTab,
    ...rtsTab,
    ...securitySafes,
    ...securityDealDrawer,
    ...securityNightDrop,
    ...securityVault,
    ...surveillanceTab,
  };

  return {
    pageOpenMode,
    pageSubmitMode,
    isEditable: true,
    fromXml: false,
    security,
    workOrderDetail: workOrderDetail,
  };
};
