import { CompleteNa } from 'models/shared/complete-na';

export class TellerUnitForm {
  allNa: boolean;
  inspectDoorMotors: CompleteNa | null;
  sendRecallSwitches: CompleteNa | null;
  testCarrierSwitches: CompleteNa | null;
  testRelays: CompleteNa | null;
  testBumperPads: CompleteNa | null;
  testIndicatorLights: CompleteNa | null;
  testCarrierCatch: CompleteNa | null;
  cleanOutside: CompleteNa | null;
  vaccumInside: CompleteNa | null;

  constructor() {
    this.allNa = false;
    this.inspectDoorMotors = null;
    this.sendRecallSwitches = null;
    this.testCarrierSwitches = null;
    this.testRelays = null;
    this.testBumperPads = null;
    this.testIndicatorLights = null;
    this.testCarrierCatch = null;
    this.cleanOutside = null;
    this.vaccumInside = null;
  }
}
