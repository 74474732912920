<ads-service-calls-header
  [allCalls]="allCalls"
  [calls]="calls"
  [minDate]="getMinDate()"
  [isDesktopView]="isDesktopView"
  (sortingChanged)="refreshData()"
  (filterChanged)="refreshData()"
>
</ads-service-calls-header>
<div *ngIf="calls.length > 0" class="calls-list">
  <ads-work-order-card
    *ngFor="let call of calls"
    [call]="call"
    [isDesktopView]="isDesktopView"
    [currentTheme]="currentTheme"
    (callClicked)="onCallClick($event)"
  ></ads-work-order-card>
</div>
<mat-paginator
  *ngIf="callControlService.totalItems !== calls.length"
  [length]="callControlService.totalItems"
  [pageSize]="20"
  [showFirstLastButtons]="true"
  [hidePageSize]="true"
  [pageIndex]="pageIndex"
  aria-label="Select page"
  (page)="getPaginatorData($event)"
></mat-paginator>
<div *ngIf="calls.length === 0" class="empty-list">
  <div>No results. <a href="javascript:void(0)" (click)="clearFilters()"> Click here to clear filters. </a></div>
</div>
