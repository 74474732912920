<ads-simple-form *ngIf="item" class="audio-video-section-wrapper" [ngModelGroup]="'audioVideoSection#' + item.index">
  <ads-select
    label="Audio Matrix Manufacturer"
    name="manufacturer"
    [list]="audioVideoLists?.manufacturerList"
    [value]="item.manufacturer"
    [(ngModel)]="item.manufacturer"
  ></ads-select>
  <ads-common-form-input
    [style.display]="displayOtherManufacturer() ? 'block' : 'none'"
    name="otherManufacturer"
    label="Other Manufacturer"
    [maxLength]="50"
    [required]="displayOtherManufacturer()"
    [value]="item.otherManufacturer"
    [(ngModel)]="item.otherManufacturer"
  ></ads-common-form-input>
  <ads-common-form-input
    name="audioMatrixModel"
    label="Audio Matrix Model"
    [maxLength]="50"
    [required]="true"
    [value]="item.audioMatrixModel"
    [(ngModel)]="item.audioMatrixModel"
  ></ads-common-form-input>
  <ads-common-form-input
    name="tellerConsolesAmount"
    label="Quantity of teller consoles"
    type="number"
    [minValue]="0"
    [maxLength]="4"
    [required]="true"
    [value]="item.tellerConsolesAmount"
    [(ngModel)]="item.tellerConsolesAmount"
    inputMode="tel"
  ></ads-common-form-input>
  <ads-common-form-input
    name="style"
    label="Style (i.e. 1 on 2, 2 on 2)"
    [maxLength]="50"
    [required]="true"
    [value]="item.style"
    [(ngModel)]="item.style"
  ></ads-common-form-input>
  <ads-select
    label="Select video system"
    name="videoSystem"
    [list]="audioVideoLists?.videoSystemList"
    [value]="item.videoSystem"
    [(ngModel)]="item.videoSystem"
    [required]="true"
  ></ads-select>
  <ads-common-form-input
    name="wirelessHeadsetAmount"
    label="Quantity of wireless headsets"
    type="number"
    [minValue]="0"
    [maxLength]="4"
    [required]="true"
    [value]="item.wirelessHeadsetAmount"
    [(ngModel)]="item.wirelessHeadsetAmount"
    inputMode="tel"
  ></ads-common-form-input>
  <ads-select
    label="Overall Condition - Audio Matrix"
    name="overallCondition"
    [list]="audioVideoLists?.conditionList"
    [value]="item.overallCondition"
    [(ngModel)]="item.overallCondition"
    [required]="true"
  ></ads-select>
  <ads-common-text-area
    [style.display]="displayEquipmentRating() ? 'block' : 'none'"
    name="overallConditionComments"
    label="Substandard equipment comments"
    [maxLength]="500"
    [required]="displayEquipmentRating()"
    [value]="item.overallConditionComments"
    [(ngModel)]="item.overallConditionComments"
  ></ads-common-text-area>
</ads-simple-form>
