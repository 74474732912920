import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { ChartConfiguration, Plugin } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'ads-charts',
  standalone: true,
  imports: [BaseChartDirective],
  templateUrl: './charts.component.html',
})
export class ChartsComponent implements AfterViewInit {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  @Input() chartData?: ChartConfiguration<'bar'>['data'];
  @Input() chartOptions?: ChartConfiguration<'bar'>['options'];
  @Input() chartPlugins?: Plugin<'bar', unknown>[];
  @Input() legend = false;
  @Input() onChartInit: (_: BaseChartDirective) => void = () => {};
  @Input() customHeight = 0;
  @Input() maxHeight = '';

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.chart) {
        this.onChartInit(this.chart);
      }
    });
  }
}
