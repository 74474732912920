export class AtmTasks {
  confSheetsPresent: number | null;
  logSheetPresent: number | null;
  verifySiteProfileSP: number | null;
  talliesAbnormalities: number | null;
  clearTallies: number | null;
  requestKabaCombo: number | null;
  constructor() {
    this.requestKabaCombo = null;
    this.clearTallies = null;
    this.talliesAbnormalities = null;
    this.verifySiteProfileSP = null;
    this.logSheetPresent = null;
    this.confSheetsPresent = null;
  }
}
