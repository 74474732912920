<table mat-table [dataSource]="list" *ngIf="list.length > 0">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? toggleAllRows(list, selection) : null"
        [checked]="selection.hasValue() && isAllSelected(list, selection)"
        [indeterminate]="selection.hasValue() && !isAllSelected(list, selection)"
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        color="#b3261e"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="location">
    <th mat-header-cell *matHeaderCellDef>Location</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-input
        [ngModelGroup]="'accessControlCardReaderInterface#' + (i + 1)"
        name="location"
        [maxLength]="50"
        [required]="true"
        [value]="element.location"
        [(ngModel)]="element.location"
        [onBlur]="onBlur(element)"
      ></ads-table-form-input>
    </td>
  </ng-container>

  <ng-container matColumnDef="interfaceType">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-bottom-sheet-input
        [ngModelGroup]="'accessControlCardReaderInterface#' + (i + 1)"
        name="interfaceType"
        [required]="true"
        [value]="element.interfaceType"
        [(ngModel)]="element.interfaceType"
        [openBottomSheet]="openBottomSheet(element)"
        [maxWidth]="'85px'"
        [maxWidthInput]="'58px'"
      ></ads-table-form-bottom-sheet-input>
      <div style="margin: 6px 0px" *ngIf="displayOtherInterface(element)">
        <ads-table-form-input
          [ngModelGroup]="'accessControlCardReaderInterface#' + (i + 1)"
          name="interfaceType_Other"
          [maxLength]="50"
          [required]="true"
          [value]="element.interfaceType_Other"
          [(ngModel)]="element.interfaceType_Other"
        ></ads-table-form-input>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="port">
    <th mat-header-cell *matHeaderCellDef>Port</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-input
        [ngModelGroup]="'accessControlCardReaderInterface#' + (i + 1)"
        name="port"
        [maxLength]="50"
        [required]="true"
        [value]="element.port"
        [(ngModel)]="element.port"
      ></ads-table-form-input>
    </td>
  </ng-container>

  <ng-container matColumnDef="address">
    <th mat-header-cell *matHeaderCellDef>Address</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-input
        [ngModelGroup]="'accessControlCardReaderInterface#' + (i + 1)"
        name="address"
        [maxLength]="50"
        [required]="true"
        [value]="element.address"
        [(ngModel)]="element.address"
      ></ads-table-form-input>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumnsKeys()"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsKeys()"></tr>
</table>
