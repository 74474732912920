import { CompleteNa } from 'models/shared/complete-na';

export class BatteryTasks {
  allNa: boolean;
  acLossTest: CompleteNa | null;
  batteryLossTest: CompleteNa | null;

  constructor() {
    this.allNa = false;
    this.acLossTest = null;
    this.batteryLossTest = null;
  }
}
