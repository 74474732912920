import { Injectable } from '@angular/core';
import LaunchDarkly, { LDClient, LDContext, LDFlagChangeset, LDFlagValue, LDOptions } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { AdsActiveUserModel } from 'models';
import { CallControlFeatureFlagKey, DashboardFeatureFlagKey, EmbeddedMenuFeatureFlagKey, PmFormsFeatureFlagKey } from 'utils';
import { environment } from 'environment/environment';

const unauthUserContext: LDContext = {
  kind: 'user',
  key: 'unauth-user',
  name: 'Unauthenticated User',
};

@Injectable({
  providedIn: 'root'
})
export class FeatureManagerService {
  private readonly client: LDClient;

  private context: LDContext = unauthUserContext;

  private readonly flagValues: Record<string, BehaviorSubject<LDFlagValue>> = {};

  constructor(private readonly authService: AuthService) {
    const options: LDOptions = {
      fetchGoals: false,
      bootstrap: 'localStorage',
      streaming: false,
    };

    if (this.authService.user) {
      this.context = {
        kind: 'auth-user',
        key: this.authService.user.userId,
        name: `${this.authService.user.firstName} ${this.authService.user.lastName}`,
      };
    }

    this.client = LaunchDarkly.initialize(environment.launchDarklyClientId, this.context, options);

    this.client.on('initialized', () => {
      const allFlags = this.client?.allFlags();
      if (allFlags) {
        Object.keys(allFlags).forEach((flagKey) => this.setFlag(flagKey, allFlags[flagKey]));
      }
    });

    this.client.on('change', (changeSet: LDFlagChangeset) => {
      Object.keys(changeSet).forEach((flagKey) => this.setFlag(flagKey, changeSet[flagKey].current));
    });

    this.authService.authenticatedUser$.subscribe((user: AdsActiveUserModel | null) => {
      let newContext: LDContext = unauthUserContext;
      if (user) {
        newContext = {
          kind: 'auth-user',
          key: user.userId,
          name: `${user.firstName} ${user.lastName}`,
        }
      }
      if (this.context.key !== newContext.key) {
        this.context = newContext;
        this.client.identify(this.context);
      }
    });
  }

  private setFlag(flagKey: string, value: LDFlagValue): void {
    if (!this.flagValues[flagKey]) {
      this.flagValues[flagKey] = new BehaviorSubject<LDFlagValue>(value);
    }
    this.flagValues[flagKey].next(value);
  }

  getFlag(flagKey: string, defaultValue: LDFlagValue): Observable<LDFlagValue> {
    if (!this.flagValues[flagKey]) {
      this.flagValues[flagKey] = new BehaviorSubject<LDFlagValue>(defaultValue);
    }
    return this.flagValues[flagKey].asObservable();
  }

  get pwaCallControl$(): Observable<LDFlagValue> {
    return this.getFlag(CallControlFeatureFlagKey, false);
  }

  get pwaDashboard$(): Observable<LDFlagValue> {
    return this.getFlag(DashboardFeatureFlagKey, false);
  }

  get pwaEmbeddedMenu$(): Observable<LDFlagValue> {
    return this.getFlag(EmbeddedMenuFeatureFlagKey, false);
  }

  get pwaPmForms$(): Observable<LDFlagValue> {
    return this.getFlag(PmFormsFeatureFlagKey, false);
  }

  waitForInitialization(): Promise<void> {
    return this.client.waitForInitialization(10);
  }
}
