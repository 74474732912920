import { AtmCleaning } from '../../../atm-cleaning/atm-cleaning';
import { NetworkLogo } from '../../../shared/network-logo';
import { AtmCleaningResponse, AtmCleaningResponseDropdowns } from '../../atm-cleaning/atm-cleaning-response';
import { AtmCleaningDropdowns } from '../../../atm-cleaning/atm-cleaning-dropdowns';

export const mapAtmCleaningForm = (response: AtmCleaningResponse): AtmCleaning => {
  const data = response.atmCleaning;
  const logos: { logo: NetworkLogo; dto: boolean }[] = [
    { logo: NetworkLogo.AmericanExpress, dto: data.atmNetworkLogos_AmericanExpress ?? false },
    { logo: NetworkLogo.Cirrus, dto: data.atmNetworkLogos_Cirrus ?? false },
    { logo: NetworkLogo.Coop, dto: data.atmNetworkLogos_Coop ?? false },
    { logo: NetworkLogo.Discover, dto: data.atmNetworkLogos_Discover ?? false },
    { logo: NetworkLogo.Maestro, dto: data.atmNetworkLogos_Maestro ?? false },
    { logo: NetworkLogo.Mastercard, dto: data.atmNetworkLogos_Mastercard ?? false },
    { logo: NetworkLogo.Plus, dto: data.atmNetworkLogos_Plus ?? false },
    { logo: NetworkLogo.Star, dto: data.atmNetworkLogos_Star ?? false },
    { logo: NetworkLogo.Visa, dto: data.atmNetworkLogos_Visa ?? false },
  ];

  const getLogos = () => {
    const someLogo = logos.reduce((value, nextLogo) => value || nextLogo.dto, false);
    if (someLogo) {
      return logos.filter((logo) => logo.dto).map((logo) => logo.logo);
    } else {
      return [NetworkLogo.None];
    }
  };

  return {
    atmExteriorEvaluation: {
      allNa: false,
      surchargeStickerPresent: data.atmExtEval_IsSurchargeStickerPresent,
      depositoryAvailabilityStickerPresent: data.atmExtEval_IsDepositoryAvailabilityStickerPresent,
      audioInstructionsFunctioning: data.atmExtEval_AreAudioInstructionsFunctioning,
      isSoftwareOnsite: data.atmExtEval_IsSoftwareOnsite,
    },
    atmNetworkLogos: {
      other: data.atmNetworkLogos_Other ?? '',
      logos: getLogos(),
    },
    atmSection: {
      atmManufacturer: data.atm_Manufacturer,
      otherAtmManufacturer: data.atm_ManufacturerOther,
      atmModel: data.atm_Model,
      atmSerialNumber: data.atm_SerialNumber,
      depositorType: data.atm_DepositorType,
      terminalId: data.atm_TerminalID,
    },
    cleaningTasks: {
      allNa: false,
      thoroughInspection: data.cleaningTasks_ThoroughlyInspectATM,
      fasciaTrim: data.cleaningTasks_CleanWipeFasciaTrim,
      customerDisplay: data.cleaningTasks_CleanWipeCustomerDisplay,
      functionKeys: data.cleaningTasks_CleanWipeFunctionKeys,
      atmSidesBackAndTop: data.cleaningTasks_CleanWipeAtmSides,
      atmArea: data.cleaningTasks_CleanAtmAreaAndDisposeGarbage,
      vacuumSafe: data.cleaningTasks_VaccumBlowoutSafe,
      vacuumTopHalf: data.cleaningTasks_VaccumBlowoutTopHalf,
      vacuumAllFans: data.cleaningTasks_VaccumBlowoutAllFans,
      vacuumAllFilters: data.cleaningTasks_VaccumBlowoutAllFilters,
    },
    replenishmentTasks: {
      allNa: false,
      depositoryInk: data.replenishmentTasks_CheckDepositoryInkCartridge,
      envelopeDispenser: data.replenishmentTasks_CheckEnvelopDispenserHopper,
      paperRoll: data.replenishmentTasks_CheckPrinterPaperRoll,
    },
    comments: data.comments ?? '',
  } as AtmCleaning;
};

export const mapAtmCleaningDropdowns = (data: AtmCleaningResponseDropdowns): AtmCleaningDropdowns => {
  return {
    manufacturerList: data.manufacturerList.map((item) => ({
      name: item.manufacturerName,
      value: item.manufacturerName,
    })),
    depositorTypeList: data.depositorTypeList.map((item) => ({
      name: item.depositorTypeName,
      value: item.depositorTypeName,
    })),
    overallConditions: data.overallConditions.map((item) => ({
      name: item.name,
      value: item.code,
    })),
  };
};
