import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, NgForm, NgModelGroup } from '@angular/forms';
import { SelectOption, YesNo } from 'models';
import { CommonFormInputComponent, RadioButtonGroupComponent, SelectComponent } from 'shared/material-wrappers';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';

@Component({
  selector: 'ads-atm-software-section',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CommonFormInputComponent,
    SimpleFormComponent,
    SelectComponent,
    RadioButtonGroupComponent,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './atm-software-section.component.html',
})
export class AtmSoftwareSectionComponent {
  @Input() section?: FormGroup;
  @Input() operatingSystemsList: SelectOption<string>[] = [];

  constructor(@Optional() public readonly ngModelGroup: NgModelGroup) {}

  options: SelectOption<YesNo>[] = [
    { name: 'Yes', value: YesNo.Yes },
    { name: 'No', value: YesNo.No },
  ];

  displayOther = () => {
    const control = this.section?.get('operatingSystem');
    return Boolean(control && control.value === 'Other');
  };
}
