import { Component, EventEmitter, inject, Input, Output, TemplateRef } from '@angular/core';
import { CustomButtonComponent } from '../custom-button/custom-button.component';
import { CommonModule } from '@angular/common';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ads-custom-prompt',
  standalone: true,
  imports: [CustomButtonComponent, CommonModule, MatMenuModule, MatBottomSheetModule, MatIconModule],
  templateUrl: './custom-prompt.component.html',
  styleUrl: './custom-prompt.component.scss',
})
export class CustomPromptComponent {
  @Input() label = '';
  @Input() icon = '';
  @Input() focused = false;
  @Input() desktopContent: TemplateRef<unknown> | null = null;
  @Input() desktopTrigger: MatMenu | null = null;
  @Input() mobileContent: TemplateRef<unknown> | null = null;
  @Input() isDesktopView = false;
  @Input() disabled = false;
  @Output() clicked = new EventEmitter<MouseEvent>();
  @Output() dismissed = new EventEmitter<MouseEvent>();
  private readonly _bottomSheet = inject(MatBottomSheet);

  onMobileClick() {
    if (this.mobileContent !== null) {
      this._bottomSheet.open(this.mobileContent, {
        hasBackdrop: true,
      });
    }
    this._bottomSheet._openedBottomSheetRef?.afterDismissed().subscribe(() => {
      this.dismissed.emit();
    });
    this.clicked.emit();
  }

  close() {
    this._bottomSheet.dismiss();
  }

  onDesktopClick() {
    this.clicked.emit();
  }

  onOverlayClick() {
    this.dismissed.emit();
  }
}
