import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { FormsModule, ControlContainer, NgModelGroup, FormGroup } from '@angular/forms';
import { CommonFormInputComponent, SelectComponent } from 'shared/material-wrappers';
import { UCSFormSection } from '../ucs.component';
import { SecurityUCSDropdowns } from 'models/security/security-dropdowns';
import { MatDividerModule } from '@angular/material/divider';
import { SimpleFormComponent } from 'shared/simple-form/simple-form.component';
import { CommonTextAreaComponent } from 'shared/material-wrappers/common-text-area/common-text-area.component';

@Component({
  selector: 'ads-ucs-section-form',
  standalone: true,
  imports: [CommonModule, FormsModule, CommonFormInputComponent, SelectComponent, MatDividerModule, SimpleFormComponent, CommonTextAreaComponent],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
  templateUrl: './ucs-section-form.component.html',
})
export class UcsSectionFormComponent {
  @Input() ucsItems?: SecurityUCSDropdowns;
  @Input() section?: FormGroup;
  @Input() item?: UCSFormSection;

  constructor(@Optional() public readonly ngModelGroup: NgModelGroup) {}

  displayOtherManufacturer = () => {
    const manufacturer = this.section?.get('ucsManufacturer');
    return Boolean(manufacturer && manufacturer.value === 'Other');
  };

  displayOtherCondition = () => {
    const condition = this.section?.get('overallConditionUCS');
    return Boolean(condition && (condition.value === 'Poor Condition' || condition.value === 'Failed'));
  };
}
