import { AdsCallControl } from 'models';
import { DifferencePipe } from 'ngx-moment';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { Component, AfterViewInit, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TimeZonePipe } from 'app/utils/time-zone.pipe';
import { CallControlService } from './call-control.service';
import { ServiceCallsService } from './service-calls/call-control-filters/sorting.service';
import { CallControlServiceCallsComponent } from './call-control-service-calls/call-control-service-calls.component';
import { CallControlPmBoardComponent } from './call-control-pm-board/call-control-pm-board.component';
import { LayoutNavService } from 'shared/layout-nav/layout-nav.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'ads-call-control',
  standalone: true,
  providers: [TimeZonePipe],
  imports: [CommonModule, MatTabsModule, CallControlServiceCallsComponent, CallControlPmBoardComponent, MatIconModule, MatButtonModule],
  templateUrl: './call-control.component.html',
  styleUrl: './call-control.component.scss',
})
export class CallControlComponent implements OnInit, AfterViewInit {
  pmBoardCalls: AdsCallControl[] = [];
  serviceCalls: AdsCallControl[] = [];
  isDesktopView = false;
  loading = true;

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly timeZonePipe: TimeZonePipe,
    private readonly ccService: CallControlService,
    public readonly dataService: ServiceCallsService,
    protected readonly layoutNavService: LayoutNavService,
  ) {}

  ngOnInit(): void {
    this.ccService.callControlList().subscribe((response) => {
      const items: AdsCallControl[] = this.mapCalls(response);
      this.pmBoardCalls = items.filter((x) => x.isPm);
      this.serviceCalls = items.filter((x) => !x.isPm);
      this.loading = false;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe((x) => {
        this.isDesktopView = !x.matches;
      });
    });
  }

  mapCalls(data: AdsCallControl[]) {
    // Migrating logic from previous ADS implementation
    const items: AdsCallControl[] = [];
    const differencePipe = new DifferencePipe();
    data.forEach((x) => {
      let pmFlag = false;
      const now = new Date();
      const dueDate = x.servicedatetime ? this.timeZonePipe.transform(x.servicedatetime.toString()) : new Date();
      const daydiff = differencePipe.transform(now, dueDate, 'day', false);

      if (Math.abs(daydiff) >= 31 && x.problem?.includes('Preventative')) {
        pmFlag = true;
      }

      if (now > dueDate) {
        pmFlag = false;
      }

      x.dateopened = x.dateopened ? new Date(x.dateopened).toLocaleDateString() : new Date().toLocaleDateString();
      x.createddatetime = x.createddatetime
        ? new Date(x.createddatetime).toLocaleString()
        : new Date().toLocaleString();
      x.servicedatetime = x.servicedatetime
        ? new Date(x.servicedatetime).toLocaleString()
        : new Date().toLocaleString();

      items.push({ ...x, isPm: pmFlag });
    });

    return items;
  }

  onTabChange() {
    this.dataService.statusFilter.value = 'none';
    this.dataService.dateFilter.minValue = undefined;
    this.dataService.dateFilter.maxValue = undefined;
    this.dataService.sorting.activeSortCriteria = 'none';
    this.dataService.sorting.sortAsc = true;
    this.dataService.sorting.tempSortCriteria = 'none';
    this.dataService.sorting.tempSortingLabel = '- None -';
  }
}
