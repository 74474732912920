import { Injectable, signal } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { filter, lastValueFrom, take } from 'rxjs';
import { FeatureManagerService, IframeMessageService } from 'services';
import { CallControlFeatureFlagKey, DashboardFeatureFlagKey, NavigationRoutesEnum } from 'utils';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

interface NavigationRoute {
  url: string;
  featureFlagKey?: string;
}

const NavigationHandler = {
  [NavigationRoutesEnum.Home]: {
    url: 'dashboard',
    featureFlagKey: DashboardFeatureFlagKey,
  },
  [NavigationRoutesEnum.CallControl]: {
    url: 'callControl',
    featureFlagKey: CallControlFeatureFlagKey,
  },
  [NavigationRoutesEnum.Lines]: {
    url: 'lines',
  },
  [NavigationRoutesEnum.Notes]: {
    url: 'notes',
  },
  [NavigationRoutesEnum.Calendar]: {
    url: 'calendar',
  },
  [NavigationRoutesEnum.History]: {
    url: 'history',
  },
  [NavigationRoutesEnum.Centran]: {
    url: 'centran',
  },
  [NavigationRoutesEnum.MyOpenCalls]: {
    url: 'myOpenCalls',
  },
  [NavigationRoutesEnum.AtmDown]: {
    url: 'atmDown',
  },
  [NavigationRoutesEnum.OtherTechs]: {
    url: 'otherTechs',
  },
  [NavigationRoutesEnum.Inventory]: {
    url: 'inventory',
  },
  [NavigationRoutesEnum.PartStatus]: {
    url: 'partStatus',
  },
} as const;

@Injectable({
  providedIn: 'root',
})
export class LayoutNavService {
  public pwaRoute = false;

  public selectedIndex = 0;
  public isDesktopView = signal(false);
  public isMobileIOS = signal(false);
  public sideNavOpened = true;

  constructor(
    private readonly iframeMessageService: IframeMessageService,
    private readonly featureManager: FeatureManagerService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const tabIndex = this.getTabIndexFromRoute();
      if (this.isNavigationRouteDefined(tabIndex)) {
        this.changeTab(tabIndex);
      }
    });
  }

  async onTabChanged(event: MatTabChangeEvent): Promise<void> {
    const index = event.index as keyof typeof NavigationHandler;

    if (event.index === 0) {
      this.router.navigate(['home']);
      this.pwaRoute = true;
      return;
    }

    if (!this.isDesktopView() && event.index === 4) {
      this.router.navigate(['more']);
      this.pwaRoute = true;
      return;
    }

    if (NavigationHandler[index]) {
      this.router.navigate([NavigationHandler[index].url]);
    }

    await this.changeTab(index);
  }

  async changeTab(index: keyof typeof NavigationHandler): Promise<void> {
    this.selectedIndex = index;

    const navHandler = NavigationHandler[index] as NavigationRoute;

    const iframeMustNavigate =
      !navHandler.featureFlagKey ||
      !(await lastValueFrom(this.featureManager.getFlag(navHandler.featureFlagKey, false).pipe(take(1))));

    if (iframeMustNavigate) {
      this.iframeMessageService.iframeNavigateTo(NavigationHandler[index].url);
    }

    this.pwaRoute = !iframeMustNavigate;
  }

  isNavigationRouteDefined(value: NavigationRoutesEnum): boolean {
    return !!value || value === NavigationRoutesEnum.Home;
  }

  getTabIndexFromRoute(): NavigationRoutesEnum {
    let activatedRouteSnapshot = this.activatedRoute?.snapshot;
    while (activatedRouteSnapshot?.firstChild) {
      activatedRouteSnapshot = activatedRouteSnapshot?.firstChild;
    }
    const data = activatedRouteSnapshot?.data;
    return data?.['tabIndex'] as NavigationRoutesEnum;
  }

  iframeNavigateTo(route: string) {
    this.iframeMessageService.iframeNavigateTo(route);
    this.pwaRoute = true;
  }

  toggleSideNav() {
    this.sideNavOpened = !this.sideNavOpened;
  }

  goHome() {
    this.selectedIndex = 0;
    this.pwaRoute = true;
    this.router.navigate(['home']);
  }
}
