<table mat-table [dataSource]="list">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? toggleAllRows(list, selection) : null"
        [checked]="selection.hasValue() && isAllSelected(list, selection)"
        [indeterminate]="selection.hasValue() && !isAllSelected(list, selection)"
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        color="#b3261e"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="camera">
    <th mat-header-cell *matHeaderCellDef>Camera</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-input
        [ngModelGroup]="'cameraTableItem#' + (i + 1)"
        name="camera"
        [maxLength]="50"
        [required]="true"
        [value]="element.camera"
        [(ngModel)]="element.camera"
        [onBlur]="onBlur(element)"
      ></ads-table-form-input>
    </td>
  </ng-container>

  <ng-container matColumnDef="cameraType">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-bottom-sheet-input
        [ngModelGroup]="'cameraTableItem#' + (i + 1)"
        name="cameraType"
        [required]="true"
        [value]="element.cameraType"
        [(ngModel)]="element.cameraType"
        [openBottomSheet]="openBottomSheet(element)"
        [maxWidth]="'84px'"
      ></ads-table-form-bottom-sheet-input>
    </td>
  </ng-container>

  <ng-container matColumnDef="isPTZ">
    <th mat-header-cell *matHeaderCellDef>Is PTZ?</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <ads-table-form-bottom-sheet-input
        [ngModelGroup]="'cameraTableItem#' + (i + 1)"
        name="isPTZ"
        [required]="true"
        [value]="element.isPTZ"
        [(ngModel)]="element.isPTZ"
        [openBottomSheet]="openBottomSheet(element)"
        type="boolean"
        [maxWidth]="'56px'"
      ></ads-table-form-bottom-sheet-input>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumnsKeys()"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsKeys()"></tr>
</table>
