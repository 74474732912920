export class ItmCashDispenserTasks {
  allNa: boolean;
  checkAndCleanAllSensors: number | null;
  removeAnyForeignMaterialFromDispenser: number | null;
  checkAndCleanAllRollers: number | null;
  checkAndCleanAllBelts: number | null;
  checkAndCleanShutter: number | null;
  replacePickCupsOrFeedRollers: number | null;
  checkInterlockSwitch: number | null;
  performDiagnosticTest: number | null;

  constructor() {
    this.allNa = false;
    this.checkAndCleanAllSensors = null;
    this.removeAnyForeignMaterialFromDispenser = null;
    this.checkAndCleanAllRollers = null;
    this.checkAndCleanAllBelts = null;
    this.checkAndCleanShutter = null;
    this.replacePickCupsOrFeedRollers = null;
    this.checkInterlockSwitch = null;
    this.performDiagnosticTest = null;
  }
}
