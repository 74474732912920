import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from 'services';
import { HeaderService } from 'shared/header/header.service';

@Component({
  selector: 'ads-profile-name',
  standalone: true,
  imports: [MatIconModule, CommonModule],
  templateUrl: './profile-name.component.html',
  styleUrl: './profile-name.component.scss',
})
export class ProfileNameComponent {
  @Input() showIcon = true;

  constructor(public authService: AuthService, public headerService: HeaderService) {}
}
