export interface AdsCallControl {
  isPm?: boolean;
  workorder?: string;
  ticketRecId?: string;
  jo?: string;
  calltype?: string;
  problem?: string;
  priority?: string;
  customer?: string;
  nameAlias?: string;
  city?: string;
  createddatetime?: Date | string;
  servicedatetime?: Date | string;
  onSiteBy?: Date | string;
  resolveBy?: Date | string;
  laststatusname?: string;
  nbr?: string;
  masterAccount?: string;
  recid?: number;
  customerpo?: string;
  state?: string;
  zip?: string;
  description?: string;
  isArticleAssociated?: boolean;
  dateopened?: Date | string;
  technician?: string;
  address?: string;
  projid?: string;
  problemTypeId?: string;
  projgroup?: string;
  status?: number;
  personnelnumber?: string;
  center?: string;
  contact?: string;
  phone?: string;
}

export interface AdsCallControlPaged {
  totalRecords: number;
  items: AdsCallControl[];
}

export interface AdsCallControlFilters {
  isMyOpenCalls?: boolean;
  pageNo?: number;
  pageSize?: number;
  sortField?: string;
  sortOrder?: string;
  searchText?: string;
  priorities?: string;
  onSiteByTo?: string;
  onSiteByFrom?: string;
  scheduledFrom?: string;
  scheduledTo?: string;
  statuses?: string;
}

export interface AdsEquipmentOnSite {
  index?: number;
  equipmentId?: string | number;
  equipmentType?: string;
  equipmentTypeId?: string | number;
  manufacturer?: string;
  model?: string;
  terminalID?: string;
  type?: string;
  partsWarrantyEnds?: Date;
}

export interface AdsTicketNote {
  ticketRecId: number;
  ticketNoteRecId: number;
  description: string;
  firstName: string;
  lastName: string;
  fullName: string;
  createdDateUtc: string;
  email: string;
  isInternal: boolean;
  isNotification: boolean;
  isSecure: boolean;
  customerId: string;
}

export interface AdsTicketNotes {
  ticketInfo: never;
  noteInfo: AdsTicketNote[];
  attachmentInfo: never[];
}

export interface AdsPMDetails {
  formType?: string;
  workStatusByName?: string;
  isPmFormDisabled?: boolean;
  isSurvey?: boolean;
}

export enum AdsPMFormType {
  'Agreement Survey' = 'AS',
  'PM Security' = 'Security',
  'PM ITM' = 'Itm',
  'PM ATM' = 'Atm',
  'PM TCD TCR' = 'TcdTcr',
  'PM Currency Counter Discriminator' = 'CcCd',
  'PM ATM Cleaning' = 'AtmCleaning',
  'PM ITM Cleaning' = 'ItmCleaning',
  'Preventative Maintenance' = 'PM',
}

export interface AdsAttachments {
  documentId: string;
  refrecid: number;
  recId: number;
  typeId: number;
  name: string;
  notes: string;
  restrictions: string;
  createdDateTime: string;
  createdBy: string;
  isTechNotes: number;
}

export interface AttachmentEntity {
  data: {
    name?: string;
    restriction?: string;
    createdDate?: Date;
    recId?: number;
    base64str?: string;
    fileExt?: string;
    createdBy?: string;
    isDeleted?: boolean;
  };
}

export interface AdsAttachmentUpload {
  name?: string;
  internal?: boolean;
  external?: boolean;
  extension?: string;
  base64?: string;
  recId?: number;
}

export interface AdsRelatedArticle {
  articleRecId: number;
  title: string;
  createdBy: string;
}

export interface AdsOpenCall {
  nbr: string;
  workorder: string;
  recid: number;
  projid: string;
  jo: string;
  calltype: string;
  problem: string;
  priority: string;
  problemTypeId: string;
  customer: string;
  nameAlias: string;
  contact: string;
  createddatetime: string;
  servicedatetime: string;
  onSiteBy: string;
  resolveBy: string;
  laststatusname: string;
  status: number;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  description: string;
  center: string;
  customerpo: string;
  technician: string;
  personnelnumber: string;
  dateopened: string;
  projgroup: string;
  masterAccount: string;
  ticketRecId: number;
  isArticleAssociated: boolean;
}

export interface OpenAckCalls {
  openCalls: number;
}
