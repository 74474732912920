import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AdsActiveUserModel } from 'models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly service: ApiService) {}

  /**
   * Gets the personnel id of the user authenticated..
   */
  public employeeDetail(): Observable<AdsActiveUserModel> {
    return this.service.sendRequest<AdsActiveUserModel>({
      method: 'get',
      url: 'user/employeeDetail',
      urlPrefix: 'ads',
    });
  }
}
