<ads-custom-button
  *ngIf="isDesktopView"
  [label]="label"
  [icon]="icon"
  [matMenu]="desktopTrigger"
  (clicked)="onDesktopClick()"
  [disabled]="disabled"
>
</ads-custom-button>
<ads-custom-button
  *ngIf="!isDesktopView"
  [label]="label"
  [icon]="icon"
  (clicked)="onMobileClick()"
  [disabled]="disabled"
>
</ads-custom-button>
<button *ngIf="isDesktopView && focused" class="desktop-overlay" (click)="onOverlayClick()"> </button>
