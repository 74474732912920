<div class="container">
  <span
    class="name"
    [class.name-mobile]="!layoutNavService.isDesktopView()"
    [class.name-desktop]="layoutNavService.isDesktopView()"
    >{{ authService.user?.fullName }}</span
  >
  <button mat-icon-button aria-label="expand menu" (click)="layoutNavService.toggleSideNav()">
    <mat-icon class="material-icons-outlined" *ngIf="layoutNavService.isDesktopView()">menu_open</mat-icon>
  </button>
</div>
