<ads-simple-form #itmTasks="ngModelGroup" ngModelGroup="itmTasks">
  <ads-radio-button-group
    [options]="yesNoOptions"
    label="Are Configuration sheets present?"
    name="areConfigSheetsPresent"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="yesNoOptions"
    label="Is maintenance log sheet present?"
    name="isMaintainanceLogSheetPresent"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Verify site profile information"
    name="verifySiteProfileOnSharepoint"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Check error log and tallies for abnormalities (SLM ONLY)"
    name="checkErrorLog"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Clear tallies (SLM Only)"
    name="clearTallies"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
  <mat-divider></mat-divider>

  <ads-radio-button-group
    [options]="completeNaOptions"
    label="Request Kaba combo"
    name="requestKabaCombo"
    [required]="true"
    ngModel
  ></ads-radio-button-group>
</ads-simple-form>
