import { CommonModule } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogContent, MatDialogActions, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdsTicketNote } from 'models';
import { AuthService } from 'services';

@Component({
  selector: 'ads-ticket-dialog',
  standalone: true,
  imports: [MatButtonModule, MatDialogContent, MatDialogActions, CommonModule],
  templateUrl: './ticket-dialog.component.html',
  styleUrl: './ticket-dialog.component.scss',
})
export class TicketDialogComponent {
  readonly dialogRef = inject(MatDialogRef<TicketDialogComponent>);
  constructor(@Inject(MAT_DIALOG_DATA) public data: AdsTicketNote, private readonly authService: AuthService) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
