<button class="card" (click)="clicked.emit()">
  <div class="part-info">
    <span class="part-date"><mat-icon>event</mat-icon>{{item.date | date: 'MM/dd/yyyy'}}</span>
    <span class="part-type">{{item.type}}: {{roundHours(item.hours ?? '')}}</span>
  </div>
  <div>
    {{item.startTime | date: 'hh:mm a'}} - {{item.endTime | date: 'hh:mm a'}}
  </div>
  <div>
    Entry type: {{item.entryType}}
  </div>
</button>
