export class AtmCashDepositoryTasks {
  allNa: boolean;
  cleanSensors: number | null = null;
  cleanBillScannerLenses: number | null = null;
  cleanRollers: number | null = null;
  cleanBelts: number | null = null;
  cleanBins: number | null = null;
  verifyInfeedAlignment: number | null = null;
  diagnostic: number | null = null;

  constructor() {
    this.allNa = false;
    this.cleanSensors = null;
    this.cleanBillScannerLenses = null;
    this.cleanRollers = null;
    this.cleanBelts = null;
    this.cleanBins = null;
    this.verifyInfeedAlignment = null;
    this.diagnostic = null;
  }
}
