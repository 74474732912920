<div class="title-container">
  <span class="title">Related Articles</span>
</div>

<div class="container">
  <div *ngIf="isLoading()" class="loading">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
  @for (article of articles(); track articles()) {

  <div class="attachments-container">
    <span>{{ article.title }}</span>
    <button mat-button class="view" (click)="viewArticle(article.articleRecId)">View</button>
  </div>
  }
</div>

<button class="ok-b" mat-button mat-stroked-button (click)="closeDialog()">Ok</button>
