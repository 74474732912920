import { AuthService } from 'services';
import { FormsModule } from '@angular/forms';
import { SelectOption, YesNo } from 'models';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { Component, Inject, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { WorkOrderService } from 'pages/work-order/work-order-service';
import { CallControlService } from 'pages/call-control/call-control.service';
import { ModalActionsComponent } from 'shared/modal-actions/modal-actions.component';
import { OnSiteByComponent } from '../on-site-by/on-site-by.component';
import { CallStatusId } from 'models/call-control/ack-schedule-models';
import { OperationResponse } from 'models/call-control/command-response';
import { LoaderService } from 'shared/loader/loader.service';
import { roundDateToNearestQuarter } from 'app/utils/dates';

@Component({
  selector: 'ads-acknowledge',
  standalone: true,
  imports: [CommonModule, FormsModule, MatTabsModule, ModalActionsComponent, OnSiteByComponent],
  templateUrl: './acknowledge.component.html',
  styleUrl: './acknowledge.component.scss',
  providers:[
    {
      provide: Window,
      useValue: window
    }
  ]

})
export class AcknowledgeComponent {
  maxDate = new Date(new Date().getFullYear() + 1, 11, 31);

  public readonly _snackBar = inject(MatSnackBar);
  public readonly _bottomSheet = inject(MatBottomSheet);
  public readonly dialogRef = inject(MatDialog);
  private readonly datepipe: DatePipe = new DatePipe('en-US');

  yesNoOptions: SelectOption<YesNo>[] = [
    { name: 'Yes', value: YesNo.Yes },
    { name: 'No', value: YesNo.No },
  ];

  constructor(
    public readonly callControlService: CallControlService,
    public readonly authService: AuthService,
    public readonly workOrderService: WorkOrderService,
    public readonly loader: LoaderService,
    @Inject(Window) private readonly window: Window
  ) {}

  onConfirm() {
    if (this.authService.authenticated) {
      this.callControlService
        .updateCallStatus({
          workOrder: this.workOrderService.workOrderDetails().workorder ?? '',
          dateEnter: this.datepipe.transform(new Date(), 'MM/dd/yyyy')?.toString(),
          timeEnter: this.datepipe.transform(roundDateToNearestQuarter(new Date()), 'HH:mm:ss')?.toString(),
          noteSubject: 'Call Acknowledged',
          noteText: 'Tech acknowledged call. ',
          status: CallStatusId.Acknowledged_0,
          techIdMaster: this.authService.user?.personnelNumber ?? '',
          userId: this.authService.user?.userId ?? '',
          utcOffset: new Date().getTimezoneOffset() / -60,
        })
        .subscribe({
          next: this.onAcknowledgeSuccess,
          error: this.onAcknowledgeSubmitError,
        });
    }
  }

  hideAll() {
    this._bottomSheet.dismiss();
    this.dialogRef.closeAll();
  }

  refreshPage = () => this.window.location.reload();

  onAcknowledgeSuccess = (result: OperationResponse) => {
    this.hideAll();
    if (result.isSuccess) {
      this._snackBar.open('Work Order Acknowledged successfully.', 'Close');
      this.loader.show();
      setTimeout(() => {
        this.loader.hide();
        this.refreshPage();
      }, 3000);
    } else {
      this.onAcknowledgeSubmitError(result.errorMessage);
    }
  };

  onAcknowledgeSubmitError = (error: unknown) => {
    console.error(error);
    this._snackBar.open('Failed to mark your Work Order as Acknowledged, please try again later.', 'Close');
  };

}
