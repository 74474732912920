import { CcCdSectionDto } from 'models/api/cc-cd/cc-cd';
import { CcCdResponse, CcCdResponseDropdowns } from 'models/api/cc-cd/cc-cd-response';
import { CcCdDropdowns, CcCdExternalAreaTasks, CcCdFeedModuleTasks, CcCdForm, CcCdPresentModuleTasks, CcCdSection, CcCdTasks } from 'models/cc-cd';

export const mapCcCdSection = (item: CcCdSectionDto, index: number): CcCdSection => {
  return {
    manufacturer: item.currencyCounterDiscriminator_Manufacturer,
    model: item.currencyCounterDiscriminator_Model,
    serial: item.currencyCounterDiscriminator_Serial,
    type: item.currencyCounterDiscriminator_Type,
    manufacturerOfPrinter: item.currencyCounterDiscriminator_ManufacturerOfPrinter,
    externalEquipmentAttached: item.currencyCounterDiscriminator_ExternalEquipmentAttached,
    overallCondition: item.currencyCounterDiscriminator_OverallCondition,
    equipmentRating: item.equipment_Rating,
    photoFront: item.photo_Front,
    photoSerialNumber: item.photo_SerialNumber,
    photoPrinter: item.photo_Printer,
    photoAdditionalPhoto: item.photo_AdditionalPhoto,
    photoExternalDevices: item.photo_ExternalDevices,
    quantityOfPockets: item.currencyCounterDiscriminator_QuantityOfPockets ? item.currencyCounterDiscriminator_QuantityOfPockets.toString() : null,
    quantityOfPrinters: item.currencyCounterDiscriminator_QuantityOfPrinters ? item.currencyCounterDiscriminator_QuantityOfPrinters.toString() : null,
    index,
  };
};

export const mapCcCdForm = (response: CcCdResponse): CcCdForm => {
  const data = response.currencyCounter;
  const ccCdSections: CcCdSection[] = [];

  data.currencyCounterDiscriminatorItems.forEach((section, index) => {
    ccCdSections.push(mapCcCdSection(section, index + 1));
  });

  const ccCdTasks: CcCdTasks = {
    confSheetsPresent: data.currencyCounterDiscriminatorTasks_ConfSheetsPresent,
    checkErrorForAbnormalities: data.currencyCounterDiscriminatorTasks_CheckErrorForAbnormalities,
  };

  const ccCdFeedModuleTasks: CcCdFeedModuleTasks = {
    allNa: false,
    checkCleanSensors: data.feedModuleTasks_CheckCleanSensors,
    removeForeignMaterial: data.feedModuleTasks_RemoveForeignMaterial,
    checkCleanRollers: data.feedModuleTasks_CheckCleanRollers,
    checkCleanBelts: data.feedModuleTasks_CheckCleanBelts,
    performDiagnosticTests: data.feedModuleTasks_PerformDiagnosticTests,
  };

  const ccCdPresentModuleTasks: CcCdPresentModuleTasks = {
    allNa: false,
    checkCleanSensors: data.presentModuleTasks_CheckCleanSensors,
    removeForeignMaterial: data.presentModuleTasks_RemoveForeignMaterial,
    checkCleanRollers: data.presentModuleTasks_CheckCleanRollers,
    checkCleanBelts: data.presentModuleTasks_CheckCleanBelts,
    performDiagnosticTests: data.presentModuleTasks_PerformDiagnosticTests,
  };

  const ccCdExternalAreaTasks: CcCdExternalAreaTasks = {
    allNa: false,
    cleanCustomerInterfaceArea: data.externalAreaTasks_CleanCustomerInterfaceArea,
    cleanTestScreen: data.externalAreaTasks_CleanTestScreen,
    cleanEntireExterior: data.externalAreaTasks_CleanEntireExterior,
    checkAdjustmentScrewCalibration: data.externalAreaTasks_CheckAdjustmentScrewCalibration,
    verifyCablesAreNeatOrganizedBehindEquip: data.externalAreaTasks_VerifyCablesAreNeatOrganizedBehindEquip,
  };

  const ccCdVacuumingBlowOutTasks = {
    allNa: false,
    mainBody: data.vaccummingBlowOut_MainBody,
    allFans: data.vaccummingBlowOut_AllFans,
    powerSupply: data.vaccummingBlowOut_PowerSupply,
  };

  return {
    ccCdSections,
    ccCdTasks,
    ccCdFeedModuleTasks,
    ccCdPresentModuleTasks,
    ccCdExternalAreaTasks,
    ccCdVacuumingBlowOutTasks,
    comments: data.comments ?? '',
  };
};

export const mapCcCdDropdowns = (data: CcCdResponseDropdowns): CcCdDropdowns => {
  return {
    manufacturerList: data.ccdManufacturer.map((item) => ({
      value: item.manufacturerName,
      name: item.manufacturerName,
    })),
    printerManufacturerList: data.printerManufacturer.map((item) => ({
      value: item.manufacturerName,
      name: item.manufacturerName,
    })),
    currencyCounterDiscriminatorTypeList: data.currencyCounterDiscriminatorType.map((item) => ({
      value: item.name,
      name: item.name,
    })),
    ccdPocketsList: data.ccdPockets.map((item) => ({
      value: item.name,
      name: item.name,
    })),
    ccdPrintersList: data.ccdPrinters.map((item) => ({
      value: item.name,
      name: item.name,
    })),
    currencyCounterOverallConditionsList: data.currencyCounterOverallConditions.map((item) => ({
      value: item.name,
      name: item.name,
    })),
  };
};
