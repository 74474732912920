import { AudioVideoLists } from 'forms/security/audio-video/audio-video-section-form/audio-video-section-form.component';
import { SecurityAudioVideoDropdowns } from 'models/security/security-dropdowns';

export const mapAudioVideoDropdowns = (dropdowns: SecurityAudioVideoDropdowns): AudioVideoLists => ({
  conditionList: dropdowns.audioVideoDUDriveUpAudioMatrixOverallConditions.map((manufacturer) => ({
    name: manufacturer.name,
    value: manufacturer.name,
  })),
  manufacturerList: dropdowns.audioVideoDUAudioMatrixManufacturers.map((manufacturer) => ({
    name: manufacturer.manufacturerName,
    value: manufacturer.manufacturerName,
  })),
  videoSystemList: dropdowns.audioVideoDUDriveUpAudioMatrixVideoSystem.map((manufacturer) => ({
    name: manufacturer.name,
    value: manufacturer.name,
  })),
});
